import { useSelector } from 'react-redux'
import { Invoice as InvoiceType } from '../../../../../types/Invoice'
import GrayBox from '../../../../UI/Graybox/Graybox'
import Title from '../../../../UI/Graybox/Title'
import Loading from '../../../../UI/Loading/Loading'
import props from '../../../../../redux/props'
import Preview from './Preview'
import { useState } from 'react'
import Invoice from './Invoice'
import YearSelection from './YearSelection'

type Props = {
  invoices: InvoiceType[]
}

const List: React.FC<Props> = ({ invoices }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [activeYear, setActiveYear] = useState<number>(null)
  const [previewInvoice, setPreviewInvoice] = useState<InvoiceType>(null)

  const filter = (invoice: InvoiceType) => new Date(invoice.createdOn).getFullYear() === activeYear

  return (
    <GrayBox>
      <Title title={t.invoices.list.title} />
      <Loading loading={invoices} />
      {!!invoices && invoices.length > 0 && (
        <>
          <div className="flex flex-col lg:flex-row gap-5 md:gap-0">
            <div className="flex flex-col lg:w-1/2 shrink-0 w-full">
              <div className="lg:w-40 mb-3">
                <YearSelection invoices={invoices} value={activeYear} onChange={setActiveYear} />
              </div>

              {activeYear && (
                <div className="mt-2 overflow-y-auto overflow-x-hidden max-h-80 md:max-h-96">
                  {invoices.filter(filter).map((invoice, i) => (
                    <Invoice
                      key={invoice.paymentUUID}
                      invoice={invoice}
                      isActive={invoice.uuid === previewInvoice?.uuid}
                      previewCallback={setPreviewInvoice}
                      isFirst={i === 0}
                    />
                  ))}
                </div>
              )}
            </div>
            <div className="w-full lg:w-1/2 shrink-0">
              <Preview invoice={previewInvoice} />
            </div>
          </div>
        </>
      )}
      {!!invoices && invoices.length === 0 && <div className="text-center text-gray-primary text-sm">{t.invoices.list.noData}</div>}
    </GrayBox>
  )
}

export default List
