import { AddOn as AddOnType, Project, Status } from '../../../../../../types/Project'
import GrayBox from '../../../../../UI/Graybox/Graybox'
import Headline from '../../../../../UI/Headline/Headline'
import { useSelector } from 'react-redux'
import props from '../../../../../../redux/props'
import PriceCalculator, { PriceCalculatorType } from '../../../../../UI/PriceCalculator/PriceCalculator'
import Title from '../../../../../UI/Graybox/Title'
import AddOn from '../../../../../UI/PriceCalculator/Project/AddOn'
import NewButton from '../../../../../UI/NewButton/NewButton'
import { useState } from 'react'
import { getTotalPriceOfProjectAddOn } from '../../../../../../utility'
import toast, { TYPE } from '../../../../../../toast'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../../../redux/routes'
import { useNavigate } from 'react-router-dom'

type Props = {
  project: Project
}

const Pricing: React.FC<Props> = ({ project }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [addOn, setAddOn] = useState<AddOnType>({})
  const createProjectAddOn = functional.use(Routes.CREATE_PROJECT_ADD_ON)
  const navigate = useNavigate()

  const submitAddOn = async () => {
    const price = getTotalPriceOfProjectAddOn(project, addOn)
    if (isFinite(price) && price > 0) {
      const result = await createProjectAddOn({ uuid: project.uuid, addOn })
      if (!!result) {
        toast(TYPE.SUCCESS, t.project.view.pricing.addOn.success)
        setAddOn({})
        navigate(`/home/artist/project/milestones/${project.uuid}`)
      } else {
        toast(TYPE.ERROR, t.project.view.pricing.addOn.error)
      }
    } else {
      toast(TYPE.ERROR, t.project.view.pricing.addOn.errorAmount)
    }
  }

  return (
    <div className="mt-5">
      <Headline className="mb-2 text-md">{t.project.view.pricing.headline}</Headline>
      <GrayBox>
        <Title title={t.project.view.pricing.overview.title} />
        <div className="lg:pl-10">
          <PriceCalculator type={PriceCalculatorType.PROJECT} project={project} />
        </div>
        {project.status === Status.IN_PROGRESS && (
          <>
            <Title title={t.project.view.pricing.addOn.title} className="mt-10" />
            <div className="border border-gray-primary rounded-sm py-5 px-5 md:px-10 mt-7 flex flex-col md:flex-row gap-5">
              <div className="md:w-8/12">
                <AddOn project={project} addOn={addOn} onChange={setAddOn} />
                <NewButton className="btn-secondary w-fit !text-xs mt-5" text={t.project.view.pricing.addOn.submit} onClick={submitAddOn} />
              </div>
              <div className="md:w-4/12">
                <div className="p-3 shadow-md bg-white rounded-2xl">
                  <div className="text-xs font-bold">{t.project.view.pricing.addOn.hint.title}</div>
                  <div className="text-2xs mt-2">{t.project.view.pricing.addOn.hint.text}</div>
                </div>
              </div>
            </div>
          </>
        )}
      </GrayBox>
    </div>
  )
}

export default Pricing
