type Props = {
  minified?: boolean
}

const Separator: React.FC<Props> = ({ minified = false }) => {
  return (
    <div className="h-fit flex flex-col gap-1 my-1 items-center">
      {Array(3)
        .fill(0)
        .map((_, i) => (
          <div key={i} className={`${minified ? 'w-[3px] h-[3px]' : 'w-1 h-1'} bg-blue-primary rounded-full`}></div>
        ))}
    </div>
  )
}

export default Separator
