import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { Story as StoryType } from '../types'
import { PipelineComponent } from '../../../UI/Pipeline/types'
import toast, { TYPE } from '../../../../toast'
import Textarea from '../../../UI/Textarea/Textarea'
import SmallContainer from '../../../UI/Container/SmallContainer'
import SectionHeader from '../../../UI/Pipeline/SectionHeader'
import { maxArtistStoryLength, minArtistStoryLength } from '../../../../utility'

const Story: PipelineComponent<StoryType> = ({ pipelineStateProp, data, setData, NavigationForm, finalStepSubmit }) => {
  const t = useSelector((state) => state[props.TRANSLATION])

  const set = (prop: keyof StoryType) => (value: any) => setData({ ...data, [prop]: value })

  const getData = () => {
    if (data.story?.length >= minArtistStoryLength && data.story?.length <= maxArtistStoryLength) {
      return data
    } else {
      toast(TYPE.ERROR, t.register.artist.story.error)
    }
    return false
  }

  return (
    <SmallContainer>
      <SectionHeader headline={t.register.artist.story.headline} description={t.register.artist.story.description} />
      <NavigationForm pipelineStateProp={pipelineStateProp} getData={getData} finalStepSubmit={finalStepSubmit}>
        <div className="mt-7">
          <Textarea value={data.story} onChange={set('story')} minCharacters={minArtistStoryLength} maxCharacters={maxArtistStoryLength} />
        </div>
      </NavigationForm>
    </SmallContainer>
  )
}

export default Story
