import { useSelector } from 'react-redux'
import { Project } from '../../../types/Project'
import Full from './Full/Full'
import Single from './Single/Single'
import MinifiedList from './MinifiedList/MinifiedList'
import props from '../../../redux/props'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import { PurposeFlag } from '../../../types/Auth'
import useListMilestones from '../../hooks/useListMilestones'

export enum View {
  SINGLE = 'SINGLE',
  FULL = 'FULL',
  MINIFIED_LIST = 'MINIFIED_LIST',
}

type Props = {
  project: Project
  view?: View
  isAddOnCallback?: (status: boolean) => void
}

const Milestones: React.FC<Props> = ({ project, view = View.FULL, isAddOnCallback }) => {
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])
  const [milestones, setMilestones] = useListMilestones(project.uuid)

  const isArtist = () => accountLink.purposeFlag === PurposeFlag.ARTIST

  if (!accountLink || !milestones) return null
  if (view === View.SINGLE)
    return (
      <Single project={project} milestones={milestones} isArtist={isArtist()} isAddOnCallback={isAddOnCallback} updateListCallback={setMilestones} />
    )
  if (view === View.MINIFIED_LIST) return <MinifiedList project={project} milestones={milestones} isArtist={isArtist()} />
  return <Full project={project} milestones={milestones} isArtist={isArtist()} updateListCallback={setMilestones} />
}

export default Milestones
