import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import NewInput from '../../../UI/NewInput/NewInput'
import Checkbox from '../../../UI/Checkbox/Checkbox'
import toast, { TYPE } from '../../../../toast'
import { s3PublicStoragePrefix, tldList } from '../../../../utility'
import { PersonalDetail as PersonalDetailType } from '../types'
import { GetData, PipelineComponent } from '../../../UI/Pipeline/types'

import SmallContainer from '../../../UI/Container/SmallContainer'
import Routes from '../../../../redux/routes'
import { functional } from '@think-internet/zeus-frontend-package'
import SectionHeader from '../../../UI/Pipeline/SectionHeader'

enum AvailabilityErrorResponses {
  USERNAME = 'USERNAME',
  MAIL = 'MAIL',
}

const PersonalDetail: PipelineComponent<PersonalDetailType> = ({ pipelineStateProp, data, setData, NavigationForm, finalStepSubmit }) => {
  const t = useSelector((state) => state[props.TRANSLATION])
  const checkUsernameAndMailAvailability = functional.use(Routes.CHECK_USERNAME_AND_MAIL_AVAILABILITY)

  const set = (prop: keyof PersonalDetailType) => (value: any) => setData({ ...data, [prop]: value })

  const usernameIncludesTLD = (username: string) => {
    if (!username || username.length === 0) return false
    const usernameSplit = username.split('.')
    const result = usernameSplit.length > 1 && usernameSplit[1] && tldList.includes(`.${usernameSplit[1]}`)
    if (result) {
      toast(TYPE.ERROR, t.register.artist.personalDetail.usernameIncludesTLD)
    }
    return result
  }

  const validateUsername = (value: string) => {
    const username = value.trim().replace(/[^0-9a-z_]/gi, '')
    set('username')(username)
    usernameIncludesTLD(username)
  }

  const passwordCheck = () => {
    const status = data.password === data.repeatedPassword
    if (!status) {
      toast(TYPE.ERROR, t.register.artist.personalDetail.passwordsNotEqual)
    }
    return status
  }

  const getData: GetData = async () => {
    if (!usernameIncludesTLD(data.username) && passwordCheck()) {
      const response = await checkUsernameAndMailAvailability(data)
      if (response === true) return data
      if (response === AvailabilityErrorResponses.USERNAME) {
        toast(TYPE.ERROR, t.register.artist.personalDetail.usernameTaken)
      } else if (response === AvailabilityErrorResponses.MAIL) {
        toast(TYPE.ERROR, t.register.artist.personalDetail.mailTaken)
      }
    }
    return false
  }

  return (
    <SmallContainer>
      <SectionHeader headline={t.register.artist.personalDetail.headline} description={t.register.artist.personalDetail.description} />
      <NavigationForm pipelineStateProp={pipelineStateProp} getData={getData} finalStepSubmit={finalStepSubmit}>
        <div className="mt-7 flex gap-3 flex-col lg:w-7/12 mx-auto">
          <NewInput required value={data?.username} onChange={validateUsername} placeholder={t.generic.username} maxLength={17} />
          <NewInput required type="email" value={data?.mail} onChange={set('mail')} placeholder={t.generic.mail} />
          <NewInput required type="password" value={data?.password} onChange={set('password')} placeholder={t.generic.password} />
          {!!data?.password?.length && (
            <NewInput
              required
              type="password"
              value={data?.repeatedPassword}
              onChange={set('repeatedPassword')}
              placeholder={t.generic.repeatedPassword}
            />
          )}
          <Checkbox
            required
            checked={data.agb || false}
            onChange={set('agb')}
            label={t.generic.acceptAGB}
            linkSuffix={t.generic.acceptAGBSuffix}
            link={`${s3PublicStoragePrefix}agb/Q_AGB_28082024.pdf`}
            className="mt-3"
          />
          <Checkbox checked={data.declineNotifications || false} onChange={set('declineNotifications')} label={t.generic.declineNotifications} />
          <div className="text-xs text-gray-primary leading-[18px] ml-6">
            {t.register.artist.personalDetail.dataProtectionHint.prefix}
            <a
              href={t.register.artist.personalDetail.dataProtectionHint.link.href}
              target="_blank"
              rel="noreferrer"
              className="text-xs text-gray-primary underline"
            >
              {t.register.artist.personalDetail.dataProtectionHint.link.text}
            </a>
            {t.register.artist.personalDetail.dataProtectionHint.suffix}
          </div>
        </div>
      </NavigationForm>
    </SmallContainer>
  )
}

export default PersonalDetail
