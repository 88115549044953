import { Primer } from './Request'
import { Height, Length } from './Size'
import Location from './Location'
import { Booker } from './Booker'
import { Artist } from './Artist'
import { Rating } from './Rating'

export enum Status {
  PENDING_DOWNPAYMENT = 'PENDING_DOWNPAYMENT',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

export enum AddOnStatus {
  PENDING = 'PENDING',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  COMPLETED = 'COMPLETED',
}

export enum VerifiedBy {
  QSELLART = 'QSELLART',
  EXTERNAL = 'EXTERNAL',
}

export type NameAndLocation = {
  name?: string
  location?: Location
  existingRequestUUID?: string
}

export type Tags = {
  object: string
  surface: string
  equipment: string[]
  equipmentAvailable?: boolean
  style?: [string]
}

export type SizeAndPrimer = {
  sizeInSquareMeters: number
  size: {
    height: Height
    length: Length
  }
  primerRequired: Primer
}

export type Deadline = {
  date: Date
}

export type Design = {
  text?: string
  keys: string[]
  urls: string[]
}

export type PricingAdditional = {
  description: string
  price: number
}
export type Pricing = {
  artPerSquareMeter?: number
  primerPerSquareMeter?: number
  singleConcept?: number
  additional?: PricingAdditional
  discount?: number
  downPaymentPercentage?: number
  pricePerAttendee?: number
  netTotalAfterCompletion?: number
}

export type Attendee = {
  amount: number
  motto: string
}

export type Insurance = {
  accepted: boolean
  radius?: number
  pricePercentage?: number
  timespanInMonths?: number
  timeExpenseInHours?: number
}

export type Verification = {
  verifiedBy: VerifiedBy
  verifiedByClientName?: string
  verifiedByClientMail?: string
  isVerifiedByClient?: boolean
  mailText?: string
  mailSentOn?: Date

  // dynamic param
  verifiedByDomain?: string
}

export type Project = {
  status?: string
  isMyProject?: boolean
  isFavorite?: boolean
  favoriteCount?: number
  uuid?: string
  bookerUUID?: string
  artistUUID?: string
  nameAndLocation?: NameAndLocation
  tags?: Tags
  sizeAndPrimer?: SizeAndPrimer
  pricing?: Pricing
  deadline?: Deadline
  design?: Design
  attendee?: Attendee
  insurance?: Insurance
  verification?: Verification
  createdOn?: Date
  addOns?: AddOn[]
  booker?: Pick<Booker, 'billingDetail' | 'profileImages'>
  artist?: Pick<Artist, 'personalDetail' | 'profileImages'>
  rating?: Rating
  finalizedOn?: Date
  isVisible?: boolean
}

export type AddOn = {
  uuid?: string
  projectUUID?: string
  status?: AddOnStatus
  conceptAdded?: boolean
  primerAdded?: boolean
  insuranceAdded?: boolean
  additionalSquareMeter?: number
  additionalAttentees?: number
  additionalOtherCosts?: number
  additionalOtherCostsDescription?: string
  price?: number
}
