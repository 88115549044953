import Pipeline from '../../../UI/Pipeline/Pipeline'
import ComponentMappingArtist from './ComponentMappingArtist'
import ComponentMappingGlobal from './ComponentMappingGlobal'
import Routes from '../../../../redux/routes'
import { TYPES } from '../../../UI/Pipeline/types'
import { Artist } from '../../../../types/Artist'
import { Request } from '../../../../types/Request'
import Success from '../StepComponents/Success/Success'

type Props = { artist?: Artist }

const Default: React.FC<Props> = ({ artist }) => {
  if (!!artist)
    return (
      <Pipeline<Request, { artist: Artist }>
        type={TYPES.ARTIST_DEFAULT_REQUEST}
        componentMapping={ComponentMappingArtist(artist)}
        finalSubmitFunctionalRoute={Routes.CREATE_REQUEST}
        SuccessComponent={Success}
        additionalData={{ artist }}
      />
    )
  return (
    <Pipeline<Request>
      type={TYPES.GLOBAL_DEFAULT_REQUEST}
      componentMapping={ComponentMappingGlobal}
      finalSubmitFunctionalRoute={Routes.CREATE_REQUEST}
      SuccessComponent={Success}
    />
  )
}

export default Default
