import { useSelector } from 'react-redux'
import { Primer } from '../../../../types/Request'
import { formatPrice, getPercentageOfPrice, getTotalPriceOfProject } from '../../../../utility'
import props from '../../../../redux/props'
import Property from '../../PropertyList/Property'
import { Project } from '../../../../types/Project'
import _ from 'lodash'

type Props = {
  project: Project
  className?: string
}

const Default: React.FC<Props> = ({ project, className = '' }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const getTotalPrice = (noInsurance?: boolean) => getTotalPriceOfProject(project, noInsurance)

  const primerEnabled = project.sizeAndPrimer.primerRequired === Primer.YES

  const additionalPriceIsSet =
    _.has(project, 'pricing.additional.price') && isFinite(project.pricing.additional.price) && project.pricing.additional.price > 0
  const discountPriceIsSet = _.has(project, 'pricing.discount') && isFinite(project.pricing.discount) && project.pricing.discount > 0

  return (
    <div className={className}>
      <Property
        property={{
          name: `${t.priceCalculator.art}`,
          valuePrefix: `${formatPrice(project.pricing.artPerSquareMeter)}${t.generic.multiply}${project.sizeAndPrimer.sizeInSquareMeters}${
            t.generic.squareMeter
          }`,
          value: formatPrice(project.pricing.artPerSquareMeter * project.sizeAndPrimer.sizeInSquareMeters),
        }}
      />
      {primerEnabled && (
        <Property
          property={{
            name: `${t.priceCalculator.primer} ${formatPrice(project.pricing.primerPerSquareMeter)}${t.generic.multiply}${
              project.sizeAndPrimer.sizeInSquareMeters
            }${t.generic.squareMeter}`,
            value: formatPrice(project.pricing.primerPerSquareMeter * project.sizeAndPrimer.sizeInSquareMeters),
          }}
        />
      )}
      {additionalPriceIsSet && (
        <>
          <Property
            className="mt-5"
            property={{
              name: t.priceCalculator.additionalCosts.label,
              value: formatPrice(project.pricing.additional.price),
            }}
          />
          {!!project.pricing.additional.description && <div className="text-sm text-gray-primary">{project.pricing.additional.description}</div>}
        </>
      )}
      {discountPriceIsSet && (
        <Property
          className="mt-5"
          property={{
            name: t.priceCalculator.discountAmount.label,
            value: formatPrice(project.pricing.discount),
          }}
        />
      )}
      {project.insurance?.accepted && (
        <Property
          className="mt-5"
          property={{
            name: t.priceCalculator.insurance.label,
            value: formatPrice(getPercentageOfPrice(getTotalPrice(true), project.insurance.pricePercentage)),
          }}
        />
      )}
      <Property
        className="mt-5"
        property={{
          name: t.priceCalculator.total,
          value: formatPrice(getTotalPrice()),
        }}
      />
      <Property
        className="mt-5"
        property={{
          name: `${t.priceCalculator.downPayment} ${project.pricing.downPaymentPercentage}${t.generic.percent}`,
          value: formatPrice(getPercentageOfPrice(getTotalPrice(), project.pricing.downPaymentPercentage)),
        }}
      />
      <Property
        property={{
          name: `${t.priceCalculator.finalPayment} ${100 - project.pricing.downPaymentPercentage}${t.generic.percent}`,
          value: formatPrice(getPercentageOfPrice(getTotalPrice(), 100 - project.pricing.downPaymentPercentage)),
        }}
      />
    </div>
  )
}

export default Default
