export enum Category {
  OBJECT = 'OBJECT',
  SURFACE = 'SURFACE',
  EQUIPMENT = 'EQUIPMENT',
  STYLE = 'STYLE',
}

type AdditionalTags = {
  [key: string]: Tag
}

export const selectedObjectWhitelist = ['AUSSENWAND', 'INNENWAND', 'LEINWAND', 'ÖFFENTLICH', 'GEWERBE', 'GARAGE', 'OBJEKT', 'WERBUNG']

export const additionalTags: AdditionalTags = {
  OTHER: {
    name: 'Sonstiges',
    enum: 'OTHER',
  },
  NO_EQUIPMENT_REQUIRED: {
    name: 'Keine',
    enum: 'KEINE',
  },
  UNSURE: {
    name: 'Unsicher',
    enum: 'UNSURE',
  },
}

export const additionalTagsArray = Object.values(additionalTags)

export type Tag = {
  uuid?: string
  name?: string
  enum?: string
  category?: string
}
