import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { Deadline as DeadlineType } from '../../../../../types/Project'
import { GetData, PipelineComponent } from '../../../../UI/Pipeline/types'
import SectionHeader from '../../../../UI/Pipeline/SectionHeader'
import NewInput from '../../../../UI/NewInput/NewInput'
import toast, { TYPE } from '../../../../../toast'
import ArtistPriceCalculator from '../ArtistPriceCalculator/ArtistPriceCalculator'
import CalendarSmall from '../../../../UI/Calendar/Small/Small'
import _ from 'lodash'

const Deadline: PipelineComponent<DeadlineType> = ({ pipelineStateProp, data, setData, NavigationForm, finalStepSubmit }) => {
  const t = useSelector((state) => state[props.TRANSLATION])
  const pipelineState = useSelector((state) => state[props.PIPELINE])

  const set = (prop: keyof DeadlineType) => (value: any) => setData({ ...data, [prop]: value })

  const getData: GetData = () => {
    const date = new Date(data.date)

    if (date.toString() === 'Invalid Date' || date < new Date()) {
      toast(TYPE.ERROR, t.request.create.deadline.error)
      return false
    }
    return data
  }

  console.log(pipelineState.data)

  const isWorkshop =
    _.has(pipelineState, 'data.attendee.amount') && isFinite(pipelineState.data.attendee.amount) && pipelineState.data.attendee.amount > 0

  return (
    <div>
      <ArtistPriceCalculator />
      <NavigationForm pipelineStateProp={pipelineStateProp} getData={getData} finalStepSubmit={finalStepSubmit}>
        {!isWorkshop && <SectionHeader headline={t.request.create.deadline.title} description={t.request.create.deadline.description} />}
        {isWorkshop && <SectionHeader headline={t.request.create.deadline.title} description={t.request.create.deadline.descriptionWorkshop} />}
        <div className="mt-5">
          <CalendarSmall value={data.date ? new Date(data.date) : new Date()} onChange={set('date')} />
        </div>
        <NewInput
          type="date"
          className="max-w-xs w-36 mx-auto mt-5"
          value={data.date}
          onChange={set('date')}
          placeholder={t.request.create.deadline.title}
        />
      </NavigationForm>
    </div>
  )
}

export default Deadline
