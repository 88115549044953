import ElasticCarousel from 'react-elastic-carousel'
import prev from '../../../assets/image/carousel/prev.png'
import next from '../../../assets/image/carousel/next.png'
import './style.css'
import NewModal from '../NewModal/NewModal'
import { useState } from 'react'
import Button from '../NewButton/NewButton'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import useIsBreakpoint, { Breakpoint } from '../../hooks/useIsBreakpoint'

type Props = {
  items?: React.ReactNode[]
  images?: string[]
  itemsToShow?: number
  itemsToShowMobile?: number
  gap?: number
  className?: string
  customPrevArrow?: React.ReactNode
  customNextArrow?: React.ReactNode
  initIndex?: number
  smallArrows?: boolean
}

const Carousel: React.FC<Props> = ({
  items,
  images,
  itemsToShow,
  itemsToShowMobile,
  gap = 10,
  className = '',
  initIndex = 0,
  customPrevArrow,
  customNextArrow,
  smallArrows,
}) => {
  const [lightboxIndex, setLightboxIndex] = useState<number>(null)
  const t = useSelector((s) => s[props.TRANSLATION])
  const isMobileView = useIsBreakpoint(Breakpoint.SM)
  const breakpoints = [
    {
      width: 0,
      itemsToShow: itemsToShowMobile || 1,
    },
    {
      width: 750,
      itemsToShow: itemsToShow || 3,
    },
  ]
  const arrows = ({ type, onClick }) => {
    const isPrev = type === 'PREV'

    const pointer = isPrev ? prev : next

    if (customPrevArrow && isPrev) {
      return (
        <div className="absolute h-full flex items-center top-0 left-0 z-10">
          <div className="h-fit cursor-pointer -ml-[50%]" onClick={onClick}>
            {customPrevArrow}
          </div>
        </div>
      )
    }
    if (customNextArrow && !isPrev) {
      return (
        <div className="absolute h-full flex items-center top-0 right-0 z-10">
          <div className="h-fit cursor-pointer shrink-0 ml-[50%]" onClick={onClick}>
            {customNextArrow}
          </div>
        </div>
      )
    }
    return (
      <div className={`absolute z-10 h-full flex items-center top-0 ${isPrev ? '-left-2 lg:-left-5' : '-right-2 lg:-right-5'}`}>
        <img className={`${smallArrows ? 'w-[14px]' : 'w-5'} cursor-pointer`} onClick={onClick} src={pointer} alt={type} />
      </div>
    )
  }

  const getFillerChildren = () => {
    const internalItemsToShow = itemsToShow || 3

    const internalItems = items || images

    if (!!internalItems && internalItems.length < internalItemsToShow) {
      return Array(internalItemsToShow - 1)
        .fill(null)
        .map((_, i) => <div key={i} className="w-full" />)
    }
    return null
  }

  const getLightboxItems = () => {
    return images.map((image, i) => (
      <div
        key={i}
        className="w-full h-[300px] md:h-[400px] items-center justify-center bg-center bg-contain bg-no-repeat"
        style={{ backgroundImage: `url(${image})` }}
      ></div>
    ))
  }

  return (
    <div className="px-3">
      <ElasticCarousel
        className={`custom-carousel relative ${className}`}
        breakPoints={breakpoints}
        isRTL={false}
        renderArrow={arrows}
        pagination={false}
        itemPadding={[0, gap]}
        autoPlaySpeed={5000}
        initialActiveIndex={initIndex}
      >
        {Array.isArray(images) &&
          images.map((image, i) => (
            <div
              key={i}
              className="aspect-video w-full items-center justify-center bg-center bg-cover bg-no-repeat rounded-md cursor-pointer border border-gray-secondary"
              style={{ backgroundImage: `url(${image})` }}
              onClick={() => setLightboxIndex(i)}
            ></div>
          ))}
        {Array.isArray(items) && items}
        {getFillerChildren()}
      </ElasticCarousel>
      {Array.isArray(images) && (
        <NewModal large show={lightboxIndex !== null} noClose onClose={() => setLightboxIndex(null)}>
          <Carousel items={getLightboxItems()} gap={isMobileView ? 20 : 15} initIndex={lightboxIndex} itemsToShow={1} />
          <Button text={t.generic.close} className="btn-secondary w-fit mx-auto mt-6" onClick={() => setLightboxIndex(null)} />
        </NewModal>
      )}
    </div>
  )
}

export default Carousel
