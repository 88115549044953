import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { ArtistListItem, Request } from '../../../../../types/Request'
import { calculateSquareMeters, getPrettyDate, prettyLocation, s3PublicStoragePrefix } from '../../../../../utility'
import SectionHeader from '../../../../UI/Pipeline/SectionHeader'
import PropertyList from '../../../../UI/PropertyList/PropertyList'
import Property from '../../../../UI/PropertyList/Property'
import { Property as PropertyType } from '../../../../UI/PropertyList/types'
import Pricing from './Pricing'
import { PipelineState } from '../../../../UI/Pipeline/types'
import Carousel from '../../../../UI/Carousel/Carousel'
import useIsBreakpoint, { Breakpoint } from '../../../../hooks/useIsBreakpoint'
import { Tag, additionalTags, additionalTagsArray } from '../../../../../types/Tag'

type Props = {
  state: PipelineState<Request, { artist: ArtistListItem }>
}

const Properties: React.FC<Props> = ({ state }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const tags = useSelector((s) => s[props.TAG])
  const isMobile = useIsBreakpoint(Breakpoint.SM)

  const getTagName = (tagEnum: string) => {
    const tag = [...tags, ...additionalTagsArray].find((t: Tag) => t.enum === tagEnum)
    return tag ? tag.name : ''
  }

  const showEquipmentAvailable = () => {
    if (state.data.tags.equipment.includes(additionalTags.UNSURE.enum) && state.data.tags.equipment.length === 1) {
      return false
    }
    return !state.data.tags.equipment.includes(additionalTags.NO_EQUIPMENT_REQUIRED.enum)
  }

  const getEquipmentValue = () => {
    if (state.data.tags.equipment.includes(additionalTags.UNSURE.enum)) {
      if (state.data.tags.equipment.length === 1) {
        return t.request.create.summary.default.properties.equipmentUnsure
      }
      return (
        state.data.tags.equipment
          .filter((e) => e !== additionalTags.UNSURE.enum)
          .map((e) => getTagName(e))
          .join(', ') + ` (${t.request.create.summary.default.properties.equipmentUnsure})`
      )
    }
    return state.data.tags.equipment.map((e) => getTagName(e)).join(', ')
  }

  const getPropertyList = (): PropertyType[] => {
    const propertyList: PropertyType[] = [
      { name: t.request.create.summary.default.properties.title, value: state.data.nameAndLocation.name },
      { name: t.request.create.summary.default.properties.location, value: prettyLocation(state.data.nameAndLocation.location) },
      { name: t.request.create.summary.default.properties.object, value: getTagName(state.data.tags.object) },
      { name: t.request.create.summary.default.properties.surface, value: getTagName(state.data.tags.surface) },
      { name: t.request.create.summary.default.properties.equipment, value: getEquipmentValue() },
    ]
    return propertyList
  }

  return (
    <div className="flex flex-col gap-0 mt-6">
      <PropertyList properties={getPropertyList()} />
      {showEquipmentAvailable() && (
        <Property
          property={{
            name: t.request.create.summary.default.properties.equipmentAvailable.name,
            value: state.data.tags.equipmentAvailable
              ? t.request.create.summary.default.properties.equipmentAvailable.YES
              : t.request.create.summary.default.properties.equipmentAvailable.NO,
          }}
        />
      )}
      <Property
        property={{
          name: t.request.create.summary.default.properties.size,
          value: `${calculateSquareMeters(state.data.sizeAndPrimer.size.length, state.data.sizeAndPrimer.size.height)}${t.generic.squareMeter}`,
        }}
      />
      {(!state.data.sizeAndPrimer.primerRequired || (!!state.additionalData.artist && !state.additionalData.artist.pricing.primer.disabled)) && (
        <Property
          property={{
            name: t.request.create.summary.default.properties.primer,
            value: t.request.primerOptions[state.data.sizeAndPrimer.primerRequired],
          }}
        />
      )}
      {!!state.additionalData.artist && state.additionalData.artist.insuranceDetail.enabled && !!state.additionalData.artist && (
        <Property
          property={{
            name: t.request.create.summary.default.properties.insurance.name,
            value: state.additionalData.artist.insuranceAcceptedByBooker
              ? t.request.create.summary.default.properties.insurance.YES
              : t.request.create.summary.default.properties.insurance.NO,
          }}
        />
      )}
      <Property
        property={{
          name: t.request.create.summary.default.properties.deadlineDate,
          value: getPrettyDate(new Date(state.data.deadline.date)),
        }}
      />
      <SectionHeader headline={t.request.create.summary.default.design.title} />
      <div className="text-gray-primary my-2 text-sm break-words mt-6">{state.data.design.text}</div>
      <SectionHeader
        headline={t.request.create.summary.default.design.titleImages}
        description={`${t.request.create.summary.default.design.imagePrefix}${state.data.design.keys.length}`}
        italicSubline
      />
      <Carousel
        className="mt-6"
        itemsToShow={2}
        itemsToShowMobile={2}
        smallArrows={isMobile}
        images={state.data.design.keys.map((key) => `${s3PublicStoragePrefix}${key}`)}
      />
      <Pricing state={state} />
    </div>
  )
}

export default Properties
