import { useSelector } from 'react-redux'
import MediumContainer from '../../../UI/Container/MediumContainer'
import Headline from '../../../UI/Headline/Headline'
import props from '../../../../redux/props'
import DataSectionWrapper from '../../Components/DataSection/DataSectionWrapper'
import StartProject from './StartProject/StartProject'
import Calendar from './Calendar/Calendar'
import Favorites from './Favorites/Favorites'
import Recommendation from './Recommendation/Recommendation'

const Dashboard: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <MediumContainer className="py-3">
      <Headline className="mb-3">{t.booker.dashboard.title}</Headline>
      <div className="flex flex-col gap-3">
        <DataSectionWrapper>
          <StartProject />
        </DataSectionWrapper>
        <DataSectionWrapper>
          <Calendar />
        </DataSectionWrapper>
        <DataSectionWrapper>
          <Favorites />
        </DataSectionWrapper>
        <DataSectionWrapper>
          <Recommendation />
        </DataSectionWrapper>
      </div>
    </MediumContainer>
  )
}

export default Dashboard
