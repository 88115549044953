import { Milestone, MilestoneType, MilestonesWithBookerAcceptance } from '../../../../types/Milestone'
import NewModal from '../../../UI/NewModal/NewModal'
import { UpdateListCallback } from '../types'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { useState } from 'react'
import Routes from '../../../../redux/routes'
import { functional } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../../../toast'
import checked from '../../../../assets/image/milestone/checked.png'
import unchecked from '../../../../assets/image/milestone/unchecked.png'
import Loading from '../../Loading/Loading'
import useReadNotification from '../../../hooks/useReadNotification'
import { Type } from '../../../../types/Notification'
import { Project } from '../../../../types/Project'

type Props = {
  milestone: Milestone
  isArtist: boolean
  updateListCallback: UpdateListCallback
  hasOpenAddOn: boolean
  project: Project
  milestones: Milestone[]
}

const Accept: React.FC<Props> = ({ milestone, milestones, isArtist, updateListCallback, hasOpenAddOn, project }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState(false)
  const [accepted, setAccepted] = useState<boolean>(isArtist ? milestone.acceptedByArtist : milestone.acceptedByBooker)
  const upsertMilestone = functional.use(Routes.UPSERT_MILESTONE_OF_PROJECT)
  const [processing, setProcessing] = useState<boolean>(false)
  const read = useReadNotification()

  const setCompleted = () => {
    setAccepted(true)
    setOpen(true)
  }

  const close = () => {
    setAccepted(!accepted)
    setOpen(false)
  }

  const confirm = async () => {
    setProcessing(true)
    const updatedMilestone = { ...milestone }
    if (isArtist) {
      updatedMilestone.acceptedByArtist = accepted
    } else {
      updatedMilestone.acceptedByBooker = accepted
    }
    const milestones: Milestone[] = await upsertMilestone(updatedMilestone)
    setProcessing(false)
    if (!!milestones) {
      toast(TYPE.SUCCESS, t.milestone.accept.success)
      updateListCallback(milestones)
      if (updatedMilestone.type === MilestoneType.PROJECT_COMPLETED && updatedMilestone.acceptedByBooker === true) {
        read({
          type: Type.PROJECT_COMPLETION,
          payload: { projectUUID: milestone.projectUUID },
        })
      }
      setOpen(false)
    } else {
      toast(TYPE.ERROR, t.milestone.accept.error)
    }
  }

  const getTooltip = () => {
    if (isArtist) {
      return milestoneAccepted ? t.milestone.accept.tooltip.yesArtist : t.milestone.accept.tooltip.no
    } else {
      if (milestoneAccepted) {
        if (milestone.type === MilestoneType.COMPLETE_FINAL_PAYMENT) {
          return t.milestone.accept.tooltip.yesBookerFinalPayment
        }
        return t.milestone.accept.tooltip.yesBooker
      }
      return t.milestone.accept.tooltip.no
    }
  }

  const milestoneAccepted = isArtist ? milestone.acceptedByArtist : milestone.acceptedByBooker

  const showProjectInProgressBlockScreen = () => {
    if (milestone.type !== MilestoneType.PROJECT_COMPLETED) return false
    const projectInProgressMilestone = milestones.find((m) => m.type === MilestoneType.PROJECT_IN_PROGRESS)
    if (!projectInProgressMilestone) return false
    return !projectInProgressMilestone.isCompleted
  }

  const showCompleteFinalPaymentBlockScreen = () => {
    if (milestone.type !== MilestoneType.PROJECT_IN_PROGRESS) return false
    const completeFinalPaymentMilestone = milestones.find((m) => m.type === MilestoneType.COMPLETE_FINAL_PAYMENT)
    if (!completeFinalPaymentMilestone) return false
    return !completeFinalPaymentMilestone.isCompleted
  }

  const showProjectCompletedBlockScreen = () => {
    if (isArtist) return false
    if (milestone.type !== MilestoneType.PROJECT_COMPLETED) return false
    if (showProjectInProgressBlockScreen()) return false
    return !milestone.acceptedByArtist
  }

  const isCompleteFinalPaymentMilestone = milestone.type === MilestoneType.COMPLETE_FINAL_PAYMENT
  const isProjectCompletedMilestone = milestone.type === MilestoneType.PROJECT_COMPLETED

  const isVisible = () => {
    if (isArtist) return true
    if (MilestonesWithBookerAcceptance.includes(milestone.type)) {
      if (milestone.type === MilestoneType.PROJECT_COMPLETED) return true
      if (milestone.acceptedByArtist) return true
      return false
    }
    return false
  }

  const isCurrentMilestone = () => {
    const uuid = milestone.uuid
    const currentMilestone = milestones.find((m) => !m.isCompleted)
    return uuid === currentMilestone?.uuid
  }

  const getTitle = () => {
    if (isArtist) {
      if (milestone.type === MilestoneType.PROJECT_COMPLETED) return t.milestone.accept.titleProjectCompletedArtist
    } else {
      if (milestone.type === MilestoneType.COMPLETE_FINAL_PAYMENT) return t.milestone.accept.titleCompleteFinalPaymentBooker
      if (milestone.type === MilestoneType.PROJECT_COMPLETED) return t.milestone.accept.titleProjectCompletedBooker
    }
    return t.milestone.accept.title
  }

  if (!isVisible()) return <></>
  return (
    <>
      <div className="tooltip" data-tip={getTooltip()}>
        {!isArtist && isCurrentMilestone() && !milestone.acceptedByBooker && (
          <div className={`absolute -right-[2px] -top-[2px] bg-magenta-primary rounded-full h-2 aspect-square grow-0 shrink-0`}></div>
        )}
        <img
          src={milestoneAccepted ? checked : unchecked}
          onClick={milestoneAccepted ? () => {} : setCompleted}
          className={`h-6 ${!milestoneAccepted && 'cursor-pointer'}`}
          alt=""
        />
      </div>
      <NewModal
        show={open}
        onClose={close}
        cancelButtonText={t.generic.cancel}
        onConfirm={
          !processing && !showProjectInProgressBlockScreen() && !showCompleteFinalPaymentBlockScreen() && !showProjectCompletedBlockScreen()
            ? confirm
            : null
        }
        noForm
      >
        <div className="font-bold">{getTitle()}</div>
        <Loading loading={!processing} />
        {!processing && (
          <>
            {!showCompleteFinalPaymentBlockScreen() && !showProjectInProgressBlockScreen() && !showProjectCompletedBlockScreen() && (
              <>
                {isArtist && accepted && (
                  <>
                    {!isCompleteFinalPaymentMilestone && !isProjectCompletedMilestone && (
                      <div className="mt-2">{t.milestone.accept.yes.textArtist}</div>
                    )}
                    {isCompleteFinalPaymentMilestone && <div className="mt-2">{t.milestone.accept.yes.textArtistForCompleteFinalPayment}</div>}
                    {hasOpenAddOn && milestone.type === MilestoneType.PROJECT_COMPLETED && (
                      <div className="mt-2 font-bold">{t.milestone.accept.yes.textAritstForOpenAddOn}</div>
                    )}
                    {isProjectCompletedMilestone &&
                      t.milestone.accept.yes.textArtistForProjectCompletion.map((t, i) => (
                        <div className="mt-2" key={i}>
                          {t}
                        </div>
                      ))}
                  </>
                )}
                {isArtist && !accepted && <div className="mt-2">{t.milestone.accept.no.textArtist}</div>}
                {!isArtist && !isCompleteFinalPaymentMilestone && !isProjectCompletedMilestone && (
                  <div className="mt-2">{t.milestone.accept.yes.textBooker}</div>
                )}
                {!isArtist && isCompleteFinalPaymentMilestone && (
                  <div className="mt-2">{t.milestone.accept.yes.textBookerForCompleteFinalPayment}</div>
                )}
                {!isArtist && isProjectCompletedMilestone && milestone.acceptedByArtist && (
                  <div className="mt-2">{t.milestone.accept.yes.textBookerForProjectCompleted}</div>
                )}
              </>
            )}
            {showProjectInProgressBlockScreen() && (
              <>
                {isArtist
                  ? t.milestone.accept.blockScreenForInProgress.artist
                  : t.milestone.accept.blockScreenForInProgress.booker.map((t, i) => (
                      <div className="mt-3" key={i}>
                        {t}
                      </div>
                    ))}
              </>
            )}
            {showCompleteFinalPaymentBlockScreen() && (
              <>
                {isArtist
                  ? t.milestone.accept.blockScreenForFinalPayment.artist
                  : t.milestone.accept.blockScreenForFinalPayment.booker.map((t, i) => (
                      <div className="mt-3" key={i}>
                        {t}
                      </div>
                    ))}
              </>
            )}
            {showProjectCompletedBlockScreen() && (
              <>
                {t.milestone.accept.blockScreenForProjectCompleted.booker.map((t, i) => (
                  <div className="mt-3" key={i}>
                    {t}
                  </div>
                ))}
              </>
            )}
          </>
        )}
      </NewModal>
    </>
  )
}

export default Accept
