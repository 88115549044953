import { useEffect } from 'react'
import props from '../../../redux/props'
import { useDispatch, useSelector } from 'react-redux'
import { setLocal } from '../../../redux/action/local'
import { PipelineState } from './types'

type InitState<D, A> = Pick<PipelineState<D, A>, 'type' | 'data' | 'additionalData'>

const Init = <D, A>({ type, additionalData, data }: InitState<D, A>) => {
  const state: PipelineState<A> = useSelector((s) => s[props.PIPELINE])
  const dispatch = useDispatch()

  useEffect(() => {
    const initBody: PipelineState<D, A> = { type, currentIndex: 0, data }
    if (typeof additionalData === 'object') {
      initBody.additionalData = additionalData
    } else {
      initBody.additionalData = {} as A
    }
    if (state) {
      if (state.type !== type) {
        dispatch(setLocal(props.PIPELINE, initBody))
      }
    } else {
      dispatch(setLocal(props.PIPELINE, initBody))
    }
  }, [state, dispatch, type, additionalData, data])

  return null
}

export default Init
