import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { Chat } from '../../../../../types/Chat'
import Headline from '../../../../UI/Headline/Headline'
import MinifiedMilestones, { View } from '../../../../UI/Milestones/Milestones'
import { FullHeightBody } from '../Chat'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import { PurposeFlag } from '../../../../../types/Auth'
import Placeholder from './Placeholder'

type Props = {
  chat: Chat | false
}

const Milestones: React.FC<Props> = ({ chat }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])

  const isArtist = accountLink.purposeFlag === PurposeFlag.ARTIST

  if (chat === false) {
    return (
      <div>
        <Headline className="h-20 shrink-0 text-gray-primary">{t.chat.milestones.headline}</Headline>
        <Placeholder />
      </div>
    )
  } else if (!chat) {
    return <></>
  } else {
    return (
      <a href={`/home/${isArtist ? 'artist' : 'booker'}/project/milestones/${chat.projectUUID}`}>
        <Headline className="h-20 shrink-0">{t.chat.milestones.headline}</Headline>
        <FullHeightBody reduceHeightBy={100}>
          <div className="pl-3">
            <MinifiedMilestones project={chat.project} view={View.MINIFIED_LIST} />
          </div>
        </FullHeightBody>
      </a>
    )
  }
}

export default Milestones
