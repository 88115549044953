import { useSelector } from 'react-redux'
import MediumContainer from '../../../UI/Container/MediumContainer'
import Headline from '../../../UI/Headline/Headline'
import Loading from '../../../UI/Loading/Loading'
import DataSectionWrapper from '../../Components/DataSection/DataSectionWrapper'
import Mail from './Components/Mail'
import Password from './Components/Password'
import Notification from './Components/Notification'
import Billing from './Components/Billing'
import Tax from './Components/Tax'
import Documents from './Components/Documents'
import BankAccount from './Components/BankAccount'
import props from '../../../../redux/props'
import useGetArtist from '../../../hooks/useGetArtist'
import { Artist } from '../../../../types/Artist'
import Progress from './Components/Progress'
import Username from './Components/Username'

const Settings: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [artist, setArtistProperty] = useGetArtist()

  const set = (prop: keyof Artist) => (value: any) => setArtistProperty(prop)(value)

  return (
    <MediumContainer className="py-3">
      <Headline className="mb-3">{t.artist.settings.title}</Headline>
      <Progress artist={artist} />
      <Loading loading={artist} />
      {artist && (
        <div className="flex flex-col gap-3">
          <DataSectionWrapper>
            <Billing artist={artist} onChangeCallback={set} />
            <BankAccount artist={artist} onChangeCallback={setArtistProperty('invoiceDetail')} />
          </DataSectionWrapper>
          <DataSectionWrapper>
            <Documents artist={artist} onChangeCallback={setArtistProperty('invoiceDetail')} />
            <Tax artist={artist} onChangeCallback={setArtistProperty('invoiceDetail')} />
          </DataSectionWrapper>
          <DataSectionWrapper>
            <Notification artist={artist} onChangeCallback={setArtistProperty('profileImages')} />
          </DataSectionWrapper>
          <DataSectionWrapper>
            <Mail artist={artist} />
            <Password artist={artist} />
          </DataSectionWrapper>
          <DataSectionWrapper>
            <Username artist={artist} onChangeCallback={setArtistProperty('personalDetail')} />
            <div className="md:w-1/2"></div>
          </DataSectionWrapper>
        </div>
      )}
    </MediumContainer>
  )
}

export default Settings
