import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { LocationDetail as LocationDetailType } from '../types'
import { PipelineComponent } from '../../../UI/Pipeline/types'

import LocationAutocomplete from '../../../UI/LocationAutocomplete/LocationAutocomplete'
import Button from '../../../UI/Button/Button'
import Checkbox from '../../../UI/Checkbox/Checkbox'
import toast, { TYPE } from '../../../../toast'
import SmallContainer from '../../../UI/Container/SmallContainer'
import SectionHeader from '../../../UI/Pipeline/SectionHeader'
import styled from 'styled-components'
import { getUUID } from '../../../../utility'

const ButtonWrapper = styled.div`
  width: calc(100% / 4 - 12px * 3 / 4);
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: calc(100% / 2 - 12px / 2);
  }
`

const LocationDetail: PipelineComponent<LocationDetailType> = ({ pipelineStateProp, data, setData, NavigationForm, finalStepSubmit }) => {
  const t = useSelector((state) => state[props.TRANSLATION])

  const set = (prop: keyof LocationDetailType) => (value: any) => setData({ ...data, [prop]: value })

  const setRadius = (radius: number) => () => setData({ ...data, radius, nationwide: false })

  const setNationwide = () => setData({ ...data, nationwide: true, radius: undefined })

  const getData = () => {
    if (!!data.location) {
      if (!!data.radius || data.nationwide) {
        return data
      } else {
        toast(TYPE.ERROR, t.register.artist.locationDetail.radius.error)
      }
    } else {
      toast(TYPE.ERROR, t.register.artist.locationDetail.autocomplete.error)
    }
    return false
  }

  return (
    <SmallContainer>
      <SectionHeader
        headline={t.register.artist.locationDetail.autocomplete.headline}
        description={t.register.artist.locationDetail.autocomplete.description}
      />
      <NavigationForm pipelineStateProp={pipelineStateProp} getData={getData} finalStepSubmit={finalStepSubmit}>
        <div className="mt-3">
          <LocationAutocomplete onChange={set('location')} label={t.generic.postcodeCity} initValue={data.location?.name} />
        </div>
        <SectionHeader
          headline={t.register.artist.locationDetail.radius.headline}
          description={t.register.artist.locationDetail.radius.description}
        />
        <div className="mt-7 flex flex-row flex-wrap gap-[12px]">
          {t.register.artist.locationDetail.radius.options.map((o: number) => (
            <ButtonWrapper key={getUUID()}>
              <Button className="w-full" onClick={setRadius(o)} inverted={data.radius !== o} text={`${o}${t.generic.km}`} key={o} />
            </ButtonWrapper>
          ))}
        </div>
        <div className="mt-7">
          <Checkbox checked={data.nationwide || false} onChange={setNationwide} label={t.generic.nationwide} />
        </div>
      </NavigationForm>
    </SmallContainer>
  )
}

export default LocationDetail
