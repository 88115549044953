import RatingStars from '../../../../UI/RatingStars/RatingStars'

type QuestionType = {
  value: number
  onChange: (value: number) => void
  label: string
}

const Question: React.FC<QuestionType> = ({ value, onChange, label }) => {
  return (
    <div className="flex flex-row justify-between items-center">
      <div className="text-gray-primary">{label}</div>
      <RatingStars rating={value} interactiveCallback={onChange} />
    </div>
  )
}

export default Question
