import ArtistHeaderMask from '../../../UI/Artist/HeaderMask/HeaderMask'
import MediumContainer from '../../../UI/Container/MediumContainer'
import useGetArtist from '../../../hooks/useGetArtist'
import Loading from '../../../UI/Loading/Loading'
import Location from './Components/Location'
import Skills from './Components/Skills'
import DataSectionWrapper from '../../Components/DataSection/DataSectionWrapper'
import Insurance from './Components/Insurance'
import Story from './Components/Story'
import DownPayment from './Components/DownPayment'
import Pricing from './Components/Pricing/Pricing'
import Portfolio from './Components/Portfolio'
import ExternalProjects from './Components/ExternalProjects/ExternalProjects'
import { ProfileImages } from '../../../../types/Artist'
import { crud } from '@think-internet/zeus-frontend-package'
import props from '../../../../redux/props'
import toast, { TYPE } from '../../../../toast'
import { useSelector } from 'react-redux'
import Headline from '../../../UI/Headline/Headline'
import Progress from '../Settings/Components/Progress'

const Profile: React.FC<{}> = () => {
  const [artist, setArtistProperty] = useGetArtist()
  const artistFeature = crud.use(props.ARTIST)
  const t = useSelector((s) => s[props.TRANSLATION])

  const updateImageKey = (key: keyof ProfileImages) => async (value: string) => {
    const newImages = { ...artist.profileImages, [key]: value }
    const response = await artistFeature.update({ uuid: artist.uuid, profileImages: newImages })
    if (!response) {
      toast(TYPE.ERROR, t.artist.profile.images.edit.error)
    } else {
      setArtistProperty('profileImages')(newImages)
    }
  }

  return (
    <MediumContainer className="py-3">
      <Headline className="mb-3">{t.artist.profile.title}</Headline>
      <Loading loading={artist} />
      {artist && (
        <div className="flex flex-col gap-3">
          <Progress artist={artist} />
          <div className="w-full">
            <ArtistHeaderMask
              artist={artist}
              personImageKeyCallback={updateImageKey('personImageKey')}
              backgroundImageKeyCallback={updateImageKey('backgroundImageKey')}
              addPrivateElements
            />
          </div>
          <DataSectionWrapper>
            <Location artist={artist} onChangeCallback={setArtistProperty('locationDetail')} />
            <Skills artist={artist} onChangeCallback={setArtistProperty('skills')} />
          </DataSectionWrapper>
          <DataSectionWrapper className="hidden md:block">
            <Insurance artist={artist} onChangeCallback={setArtistProperty('insuranceDetail')} />
          </DataSectionWrapper>
          <DataSectionWrapper>
            <Story artist={artist} onChangeCallback={setArtistProperty('story')} />
            <DownPayment artist={artist} onChangeCallback={setArtistProperty('pricing')} />
          </DataSectionWrapper>
          <DataSectionWrapper className="block md:hidden">
            <Insurance artist={artist} onChangeCallback={setArtistProperty('insuranceDetail')} />
          </DataSectionWrapper>
          <DataSectionWrapper>
            <Pricing artist={artist} onChangeCallback={setArtistProperty('pricing')} />
          </DataSectionWrapper>
          <DataSectionWrapper>
            <Portfolio artist={artist} onChangeCallback={setArtistProperty('portfolio')} />
          </DataSectionWrapper>
          <DataSectionWrapper>
            <ExternalProjects artist={artist} />
          </DataSectionWrapper>
        </div>
      )}
    </MediumContainer>
  )
}

export default Profile
