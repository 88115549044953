import { Payment } from './Payment'
import { Project } from './Project'

export enum InvoiceType {
  ARTIST_TO_BOOKER = 'ARTIST_TO_BOOKER',
  REVERSE_CHARGE_FOR_ARTIST = 'REVERSE_CHARGE_FOR_ARTIST',
}

export type Invoice = {
  uuid: string
  type: InvoiceType
  projectUUID: string
  paymentUUID: string
  bookerUUID: string
  artistUUID: string
  numberPrefix: string
  number: number
  fullNumber?: string
  payment: Payment
  project: Project
  pdfKey: string
  pdfURL: string
  previewImageKey: string
  previewImageURL: string
  isStorno: boolean
  createdOn: Date
}
