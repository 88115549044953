import { useSelector } from 'react-redux'
import GrayBox from '../../../../UI/Graybox/Graybox'
import props from '../../../../../redux/props'
import useListRecommendations from '../../../../hooks/useListRecommendations'
import RecommendationType, { Type } from '../../../../../types/Recommendation'
import Carousel from '../../../../UI/Carousel/Carousel'
import Project from '../../../../UI/Project/SmallCard/SmallCard'
import Artist from '../../../../UI/Artist/SmallCard/SmallCard'

const Recommendation: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const recommendations = useListRecommendations([Type.ARTIST, Type.PROJECT])

  const getItems = () =>
    recommendations.map((r: RecommendationType) => (
      <div key={r.artist ? r.artist.uuid : r.project.uuid} className="w-full pt-5">
        {!!r.artist && <Artist artist={r.artist} />}
        {!!r.project && <Project project={r.project} />}
      </div>
    ))

  if (!Array.isArray(recommendations) || recommendations.length === 0) return null
  return (
    <GrayBox title={t.booker.dashboard.recommendation.title} className="w-full" noTitlePadding>
      <Carousel items={getItems()} smallArrows />
    </GrayBox>
  )
}

export default Recommendation
