import { Project as ProjectType } from '../../../../types/Project'
import styled from 'styled-components'
import Favorite from '../../../Project/Favorite/Favorite'

const BackgroundImage = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
`

type Props = { project: ProjectType; initFavorite?: boolean }

const Project: React.FC<Props> = ({ project, initFavorite }) => {
  const link = `/project/view/${project.uuid}`

  return (
    <div className="w-full">
      <div className="h-20 relative">
        <a href={link} className="block w-full h-full">
          <BackgroundImage src={project.design.urls[0]} />
        </a>
        {typeof initFavorite === 'boolean' && <Favorite initIsFavorite={initFavorite} projectUUID={project.uuid} className="bottom-1 left-1" />}
      </div>
      <a href={link} className="block text-center truncate text-sm mt-1">
        {project.nameAndLocation.name}
      </a>
    </div>
  )
}

export default Project
