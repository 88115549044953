import styled from 'styled-components'

export const Point = styled.div<{ active?: boolean }>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${({ theme, active }) => (active ? theme.color.magenta.primary : theme.color.white)};
  border: 1px solid ${({ theme, active }) => (active ? theme.color.magenta.primary : theme.color.blue.primary)};
`
export const Line = styled.div<{ active?: boolean }>`
  width: 50px;
  height: 2px;
  background-color: ${({ theme, active }) => (active ? theme.color.magenta.primary : theme.color.blue.primary)};

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 20px;
  }
`
