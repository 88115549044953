import { Chat, Message as MessageType } from '../../../../../types/Chat'
import Compose from './Compose/Compose'
import Loading from '../../../../UI/Loading/Loading'
import Headline from '../../../../UI/Headline/Headline'
import useGetChatHistory from '../../../../hooks/useGetChatHistory'
import Message from './Message/Message'
import { FullHeightBody } from '../Chat'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { PurposeFlag } from '../../../../../types/Auth'
import { useEffect } from 'react'
import Placeholder from './Placeholder'
import useReadNotification from '../../../../hooks/useReadNotification'
import { Type } from '../../../../../types/Notification'
import useReadChatMessages from '../../../../hooks/useReadChatMessages'
import Single from '../Contacts/Single'
import styled from 'styled-components'
import { getPrettyDate } from '../../../../../utility'

type Props = {
  chat: Chat | false
  removeChatSelection: () => void
}

const MessageWrapper = styled.div`
  height: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: calc(100% - 150px);
  }
`

const Messages: React.FC<Props> = ({ chat, removeChatSelection }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [messages, addMessage] = useGetChatHistory(chat)
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])
  const readNotification = useReadNotification()
  const readChatMessages = useReadChatMessages(chat && chat?.projectUUID)

  const isArtist = accountLink?.purposeFlag === PurposeFlag.ARTIST

  useEffect(() => {
    if (chat && !!chat.projectUUID) {
      readNotification({ type: Type.NEW_CHAT_MESSAGE, payload: { projectUUID: chat.projectUUID } })
      if (Array.isArray(messages)) {
        readChatMessages(messages, accountLink)
      }
    }
  }, [messages, chat, readNotification, readChatMessages, accountLink])

  useEffect(() => {
    const wrapper = document.getElementById('chat-messages-wrapper')
    if (!wrapper) return
    setTimeout(() => {
      wrapper.scrollTop = wrapper.scrollHeight
    }, 0)
  }, [messages])

  const isNewDay = (message: MessageType, previousMessage: MessageType): boolean => {
    if (!previousMessage) return false
    const messageDate = new Date(message.createdOn)
    const previousMessageDate = new Date(previousMessage.createdOn)
    return messageDate.getDate() !== previousMessageDate.getDate()
  }

  return (
    <FullHeightBody className="flex flex-col md:mx-3">
      {chat && (
        <>
          <a className="hidden md:block" href={`/home/${isArtist ? 'artist' : 'booker'}/project/view/${chat.projectUUID}`}>
            <Headline className="whitespace-nowrap truncate h-20 shrink-0">{chat.project.nameAndLocation.name}</Headline>
          </a>
          <div className="block md:hidden">
            <Single chat={chat} setChat={() => {}} isActive={true} minifiedView removeChatSelection={removeChatSelection} />
          </div>
          <MessageWrapper id="chat-messages-wrapper" className="px-3 md:border-l md:border-r border-gray-primary overflow-x-hidden overflow-y-auto">
            <Loading loading={messages} />
            {messages &&
              messages.map((m, i) => {
                return (
                  <>
                    {isNewDay(m, messages[i - 1]) && (
                      <div className="text-center text-gray-primary-second text-xs my-2">{getPrettyDate(new Date(messages[i - 1].createdOn))}</div>
                    )}
                    <Message key={m.uuid} chat={chat} message={m} />
                  </>
                )
              })}
          </MessageWrapper>
          <div className="pb-5 md:pb-0">
            <Compose projectUUID={chat.projectUUID} addMessage={addMessage} />
          </div>
        </>
      )}
      {chat === false && (
        <>
          <Headline className="h-20 shrink-0 text-gray-primary">{t.chat.messages.headline}</Headline>
          <Placeholder />
        </>
      )}
    </FullHeightBody>
  )
}

export default Messages
