import styled from 'styled-components'

export const StyledTextarea = styled.textarea<{ borderColor?: string; margin?: string }>`
  border: ${({ borderColor, theme }) => (borderColor ? `thin solid ${borderColor}` : `thin solid ${theme.color.blue.primary}`)};
  color: ${({ theme }) => theme.color.blue.primary};
  background-color: ${({ theme }) => theme.color.white};
  padding: 5px 10px;
  font-size: ${({ theme }) => theme.font.size.sm};
  display: block;
  width: 100%;
  outline: none;
  resize: none;
  box-sizing: border-box;
  /* box-shadow: -1px 1px 8px -2px ${({ theme }) => theme.color.primary};
  -webkit-box-shadow: -1px 1px 8px -2px ${({ theme }) => theme.color.primary};
  -moz-box-shadow: -1px 1px 8px -2px ${({ theme }) => theme.color.primary}; */
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${({ theme }) => theme.color.blue.primary};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ theme }) => theme.color.blue.primary};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${({ theme }) => theme.color.blue.primary};
  }
`
