import { useParams } from 'react-router-dom'
import toast, { TYPE } from '../../../toast'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Routes from '../../../redux/routes'
import { functional } from '@think-internet/zeus-frontend-package'
import Loading from '../../UI/Loading/Loading'
import useGetProject from '../../hooks/useGetProject'
import DefaultProperties from '../View/DefaultProperties/DefaultProperties'
import WorkshopProperties from '../View/WorkshopProperties/WorkshopProperties'
import GridViewWithCarousel from '../../UI/GridViewWithCarousel/GridViewWithCarousel'
import _ from 'lodash'
import GetIcon from '../../UI/GetIcon/GetIcon'
import { s3PublicStoragePrefix } from '../../../utility'
import MediumContainer from '../../UI/Container/MediumContainer'
import Headline from '../../UI/Headline/Headline'
import Button from '../../UI/NewButton/NewButton'
import SmallContainer from '../../UI/Container/SmallContainer'
import ArtistCard from '../../Home/Booker/Request/ReverseOffer/ArtistCard'
import { Artist } from '../../../types/Artist'

const ExternalVerification = () => {
  const { projectUUID } = useParams()
  const t = useSelector((s) => s[props.TRANSLATION])
  const [loading, setLoading] = useState(true)
  const [verified, setVerified] = useState(false)
  const verify = functional.use(Routes.VERIFY_EXTERNAL_PROJECT)
  const [project] = useGetProject(projectUUID)

  useEffect(() => {
    if (project) {
      if (project.verification && project.verification.isVerifiedByClient) {
        setVerified(true)
      }
      setLoading(false)
    }
  }, [project])

  const submit = async () => {
    const status = await verify({ projectUUID })
    if (status) {
      setVerified(true)
    } else {
      toast(TYPE.ERROR, t.project.externalVerification.error)
    }
  }

  const isWorkshop = () => {
    return project && _.has(project, 'attendee.amount') && isFinite(project.attendee.amount)
  }

  return (
    <MediumContainer>
      <Loading loading={project} />
      {project && !loading && (
        <>
          <Headline>{project.nameAndLocation.name}</Headline>
          <SmallContainer className="mt-6 mb-6">
            <ArtistCard artist={project.artist as Artist} />
          </SmallContainer>
          <div className="pb-5 text-center">
            <div className="text-gray-primary">{t.project.externalVerification.hint}</div>
            {!verified && <Button className="w-fit mx-auto mt-2" text={t.project.externalVerification.cta} onClick={submit} />}
            {!!verified && <h2 className="text-xl text-gray-primary font-bold">{t.project.externalVerification.verified}</h2>}
          </div>
          <div className="rounded-md bg-gray-thirdary p-2 flex flex-row gap-10 justify-between">
            <div className="border border-gray-secondary bg-white rounded-md p-2 flex items-center w-full">
              {!isWorkshop() && <DefaultProperties project={project} />}
              {isWorkshop() && <WorkshopProperties project={project} />}
            </div>
            {project.rating && (
              <div className="border border-gray-secondary bg-white rounded-md py-2 px-3">
                <div className="text-sm">{t.project.search.view.rating.title}</div>
                <div className="flex flex-row gap-2 items-center justify-center">
                  <GetIcon name={'star-filled'} className={'h-6  fill-yellow-primary'} />
                  <div className="font-bold text-lg text-gray-primary">{project.rating.publicScore}</div>
                </div>
              </div>
            )}
          </div>
          <GridViewWithCarousel
            enableFullHeightContent
            className="mt-2"
            images={project.design.keys.map((key) => `${s3PublicStoragePrefix}${key}`)}
          ></GridViewWithCarousel>
        </>
      )}
    </MediumContainer>
  )
}

export default ExternalVerification
