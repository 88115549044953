import { useSelector } from 'react-redux'
import MediumContainer from '../../../../UI/Container/MediumContainer'
import Loading from '../../../../UI/Loading/Loading'
import props from '../../../../../redux/props'
import { useParams } from 'react-router-dom'
import useGetProject from '../../../../hooks/useGetProject'
import MilestonesFull, { View } from '../../../../UI/Milestones/Milestones'
import Headline from '../../../../UI/Headline/Headline'
import { PurposeFlag } from '../../../../../types/Auth'
import ArtistCard from '../../../Booker/Request/ReverseOffer/ArtistCard'
import { Artist } from '../../../../../types/Artist'
import SmallContainer from '../../../../UI/Container/SmallContainer'
import Button from '../../../../UI/NewButton/NewButton'
import Card from '../../../../UI/Booker/Card/Card'
import { Booker } from '../../../../../types/Booker'

const Milestones: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const { uuid } = useParams<{ uuid: string }>()
  const [project] = useGetProject(uuid)
  const accountLink = useSelector((s) => s[props.ACCOUNT_LINK])

  const isArtist = accountLink?.purposeFlag === PurposeFlag.ARTIST

  return (
    <MediumContainer className="py-3">
      <Loading loading={project} />
      {project && (
        <>
          <Headline className="!pb-0">{t.project.milestones.title}</Headline>
          {!isArtist && (
            <SmallContainer className="mt-6 mb-6">
              <ArtistCard artist={project.artist as Artist} />
            </SmallContainer>
          )}
          {!!isArtist && (
            <SmallContainer className="mt-6 mb-6">
              <Card booker={project.booker as Booker}></Card>
            </SmallContainer>
          )}
          <div className="flex gap-2 items-center justify-center text-sm text-blue-primary">
            {t.project.milestones.subtitlePrefix}
            {!isArtist && (
              <Button text={project.nameAndLocation.name} className="btn-primary-outline" href={`/home/booker/project/view/${project.uuid}`} />
            )}
            {!!isArtist && (
              <Button text={project.nameAndLocation.name} className="btn-primary-outline" href={`/home/artist/project/view/${project.uuid}`} />
            )}
          </div>
          <div className="mt-8">
            <MilestonesFull view={View.FULL} project={project} />
          </div>
        </>
      )}
    </MediumContainer>
  )
}

export default Milestones
