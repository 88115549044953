import { crud } from '@think-internet/zeus-frontend-package'

const props = {
  ...crud.Features,
  COOKIE_CONSENT: 'COOKIE_CONSENT',
  PIPELINE: 'PIPELINE',
  TAG: 'TAG',
  REQUEST: 'REQUEST',
  ARTIST: 'ARTIST',
  BOOKER: 'BOOKER',
  PROJECT: 'PROJECT',
  NOTIFICATION_LIST: 'NOTIFICATION_LIST',
  CHAT_MESSAGE: 'CHAT_MESSAGE',
  FINGERPRINT: 'FINGERPRINT',
}

export default props
