import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { getPrettyDate, prettyLocation } from '../../../../utility'
import PropertyList from '../../../UI/PropertyList/PropertyList'
import { Property as PropertyType } from '../../../UI/PropertyList/types'
import { Project } from '../../../../types/Project'

type Props = {
  project: Project
}

const DefaultProperties: React.FC<Props> = ({ project }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const getPropertyList = (): PropertyType[] => {
    const propertyList: PropertyType[] = [
      { name: t.project.search.properties.title, value: project.nameAndLocation.name },
      { name: t.project.search.properties.location, value: prettyLocation(project.nameAndLocation.location) },
      {
        name: t.project.search.properties.attendee.amount,
        value: `${project.attendee.amount}${t.project.search.properties.attendee.suffix}`,
      },
      {
        name: t.project.search.properties.workshopDate,
        value: getPrettyDate(new Date(project.deadline.date)),
      },
    ]
    return propertyList
  }

  return (
    <div className="w-full">
      <div className="hidden md:block">
        <PropertyList properties={getPropertyList()} contrast compressedView />
      </div>
      <div className="md:hidden">
        <PropertyList properties={getPropertyList()} contrast />
      </div>
    </div>
  )
}

export default DefaultProperties
