import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { ProfileImages as ProfileImagesType } from '../types'
import { PipelineComponent, PipelineState } from '../../../UI/Pipeline/types'

import toast, { TYPE } from '../../../../toast'
import SmallContainer from '../../../UI/Container/SmallContainer'
import MediumContainer from '../../../UI/Container/MediumContainer'
import ArtistHeaderMask from '../../../UI/Artist/HeaderMask/HeaderMask'
import { s3PublicStoragePrefix } from '../../../../utility'
import SectionHeader from '../../../UI/Pipeline/SectionHeader'
import { Artist } from '../../../../types/Artist'

const ProfileImages: PipelineComponent<ProfileImagesType> = ({ pipelineStateProp, data, setData, NavigationForm, finalStepSubmit }) => {
  const state: PipelineState<Artist> = useSelector((s) => s[props.PIPELINE])
  const t = useSelector((state) => state[props.TRANSLATION])

  const set = (prop: keyof ProfileImagesType) => (value: any) => setData({ ...data, [prop]: value })

  const getData = () => {
    if (!!data.personImageKey && !!data.backgroundImageKey) {
      return data
    } else {
      toast(TYPE.ERROR, t.register.artist.profileImages.error)
    }
    return false
  }

  const getMockArtist = (): Artist => {
    return {
      personalDetail: {
        username: state.data.personalDetail?.username,
      },
      rating: {
        rating: 4.51,
        ratingAmount: 63,
        projectAmount: 92,
      },
      skills: {
        tagENUMList: state.data.skills?.tagENUMList,
      },
      locationDetail: {
        location: state.data.locationDetail?.location,
        nationwide: state.data.locationDetail?.nationwide,
      },
      profileImages: {
        personImageKey: data.personImageKey,
        personImageURL: data.personImageKey ? `${s3PublicStoragePrefix}${data.personImageKey}` : null,
        backgroundImageKey: data.backgroundImageKey,
        backgroundImageURL: data.backgroundImageKey ? `${s3PublicStoragePrefix}${data.backgroundImageKey}` : null,
      },
    } as Artist
  }

  return (
    <>
      <SmallContainer>
        <SectionHeader headline={t.register.artist.profileImages.headline} description={t.register.artist.profileImages.description} />
      </SmallContainer>
      <MediumContainer innerClassName="!px-0">
        <NavigationForm pipelineStateProp={pipelineStateProp} getData={getData} finalStepSubmit={finalStepSubmit}>
          <ArtistHeaderMask
            className="mt-7"
            artist={getMockArtist()}
            personImageKeyCallback={set('personImageKey')}
            backgroundImageKeyCallback={set('backgroundImageKey')}
            isRegistrationMode
          />
        </NavigationForm>
      </MediumContainer>
    </>
  )
}

export default ProfileImages
