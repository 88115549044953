import { useSelector } from 'react-redux'
import NewInput from '../../UI/NewInput/NewInput'
import NewButton from '../../UI/NewButton/NewButton'
import props from '../../../redux/props'
import { useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import Modal from '../../UI/NewModal/NewModal'

const RequestReset: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [show, setShow] = useState(false)
  const [identifier, setIdentifier] = useState('')
  const [success, setSuccess] = useState(null)
  const requestPasswordReset = functional.use(Routes.REQUEST_PASSWORD_RESET)

  const submit = async () => {
    const status = await requestPasswordReset({ identifier })
    setSuccess(!!status)
    setIdentifier('')
  }

  const close = () => {
    setShow(false)
    setSuccess(null)
    setIdentifier('')
  }

  return (
    <div className="mt-2">
      <NewButton
        onClick={() => setShow(true)}
        text={t.lostPassword.requestReset.title}
        className="btn-link-primary !text-[#555555] !text-xs mx-auto mt-1"
      />
      <Modal
        confirmButtonText={t.generic.confirm}
        show={show}
        onClose={close}
        onConfirm={success === null ? submit : null}
        title={t.lostPassword.requestReset.title}
      >
        {success === null && (
          <NewInput type="email" required placeholder={t.generic.mail} onChange={setIdentifier} value={identifier} autoComplete="off" />
        )}
        {success === true && <div className="text-sm">{t.lostPassword.requestReset.success}</div>}
        {success === false && <div className="text-sm">{t.lostPassword.requestReset.error}</div>}
      </Modal>
    </div>
  )
}

export default RequestReset
