import styled from 'styled-components'

type Props = {
  image?: string
  small?: boolean
  onClick?: () => void
  highlight?: boolean
}

export const StyledImage = styled.img<{ url?: string }>`
  ${({ url }) => url && `background-image: url(${url});`}
  background-size: cover;
  background-position: center;
`

const Image: React.FC<Props> = ({ image, onClick, small = false, highlight = false }) => {
  if (!image) return <></>
  return (
    <StyledImage
      src={image}
      className={`rounded-md w-full object-cover aspect-video border-[0.1px] border-gray-secondary ${small ? 'h-1/3' : 'h-full'} ${
        !!onClick ? 'cursor-pointer' : ''
      } ${highlight ? '!border-4 border-magenta-primary' : ''}`}
      onClick={onClick || null}
    />
  )
}

export default Image
