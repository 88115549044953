import PersonalDetail from './PersonalDetail/PersonalDetail'
import LocationDetail from './LocationDetail/LocationDetail'
import Skills from './Skills/Skills'
import Story from './Story/Story'
import ProfileImages from './ProfileImages/ProfileImages'
import Pricing from './Pricing/Pricing'
import BillingDetailOne from './BillingDetail/BillingDetailOne'
import BillingDetailTwo from './BillingDetail/BillingDetailTwo'
import { ComponentMapping as ComponentMappingType } from '../../UI/Pipeline/types'

const ComponentMapping: ComponentMappingType = [
  {
    component: PersonalDetail,
    pipelineStateProp: 'personalDetail',
  },
  {
    component: LocationDetail,
    pipelineStateProp: 'locationDetail',
  },
  {
    component: Skills,
    pipelineStateProp: 'skills',
  },
  {
    component: Story,
    pipelineStateProp: 'story',
  },
  {
    component: ProfileImages,
    pipelineStateProp: 'profileImages',
  },
  {
    component: Pricing,
    pipelineStateProp: 'pricing',
  },
  {
    component: BillingDetailOne,
    pipelineStateProp: 'billingDetail',
  },
  {
    component: BillingDetailTwo,
    pipelineStateProp: 'billingDetail',
  },
]

export default ComponentMapping
