import { useSelector } from 'react-redux'
import { Pricing } from '../../../../../../types/Artist'
import { useState } from 'react'
import props from '../../../../../../redux/props'
// import Section from '../../../../../UI/NewAccordion/Section/Section'
import TypeUnitValueMask from '../../../../../UI/Pricing/Editable/TypeUnitValueMask'
import Currency from '../../../../../UI/NewInput/Currency'
import { Price } from '../../../../../../types/Pricing'
// import { getUUID } from '../../../../../../utility'

type Props = {
  initData?: Pricing['concept']
  update?: (value: any) => void
}

const Concept: React.FC<Props> = ({ initData, update }) => {
  const t = useSelector((state) => state[props.TRANSLATION])
  const [data, setData] = useState<Pricing['concept']>(
    initData || {
      price: null,
    },
  )

  const set = (prop: 'price') => (value: Price) => {
    setData({ ...data, [prop]: value })
    update({ ...data, [prop]: value })
  }

  return (
    <div className={!!update && 'md:mt-[42px]'}>
      <div className="font-bold mb-4">{t.register.artist.pricing.concept.headline}</div>
      <div className="mt-2">
        <TypeUnitValueMask type={t.register.artist.pricing.concept.amount} unit={t.register.artist.pricing.concept.unit}>
          <Currency
            required
            value={data.price}
            onChange={set('price')}
            suffixText={t.generic.currencySymbolToSqareMeter}
            contentClassName="font-light"
            hiddenDisabled={!update}
          />
        </TypeUnitValueMask>
      </div>
      {/* {!!update && (
        <Section
          className="mt-2"
          title={t.register.artist.pricing.concept.hint}
          content={t.register.artist.pricing.concept.hintText.map((t) => (
            <div className="text-white text-xs mb-2" key={getUUID()}>
              {t}
            </div>
          ))}
        />
      )} */}
    </div>
  )
}

export default Concept
