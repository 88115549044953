import { useEffect, useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../redux/routes'
import { Statistics } from '../../types/Statistics'

type Props = () => Statistics

const useGetStatistics: Props = () => {
  const [statistics, setStatistics] = useState<Statistics>()
  const getStatistics = functional.use(Routes.GET_STATISTICS)

  useEffect(() => {
    const get = async () => {
      const statistics = await getStatistics()
      setStatistics(statistics)
    }
    if (!statistics) {
      get()
    }
  })

  return statistics
}

export default useGetStatistics
