import { Navigate, Route, Routes } from 'react-router'
import Dashboard from './Dashboard/Dashboard'
import Settings from './Settings/Settings'
import Project from './Project/Project'
import Chat from '../Components/Chat/Chat'
import Request from './Request/Request'
import Finance from './Finance/Finance'

const Booker: React.FC<{}> = () => {
  return (
    <Routes>
      <Route path={'dashboard'} element={<Dashboard />} />
      <Route path={'settings'} element={<Settings />} />
      <Route path={'request/*'} element={<Request />} />
      <Route path={'project/*'} element={<Project />} />
      <Route path={'finance/*'} element={<Finance />} />
      <Route path={'chat/:uuid'} element={<Chat />} />
      <Route path={'chat'} element={<Chat />} />
      <Route path="*" element={<Navigate replace to="dashboard" />} />
    </Routes>
  )
}

export default Booker
