import NameAndLocation from '../StepComponents/NameAndLocation/NameAndLocation'
import Tags from '../StepComponents/Tags/Tags'
import SizeAndPrimer from '../StepComponents/SizeAndPrimer/SizeAndPrimer'
import Deadline from '../StepComponents/Deadline/Deadline'
import DesignText from '../StepComponents/DesignText/DesignText'
import Design from '../StepComponents/Design/Design'
import BudgetExpectation from '../StepComponents/BudgetExpectation/BudgetExpectation'
import { ComponentMapping as ComponentMappingType } from '../../../UI/Pipeline/types'
import Summary from './Summary/Summary'

const ComponentMapping: ComponentMappingType = [
  {
    component: NameAndLocation,
    pipelineStateProp: 'nameAndLocation',
  },
  {
    component: Tags,
    pipelineStateProp: 'tags',
  },
  {
    component: SizeAndPrimer,
    pipelineStateProp: 'sizeAndPrimer',
  },
  {
    component: Deadline,
    pipelineStateProp: 'deadline',
  },
  {
    component: DesignText,
    pipelineStateProp: 'design',
  },
  {
    component: Design,
    pipelineStateProp: 'design',
  },
  {
    component: BudgetExpectation,
    pipelineStateProp: 'budgetExpectation',
  },
  {
    component: Summary,
    pipelineStateProp: 'summary',
  },
]

export default ComponentMapping
