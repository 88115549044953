import { useCallback, useEffect, useState } from 'react'
import SearchFilterMask from '../../../UI/SearchFilterMask/SearchFilterMask'
import props from '../../../../redux/props'
import { useSelector } from 'react-redux'
import { Category, Tag, additionalTags, selectedObjectWhitelist } from '../../../../types/Tag'
import SelectableTagList from '../../../UI/SelectableTagList/SelectableTagList'
import Routes from '../../../../redux/routes'
import { functional } from '@think-internet/zeus-frontend-package'
import Title from '../../../UI/Graybox/Title'
import OrderSelection from '../../../UI/SearchFilterMask/OrderSelection'
import { Request } from '../../../../types/Request'
import ProjectSizeSelection, { SizeFilterSelection } from '../../../UI/SearchFilterMask/ProjectSizeSelection'
import _ from 'lodash'
import history from 'history/browser'
import CreateRequestButton from '../CreateRequestButton'

type Props = {
  requests: Request[]
  setRequests: (request: Request[]) => void
  init: FilterProps
  toggleListView: () => void
  isListView: boolean
}

export type FilterProps = {
  input?: string
  objectTagEnumList?: string[]
  price: {
    order?: 1 | -1
  }
  date: {
    order?: 1 | -1
  }
  size: SizeFilterSelection
}

const Filter: React.FC<Props> = ({ requests, setRequests, init, toggleListView, isListView }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const allTags: Tag[] = useSelector((state) => state[props.TAG])
  const [filter, setFilter] = useState<FilterProps>(
    _.merge(
      {},
      {
        input: '',
        price: {
          order: undefined,
        },
        date: {},
        size: {},
      },
      init || {},
    ),
  )
  const searchRequests = functional.use(Routes.SEARCH_REQUESTS)

  const search = useCallback(async () => {
    const requests = await searchRequests({ filter })
    if (Array.isArray(requests)) {
      setRequests(requests)
      history.replace('/request?query=' + JSON.stringify(filter))
    }
  }, [setRequests, searchRequests, filter])

  useEffect(() => {
    const fetchRequests = async () => {
      search()
    }
    if (!requests) {
      fetchRequests()
    }
  })

  const set = (prop: keyof FilterProps) => (value: any) => setFilter({ ...filter, [prop]: value })

  const setOrder = (prop: keyof FilterProps) => (value: 1 | -1) => {
    const currentProp = filter[prop] as { order: 1 | -1 }
    if (value === currentProp.order) {
      setFilter({ ...filter, [prop]: { ...currentProp, order: undefined } })
    } else {
      setFilter({ ...filter, [prop]: { ...currentProp, order: value } })
    }
  }

  const getAvailableObjectTags = () => {
    if (!Array.isArray(allTags)) return []
    return selectedObjectWhitelist.map((t) => allTags.find((tag) => tag.enum === t)).filter((t) => !!t)
  }

  return (
    <div className="flex flex-row items-center gap-2 md:gap-6 justify-center">
      <SearchFilterMask
        input={filter.input}
        inputOnChange={set('input')}
        submitCallback={search}
        toggleListView={toggleListView}
        isListView={isListView}
        small
      >
        <div className="flex flex-col gap-10 md:gap-14 pt-2">
          <div className="w-full flex flex-col gap-10">
            <div>
              <Title title={t.tag.category[Category.OBJECT]} className="text-white text-lg md:ml-3" />
              <SelectableTagList
                maxVisibleRows={3}
                selectedTagENUMList={filter.objectTagEnumList}
                tags={getAvailableObjectTags()}
                onChange={set('objectTagEnumList')}
                additionalTags={[additionalTags.OTHER]}
              />
            </div>
            <OrderSelection title={t.request.search.filter.price.title} value={filter.price.order} onChange={setOrder('price')} />
            <OrderSelection title={t.request.search.filter.date.title} value={filter.date.order} onChange={setOrder('date')} />
            <ProjectSizeSelection useSmallName title={t.request.search.filter.size.title} value={filter.size} onChange={set('size')} />
          </div>
        </div>
      </SearchFilterMask>
      <CreateRequestButton />
    </div>
  )
}

export default Filter
