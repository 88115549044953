import { useSelector } from 'react-redux'
import DataSection from '../../../Components/DataSection/DataSection'
import NewToggle from '../../../../UI/NewToggle/NewToggle'
import { DataSectionComponent } from '../../types'
import props from '../../../../../redux/props'
import { useEffect, useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../../../../toast'
import Routes from '../../../../../redux/routes'
import { Setting as NotificationSetting, TopicArtist } from '../../../../../types/Notification'
import Loading from '../../../../UI/Loading/Loading'

const Notification: DataSectionComponent = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const getNotificationSettings = functional.use(Routes.GET_NOTIFICATION_SETTINGS)
  const updateNotificationSettings = functional.use(Routes.UPDATE_NOTIFICATION_SETTINGS)
  const [settings, setSettings] = useState<NotificationSetting[]>()

  useEffect(() => {
    const listSettings = async () => {
      const settings = await getNotificationSettings()
      if (settings) {
        setSettings(settings)
      } else {
        toast(TYPE.ERROR, t.artist.settings.notification.error)
      }
    }
    if (!settings) {
      listSettings()
    }
  }, [getNotificationSettings, settings, t])

  const Item: React.FC<{ enabled: boolean; topic: TopicArtist; onChange?: (v: boolean) => void }> = ({ enabled, topic, onChange }) => {
    const [localEnabledState, setLocalEnabledState] = useState(enabled)

    useEffect(() => {
      if (enabled !== localEnabledState) {
        setLocalEnabledState(enabled)
      }
    }, [enabled, localEnabledState])

    const toggle = () => {
      if (onChange) {
        onChange(!localEnabledState)
      }
    }

    return (
      <div className="md:w-1/3 px-1">
        <div className="text-black-secondary text-sm">{t.notificationSettingsTopicMapping.artist[topic]}</div>
        <div className="flex items-center gap-2 mt-2">
          <NewToggle checked={localEnabledState} disabled={!onChange} onChange={toggle} />
          <div className="text-sm">{t.artist.settings.notification.item.mail}</div>
        </div>
      </div>
    )
  }

  const Default = (
    <div>
      <div className="text-black-secondary text-sm md:w-2/3">{t.artist.settings.notification.hint}</div>
      <div className="flex flex-wrap gap-y-6 mt-6">
        {settings?.map((s) => (
          <Item key={s.topic} enabled={s.enabled} topic={s.topic as TopicArtist} />
        ))}
      </div>
    </div>
  )

  const set = (topic: TopicArtist) => (enabled: boolean) => {
    const newSettings = settings.map((s) => (s.topic === topic ? { ...s, enabled } : s))
    setSettings(newSettings)
  }

  const allEnabled = () => settings.every((s) => s.enabled)

  const toggleAll = () => {
    const newSettings = settings.map((s) => ({ ...s, enabled: !allEnabled() }))
    setSettings(newSettings)
  }

  const save = async () => {
    const response = await updateNotificationSettings({ settings })
    if (!Array.isArray(response)) {
      toast(TYPE.ERROR, t.artist.settings.notification.edit.error)
    } else {
      setSettings(response)
    }
    return !!response
  }

  return (
    <DataSection className="w-full pb-16" title={t.artist.settings.notification.title} defaultView={Default} saveCallback={save}>
      <div className="text-black-secondary text-sm md:w-2/3">{t.artist.settings.notification.hint}</div>
      <Loading loading={settings} />
      {settings && (
        <div>
          <div className="w-full px-1 mb-6 mt-7">
            <div className="flex items-center gap-2">
              <NewToggle checked={allEnabled()} onChange={toggleAll} />
              <div className="text-sm">{t.artist.settings.notification.item.enableAll}</div>
            </div>
          </div>
          <div className="flex flex-wrap gap-y-6">
            {settings?.map((s) => (
              <Item key={s.topic} enabled={s.enabled} topic={s.topic as TopicArtist} onChange={set(s.topic as TopicArtist)} />
            ))}
          </div>
        </div>
      )}
    </DataSection>
  )
}

export default Notification
