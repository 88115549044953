import styled from 'styled-components'
import backgroundImage from '../../../assets/image/home/qr/background.png'

export const StyledQrBanner = styled.div`
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  margin-top: 50px;
`

export const Flex = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: block;
  }
`

export const Column = styled.div``

export const Headline = styled.div`
  font-size: ${({ theme }) => theme.font.size.lg};
  color: ${({ theme }) => theme.color.font.secondary};
`

export const SubHeadline = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
`

export const PictureWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 20px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: row;
    align-items: flex-start;
  }
`

export const PictureQR = styled.img`
  width: 200px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: auto;
    height: 100px;
  }
`

export const PictureRegister = styled.img`
  width: 150px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: auto;
    height: 100px;
  }
`

export const Arrows = styled.img`
  max-height: 400px;
  width: auto;
`

export const HeadlineWrapper = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding-top: 50px;
  }
`

export const SubHeadlineWrapper = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding-bottom: 20px;
  }
`

export const ArrowColumn = styled.div`
  display: flex;
  align-items: flex-end;
`
