import { ArtistListItem, Request } from '../../../../types/Request'
import { Offer } from '../../../../types/Offer'
import PriceCalculator, { PriceCalculatorType } from '../../../UI/PriceCalculator/PriceCalculator'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { Artist } from '../../../../types/Artist'

type Props = {
  request: Request
  artist: ArtistListItem | Artist
  offer?: Offer
}

const ArtistPricing: React.FC<Props> = ({ request, artist, offer }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  if (!artist) return null
  return (
    <div className="mt-10">
      <div className="text-lg font-bold text-gray-primary">
        {!!offer ? t.request.view.artistPricing.titleForOffer : t.request.view.artistPricing.title}
      </div>
      <div className="border border-gray-primary rounded-sm p-5 mt-3">
        {!offer && <PriceCalculator type={PriceCalculatorType.REQUEST} artist={artist} request={request} />}
        {!!offer && <PriceCalculator type={PriceCalculatorType.OFFER} artist={artist} request={request} offer={offer} />}
      </div>
    </div>
  )
}

export default ArtistPricing
