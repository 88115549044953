import { useSelector } from 'react-redux'
import DataSection from '../../../../Components/DataSection/DataSection'
import { DataSectionComponent } from '../../../types'
import props from '../../../../../../redux/props'
import List from './List'
import Create from './Create'
import { useState } from 'react'
import { Project } from '../../../../../../types/Project'
import _ from 'lodash'
import toast, { TYPE } from '../../../../../../toast'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../../../redux/routes'

const ExternalProjects: DataSectionComponent = ({ artist }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [project, setProject] = useState<Project>()
  const create = functional.use(Routes.CREATE_EXTERNAL_PROJECT)

  const Default = (
    <div>
      <List artist={artist} />
    </div>
  )

  const save = async () => {
    if (_.has(project, 'tags.object') && !!project.tags.object) {
      if (_.has(project, 'tags.style') && project.tags.style.length > 0) {
        if (new Date(project.deadline.date) < new Date()) {
          if (_.has(project, 'design.keys') && project.design.keys.length > 0) {
            const status = await create({ project })
            if (status) {
              toast(TYPE.SUCCESS, t.artist.profile.externalProjects.edit.success)
              setProject(null)
              return true
            } else {
              toast(TYPE.ERROR, t.artist.profile.externalProjects.edit.error.generic)
            }
          } else {
            toast(TYPE.ERROR, t.artist.profile.externalProjects.edit.error.designImages)
          }
        } else {
          toast(TYPE.ERROR, t.artist.profile.externalProjects.edit.error.deadlineDate)
        }
      } else {
        toast(TYPE.ERROR, t.artist.profile.externalProjects.edit.error.styleTag)
      }
    } else {
      toast(TYPE.ERROR, t.artist.profile.externalProjects.edit.error.objectTag)
    }
    return false
  }

  return (
    <DataSection className="w-full" defaultView={Default} enableCancel saveCallback={save} saveButtonText={t.generic.send}>
      <Create artist={artist} project={project} onChange={setProject} />
    </DataSection>
  )
}

export default ExternalProjects
