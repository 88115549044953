import Loading from '../Loading/Loading'
import { useEffect, useState } from 'react'
import { Tag } from '../../../types/Tag'
import ShowMore from './ShowMore/ShowMore'
import Item from './Item/Item'

type Props = {
  selectedTagENUMList: string[]
  tags: Tag[] | null
  additionalTags?: Tag[] | null
  onChange: (selectedTagENUMList: string[] | string) => void
  maxVisibleRows?: number
  maxVisibleItemsPerRow?: number
  className?: string
  singleSelect?: boolean
  maxSelectable?: number
}

const SelectableTagList: React.FC<Props> = ({
  selectedTagENUMList = [],
  tags,
  additionalTags,
  onChange,
  maxVisibleRows,
  maxVisibleItemsPerRow = 3,
  className = '',
  singleSelect = false,
  maxSelectable,
}) => {
  const [internalTags, setInternalTags] = useState<Tag[]>(null)
  const [showMoreItems, setShowMoreItems] = useState<boolean>(false)

  useEffect(() => {
    if (Array.isArray(tags) && Array.isArray(additionalTags)) {
      setInternalTags([...tags, ...additionalTags])
    } else {
      setInternalTags(tags || [])
    }
  }, [tags, additionalTags])

  const toggle = (tagEnum: string) => () => {
    let newSelectedENUMList: string[] = []
    if (selectedTagENUMList.includes(tagEnum)) {
      if (!singleSelect) {
        newSelectedENUMList.push(...selectedTagENUMList.filter((s) => s !== tagEnum))
      }
    } else {
      if (singleSelect) {
        newSelectedENUMList.push(tagEnum)
      } else {
        if (isFinite(maxSelectable)) {
          if (selectedTagENUMList.length < maxSelectable) {
            newSelectedENUMList.push(...selectedTagENUMList, tagEnum)
          } else {
            newSelectedENUMList.push(...selectedTagENUMList)
          }
        } else {
          newSelectedENUMList.push(...selectedTagENUMList, tagEnum)
        }
      }
    }
    onChange(singleSelect ? newSelectedENUMList[0] : newSelectedENUMList)
  }

  const getMaxVisibleItems = () => {
    if (maxVisibleRows) {
      if (maxVisibleRows * maxVisibleItemsPerRow === tags.length + additionalTags?.length) {
        return tags.length + additionalTags?.length
      } else {
        return maxVisibleRows * maxVisibleItemsPerRow - 1
      }
    }
    return tags.length
  }

  return (
    <div className={`flex flex-wrap gap-x-[16px] gap-y-[13px] ${className}`}>
      {!internalTags && (
        <div className="w-full">
          <Loading loading={internalTags} />
        </div>
      )}
      {Array.isArray(internalTags) && (
        <>
          {Array.isArray(internalTags) &&
            internalTags
              .slice(0, getMaxVisibleItems())
              .map((tag) => (
                <Item
                  maxVisibleItemsPerRow={maxVisibleItemsPerRow}
                  key={tag.enum}
                  name={tag.name}
                  isSelected={selectedTagENUMList.includes(tag.enum)}
                  onClick={toggle(tag.enum)}
                />
              ))}
          {!showMoreItems && internalTags.length > getMaxVisibleItems() && (
            <ShowMore maxVisibleItemsPerRow={maxVisibleItemsPerRow} onClick={() => setShowMoreItems(!showMoreItems)} isOpen={showMoreItems} />
          )}
          {showMoreItems &&
            Array.isArray(internalTags) &&
            internalTags
              .slice(getMaxVisibleItems())
              .map((tag) => (
                <Item
                  maxVisibleItemsPerRow={maxVisibleItemsPerRow}
                  key={tag.enum}
                  name={tag.name}
                  isSelected={selectedTagENUMList.includes(tag.enum)}
                  onClick={toggle(tag.enum)}
                />
              ))}
          {showMoreItems && internalTags.length > getMaxVisibleItems() && (
            <ShowMore maxVisibleItemsPerRow={maxVisibleItemsPerRow} onClick={() => setShowMoreItems(!showMoreItems)} isOpen={showMoreItems} />
          )}
        </>
      )}
    </div>
  )
}

export default SelectableTagList
