import { useEffect, useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import { Offer } from '../../types/Offer'
import Routes from '../../redux/routes'
import { useSelector } from 'react-redux'
import props from '../../redux/props'

type Props = (requestUUID: string) => [Offer[], (offers: Offer[]) => void]

const useListOffers: Props = (requestUUID) => {
  const token = useSelector((s) => s[props.TOKEN])
  const [offers, setOffers] = useState<Offer[]>()
  const listOffers = functional.use(Routes.LIST_OFFERS)

  useEffect(() => {
    const list = async () => {
      if (!!requestUUID) {
        const offers = await listOffers({ uuid: requestUUID })
        setOffers(offers)
      }
    }
    if (!offers && !!token) {
      list()
    }
  })

  return [offers, setOffers]
}

export default useListOffers
