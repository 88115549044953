import { useSelector } from 'react-redux'
import Input, { BaseInput } from './NewInput'
import CurrencyFormat from 'react-currency-format'
import props from '../../../redux/props'

const Currency: React.FC<BaseInput> = ({ value, onChange, suffixText, ...params }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <CurrencyFormat
      customInput={Input}
      thousandSeparator="."
      decimalSeparator=","
      allowNegative={false}
      decimalScale={2}
      fixedDecimalScale={true}
      value={value || ''}
      onValueChange={(v) => onChange(v.floatValue)}
      returnRawChangeEvent
      suffixText={suffixText || t.generic.currencySymbol}
      {...params}
      type="text"
      textAlign="right"
    />
  )
}

export default Currency
