import { useSelector } from 'react-redux'
import contact from '../../../../../assets/image/chat/contacts/contact.png'
import icon from '../../../../../assets/image/chat/contacts/icon.png'
import props from '../../../../../redux/props'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import { PurposeFlag } from '../../../../../types/Auth'

const Placeholder = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const amount = [...new Array(5)]
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])

  const isArtist = accountLink.purposeFlag === PurposeFlag.ARTIST

  return (
    <div className="px-8 flex flex-col items-center gap-2 relative">
      {amount.map((x, i) => (
        <div key={i} className="hidden md:block">
          <img src={contact} alt="" />
        </div>
      ))}
      <div className="absolute top-0 left-0 w-full h-full flex items-center pt-20  flex-col gap-2">
        <img src={icon} alt="" className="h-10" />
        <div className="text-gray-primary font-bold text-lg">{t.chat.contacts.placeholder}</div>
        {!!isArtist && <div className="text-gray-primary text-center text-sm">{t.chat.contacts.artistPlaceholderHint}</div>}
        {!isArtist && <div className="text-gray-primary text-center text-sm">{t.chat.contacts.bookerPlaceholderHint}</div>}
      </div>
    </div>
  )
}

export default Placeholder
