import props from '../../redux/props'
import { useSelector } from 'react-redux'
import Notification, { Type, Payload } from '../../types/Notification'

type Props = (type: Type, payload?: Payload) => boolean

const useHasNotification: Props = (type, payload) => {
  const notifications: Notification[] = useSelector((s) => s[props.NOTIFICATION_LIST])

  if (!notifications) return false
  return !!notifications.find((notification: Notification) => {
    if (notification.type !== type || notification.read) return false
    if (payload) {
      const keys = Object.keys(payload)
      return keys.every((key) => notification.payload[key] === payload[key])
    }
    return true
  })
}

export default useHasNotification
