import './style.css'
import ReactCalendar from 'react-calendar'

type Props = {
  value: Date
  onChange: (value: Date) => void
  minDate?: Date
}

const tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate() + 1)

const CalendarSmall: React.FC<Props> = ({ value, onChange, minDate = tomorrow }) => {
  return (
    <div className="flex justify-center">
      <ReactCalendar onChange={onChange} value={value} locale={'de-DE'} minDate={minDate} />
    </div>
  )
}

export default CalendarSmall
