import { useEffect, useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../redux/routes'
import { Milestone } from '../../types/Milestone'

type Set = (milestones: Milestone[]) => void

type Props = (projectUUID: string) => [Milestone[], Set]

const useListMilestones: Props = (projectUUID) => {
  const [milestones, setMilestones] = useState<Milestone[]>()
  const listMilestonesOfProject = functional.use(Routes.LIST_MILESTONES_OF_PROJECT)
  const [currentProjectUUID, setCurrentProjectUUID] = useState<string>()

  useEffect(() => {
    const list = async () => {
      if (!!projectUUID) {
        const milestones = await listMilestonesOfProject({ projectUUID })
        setMilestones(milestones)
        setCurrentProjectUUID(projectUUID)
      }
    }
    if (!milestones || projectUUID !== currentProjectUUID) {
      list()
    }
  })

  return [milestones, setMilestones]
}

export default useListMilestones
