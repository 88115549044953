import { functional } from '@think-internet/zeus-frontend-package'
import favorite from '../../../assets/image/artist/favorite.png'
import Routes from '../../../redux/routes'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { useState } from 'react'

type Props = {
  init?: boolean
  artistUUID: string
  className?: string
}

const Favorite: React.FC<Props> = ({ init = false, artistUUID, className = '' }) => {
  const toggleFavorite = functional.use(Routes.TOGGLE_FAVORITE)
  const accountLink = useSelector((s) => s[props.ACCOUNT_LINK])
  const [status, setStatus] = useState<boolean>(init)

  const toggle = async () => {
    setStatus(!status)
    const updateStatus = await toggleFavorite({ artistUUID })
    if (!updateStatus) {
      setStatus(!status)
    }
  }

  if (!accountLink) return null
  return (
    <div
      className={`absolute cursor-pointer rounded-full ${status ? 'bg-magenta-primary' : 'bg-white'} ${className ? className : 'bottom-6 right-0'}`}
    >
      <img src={favorite} className="h-7 md:h-8" alt="" onClick={toggle} />
    </div>
  )
}

export default Favorite
