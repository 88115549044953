import { useState } from 'react'
import Image from './Image'
import { Title } from './Title'
import prevImage from '../../../assets/image/project/public-view/prev.png'
import nextImage from '../../../assets/image/project/public-view/next.png'

type Props = {
  title?: string
  images: string[]
  children?: React.ReactNode
  className?: string
  enableFullHeightContent?: boolean
}

const GridViewWithCarousel: React.FC<Props> = ({ title, images, children, className, enableFullHeightContent }) => {
  const [activeImageIndex, setActiveImageIndex] = useState<number>(null)

  const getImageOfIndex = (index: number) => {
    if (index === 0) {
      return images[activeImageIndex || 0]
    } else if (index > 0) {
      return index === activeImageIndex ? images[0] : images[index]
    } else {
      return images[index]
    }
  }

  const setImageIndex = (index: number) => () => {
    setActiveImageIndex(index === activeImageIndex ? null : index)
  }

  const prev = () => setActiveImageIndex(activeImageIndex === 0 ? images.length - 1 : activeImageIndex - 1)
  const next = () => setActiveImageIndex(activeImageIndex === images.length - 1 ? 0 : activeImageIndex + 1)

  return (
    <div className={`flex flex-col gap-2 aspect-video ${className}`}>
      <div className={`relative h-full w-full`}>
        <Image image={getImageOfIndex(0)} />
        {!!title && (
          <div className="absolute top-2 w-full flex justify-center pointer-events-none">
            <Title className="truncate max-w-[95%] backdrop-blur-sm rounded-sm">{title}</Title>
          </div>
        )}
        {!!children && (
          <div className={`absolute w-full flex justify-center ${enableFullHeightContent ? 'h-full bottom-0' : 'bottom-2'}`}>{children}</div>
        )}
        {images.length > 1 && (
          <>
            <div className="hidden md:block absolute top-1/2 left-0 transform -translate-y-1/2 -translate-x-1/2">
              <img src={prevImage} className="h-8 cursor-pointer rounded-full shadow-xl" alt="" onClick={prev} />
            </div>
            <div className="hidden md:block absolute top-1/2 right-0 transform  -translate-y-1/2 translate-x-1/2">
              <img src={nextImage} className="h-8 cursor-pointer  rounded-full shadow-xl" alt="" onClick={next} />
            </div>
          </>
        )}
      </div>
      {images.length > 1 && (
        <div className="flex flex-row gap-[8px] flex-wrap items-start w-full">
          {images.map((image, i) => {
            if (i === 0) return null
            return <Image image={getImageOfIndex(i)} small onClick={setImageIndex(i)} />
          })}
        </div>
      )}
    </div>
  )
}

export default GridViewWithCarousel
