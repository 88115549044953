import { useSelector } from 'react-redux'
import { Pricing } from '../../../../../types/Artist'
import props from '../../../../../redux/props'
import { Sizes } from '../../../../../types/Size'
import SizeAndPrice from '../../../../UI/Pricing/Editable/SizeAndPrice'
import { formatPricePerSquareMeter } from '../../../../../utility'

type Props = {
  data: Pricing['art']
  singleLine?: boolean
  noTitle?: boolean
}

const Art: React.FC<Props> = ({ data, singleLine, noTitle }) => {
  const t = useSelector((state) => state[props.TRANSLATION])

  return (
    <div>
      {!noTitle && <div className="font-bold mb-5">{t.register.artist.pricing.art.headline}</div>}
      <div className={`flex ${singleLine ? 'flex-col lg:flex-row' : 'flex-col items-center'} ${noTitle ? 'gap-3 md:gap-6' : 'gap-4'}`}>
        <SizeAndPrice
          required
          size={Sizes.S}
          price={formatPricePerSquareMeter(data[Sizes.S])}
          readOnly
          noUnit={singleLine}
          sizeFullName={singleLine}
        />
        <SizeAndPrice
          required
          size={Sizes.M}
          price={formatPricePerSquareMeter(data[Sizes.M])}
          readOnly
          noUnit={singleLine}
          sizeFullName={singleLine}
        />
        <SizeAndPrice
          required
          size={Sizes.L}
          price={formatPricePerSquareMeter(data[Sizes.L])}
          readOnly
          noUnit={singleLine}
          sizeFullName={singleLine}
        />
      </div>
    </div>
  )
}

export default Art
