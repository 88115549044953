import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { useState } from 'react'
import { Pricing } from '../types'
import TypeUnitValueMask from '../../../UI/Pricing/Editable/TypeUnitValueMask'
import Currency from '../../../UI/NewInput/Currency'
import Section from '../../../UI/NewAccordion/Section/Section'
import Number from '../../../UI/NewInput/Number'
import SectionHeader from '../../../UI/Pipeline/SectionHeader'

type Props = {
  initData?: Pricing['workshop']
  update: (value: any) => void
}

const Workshop: React.FC<Props> = ({ initData, update }) => {
  const t = useSelector((state) => state[props.TRANSLATION])
  const [data, setData] = useState<Pricing['workshop']>(
    initData || {
      pricePerAttendee: null,
      minAttendees: null,
    },
  )

  const set = (prop: keyof Pricing['workshop']) => (value: number) => {
    setData({ ...data, [prop]: value })
    update({ ...data, [prop]: value })
  }

  return (
    <div>
      <SectionHeader headline={t.register.artist.pricing.workshop.headline} small />
      <Section className="mt-2" title={t.register.artist.pricing.workshop.hint} content={t.register.artist.pricing.workshop.hintText} />
      <div className="w-2/3 md:w-1/2 mt-5">
        <TypeUnitValueMask
          type={t.register.artist.pricing.workshop.pricePerAttendee.amount}
          unit={t.register.artist.pricing.workshop.pricePerAttendee.unit}
        >
          <Currency
            value={data.pricePerAttendee}
            onChange={set('pricePerAttendee')}
            className="pr-1"
            contentClassName="font-light"
            suffixText="€/Person"
          />
        </TypeUnitValueMask>
      </div>
      <div className="w-full md:w-2/3 mt-5">
        <TypeUnitValueMask unit={t.register.artist.pricing.workshop.minAttendees.unit}>
          <Number value={data.minAttendees} onChange={set('minAttendees')} contentClassName="font-light" textAlign="center" />
        </TypeUnitValueMask>
      </div>
    </div>
  )
}

export default Workshop
