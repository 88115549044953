type Props = {
  className?: string
  children: React.ReactNode
}

const Headline: React.FC<Props> = ({ children, className }) => {
  return <div className={`text-blue-primary text-center text-lg pt-5 pb-3 ${className}`}>{children}</div>
}

export default Headline
