import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Container from '../../UI/Container/Container'
import { Headline } from './Functionality.Styled'

type Props = {
  className?: string
}

const Functionality: React.FC<Props> = ({ className = '' }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <div className={className}>
      <Container>
        <Headline>{translation.home.functionality.headline}</Headline>
        {/* <SubHeadline>{translation.home.functionality.subHeadline}</SubHeadline> */}
      </Container>
    </div>
  )
}

export default Functionality
