import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import ProjectGridView from '../../../../Project/ProjectGridView/ProjectGridView'
import { Artist } from '../../../../../types/Artist'
import Title from '../../../../UI/Graybox/Title'
import useListProjects from '../../../../hooks/useListProjects'

type Props = {
  artist: Artist
}

const Projects: React.FC<Props> = ({ artist }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const projects = useListProjects({ artistUUID: artist.uuid, isVisible: true })

  if (!Array.isArray(projects) || projects.length === 0) return null
  return (
    <div className="w-full mt-10">
      <Title className="text-lg lg:ml-12 mt-11" title={t.artist.search.view.projects.title} />
      {Array.isArray(projects) && (
        <div className="flex flex-col gap-11 mt-10">
          {projects.map((p) => (
            <ProjectGridView key={p.uuid} project={p} minifiedView />
          ))}
        </div>
      )}
    </div>
  )
}

export default Projects
