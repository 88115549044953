import { useEffect, useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../redux/routes'
import cookie, { FINGERPRINT } from '../UI/helper/cookie'
import { useDispatch, useSelector } from 'react-redux'
import props from '../../redux/props'
import { setLocal } from '../../redux/action/local'

const useGetFingerprint = () => {
  const dispatch = useDispatch()
  const [fingerprintUUID, setFingerprintUUID] = useState<string>()
  const getFingerprint = functional.use(Routes.GET_FINGERPRINT)
  const storeFingerprint = useSelector((s) => s[props.FINGERPRINT])

  useEffect(() => {
    if (!fingerprintUUID && storeFingerprint) {
      setFingerprintUUID(storeFingerprint)
    }
  }, [storeFingerprint, fingerprintUUID])

  useEffect(() => {
    const get = async () => {
      try {
        const cookieFingerprintUUID = cookie.get(FINGERPRINT)

        const fingerprintUUID = await getFingerprint({ fingerprintUUID: cookieFingerprintUUID })

        if (fingerprintUUID) {
          setFingerprintUUID(fingerprintUUID)
          cookie.set(FINGERPRINT, fingerprintUUID)
          dispatch(setLocal(props.FINGERPRINT, fingerprintUUID))
        } else {
          cookie.remove(FINGERPRINT)
          dispatch(setLocal(props.FINGERPRINT, false))
        }
      } catch (e) {
        console.log(e)
      }
    }
    if (!fingerprintUUID && !storeFingerprint) {
      get()
    }
  })

  return fingerprintUUID
}

export default useGetFingerprint
