import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { formatPrice, getPrettyDate, prettyLocation, s3PublicStoragePrefix } from '../../../../utility'
import SectionHeader from '../../../UI/Pipeline/SectionHeader'
import { Request } from '../../../../types/Request'
import PropertyList from '../../../UI/PropertyList/PropertyList'
import Property from '../../../UI/PropertyList/Property'
import { Property as PropertyType } from '../../../UI/PropertyList/types'
import Headline from '../../../UI/Headline/Headline'
import Carousel from '../../../UI/Carousel/Carousel'
import GrayBox from '../../../UI/Graybox/Graybox'
import Button from '../../../UI/NewButton/NewButton'

type Props = {
  request: Request
  ProfilePreview?: React.ReactNode
}

const DefaultProperties: React.FC<Props> = ({ request, ProfilePreview }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const accountLink = useSelector((s) => s[props.ACCOUNT_LINK])

  const getPropertyList = (): PropertyType[] => {
    const propertyList: PropertyType[] = [
      { name: t.request.search.properties.title, value: request.nameAndLocation.name },
      { name: t.request.search.properties.location, value: prettyLocation(request.nameAndLocation.location) },
      {
        name: t.request.search.properties.attendee.amount,
        value: `${request.attendee.amount}${t.request.search.properties.attendee.suffix}`,
      },
      {
        name: t.request.search.properties.workshopDate,
        value: getPrettyDate(new Date(request.deadline.date)),
      },
    ]
    return propertyList
  }

  return (
    <div className="flex flex-col gap-0">
      <Headline>{t.request.view.headline}</Headline>
      {!!ProfilePreview && ProfilePreview}
      <SectionHeader headline={t.request.view.propertySection} />
      <PropertyList className="mt-3" properties={getPropertyList()} />
      <SectionHeader headline={t.request.search.properties.attendee.motto} />
      <div className="text-gray-primary my-2 text-sm">{request.attendee.motto}</div>
      {!!request.budgetExpectation && (
        <>
          <SectionHeader headline={t.request.search.properties.budgetExpectation.title} />
          <Property
            className="mt-3"
            property={{
              name: t.request.search.properties.budgetExpectation.total,
              value:
                request.budgetExpectation.noAskingPrice === true
                  ? t.request.search.properties.budgetExpectation.noAskingPrice
                  : formatPrice(request.budgetExpectation.askingPrice),
            }}
          />
        </>
      )}
      <SectionHeader
        headline={t.request.search.properties.design.titleWorkshop}
        description={`${t.request.search.properties.imagePrefix} ${request.design.keys.length}`}
        italicSubline
      />
      <Carousel
        className="mt-6"
        itemsToShow={2}
        itemsToShowMobile={2}
        smallArrows={true}
        images={request.design.keys.map((key) => `${s3PublicStoragePrefix}${key}`)}
      />
      {!accountLink && (
        <GrayBox className="w-full mt-10 border-[1px] border-gray-secondary bg-white">
          <div className="pb-3 text-center text-blue-primary font-bold">{t.request.search.loginHint}</div>
          <div className="text-center flex items-center justify-center">
            <Button text={t.request.search.loginCTA} href={'/login'} className="btn-secondary w-fit !text-sm" />
          </div>
        </GrayBox>
      )}
    </div>
  )
}

export default DefaultProperties
