import { useSelector } from 'react-redux'
import MediumContainer from '../../../../UI/Container/MediumContainer'
import Loading from '../../../../UI/Loading/Loading'
import props from '../../../../../redux/props'
import { useParams } from 'react-router-dom'
import useGetProject from '../../../../hooks/useGetProject'
import Pricing from './Pricing/Pricing'
import { Status, VerifiedBy } from '../../../../../types/Project'
import Milestones, { View } from '../../../../UI/Milestones/Milestones'
import GrayBox from '../../../../UI/Graybox/Graybox'
import NewButton from '../../../../UI/NewButton/NewButton'
import Properties from '../../../Components/Project/View/Properties/Properties'
import ChatPreview from '../../../Components/Project/View/ChatPreview/ChatPreview'
import Edit from './Edit/Edit'
import SmallContainer from '../../../../UI/Container/SmallContainer'
import Card from '../../../../UI/Booker/Card/Card'
import { Booker } from '../../../../../types/Booker'
import ToggleVisibility from './ToggleVisibility/ToggleVisibility'

const Project: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const { uuid } = useParams<{ uuid: string }>()
  const [project] = useGetProject(uuid)

  const isExternalProject = () => !!project && project.verification.verifiedBy === VerifiedBy.EXTERNAL
  const chatIsEnabled = () => {
    return !isExternalProject() && project.status !== Status.CANCELLED
  }

  return (
    <MediumContainer className="py-3">
      <Loading loading={project} />
      {project && (
        <>
          <div className="text-blue-primary text-center text-lg">
            {t.project.view.titlePrefix}
            {project.nameAndLocation.name}
          </div>
          {!!project.artist && (
            <SmallContainer className="mt-6 mb-10">
              <Card booker={project.booker as Booker}></Card>
            </SmallContainer>
          )}
          {!isExternalProject() && project.status === Status.COMPLETED && <Edit project={project} />}
          {!isExternalProject() && (
            <GrayBox title={t.project.view.milestone.title} className="pb-14 md:pb-7">
              {project.status === Status.PENDING_DOWNPAYMENT ? (
                <div className="text-center text-gray-primary text-sm mb-5">{t.project.view.milestone.waitingForDownpayment}</div>
              ) : (
                <>
                  <Milestones view={View.SINGLE} project={project} />
                  <NewButton
                    className="hidden md:flex btn-secondary ml-auto w-fit mt-2"
                    text={t.project.view.milestone.goTo}
                    href={`/home/artist/project/milestones/${project.uuid}`}
                  />
                  <div className="md:hidden flex items-center justify-center  gap-2 absolute bottom-4 w-full left-0">
                    <NewButton
                      className="btn-secondary !text-2xs w-fit !h-6 md:!h-5 mx-auto"
                      text={t.project.view.milestone.goTo}
                      href={`/home/artist/project/milestones/${project.uuid}`}
                    />
                  </div>
                </>
              )}
            </GrayBox>
          )}
          {chatIsEnabled() && <ChatPreview project={project} />}
          <Properties project={project} />
          {!isExternalProject() && <Pricing project={project} />}
          <ToggleVisibility project={project} />
        </>
      )}
    </MediumContainer>
  )
}

export default Project
