import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import GetIcon from '../../../GetIcon/GetIcon'
import props from '../../../../../redux/props'
import { getUUID } from '../../../../../utility'

const BottomNavigation = () => {
  const navigate = useNavigate()
  const translation = useSelector((s) => s[props.TRANSLATION])
  const goTo = (path) => () => navigate(path)

  return (
    <div className="btm-nav z-50 lg:hidden bg-blue-primary border-t border-white">
      {translation.header.navigation.default.map(({ name, link, icon }, i: number) => (
        <button key={getUUID()} className="w-1/3 flex flex-col items-center" onClick={goTo(link)}>
          <GetIcon name={icon} className="max-h-5 fill-white" />
          <div className="text-xs text-white btm-nav-label">{name}</div>
        </button>
      ))}
    </div>
  )
}

export default BottomNavigation
