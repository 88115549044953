import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Container from '../../UI/Container/Container'
import { StyledVision, Headline, Text } from './Vision.Styled'

const Vision = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <StyledVision>
      <Container>
        <Headline>{translation.home.vision.headline}</Headline>
        <Text>{translation.home.vision.paragraph}</Text>
      </Container>
    </StyledVision>
  )
}

export default Vision
