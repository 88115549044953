import NewButton from '../../NewButton/NewButton'
import Wrapper from './Wrapper'

type Props = {
  name: string
  isSelected: boolean
  onClick: () => void
  maxVisibleItemsPerRow?: number
  className?: string
  disableFixedWidth?: boolean
}

const Item: React.FC<Props> = ({ name, isSelected, onClick, maxVisibleItemsPerRow = 4, className = '', disableFixedWidth = false }) => {
  return (
    <Wrapper maxVisibleItemsPerRow={maxVisibleItemsPerRow} disableFixedWidth={disableFixedWidth}>
      <NewButton
        text={name}
        className={`!h-6 ${isSelected ? 'btn-primary' : 'btn-primary-outline !text-white !bg-gray-primary-second border-none'} ${className}`}
        onClick={onClick}
      />
    </Wrapper>
  )
}

export default Item
