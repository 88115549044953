import { useEffect, useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../redux/routes'
import useGetFingerprint from './useGetFingerprint'

type Props = (artistUUID: string) => boolean

const useTrackArtistProfileView: Props = (artistUUID) => {
  const [status, setStatus] = useState<boolean>()
  const trackArtistProfileView = functional.use(Routes.TRACK_ARTIST_PROFILE_VIEW)
  const fingerprintUUID = useGetFingerprint()

  useEffect(() => {
    const get = async () => {
      const status = await trackArtistProfileView({ fingerprintUUID, artistUUID })
      if (status) setStatus(status)
    }
    if (!!artistUUID && !status && !!fingerprintUUID) {
      get()
    }
  })

  return status
}

export default useTrackArtistProfileView
