import { useSelector } from 'react-redux'
import props from '../../../../../../redux/props'
import { getPrettyDate, prettyLocation } from '../../../../../../utility'
import { Project, VerifiedBy } from '../../../../../../types/Project'
import Property from '../../../../../UI/PropertyList/Property'
import _ from 'lodash'
import { Tag, additionalTags, additionalTagsArray } from '../../../../../../types/Tag'

type Props = {
  project: Project
}

const Default: React.FC<Props> = ({ project }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const tags = useSelector((s) => s[props.TAG])

  const getTagName = (tagEnum: string) => {
    const tag = [...tags, ...additionalTagsArray].find((t: Tag) => t.enum === tagEnum)
    return tag ? tag.name : ''
  }

  const showEquipmentAvailable = () => {
    return !project.tags.equipment.includes(additionalTags.NO_EQUIPMENT_REQUIRED.enum)
  }

  const isExternalProject = () => project.verification.verifiedBy === VerifiedBy.EXTERNAL

  return (
    <div className="flex flex-col gap-0">
      <Property
        property={{
          name: t.project.search.properties.title,
          value: project.nameAndLocation.name,
        }}
      />
      <Property
        property={{
          name: t.project.search.properties.location,
          value: prettyLocation(project.nameAndLocation.location),
        }}
      />
      <Property
        property={{
          name: t.project.search.properties.object,
          value: getTagName(project.tags.object),
        }}
      />
      {_.has(project, 'tags.surface') && project.tags.surface.length > 0 && (
        <Property
          property={{
            name: t.project.search.properties.surface,
            value: getTagName(project.tags.surface),
          }}
        />
      )}
      {_.has(project, 'tags.equipment') && project.tags.equipment.length > 0 && (
        <Property
          property={{
            name: t.project.search.properties.equipment,
            value: project.tags.equipment.map((e) => getTagName(e)).join(', '),
          }}
        />
      )}
      {showEquipmentAvailable() && !isExternalProject && (
        <Property
          property={{
            name: t.project.search.properties.equipmentAvailable.name,
            value: project.tags.equipmentAvailable
              ? t.project.search.properties.equipmentAvailable.YES
              : t.project.search.properties.equipmentAvailable.NO,
          }}
        />
      )}
      <Property
        property={{
          name: t.project.search.properties.size,
          value: `${project.sizeAndPrimer.sizeInSquareMeters}${t.generic.squareMeter}`,
        }}
      />
      {!isExternalProject() && (
        <Property
          property={{
            name: t.project.search.properties.primer,
            value: t.project.primerOptions[project.sizeAndPrimer.primerRequired],
          }}
        />
      )}
      {!!project.insurance && (
        <Property
          property={{
            name: t.project.search.properties.insurance.name,
            value: project.insurance.accepted ? t.project.search.properties.insurance.YES : t.project.search.properties.insurance.NO,
          }}
        />
      )}
      <Property
        property={{
          name: t.project.search.properties.deadlineDate,
          value: getPrettyDate(new Date(project.finalizedOn || project.deadline.date)),
        }}
      />
    </div>
  )
}

export default Default
