import styled from 'styled-components'

export const StyledImage = styled.div<{ horizontal?: boolean; index?: number; url?: string | false }>`
  position: relative;
  ${({ url }) => url && `background-image: url(${url});`}
  background-size: cover;
  background-position: center;
  background-color: ${({ theme }) => theme.color.white};
  ${({ horizontal, index }) => `
    ${
      horizontal
        ? index === 0
          ? 'height: 100%;'
          : 'height: 33%;'
        : index === 0
        ? 'aspect-ratio: 16 / 9;'
        : 'aspect-ratio: 16 / 9; width: calc(100% / 3 - 8px * 2  / 3); flex-shrink: 0;'
    }
  `}
`
