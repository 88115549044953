import Image from './Image/Image'
import { OnCallbackFile, UPLOAD_TYPES } from '../DirectFileUpload/DirectFileUpload'
import { useEffect, useState } from 'react'

type ImageKeys = string[]

type Props = {
  imageKeys?: ImageKeys
  callback: (keys: ImageKeys) => void
  uploadType: UPLOAD_TYPES
  horizontal?: boolean
  className?: string
}

const ProjectImageUploadMask: React.FC<Props> = ({ imageKeys = [], callback, uploadType, horizontal = false, className = '' }) => {
  const [keys, setKeys] = useState<ImageKeys>(imageKeys)

  const set = (imageKeys: ImageKeys) => {
    setKeys(imageKeys)
    callback(imageKeys.filter((k) => !!k))
  }

  useEffect(() => {
    const imageAmount = 4
    if (keys && keys.length < imageAmount) {
      const newKeys = [...Array(imageAmount)].map((_, idx) => keys[idx])
      setKeys(newKeys)
    } else if (!keys) {
      setKeys([...Array(imageAmount)])
    }
  }, [keys, callback])

  useEffect(() => {
    const fullKeyAmount = keys.filter((k) => !!k).length
    if (fullKeyAmount === keys.length && !horizontal) {
      setKeys([...keys, undefined])
    }
  }, [keys, horizontal])

  const setImage = (index: number) => (file: OnCallbackFile) => {
    set(keys.map((k, idx) => (idx === index ? file.key : k)))
  }

  const removeImage = (index: number) => () => {
    set(keys.map((k, idx) => (idx === index ? undefined : k)))
  }

  return (
    <div className={horizontal ? `flex flex-row gap-1 aspect-video ${className}` : className}>
      <div className={horizontal ? 'w-2/3' : ''}>
        <Image index={0} keys={keys} setImage={setImage(0)} remove={removeImage(0)} uploadType={uploadType} horizontal={horizontal} />
      </div>
      <div className={horizontal ? 'w-1/3 h-full flex flex-col gap-1' : 'flex flex-row flex-wrap gap-[8px] mt-2'}>
        {keys.map((k, idx) => {
          if (idx === 0) return null
          return (
            <Image
              key={idx}
              index={idx}
              keys={keys}
              setImage={setImage(idx)}
              remove={removeImage(idx)}
              uploadType={uploadType}
              horizontal={horizontal}
            />
          )
        })}
      </div>
    </div>
  )
}

export default ProjectImageUploadMask
