import props from '../../redux/props'
import { useSelector } from 'react-redux'
import Notification, { Type } from '../../types/Notification'

type Props = (type?: Type | Type[]) => Notification[]

const useGetNotification: Props = (type) => {
  const notifications = useSelector((s) => s[props.NOTIFICATION_LIST])

  if (!notifications) return []
  return notifications.filter((notification: Notification) => {
    if (!type) return false
    if (Array.isArray(type)) return type.includes(notification.type)
    return notification.type === type
  })
}

export default useGetNotification
