import RangeSlider from 'react-range-slider-input'
import 'react-range-slider-input/dist/style.css'
import './Slider.Style.css'

type Props = {
  value: number
  onChange: (value: number) => void
  min?: number
  max?: number
  step?: number
}

const Slider: React.FC<Props> = ({ value, onChange, min = 0, max = 100, step = 10 }) => {
  const update = (value: number[]) => {
    onChange(value[1])
  }

  return (
    <RangeSlider
      className="single-thumb"
      thumbsDisabled={[true, false]}
      rangeSlideDisabled={true}
      value={[0, value || 0]}
      onInput={update}
      max={max}
      min={min}
      step={step}
    />
  )
}

export default Slider
