import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import NewInput from '../../../UI/NewInput/NewInput'
import { BillingDetail as BillingDetailType } from '../types'
import { PipelineComponent } from '../../../UI/Pipeline/types'
import SmallContainer from '../../../UI/Container/SmallContainer'
import SectionHeader from '../../../UI/Pipeline/SectionHeader'
import toast, { TYPE } from '../../../../toast'
import Phone from '../../../UI/NewInput/Phone'
import { isValidPhoneNumber } from '../../../../utility'

const BillingDetailTwo: PipelineComponent<BillingDetailType> = ({ pipelineStateProp, data, setData, NavigationForm, finalStepSubmit }) => {
  const t = useSelector((state) => state[props.TRANSLATION])

  const set = (prop: keyof BillingDetailType) => (value: any) => setData({ ...data, [prop]: value })

  const is18YearsOld = (birthdate: string, age: number = 18): boolean => {
    const today = new Date()
    const birthDate = new Date(birthdate)

    let ageDiff = today.getFullYear() - birthDate.getFullYear()
    const monthDiff = today.getMonth() - birthDate.getMonth()

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      ageDiff--
    }

    return ageDiff >= age
  }

  const getData = () => {
    if (is18YearsOld(data.birthday as unknown as string)) {
      if (!!data.businessType) {
        if (isValidPhoneNumber(data.phone)) {
          return data
        } else {
          toast(TYPE.ERROR, t.generic.phoneError)
        }
      } else {
        toast(TYPE.ERROR, t.register.artist.billingDetail.error)
      }
    } else {
      toast(TYPE.ERROR, t.register.artist.billingDetail.two.ageError)
    }
    return false
  }

  return (
    <SmallContainer>
      <SectionHeader headline={t.register.artist.billingDetail.two.headline} description={t.register.artist.billingDetail.two.description} />
      <NavigationForm pipelineStateProp={pipelineStateProp} getData={getData} finalStepSubmit={finalStepSubmit}>
        <div className="mt-7 flex gap-3 flex-col lg:w-7/12 mx-auto">
          <NewInput required value={data.firstname} onChange={set('firstname')} placeholder={t.generic.firstname} />
          <NewInput required value={data.lastname} onChange={set('lastname')} placeholder={t.generic.lastname} />
          <NewInput type="date" required value={data.birthday} onChange={set('birthday')} placeholder={t.generic.birthday} />
          <Phone required value={data.phone} onChange={set('phone')} placeholder={t.generic.phone} />
        </div>
      </NavigationForm>
    </SmallContainer>
  )
}

export default BillingDetailTwo
