import { useDispatch, useSelector } from 'react-redux'
import Steps from './Steps/Steps'
import props from '../../../redux/props'
import Init from './Init'
import { ComponentMapping, FinalStepSubmit, PipelineState } from './types'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import toast, { TYPE } from '../../../toast'
import React, { useState } from 'react'
import Navigation from './Navigation/Navigation'
import { setLocal } from '../../../redux/action/local'
import useGetFingerprint from '../../hooks/useGetFingerprint'

type Props<D, A> = Pick<PipelineState<D, A>, 'type' | 'additionalData'> & {
  finalSubmitFunctionalRoute: Routes
  componentMapping: ComponentMapping
  SuccessComponent: React.FC<any>
  hideSteps?: boolean
}

const Pipeline = <D, A = any>({ type, componentMapping, finalSubmitFunctionalRoute, SuccessComponent, additionalData, hideSteps }: Props<D, A>) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const state: PipelineState<D> = useSelector((s) => s[props.PIPELINE])
  const submitCall = functional.use(finalSubmitFunctionalRoute)
  const [success, setSuccess] = useState<boolean>(false)
  const dispatch = useDispatch()
  const fingerprintUUID = useGetFingerprint()

  const submit: FinalStepSubmit<D> = async (data) => {
    const response = await submitCall({ ...data.data, ...data.additionalData, fingerprintUUID })
    if (!!response) {
      setSuccess(true)
      dispatch(setLocal(props.PIPELINE, {}))
      document.body.scrollTop = 0
    } else {
      toast(TYPE.ERROR, t.generic.error)
    }
  }

  const GetComponent: React.FC<{}> = () => {
    const item = componentMapping[state.currentIndex]
    return <Navigation<D> pipelineStateProp={item.pipelineStateProp} Component={item.component} componentMapping={componentMapping} submit={submit} />
  }

  return (
    <>
      <Init type={type} additionalData={additionalData} data={{}} />
      {!!state && !success && (
        <>
          {!hideSteps && <Steps total={componentMapping.length} current={state.currentIndex} />}
          <GetComponent />
        </>
      )}
      {!!success && <SuccessComponent state={state} />}
    </>
  )
}

export default Pipeline
