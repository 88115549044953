import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import NewInput from '../../../UI/NewInput/NewInput'
import { BillingDetail as BillingDetailType } from '../types'
import SmallContainer from '../../../UI/Container/SmallContainer'

import { PipelineComponent } from '../../../UI/Pipeline/types'
import SectionHeader from '../../../UI/Pipeline/SectionHeader'

const BillingDetail: PipelineComponent<BillingDetailType> = ({ pipelineStateProp, data, setData, NavigationForm, finalStepSubmit }) => {
  const t = useSelector((state) => state[props.TRANSLATION])

  const set = (prop: keyof BillingDetailType) => (value: any) => setData({ ...data, [prop]: value })

  const getData = async () => data

  return (
    <SmallContainer>
      <SectionHeader headline={t.register.booker.billingDetail.headline} description={t.register.booker.billingDetail.description} />
      <NavigationForm pipelineStateProp={pipelineStateProp} getData={getData} finalStepSubmit={finalStepSubmit}>
        <div className="mt-7 flex gap-3 flex-col lg:w-7/12 mx-auto">
          <NewInput required value={data.firstname} onChange={set('firstname')} placeholder={t.generic.firstname} />
          <NewInput required value={data.lastname} onChange={set('lastname')} placeholder={t.generic.lastname} />
          <NewInput value={data.companyName} onChange={set('companyName')} placeholder={t.generic.companyName + t.generic.optionalSuffix} />
          <NewInput required value={data.street} onChange={set('street')} placeholder={t.generic.street} />
          <NewInput required value={data.postcode} onChange={set('postcode')} placeholder={t.generic.postcode} />
          <NewInput required value={data.city} onChange={set('city')} placeholder={t.generic.city} />
        </div>
      </NavigationForm>
    </SmallContainer>
  )
}

export default BillingDetail
