import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import props from '../../../../redux/props'
import { useEffect, useState } from 'react'
import { crud } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../../../toast'
import { Request } from '../../../../types/Request'
import DefaultProperties from '../../../Request/View/DefaultProperties/DefaultProperties'
import WorkshopProperties from '../../../Request/View/WorkshopProperties/WorkshopProperties'
import ArtistPricing from '../../../Request/View/ArtistPricing/ArtistPricing'
import _ from 'lodash'
import SmallContainer from '../../../UI/Container/SmallContainer'
import MakeAnOffer from './MakeAnOffer/MakeAnOffer'
import useListOffers from '../../../hooks/useListOffers'
import Card from '../../../UI/Booker/Card/Card'
import useGetArtist from '../../../hooks/useGetArtist'
import { Offer } from '../../../../types/Offer'

const View: React.FC<{}> = () => {
  const { uuid } = useParams<{ uuid: string }>()
  const [request, setRequest] = useState<Request>(null)
  const t = useSelector((s) => s[props.TRANSLATION])
  const requestFeature = crud.use(props.REQUEST)
  const [offers] = useListOffers(request?.uuid)
  const accountLink = useSelector((s) => s[props.ACCOUNT_LINK])
  const [artist] = useGetArtist()

  useEffect(() => {
    const getRequest = async () => {
      const request = await requestFeature.get({ uuid })
      if (request) {
        setRequest(request)
      } else {
        toast(TYPE.ERROR, t.request.view.error)
      }
    }
    if (!request) {
      getRequest()
    }
  }, [request, uuid, t, requestFeature])

  const isWorkshop = () => {
    return request && _.has(request, 'attendee.amount') && isFinite(request.attendee.amount)
  }

  const getOffer = () => {
    if (!Array.isArray(offers) || !accountLink) return false
    return offers.find((o) => o.artistUUID === accountLink.assignedToUUID)
  }
  const hasOffer = !!getOffer()

  if (!request || !artist) return <></>
  return (
    <>
      <SmallContainer>
        {isWorkshop() && <WorkshopProperties ProfilePreview={<Card booker={request.booker} />} request={request} />}
        {!isWorkshop() && <DefaultProperties ProfilePreview={<Card booker={request.booker} />} request={request} />}
        <ArtistPricing key={artist.uuid} request={request} artist={artist} offer={getOffer() as Offer} />
        {!hasOffer && <MakeAnOffer request={request} artist={artist} />}
      </SmallContainer>
    </>
  )
}

export default View
