import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { GetData, PipelineComponent, PipelineState } from '../../../../UI/Pipeline/types'
import SectionHeader from '../../../../UI/Pipeline/SectionHeader'
import useGoNext from '../../../../UI/Pipeline/Navigation/useGoNext'
import NewButton from '../../../../UI/NewButton/NewButton'
import useGoPrevious from '../../../../UI/Pipeline/Navigation/useGoPrevious'
import Properties from './Properties'
import { ArtistListItem, Request } from '../../../../../types/Request'
import { useState } from 'react'
import toast, { TYPE } from '../../../../../toast'
import Checkbox from '../../../../UI/Checkbox/Checkbox'

const Summary: PipelineComponent<{}> = ({ pipelineStateProp, data, setData, NavigationForm, finalStepSubmit }) => {
  const t = useSelector((state) => state[props.TRANSLATION])
  const state: PipelineState<Request, { artist: ArtistListItem }> = useSelector((state) => state[props.PIPELINE])
  const [acceptTerms, setAcceptTerms] = useState(false)

  const getData: GetData = () => {
    if (acceptTerms) {
      return data
    } else {
      toast(TYPE.ERROR, t.request.create.summary.widerrufsrecht.error)
    }
  }

  const isBasedOnExistingRequest = !!state.data.nameAndLocation.existingRequestUUID

  const goNext = useGoNext(pipelineStateProp, getData, finalStepSubmit)
  const goPrevious = useGoPrevious()
  return (
    <div>
      <NavigationForm pipelineStateProp={pipelineStateProp} getData={getData} finalStepSubmit={finalStepSubmit} hideControls>
        <SectionHeader
          headline={t.request.create.summary.default.title}
          italicSubline
          description={
            !!state.additionalData.artist
              ? `${t.request.create.summary.default.artistDescriptionPrefix}${state.additionalData.artist.personalDetail.username}`
              : t.request.create.summary.default.description
          }
        />
        <Properties state={state} />
        <div className="p-3">
          <Checkbox checked={acceptTerms} onChange={setAcceptTerms} label={t.request.create.summary.widerrufsrecht.hint} />
        </div>
        <div className="flex flex-col md:flex-row justify-center gap-3 mt-10">
          <NewButton
            className="btn-secondary w-fit mx-auto md:mx-0"
            text={!!state.additionalData.artist ? t.request.create.summary.default.createCTAWithArtist : t.request.create.summary.default.createCTA}
            onClick={goNext}
          />
          <div className="flex flex-row justify-center gap-3">
            {!isBasedOnExistingRequest && (
              <NewButton className="btn-secondary-outline" text={t.request.create.summary.default.backCTA} onClick={goPrevious} />
            )}
            <NewButton className="btn-secondary-outline" text={t.request.create.summary.default.cancelCTA} href={'/'} />
          </div>
        </div>
      </NavigationForm>
    </div>
  )
}

export default Summary
