import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { useState } from 'react'
import Form from './Form/Form'
import { PipelineStateProp, PipelineState, PipelineComponent, ComponentMapping, FinalStepSubmit } from '../types'

export type Props<D> = {
  pipelineStateProp: PipelineStateProp
  Component: PipelineComponent<any>
  componentMapping: ComponentMapping
  submit: FinalStepSubmit<D>
}

const Navigation = <D,>({ pipelineStateProp, componentMapping, Component, submit }: Props<D>) => {
  const state: PipelineState<D> = useSelector((s) => s[props.PIPELINE])
  const [data, setData] = useState(state.data[pipelineStateProp] || {})

  const getFinalStepSubmit = () => {
    const isLastStep = state.currentIndex === componentMapping.length - 1
    return isLastStep ? submit : null
  }

  return (
    <div className="px-6 md:px-0">
      <Component
        pipelineStateProp={pipelineStateProp}
        data={data}
        setData={setData}
        NavigationForm={Form}
        finalStepSubmit={getFinalStepSubmit()}
        componentMapping={componentMapping}
      />
    </div>
  )
}

export default Navigation
