import { useEffect, useState } from 'react'
import { crud } from '@think-internet/zeus-frontend-package'
import props from '../../redux/props'
import { useSelector } from 'react-redux'
import { PurposeFlag } from '../../types/Auth'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import { Booker } from '../../types/Booker'

type Props = (uuid?: string) => [Booker, (booker: keyof Booker) => (data: any) => void]

const useGetBooker: Props = (uuid) => {
  const [booker, setBooker] = useState<Booker>()
  const bookerFeature = crud.use(props.BOOKER)
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])

  const set = (prop: keyof Booker) => (value: any) => {
    setBooker({ ...booker, [prop]: value })
  }

  useEffect(() => {
    const get = async () => {
      if (!!uuid) {
        const booker = await bookerFeature.get({ uuid })
        setBooker(booker)
      } else if (accountLink && accountLink.purposeFlag === PurposeFlag.BOOKER) {
        const booker = await bookerFeature.get({ uuid: accountLink.assignedToUUID })
        setBooker(booker)
      }
    }
    if (!booker) {
      get()
    }
  })

  return [booker, set]
}

export default useGetBooker
