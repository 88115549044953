import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import props from '../../../../redux/props'
import { useEffect, useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../../../toast'
import MediumContainer from '../../../UI/Container/MediumContainer'
import Routes from '../../../../redux/routes'
import Diagrams from './Diagrams/Diagrams'
import List from './List/List'
import { Invoice } from '../../../../types/Invoice'
import Headline from '../../../UI/Headline/Headline'
import useGetArtist from '../../../hooks/useGetArtist'
import Progress from '../Settings/Components/Progress'

const Finance: React.FC<{}> = () => {
  const { uuid } = useParams<{ uuid: string }>()
  const [invoices, setInvoices] = useState<Invoice[]>(null)
  const t = useSelector((s) => s[props.TRANSLATION])
  const listInvoices = functional.use(Routes.LIST_INVOICES)
  const [artist] = useGetArtist()

  useEffect(() => {
    const list = async () => {
      const invoices: Invoice[] = await listInvoices()
      if (Array.isArray(invoices)) {
        setInvoices(invoices)
      } else {
        toast(TYPE.ERROR, t.invoices.error.fetch)
      }
    }
    if (!invoices) {
      list()
    }
  }, [invoices, uuid, t, listInvoices])

  return (
    <>
      <MediumContainer>
        <Headline className="mb-3">{t.invoices.headline}</Headline>
        <div className="mb-10">
          <Progress artist={artist} />
        </div>
        <div className="flex flex-col gap-5">
          <List invoices={invoices} />
          <Diagrams invoices={invoices} />
        </div>
      </MediumContainer>
    </>
  )
}

export default Finance
