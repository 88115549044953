import styled from 'styled-components'

export const StyledFeatures = styled.div`
  margin-top: 80px;
`

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-direction: column;
    gap: 50px;
  }
`

export const Item = styled.div<{ odd?: boolean }>`
  /* ${({ odd }) =>
    odd &&
    `
    margin-top: 150px;
  `} */
  flex: 0 0 1;
  width: calc(50% - 50px / 2);
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    margin-top: 0;
    width: 100%;
  }
`

export const Image = styled.img`
  max-height: 100px;
  max-width: 100px;
`

export const Headline = styled.div`
  padding: 20px 0px;
  color: ${({ theme }) => theme.color.blue.primary};
  font-size: ${({ theme }) => theme.font.size.lg};
  text-align: center;
  font-family: 'bold';
`

export const Text = styled.div`
  color: ${({ theme }) => theme.color.blue.primary};
  font-size: ${({ theme }) => theme.font.size.sm};
  text-align: center;
  padding: 0px 100px;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 0px;
  }
`
