import { useEffect, useState } from 'react'
import { crud } from '@think-internet/zeus-frontend-package'
import { Chat, Message } from '../../types/Chat'
import props from '../../redux/props'

type Props = (chat: Chat | false) => [Message[], (message: Message) => void]

const useGetChatHistory: Props = (chat) => {
  const [messages, setMessages] = useState<Message[]>()
  const chatMessageFeature = crud.use(props.CHAT_MESSAGE)
  const [currentChat, setCurrentChat] = useState<Chat>(null)

  const addMessage = (message: Message) => {
    setMessages([...messages, message])
  }

  useEffect(() => {
    const list = async () => {
      if (!!chat) {
        const messages = await chatMessageFeature.list({ projectUUID: chat.projectUUID })
        setMessages(messages)
        setCurrentChat(chat)
      }
    }
    if ((!messages && chat) || (chat && chat.projectUUID !== currentChat?.projectUUID)) {
      list()
    }
  })

  return [messages, addMessage]
}

export default useGetChatHistory
