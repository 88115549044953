import ComponentMapping from './ComponentMapping'
import Routes from '../../../redux/routes'
import Success from '../SharedComponents/Success/Success'
import Pipeline from '../../UI/Pipeline/Pipeline'
import Headline from '../../UI/Headline/Headline'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { TYPES } from '../../UI/Pipeline/types'
import { BookerBody } from './types'

const Booker: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  return (
    <>
      <Headline>{t.register.booker.headline}</Headline>
      <Pipeline<BookerBody>
        type={TYPES.REGISTER_BOOKER}
        componentMapping={ComponentMapping}
        finalSubmitFunctionalRoute={Routes.REGISTER_BOOKER}
        SuccessComponent={Success}
        hideSteps
      />
    </>
  )
}

export default Booker
