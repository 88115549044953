export enum TYPES {
  REGISTER_ARTIST = 'REGISTER_ARTIST',
  REGISTER_BOOKER = 'REGISTER_BOOKER',
  GLOBAL_DEFAULT_REQUEST = 'GLOBAL_DEFAULT_REQUEST',
  ARTIST_DEFAULT_REQUEST = 'ARTIST_DEFAULT_REQUEST',
  GLOBAL_WORKSHOP_REQUEST = 'GLOBAL_WORKSHOP_REQUEST',
  ARTIST_WORKSHOP_REQUEST = 'ARTIST_WORKSHOP_REQUEST',
  ARTIST_VERIFICATION = 'ARTIST_VERIFICATION',
}

export type Type = string

export interface PipelineState<D, A = Object> {
  currentIndex: number
  type: Type
  data: D
  additionalData?: A
}

export type FinalStepSubmit<Data> = (data: PipelineState<Data>) => void
export type PrefilPipelineStateData<Data> = Data

export type PipelineStateProp = string

export type GetDataResponse = Promise<any> | any
export type GetData = () => GetDataResponse

export type SetData<Data> = (data: Data) => void

export type NavigationForm<ComponentData> = {
  pipelineStateProp: PipelineStateProp
  getData: GetData
  finalStepSubmit: FinalStepSubmit<ComponentData>
  children: React.ReactNode
  hideControls?: boolean
  componentMapping?: ComponentMapping
  prefilPipelineData?: PrefilPipelineStateData<any>
}

export type PipelineComponent<ComponentData> = React.FC<{
  pipelineStateProp: PipelineStateProp
  data: ComponentData
  setData: SetData<ComponentData>
  finalStepSubmit: FinalStepSubmit<ComponentData>
  NavigationForm: React.FC<NavigationForm<ComponentData>>
  componentMapping?: ComponentMapping
}>

export type ComponentMappingItem = {
  component: PipelineComponent<any>
  pipelineStateProp: PipelineStateProp
}

export type ComponentMapping = ComponentMappingItem[]
