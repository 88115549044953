import Modal from '../../../../UI/NewModal/NewModal'
import { useState } from 'react'
import Loading from '../../../../UI/Loading/Loading'
import props from '../../../../../redux/props'
import { useSelector } from 'react-redux'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../../redux/routes'
import toast, { TYPE } from '../../../../../toast'
import { ReactComponent as TrashIcon } from '../../../../../assets/image/request/trash.svg'

type Props = {
  requestUUID: string
  artistUUID: string
}

const RemoveDirectRequest: React.FC<Props> = ({ requestUUID, artistUUID }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [processing, setProcessing] = useState(false)
  const [open, setOpen] = useState(false)
  const removeRequestToArtist = functional.use(Routes.REMOVE_REQUEST_TO_ARTIST)

  const toggle = () => setOpen(!open)

  const confirm = async () => {
    setProcessing(true)
    const result = await removeRequestToArtist({ requestUUID, artistUUID })
    if (!result) {
      toast(TYPE.ERROR, t.booker.request.direct.remove.error)
    } else {
      window.location.replace('/home/booker/request')
    }
  }

  return (
    <div>
      <div onClick={toggle} className="backdrop-blur-sm rounded-sm border py-1 px-2 border-white cursor-pointer group">
        <TrashIcon className="h-5 fill-white group-hover:fill-magenta-primary" />
      </div>

      <Modal show={open} onClose={toggle} onConfirm={!processing ? confirm : null} noForm>
        <div className="font-bold">{t.booker.request.direct.remove.title}</div>
        <Loading loading={!processing} />
        {!processing && <>{t.booker.request.direct.remove.hint}</>}
      </Modal>
    </div>
  )
}

export default RemoveDirectRequest
