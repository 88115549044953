import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { ArtistListItem, Request } from '../../../../../types/Request'
import { formatPrice, getUUID } from '../../../../../utility'
import SectionHeader from '../../../../UI/Pipeline/SectionHeader'
import Property from '../../../../UI/PropertyList/Property'
import PriceCalculator, { PriceCalculatorType } from '../../../../UI/PriceCalculator/PriceCalculator'
import { PipelineState } from '../../../../UI/Pipeline/types'

type Props = {
  state: PipelineState<Request, { artist: ArtistListItem }>
}

const Pricing: React.FC<Props> = ({ state }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const isArtistRequest = !!state.additionalData.artist

  if (isArtistRequest) {
    return (
      <>
        <SectionHeader headline={t.request.create.summary.workshop.price.ofArtist.overview.title} />
        <div className="rounded-sm border border-gray-primary p-2">
          <PriceCalculator type={PriceCalculatorType.REQUEST} artist={state.additionalData.artist} request={state.data} />
        </div>
        <div className="bg-gray-thirdary p-3 mt-3">
          <div className="text-sm">{t.request.create.summary.workshop.price.ofArtist.payment.title}</div>
          {t.request.create.summary.workshop.price.ofArtist.payment.text.map((t: string) => (
            <div className="text-xs mt-2 text-black-secondary" key={getUUID()}>
              {t}
            </div>
          ))}
        </div>
        <div className="bg-blue-thirdary p-3 mt-3">
          <div className="text-sm">{t.request.create.summary.workshop.price.ofArtist.cancellation.title}</div>
          <div className="text-xs mt-2 text-black-secondary">{t.request.create.summary.workshop.price.ofArtist.cancellation.text}</div>
        </div>
        <div className="p-3 mt-3">
          <div className="text-sm">{t.request.create.summary.widerrufsrecht.title}</div>
          {t.request.create.summary.widerrufsrecht.text.map((t: string) => (
            <div className="text-xs mt-2 text-black-secondary" key={getUUID()}>
              {t}
            </div>
          ))}
        </div>
      </>
    )
  } else {
    return (
      <>
        <SectionHeader headline={t.request.create.summary.workshop.price.expectation.title} />
        <Property
          property={{
            name: t.request.create.summary.workshop.price.expectation.total,
            value:
              state.data.budgetExpectation.noAskingPrice === true
                ? t.request.create.summary.workshop.price.expectation.noAskingPrice
                : formatPrice(state.data.budgetExpectation.askingPrice),
          }}
        />
        <div className="bg-gray-thirdary p-3 mt-3">
          <div className="text-sm">{t.request.create.summary.workshop.price.payment.title}</div>
          {t.request.create.summary.workshop.price.payment.text.map((t: string) => (
            <div className="text-xs mt-2 text-black-secondary" key={getUUID()}>
              {t}
            </div>
          ))}
        </div>
        <div className="bg-blue-thirdary p-3 mt-3">
          <div className="text-sm">{t.request.create.summary.workshop.price.cancellation.title}</div>
          <div className="text-xs mt-2 text-black-secondary">{t.request.create.summary.workshop.price.cancellation.text}</div>
        </div>
        <div className="p-3 mt-3">
          <div className="text-sm">{t.request.create.summary.widerrufsrecht.title}</div>
          {t.request.create.summary.widerrufsrecht.text.map((t: string) => (
            <div className="text-xs mt-2 text-black-secondary" key={getUUID()}>
              {t}
            </div>
          ))}
        </div>
      </>
    )
  }
}

export default Pricing
