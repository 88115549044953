export type Length = number
export type Height = number

export type Size = {
  min: number
  max?: number
}

export enum Sizes {
  S = 'S',
  M = 'M',
  L = 'L',
}

export enum SizesFullName {
  S = 'small',
  M = 'medium',
  L = 'large',
}

export type SizeValues = {
  [key in Sizes]: {
    min: number
    max?: number
  }
}

export const sizeValues: SizeValues = {
  [Sizes.S]: {
    min: 1,
    max: 5,
  },
  [Sizes.M]: {
    min: 6,
    max: 30,
  },
  [Sizes.L]: {
    min: 31,
  },
}
