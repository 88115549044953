import { useDispatch, useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { useEffect } from 'react'
import { functional, socket } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../redux/routes'
import { setLocal } from '../../../../redux/action/local'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import sockets from '../../../../redux/sockets'
import Notification from '../../../../types/Notification'

const useNotifications = () => {
  const dispatch = useDispatch()
  const notifications = useSelector((s) => s[props.NOTIFICATION_LIST])
  const listNotifications = functional.use(Routes.LIST_NOTIFICATIONS)
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])
  const token = useSelector((s) => s[props.TOKEN])

  // listen for new notifications and update the list
  socket.use(sockets.NOTIFICATION, (data: Notification) => {
    if (typeof data === 'object') {
      if (!!data.read) {
        const updatedNotifications = notifications.filter((n: Notification) => n.uuid !== data.uuid)
        dispatch(setLocal(props.NOTIFICATION_LIST, updatedNotifications))
      } else {
        dispatch(setLocal(props.NOTIFICATION_LIST, [...notifications, data]))
      }
    }
  })

  useEffect(() => {
    const fetchNotifications = async () => {
      const response = await listNotifications()
      if (Array.isArray(response)) {
        dispatch(setLocal(props.NOTIFICATION_LIST, response))
      }
    }
    // init notifications
    if (!notifications) {
      if (!!accountLink?.level) {
        fetchNotifications()
      }
    }
  }, [notifications, listNotifications, dispatch, accountLink, token])
}

export default useNotifications
