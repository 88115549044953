import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Title from '../Graybox/Title'
import NewButton from '../NewButton/NewButton'

type Props = {
  title?: string
  value: number
  onChange: (value: number) => void
  className?: string
}

const OrderSelection: React.FC<Props> = ({ title, value, onChange, className = '' }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const update = (newValue: number) => () => {
    if (newValue === value) {
      onChange(undefined)
    } else {
      onChange(newValue)
    }
  }

  return (
    <div className={className}>
      {!!title && <Title title={title} className="text-white text-lg md:ml-3 !mb-2" />}
      <div className="flex flex-row gap-3 justify-center md:justify-start">
        <NewButton
          className={`rounded-sm !bg-opacity-10 !bg-white ${value === 1 ? '!border-white' : '!border-transparent'}`}
          onClick={update(1)}
          text={t.UI.search.filter.orderSelection.asc}
        />
        <NewButton
          className={`rounded-sm !bg-opacity-10 !bg-white ${value === -1 ? '!border-white' : '!border-transparent'}`}
          onClick={update(-1)}
          text={t.UI.search.filter.orderSelection.desc}
        />
      </div>
    </div>
  )
}

export default OrderSelection
