import { useSelector } from 'react-redux'
import placeholder from '../../../../../assets/image/invoices/placeholder.jpg'
import { Invoice } from '../../../../../types/Invoice'
import Button from '../../../../UI/NewButton/NewButton'
import props from '../../../../../redux/props'
import Modal from '../../../../UI/NewModal/NewModal'
import { useState } from 'react'

type Props = {
  invoice?: Invoice
}

const Preview: React.FC<Props> = ({ invoice }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false)

  const togglePaymentModal = () => {
    if (showPaymentModal) {
      window.location.reload()
    } else {
      setShowPaymentModal(!showPaymentModal)
    }
  }

  const triggerPayment = () => {
    togglePaymentModal()
    window.open(invoice.payment.paymentURL, '_blank')
  }

  return (
    <div className={`flex items-center justify-center`}>
      <div className="lg:w-8/12">
        {!invoice && <img src={placeholder} alt="" className="w-full" />}
        {!!invoice && invoice.previewImageURL && <img src={invoice.previewImageURL} alt="" className="w-full" />}
        {!!invoice && (
          <div className="flex flex-col items-center mt-3 gap-2">
            {!invoice.payment.completed && <Button className="btn-secondary w-10/12" text={t.invoices.list.cta.pay} onClick={triggerPayment} />}
            <Button className="btn-secondary w-10/12" text={t.invoices.list.cta.download} href={invoice.pdfURL} target="_blank" />
          </div>
        )}
      </div>
      <Modal show={showPaymentModal} onClose={togglePaymentModal} noClose noForm>
        <div className="text-center">{t.booker.finance.paymentModal.title}</div>
        <div className="flex justify-center gap-5 mt-5">
          <Button text={t.booker.finance.paymentModal.options.YES} className="!px-10" onClick={togglePaymentModal} />
          <Button text={t.booker.finance.paymentModal.options.NO} className="!px-10 btn-primary-outline" onClick={togglePaymentModal} />
        </div>
      </Modal>
    </div>
  )
}

export default Preview
