import { getUUID } from '../../../utility'
import Property from './Property'
import { Property as PropertyType } from './types'

type Props = {
  properties: PropertyType[]
  className?: string
  compressedView?: boolean
  contrast?: boolean
  small?: boolean
  splitInHalf?: boolean
}

const PropertyList: React.FC<Props> = ({
  properties,
  className = '',
  compressedView = false,
  contrast = false,
  small = false,
  splitInHalf = false,
}) => {
  return (
    <div className={`flex w-full ${compressedView ? 'flex-wrap gap-x-6' : 'flex-col'} ${className}`}>
      {properties.map((p) => (
        <Property
          key={getUUID()}
          property={p}
          contrast={contrast}
          small={small}
          splitInHalf={splitInHalf}
          className={compressedView ? 'w-[calc(50%-0.75rem)]' : ''}
        />
      ))}
    </div>
  )
}

export default PropertyList
