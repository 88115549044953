import { useEffect, useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import { Chat } from '../../types/Chat'
import Routes from '../../redux/routes'

type Props = () => Chat[]

const useListChats: Props = () => {
  const [chats, setChats] = useState<Chat[]>()
  const listChats = functional.use(Routes.LIST_CHATS)

  useEffect(() => {
    const list = async () => {
      const chats = await listChats()
      setChats(chats)
    }
    if (!chats) {
      list()
    }
  })

  return chats
}

export default useListChats
