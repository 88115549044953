import { MilestoneView } from '../types'
import Milestone from '../Milestone/Milestone'
import { getUUID } from '../../../../utility'
import Separator from '../Milestone/Separator'
import { useEffect } from 'react'
import { MilestoneType } from '../../../../types/Milestone'
import { AddOnStatus } from '../../../../types/Project'

const Single: MilestoneView = ({ project, milestones, isArtist, isAddOnCallback, updateListCallback }) => {
  const getCurrentMilestone = () => {
    const currentMilestone = milestones.find((m) => !m.isCompleted)
    if (!currentMilestone) return milestones[milestones.length - 1]
    return currentMilestone
  }

  useEffect(() => {
    const milestone = getCurrentMilestone()
    if (isAddOnCallback) {
      isAddOnCallback(milestone.type === MilestoneType.PROJECT_ADD_ON && milestone.payload.addOn.status !== AddOnStatus.COMPLETED)
    }
  })

  return (
    <div>
      <Separator />
      <Milestone
        key={getUUID()}
        project={project}
        milestone={getCurrentMilestone()}
        milestones={milestones}
        isArtist={isArtist}
        updateListCallback={updateListCallback}
      />
    </div>
  )
}

export default Single
