import { useSelector } from 'react-redux'
import { Project } from '../../../../../../types/Project'
import GrayBox from '../../../../../UI/Graybox/Graybox'
import Button from '../../../../../UI/NewButton/NewButton'
import props from '../../../../../../redux/props'
import useGetRating from '../../../../../hooks/useGetRating'

type Props = { project: Project }

const GoToRating: React.FC<Props> = ({ project }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const receivedRating = useGetRating(project.uuid)

  if (!!receivedRating === true || receivedRating === undefined) return null
  return (
    <GrayBox className="w-full mb-10 border-4 border-magenta-primary">
      <div className="py-5 text-center text-blue-primary font-bold">{t.project.view.rating.text}</div>
      <div className="lg:absolute right-7 top-0 lg:h-full text-center flex items-center justify-center">
        <Button text={t.project.view.rating.cta} href={`/home/booker/project/rating/${project.uuid}`} className="btn-secondary w-fit !text-sm" />
      </div>
    </GrayBox>
  )
}

export default GoToRating
