import { functional } from '@think-internet/zeus-frontend-package'
import favorite from '../../../assets/image/project/favorite.png'
import favoriteActive from '../../../assets/image/project/favorite-active.png'
import Routes from '../../../redux/routes'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { useState } from 'react'
import { formatNumber } from '../../../utility'

type Props = {
  initIsFavorite: boolean
  initCount?: number
  projectUUID: string
  className?: string
}

const Favorite: React.FC<Props> = ({ initIsFavorite, initCount, projectUUID, className = '' }) => {
  const toggleFavorite = functional.use(Routes.TOGGLE_FAVORITE)
  const accountLink = useSelector((s) => s[props.ACCOUNT_LINK])
  const [status, setStatus] = useState<boolean>(initIsFavorite)
  const [count, setCount] = useState<number>(initCount)

  const toggle = async () => {
    setStatus(!status)
    const updateStatus = await toggleFavorite({ projectUUID })
    if (!updateStatus) {
      setStatus(!status)
    }
    if (status) {
      setCount(count - 1)
    } else {
      setCount(count + 1)
    }
  }

  if (!accountLink) return null
  return (
    <div className={`absolute flex flex-row items-center gap-2 rounded-full bg-white ${className ? className : 'top-3 right-3'}`}>
      {typeof initCount !== 'undefined' && <div className="text-magenta-primary pl-3">{formatNumber(count)}</div>}
      <div className={`cursor-pointer rounded-full ${status ? 'bg-magenta-primary' : ''}`}>
        <img
          src={status ? favoriteActive : favorite}
          className={`transition-all h-7 md:h-8 rounded-full hover:bg-white hover:bg-opacity-30 ${status && 'border border-magenta-primary'}`}
          alt=""
          onClick={toggle}
        />
      </div>
    </div>
  )
}

export default Favorite
