import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import NewButton from '../../NewButton/NewButton'
import Wrapper from '../Item/Wrapper'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'

type Props = {
  onClick: () => void
  isOpen: boolean
  maxVisibleItemsPerRow: number
}

const ShowMore: React.FC<Props> = ({ onClick, isOpen, maxVisibleItemsPerRow = 4 }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <Wrapper maxVisibleItemsPerRow={maxVisibleItemsPerRow}>
      <NewButton
        text={isOpen ? t.UI.selectableTagList.showLess : t.UI.selectableTagList.showMore}
        className="btn-primary-outline !h-6"
        onClick={onClick}
        positionChildrenAtEnd
      >
        {isOpen ? <ChevronUpIcon className="w-3" /> : <ChevronDownIcon className="w-3" />}
      </NewButton>
    </Wrapper>
  )
}

export default ShowMore
