import _ from 'lodash'
import { AddOn as AddOnType, Project } from '../../../../types/Project'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import {
  additionalAttenteesPriceOfAddOn,
  additionalSquareMeterPriceOfAddOn,
  formatPrice,
  getTotalPriceOfProjectAddOn,
  insurancePriceOfAddOn,
  primerPriceOfAddOn,
} from '../../../../utility'
import { Primer } from '../../../../types/Request'
import Currency from '../../NewInput/Currency'
import Number from '../../NewInput/Number'
import Checkbox from '../../Checkbox/Checkbox'
import Input from '../../NewInput/NewInput'
import useListMilestones from '../../../hooks/useListMilestones'
import { MilestoneType } from '../../../../types/Milestone'

type Props = {
  project: Project
  addOn: AddOnType
  onChange?: (addOn: AddOnType) => void
}

type RowProps = {
  name: string
  children?: React.ReactNode
  value?: number | string
  className?: string
  wrappedInput?: boolean
  titleClassName?: string
}
const Row: React.FC<RowProps> = ({ name, children, value, className = '', wrappedInput = false, titleClassName = '' }) => {
  const valueIsMarked = typeof value === 'number' && isFinite(value) && value > 0

  return (
    <div className={`flex flex-row flex-wrap md:flex-nowrap md:gap-7 items-center ${className}`}>
      <div className={`${wrappedInput ? 'w-full' : 'w-1/2'} md:w-2/5 text-gray-primary text-sm truncate ${titleClassName}`}>{name}</div>
      <div className={`${wrappedInput ? 'w-1/2' : 'w-1/2'} md:w-2/5 flex items-center justify-center`}>{children ? children : null}</div>
      {!!value ? (
        <div
          className={`${wrappedInput ? 'w-1/2 text-center md:text-left' : 'w-full'} md:w-1/5 text-sm font-light truncate ${
            valueIsMarked ? 'text-blue-primary' : 'text-gray-primary'
          }`}
        >
          {formatPrice(value)}
        </div>
      ) : (
        <div className={`${wrappedInput ? 'w-1/2 text-center md:text-left' : 'w-full'} md:w-1/5`}></div>
      )}
    </div>
  )
}

const AddOn: React.FC<Props> = ({ project, addOn, onChange }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const isWorkshop = _.has(project, 'attendee.amount') && isFinite(project.attendee.amount)
  const [milestones] = useListMilestones(project.uuid)

  const insuranceIsPossible = () => {
    const insuranceMilestone = milestones.find((m) => m.type === MilestoneType.PROJECT_ADD_ON && !!m.payload.addOn.insuranceAdded)
    if (!!insuranceMilestone) return false
    return (
      (!_.has(project, 'insurance.accepted') || project.insurance.accepted === false) &&
      _.has(project, 'insurance.pricePercentage') &&
      isFinite(project.insurance.pricePercentage)
    )
  }

  const primerIsPossible =
    _.has(project, 'pricing.primerPerSquareMeter') &&
    isFinite(project.pricing.primerPerSquareMeter) &&
    _.has(project, 'sizeAndPrimer.primerRequired') &&
    project.sizeAndPrimer.primerRequired !== Primer.YES

  const getTotalPrice = () => getTotalPriceOfProjectAddOn(project, addOn)

  const set = (key: keyof AddOnType) => (value: any) => onChange({ ...addOn, [key]: value })

  const commonElements = (
    <>
      <Row name={t.priceCalculator.addOn.additionalOtherCosts} value={addOn.additionalOtherCosts} wrappedInput>
        <Currency value={addOn.additionalOtherCosts} onChange={set('additionalOtherCosts')} />
      </Row>
      {!!addOn.additionalOtherCosts && addOn.additionalOtherCosts > 0 && (
        <Row name={t.priceCalculator.addOn.additionalOtherCostsDescription} wrappedInput>
          <Input value={addOn.additionalOtherCostsDescription} maxLength={250} onChange={set('additionalOtherCostsDescription')} />
        </Row>
      )}
      <Row name={t.priceCalculator.addOn.total} value={getTotalPrice()} className="mt-5" titleClassName={'w-full md:1/2'} />
    </>
  )

  if (isWorkshop) {
    return <div className="flex flex-col">{commonElements}</div>
  }
  if (!milestones) return null
  return (
    <div className="flex flex-col gap-3 md:gap-1">
      {!isWorkshop && (
        <>
          <Row name={t.priceCalculator.addOn.concept} value={addOn.conceptAdded ? project.pricing.singleConcept : 0}>
            <Checkbox width="fit-content" checked={addOn.conceptAdded} onChange={set('conceptAdded')} />
          </Row>
          {primerIsPossible && (
            <Row name={t.priceCalculator.addOn.primer} value={primerPriceOfAddOn(project, addOn)}>
              <Checkbox width="fit-content" checked={addOn.primerAdded} onChange={set('primerAdded')} />
            </Row>
          )}
          {insuranceIsPossible() && (
            <Row name={t.priceCalculator.addOn.insurance} value={insurancePriceOfAddOn(project, addOn)} wrappedInput>
              <Checkbox width="fit-content" checked={addOn.insuranceAdded} onChange={set('insuranceAdded')} />
            </Row>
          )}
          <Row name={t.priceCalculator.addOn.additionalSquareMeter} value={additionalSquareMeterPriceOfAddOn(project, addOn)} wrappedInput>
            <Number required value={addOn.additionalSquareMeter} onChange={set('additionalSquareMeter')} suffixText={t.generic.squareMeter} />
          </Row>
        </>
      )}
      {isWorkshop && (
        <Row name={t.priceCalculator.addOn.additionalAttentees} value={additionalAttenteesPriceOfAddOn(project, addOn)} wrappedInput>
          <Number required value={addOn.additionalAttentees} onChange={set('additionalAttentees')} />
        </Row>
      )}
      {commonElements}
    </div>
  )
}

export default AddOn
