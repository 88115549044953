//
// ONLY EDIT THIS FILE IN /frontend/src/
//

import { BusinessType, IdentityCardStatus } from '../types/Artist'
import { MilestoneType } from '../types/Milestone'
import { Type } from '../types/Notification'
import { formatPrice, maxAgeOfOffersInDays, maxPublicRatingTextLength } from '../utility'

export const translation = {
  projectName: 'Qsellart',

  header: {
    search: {
      placeholder: 'Ort, Künstler, Stil',
      placeholderMobile: 'Ort, Künstler',
      cta: 'suchen',
    },
    navigation: {
      default: [
        {
          name: 'Werke',
          link: '/project',
          icon: 'project',
        },
        {
          name: 'Künstler',
          link: '/artist',
          icon: 'artist',
        },
        {
          name: 'Ausschreibungen',
          link: '/request',
          icon: 'publicrequest',
        },
      ],
      ARTIST: {
        dropdown: [
          {
            name: 'Mein Profil',
            link: '/home/artist/profile',
            icon: 'home',
            hiddenOnMobile: true,
          },
          {
            name: 'Einstellungen',
            link: '/home/artist/settings',
            icon: 'settings',
            notificationType: Type.ARTIST_COMPLETE_PROFILE,
          },
        ],
        sidemenu: [
          {
            name: 'Dashboard',
            link: '/home/artist/dashboard',
            icon: 'dashboard',
          },
          {
            name: 'Mein Profil',
            link: '/home/artist/profile',
            icon: 'home',
          },
          {
            name: 'Anfragen',
            link: '/home/artist/request',
            icon: 'request',
            notificationType: Type.NEW_ARTIST_REQUEST,
          },
          {
            name: 'Projekte',
            link: '/home/artist/project',
            icon: 'project',
          },
          {
            name: 'Finanzen',
            link: '/home/artist/finance',
            icon: 'finance',
          },
          {
            name: 'Chat',
            link: '/home/artist/chat',
            icon: 'chat',
            notificationType: Type.NEW_CHAT_MESSAGE,
          },
        ],
      },
      BOOKER: {
        dropdown: [
          {
            name: 'Dashboard',
            link: '/home/booker/dashboard',
            icon: 'dashboard',
            hiddenOnMobile: true,
          },
          {
            name: 'Einstellungen',
            link: '/home/booker/settings',
            icon: 'settings',
          },
        ],
        sidemenu: [
          {
            name: 'Dashboard',
            link: '/home/booker/dashboard',
            icon: 'dashboard',
          },
          {
            name: 'Anfragen',
            link: '/home/booker/request',
            icon: 'request',
            notificationType: Type.NEW_OFFER,
          },
          {
            name: 'Projekte',
            link: '/home/booker/project',
            icon: 'project',
            notificationType: [Type.PROJECT_ADD_ON, Type.PROJECT_COMPLETION, Type.PROJECT_RATING],
          },
          {
            name: 'Finanzen',
            link: '/home/booker/finance',
            icon: 'finance',
            notificationType: Type.NEW_BOOKER_INVOICE,
          },
          {
            name: 'Chat',
            link: '/home/booker/chat',
            icon: 'chat',
            notificationType: Type.NEW_CHAT_MESSAGE,
          },
        ],
      },
      ADMIN: {
        dropdown: [
          {
            name: 'Dashboard',
            link: '/admin/banking',
            icon: 'dashboard',
            hiddenOnMobile: true,
          },
        ],
        sidemenu: [
          {
            name: 'Dashboard',
            link: '/admin/banking',
            icon: 'dashboard',
          },
          {
            name: 'Bankkonten',
            link: '/admin/banking',
            icon: 'finance',
          },
          {
            name: 'Tags',
            link: '/admin/tags',
            icon: 'tag',
          },
        ],
      },
      isNotLoggedIn: {
        name: 'Anmelden',
        link: '/login',
        icon: 'login',
      },
      isLoggedIn: {
        name: 'Abmelden',
        icon: 'logout',
      },
    },
  },

  pipeline: {
    navigation: {
      previous: 'zurück',
      next: 'weiter',
      finish: 'abschließen',
    },
  },

  register: {
    confirm: {
      headline: 'Account bestätigen...',
      confirmed: 'Dein Account wurde erfolgreich bestätigt!',
      redirectCountdownPrefix: 'Du wirst in ',
      redirectCountdownSuffix: ' Sekunden weitergeleitet...',
      cta: 'Bestätigen',
      error: 'Dein Account konnte nicht bestätigt werden.',
    },
    success: {
      booker: {
        headline: 'Sie haben eine E-Mail erhalten!',
        description:
          'Wir haben Ihnen eine Bestätigungsmail an Ihre E-Mail-Adresse geschickt. Bitte bestätigen Sie Ihre E-Mail-Adresse, damit Ihr Konto aktiviert wird.',
        loginCTA: 'Zum Login',
      },
      artist: {
        headline: 'Du hast eine E-Mail erhalten!',
        description:
          'Wir haben dir eine Bestätigungsmail an deine E-Mail-Adresse geschickt. Bitte bestätige deine E-Mail-Adresse, damit dein Konto aktiviert wird.',
      },
      loginCTA: 'Zum Login',
    },
    selection: {
      headline: 'Account Einstellungen',
      description: 'Für welche Zwecke möchtest du Qsellart nutzen?',
      artist: 'Kunst anbieten',
      booker: 'Künstler buchen',
    },
    artist: {
      headline: 'Künstler',
      personalDetail: {
        headline: 'Deine Anmeldeinfos',
        description: 'Nenne uns bitte deinen Künstlernamen und deine Email.',
        usernameIncludesTLD: 'Dein Name darf keine Domain beinhalten.',
        passwordsNotEqual: 'Deine Passwörter stimmen nicht überein.',
        usernameTaken: 'Dieser Künstlername wird bereits vergeben.',
        mailTaken: 'Diese E-Mail-Adresse wird bereits verwendet.',
        dataProtectionHint: {
          prefix: 'Informationen zum Umgang mit persönlichen Daten können der ',
          link: {
            href: '/data-protection',
            text: 'Datenschutzrichtlinie',
          },
          suffix: ' entnommen werden.',
        },
      },
      locationDetail: {
        autocomplete: {
          headline: 'Dein Standort',
          description: 'Wo ist dein Qsellart Einsatzgebiet?',
          error: 'Bitte gib einen Standort an.',
        },
        radius: {
          headline: 'Deine Reichweite?',
          description: 'Wie weit von deinem Standort würdest du für einen Auftrag raus fahren?',
          options: [25, 50, 100, 250],
          error: 'Bitte gib einen Radius an.',
        },
      },
      skills: {
        headline: 'Deine Spezialisierungen',
        description: 'Wähle drei Merkmale aus, die deine Kunst am besten beschreiben.',
        fetchingError: 'Fehler beim Laden der Spezialisierungen',
        error: 'Bitte wähle drei Spezialisierung aus.',
      },
      story: {
        headline: 'Deine Story',
        description: 'Erzähle deinen Auftraggebern wer du bist und was dich inspiriert. Warum ist es eine gute Idee mit dir zusammen zu arbeiten?',
        error: 'Deine Story muss mindestens 150 und maximal 450 Zeichen lang sein.',
      },
      profileImages: {
        headline: 'Dein Profil',
        description: 'Lade dein Profilbild hoch und wähle einen passenden Hintergrund.',
        error: 'Du musst ein Bild von dir, sowie einen gewünschten Hintergrund hochladen.',
      },
      pricing: {
        headline: 'Deine Nettopreise',
        hint: 'm²-Preise verstehen',
        hintText: [
          'Künstler berechnen im Durchschnitt etwa 50 – 100 €/m² für mittelgroße Flächen (M). Für kleinere Flächen (S), wie Stromkästen oder Leinwände, sollten höhere Preise angesetzt werden. Deine m²-Preise sind öffentlich sichtbar, können jedoch nachträglich angepasst und öffentlich ausgeblendet werden.',
          'Im Preis enthalten sind Materialkosten, ein Konzept, Schutzabdeckung, Anfahrt und mehr. Zusätzliche Konzepte, Flächen oder weitere Kosten, können in laufenden Projekten angepasst werden. Die Grundierung wird bei Bedarf zusätzlich berechnet.',
        ],
        art: {
          headline: 'Kunstpreise',
          error: 'Bitte gib deine Kunstpreise an.',
        },
        primer: {
          headline: 'Grundierungpreise',
          disableToggleLabel: 'keine Grundierung anbieten',
          error: 'Bitte gib deine Grundierungspreise an.',
        },
        concept: {
          headline: 'Konzeptpreis',
          hint: 'Konzept einschätzen',
          amount: '1',
          unit: 'Konzept',
          hintText: [
            'Farbwechsel und leichte Korrekturen gehören zu einem Konzept dazu und stellen keinen Wechsel dar, dies entspricht eher einer Überarbeitung.',
            'Ab wann konkret ein Konzept als „neu“ bezeichnet wird, sollte vorab mit dem Auftraggeber besprochen werden.',
            'Ein Konzept ist im Angebot enthalten, jedes weitere Konzept wird zusätzlich berechnet.',
          ],
          error: 'Bitte gib deine Konzeptpreise an.',
        },
        workshop: {
          headline: 'Workshop-Preis',
          hint: 'Workshop-Preise verstehen',
          pricePerAttendee: {
            amount: '1',
            unit: 'Teilnehmer',
          },
          minAttendees: {
            unit: 'Mindestanzahl Teilnehmer',
          },
          hintText:
            'Workshop-Preise werden anhand der Teilnehmeranzahl berechnet. Du erhältst einen fixen Betrag je Teilnehmer. Gib eine Mindestanzahl von Teilnehmern an, die benötigt werden, um einen Workshop bei dir zu buchen. Idealerweise so viele, dass deine Fixkosten gedeckt sind. Jeder weitere Teilnehmer erhöht deine Einnahmen.',
          error: 'Bitte gib deine Workshoppreise an.',
        },
        priceReductionHint:
          'Für die Bereitstellung der Plattform und des Marketings berechnet Qsellart eine 12,5 %-Gebühr. Sie wird mit Abschluss eines jeden Projektes mit deinen Nettopreisen verrechnet.',
        hiddenHint: 'Du kannst deine Preise nach der Registrierung jederzeit anpassen und in der öffentlichen Ansicht verbergen.',
        error: 'Bitte gib deine Preise an.',
      },
      billingDetail: {
        one: {
          headline: 'Rechnungsanschrift',
          description: 'Welche Adresse soll auf deiner Rechnung stehen?',
          error: 'Bitte wähle deine Unternehmensform aus.',
        },
        two: {
          headline: 'Persönliche Angaben',
          description: 'Deine Angaben dienen der Verifizierung und sind nicht öffentlich zu sehen.',
          ageError: 'Du musst mindestens 18 Jahre alt sein.',
        },
      },
      invoiceDetail: {
        headline: 'Deine Rechnung',
        description: 'Wir erstellen dir deine Rechnung damit du mehr Zeit hast für deine Kunst',
        smallBusinessRegulation: {
          toggle: 'Ich benutze die Kleinunternehmerregelung',
        },
        error: 'Bitte gib deine Rechnungsdaten an.',
      },
    },
    booker: {
      headline: 'Auftraggeber',
      accountDetail: {
        headline: 'Ihre Anmeldeinfos',
        description: 'Bitte geben Sie Ihre E-Mail-Adresse und ein Passwort ein.',
        passwordsNotEqual: 'Deine Passwörter stimmen nicht überein.',
        mailTaken: 'Diese E-Mail-Adresse wird bereits verwendet.',
      },
      billingDetail: {
        headline: 'Ihre Rechungsanschrift',
        description: 'Welcher Name und welche Adresse sollen auf Ihrer Rechnung stehen?',
      },
    },
  },

  login: {
    headline: 'Willkommen',
    description: 'Wenn du bereits einen Account hast melde dich gerne an.',
    cta: 'Anmelden',
    registerCTA: 'Registrieren',
    error: 'Deine E-Mail-Adresse oder dein Passwort ist falsch.',
  },

  lostPassword: {
    requestReset: {
      title: 'Passwort vergessen?',
      success: 'Wir haben dir eine E-Mail mit einem Link zum Zurücksetzen deines Passworts gesendet.',
      error: 'Bitte verwende deine richtige E-Mail-Adresse und versuche es erneut.',
    },
    set: {
      title: 'Passwort zurücksetzen',
      description: 'Bitte hier gib dein neues Passwort ein.',
      success: 'Dein neues Passwort wurde erfolgreich gespeichert.',
    },
  },

  newMailConfirm: {
    title: 'E-Mail-Adresse bestätigen',
    success: 'Deine neue E-Mail-Adresse wurde erfolgreich gespeichert.',
    error: 'Deine E-Mail-Adresse konnte nicht bestätigt werden.',
  },

  artist: {
    dashboard: {
      title: 'Dashboard',
      statistics: {
        title: 'Statistiken',
        favorites: 'Als Favorit\nmarkiert',
        profileViewsOfMonth: (currentMonth) => `Profilansichten\n${currentMonth}`,
        profileViewsTotal: 'Profilansichten\ngesamt',
      },
      qrCode: {
        title: 'Persönlicher QR Code',
        hint: 'Hier ist dein persönlichen QR Code, z. B. für Visitenkarten.',
        cta: 'download',
      },
      calendar: {
        title: 'Kalender',
        error: {
          loadingProject: 'Fehler beim Laden deiner Projekte.',
        },
      },
      favorites: {
        title: 'Favoriten',
        empty: 'Du hast noch keine Favoriten.',
      },
      recommendation: {
        title: 'Das könnte dich auch interessieren',
      },
    },
    profile: {
      title: 'Mein Profil',
      externalViewCTA: 'Mein Profil ansehen',
      images: {
        edit: {
          error: 'Dein Bild konnte nicht gespeichert werden.',
        },
      },
      location: {
        title: 'Einsatzort',
        radius: ' km Radius um ',
        edit: {
          addressTitle: 'Wo ist dein Qsellart Einsatzgebiet?',
          radiusTitle: 'Deine Reichweite',
          radiusHint: 'Wie weit von deinem Standort würdest Du für einen Auftrag raus fahren?',
          error: 'Dein Einsatzort konnte nicht gespeichert werden.',
        },
      },
      skills: {
        title: 'Spezialisierungen',
        hint: 'Wähle drei Merkmale aus, die deine Kunst am besten beschreiben. Auftraggeber können dich damit einfacher finden.',
        edit: {
          error: 'Deine Spezialisierungen konnten nicht gespeichert werden.',
          errorAmount: 'Du musst drei Spezialisierungen auswählen.',
        },
      },
      insurance: {
        headline: {
          values: 'Schutzversicherung',
          text: 'So funktioniert deine Versicherung',
        },
        text1:
          'Im Falle einer Verunreinigung des Werkes durch Vandalismus o.Ä., verpflichtest du dich einmalig, den Schaden zu beseitigen, bzw. den Originalzustand des Werkes wieder herzustellen. Dabei bestimmst du individuell das Ausmaß und den Wirkungsgrad der Versicherung.',
        text2: 'Vervollständige folgende Angaben, damit sich der Auftraggeber für deine Versicherung entscheiden kann:',
        bulletpoints: [
          'Die Anzahl der Monate nach Fertigstellung, in denen die Versicherung greift.',
          'Den Radius von deinem Einsatzort, in dem die Versicherung wirkt.',
          'Den prozentuellen Anteil der Gesamtkosten eines Projektes, die deine Versicherung kostet.',
          'Den zeitlichen Aufwand, gemessen in Stunden, der zur Beseitigung des Schadens anfallen darf.',
        ],
        enabled: {
          statusLabel: 'Status: ',
          status: {
            enabled: 'Aktiviert',
            disabled: 'Deaktiviert',
          },
        },
        radius: 'Umkreis',
        pricePercentage: 'Kosten',
        timespanInMonths: 'Zeitraum',
        timeExpenseInHours: 'Aufwand',
        edit: {
          error: 'Bitte verfollständige deine Angaben oder deaktiviere die Schutzversicherung.',
          errorGeneric: 'Deine Schutzversicherung konnten nicht gespeichert werden.',
        },
      },
      story: {
        title: 'Meine Story',
        edit: {
          error: 'Deine Story muss mindestens 150 und maximal 450 Zeichen lang sein.',
          errorGeneric: 'Deine Story konnten nicht gespeichert werden.',
        },
      },
      downPayment: {
        title: 'Anzahlung',
        hint: 'Bestimme die Höhe deiner Anzahlung, die du zu Beginn eines Projektes erhältst. Wähle einen Wert zwischen 10 % und 70 % aus.',
        text: (percentage: number) => `Mit jedem Projekt erhältst du eine Anzahlung von ${percentage} %`,
        edit: {
          error: 'Deine Anzahlung muss zwischen 10 und 70 % liegen.',
          errorGeneric: 'Deine Anzahlung konnten nicht gespeichert werden.',
        },
      },
      pricing: {
        hideToggle: 'Preise öffentlich verbergen',
      },
      porfolio: {
        title: 'Galerie',
        hint: 'Zeige hier mit einem Fotoupload dein Talent. Lade mindestens vier deiner Lieblingsbilder hoch und mache auf dich aufmerksam.',
        hintEmpty: [
          'Zeige hier mit einem Fotoupload, Arbeiten, die deine Kunst am besten beschreiben.',
          'Die Galerie ist dein Aushängeschild und lädt Auftraggeber ein, sich dein Profil genauer anzuschauen.',
        ],
        edit: {
          errorGeneric: 'Deine Portfolio konnte nicht gespeichert werden.',
        },
      },
      externalProjects: {
        title: 'Externe Projekte',
        noProjects: 'Zeige hier deine Zusammenarbeit mit ehemaligen Auftraggebern, die außerhalb der Qsellart Plattform erfolgten.',
        list: {
          sendVerificationRequestAgain: {
            cta: 'Anfrage erneut senden',
            success: 'Anfrage erneut erfolgreich gesendet.',
            error: 'Anfrage konnte nicht erneut gesendet werden. Du kannst jede 60 Minuten eine neue Anfrage senden.',
          },
          delete: {
            request: 'Anfrage löschen',
            project: 'Projekt löschen',
            success: 'Dein Projekt wurde erfolgreich gelöscht.',
            error: 'Dein Projekt konnte nicht gelöscht werden.',
            modal: {
              title: 'Projekt löschen',
              text: 'Möchtest du dieses Projekt wirklich löschen?',
            },
          },
          error: 'Fehler beim Laden der externen Projekte',
        },
        edit: {
          title: 'Externes Projekt erstellen',
          intro:
            'Lege ein Projekt an, das außerhalb der Qsellart-Plattform entstanden ist. Je mehr Projekte dein Profil aufweist, desto mehr Erfahrung strahlst du im Umgang mit Auftraggebern aus. Das schafft Vertrauen und bringt mehr Aufträge.',
          description: 'Beschreibe das Projekt mit folgenden Infos:',
          verifiedByClientName: 'Auftraggeber',
          size: 'Größe',
          date: 'Umsetzungsdatum',
          tags: {
            text: 'Mit den Tags kannst du detailliertere Angaben machen und schneller gefunden werden',
            cta: 'Tags',
            modal: {
              title: 'Tags für dein Projekt',
              object: 'Objekt',
              style: 'Stil',
            },
          },
          process: {
            title: 'Externes Projekt verifizieren',
            text: [
              'Bereits abgeschlossene Projekte können von den jeweiligen Auftraggebern per Mail verifiziert werden.',
              'Dein ehemaliger Auftraggeber erhält eine Mail, wie unten formuliert und kann mit wenigen Klicks, ohne Anmeldung, das Projekt bestätigen.',
              'Die Verifizierung erfolgt anhand der E-Mail-Domain deiner Kontaktperson und ist öffentlich sichtbar.',
            ],
          },
          name: 'Projektname',
          verifiedByClientMail: 'Mailadresse des Auftraggebers',
          verificationTextPlaceholder: 'Deine Nachricht an den Auftraggeber',
          verificationTextTemplate:
            'Guten Tag,\n\nvor einiger Zeit haben wir gemeinsam ein Kunstprojekt umgesetzt. Ich möchte dieses Projekt als Referenz verwenden und benötige Ihre kurze Bestätigung, dass es sich um eine gemeinsame Arbeit handelte. Sie würden mir damit einen großen Gefallen tun.\n\nDie Bestätigung erfolgt über den nachfolgenden Link. Eine Anmeldung ist nicht erforderlich.\n\nVielen Dank im Voraus.',

          error: {
            objectTag: 'Bitte wähle einen passenden Objekt-Tag.',
            styleTag: 'Bitte wähle mindestens einen Style-Tag.',
            deadlineDate: 'Ein externes Projekt kann nicht in der Zukunft liegen.',
            designImages: 'Bitte lade mindestens ein Bild deines Projekts hoch.',
            generic: 'Dein externes Projekt konnte nicht gespeichert werden.',
          },
          success: 'Wir haben deinem Auftraggeber eine Mail mit einem Link zur Verifizierung deines Projekts gesendet.',
        },
      },
      rating: {
        projectAmountSuffix: ' Aufträge',
        ratingAmountSuffix: ' Meinungen',
      },
      viewCTA: 'Profil ansehen',
      createRequestCTA: 'Anfrage stellen',
    },
    settings: {
      title: 'Accounteinstellungen',
      mail: {
        title: 'E-Mail-Adresse ändern',
        hint: 'Deine aktuelle E-Mail-Adresse laut:',
        edit: {
          hint: 'Bitte gib deine neue E-Mail-Adresse ein:',
          success: 'Wir haben dir eine E-Mail mit einem Link zum Bestätigen deiner neuen E-Mail-Adresse gesendet.',
          error: 'Deine neue E-Mail-Adresse konnten nicht gespeichert werden.',
        },
      },
      password: {
        title: 'Passwort ändern',
        hint: 'Hier kannst du dein Passwort ändern.',
        edit: {
          hint: 'Bitte gib deine neues Passwort ein:',
          success: 'Dein Passwort wurde erfolgreich geändert.',
          error: 'Dein Passwort konnte nicht geändert werden.',
          passwordsNotEqual: 'Deine Passwörter stimmen nicht überein.',
        },
      },
      notification: {
        title: 'Benachrichtigungen',
        hint: 'Hier kannst du deine Nachrichteneingänge verwalten. Wähle aus, was dich interessiert und werde per E-Mail informiert.',
        error: 'Deine Benachrichtigungseinstellungen konnten nicht geladen werden.',
        item: {
          mail: 'E-Mail',
          enableAll: 'Alle Benachrichtigungen aktivieren',
        },
        edit: {
          error: 'Deine Benachrichtigungseinstellungen konnten nicht gespeichert werden.',
        },
      },
      billing: {
        title: 'Rechnungsanschrift',
        edit: {
          hint: 'Damit deine Rechnungen korrekt erstellt werden, benötigen wir deinen vollständigen Namen und die Adresse deines Unternehmens. Dein Geburtsdatum und deine Telefonnummer sind für den reibungslosen Zahlungsablauf über unseren Treuhand-Partner Stripe Payments Europe erforderlich.',
          error: 'Deine Rechnungsdaten konnte nicht aktualisiert werden.',
        },
      },
      personalDetail: {
        title: 'Benutzername',
        edit: {
          hint: 'Hier kannst du deinen Benutzernamen ändern. Bitte beachte, dass dein Benutzername öffentlich sichtbar ist.',
          error: 'Dein Benutzername konnte nicht aktualisiert werden.',
        },
      },
      tax: {
        title: 'Steuerinformationen',
        edit: {
          hint: 'Damit deine Rechnung vollständig ist, werden die relevanten Steuerinformationen deines Unternehmens benötigt.',
          error: 'Deine Steuerinformationen konnte nicht aktualisiert werden.',
        },
      },
      documents: {
        title: 'Dein Ausweis',
        identityCard: {
          hint: {
            [IdentityCardStatus.MISSING]:
              'Du hast noch keine Identifikation durchgeführt. Hinterlege hier deinen Ausweis, damit du als Kontoinhaber verifiziert wirst.',
            [IdentityCardStatus.PENDING]: 'Dein Upload ist bei uns eingegangen. Du erhältst eine E-Mail sobald deine Ausweis verifiziert wurde.',
            [IdentityCardStatus.DECLINED]:
              'Dein Ausweis konnten nicht verifiziert werden, bitte achte darauf das alle Daten gut lesbar sind und deine Ausweisdaten mit den angegebenen persönlichen Daten übereinstimmen.',
            [IdentityCardStatus.VERIFIED]: 'Dein Ausweis wurden erfolgreich verifiziert.',
          },
        },
        edit: {
          individual: {
            error: 'Bitte lade alle Dokumente hoch.',
            errorGeneric: 'Dein Personalausweis konnte nicht verarbeitet werden.',
            front: 'Vorderseite deines Personalausweises',
            back: 'Rückseite deines Personalausweises',
          },
        },
      },
      bankAccount: {
        title: 'Bankverbindung',
        hint: {
          [IdentityCardStatus.MISSING]:
            'Du hast noch keine Bankverbindung angegeben. Deine Bankdaten werden benötigt, damit du Auszahlungen erhalten kannst.',
          [IdentityCardStatus.PENDING]:
            'Wir haben deine Bankverbindung erhalten und werden diese in Kürze prüfen. Du erhältst eine E-Mail sobald deine Bankverbindung verifiziert wurde.',
          [IdentityCardStatus.DECLINED]:
            'Deine Bankverbindung konnten nicht verifiziert werden, bitte versuche es erneut und achte darauf das alle deine Daten korrekt sind.',
          [IdentityCardStatus.VERIFIED]: 'Deine Bankverbindung wurde erfolgreich verifiziert.',
        },
        edit: {
          errorIBAN: 'Bitte gib eine gültige IBAN ein.',
          errorFill: 'Bitte fülle alle Felder aus.',
          errorGeneric: 'Deine Bankverbindung konnte nicht verarbeitet werden.',
        },
      },
      progress: {
        hint: 'Es fehlen noch Infos, um dein Profil freizuschalten.',
        cta: 'Mein Profil vervollständigen',
      },
    },
    request: {
      title: 'Anfragen',
      directTitle: 'Projektanfragen',
      publicTitle: 'Ausschreibungen',
      error: 'Fehler beim Laden deiner Anfragen',
      list: {
        placeholder: {
          title: 'Aktuell keine Anfragen',
          text: 'Aktuell liegen für dich keine Anfragen oder Ausschreibungen vor. Um mehr Aufträge zu erhalten, könntest du dein Profil aufwerten. Optimiere deine Fähigkeiten oder deine Preise, erweitere dein Portfolio und deine externen Projekte. Potentielle Auftraggeber können mit Referenzen überzeugt werden.',
        },
      },
    },
    project: {
      list: {
        titlePlaceholder: 'Projekte',
        titleInProgress: 'Laufende Projekte',
        titleCompleted: 'Abgeschlossene Projekte',
        error: 'Fehler beim Laden deiner Project',
        placeholder: {
          title: 'Aktuell keine Projekte',
          text: 'Hier kannst du laufende Projekte führen und abgeschlossene Projekte bearbeiten. Um Aufträge zu erhalten und Projekte zu führen, könntest du dein Profil aufwerten. Erweitere dein Portfolio oder deine externen Projekte. Potentielle Auftraggeber können mit Referenzen überzeugt werden.',
        },
      },
      toggleVisibility: {
        headline: 'Sichtbarkeit',
        title: 'Projekt verbergen',
        textIsActive: 'Projekt ist öffentlich sichtbar',
        textIsInactive: 'Projekt ist nicht öffentlich sichtbar',
        textIsActiveSmall: 'öffentlich sichtbar',
        textIsInactiveSmall: 'nicht öffentlich sichtbar',
      },
    },
    search: {
      headline: 'Künstler',
      view: {
        portfolio: {
          title: 'Galerie',
        },
        story: {
          title: 'Über den Künstler',
        },
        reviews: {
          title: 'Das sagen Auftraggeber',
          empty: 'Dieser Künstler hat noch keine Bewertungen.',
        },
        pricing: {
          title: 'Preise',
          hint: 'Alle Infos zu Preisen anzeigen',
          conceptSizeText: 'Konzept',
          workshopSizeText: 'Workshop',
        },
        projects: {
          title: 'Abgeschlossene Projekte',
        },
      },
      filter: {
        order: {
          asc: 'aufsteigend',
          desc: 'absteigend',
        },
        price: {
          title: 'Preis',
        },
        size: {
          title: 'Größe',
        },
        rating: {
          title: 'Bewertung',
        },
      },
      error: 'Fehler beim Laden der Künstler',
    },
  },

  booker: {
    dashboard: {
      title: 'Dashboard',
      startProject: {
        title: 'Ein neues Projekt starten',
        cta: 'Projekt starten',
      },
      calendar: {
        title: 'Kalender',
        error: {
          loadingProject: 'Fehler beim Laden Ihrer Projekte.',
        },
      },
      favorites: {
        title: 'Favoriten',
        empty: 'Sie haben noch keine Favoriten.',
      },
      recommendation: {
        title: 'Das könnte Sie auch interessieren',
      },
    },
    settings: {
      title: 'Accounteinstellungen',
      billing: {
        title: 'Rechnungsanschrift',
        edit: {
          hint: 'Geben Sie hier bitte den Namen und die Adresse ein. Die Rechnungen, die Sie vom Künstler erhalten, werden diese Anschrift aufweisen.',
          error: 'Deine Rechnungsdaten konnte nicht aktualisiert werden.',
        },
      },
      mail: {
        title: 'E-Mail-Adresse ändern',
        hint: 'Ihre aktuelle E-Mail-Adresse laut:',
        edit: {
          hint: 'Bitte geben Sie Ihre neue E-Mail-Adresse ein:',
          success: 'Wir haben dir eine E-Mail mit einem Link zum Bestätigen deiner neuen E-Mail-Adresse gesendet.',
          error: 'Deine neue E-Mail-Adresse konnten nicht gespeichert werden.',
        },
      },
      password: {
        title: 'Passwort ändern',
        hint: 'Hier können Sie Ihr Passwort ändern.',
        edit: {
          hint: 'Bitte gib Sie Ihr neues Passwort ein:',
          success: 'Dein Passwort wurde erfolgreich geändert.',
          error: 'Dein Passwort konnte nicht geändert werden.',
          passwordsNotEqual: 'Deine Passwörter stimmen nicht überein.',
        },
      },
      profileImages: {
        title: 'Profilbild',
        hint: 'Hier können Sie Ihr Profilbild ändern.',
        edit: {
          error: 'Dein Profilbild konnte nicht gespeichert werden.',
        },
      },
      notification: {
        title: 'Benachrichtigungen',
        hint: 'Hier können Sie Ihre Nachrichteneingänge verwalten. Wählen Sie, was Sie nicht verpassen möchten und werden Sie per E-Mail informiert.',
        error: 'Deine Benachrichtigungseinstellungen konnten nicht geladen werden.',
        item: {
          mail: 'E-Mail',
          enableAll: 'Alle Benachrichtigungen aktivieren',
          prefix: 'Benachrichtigungen für ',
        },
        edit: {
          error: 'Deine Benachrichtigungseinstellungen konnten nicht gespeichert werden.',
        },
      },
    },
    request: {
      title: 'Anfragen',
      directTitle: 'Direkte Anfragen',
      publicTitle: 'Ausschreibungen',
      reverseOfferTitle: 'Gegenangebote',
      offerTitle: 'Angebote',
      error: 'Fehler beim Laden deiner Anfragen',
      direct: {
        remove: {
          title: 'Anfrage löschen',
          hint: 'Möchten Sie Ihre Anfrage wirklich löschen?',
          success: 'Ihre Anfrage wurde erfolgreich gelöscht.',
          error: 'Ihre Anfrage konnte nicht gelöscht werden.',
        },
      },
      list: {
        placeholder: {
          title: 'Keine offenen Anfragen vorhanden',
          text: 'Sobald Sie einen Künstler direkt anfragen oder eine Ausschreibung erstellen, erhalten Sie an dieser Stelle eine Übersicht Ihrer potentiellen Projekte. In der Detailansicht Ihrer Anfragen sehen Sie welche Künstler sich auf Ihre Anfrage beworben haben oder welches Gegenangebot Ihnen vorliegt.',
        },
      },
    },
    project: {
      list: {
        titlePlaceholder: 'Projekte',
        titleInProgress: 'Laufende Projekte',
        titleCompleted: 'Abgeschlossene Projekte',
        error: 'Fehler beim Laden deiner Project',
        placeholder: {
          title: 'Keine laufenden Projekte vorhanden',
          text: 'Sobald Sie sich mit dem Künstler auf ein Kunstprojekt geeinigt haben, finden Sie an dieser Stelle alle notwendigen Infos dazu. Sie behalten die Kosten und den Stand Ihres Projektes im Überblick, sprechen Änderungen oder Termine ab und entscheiden darüber, ob das Projekt abgeschlossen ist und der Künstler seine Abschlusszahlung erhält.',
        },
      },
      view: {
        titlePrefix: 'Projekt: ',
      },
    },
    finance: {
      paymentModal: {
        title: 'Haben Sie die Rechnung bezahlt?',
        options: {
          YES: 'Ja',
          NO: 'Nein',
        },
      },
    },
  },

  notificationSettingsTopicMapping: {
    artist: {
      DIRECT_REQUEST: 'Neue Projektanfrage',
      CHAT_MESSAGE: 'Chat-Nachricht',
      PROJECT_CHANGE: 'Projektänderungen',
      REMINDER: 'Erinnerung',
      NEWSLETTER: 'Newsletter',
    },
    booker: {
      CHAT_MESSAGE: 'Chat-Nachricht',
      PROJECT_CHANGE: 'Projektänderungen',
      REMINDER: 'Erinnerung',
      NEWSLETTER: 'Newsletter',
    },
  },

  admin: {
    tags: {
      headline: 'Tags',
      list: {
        error: 'Fehler beim Laden der Tags',
      },
      upsert: {
        name: 'Name',
        cta: 'Tag hinzufügen',
        error: 'Fehler beim Speichern',
      },
    },
    banking: {
      accounts: {
        headline: 'Bankkonten',
        list: {
          error: 'Fehler beim Laden der Bankkonten',
        },
      },
    },
  },

  tag: {
    category: {
      OBJECT: 'Objekt',
      SURFACE: 'Untergrund',
      EQUIPMENT: 'Ausrüstung',
      STYLE: 'Stil',
    },
  },

  request: {
    search: {
      headline: 'Ausschreibungen',
      createNewGlobalCTA: 'Ausschreibung erstellen',
      goTo: 'Zur Ausschreibung',
      goToDirect: 'Zur Anfrage',
      loginHint: 'Melde dich an, um an der Ausschreibung teilzunehmen',
      loginCTA: 'Jetzt Anmelden',
      properties: {
        title: 'Titel',
        location: 'Standort',
        object: 'Objekt',
        surface: 'Untergrund',
        equipment: 'Hilfsmittel',
        equipmentUnsure: 'Unsicher',
        equipmentAvailable: {
          name: 'Hilfsmittel vorhanden',
          YES: 'vorhanden',
          NO: 'nicht vorhanden',
        },
        size: 'Größe',
        primer: 'Grundierung benötigt',
        deadlineDate: 'Fertigstellung bis',
        workshopDate: 'Workshop-Tag:',
        imagePrefix: 'Bilder: ',
        attendee: {
          amount: 'Teilnehmer',
          suffix: ' Personen',
          motto: 'Motto',
        },
        insurance: {
          name: 'Schutzversicherung',
          YES: 'Mit Versicherung',
          NO: 'Ohne Versicherung',
        },
        budgetExpectation: {
          title: 'Budgetvorstellung',
          noAskingPrice: 'Keine Angabe',
          total: 'Gesamtkosten',
        },
        design: {
          title: 'Motiv/Gestaltungswunsch',
          titleWorkshop: 'Foto-Upload',
        },
      },
      filter: {
        order: {
          asc: 'aufsteigend',
          desc: 'absteigend',
        },
        price: {
          title: 'Preis',
          rangeTitle: 'Preisspanne',
          noAskingPrice: 'Ohne Preisvorstellung',
        },
        size: {
          title: 'Größe',
        },
        date: {
          title: 'Datum',
        },
      },
    },
    primerOptions: {
      YES: 'Ja',
      NO: 'Nein',
      UNSURE: 'Unsicher',
    },
    validUntil: {
      prefix: 'Gültigkeit: noch ',
      suffix: ' Tage',
    },
    create: {
      pipelineSelection: {
        forArtist: {
          headlinePrefix: 'Projektanfrage an ',
        },
        global: {
          headline: 'Projektanfrage',
        },
        default: {
          text: 'Der Künstler entwickelt aus den Motivwünschen des Auftraggebers ein passendes Konzept und betreut den Projektablauf.',
          cta: 'Auftragsarbeit',
        },
        workshop: {
          text: 'Ein Workshop besteht aus mehreren Teilnehmern, die aktiv Kunst erleben. Der Künstler organisiert den Ablauf und betreut die Teilnehmer mit wertvollen Tipps. So entsteht ein einzigartiges Erlebnis.',
          cta: 'Workshop',
        },
      },
      nameAndLocation: {
        exisitingProject: {
          title: 'Bestehendes Projekt',
          description: 'Bestehendes Projekt um einen zusätzlichen Künstler erweitern',
          emptySelection: 'Nicht ausgewählt',
          error: 'Bestehende Ausschreibungen konnten nicht geladen werden.',
        },
        name: {
          title: 'Neues Projekt',
          description: 'Geben Sie Ihrem Projekt einen Namen.',
          placeholder: 'Projektname',
        },
        location: {
          title: 'An welchem Ort soll der Künstler tätig werden?',
          error: 'Bitte geben Sie einen Namen und einen Ort ein.',
        },
      },
      tags: {
        object: {
          title: 'Was darf der Künstler machen?',
          error: 'Bitte wählen Sie ein passendes Objekt.',
        },
        surface: {
          title: 'Kennen Sie den Untergrund?',
          error: 'Bitte wählen Sie einen passenden Untergrund.',
        },
        equipment: {
          title: 'Braucht der Künstler Hilfsmittel für seine Arbeit?',
          error: 'Bitte wählen Sie ein passendes Hilfsmittel.',
        },
        equipmentAvailable: {
          title: 'Haben Sie die Hilfmittel vor Ort?',
          yes: 'Ja',
          no: 'Nein',
        },
      },
      sizeAndPrimer: {
        size: {
          title: 'Wie groß ist die Fläche?',
          description: 'Geben Sie die (ungefähren) Maße der Fläche an',
        },
        primerRequired: {
          description: 'Muss die Fläche grundiert oder vorbehandelt werden?',
          noPrimerAvailable: 'Dieser Künstler bietet keine Grundierung an.',
          error: 'Bitte geben Sie an, ob eine Grundierung benötigt wird.',
        },
      },
      insurance: {
        title: 'Schutzversicherung',
        description: 'Dieser Künstler bietet eine Schutzversicherung an.',
        error: 'Bitte wählen Sie ob Sie eine Schutzversicherung wünschen.',
        price: 'Kosten',
        timespanInMonths: 'Garantie',
        timeExpenseInHours: 'Aufwand',
        cta: {
          accept: 'Akzeptieren',
          decline: 'Ablehnen',
        },
        info: {
          title: 'Info',
          text: 'Mit der Versicherung erhalten Sie vom Künstler eine Garantie vor Verunreinigung des Werkes für den angegebenen Zeitraum. Verunreinigungen werden mit dem angegebenen Aufwand, einmalig beseitigt',
        },
      },
      deadline: {
        title: 'Termin',
        description: 'Bis wann möchten Sie das Werk umsetzen?',
        descriptionWorkshop: 'Wann soll der Workshop stattfinden?',
        error: 'Bitte geben Sie einen Termin in der Zukunft an.',
      },
      design: {
        title: 'Foto Upload',
        description: [
          'Anhand der Fotos, kann sich der Künstler einen noch besseren Eindruck von Ihrem Projekt machen.',
          'Bitte Laden Sie Fotos, Skizzen oder ähnliches von Ihrem Projekt hoch.',
        ],
        text: {
          title: 'Motiv/Gestaltungswunsch',
          placeholder: 'Bitte beschreiben Sie hier Ihr Projekt.',
          description: 'Welche Ideen, Wünsche und Vorstellungen haben Sie für Ihr Projekt? Soll das Werk ein bestimmtes Gefühl auslösen?',
        },
        workshop: {
          title: 'Foto-Upload',
          description: 'Zeigen Sie dem Künstler wo der Workshop stattfindet.',
        },
        error: {
          text: 'Bitte beschreiben Sie hier Ihr Projekt.',
          image: 'Bitte laden Sie mindestens ein Bild hoch.',
        },
      },
      budgetExpectation: {
        title: 'Budgetvorstellung',
        noAskingPrice: 'Keine Angabe',
        error: 'Bitte geben Sie ein Budget an oder wählen Sie "Keine Angabe".',
      },
      summary: {
        widerrufsrecht: {
          title: 'Widerrufsrecht',
          text: [
            'Bitte beachten Sie, dass das Widerrufsrecht bei individuell angefertigten Dienstleistungen entfällt, sobald der Vertrag abgeschlossen ist und der Künstler mit der Dienstleistung beginnt (§ 312g Abs. 2 Nr. 1 BGB). Sie erklären sich ausdrücklich damit einverstanden, dass der Künstler vor Ablauf der Widerrufsfrist mit der Ausführung beginnen darf.',
          ],
          hint: 'Widerrufsrecht gelesen und akzeptiert',
          error: 'Bitte akzeptieren Sie das Widerrufsrecht.',
        },
        default: {
          title: 'Zusammenfassung',
          description: 'Allgemeine Ausschreibung',
          artistDescriptionPrefix: 'Projektanfrage an ',
          createCTA: 'Ausschreibung erstellen',
          createCTAWithArtist: 'Anfrage abschließen',
          backCTA: 'zurück',
          cancelCTA: 'abbrechen',
          properties: {
            title: 'Titel',
            location: 'Standort',
            object: 'Objekt',
            surface: 'Untergrund',
            equipment: 'Hilfsmittel',
            equipmentUnsure: 'unsicher',
            equipmentAvailable: {
              name: 'Hilfsmittel vorhanden',
              YES: 'vorhanden',
              NO: 'nicht vorhanden',
            },
            insurance: {
              name: 'Schutzversicherung',
              YES: 'Mit Versicherung',
              NO: 'Ohne Versicherung',
            },
            size: 'Größe',
            primer: 'Grundierung benötigt',
            deadlineDate: 'Fertigstellung bis',
          },
          price: {
            ofArtist: {
              concept: {
                title: 'Konzept',
                description:
                  'Anhand Ihrer Gestaltungsideen und Bilder erstellt der Künstler ein passendes Konzept. Dieses Konzept, auch Entwurf genannt, zeigt, wie Ihr Objekt nach der Fertigstellung aussehen könnte. Da die Erstellung eines Konzepts mit einem hohen Aufwand verbunden ist, werden ab dem zweiten Konzept zusätzliche Kosten berechnet. Das erste Konzept ist im Preis inbegriffen.',
                additionalConcept: {
                  prefix: 'Preis für jedes zusätzliche Konzept von ',
                  suffix: ':',
                },
                estimation: {
                  title: 'Konzept einschätzen',
                  description:
                    'Farbwechsel und kleinere Korrekturen sind Teil eines Konzepts und stellen in der Regel keinen Wechsel dar. Sie entsprechen eher einer Anpassung. Wann genau ein Konzept als "neu" bezeichnet wird, sollte vorab mit dem Künstler besprochen werden.',
                },
              },
              overview: {
                title: 'Kostenübersicht',
              },
              payment: {
                title: 'Bezahlvorgang',
                text: [
                  'Sobald der Künstler Ihre Anfrage angenommen hat, werden Sie per E-Mail um die Anzahlung gebeten. Details dazu und zu den weiteren Kosten erhalten Sie oben in der Kostenübersicht. Mit Ihrer Anzahlung auf dem Qsellart-Treuhandkonto, wird die Chatfunktion freigeschaltet und das Projekt kann beginnen.',
                  'Die Abschlusszahlung überweisen Sie auf das Treuhandkonto, bevor das Werk umgesetzt ist. Sobald der Künstler und Sie das Projekt als abgeschlossen markieren, erhält der Künstler seine Abschlusszahlung.',
                ],
              },
              cancellation: {
                title: 'Stornierung',
                text: 'Wenn sich ein Künstler auf Ihre Anfrage bewirbt, haben Sie 14 Tage Zeit, um zu reagieren. Andernfalls wird seine Bewerbung automatisch storniert. Sollte sich innerhalb von 14 Tagen niemand auf Ihre Ausschreibung bewerben, wird die Ausschreibung automatisch beendet.',
                directText:
                  'Sollte der Künstler innerhalb von 14 Tagen nicht reagieren, wird Ihre Anfrage automatisch storniert. Danach können Sie den Künstler erneut anfragen. Während dieser Zeit haben Sie die Möglichkeit, beliebig viele weitere Künstler anzufragen und die Anfrage im Bereich der Projektinformationen bei jedem einzelnen Künstler direkt zu löschen.',
              },
              acceptancePeriodHint:
                'Der Künstler hat 5 Tage Zeit Ihr Angebot anzunehmen oder ein Gegenangebot zu erstellen. Es können zeitgleich mehrere Künstler für das slebe Projekt angefragt werden. Der direkte Kontakt zum Künstler erfolgt über unsere Chatfunktion nachdem die Anzahlung getätigt wurde.',
            },
            payment: {
              title: 'Bezahlvorgang',
              text: [
                'Nachdem Sie sich für einen Künstler entschieden haben, erhalten sie eine Zahlungsaufforderung mit der Kostenübersicht per E-Mail. Mit Ihrer Anzahlung auf dem Qsellart-Treuhandkonto, wird die Chatfunktion freigeschaltet und das Projekt kann beginnen.',
                'Die Abschlusszahlung überweisen Sie auf das Treuhandkonto, bevor das Werk umgesetzt ist. Sobald der Künstler und Sie das Projekt als abgeschlossen markieren, erhält der Künstler seine Abschlusszahlung.',
              ],
            },
            cancellation: {
              title: 'Stornierung',
              text: 'Wenn sich ein Künstler auf Ihre Anfrage bewirbt, haben Sie 14 Tage Zeit, um zu reagieren. Andernfalls wird seine Bewerbung automatisch storniert. Sollte sich innerhalb von 14 Tagen niemand auf Ihre Ausschreibung bewerben, wird die Ausschreibung automatisch beendet.',
              directText:
                'Sollte der Künstler innerhalb von 14 Tagen nicht reagieren, wird Ihre Anfrage automatisch storniert. Danach können Sie den Künstler erneut anfragen. Während dieser Zeit haben Sie die Möglichkeit, beliebig viele weitere Künstler anzufragen und die Anfrage im Bereich der Projektinformationen bei jedem einzelnen Künstler direkt zu löschen.',
            },
            expectation: {
              title: 'Budgetvorstellung',
              noAskingPrice: 'Keine Angabe',
              total: 'Gesamtkosten',
            },
          },
          design: {
            title: 'Motiv/Gestaltungswunsch',
            titleImages: 'Ihre Foto Uploads',
            imagePrefix: 'Bilder: ',
          },
        },
        workshop: {
          title: 'Zusammenfassung',
          description: 'Workshop Allgemein',
          artistDescriptionPrefix: 'Workshop-Anfrage an ',
          createCTA: 'Ausschreibung erstellen',
          createCTAWithArtist: 'Anfrage abschließen',
          backCTA: 'zurück',
          cancelCTA: 'abbrechen',
          error: 'Bitte akzeptieren Sie unsere AGB.',
          properties: {
            title: 'Titel',
            location: 'Standort',
            attendee: {
              title: 'Teilnehmer',
              amountSuffix: ' Personen',
              motto: 'Motto',
            },
            size: 'Größe',
            primer: 'Grundierung benötigt',
            deadlineDate: 'Fertigstellung bis',
          },
          price: {
            ofArtist: {
              concept: {
                title: 'Konzept',
                description:
                  'Anhand Ihrer Gestaltungsideen und Bilder, wird der Künstler ein möglichst passendes Konzept erstellen. Ein Konzept, teilweise auch Entwurf genannt, zeigt auf, wie Ihr Objekt bei Fertigstellung aussehen könnte. Ein Konzept ist immer auch mit einem hohen Aufwand verbunden und wird daher ab dem 2. Konzept mit zusätzlichen Kosten verrechnet. Das erste Konzept ist im Preis enthalten.',
                additionalConcept: {
                  prefix: 'zusätzliches Konzept von ',
                  suffix: ':',
                },
                estimation: {
                  title: 'Konzept einschätzen',
                  description:
                    'Farbwechsel und kleinere Korrekturen sind Teil eines Konzepts und stellen in der Regel keinen Wechsel dar. Sie entsprechen eher einer Anpassung. Wann genau ein Konzept als "neu" bezeichnet wird, sollte vorab mit dem Künstler besprochen werden.',
                },
              },
              overview: {
                title: 'Kostenübersicht',
              },
              payment: {
                title: 'Bezahlvorgang',
                text: [
                  'Sobald der Künstler Ihre Anfrage angenommen hat, werden Sie per E-Mail um die Anzahlung gebeten. Details dazu und zu den weiteren Kosten erhalten Sie oben in der Kostenübersicht. Mit Ihrer Anzahlung auf dem Qsellart-Treuhandkonto, wird die Chatfunktion freigeschaltet und das Projekt kann beginnen.',
                  'Die Abschlusszahlung überweisen Sie auf das Treuhandkonto, bevor das Werk umgesetzt ist. Sobald der Künstler und Sie das Projekt als abgeschlossen markieren, erhält der Künstler seine Abschlusszahlung.',
                ],
              },
              cancellation: {
                title: 'Stornierung',
                text: 'Sollte der Künstler innerhalb von 14 Tagen nicht reagieren, wird Ihre Anfrage automatisch storniert. Danach können Sie den Künstler erneut anfragen. Während dieser Zeit haben Sie die Möglichkeit, beliebig viele weitere Künstler anzufragen und die Anfrage im Bereich der Projektinformationen bei jedem einzelnen Künstler direkt zu löschen.',
                directText:
                  'Sollte der Künstler innerhalb von 14 Tagen nicht reagieren, wird Ihre Anfrage automatisch storniert. Danach können Sie den Künstler erneut anfragen. Während dieser Zeit haben Sie die Möglichkeit, beliebig viele weitere Künstler anzufragen und die Anfrage im Bereich der Projektinformationen bei jedem einzelnen Künstler direkt zu löschen.',
              },
              acceptancePeriodHint:
                'Der Künstler hat 5 Tage Zeit Ihr Angebot anzunehmen oder ein Gegenangebot zu erstellen. Es können zeitgleich mehrere Künstler für das slebe Projekt angefragt werden. Der direkte Kontakt zum Künstler erfolgt über unsere Chatfunktion nachdem die Anzahlung getätigt wurde.',
            },
            payment: {
              title: 'Bezahlvorgang',
              text: [
                'Nachdem Sie sich für einen Künstler entschieden haben, erhalten sie eine Zahlungsaufforderung mit der Kostenübersicht per E-Mail. Mit Ihrer Anzahlung auf dem Qsellart-Treuhandkonto, wird die Chatfunktion freigeschaltet und das Projekt kann beginnen.',
                'Die Abschlusszahlung überweisen Sie auf das Treuhandkonto, bevor das Werk umgesetzt ist. Sobald der Künstler und Sie das Projekt als abgeschlossen markieren, erhält der Künstler seine Abschlusszahlung.',
              ],
            },
            cancellation: {
              title: 'Stornierung',
              text: 'Wenn sich ein Künstler auf Ihre Anfrage bewirbt, haben Sie 14 Tage Zeit, um zu reagieren. Andernfalls wird seine Bewerbung automatisch storniert. Sollte sich innerhalb von 14 Tagen niemand auf Ihre Ausschreibung bewerben, wird die Ausschreibung automatisch beendet.',
              directText:
                'Sollte der Künstler innerhalb von 14 Tagen nicht reagieren, wird Ihre Anfrage automatisch storniert. Danach können Sie den Künstler erneut anfragen. Während dieser Zeit haben Sie die Möglichkeit, beliebig viele weitere Künstler anzufragen und die Anfrage im Bereich der Projektinformationen bei jedem einzelnen Künstler direkt zu löschen.',
            },
            expectation: {
              title: 'Budgetvorstellung',
              noAskingPrice: 'Keine Angabe',
              total: 'Gesamtkosten',
            },
          },
          design: {
            title: 'Ihre Foto Uploads',
          },
        },
      },
      success: {
        publicRequest: {
          headline: 'Ihre Ausschreibung wurde erfolgreich erstellt!',
          description: 'Wir haben Ihre Ausschreibung an unsere Künstler weitergeleitet.',
          cta: 'Ihre Ausschreibungen',
        },
        privateRequest: {
          headline: 'Ihre Anfrage wurde erfolgreich erstellt!',
          description: 'Wir haben Ihre Anfrage an den Künstler weitergeleitet.',
          cta: 'Ihre Anfragen',
        },
        anonymousRequest: {
          headline: 'Melden Sie sich an',
          privateDescription:
            'Ihre Anfrage wurde erfolgreich erstellt! Sobald Sie sich anmelden oder registrieren, wird Ihre Anfrage an den Künstler weitergeleitet.',
          publicDescription:
            'Ihre Anfrage wurde erfolgreich erstellt! Sobald Sie sich anmelden oder registrieren, wird Ihre Anfrage an unsere Künstler weitergeleitet.',
          cta: 'Zum Login',
        },
      },
      artistPriceCalculator: {
        headline: 'Kostenübersicht',
        default: {
          art: 'Gestaltung:',
          primer: 'Grundierung:',
          insurance: 'Schutz-Versicherung:',
          total: 'Gesamtkosten:',
        },
        workshop: {
          minPrice: 'Mindestbetrag:',
          additionalAttendees: 'weitere Teilnehmer:',
          total: 'Gesamtkosten:',
        },
      },
      attendee: {
        hint: (minAttendee: number, pricePerAttendee: number) =>
          `Die Mindestanzahl der Teilnehmer für Workshops, ist von dem Künstler vorgegeben und beträgt ${minAttendee} Teilnehmer. Jeder zusätzlicher Teilnehmer kostet ${formatPrice(
            pricePerAttendee,
          )}.`,
        amount: {
          title: 'Teilnehmer',
          description: 'Bestimmen Sie hier, wieviele Teilnehmer der Workshop hat.',
          unit: 'Teilnehmeranzahl',
        },
        error: (amount: number) => `Mindestanzahl von ${amount} nicht erreicht.`,
        motto: {
          title: 'Motto',
          description: 'Unter welchem Motto findet der Workshop statt?',
          placeholder: 'Workshop Motto',
          error: 'Bitte geben Sie ein Motto an.',
        },
      },
    },
    view: {
      headline: 'Projektinformationen',
      propertySection: 'Projektübersicht',
      offers: {
        headline: 'Angebote',
        reverseOffer: 'Gegenangebot',
        offerTitle: 'Angebot',
        remainingDays: 'verbleibende Tage: ',
        accept: {
          cta: 'Angebot annehmen',
          error: 'Fehler beim Annehmen des Angebots.',
        },
        decline: {
          cta: 'Angebot ablehnen',
          success: 'Das Angebot wurde abgelehnt und gelöscht.',
          error: 'Fehler beim Ablehnen des Angebots.',
        },
        modal: {
          title: {
            ACCEPTING: 'Angebot annehmen',
            DECLINING: 'Angebot ablehnen',
            accepted: 'Angebot erfolgreich angenommen!',
          },
          text: {
            ACCEPTING: 'Hiermit nehmen Sie das Angebot des Künstlers an.',
            DECLINING: 'Wollen Sie das Angebot des Künstlers wirklich ablehnen?',
            in_progress: 'Angebot wird verarbeitet, bitte warten Sie einen Moment...',
            accepting_success:
              'Sie haben eine Mail erhalten mit den Rechnungsdetails der Anzahlung. Überweisen Sie den offenen Betrag über das Menü „Finanzen“. Sobald Ihre Anzahlung eingeht, wird der Chat aktiviert und das Projekt gestartet.',
          },
          cta: {
            ACCEPTING: 'Annehmen',
            DECLINING: 'Ablehnen',
          },
          goToProject: 'Zum Projekt',
          goToFinance: 'Zu Finanzen',
        },
      },
      makeAnOffer: {
        validUntil: {
          prefix: 'Diese Ausschreibung ist noch für ',
          prefixReverse: 'Diese Anfrage ist noch für ',
          suffix: ' Tage verfügbar',
        },
        offer: {
          ctaLoggedIn: 'Angebot abgeben',
          ctaNotLoggedIn: 'Angebot als Künstler abgeben',
          ctaReverseOffer: 'Gegenangebot abgeben',
          modal: {
            title: 'Angebot abgeben',
            titleReverseOffer: 'Gegenangebot abgeben',
            cta: 'Angebot abgeben',
            ctaReverseOffer: 'Gegenangebot abgeben',
            radiusHint: {
              title: 'Hinweis:',
              text: 'Dieses Projekt liegt außerhalb deines Einsatzgebiets.',
            },
          },
          hint: `Sobald der Auftraggeber dein Gegenangebot akzeptiert und die Anzahlung tätigt, kannst du den direkten Kontakt zu ihm aufnehmen und das Projekt beginnt.`,
          successHint: `Der Auftraggeber wird über dein Gegenangebot informiert und hat ${maxAgeOfOffersInDays} Tage Zeit sich dafür zu entscheiden.`,
          processing: {
            submitting: 'Dein Angebot wird verarbeitet, bitte warte einen Moment...',
            success: 'Angebot erfolgreich abgegeben!',
            successReverseOffer: 'Gegenangebot erfolgreich abgegeben!',
            error: 'Fehler beim Abgeben deines Angebots.',
          },
          error: {
            priceIsNegative: 'Dein Preis muss mehr als 0 € betragen.',
          },
        },
        accept: {
          cta: 'Anfrage annehmen',
          processing: {
            submitting: 'Die Anfrage wird angenommen, bitte warte einen Moment...',
            success: 'Anfrage erfolgreich angenommen!',
            error: 'Fehler beim Annehmen der Anfrage.',
          },
          modal: {
            title: 'Super, Bitte bestätige die Bedingungen und es kann losgehen',
            text: 'Ich versichere hiermit die Arbeiten sachgerecht auszuführen, die Termine einzuhalten und die angefragten Leistungen zu erbringen. Sobald der Auftraggeber die Anzahlung tätigt, kannst du den direkten Kontakt zu ihm aufnehmen und das Projekt beginnt.',
            cta: 'Anfrage annehmen',
            goToOffer: 'Zum Projekt',
            radiusHint: {
              title: 'Hinweis:',
              text: 'Dieses Projekt liegt außerhalb deines Einsatzgebiets. Bist du sicher, dass du die Anfrage annehmen möchtest?',
            },
          },
        },
        publicRequestConfirmation: {
          cta: 'Jetzt bewerben',
          processing: {
            submitting: 'Deine Bewerbung wird verarbeitet, bitte warte einen Moment...',
            success: 'Bewerbung erfolgreich abgegeben!',
            error: 'Fehler beim Verarbeiten deiner Bewerbung.',
          },
          modal: {
            title: 'Auf diese Ausschreibung bewerben.',
            text: 'Ich versichere hiermit die Arbeiten sachgerecht auszuführen, die Termine einzuhalten und die angefragten Leistungen zu erbringen. Sobald der Auftraggeber sich für dich entschieden hat und die Anzahlung tätigt, beginnt das Projekt und du kannst den direkten Kontakt zu ihm aufnehmen.',
            cta: 'Jetzt bewerben',
            ctaDirectRequest: 'Anfrage annehmen',
            goToOffer: 'Zum Projekt',
            radiusHint: {
              title: 'Hinweis:',
              text: 'Dieses Projekt liegt außerhalb deines Einsatzgebiets. Bist du sicher, dass du die Anfrage annehmen möchtest?',
            },
          },
        },
        decline: {
          cta: 'Anfrage ablehnen',
          processing: {
            submitting: 'Die Anfrage wird abgelehnt, bitte warte einen Moment...',
            success: 'Anfrage erfolgreich abgelehnt!',
            error: 'Fehler beim Ablehnen der Anfrage.',
          },
          modal: {
            title: 'Anfrage ablehnen',
            text: 'Möchtest du die Anfrage wirklich ablehnen?',
            cta: 'Anfrage ablehnen',
            ctaReverseOffer: 'Gegenangebot abgeben',
          },
        },
      },
      artistPricing: {
        title: 'Kostenübersicht',
        titleForOffer: 'Dein Angebot',
      },
      error: 'Fehler beim Laden der Ausschreibung',
    },
  },

  priceCalculator: {
    art: 'Gestaltung:',
    primer: 'Grundierung:',
    insurance: {
      label: 'Schutz-Versicherung:',
      toggle: 'Schutz-Versicherung anbieten',
    },
    concept: 'Konzept: 1x',
    additionalCosts: {
      title: 'Zusatzkosten hinzufügen',
      titleReverseOffer: 'Kein Problem, lass den Auftraggeber wissen, was nachkalkulliert werden soll und wieviel es kostet.',
      label: 'Mehraufwand:',
      priceLabel: 'Höhe deiner Zusatzleistung',
      descriptionPlaceholder: 'Hier den Mehraufwand beschreiben',
    },
    discountAmount: {
      priceLabel: 'Höhe des Rabatts',
      label: 'Rabatt:',
      toggle: 'Rabatt hinzufügen',
    },
    alternativeBudget: {
      title: 'Deine Preisvorstellung',
      noBudget: 'Der Auftraggeber hat keine Budgetvorstellung angegeben.',
      textPlaceholder: 'Begründe deine Preisvorstellung.',
      description: 'Hier kannst du eine alternative Preisvorstellung angeben, die der Auftraggeber annehmen kann.',
      currentBudget: 'Budgetvorstellung des Auftraggebers: ',
    },
    attendee: {
      label: 'Teilnehmer: ',
      min: 'Mindestteilnehmer: ',
      additional: 'weitere Teilnehmer: ',
    },
    addOn: {
      concept: 'Konzept',
      primer: 'Grundierung',
      insurance: 'Schutz-Versicherung',
      additionalSquareMeter: 'Zusätzliche Fläche',
      additionalOtherCosts: 'Sonstige Kosten',
      additionalOtherCostsDescription: 'Beschreibung',
      additionalAttentees: 'Zusätzliche Teilnehmer',
      total: 'Anpassung Gesamt',
    },
    total: 'Gesamtkosten:',
    downPayment: 'Anzahlung:',
    finalPayment: 'Abschlusszahlung:',
  },

  project: {
    length: 'Länge',
    height: 'Höhe',
    size: 'Projektgröße',
    sizes: {
      S: 'small',
      M: 'medium',
      L: 'large',
    },
    primerOptions: {
      YES: 'Ja',
      NO: 'Nein',
      UNSURE: 'Unsicher',
    },
    externalVerification: {
      hint: 'Bitte überprüfen Sie die Details des Projekts und bestätigen Sie die Freigabe.',
      cta: 'Projekt bestätigen',
      verified: 'Das Projekt des Künstlers wurde erfolgreich bestätigt!',
      error: 'Fehler beim Verifizieren des Projekts',
      status: {
        verified: {
          prefix: 'Von ',
          suffix: ' bestätigt',
        },
        unverified: {
          prefix: 'Bestätigung von ',
          suffix: ' noch ausstehend',
        },
      },
    },
    internalVerification: {
      statusText: 'mit Qsellart.de umgesetzt',
    },
    search: {
      headline: 'Werke',
      goTo: 'zum Projekt',
      ratingReplacementText: 'Noch keine Bewertung',
      properties: {
        title: 'Titel',
        location: 'Standort',
        object: 'Objekt',
        surface: 'Untergrund',
        style: 'Stil',
        equipment: 'Hilfsmittel',
        equipmentAvailable: {
          name: 'Hilfsmittel vorhanden',
          YES: 'vorhanden',
          NO: 'nicht vorhanden',
        },
        size: 'Größe',
        primer: 'Grundierung benötigt',
        deadlineDate: 'Fertiggestellt am',
        workshopDate: 'Workshop-Tag:',
        attendee: {
          amount: 'Teilnehmer',
          suffix: ' Personen',
        },
        design: {
          title: 'Motiv/Gestaltungswunsch',
        },
        insurance: {
          name: 'Schutzversicherung',
          YES: 'Mit Versicherung',
          NO: 'Ohne Versicherung',
        },
      },
      filter: {
        order: {
          asc: 'aufsteigend',
          desc: 'absteigend',
        },
        price: {
          title: 'Preis',
          rangeTitle: 'Preisspanne',
        },
        size: {
          title: 'Größe',
        },
        date: {
          title: 'Datum',
        },
      },
      view: {
        rating: {
          title: 'Projektbewertung:',
        },
        cta: {
          artist: 'zum Künstlerprofil',
          artistRequest: 'Künstler buchen',
        },
      },
      error: 'Fehler beim Laden der Werke',
    },
    view: {
      headline: {
        artistPrefix: 'Auftraggeber ',
        bookerPrefix: 'Künstler ',
      },
      titlePrefix: 'Projekt: ',
      properties: {
        headline: 'Projektdetails',
        title: 'Projektübersicht',
      },
      chatPreview: {
        headlinePrefix: 'Chat mit ',
        title: 'Chat',
        noMessages: 'Ihr habt noch keine Nachrichten ausgetauscht.',
        goTo: 'Chat öffnen',
        waitingForDownpayment: 'Der Chat wird freigeschaltet, sobald die Anzahlung erfolgt.',
      },
      pricing: {
        headline: 'Projektkosten',
        overview: {
          title: 'Kostenübersicht',
        },
        addOn: {
          title: 'Kostenanpassung',
          hint: {
            title: 'Funktionsweise',
            text: 'Kostenanpassungen werden den Milestones hinzugefügt. Wenn der Auftraggeber mit der Anpassung einverstanden ist, bestätigt er den Kosten-Milestone und überweist den Betrag auf das Treuhandkonto. Danach wird der Milestone als erledigt markiert und blau angezeigt. Du kannst an den jeweiligen Milestones sehen, ob der Auftraggeber die Kostenanpassung akzeptiert hat.',
          },
          submit: 'Kostenanpassung hinzufügen',
          errorAmount: 'Der Preis deiner Kostenanpassung muss mehr als 0 € betragen.',
          error: 'Deine Kostenanpassung konnte nicht verarbeitet werden.',
          success: 'Kostenanpassung erfolgreich hinzugefügt. Der Auftraggeber muss sie jetzt nur noch bestätigen.',
        },
      },
      nextProject: {
        text: 'Künstler für neues Projekt buchen',
        cta: 'weiteres Projekt starten',
      },
      rating: {
        text: 'Den Künstler und die Zusammenarbeit bewerten',
        cta: 'Projekt bewerten',
      },
      milestone: {
        title: 'Milestones',
        goTo: 'Milestones öffnen',
        waitingForDownpayment: 'Die Milestones werden freigeschaltet, sobald die Anzahlung erfolgt.',
      },
      edit: {
        text: 'Möchtest du dieses Projekt für dein Portfolio bearbeiten?',
        cta: 'Projekt bearbeiten',
        submitCTA: 'Änderungen übernehmen',
        name: {
          title: 'Projektname',
          placeholder: 'Projektname',
        },
        deadline: {
          title: 'Umsetzung',
          inputTitle: 'Termin:',
        },
        tags: {
          object: {
            error: 'Bitte wähle ein passendes Objekt.',
          },
          surface: {
            error: 'Bitte wähle einen passenden Untergrund.',
          },
          style: {
            error: 'Bitte wähle mindestens einen Stil.',
          },
        },
        success: 'Projekt erfolgreich gespeichert.',
        error: 'Fehler beim Speichern des Projekts.',
      },
    },
    milestones: {
      title: 'Milestones',
      subtitlePrefix: 'Projekt: ',
    },
    verified: {
      intern: 'Qsellart verifiziert',
      extern: 'Extern verifiziert',
    },
  },

  rating: {
    headline: 'Wie bewerten Sie die Zusammenarbeit?',
    questions: {
      quality: 'Wie zufrieden sind Sie mit der Qualität des Werkes?',
      reliability: 'Wie zuverlässig war der Künstler?',
      communication: 'Wie bewerten Sie die Kommunikation mit dem Künstler?',
      cooperation: 'Verlief die Projektumsetzung nach Ihren Vorstellungen?',
    },
    textHint: 'Dieser Text ist öffentlich sichtbar.',
    textPlaceholder: 'Ein abschließendes Feedback über das Projekt und die Zusammenarbeit mit dem Künstler',
    internal: {
      headline: 'Ganz unter uns! Wie bewerten Sie die Zusammenarbeit wirklich?',
      hint: 'Diesen Bewertungsteil werden wir vertraulich behandeln. Der Künstler bekommt diesen Teil nicht zu sehen.',
      textHint: 'Dieser Text ist nur für uns sichtbar.',
      textPlaceholder: 'Helfen Sie uns Ihr Erlebnis zu verbessern. Was können wir besser machen?',
    },
    cta: 'Bewertung abgeben',
    alreadyRated: 'Sie haben dieses Projekt bereits bewertet. Vielen Dank.',
    error: {
      ratingProp: 'Bitte bewerten Sie alle Fragen.',
      ratingText: `Bitte geben Sie einen Text mit maximal ${maxPublicRatingTextLength} Zeichen ein.`,
      submit: 'Fehler beim Speichern Ihrer Bewertung.',
    },
    success: {
      submit: 'Vielen Dank für Ihre Bewertung!',
    },
  },

  chat: {
    contacts: {
      headline: 'Chats',
      placeholder: 'Kontakte',
      artistPlaceholderHint: 'Hier ist eine Übersicht der Auftraggeber, mit denen du in Kontakt bist.',
      bookerPlaceholderHint: 'Hier ist eine Übersicht der Künstler, mit denen Sie kommunizieren.',
    },
    messages: {
      headline: 'Projektname',
      placeholder: 'Chat',
      artistPlaceholderHint: 'Sprich mit deinen Auftraggebern über den Qsellart-Chat.',
      bookerPlaceholderHint: 'Sprechen Sie mit Ihren Künstlern über den Qsellart-Chat.',
    },
    milestones: {
      headline: 'Milestones',
      placeholder: 'Projektverlauf',
      artistPlaceholderHint: 'Hier ist eine Übersicht der Milestones deines Projekts.',
      bookerPlaceholderHint: 'Hier ist eine Übersicht der Milestones Ihres Projekts.',
    },
    submit: 'senden',
  },

  dataSection: {
    toggle: {
      on: 'Bearbeiten',
      off: 'Speichern',
    },
  },

  milestone: {
    deadlinePrefix: 'Geplant für ',
    help: {
      artist: 'Milestone-Bearbeitung wird mit der Anzahlung des Auftraggebers aktiviert.',
    },
    remove: {
      title: 'Milestone entfernen',
      text: 'Soll der Milestone entfernt werden?',
      success: 'Milestone erfolgreich entfernt.',
      error: 'Fehler beim Entfernen des Milestones.',
    },
    settings: {
      success: 'Milestone erfolgreich entfernt.',
      error: 'Fehler beim Entfernen des Milestones.',
    },
    accept: {
      title: 'Milestone abschließen',
      titleProjectCompletedArtist: 'Projekt abschließen',
      titleCompleteFinalPaymentBooker: 'Abschlusszahlung akzeptieren',
      titleProjectCompletedBooker: 'Projekt abschließen',
      tooltip: {
        no: 'Milestone erledigt?',
        yesBooker: 'Sie haben diesen Milestone bereits abgeschlossen.',
        yesBookerFinalPayment: 'Sie haben die Abschlusszahlung akzeptiert',
        yesArtist: 'Du hast diesen Milestone bereits abgeschlossen.',
      },
      yes: {
        textArtist: 'Möchtest du diesen Milestone abschließen und als erledigt markieren?',
        textArtistForProjectCompletion: [
          'Möchtest du das Projekt abschließen und den Auftraggeber darüber informieren? Sobald der Auftraggeber dem Projektabschluss zustimmt, wird die noch ausstehende Abschlusszahlung auf dein Konto überwiesen.',
          'Bitte teile dem Auftraggeber mit, dass er den Milestone "Projekt abgeschlossen" bestätigen soll.',
        ],
        textArtistForCompleteFinalPayment:
          'Möchtest du deinem Auftraggeber mitteilen, dass er die Abschlusszahlung auf das Treuhandkonto tätigen soll?',
        textAritstForOpenAddOn: 'Es gibt noch unbezahlte Kostenanpassungen, die storniert werden, wenn du diesen Milestone als erledigt markierst.',
        textBooker: 'Hat der Künstler das Objekt besichtigt und möchten Sie diesen Milestone als erledigt markieren?',
        textBookerForCompleteFinalPayment:
          'Mit Ihrer Bestätigung akzeptieren Sie es, die Abschlusszahlung zu tätigen. Sie erhalten eine Rechnung, die im Bereich „Finanzen“ bezahlt werden kann.',
        textBookerForProjectCompleted:
          'Der Künstler hat dieses Projekt als beendet markiert. Möchten Sie den Projektabschluss ebenfalls bestätigen? Sobald das Projekt von beiden Seiten als abgeschlossen markiert ist, wird dem Künstler seine Gage ausgezahlt.',
      },
      no: {
        textArtist: 'Möchtest du diesen Milestone sicher als unerledigt markieren und den Auftraggeber darüber benachrichtigen?',
        textBooker: 'Möchten Sie diesen Milestone sicher als unerledigt markieren und den Küstler darüber benachrichtigen?',
      },
      blockScreenForInProgress: {
        artist: 'Dein Projekt kann noch nicht abgeschlossen werden. Du musst zunächst den Milestone „Werk Umsetzung“ beenden.',
        booker: [
          'Der Projektabschluss wird vom Künstler eingeleitet und kann anschließend bestätigt werden.',
          'Voraussetzung dafür sind die Abschlusszahlung an den Künstler und die Umsetzung es Werks.',
        ],
      },
      blockScreenForFinalPayment: {
        artist:
          'Der Milestone „Werk Umsetzung“ kann noch nicht abgeschlossen werden. Du musst zunächst die Abschlusszahlung anfordern mit dem Milestone „Abschlusszahlung tätigen“. Damit erhältst du die Sicherheit bei Projektabschluss schnell ausgezahlt zu werden.',
        booker: [
          'Der Projektabschluss wird vom Künstler eingeleitet und kann anschließend bestätigt werden.',
          'Voraussetzung dafür sind die Abschlusszahlung an den Künstler und die Umsetzung es Werks.',
        ],
      },
      blockScreenForProjectCompleted: {
        booker: [
          'Der Projektabschluss wird vom Künstler eingeleitet und kann anschließend bestätigt werden.',
          'Voraussetzung dafür sind die Abschlusszahlung an den Künstler und die Umsetzung es Werks.',
        ],
      },
      success: 'Milestone erfolgreich aktualisiert.',
      error: 'Fehler beim Aktualisieren des Milestones.',
    },
    declineCompletion: {
      completeFinalPayment: {
        title: 'Abschlusszahlung ablehnen',
        text: 'Möchten Sie die Abschlusszahlung ablehnen? Der Künstler wird per Mail darüber informiert.',
      },
      projectInProgress: {
        title: 'Werk Umsetzung ablehnen',
        text: 'Möchten Sie die Werk Umsetzung ablehnen? Der Künstler wird per Mail darüber informiert.',
      },
      projectCompleted: {
        title: 'Projektabschluss ablehnen',
        text: 'Möchten Sie den Projektabschluss wirklich ablehnen? Sprechen Sie bitte mit dem Künstler über Ihre Bedenken.',
      },
      success: 'Milestone erfolgreich abgelehnt.',
      error: 'Fehler beim Ablehnen des Milestones.',
    },
    completeFinalPayment: {
      hint: {
        isAcceptancePending: {
          artist: 'Noch nicht bestätigt',
          booker: 'Noch nicht bestätigt',
        },
        isPaymentPending: {
          artist: 'Noch nicht bezahlt',
          booker: 'hier bezahlen',
          artistOpenChat: 'Chat',
        },
      },
    },
    addOnDecision: {
      accept: {
        title: 'Kostenanpassung akzeptieren',
        tooltip: 'Kostenanpassung akzeptieren',
        tooltipAlreadyAccepted: 'Sie haben die Kostenanpassung akzeptiert',
        text: 'Mit Ihrer Bestätigung akzeptieren Sie die Kostenanpassung und erhalten eine Rechnung, die im Bereich „Finanzen“ bezahlt werden kann.',
        success: 'Kostenanpassung erfolgreich akzeptiert, Sie erhalten in Kürze eine Zahlungsaufforderung.',
        error: 'Fehler beim Akzeptieren der Kostenanpassung.',
      },
      decline: {
        title: 'Kostenanpassung ablehnen',
        text: 'Möchten Sie die Kostenanpassung ablehnen? Der Künstler wird per Mail darüber informiert.',
        success: 'Kostenanpassung erfolgreich abgelehnt.',
        error: 'Fehler beim Ablehnen der Kostenanpassung.',
      },
      hint: {
        isAcceptancePending: {
          artist: 'Noch nicht bestätigt',
          booker: 'Noch nicht bestätigt',
        },
        isPaymentPending: {
          artist: 'Noch nicht bezahlt',
          booker: 'hier bezahlen',
          artistOpenChat: 'Chat',
        },
      },
    },
    upsert: {
      namePlaceholder: 'Milestone auswählen',
      selection: {
        title: 'Auswahl Milestone',
      },
      help: {
        show: 'Hilfe anzeigen',
        hide: 'Hilfe ausblenden',
        artist: [
          'Milestones dienen als Orientierung für dich und den Auftraggeber. Sie unterstützen dich bei der aktiven Projektführung, um den Auftraggeber zu leiten und notwendige Maßnahmen bezüglich deiner Finanzen und des Projektabschlusses zu überwachen. Um das Projekt erfolgreich abzuschließen und die Abschlusszahlung zu erhalten, muss der letzte Milestone „Projekt abgeschlossen“ von beiden Parteien bestätigt werden.',
          'Wähle einen Milestone aus und ordne ihn entsprechend der gewünschten Reihenfolge nummeriert an. Füge ein Datum hinzu, um den Ablauf übersichtlicher zu gestalten. Der Auftraggeber wird es dir danken! Kostenanpassungen werden in der Projektübersicht vorgenommen und erscheinen als Milestone.',
        ],
        booker: [
          'Milestones bieten Orientierung und zeigen ausstehende Aufgaben an. Mit Hilfe der Milestones entscheiden Sie, ob zusätzliche Kostenanpassungen akzeptiert und Rechnungen erstellt werden. Damit der Künstler seine Abschlusszahlung erhält, müssen Sie den letzten Milestone abschließen.',
          'Milestones werden vom Künstler erstellt und vom Auftraggeber bestätigt. Mit der Bestätigung wird der Milestone abgeschlossen. Zuvor nicht abgesprochene Kostenanpassungen können innerhalb von 14 Tagen abgelehnt werden. Danach werden sie automatisch storniert.',
        ],
      },
      success: 'Milestone erfolgreich gespeichert.',
      error: {
        type: 'Bitte wähle einen passenden Milestone aus.',
        date: 'Bitte wähle einen Termin in der Zukunft.',
        upsert: 'Fehler beim Speichern des Milestones.',
      },
      cta: {
        create: 'Milestone hinzufügen',
        edit: 'Milestone speichern',
      },
    },
    type: {
      [MilestoneType.CONCEPT_AGREEMENT]: 'Machbarkeit Projekt',
      [MilestoneType.PROJECT_FEASIBILITY]: 'Anzahlung getätigt',
      [MilestoneType.PROJECT_COMPLETED]: 'Projekt abgeschlossen',
      [MilestoneType.OBJECT_INSPECTION]: 'Objekt Besichtigung',
      [MilestoneType.DEADLINE_AGREEMENT]: 'Deadline abstimmung',
      [MilestoneType.OBJECT_PRIMER_ADDED]: 'Objekt Grundierung',
      [MilestoneType.PROJECT_ADD_ON]: 'Kostenanpassung',
      [MilestoneType.MEETING_PREPARATION]: 'Vorbereitung Treffen',
      [MilestoneType.CANVAS_COMPLETION]: 'Leinwand Fertigstellung',
      [MilestoneType.COMPLETE_FINAL_PAYMENT]: 'Abschlusszahlung tätigen',
      [MilestoneType.PROJECT_IN_PROGRESS]: 'Werk Umsetzung',
    },
  },

  invoices: {
    headline: 'Finanzen',
    diagrams: {
      title: 'Umsatzbericht',
      noData: 'Kein Umsatzbericht vorhanden',
    },
    list: {
      title: 'Rechnungen',
      noData: 'Keine Rechnungen vorhanden',
      download: 'öffnen',
      notPaidYet: 'Noch nicht bezahlt',
      fullyPaid: 'Vollständig bezahlt',
      isStornoPrefix: '- Stornierung',
      cta: {
        pay: 'Rechnung bezahlen',
        download: 'PDF herunterladen',
      },
    },
    error: {
      fetch: 'Fehler beim Laden der Rechnungen.',
    },
  },

  footer: {
    imprint: 'Impressum',
    privacy: 'Datenschutz',
    agb: 'AGB',
    name: 'Qsellart',
    category: 'Kategorien',
    about: 'Über',
    support: 'Support',
    community: 'Community',
    more: 'Mehr über Qsellart',
  },

  home: {
    qr: {
      headlines: ['Persönlicher QR Code', 'für jeden Künstler!'],
      subHeadlines: [
        'Erstelle jetzt dein Profil und erhalte deinen persönlichen QR-Code zum Download.',
        'Geeignet für Visitenkarten und Wandgestaltung.',
      ],
    },
    banner: {
      texts: [
        { headline: 'Wandgestaltung von Profis!', slogan: ['Der Marktplatz für Wand- und Objektgestaltung.'] },
        { headline: 'Große Kunst mit kleinem Aufwand!', slogan: ['Finden Sie den passenden Künstler für Ihre Wand.'] },
        { headline: 'Erwecken Sie die Wände zum Leben!', slogan: ['Gestalten Sie Ihre Wände von professionellen Künstlern.'] },
        { headline: 'Kunst auf Knopfdruck!', slogan: ['Der Marktplatz für Wand- und Objektgestaltung.'] },
      ],
      buttonStart: 'Kunstprojekt starten',
      buttonArtist: 'Kunst entdecken',
    },

    projects: {
      headline: 'Beliebte Auftragsarbeiten',
      cta: 'Zum Projekt',
    },

    functionality: {
      headline: 'So funktioniert Qsellart',
      subHeadline: 'Ein Projekt umzusetzen ist kinderleicht. Es sind nur drei Schritte!',
      step1: {
        headline: 'Künstler finden',
        text: 'Benutzen Sie die Filterfunktion, um Künstlerprofile nach Preis oder Stil zu sortieren und wählen Sie den passenden Künstler. Noch einfacher geht es mit einer Ausschreibung, in der Sie Ihr Projekt beschreiben und ein Budget angeben.',
      },
      step2: {
        headline: 'Projekt führen',
        text: 'Chatten und besprechen Sie mit dem Künstler alle notwendigen Details. Fügen Sie Preisanpassungen hinzu und behalten Sie den Überblick mit Hilfe der Milestones. Transparent, übersichtlich und einfach.',
      },
      step3: {
        headline: 'Sicher bezahlen',
        text: 'Sind Sie zufrieden mit der Kunst, erhält der Künstler seine Gage. Die Bezahlung wird vor Projektabschluss auf das Qsellart-Treuhandkonto überwiesen und verbleibt bis Projektende sicher aufbewahrt.',
        cta: 'Jetzt loslegen',
      },
    },

    mission: {
      headline: 'Unsere Mission',
      paragraphs: [
        'Wir glauben daran, dass Kunst die Kraft hat, Räume zu transformieren und die Stimmung zu verbessern. Indem wir Künstlern eine Plattform bieten, auf der sie ihre Fähigkeiten präsentieren und von Menschen gesehen werden, helfen wir dabei, die Welt ein bisschen schöner zu gestalten.',
        'Wir streben danach, die erste Anlaufstelle für Menschen zu sein, die eine transparente, einfache und vertrauenswürdige Möglichkeit suchen, Kunst auf Wände und Objekte zu bringen.',
      ],
    },

    vision: {
      headline: 'Unsere Vision',
      paragraph:
        'Wir möchten einen inspirierenden Ort für Kunstliebhaber erschaffen, in dem einzigartige Kunstwerke entstehen und ihren Weg mühelos auf Wände und Objekte finden. Wir öffnen den Zugang zur großen Kunst und formen Bewusstsein. Qsellart und Wandgestaltung soll weltweit unzertrennlich sein.',
    },

    reviews: {
      headline: 'Das sagen Auftraggeber',
    },

    icons: [
      {
        headline: 'Sicherheit durch Treuhand',
        text: 'Starten Sie ein Projekt mit der Anzahlung, der Rest wird kurz vor Fertigstellung überwiesen. Bleiben Sie liquide, auch bei längeren Projekten.',
      },
      {
        headline: 'Künstler finden leicht gemacht',
        text: 'Mit unserer Filterfunktion, können Künstler schnell für Ihre individuellen Ansprüche gefunden werden.',
      },
      {
        headline: 'Der rote Faden des Projektes',
        text: 'Behalten Sie den Überblick mit unserer Milestone-Funktion. Ausstehende Termine, Kostenanpassungen und abgeschlossene Aufgaben.',
      },
      {
        headline: 'Chatfunktion',
        text: 'Halten Sie den direkten Kontakt zum Künstler, um Feedback zu erhalten oder Wünsche und Änderungen zu besprechen.',
      },
      {
        headline: 'Von Klein bis Groß',
        text: 'Ob 1 m² Leinwand, ein Garagentor oder ein gesamtes Stadion. Hier finden Sie den passenden Künstler für Ihr Objekt.',
      },
      {
        headline: 'Professionelle Kostenübersicht',
        text: 'Zahlungsein- und -ausgänge, Rechnungen du ausstehende Beträge. Übersichtlich und steuerkonform aufbereitet.',
      },
    ],
  },

  imprint: {
    headline: 'Impressum',
  },

  dataProtection: {
    headline: 'Datenschutz',
  },

  unsubscribe: {
    error: {
      send: 'Bei Ihrer Abmeldung ist ein technischer Fehler aufgetreten. Versuchen Sie es später erneut.',
    },
  },

  countries: {
    GERMANY: 'Deutschland',
  },

  generic: {
    salutation: 'Anrede',
    firstname: 'Vorname',
    lastname: 'Nachname',
    birthday: 'Geburtsdatum',
    street: 'Straße, Hausnummer',
    postcode: 'Postleitzahl',
    postcodeCity: 'PLZ / Ort',
    companyName: 'Firmenname',
    optionalSuffix: ' (optional)',
    city: 'Stadt',
    phone: 'Telefon',
    phoneError: 'Telefonnummern müssen mit +49 beginnen und dürfen keine Leerzeichen beinhalten.',
    fax: 'Fax',
    username: 'Künstlername',
    mail: 'E-Mail-Adresse',
    password: 'Passwort',
    repeatedPassword: 'Passwort wiederholen',
    currencySymbol: '€',
    currencySymbolToSqareMeter: '€/m²',
    priceFromPrefix: 'ab ',
    squareMeter: ' m²',
    meter: ' m',
    km: ' km',
    months: ' Monate',
    hoursShort: ' h',
    name: 'QSELLART',
    priceWarning: 'Beachte, dass 12,5 % an Qsellart-Gebühr von deinen Preisen abgezogen werden.',
    confirm: 'Bestätigen',
    cancel: 'Abbrechen',
    close: 'Schließen',
    send: 'Senden',
    error: 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.',
    acceptAGB: 'Ich akzeptiere die ',
    acceptAGBSuffix: 'AGB.',
    declineNotifications: 'Ich möchte keine Mitteilungen zu Nachrichten und Produktneuheiten von Qsellart erhalten.',
    equals: ' = ',
    multiply: ' x ',
    percent: ' %',
    upload: 'Hochladen',
    nationwide: 'Deutschlandweit',
    radius: 'Umkreis',
    iban: 'IBAN',
    ibanLast4DigitsPrefix: 'endet mit: ',
    bankname: 'Name der Bank',
    invoice: {
      taxNumber: 'Steuernummer',
      taxID: 'Umsatzsteuer-ID',
      registerNumber: 'Register-Nr.',
      registerName: 'Registergericht',
      smallBusinessRegulation: {
        hint: 'Kleinunternehmerregelung',
        hintText: [
          'In Deutschland werden i.d.R. 19% MwSt ausgewiesen und sollten auf der Rechnung berücksichtigt werden. Ausnahme stellt die Kleinunternehmerregelung dar, wonach bis zu einer bestimmten Umsatzgrenze keine MwSt ausgewiesen werden.',
          'Bitte beachte, dass du bei wiederholter Projektumsetzung als Gewerbetreibender oder Freiberufler eingestuft wirst.',
        ],
      },
    },
    businessType: {
      label: 'Unternehmensform',
      [BusinessType.INDIVIDUAL]: 'Einzelunternehmen, Gbr, Freiberufler, etc.',
      [BusinessType.COMPANY]: 'GmbH, AG, UG, KG etc.',
    },
  },

  cookieConsent: {
    hi: 'Hi, alles klar?',
    question: 'Darf es ein Cookie zu deinem Caffee sein?',
    accept: 'Cookies akzeptieren',
    decline: 'Ablehnen',
    settings: {
      goTo: 'Zu den Cookie-Einstellungen',
      back: '< Zurück',
      save: 'Speichern',
    },
    cookies: [
      {
        type: 'Marketing',
        description: 'Diese Technologien werden verwendet, um Inhalte zu personalisieren, die für dich relevant sind.',
        items: [
          {
            name: 'fingerprintUUID',
            description: 'Speichert den Fingerprint des Geräts.',
          },
        ],
      },
      {
        type: 'Essentiell',
        disabled: true,
        description: 'Diese Technologien sind erforderlich, um die Kernfunktionalität der Website zu aktivieren.',
        items: [
          {
            name: 'cookie_consent',
            description: 'Speichert die Auswahl des Cookie-Consents.',
          },
          {
            name: 'token',
            description: 'Speichert das Login-Token.',
          },
        ],
      },
    ],
  },

  UI: {
    daysSince: {
      today: 'Heute',
      yesterday: 'Gestern',
      prefix: 'vor ',
      suffix: ' Tagen',
    },
    autocomplete: {
      searchError: 'Suche konnte nicht ausgeführt werden.',
      searchEmpty: 'Keine Ergebnisse',
    },
    dropdown: {
      emptySelection: 'Bitte wählen',
    },
    selectableTagList: {
      showMore: 'Mehr',
      showLess: 'Weniger',
    },
    textarea: {
      counter: {
        prefix: 'verbleibende Zeichen: ',
        max: 'max. ',
      },
    },
    fileSelect: {
      fileToLargePrefix: 'Die ausgewählte Datei ist zu groß: ',
      allowedFileTypes: 'Erlaubte Dateitypen: ',
      allowedFileSize: 'Max. erlaubte Dateigröße: ',
    },
    search: {
      placeholder: 'Ort, Künstler, Stil',
      placeholderMobile: 'Ort, Künstler',
      cta: 'suchen',
      toggleFilter: 'filter',
      applyFilter: 'Filter anwenden',
      filter: {
        skillTags: 'Spezialisierungen',
        objectTags: 'Objekte',
        surfaceTags: 'Untergründe',
        equipmentTags: 'Hilfsmittel',
        styleTags: 'Stil',

        orderSelection: {
          asc: 'aufsteigend',
          desc: 'absteigend',
        },
      },
    },
    yearSelection: {
      total: 'Gesamt',
    },
  },

  resetPassword: {
    passwordsDontMatch: 'Passwörter stimmen nicht überein',
    passwordReset: 'Passwort wurde erfolgreich geändert',
    save: 'Ändern',
    password: 'Passwort',
    passwordRepeat: 'Passwort wiederholen',
    title: 'Passwort ändern',
  },
}

export const langKey = 'DE'
