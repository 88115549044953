import styled from 'styled-components'

export const BackgroundImage = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 4.8;
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.gray.secondary};
  position: relative;
`

export const PersonImage = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  width: calc(100% - 15px);
  aspect-ratio: 1 / 1;
  border-radius: 500px;
  border: none;
  position: absolute;
  left: 15px;
  transform: translateY(-50%);
  border: 2px solid white;
  background-color: ${({ theme }) => theme.color.gray.thirdary};
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    /* position: relative;
    left: 0%;
    transform: none;
    width: 80px; */
    width: 95px !important;
    aspect-ratio: 1 / 1;
    position: relative !important;
    transform: none;
    left: 0px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    /* width: calc(80% - 15px); */
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.xxl}) {
    width: calc(100% - 15px);
  }
`
