import { useNavigate } from 'react-router'
import { NavigationItem } from '../../types'
import GetIcon from '../../../../GetIcon/GetIcon'
import NotificationBell from '../../../../NotificationBell/NotificationBell'

type Props = {
  data: NavigationItem
  onClick?: () => void
  noIcon?: boolean
  className?: string
}

const Dropdown: React.FC<Props> = ({ data, onClick, noIcon = false, className = '' }) => {
  const navigate = useNavigate()

  const isActive = (path: string) => {
    return window.location.pathname.includes(path)
  }

  const clicked = (e) => {
    e.preventDefault()
    if (onClick) {
      onClick()
    }
    if (data.link) {
      navigate(data.link)
    }
  }

  return (
    <a
      href={data.link || ''}
      onClick={clicked}
      className={`flex-row gap-3 items-center px-3 py-1 hover:bg-white rounded-md relative ${data.hiddenOnMobile ? 'hidden 2xl:flex' : 'flex'} ${
        isActive(data.link) ? 'bg-white' : ''
      } ${className}`}
    >
      {data.icon && !noIcon ? <GetIcon name={data.icon} className="h-5 fill-blue-primary" /> : null}
      <span className={`text-blue-primary font-thin`}>{data.name}</span>
      <NotificationBell type={data.notificationType} />
    </a>
  )
}

export default Dropdown
