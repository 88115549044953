import { useState } from 'react'
import { Request } from '../../../types/Request'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Filter, { FilterProps } from './Filter/Filter'
import MediumContainer from '../../UI/Container/MediumContainer'
import Headline from '../../UI/Headline/Headline'
import RequestGridView from '../RequestGridView/RequestGridView'
import { useSearchParams } from 'react-router-dom'
import { getUUID } from '../../../utility'
import Container from '../../UI/Container/Container'
import styled from 'styled-components'

const Wrapper = styled.div<{ itemsPerRow: number }>`
  width: calc(100% / ${(props) => props.itemsPerRow} - (20px));
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

const Search = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [requests, setRequests] = useState<Request[]>(null)
  const [searchParams] = useSearchParams()
  const [isListView, setIsListView] = useState<boolean>(false)

  const getInitQuery = (): FilterProps => {
    const query = searchParams.get('query')
    try {
      return !!query ? JSON.parse(query) : null
    } catch (e) {
      return null
    }
  }

  const toggleListView = () => setIsListView(!isListView)

  const RequestList = (
    <div className="px-0 sm:px-6 md:px-0">
      {requests?.length > 0 && (
        <div className="flex flex-row flex-wrap gap-6 md:gap-[40px] mt-5">
          {requests.map((request) => (
            <Wrapper key={getUUID()} itemsPerRow={isListView ? 1 : 2}>
              <RequestGridView key={request.uuid} request={request} isGridView={!isListView} />
            </Wrapper>
          ))}
        </div>
      )}
    </div>
  )

  return (
    <>
      <Container>
        <Headline>{t.request.search.headline}</Headline>
        <div className="mt-5">
          <Filter requests={requests} setRequests={setRequests} init={getInitQuery()} toggleListView={toggleListView} isListView={isListView} />
        </div>
      </Container>
      {isListView ? <MediumContainer>{RequestList}</MediumContainer> : <Container>{RequestList}</Container>}
    </>
  )
}

export default Search
