import { StyledImage } from './Image.Styled'
import upload from '../../../../assets/image/generic/upload.png'
import uploadMinimized from '../../../../assets/image/generic/upload_minimized.png'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { s3PublicStoragePrefix } from '../../../../utility'
import DirectFileUpload, { UPLOAD_TYPES } from '../../DirectFileUpload/DirectFileUpload'
import { TrashIcon } from '@heroicons/react/24/solid'

type Props = {
  index: number
  keys?: string[]
  setImage: (file: any) => void
  remove: () => void
  uploadType: UPLOAD_TYPES
  horizontal?: boolean
}

const Image: React.FC<Props> = ({ index, keys, setImage, remove, uploadType, horizontal = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const getURL = () => {
    if (!keys) return false
    const key = keys[index]
    if (!!key) return `${s3PublicStoragePrefix}${key}`
    return false
  }

  return (
    <StyledImage url={getURL()} horizontal={horizontal} index={index} className={`border-blue-primary border rounded-lg`}>
      {!getURL() && (
        <DirectFileUpload uploadType={uploadType} acceptedMimeTypes={['image/*']} onFilesCallback={setImage}>
          {index === 0 && <img src={upload} className="h-9 hover:scale-105 transition-all" alt={t.generic.upload} />}
          {index > 0 && <img src={uploadMinimized} className="h-5 hover:scale-105 transition-all" alt={t.generic.upload} />}
        </DirectFileUpload>
      )}
      {getURL() && (
        <div
          className="backdrop-blur-sm rounded-sm border py-1 px-2 border-white cursor-pointer group w-fit absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          onClick={remove}
        >
          <TrashIcon className="h-5 fill-white group-hover:fill-magenta-primary" />
        </div>
      )}
    </StyledImage>
  )
}

export default Image
