import styled from 'styled-components'

type Props = {
  image?: string
  small?: boolean
  onClick?: () => void
}

export const StyledImage = styled.img<{ url?: string; small: boolean }>`
  ${({ url }) => url && `background-image: url(${url});`}
  background-size: cover;
  background-position: center;
  ${({ small }) => (small ? 'width: calc(100% / 4 - 8px * 3 / 4);' : 'width: 100%;')}
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    ${({ small }) => (small ? 'width: calc(100% / 3 - 8px * 2 / 3);' : 'width: 100%;')}
  }
`

const Image: React.FC<Props> = ({ image, onClick, small = false }) => {
  if (!image) return <></>
  return (
    <StyledImage
      src={image}
      className={`rounded-md aspect-video object-cover border-[0.1px] border-gray-secondary ${!!onClick ? 'cursor-pointer' : ''}`}
      onClick={onClick || null}
      small={small}
    />
  )
}

export default Image
