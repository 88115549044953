type Props = {
  loading: any
  spinner?: boolean
  className?: string
}

const Loading: React.FC<Props> = ({ loading, className, spinner = false }) => {
  if (!!loading) return null
  return (
    <div className={`text-center py-3 ${className || ''}`}>
      <span className={`loading ${spinner ? 'loading-spinner' : 'loading-ring'} loading-sm`}></span>
    </div>
  )
}

export default Loading
