import { useSelector } from 'react-redux'
import { Project } from '../../../../../../types/Project'
import GrayBox from '../../../../../UI/Graybox/Graybox'
import Button from '../../../../../UI/NewButton/NewButton'
import NewModal from '../../../../../UI/NewModal/NewModal'
import NewInput from '../../../../../UI/NewInput/NewInput'
import props from '../../../../../../redux/props'
import { useState } from 'react'
import SelectableTagList from '../../../../../UI/SelectableTagList/SelectableTagList'
import { Category, Tag } from '../../../../../../types/Tag'
import { UPLOAD_TYPES } from '../../../../../UI/DirectFileUpload/DirectFileUpload'
import ProjectImageUploadMask from '../../../../../UI/ProjectImageUploadMask/ProjectImageUploadMask'
import _ from 'lodash'
import toast, { TYPE } from '../../../../../../toast'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../../../redux/routes'

type Props = { project: Project }

type UpdateProject = Pick<Project, 'uuid' | 'tags' | 'design' | 'deadline' | 'nameAndLocation'>

const Edit: React.FC<Props> = ({ project }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [showEdit, setShowEdit] = useState<boolean>(false)
  const allTags: Tag[] = useSelector((state) => state[props.TAG])
  const updateProject = functional.use(Routes.UPDATE_PROJECT)
  const [data, setData] = useState<UpdateProject>({
    uuid: project.uuid,
    tags: _.cloneDeep(project.tags),
    design: _.cloneDeep(project.design),
    deadline: _.cloneDeep(project.deadline),
    nameAndLocation: _.cloneDeep(project.nameAndLocation),
  })

  const isWorkshop = isFinite(project.attendee?.amount)

  const submit = async () => {
    const isSelected = (prop: any) => Array.isArray(prop) && prop.length > 0
    if (isWorkshop || !!data.tags.object) {
      if (isWorkshop || !!data.tags.surface) {
        if (isSelected(data.tags.style)) {
          const result = await updateProject({ data })
          if (result) {
            setShowEdit(false)
            toast(TYPE.SUCCESS, t.project.view.edit.success)
          } else {
            toast(TYPE.ERROR, t.project.view.edit.error)
          }
        } else {
          toast(TYPE.ERROR, t.project.view.edit.tags.style.error)
        }
      } else {
        toast(TYPE.ERROR, t.project.view.edit.tags.surface.error)
      }
    } else {
      toast(TYPE.ERROR, t.project.view.edit.tags.object.error)
    }
  }

  const set = (prop: string, key: string) => (value: any) => {
    setData({ ...data, [prop]: { ...data[prop], [key]: value } })
  }

  return (
    <GrayBox className="w-full mb-10 border-4 border-blue-primary">
      <div className="py-5 text-center text-blue-primary font-bold">{t.project.view.edit.text}</div>
      <div className="lg:absolute right-7 top-0 lg:h-full text-center flex items-center justify-center">
        <Button text={t.project.view.edit.cta} onClick={() => setShowEdit(true)} className="btn-secondary w-fit !text-sm" />
      </div>
      <NewModal
        show={showEdit}
        onClose={() => setShowEdit(false)}
        onConfirm={submit}
        large={true}
        confirmButtonText={t.project.view.edit.submitCTA}
        glass
      >
        <div className="flex lg:flex-row gap-10">
          <div className="flex flex-col gap-10 w-full">
            <div className="flex flex-col gap-2">
              <div className="font-bold text-white">{t.project.view.edit.name.title}</div>
              <NewInput
                required
                value={data.nameAndLocation.name}
                onChange={set('nameAndLocation', 'name')}
                placeholder={t.project.view.edit.name.placeholder}
              />
            </div>
            {!isWorkshop && (
              <div className="flex flex-col gap-2">
                <div className="font-bold text-white">{t.tag.category[Category.OBJECT]}</div>
                <SelectableTagList
                  maxVisibleRows={3}
                  selectedTagENUMList={[data.tags?.object]}
                  tags={allTags.filter((t: Tag) => t.category === Category.OBJECT && t.enum !== 'WORKSHOP')}
                  onChange={set('tags', 'object')}
                  singleSelect
                />
              </div>
            )}
            <div className="flex flex-col gap-2">
              <div className="font-bold text-white">{t.tag.category[Category.STYLE]}</div>
              <SelectableTagList
                maxVisibleRows={3}
                selectedTagENUMList={data.tags?.style}
                tags={allTags.filter((t: Tag) => t.category === Category.STYLE)}
                onChange={set('tags', 'style')}
              />
            </div>
          </div>
          <div className="flex flex-col gap-10 w-full">
            <div>
              <ProjectImageUploadMask uploadType={UPLOAD_TYPES.PROJECT_UPDATED_IMAGE} imageKeys={data.design.keys} callback={set('design', 'keys')} />
            </div>
          </div>
        </div>
      </NewModal>
    </GrayBox>
  )
}

export default Edit
