import { useState } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import searchIcon from '../../../../../assets/image/header/search-blue.png'
import { functional } from '@think-internet/zeus-frontend-package'
import { useLocation, useNavigate } from 'react-router-dom'
import Routes from '../../../../../redux/routes'
import NewButton from '../../../../UI/NewButton/NewButton'
import NewInput from '../../../../UI/NewInput/NewInput'
import Form from '../../../Form/Form'
import { GlobalSearchResult } from '../../../../../types/Search'

const Search = () => {
  const [input, setInput] = useState('')
  const translation = useSelector((s) => s[props.TRANSLATION])
  const search = functional.use(Routes.SEARCH_GLOBAL)
  const navigate = useNavigate()
  const location = useLocation()

  const runSearch = async () => {
    if (input.length < 3) return

    const result: GlobalSearchResult = await search({ filter: { input } })
    if (!!result) {
      navigate(`/${result.type.toLowerCase()}?query=${result.query}`)
      // Reload page required if we are already on the result type page due to re-render behaviour of react
      if (location.pathname.toLowerCase().includes(result.type.toLowerCase())) {
        window.location.reload()
      }
    }
  }

  return (
    <Form onSubmit={runSearch} className="flex border border-blue-primary rounded-full items-center">
      <img src={searchIcon} alt="" className="h-5 ml-1" />
      <NewInput className="md:hidden border-none !h-7" value={input} onChange={setInput} placeholder={translation.header.search.placeholderMobile} />
      <NewInput className="hidden md:block border-none !h-7" value={input} onChange={setInput} placeholder={translation.header.search.placeholder} />
      <NewButton className="btn-secondary border !border-white h-8" type="submit" text={translation.header.search.cta} />
    </Form>
  )
}

export default Search
