import classNames from 'classnames'
import { memo, useEffect, useRef, useState } from 'react'
import NewInput from '../NewInput/NewInput'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import { prettyLocation } from '../../../utility'
import Location from '../../../types/Location'

type Props = {
  onChange(val: Location): void
  label?: string
  initValue?: string
  required?: boolean
}

const LocationAutocomplete: React.FC<Props> = ({ initValue, onChange, label, required = false }) => {
  const [value, setValue] = useState(initValue || '')
  const [prevValue, setPrevValue] = useState(initValue || '')
  const ref = useRef<HTMLDivElement>(null)
  const getSuggestions = functional.use(Routes.GET_LOCATION_SUGGESTION)
  const getCoordinates = functional.use(Routes.GET_GOOGLE_PLACE_COORDINATES)
  const [suggestions, setSuggestions] = useState<Array<Location>>([])

  useEffect(() => {
    const suggest = async () => {
      const suggestions = await getSuggestions({ value })
      if (Array.isArray(suggestions)) {
        setPrevValue(value)
        setSuggestions(suggestions)
      }
    }
    if (value.length >= 3 && value !== prevValue) {
      suggest()
    }
  }, [value, getSuggestions, prevValue])

  const select = (location: Location) => async () => {
    setSuggestions([])
    setPrevValue(prettyLocation(location))
    setValue(prettyLocation(location))
    const coordinates = await getCoordinates({ place_id: location.place_id })

    if (coordinates) {
      onChange({ ...location, coordinates })
    }
  }

  const isVisible = () => suggestions.length > 0 && value.length >= 3

  return (
    <div
      className={classNames({
        'dropdown w-full': true,
        'dropdown-open': isVisible(),
      })}
      ref={ref}
    >
      <NewInput required={required} value={value} onChange={setValue} placeholder={label} autoComplete="off" />
      {isVisible() && (
        <div className="dropdown-content w-full bg-white left-0 top-[30px] max-h-24 overflow-y-auto overflow-x-hidden flex-col z-10 border border-t-0 border-blue-primary rounded-sm rounded-t-none">
          <ul className="menu menu-compact p-0" style={{ width: ref.current?.clientWidth }}>
            {suggestions.map((s, index) => {
              return (
                <li key={index} tabIndex={index + 1} onClick={select(s)} className="w-full">
                  <button className="text-xs px-2 py-[2px] rounded-none w-full">{prettyLocation(s)}</button>
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}

export default memo(LocationAutocomplete)
