import { useSelector } from 'react-redux'
import { Pricing } from '../../../../../../types/Artist'
import { useState } from 'react'
import props from '../../../../../../redux/props'
// import Section from '../../../../../UI/NewAccordion/Section/Section'
import TypeUnitValueMask from '../../../../../UI/Pricing/Editable/TypeUnitValueMask'
import Currency from '../../../../../UI/NewInput/Currency'
import Number from '../../../../../UI/NewInput/Number'

type Props = {
  initData?: Pricing['workshop']
  update?: (value: any) => void
}

const Workshop: React.FC<Props> = ({ initData, update }) => {
  const t = useSelector((state) => state[props.TRANSLATION])
  const [data, setData] = useState<Pricing['workshop']>(
    initData || {
      pricePerAttendee: null,
      minAttendees: null,
    },
  )

  const set = (prop: keyof Pricing['workshop']) => (value: number) => {
    setData({ ...data, [prop]: value })
    update({ ...data, [prop]: value })
  }

  return (
    <div>
      <div className="font-bold mb-1">{t.register.artist.pricing.workshop.headline}</div>
      <div className="mt-4">
        <TypeUnitValueMask
          type={t.register.artist.pricing.workshop.pricePerAttendee.amount}
          unit={t.register.artist.pricing.workshop.pricePerAttendee.unit}
        >
          <Currency
            value={data.pricePerAttendee}
            onChange={set('pricePerAttendee')}
            className="pr-1"
            contentClassName="font-light"
            suffixText="€/Person"
            hiddenDisabled={!update}
          />
        </TypeUnitValueMask>
      </div>
      <div className="mt-4">
        <TypeUnitValueMask unit={t.register.artist.pricing.workshop.minAttendees.unit}>
          <Number
            value={data.minAttendees}
            onChange={set('minAttendees')}
            contentClassName="font-light"
            textAlign="center"
            hiddenDisabled={!update}
          />
        </TypeUnitValueMask>
        {/* {!!update && (
          <Section className="mt-2" title={t.register.artist.pricing.workshop.hint} content={t.register.artist.pricing.workshop.hintText} />
        )} */}
      </div>
    </div>
  )
}

export default Workshop
