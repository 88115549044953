import { useSelector } from 'react-redux'
import { ArtistListItem, Primer, Request } from '../../../../types/Request'
import { calculateSquareMeters, formatPrice, getPercentageOfPrice, getProjectSizeEnum, getTotalPriceOfRequest } from '../../../../utility'
import props from '../../../../redux/props'
import Property from '../../PropertyList/Property'
import { Pricing } from '../../../../types/Project'
import { useEffect, useState } from 'react'

type Props = {
  artist: ArtistListItem
  request: Request
  className?: string
}

const WithArtist: React.FC<Props> = ({ artist, request, className = '' }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const squareMeters = calculateSquareMeters(request.sizeAndPrimer.size.length, request.sizeAndPrimer.size.height)
  const projectSize = getProjectSizeEnum(squareMeters)
  const [pricing, setPricing] = useState<Pricing>()

  useEffect(() => {
    if (!pricing) {
      const pricing: Pricing = {
        artPerSquareMeter: artist.pricing.art[projectSize],
        primerPerSquareMeter: artist.pricing.primer[projectSize],
        downPaymentPercentage: artist.pricing.downPaymentPercentage,
      }
      setPricing(pricing)
    }
  }, [pricing, request, artist, projectSize])

  const getTotalPrice = (noInsurance?: boolean) => getTotalPriceOfRequest(artist, request, noInsurance)

  const primerEnabled = request.sizeAndPrimer.primerRequired === Primer.YES && !artist.pricing.primer.disabled

  if (!pricing) return <></>
  return (
    <div className={className}>
      <Property
        property={{
          name: `${t.priceCalculator.art}`,
          valuePrefix: `${formatPrice(pricing.artPerSquareMeter)}${t.generic.multiply}${squareMeters}${t.generic.squareMeter}`,
          value: formatPrice(pricing.artPerSquareMeter * squareMeters),
        }}
      />
      {primerEnabled && (
        <Property
          property={{
            name: `${t.priceCalculator.primer} ${formatPrice(pricing.primerPerSquareMeter)}${t.generic.multiply}${squareMeters}${
              t.generic.squareMeter
            }`,
            value: formatPrice(pricing.primerPerSquareMeter * squareMeters),
          }}
        />
      )}
      {artist.insuranceDetail.enabled && (artist.insuranceAcceptedByBooker || request?.insurance?.accepted) && (
        <Property
          className="mt-5"
          property={{
            name: t.priceCalculator.insurance.label,
            value: formatPrice(getPercentageOfPrice(getTotalPrice(true), artist.insuranceDetail.pricePercentage)),
          }}
        />
      )}
      <Property
        className="mt-5"
        property={{
          name: t.priceCalculator.total,
          value: formatPrice(getTotalPrice()),
        }}
      />
      <Property
        className="mt-5"
        property={{
          name: `${t.priceCalculator.downPayment} ${pricing.downPaymentPercentage}${t.generic.percent}`,
          value: formatPrice(getPercentageOfPrice(getTotalPrice(), pricing.downPaymentPercentage)),
        }}
      />
      <Property
        property={{
          name: `${t.priceCalculator.finalPayment} ${100 - pricing.downPaymentPercentage}${t.generic.percent}`,
          value: formatPrice(getPercentageOfPrice(getTotalPrice(), 100 - pricing.downPaymentPercentage)),
        }}
      />
    </div>
  )
}

export default WithArtist
