import styled from 'styled-components'

export const StyledFunctionality = styled.div`
  margin-top: 80px;
`

export const Headline = styled.div`
  width: fit-content;
  padding: 15px 40px;
  background-color: ${({ theme }) => theme.color.blue.primary};
  font-size: ${({ theme }) => theme.font.size.xlg};
  color: ${({ theme }) => theme.color.font.secondary};
  font-family: 'thin';
  margin-bottom: 34px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.lg};
  }
`

export const SubHeadline = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  width: fit-content;
  padding: 15px 40px;
  background-color: ${({ theme }) => theme.color.blue.primary};
  font-size: ${({ theme }) => theme.font.size.md};
  margin-left: -50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    margin-left: 0;
    font-family: 'thin';
  }
`
