import Image from '../../../../../UI/Image/Image'
import { StyledButton, Text } from './Button.Styled'
import right from '../../../../../../assets/image/arrow-right.png'
import left from '../../../../../../assets/image/arrow-left-filled.png'
import rightFilled from '../../../../../../assets/image/arrow-right-filled.png'

export enum Variant {
  NEXT = 'NEXT',
  PREVIOUS = 'PREVIOUS',
}

type Props = {
  variant: Variant
  text: string
  isEnabled?: boolean
  type?: 'submit' | 'button'
  onClick?: () => void
  className?: string
}

const NavigationButton: React.FC<Props> = ({ onClick, variant, text, isEnabled = true, type = 'button', className = '' }) => {
  const handleClick = () => {
    document.body.scrollTo(0, 0)
    if (!!onClick) {
      onClick()
    }
  }

  return (
    <StyledButton className={className} onClick={handleClick} variant={variant} isEnabled={isEnabled} type={type}>
      <Image width={'40px'} src={variant === Variant.NEXT ? (isEnabled ? rightFilled : right) : left} />
      <Text>{text}</Text>
    </StyledButton>
  )
}

export default NavigationButton
