import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import NewButton from '../../UI/NewButton/NewButton'
import SmallContainer from '../../UI/Container/SmallContainer'
import { AccountType } from '../../../types/Auth'
import SectionHeader from '../../UI/Pipeline/SectionHeader'

const Selection = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <SmallContainer>
      <SectionHeader headline={t.register.selection.headline} description={t.register.selection.description} />
      <div className="flex flex-row mt-7 gap-5">
        <NewButton className="w-full btn-secondary flex-1" text={t.register.selection.artist} href={`/register/${AccountType.ARTIST}`} />
        <NewButton className="w-full btn-secondary flex-1" text={t.register.selection.booker} href={`/register/${AccountType.BOOKER}`} />
      </div>
    </SmallContainer>
  )
}

export default Selection
