import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import NewInput from '../../../UI/NewInput/NewInput'
import Checkbox from '../../../UI/Checkbox/Checkbox'
import toast, { TYPE } from '../../../../toast'
import { AccountDetail as AccountDetailType } from '../types'
import { PipelineComponent } from '../../../UI/Pipeline/types'

import SmallContainer from '../../../UI/Container/SmallContainer'
import Routes from '../../../../redux/routes'
import { functional } from '@think-internet/zeus-frontend-package'
import SectionHeader from '../../../UI/Pipeline/SectionHeader'
import { s3PublicStoragePrefix } from '../../../../utility'

enum AvailabilityErrorResponses {
  MAIL = 'MAIL',
}

const AccountDetail: PipelineComponent<AccountDetailType> = ({ pipelineStateProp, data, setData, NavigationForm, finalStepSubmit }) => {
  const t = useSelector((state) => state[props.TRANSLATION])
  const checkUsernameAndMailAvailability = functional.use(Routes.CHECK_USERNAME_AND_MAIL_AVAILABILITY)

  const passwordCheck = () => {
    const status = data.password === data.repeatedPassword
    if (!status) {
      toast(TYPE.ERROR, t.register.booker.accountDetail.passwordsNotEqual)
    }
    return status
  }

  const set = (prop: keyof AccountDetailType) => (value: any) => setData({ ...data, [prop]: value })

  const getData = async () => {
    if (passwordCheck()) {
      const response = await checkUsernameAndMailAvailability(data)
      if (response === true) return data
      if (response === AvailabilityErrorResponses.MAIL) {
        toast(TYPE.ERROR, t.register.booker.accountDetail.mailTaken)
      }
    }
    return false
  }

  return (
    <SmallContainer>
      <SectionHeader headline={t.register.booker.accountDetail.headline} description={t.register.booker.accountDetail.description} />
      <NavigationForm pipelineStateProp={pipelineStateProp} getData={getData} finalStepSubmit={finalStepSubmit}>
        <div className="mt-7 flex gap-3 flex-col lg:w-7/12 mx-auto">
          <NewInput type="email" required value={data.mail} onChange={set('mail')} placeholder={t.generic.mail} />
          <NewInput required type="password" value={data?.password} onChange={set('password')} placeholder={t.generic.password} />
          {!!data?.password?.length && (
            <NewInput
              required
              type="password"
              value={data?.repeatedPassword}
              onChange={set('repeatedPassword')}
              placeholder={t.generic.repeatedPassword}
            />
          )}
          <Checkbox
            required
            checked={data.agb || false}
            onChange={set('agb')}
            label={t.generic.acceptAGB}
            linkSuffix={t.generic.acceptAGBSuffix}
            link={`${s3PublicStoragePrefix}agb/Q_AGB_28082024.pdf`}
            className="mt-3"
          />
          <Checkbox checked={data.declineNotifications || false} onChange={set('declineNotifications')} label={t.generic.declineNotifications} />
          <div className="text-xs text-gray-primary leading-[18px] ml-6">
            {t.register.artist.personalDetail.dataProtectionHint.prefix}
            <a
              href={t.register.artist.personalDetail.dataProtectionHint.link.href}
              target="_blank"
              rel="noreferrer"
              className="text-xs text-gray-primary underline"
            >
              {t.register.artist.personalDetail.dataProtectionHint.link.text}
            </a>
            {t.register.artist.personalDetail.dataProtectionHint.suffix}
          </div>
        </div>
      </NavigationForm>
    </SmallContainer>
  )
}

export default AccountDetail
