import { useSelector } from 'react-redux'
import MediumContainer from '../../../UI/Container/MediumContainer'
import Headline from '../../../UI/Headline/Headline'
import Loading from '../../../UI/Loading/Loading'
import props from '../../../../redux/props'
import useGetArtist from '../../../hooks/useGetArtist'
import { Project as ProjectType, Status, VerifiedBy } from '../../../../types/Project'
import { crud } from '@think-internet/zeus-frontend-package'
import { useEffect, useState } from 'react'
import toast, { TYPE } from '../../../../toast'
import ProjectGridView from '../../../Project/ProjectGridView/ProjectGridView'
import placeholder from '../../../../assets/image/artist/placeholder.png'
import placeholderIcon from '../../../../assets/image/artist/project/placeholderIcon.png'
import Notification from '../../../../types/Notification'
import Progress from '../Settings/Components/Progress'

const List: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [artist] = useGetArtist()
  const projectFeature = crud.use(props.PROJECT)
  const [projects, setProjects] = useState<ProjectType[]>()
  const notifications: Notification[] = useSelector((s) => s[props.NOTIFICATION_LIST])

  useEffect(() => {
    const listProjects = async () => {
      const projects = await projectFeature.list({ artistUUID: artist.uuid, 'verification.verifiedBy': VerifiedBy.QSELLART })
      if (Array.isArray(projects)) {
        setProjects(projects)
      } else {
        toast(TYPE.ERROR, t.artist.project.list.error)
      }
    }
    if (!projects && !!artist) {
      listProjects()
    }
  }, [artist, projects, t, projectFeature])

  const hasCompletedProjects = () => !!projects.find((p) => p.status === Status.COMPLETED)
  const hasInProgressProjects = () => !!projects.find((p) => p.status !== Status.COMPLETED)

  const hasNotification = (uuid: string) => {
    if (!notifications) return false
    return !!notifications.find((notification: Notification) => {
      const payload = { projectUUID: uuid }
      const keys = Object.keys(payload)
      return keys.every((key) => notification.payload[key] === payload[key])
    })
  }

  return (
    <MediumContainer className="py-3">
      <Loading loading={artist} />
      {projects && (
        <>
          {!hasInProgressProjects() && !hasCompletedProjects() && (
            <>
              <Headline className="mb-3">{t.artist.project.list.titlePlaceholder}</Headline>
              <div className="mb-10">
                <Progress artist={artist} />
              </div>
              <div className="relative">
                <div className="lg:absolute w-full top-0 h-full left-0 flex items-center flex-col lg:pt-10">
                  <img className="w-9" src={placeholderIcon} alt="" />
                  <div className="font-bold text-gray-primary mt-2">{t.artist.project.list.placeholder.title}</div>
                  <div className="lg:w-6/12 text-center text-gray-primary mt-2 text-sm">{t.artist.project.list.placeholder.text}</div>
                </div>
                <img className="w-full hidden lg:block" src={placeholder} alt="" />
              </div>
            </>
          )}
          {hasInProgressProjects() && (
            <>
              <Headline className="mb-3">{t.artist.project.list.titleInProgress}</Headline>
              <div className="flex flex-col gap-6 md:gap-[40px] lg:gap-[20px] 2xl:gap-[40px]">
                {projects
                  .filter((p) => p.status !== Status.COMPLETED && hasNotification(p.uuid))
                  .map((p) => (
                    <ProjectGridView key={p.uuid} project={p} linkToInternalView />
                  ))}
                {projects
                  .filter((p) => p.status !== Status.COMPLETED && !hasNotification(p.uuid))
                  .map((p) => (
                    <ProjectGridView key={p.uuid} project={p} linkToInternalView />
                  ))}
              </div>
            </>
          )}
          {hasCompletedProjects() && (
            <>
              <Headline className="mb-3 mt-6">{t.artist.project.list.titleCompleted}</Headline>
              <div className="flex flex-col gap-6 md:gap-[40px] lg:gap-[20px] 2xl:gap-[40px]">
                {projects
                  .filter((p) => p.status === Status.COMPLETED && hasNotification(p.uuid))
                  .map((p) => (
                    <ProjectGridView key={p.uuid} project={p} linkToInternalView />
                  ))}
                {projects
                  .filter((p) => p.status === Status.COMPLETED && !hasNotification(p.uuid))
                  .map((p) => (
                    <ProjectGridView key={p.uuid} project={p} linkToInternalView />
                  ))}
              </div>
            </>
          )}
        </>
      )}
    </MediumContainer>
  )
}

export default List
