import NewInput from '../../../UI/NewInput/NewInput'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import ModalSimple from '../../../UI/ModalSimple/ModalSimple'
import Loading from '../../../UI/Loading/Loading'
import Dropdown from '../../../UI/Dropdown/Dropdown'
import { crud } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../../../toast'
import { Category, Tag as TagType } from '../../../../types/Tag'

type Props = {
  tag?: TagType
  callback: (tag: TagType) => void
  children?: React.ReactNode
}

const Upsert: React.FC<Props> = ({ tag, callback, children }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [show, setShow] = useState<boolean>(false)
  const [data, setData] = useState<TagType>(tag || {})
  const tagFeature = crud.use(props.TAG)

  const set = (key: keyof TagType) => (value: string) => setData({ ...data, [key]: value })

  const submit = async () => {
    const response = await tagFeature.upsert(data)
    if (response) {
      callback(response)
      setShow(false)
      setData({})
    } else {
      toast(TYPE.ERROR, t.admin.tags.upsert.error)
    }
  }

  const close = () => {
    setShow(false)
    if (!tag) {
      setData({})
    }
  }

  return (
    <>
      <div onClick={() => setShow(true)}>{children}</div>
      <ModalSimple
        confirmButtonText={t.generic.save}
        show={show}
        onClose={close}
        onConfirm={submit}
        title={data.uuid ? data.name : t.admin.tags.upsert.cta}
      >
        {!data && <Loading loading={data} />}
        {data && (
          <div className="flex flex-col gap-3">
            <NewInput required label={t.admin.tags.upsert.name} onChange={set('name')} value={data.name} autoComplete="off" />
            <Dropdown
              required
              onChange={set('category')}
              value={data.category}
              items={Object.values(Category).map((c) => ({ value: c, label: t.tag.category[c] }))}
            />
          </div>
        )}
      </ModalSimple>
    </>
  )
}

export default Upsert
