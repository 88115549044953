import Story from './Components/Story'
import Pricing from './Components/Pricing/Pricing'
import Projects from './Components/Projects/Projects'
import ArtistHeaderMask from '../../UI/Artist/HeaderMask/HeaderMask'
import Headline from '../../UI/Headline/Headline'
import Reviews from './Components/Reviews/Reviews'
import { Artist } from '../../../types/Artist'

const Section = ({ children, className = '' }) => <div className={`flex flex-col md:flex-row gap-3 ${className}`}>{children}</div>

const Core: React.FC<{ artist: Artist }> = ({ artist }) => {
  return (
    <>
      <Headline className="mb-3">{artist.personalDetail.username}</Headline>
      <div className="flex flex-col gap-0">
        <div className="w-full">
          <ArtistHeaderMask artist={artist} />
        </div>
        <Section className="mt-16 !gap-24">
          <Story artist={artist} />
          <Reviews artist={artist} />
        </Section>
        {artist.pricing.hidden !== true && (
          <Section>
            <Pricing artist={artist} />
          </Section>
        )}
        <Section>
          <Projects artist={artist} />
        </Section>
      </div>
    </>
  )
}

export default Core
