import { Project } from '../../../../../../types/Project'
import GrayBox from '../../../../../UI/Graybox/Graybox'
import Headline from '../../../../../UI/Headline/Headline'
import { useSelector } from 'react-redux'
import props from '../../../../../../redux/props'
import PriceCalculator, { PriceCalculatorType } from '../../../../../UI/PriceCalculator/PriceCalculator'
import Title from '../../../../../UI/Graybox/Title'

type Props = {
  project: Project
}

const Pricing: React.FC<Props> = ({ project }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div className="mt-5">
      <Headline className="mb-2 text-md">{t.project.view.pricing.headline}</Headline>
      <GrayBox>
        <Title title={t.project.view.pricing.overview.title} />
        <div className="lg:pl-10">
          <PriceCalculator type={PriceCalculatorType.PROJECT} project={project} />
        </div>
      </GrayBox>
    </div>
  )
}

export default Pricing
