import { useSelector } from 'react-redux'
import { ArtistListItem, Request } from '../../../../types/Request'
import { Offer } from '../../../../types/Offer'
import { formatPrice, getPercentageOfPrice } from '../../../../utility'
import props from '../../../../redux/props'
import Property from '../../PropertyList/Property'
import { useEffect } from 'react'
import AdditionalCosts from '../Components/AdditionalCosts'
import { getTotalPriceOfOffer } from '../../../../utility'
import _ from 'lodash'
import AlternativeBudget from '../Components/AlternativeBudget'
import { Pricing } from '../../../../types/Project'

type Props = {
  artist: ArtistListItem
  request: Request
  offer?: Offer
  setOffer?: (data: Offer) => void
  className?: string
}

const ForWorkshopRequest: React.FC<Props> = ({ artist, request, offer, setOffer, className = '' }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const getTotalPrice = () => getTotalPriceOfOffer(request, offer)

  const isReverseOffer = !!artist && Array.isArray(request.artistList) && request.artistList.some((a) => a.uuid === artist.uuid)

  const additionalPriceIsSet =
    _.has(offer, 'pricing.additional.price') && isFinite(offer.pricing.additional.price) && offer.pricing.additional.price > 0
  const discountPriceIsSet = _.has(offer, 'pricing.discount') && isFinite(offer.pricing.discount) && offer.pricing.discount > 0

  useEffect(() => {
    if (!offer && !!setOffer) {
      const offer: Offer = {
        requestUUID: request.uuid,
        pricing: {
          pricePerAttendee: artist.pricing.workshop.pricePerAttendee,
          downPaymentPercentage: artist.pricing.downPaymentPercentage,
        },
      }
      setOffer(offer)
    }
  }, [offer, artist, setOffer, request])

  const set = (prop: keyof Pricing) => (value: any) => {
    const newOffer = { ...offer, pricing: { ...(offer.pricing || {}), [prop]: value } }
    setOffer(newOffer)
  }

  const setOfferProp = (prop: keyof Offer) => (value: any) => {
    const newOffer = { ...offer, [prop]: value }
    setOffer(newOffer)
  }

  if (!offer) return null
  return (
    <div className={className}>
      {!!setOffer && (
        <div className="mb-3">
          {isReverseOffer && <AdditionalCosts value={offer.pricing.additional} onChange={set('additional')} isReverseOffer={isReverseOffer} />}
          {/* <DiscountAmount value={offer.pricing.discount} onChange={set('pricing')('discount')} /> */}
        </div>
      )}
      <div className="mt-3">
        {/* in case of an initial offer show only the expected budget and the option to set an alternativ price */}
        {/* which automatically calculates the discount/additional costs */}
        {!isReverseOffer && !!setOffer && <AlternativeBudget request={request} offer={offer} set={setOfferProp} />}

        {/* in case of a reverse offer show all properties */}
        {(isReverseOffer || !setOffer) && (
          <>
            <Property
              property={{
                name: `${t.priceCalculator.attendee.label} ${request.attendee.amount}${t.generic.multiply}${formatPrice(
                  artist.pricing.workshop.pricePerAttendee,
                )}`,
                value: formatPrice(request.attendee.amount * artist.pricing.workshop.pricePerAttendee),
              }}
            />
            {additionalPriceIsSet && (
              <>
                <Property
                  className="mt-5"
                  property={{
                    name: t.priceCalculator.additionalCosts.label,
                    value: formatPrice(offer.pricing.additional.price),
                  }}
                />
                {!!offer.pricing.additional.description && <div className="text-sm text-gray-primary">{offer.pricing.additional.description}</div>}
              </>
            )}
            {discountPriceIsSet && (
              <Property
                className="mt-5"
                property={{
                  name: t.priceCalculator.discountAmount.label,
                  value: formatPrice(offer.pricing.discount),
                }}
              />
            )}
            <Property
              className="mt-5"
              property={{
                name: t.priceCalculator.total,
                value: formatPrice(getTotalPrice()),
              }}
            />
            <Property
              className="mt-5"
              property={{
                name: `${t.priceCalculator.downPayment} ${offer.pricing.downPaymentPercentage}${t.generic.percent}`,
                value: formatPrice(getPercentageOfPrice(getTotalPrice(), offer.pricing.downPaymentPercentage)),
              }}
            />
            <Property
              property={{
                name: `${t.priceCalculator.finalPayment} ${100 - offer.pricing.downPaymentPercentage}${t.generic.percent}`,
                value: formatPrice(getPercentageOfPrice(getTotalPrice(), 100 - offer.pricing.downPaymentPercentage)),
              }}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default ForWorkshopRequest
