import { Artist } from '../../../../../../types/Artist'
import { Deadline, NameAndLocation, Project, SizeAndPrimer, Verification, VerifiedBy } from '../../../../../../types/Project'
import { useSelector } from 'react-redux'
import props from '../../../../../../redux/props'
import LocationAutocomplete from '../../../../../UI/LocationAutocomplete/LocationAutocomplete'
import NewInput from '../../../../../UI/NewInput/NewInput'
import { useEffect } from 'react'
import Number from '../../../../../UI/NewInput/Number'
import Tags from './Tags'
import { getUUID } from '../../../../../../utility'
import ProjectImageUploadMask from '../../../../../UI/ProjectImageUploadMask/ProjectImageUploadMask'
import { UPLOAD_TYPES } from '../../../../../UI/DirectFileUpload/DirectFileUpload'
import Textarea from '../../../../../UI/Textarea/Textarea'

type Props = {
  artist: Artist
  project: Project
  onChange: (project: Project) => void
}

export type Set = <T>(projectKey: keyof Project, key: keyof T) => (value: any) => void

const Create: React.FC<Props> = ({ artist, project, onChange }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  useEffect(() => {
    if (!project) {
      onChange({
        verification: {
          verifiedBy: VerifiedBy.EXTERNAL,
          mailText: t.artist.profile.externalProjects.edit.verificationTextTemplate,
        },
      })
    }
  }, [project, artist, onChange, t])

  const set: Set = (projectKey, key) => (value: any) => {
    onChange({ ...project, [projectKey]: { ...(project[projectKey] as Object), [key]: value } })
  }

  if (!project) return <></>
  return (
    <div>
      <div className="font-bold">{t.artist.profile.externalProjects.edit.title}</div>
      <div className="flex flex-col md:flex-row gap-5 mt-3">
        <div className="md:w-1/2">
          <div className="text-sm">{t.artist.profile.externalProjects.edit.intro}</div>
          <div className="text-sm mt-5">{t.artist.profile.externalProjects.edit.description}</div>
          <div className="flex gap-2 mt-7">
            <div className="md:w-1/2">
              <LocationAutocomplete
                required
                onChange={set<NameAndLocation>('nameAndLocation', 'location')}
                label={t.generic.postcodeCity}
                initValue={project.nameAndLocation?.location?.name}
              />
            </div>
            <div className="md:w-1/2">
              <NewInput
                required
                value={project.verification?.verifiedByClientName}
                onChange={set<Verification>('verification', 'verifiedByClientName')}
                placeholder={t.artist.profile.externalProjects.edit.verifiedByClientName}
              />
            </div>
          </div>
          <div className="flex gap-2 mt-2">
            <div className="md:w-1/2">
              <Number
                required
                value={project.sizeAndPrimer?.sizeInSquareMeters}
                onChange={set<SizeAndPrimer>('sizeAndPrimer', 'sizeInSquareMeters')}
                placeholder={t.artist.profile.externalProjects.edit.size}
                suffixText={t.generic.squareMeter}
              />
            </div>
            <div className="md:w-1/2">
              <NewInput
                required
                type="date"
                value={project.deadline?.date}
                onChange={set<Deadline>('deadline', 'date')}
                placeholder={t.request.create.deadline.title}
              />
            </div>
          </div>
          <ProjectImageUploadMask
            uploadType={UPLOAD_TYPES.EXTERNAL_PROJECT_IMAGE}
            imageKeys={project.design?.keys}
            callback={set('design', 'keys')}
            horizontal
            className="md:hidden mt-5"
          />
          <Tags project={project} onChange={set} />
          <div className="font-bold mt-7">{t.artist.profile.externalProjects.edit.process.title}</div>
          {t.artist.profile.externalProjects.edit.process.text.map((t: string) => (
            <div className="text-sm mt-3 leading-5" key={getUUID()}>
              {t}
            </div>
          ))}
          <div className="flex gap-2 mt-2"></div>
        </div>
        <div className="md:w-1/2">
          <ProjectImageUploadMask
            uploadType={UPLOAD_TYPES.EXTERNAL_PROJECT_IMAGE}
            imageKeys={project.design?.keys}
            callback={set('design', 'keys')}
            horizontal
            className="hidden md:flex"
          />
          <div className="flex flex-col gap-2 mt-0 md:mt-20">
            <NewInput
              required
              className="lg:!w-1/2"
              value={project.nameAndLocation?.name}
              onChange={set<NameAndLocation>('nameAndLocation', 'name')}
              placeholder={t.artist.profile.externalProjects.edit.name}
            />
            <NewInput
              required
              className="lg:!w-1/2"
              type="email"
              value={project.verification?.verifiedByClientMail}
              onChange={set<Verification>('verification', 'verifiedByClientMail')}
              placeholder={t.artist.profile.externalProjects.edit.verifiedByClientMail}
            />
            <Textarea
              required
              className="font-extralight"
              value={project.verification.mailText}
              onChange={set<Verification>('verification', 'mailText')}
              placeholder={t.artist.profile.externalProjects.edit.verificationTextPlaceholder}
              rows={10}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Create
