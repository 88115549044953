import placeholder from '../../../../../assets/image/invoices/placeholder.jpg'
import { Invoice } from '../../../../../types/Invoice'

type Props = {
  invoice?: Invoice
}

const Preview: React.FC<Props> = ({ invoice }) => {
  return (
    <div className={`flex items-center justify-center`}>
      <div className="lg:w-8/12">
        {!invoice && <img src={placeholder} alt="" className="w-full" />}
        {!!invoice && invoice.previewImageURL && <img src={invoice.previewImageURL} alt="" className="w-full" />}
      </div>
    </div>
  )
}

export default Preview
