import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Carousel from '../Carousel/Carousel'
import { getUUID } from '../../../utility'
import { Headline, Card, Header, Type, Name, CTAWrapper, CTA, CardWrapper } from './SuggestedProjects.Styled'
import { useEffect, useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import { Project } from '../../../types/Project'

const SuggestedProjects = ({ hideHeadline = false }) => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const [projects, setProjects] = useState<Project[]>()
  const getSuggestedProjects = functional.use(Routes.GET_SUGGESTED_PROJECTS)

  useEffect(() => {
    const getProjects = async () => {
      const projects = await getSuggestedProjects()
      if (Array.isArray(projects)) {
        setProjects(projects)
      }
    }
    if (!projects) {
      getProjects()
    }
  }, [projects, getSuggestedProjects])

  if (!projects || projects.length === 0) return <></>
  return (
    <div>
      {!hideHeadline && <Headline>{translation.home.projects.headline}</Headline>}
      <Carousel
        itemsToShow={4}
        itemsToShowMobile={1}
        items={projects.map((project) => (
          <CardWrapper key={project.uuid}>
            <Card key={getUUID()} image={project.design.urls[0]} image2={project.design.urls[1]}>
              <Header>
                <Type>{''}</Type>
                <Name>{project.nameAndLocation.name}</Name>
              </Header>
              <CTAWrapper>
                <CTA href={`/project/view/${project.uuid}`}>{translation.home.projects.cta}</CTA>
              </CTAWrapper>
            </Card>
          </CardWrapper>
        ))}
      />
    </div>
  )
}

export default SuggestedProjects
