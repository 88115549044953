import { Bar, BarChart, Cell, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { Invoice } from '../../../../../types/Invoice'
import { useEffect, useState } from 'react'
import YearSelection from '../List/YearSelection'
import { formatPrice, getUUID } from '../../../../../utility'

type Props = {
  invoices: Invoice[]
}

type DataItem = {
  name: string
  keys: string[]
  total: number
  [invoiceNumber: string]: any
}

const Yearly: React.FC<Props> = ({ invoices }) => {
  const [data, setData] = useState<DataItem[]>([])
  const [year, setYear] = useState<number>(null)

  useEffect(() => {
    if (!!invoices) {
      const years = invoices.map((invoice) => new Date(invoice.createdOn).getFullYear())
      const uniqueYears = [...new Set(years)].filter((y) => year === 0 || y === year)

      const data: DataItem[] = []
      for (const year of uniqueYears) {
        const invoicesForYear = invoices.filter((invoice) => new Date(invoice.createdOn).getFullYear() === year)
        const yearData = {
          name: year.toString(),
          keys: [],
          total: invoicesForYear.reduce((acc, cur) => acc + cur.payment.netInCents / 100, 0),
        }
        for (const invoice of invoicesForYear) {
          yearData.keys.push(invoice.fullNumber)
          yearData[invoice.fullNumber] = invoice.payment.netInCents / 100
        }
        data.push(yearData)
      }

      setData(data)
    }
  }, [invoices, setData, year])

  const Label = (props: any) => {
    const { x, y, fill, value, width } = props

    if (value === 0) return null
    return (
      <text x={x + width / 2} y={y} dy={-4} width={100} fontSize="16" fill={fill} textAnchor="middle">
        {formatPrice(value, true)}
      </text>
    )
  }

  const getMaxValue = () => {
    let max = 0
    for (const d of data) {
      const sum = d.keys.reduce((acc, cur) => acc + d[cur], 0)
      if (sum > max) max = sum
    }
    return max + max / 3
  }

  const selectColor = (index: number): string => {
    const colors = ['#00dece', '#63edac', '#b0f689', '#00cce4']
    return colors[index % 4]
  }

  return (
    <>
      <div className="lg:w-40 mb-3">
        <YearSelection invoices={invoices} value={year} onChange={setYear} allowTotal />
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data} margin={{ top: 20, bottom: 10 }}>
          <XAxis dataKey="name" tickLine={false} />
          <YAxis tickCount={8} domain={[0, getMaxValue()]} width={60} tickLine={false} tickFormatter={(value: number) => formatPrice(value, true)} />
          {data.map((d) => {
            return d.keys.map((key, i) => (
              <Bar key={getUUID()} dataKey={key} stackId={'1'} label={i === d.keys.length - 1 ? <Label /> : null}>
                <Cell key={`cell-${i}`} fill={selectColor(i)} />
              </Bar>
            ))
          })}
        </BarChart>
      </ResponsiveContainer>
    </>
  )
}

export default Yearly
