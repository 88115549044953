import { useEffect, useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../redux/routes'
import { Project, VerifiedBy } from '../../types/Project'

type Payload = {
  artistUUID?: string
  bookerUUID?: string
  isVisible?: boolean
}

type Props = (payload: Payload) => Project[]

const useListProjects: Props = (payload) => {
  const [projects, setProjects] = useState<Project[]>()
  const listProjects = functional.use(Routes.LIST_PROJECTS)

  useEffect(() => {
    const list = async () => {
      if (!!payload.artistUUID || !!payload.bookerUUID) {
        const projects: Project[] = await listProjects(payload)
        setProjects(
          projects.filter((project) => project.verification.isVerifiedByClient === true || project.verification.verifiedBy === VerifiedBy.QSELLART),
        )
      }
    }
    if (!projects) {
      list()
    }
  })

  return projects
}

export default useListProjects
