import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import deLocale from '@fullcalendar/core/locales/de'
import './style.css'

type Props = {
  events: any
  eventRenderer: (event: any) => JSX.Element
  onEventClick?: (event: any) => void
}

const CalendarBig: React.FC<Props> = ({ events, eventRenderer, onEventClick }) => {
  return (
    <FullCalendar
      plugins={[dayGridPlugin]}
      initialView="dayGridMonth"
      height={'auto'}
      events={events}
      eventContent={eventRenderer}
      eventClick={onEventClick}
      eventDisplay="block"
      locale={deLocale}
      fixedWeekCount={false}
      titleFormat={{ year: 'numeric', month: 'long' }}
      headerToolbar={{
        left: 'dayGridWeek,dayGridMonth',
        center: 'title',
        right: 'today,prev,next',
      }}
    />
  )
}

export default CalendarBig
