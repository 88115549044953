import { useSelector } from 'react-redux'
import { Invoice, InvoiceType } from '../../../../../types/Invoice'
import GrayBox from '../../../../UI/Graybox/Graybox'
import Title from '../../../../UI/Graybox/Title'
import Loading from '../../../../UI/Loading/Loading'
import props from '../../../../../redux/props'
import Yearly from './Yearly'
import ProjectsPerYear from './ProjectsPerYear'

type Props = {
  invoices: Invoice[]
}

const Diagrams: React.FC<Props> = ({ invoices }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const filter = (invoice: Invoice) => {
    return invoice.type === InvoiceType.ARTIST_TO_BOOKER
  }

  return (
    <GrayBox>
      <Title title={t.invoices.diagrams.title} />
      <Loading loading={invoices} />
      {!!invoices && invoices.length === 0 && <div className="text-center text-gray-primary text-sm">{t.invoices.diagrams.noData}</div>}
      {!!invoices && invoices.length > 0 && (
        <div className="flex flex-col lg:flex-row pb-9 gap-10">
          <div className="w-full h-[250px] lg:h-[425px]">
            <Yearly invoices={invoices.filter(filter)} />
          </div>
          <div className="w-full h-[250px] lg:h-[425px]">
            <ProjectsPerYear invoices={invoices.filter(filter)} />
          </div>
        </div>
      )}
    </GrayBox>
  )
}

export default Diagrams
