import NameAndLocation from '../StepComponents/NameAndLocation/NameAndLocation'
import Deadline from '../StepComponents/Deadline/Deadline'
import Design from '../StepComponents/Design/Design'
import { ComponentMapping as ComponentMappingType } from '../../../UI/Pipeline/types'
import Summary from './Summary/Summary'
import Attendee from '../StepComponents/Attendee/Attendee'

const ComponentMapping: ComponentMappingType = [
  {
    component: NameAndLocation,
    pipelineStateProp: 'nameAndLocation',
  },
  {
    component: Attendee,
    pipelineStateProp: 'attendee',
  },
  {
    component: Deadline,
    pipelineStateProp: 'deadline',
  },
  {
    component: Design,
    pipelineStateProp: 'design',
  },
  {
    component: Summary,
    pipelineStateProp: 'summary',
  },
]

export default ComponentMapping
