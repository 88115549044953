import { Route, Routes } from 'react-router-dom'
import View from './View/View'
import List from './List'
import Rating from './Rating/Rating'
import Milestones from '../../Components/Project/Milestones/Milestones'

const Project = () => {
  return (
    <Routes>
      <Route path={'view/:uuid'} element={<View />} />
      <Route path={'milestones/:uuid'} element={<Milestones />} />
      <Route path={'rating/:uuid'} element={<Rating />} />
      <Route path={'*'} element={<List />} />
    </Routes>
  )
}

export default Project
