import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { Milestone, MilestoneType } from '../../../../types/Milestone'
import NewModal from '../../../UI/NewModal/NewModal'
import { useState } from 'react'
import decline from '../../../../assets/image/milestone/decline.png'
import Routes from '../../../../redux/routes'
import { functional } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../../../toast'
import { UpdateListCallback } from '../types'

type Props = {
  milestone: Milestone
  milestones: Milestone[]
  isArtist: boolean
  updateListCallback: UpdateListCallback
}

const DeclineCompletion: React.FC<Props> = ({ milestone, milestones, isArtist, updateListCallback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState<boolean>(false)
  const declineCompletion = functional.use(Routes.DECLINE_MILESTONE_COMPLETION)

  const toggle = () => setOpen(!open)

  const trigger = async () => {
    const milestones: Milestone[] = await declineCompletion({ uuid: milestone.uuid })
    if (milestones) {
      updateListCallback(milestones)
      setOpen(false)
      toast(TYPE.SUCCESS, t.milestone.declineCompletion.success)
    } else {
      toast(TYPE.ERROR, t.milestone.declineCompletion.error)
    }
  }

  const isVisible = () => {
    if (isArtist) return false
    if (milestone.type === MilestoneType.COMPLETE_FINAL_PAYMENT && milestone.acceptedByArtist && !milestone.acceptedByBooker) return true
    if (milestone.type === MilestoneType.PROJECT_IN_PROGRESS && milestone.acceptedByArtist && !milestone.acceptedByBooker) return true
    if (milestone.type === MilestoneType.PROJECT_COMPLETED && milestone.acceptedByArtist && !milestone.acceptedByBooker) return true
    return false
  }

  if (!isVisible()) return <></>
  return (
    <div className={`w-6 justify-center items-center shrink-0 flex`}>
      <img onClick={toggle} src={decline} className="h-6 cursor-pointer" alt="" />
      <NewModal show={open} onClose={toggle} onConfirm={trigger} noForm cancelButtonText={t.generic.cancel}>
        {milestone.type === MilestoneType.COMPLETE_FINAL_PAYMENT && (
          <>
            <div className="font-bold">{t.milestone.declineCompletion.completeFinalPayment.title}</div>
            <div className="mt-2">{t.milestone.declineCompletion.completeFinalPayment.text}</div>
          </>
        )}
        {milestone.type === MilestoneType.PROJECT_IN_PROGRESS && (
          <>
            <div className="font-bold">{t.milestone.declineCompletion.projectInProgress.title}</div>
            <div className="mt-2">{t.milestone.declineCompletion.projectInProgress.text}</div>
          </>
        )}
        {milestone.type === MilestoneType.PROJECT_COMPLETED && (
          <>
            <div className="font-bold">{t.milestone.declineCompletion.projectCompleted.title}</div>
            <div className="mt-2">{t.milestone.declineCompletion.projectCompleted.text}</div>
          </>
        )}
      </NewModal>
    </div>
  )
}

export default DeclineCompletion
