import { useSelector } from 'react-redux'
import { Sizes, SizesFullName, sizeValues } from '../../../types/Size'
import props from '../../../redux/props'

type Prop = {
  size: Sizes
  className?: string
  invert?: boolean
  onClick?: () => void
  useSmallName?: boolean
}

const SizeAndValue: React.FC<Prop> = ({ size, className = '', invert = false, onClick = null, useSmallName = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  return (
    <div
      className={`flex flex-row gap-3 items-center border border-blue-primary rounded-full ${invert ? 'bg-white' : 'bg-blue-primary'} ${
        !!onClick && 'cursor-pointer'
      } ${className}`}
      onClick={onClick}
    >
      <div className="flex">
        {!!size && (
          <div
            className={`text-blue-primary bg-gray-secondary w-7 ${
              !useSmallName && 'min-w-max'
            } h-7 px-3 flex justify-center items-center text-sm font-bold rounded-full`}
          >
            {useSmallName ? Sizes[size] : SizesFullName[size]}
          </div>
        )}
        <div
          className={`min-w-[4rem] h-7 flex justify-end items-center text-sm px-2 whitespace-nowrap ${invert ? 'text-blue-primary' : 'text-white'}`}
        >
          {!!sizeValues[size].max
            ? `${sizeValues[size].min} - ${sizeValues[size].max}${t.generic.squareMeter}`
            : `${t.generic.priceFromPrefix}${sizeValues[size].min}${t.generic.squareMeter}`}
        </div>
      </div>
    </div>
  )
}

export default SizeAndValue
