import { crud, functional } from '@think-internet/zeus-frontend-package'
import { Artist } from '../../../../../../types/Artist'
import props from '../../../../../../redux/props'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { Project, VerifiedBy } from '../../../../../../types/Project'
import toast, { TYPE } from '../../../../../../toast'
import styled from 'styled-components'
import NewButton from '../../../../../UI/NewButton/NewButton'
import Routes from '../../../../../../redux/routes'
import ProjectGridView from '../../../../../Project/ProjectGridView/ProjectGridView'
import Modal from '../../../../../UI/NewModal/NewModal'
import Visibility from './Visibility'

type Props = {
  artist: Artist
}

const ListItem = styled.div`
  width: calc(50% - 5px);
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

const List: React.FC<Props> = ({ artist }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const projectFeature = crud.use(props.PROJECT)
  const [projects, setProjects] = useState<Project[]>()
  const sendAgain = functional.use(Routes.SEND_EXTERNAL_PROJECT_VERIFICATION_MAIL_AGAIN)
  const [openRemoveConfirmation, setOpenRemoveConfirmation] = useState<string>(null)

  useEffect(() => {
    const listProjects = async () => {
      const projects = await projectFeature.list({ artistUUID: artist.uuid, 'verification.verifiedBy': VerifiedBy.EXTERNAL })
      if (Array.isArray(projects)) {
        setProjects(projects)
      } else {
        toast(TYPE.ERROR, t.artist.profile.externalProjects.list.error)
      }
    }
    if (!projects) {
      listProjects()
    }
  }, [projectFeature, projects, artist, t])

  const remove = async () => {
    const result = await projectFeature.remove({ uuid: openRemoveConfirmation })
    if (!!result) {
      toast(TYPE.SUCCESS, t.artist.profile.externalProjects.list.delete.success)
      setProjects(projects.filter((p) => p.uuid !== openRemoveConfirmation))
      toggleRemoveConfirmation(null)()
    } else {
      toast(TYPE.ERROR, t.artist.profile.externalProjects.list.delete.error)
    }
  }

  const sendVerificationRequestAgain = (uuid: string) => async () => {
    const result = await sendAgain({ projectUUID: uuid })
    if (!!result) {
      toast(TYPE.SUCCESS, t.artist.profile.externalProjects.list.sendVerificationRequestAgain.success)
    } else {
      toast(TYPE.ERROR, t.artist.profile.externalProjects.list.sendVerificationRequestAgain.error)
    }
  }

  const toggleRemoveConfirmation = (uuid: string) => () => setOpenRemoveConfirmation(uuid)

  return (
    <div>
      <div className="font-bold">{t.artist.profile.externalProjects.title}</div>
      {Array.isArray(projects) && (
        <div className="mt-3">
          {projects.length === 0 && <div className="text-center text-sm">{t.artist.profile.externalProjects.noProjects}</div>}
          {projects.length > 0 && (
            <div className="flex flex-col md:flex-row gap-[10px] flex-wrap">
              {projects.map((p) => (
                <ListItem key={p.uuid}>
                  <ProjectGridView project={p} minifiedView />
                  {p.verification.isVerifiedByClient === false && (
                    <div className="my-2 flex flex-col md:flex-row gap-1 items-center justify-center">
                      <NewButton
                        onClick={sendVerificationRequestAgain(p.uuid)}
                        text={t.artist.profile.externalProjects.list.sendVerificationRequestAgain.cta}
                        className="btn-secondary !text-sm"
                      />

                      <NewButton
                        onClick={toggleRemoveConfirmation(p.uuid)}
                        text={
                          p.verification.isVerifiedByClient
                            ? t.artist.profile.externalProjects.list.delete.project
                            : t.artist.profile.externalProjects.list.delete.request
                        }
                        className="btn-secondary-outline !text-sm"
                      />
                    </div>
                  )}
                  {p.verification.isVerifiedByClient === true && (
                    <div className="my-2 flex flex-col md:flex-row gap-3 items-center">
                      <NewButton
                        onClick={toggleRemoveConfirmation(p.uuid)}
                        text={
                          p.verification.isVerifiedByClient
                            ? t.artist.profile.externalProjects.list.delete.project
                            : t.artist.profile.externalProjects.list.delete.request
                        }
                        className="btn-secondary-outline !text-sm"
                      />
                      <Visibility project={p} />
                    </div>
                  )}
                </ListItem>
              ))}
            </div>
          )}
        </div>
      )}
      <Modal
        title={t.artist.profile.externalProjects.list.delete.modal.title}
        show={!!openRemoveConfirmation}
        onClose={toggleRemoveConfirmation(null)}
        onConfirm={remove}
        noForm
      >
        <div className="text-gray-primary">{t.artist.profile.externalProjects.list.delete.modal.text}</div>
      </Modal>
    </div>
  )
}

export default List
