import { useDispatch, useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { useEffect } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../redux/routes'
import { setLocal } from '../../../../redux/action/local'

const useTags = () => {
  const dispatch = useDispatch()
  const tags = useSelector((s) => s[props.TAG])
  const listTags = functional.use(Routes.LIST_TAGS)

  useEffect(() => {
    const fetchTags = async () => {
      const response = await listTags()

      if (Array.isArray(response)) {
        dispatch(setLocal(props.TAG, response))
      }
    }
    if (!tags) {
      fetchTags()
    }
  }, [tags, listTags, dispatch])

  return !!tags
}

export default useTags
