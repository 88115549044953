import { useNavigate } from 'react-router'
import { NavigationItem } from '../../types'

type Props = {
  data: NavigationItem
  onClick?: () => void
  className?: string
}

const Default: React.FC<Props> = ({ data, onClick, className = '' }) => {
  const navigate = useNavigate()

  const clicked = (e) => {
    e.preventDefault()
    if (onClick) {
      onClick()
    } else if (data.link) {
      navigate(data.link)
    }
  }

  return (
    <div className={`flex flex-row gap-1 items-center ${className}`}>
      <a href={data.link || ''} onClick={clicked} className="text-blue-primary font-thin hover:text-magenta-primary">
        {data.name}
      </a>
    </div>
  )
}

export default Default
