import { useSelector } from 'react-redux'
import DataSection from '../../../Components/DataSection/DataSection'
import { DataSectionComponent } from '../../types'
import props from '../../../../../redux/props'
import { useState } from 'react'
import { InsuranceDetail } from '../../../../../types/Artist'
import { crud } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../../../../toast'
import Number from '../../../../UI/NewInput/Number'
import NewToggle from '../../../../UI/NewToggle/NewToggle'
import { formatNumber, getUUID } from '../../../../../utility'
import Property from '../../../../UI/PropertyList/Property'

const Insurance: DataSectionComponent = ({ artist, onChangeCallback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [insuranceDetail, setInsuranceDetail] = useState<InsuranceDetail>(artist.insuranceDetail)
  const artistFeature = crud.use(props.ARTIST)

  const isFulfilled = () =>
    isFinite(insuranceDetail.pricePercentage) &&
    insuranceDetail.pricePercentage > 0 &&
    insuranceDetail.pricePercentage < 100 &&
    isFinite(insuranceDetail.radius) &&
    isFinite(insuranceDetail.timespanInMonths) &&
    isFinite(insuranceDetail.timeExpenseInHours)

  const TextSection = (showBullets: boolean = true) => (
    <div className="md:w-2/3">
      <div className="font-bold mb-4">{t.artist.profile.insurance.headline.text}</div>
      <div>
        {!showBullets && (
          <div className="mt-1 text-sm" key={getUUID()}>
            {t.artist.profile.insurance.text1}
          </div>
        )}
        {!!showBullets && (
          <div className="mt-1 text-sm" key={getUUID()}>
            {t.artist.profile.insurance.text2}
          </div>
        )}
      </div>
      {!!showBullets && (
        <div className="ml-4 mt-2">
          <ul className="list-disc">
            {t.artist.profile.insurance.bulletpoints.map((t: string) => (
              <li className="text-sm" key={getUUID()}>
                {t}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )

  const Default = (
    <div className="flex flex-col md:flex-row gap-6 mt-1">
      <div className="md:w-1/3">
        <div className="font-bold mb-4">{t.artist.profile.insurance.headline.values}</div>
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <div>{t.artist.profile.insurance.enabled.statusLabel}</div>
            {insuranceDetail.enabled && <div>{t.artist.profile.insurance.enabled.status.enabled}</div>}
            {!insuranceDetail.enabled && <div>{t.artist.profile.insurance.enabled.status.disabled}</div>}
          </div>
          {isFinite(insuranceDetail.radius) && (
            <Property property={{ name: t.artist.profile.insurance.radius, value: `${formatNumber(insuranceDetail.radius)}${t.generic.km}` }} />
          )}
          {isFinite(insuranceDetail.pricePercentage) && (
            <Property
              property={{
                name: t.artist.profile.insurance.pricePercentage,
                value: `${formatNumber(insuranceDetail.pricePercentage)}${t.generic.percent}`,
              }}
            />
          )}
          {isFinite(insuranceDetail.timespanInMonths) && (
            <Property
              property={{
                name: t.artist.profile.insurance.timespanInMonths,
                value: `${formatNumber(insuranceDetail.timespanInMonths)}${t.generic.months}`,
              }}
            />
          )}
          {isFinite(insuranceDetail.timeExpenseInHours) && (
            <Property
              property={{
                name: t.artist.profile.insurance.timeExpenseInHours,
                value: `${formatNumber(insuranceDetail.timeExpenseInHours)}${t.generic.hoursShort}`,
              }}
            />
          )}
        </div>
      </div>
      {TextSection(false)}
    </div>
  )

  const set = (prop: keyof InsuranceDetail) => (value: any) => setInsuranceDetail({ ...insuranceDetail, [prop]: value })

  const save = async () => {
    if (!insuranceDetail.enabled || (insuranceDetail.enabled && isFulfilled())) {
      const response = await artistFeature.update({ uuid: artist.uuid, insuranceDetail })
      if (!response) {
        toast(TYPE.ERROR, t.artist.profile.insurance.edit.errorGeneric)
      } else {
        if (onChangeCallback) {
          onChangeCallback(insuranceDetail)
        }
      }
      return !!response
    } else {
      toast(TYPE.ERROR, t.artist.profile.insurance.edit.error)
    }
    return false
  }

  return (
    <DataSection className="w-full" defaultView={Default} saveCallback={save}>
      <div className="flex flex-col md:flex-row gap-6 mt-1">
        <div className="md:w-1/3">
          <div className="font-bold mb-4">{t.artist.profile.insurance.headline.values}</div>
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <div>{t.artist.profile.insurance.enabled.statusLabel}</div>
              <NewToggle checked={insuranceDetail.enabled} onChange={set('enabled')} />
            </div>
            <div className="flex flex-row justify-between items-center gap-5">
              <div className="w-1/2">{t.artist.profile.insurance.radius}</div>
              <Number required={insuranceDetail.enabled} onChange={set('radius')} value={insuranceDetail.radius} suffixText={t.generic.km} />
            </div>
            <div className="flex flex-row justify-between items-center gap-5">
              <div className="w-1/2">{t.artist.profile.insurance.pricePercentage}</div>
              <Number
                required={insuranceDetail.enabled}
                onChange={set('pricePercentage')}
                value={insuranceDetail.pricePercentage}
                suffixText={t.generic.percent}
              />
            </div>
            <div className="flex flex-row justify-between items-center gap-5">
              <div className="w-1/2">{t.artist.profile.insurance.timespanInMonths}</div>
              <Number
                required={insuranceDetail.enabled}
                onChange={set('timespanInMonths')}
                value={insuranceDetail.timespanInMonths}
                suffixText={t.generic.months}
              />
            </div>
            <div className="flex flex-row justify-between items-center gap-5">
              <div className="w-1/2">{t.artist.profile.insurance.timeExpenseInHours}</div>
              <Number
                required={insuranceDetail.enabled}
                onChange={set('timeExpenseInHours')}
                value={insuranceDetail.timeExpenseInHours}
                suffixText={t.generic.hoursShort}
              />
            </div>
          </div>
        </div>
        {TextSection(true)}
      </div>
    </DataSection>
  )
}

export default Insurance
