import { useSelector } from 'react-redux'
import DataSection from '../../../Components/DataSection/DataSection'
import { DataSectionComponent } from '../../types'
import props from '../../../../../redux/props'
import { useState } from 'react'
import { Story as StoryType } from '../../../../../types/Artist'
import { crud } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../../../../toast'
import { maxArtistStoryLength, minArtistStoryLength } from '../../../../../utility'
import Textarea from '../../../../UI/Textarea/Textarea'

const Story: DataSectionComponent = ({ artist, onChangeCallback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [story, setStory] = useState<StoryType>(artist.story)
  const artistFeature = crud.use(props.ARTIST)

  const Default = (
    <div>
      <div className="text-sm break-words">{story.story}</div>
    </div>
  )

  const set = (value: any) => setStory({ ...story, story: value })

  const save = async () => {
    if (story.story?.length >= minArtistStoryLength && story.story?.length <= maxArtistStoryLength) {
      const response = await artistFeature.update({ uuid: artist.uuid, story })
      if (!response) {
        toast(TYPE.ERROR, t.artist.profile.story.edit.errorGeneric)
      } else {
        if (onChangeCallback) {
          onChangeCallback(story)
        }
      }
      return !!response
    } else {
      toast(TYPE.ERROR, t.artist.profile.story.edit.error)
    }
    return false
  }

  return (
    <DataSection className="md:w-2/3" title={t.artist.profile.story.title} defaultView={Default} saveCallback={save}>
      <Textarea required value={story.story} onChange={set} minCharacters={minArtistStoryLength} maxCharacters={maxArtistStoryLength} rows={4} />
    </DataSection>
  )
}

export default Story
