import { Milestone } from '../../../../types/Milestone'
import NewModal from '../../../UI/NewModal/NewModal'
import { useState } from 'react'
import settings from '../../../../assets/image/milestone/settings.png'
import { UpdateListCallback } from '../types'
import Upsert from './Upsert'
import { Project } from '../../../../types/Project'
import props from '../../../../redux/props'
import { useSelector } from 'react-redux'

type Props = {
  project: Project
  milestone: Milestone
  updateListCallback: UpdateListCallback
  isArtist: boolean
}

const Settings: React.FC<Props> = ({ project, milestone, updateListCallback, isArtist }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState<boolean>(false)

  const toggle = () => setOpen(!open)

  const update = (milestones: Milestone[]) => {
    updateListCallback(milestones)
    toggle()
  }

  return (
    <>
      <img onClick={toggle} src={settings} className="h-6 cursor-pointer" alt="" />
      <NewModal show={open} onClose={toggle} noForm cancelButtonText={t.generic.cancel}>
        <Upsert projectUUID={project.uuid} milestone={milestone} updateListCallback={update} />
      </NewModal>
    </>
  )
}

export default Settings
