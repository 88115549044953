import { useSelector } from 'react-redux'
import { Pricing } from '../../../../../../types/Artist'
import { useState } from 'react'
import props from '../../../../../../redux/props'
import { Sizes } from '../../../../../../types/Size'
import SizeAndPrice from '../../../../../UI/Pricing/Editable/SizeAndPrice'

type Props = {
  initData?: Pricing['art']
  update?: (value: any) => void
}

const Art: React.FC<Props> = ({ initData, update }) => {
  const t = useSelector((state) => state[props.TRANSLATION])
  const [data, setData] = useState<Pricing['art']>(
    initData || {
      [Sizes.S]: null,
      [Sizes.M]: null,
      [Sizes.L]: null,
    },
  )

  const set = (prop: Sizes) => (price: number) => {
    setData({ ...data, [prop]: price })
    update({ ...data, [prop]: price })
  }

  return (
    <div>
      <div className="font-bold mb-5">{t.register.artist.pricing.art.headline}</div>
      <div className="flex flex-col gap-4 mt-2">
        <SizeAndPrice required size={Sizes.S} price={data[Sizes.S]} updatePrice={set(Sizes.S)} hiddenDisabled={!update} />
        <SizeAndPrice required size={Sizes.M} price={data[Sizes.M]} updatePrice={set(Sizes.M)} hiddenDisabled={!update} />
        <SizeAndPrice required size={Sizes.L} price={data[Sizes.L]} updatePrice={set(Sizes.L)} hiddenDisabled={!update} />
      </div>
    </div>
  )
}

export default Art
