import { useSelector } from 'react-redux'
import { ArtistListItem, Request } from '../../../../types/Request'
import { formatPrice, getPercentageOfPrice, getTotalPriceOfRequest } from '../../../../utility'
import props from '../../../../redux/props'
import Property from '../../PropertyList/Property'
import { Pricing } from '../../../../types/Project'
import { useEffect, useState } from 'react'

type Props = {
  artist: ArtistListItem
  request: Request
  className?: string
}

const WithArtist: React.FC<Props> = ({ artist, request, className = '' }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [pricing, setPricing] = useState<Pricing>()

  useEffect(() => {
    if (!pricing) {
      const pricing: Pricing = {
        pricePerAttendee: artist.pricing.workshop.pricePerAttendee,
        downPaymentPercentage: artist.pricing.downPaymentPercentage,
      }
      setPricing(pricing)
    }
  }, [pricing, request, artist])

  const getTotalPrice = () => getTotalPriceOfRequest(artist, request)

  if (!pricing) return <></>
  return (
    <div className={className}>
      <Property
        property={{
          name: `${t.priceCalculator.attendee.min}`,
          valuePrefix: `${artist.pricing.workshop.minAttendees}${t.generic.multiply}${formatPrice(pricing.pricePerAttendee)}`,
          value: formatPrice(artist.pricing.workshop.minAttendees * pricing.pricePerAttendee),
        }}
      />
      {request.attendee.amount > artist.pricing.workshop.minAttendees && (
        <Property
          property={{
            name: `${t.priceCalculator.attendee.additional} ${request.attendee.amount - artist.pricing.workshop.minAttendees}${
              t.generic.multiply
            }${formatPrice(pricing.pricePerAttendee)}`,
            value: formatPrice((request.attendee.amount - artist.pricing.workshop.minAttendees) * pricing.pricePerAttendee),
          }}
        />
      )}
      <Property
        className="mt-5"
        property={{
          name: t.priceCalculator.total,
          value: formatPrice(getTotalPrice()),
        }}
      />
      <Property
        className="mt-5"
        property={{
          name: `${t.priceCalculator.downPayment} ${pricing.downPaymentPercentage}${t.generic.percent}`,
          value: formatPrice(getPercentageOfPrice(getTotalPrice(), pricing.downPaymentPercentage)),
        }}
      />
      <Property
        property={{
          name: `${t.priceCalculator.finalPayment} ${100 - pricing.downPaymentPercentage}${t.generic.percent}`,
          value: formatPrice(getPercentageOfPrice(getTotalPrice(), 100 - pricing.downPaymentPercentage)),
        }}
      />
    </div>
  )
}

export default WithArtist
