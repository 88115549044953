import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { SizeAndPrimer as SizeAndPrimerType } from '../../../../../types/Project'
import { GetData, PipelineComponent, PipelineState } from '../../../../UI/Pipeline/types'
import SectionHeader from '../../../../UI/Pipeline/SectionHeader'
import Number from '../../../../UI/NewInput/Number'
import SelectableTagList from '../../../../UI/SelectableTagList/SelectableTagList'
import { Primer } from '../../../../../types/Request'
import { Tag } from '../../../../../types/Tag'
import toast, { TYPE } from '../../../../../toast'
import { calculateSquareMeters, formatNumber, getProjectSizeEnum } from '../../../../../utility'
import ArtistPriceCalculator from '../ArtistPriceCalculator/ArtistPriceCalculator'
import { Artist } from '../../../../../types/Artist'

const SizeAndPrimer: PipelineComponent<SizeAndPrimerType> = ({ pipelineStateProp, data, setData, NavigationForm, finalStepSubmit }) => {
  const t = useSelector((state) => state[props.TRANSLATION])
  const state: PipelineState<Request, { artist?: Artist }> = useSelector((state) => state[props.PIPELINE])

  const set = (prop: keyof SizeAndPrimerType) => (value: any) => setData({ ...data, [prop]: value })

  const setSize = (prop: string) => (value: number) => set('size')({ ...data.size, [prop]: value })

  const getData: GetData = () => {
    if (!data.primerRequired && !!state.additionalData.artist && !state.additionalData.artist.pricing.primer.disabled) {
      toast(TYPE.ERROR, t.request.create.sizeAndPrimer.primerRequired.error)
      return false
    }
    return data
  }

  const getPrimerTags = (): Tag[] => {
    return Object.values(Primer).map((primerOption: Primer) => {
      return {
        enum: primerOption,
        name: t.request.primerOptions[primerOption],
      }
    })
  }

  const getSquareMeterSize = (): number | false => {
    if (data.size?.length && data.size?.height) {
      return calculateSquareMeters(data.size.length, data.size.height)
    }
    return false
  }

  const getResultSize = () => {
    const size = getSquareMeterSize()
    if (typeof size === 'number') {
      const sizeEnum = getProjectSizeEnum(size)
      if (sizeEnum) {
        return t.project.sizes[sizeEnum]
      }
    }
    return t.project.size
  }

  if (!data) return null
  return (
    <div>
      <ArtistPriceCalculator pipelineStateProp={pipelineStateProp} currentState={data} />
      <NavigationForm pipelineStateProp={pipelineStateProp} getData={getData} finalStepSubmit={finalStepSubmit}>
        <SectionHeader headline={t.request.create.sizeAndPrimer.size.title} description={t.request.create.sizeAndPrimer.size.description} />
        <div className="mt-3 flex flex-col md:flex-row items-center gap-2">
          <div className="flex flex-row items-center gap-2">
            <Number
              className="rounded-full h-6 md:!w-[118px] md:shrink-0"
              contentClassName="!text-sm"
              required
              onChange={setSize('length')}
              value={data.size?.length}
              placeholder={t.project.length}
              suffixText={t.generic.meter}
              decimal
            />
            <div className="text-gray-primary">{t.generic.multiply}</div>
            <Number
              className="rounded-full h-6 md:!w-[118px] md:shrink-0"
              contentClassName="!text-sm"
              required
              onChange={setSize('height')}
              value={data.size?.height}
              placeholder={t.project.height}
              suffixText={t.generic.meter}
              decimal
            />
          </div>
          <div className="flex flex-row items-center justify-center gap-2 mt-4 md:mt-0">
            <div className="text-gray-primary hidden md:inline-block">{t.generic.equals}</div>
            <div className="flex flex-row items-center rounded-full bg-blue-primary overflow-hidden border border-blue-primary">
              <div className="flex">
                <div className="text-blue-primary bg-gray-primary-second flex items-center px-3 rounded-r-full">{getResultSize()}</div>
              </div>
              {typeof getSquareMeterSize() === 'number' && (
                <div className=" text-white whitespace-nowrap text-sm px-2">{`${formatNumber(getSquareMeterSize())}${t.generic.squareMeter}`}</div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-10">
          <SectionHeader description={t.request.create.sizeAndPrimer.primerRequired.description} />
          {!state.additionalData.artist || !state.additionalData.artist.pricing.primer?.disabled ? (
            <SelectableTagList
              maxVisibleRows={3}
              className="mt-4"
              selectedTagENUMList={[data.primerRequired]}
              tags={getPrimerTags()}
              onChange={set('primerRequired')}
              singleSelect
            />
          ) : (
            <div className="text-gray-primary text-sm mt-3 leading-4">{t.request.create.sizeAndPrimer.primerRequired.noPrimerAvailable}</div>
          )}
        </div>
      </NavigationForm>
    </div>
  )
}

export default SizeAndPrimer
