import { useSelector } from 'react-redux'
import { Tag as TagType } from '../../../../types/Tag'
import Upsert from './Upsert'
import props from '../../../../redux/props'

type Props = {
  tag: TagType
  callback: (tag: TagType) => void
}

const Tag: React.FC<Props> = ({ tag, callback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  return (
    <Upsert tag={tag} callback={callback}>
      <div className="px-1 cursor-pointer hover:bg-gray-thirdary">
        {tag.name} ({t.tag.category[tag.category]})
      </div>
    </Upsert>
  )
}

export default Tag
