import twoImage from '../../../assets/image/home/enumeration/two-sample.png'
import two from '../../../assets/image/home/enumeration/two.png'
import projectIcon from '../../../assets/image/home/functionality/project.png'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Container from '../../UI/Container/Container'

const First = () => {
  const t = useSelector((state) => state[props.TRANSLATION])

  return (
    <div className="bg-blue-primary relative">
      <Container className="relative">
        <div className="flex flex-col md:flex-row-reverse py-10 gap-10 md:gap-5 items-center">
          <div className="shrink-0">
            <img src={two} alt="two" className="h-28 md:h-56" />
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex flex-col md:flex-row gap-5 md:gap-10 items-center md:items-start">
              <div className="flex items-center flex-col gap-3 shrink-0">
                <img className="h-16 md:h-28" src={projectIcon} alt="" />
              </div>
              <div className="flex flex-col gap-3">
                <div className="text-center md:text-left text-white font-bold text-lg mt-3">{t.home.functionality.step2.headline}</div>
                <div className="text-center md:text-left text-white">{t.home.functionality.step2.text}</div>
              </div>
            </div>
          </div>
          <div className="hidden md:flex  shrink-0 mr-10 w-64 items-center justify-center">
            <img src={twoImage} alt="two" className="h-[110%] -top-[5%] aspect-auto absolute left-0" />
          </div>
          <div className="bg-white absolute h-full w-full right-full top-0 z-0"></div>
        </div>
      </Container>
    </div>
  )
}

export default First
