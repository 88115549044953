import { Artist } from './Artist'
import { Project } from './Project'

export enum Type {
  ARTIST = 'ARTIST',
  PROJECT = 'PROJECT',
}

export type Recommendation = {
  type: Type
  artist?: Artist
  project?: Project
}

export default Recommendation
