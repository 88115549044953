import { IBaseTheme, IComponentPackageStyling } from './theme.d'

const theme: IBaseTheme = {
  font: {
    size: {
      xs: '14px',
      sm: '16px',
      md: '18px',
      lg: '22px',
      xlg: '32px',
    },
  },

  color: {
    font: {
      primary: 'black',
      secondary: 'white',
    },
    green: {
      primary: 'rgba(73, 192, 136, 1)',
      secondary: 'rgba(73, 192, 136, 0.6)',
      thirdary: 'rgba(73, 192, 136, 0.2)',
    },
    blue: {
      primary: 'rgb(14, 183, 233)',
      secondary: '#5fcbee',
      thirdary: '#c4eef9',
      superDark: 'rgb(0,64,102)',
    },
    gray: {
      primary: 'rgb(152, 175, 186)',
      secondary: 'rgb(215,222,226)',
      thirdary: 'rgb(245,245,245)',
    },
    magenta: {
      primary: 'rgb(240, 0, 255)',
      secondary: '#fc42ff',
      thirdary: '#fdbcff',
    },
    star: '#f37f18',
    black: '#0f0f0f',
    white: 'white',
    lightwhite: '#f5f5f58a',
    lightblack: '#565656',
    bg: 'white',
    red: '#E74C3C',
    primary: '#14919f',
    pink: 'rgb(240,0,255)',
  },
  leftMenu: {
    width: '487px',
  },
  bottomMenu: {
    height: '62px',
  },
  breakpoint: {
    sm: '768px',
    md: '992px',
    lg: '1320px',
    xxl: '1536px',
  },
  containerWidth: {
    xs: '100%',
    sm: '750px',
    md: '970px',
    lg: '1170px',
  },
  border: {
    radius: '20px',
  },
  shadow: '0px 5px 10px -1px rgba(0,0,0,0.20)',
  headerHeight: '100px',
  headerHeightMobile: '65px',
}

const adjustedComponentStyling: IComponentPackageStyling = {
  accordion: {
    accordion: ``,
    flex: ``,
    item: {
      item: ``,
      header: ``,
      text: ``,
      openicon: ``,
      body: ``,
    },
  },
  button: {
    button: ``,
    anchor: ``,
  },
  checkbox: {
    checkbox: ``,
    flex: ``,
    checkedicon: ``,
    label: ``,
    styledcheckbox: ``,
  },
  container: {
    container: ``,
  },
  draganddrop: {
    draganddrop: ``,
    title: ``,
    hint: ``,
    filelist: ``,
    filename: ``,
  },
  dropdown: {
    dropdown: `width: 100%;
    `,
    select: ``,
    option: ``,
    label: ``,
  },
  form: {
    form: ``,
  },
  headline: {
    headline: ``,
  },
  hero: {
    hero: ``,
  },
  input: {
    input: ``,
    wrapper: ``,
    label: ``,
  },
  link: {
    link: ``,
  },
  modal: {
    modal: ``,
    background: ``,
    core: ``,
    header: ``,
    title: `
    font-family: bold;
    `,
    close: ``,
    content: `
    `,
    footer: ``,
  },
  progressbar: {
    progressbar: ``,
    progress: ``,
    label: ``,
  },
  textarea: {
    textarea: ``,
    wrapper: ``,
    label: ``,
  },
  datepicker: {
    wrapper: ``,
    label: ``,
  },
}

const exportableTheme = { ...theme, components: adjustedComponentStyling }

export default exportableTheme
