import { v4 as uuidv4 } from 'uuid'
import tldExtensions from './tldExtensions'
import Location from './types/Location'
import { Tag } from './types/Tag'
import currencyFormatter from 'currency-formatter'
import prettyBytes from 'pretty-bytes'
import FileDownload from 'js-file-download'
import validator from 'validator'
import { Sizes, sizeValues } from './types/Size'
import _ from 'lodash'
import { ArtistListItem, Primer, Request } from './types/Request'
import { Offer } from './types/Offer'
import { AddOn, Project } from './types/Project'
import { Artist } from './types/Artist'

export const projectName = 'qsellart.think-internet.de'

export const backendDomain = process.env.NODE_ENV === 'production' ? 'https://backend.qsellart.de' : 'http://localhost:8100'

export const s3PublicStoragePrefix = 'https://s3.eu-central-1.amazonaws.com/qsellart.de-public-storage/'

export const globeRadius = 6371

export const getUUID = () => uuidv4()

export const tldList = tldExtensions

export const removeUnnessesary = (obj) => {
  const newObj = {}
  Object.keys(obj).forEach((key) => {
    //add if array is not empty

    if (Array.isArray(obj[key]) && obj[key].length > 0) newObj[key] = obj[key]
    if (typeof obj[key] === 'object' && !Array.isArray(obj[key]) && obj[key] !== null) newObj[key] = removeUnnessesary(obj[key])
    if (typeof obj[key] === 'string' && obj[key].length > 0) newObj[key] = obj[key]
    if (typeof obj[key] === 'number') newObj[key] = obj[key]
  })
  return newObj
}

export const getDateInputFormat = (date) => {
  const fill = (number: number) => {
    const numberAsString = `${number}`
    return numberAsString.length === 1 ? `0${number}` : number
  }

  const day = fill(date.getDate())
  const month = fill(date.getMonth() + 1)
  const year = date.getFullYear()
  return `${year}-${month}-${day}`
}

export const getQueryString = (string, requiredString) => {
  if (typeof string === 'string' && string.length > 0 && typeof requiredString === 'string') {
    string = string.substring(1)
    const split = string.split('=')
    return !string.includes('&') && split.length === 2 && split[0] === requiredString && split[1].length > 0 ? decodeURIComponent(split[1]) : false
  } else {
    return false
  }
}

export const includesAllProps = (propsArray, object) =>
  propsArray.reduce((acc, el) => {
    if (object?.[el] && acc) return true
    return false
  }, true)

export const frontendDomain = process.env.NODE_ENV === 'production' ? 'https://qsellart.de' : 'http://localhost:3000'

export const addMissingZero = (input) => {
  if (`${input}`.length === 1) {
    return `0${input}`
  }
  return input
}

export const formatFileSize = (sizeInBytes, locale = 'de') => {
  return prettyBytes(sizeInBytes, { locale })
}

export const formatPrice = (priceInEuro, hideDecimalIfPossible = false, symbol = '€', locale = 'de-DE') => {
  return currencyFormatter.format(priceInEuro, {
    locale,
    symbol,
    decimal: ',',
    thousand: '.',
    format: '%v %s',
    precision: hideDecimalIfPossible ? 0 : 2,
  })
}

export const formatPricePerSquareMeter = (priceInEuro: number, hideDecimalIfPossible: boolean = false, locale: string = 'de-DE') => {
  return formatPrice(priceInEuro, hideDecimalIfPossible, '€/m²', locale)
}

export const formatWorkshopPricePerAttendee = (priceInEuro: number, hideDecimalIfPossible: boolean = false, locale: string = 'de-DE') => {
  return formatPrice(priceInEuro, hideDecimalIfPossible, '€/p.P.', locale)
}

export const maxMobileWidth = 992

export const presignedUploadURLEndpoint = '/upload/get-presigned-url'

export const triggerClientSideDownload = (data, filename) => FileDownload(data, filename)

export const downloadFile = (name: string, blobData: Blob) => {
  var link = document.createElement('a')
  link.href = window.URL.createObjectURL(blobData)
  link.download = name
  link.click()
}

export const downloadFileFromURL = (name: string, url: string) => {
  var link = document.createElement('a')
  link.href = url
  link.download = name
  link.dispatchEvent(new MouseEvent('click'))
}

export const formatEUR = (amount) => {
  return currencyFormatter.format(amount, {
    locale: 'de-DE',
    symbol: '€',
    decimal: ',',
    thousand: '.',
    format: '%v %s',
  })
}

export const formatNumber = (value) => {
  return new Intl.NumberFormat('de-DE').format(value)
}

export const getLocationString = (addressComponents, order) => {
  if (!Array.isArray(addressComponents)) return 'kein Standort angegeben'
  const address = []
  addressComponents.forEach((component) => {
    component.types.forEach((type) => {
      if (order.includes(type)) address.push({ name: component.long_name, type })
    })
  })

  return address
    .sort((a, b) => (order.indexOf(a.type) < order.indexOf(b.type) ? -1 : 1))
    .map((i) => i.name)
    .join(', ')
}

export const validateMail = (mail) => {
  const mailRegex = new RegExp('^\\S+@\\S+\\.\\S+$')
  return mailRegex.test(mail)
}

export const cookieLiveTimeInMillis = 1000 * 60 * 60 * 24 * 365

export const prettyLocation = (location: Location) => {
  if (!location) return ''
  return location.name
}

export const getTagByEnum = (enumValue: string, availableTags: Tag[]) => {
  if (!Array.isArray(availableTags)) return null
  return availableTags.find((t) => t.enum === enumValue)
}

export const prettyTagsRaw = (tagENUMList: string[], availableTags: Tag[]): string[] => {
  if (!Array.isArray(tagENUMList) || tagENUMList.length === 0 || !Array.isArray(availableTags)) return []
  const tagNames = availableTags.filter((t) => tagENUMList.includes(t.enum)).map((t) => t.name)
  return tagNames
}

export const prettyTags = (tagENUMList: string[], availableTags: Tag[]) => {
  const tagNames = prettyTagsRaw(tagENUMList, availableTags)
  return tagNames.join(', ')
}

const fill = (number: Number) => {
  const numberAsString = `${number}`
  return numberAsString.length === 1 ? `0${numberAsString}` : numberAsString
}

export const getPrettyTime = (date: Date) => {
  const minute = fill(date.getMinutes())
  const hour = fill(date.getHours())
  return `${hour}:${minute}`
}
export const getPrettyDateTime = (date: Date) => {
  const day = fill(date.getDate())
  const month = fill(date.getMonth() + 1)
  const year = date.getFullYear()
  const minute = fill(date.getMinutes())
  const hour = fill(date.getHours())
  return `${day}.${month}.${year} ${hour}:${minute}`
}
export const getPrettyDate = (date: Date) => {
  const day = fill(date.getDate())
  const month = fill(date.getMonth() + 1)
  const year = date.getFullYear()
  return `${day}.${month}.${year}`
}

export const getDaysSince = (date: Date) => {
  const diff = new Date().getTime() - date.getTime()
  const days = Math.floor(diff / (1000 * 60 * 60 * 24))
  return days
}

export const calculateSquareMeters = (length: number, height: number) => {
  if (length === 0 || height === 0) return 0
  return Number.parseFloat((length * height).toFixed(2))
}

export const getProjectSizeEnum = (squareMeters: number): Sizes => {
  const item = Object.values(Sizes).find((sizeEnum: Sizes) => {
    const { min, max } = sizeValues[sizeEnum]
    if (max) {
      return min <= squareMeters && max >= squareMeters
    }
    return min <= squareMeters
  })

  return item
}

export const getPercentageOfPrice = (price: number, percentage: number) => {
  return (price / 100) * percentage
}

export const getTotalPriceOfOffer = (request: Request, offer: Offer, noInsurance = false): number => {
  const isWorkshop = isFinite(request.attendee?.amount) && request.attendee?.amount > 0
  if (isWorkshop) {
    let totalPrice = request.attendee.amount * offer.pricing.pricePerAttendee
    if (_.has(offer, 'pricing.additional.price') && isFinite(offer.pricing.additional.price)) {
      totalPrice += offer.pricing.additional.price
    }

    if (_.has(offer, 'pricing.discount') && isFinite(offer.pricing.discount)) {
      totalPrice -= offer.pricing.discount
    }
    return totalPrice
  } else {
    const squareMeters = calculateSquareMeters(request.sizeAndPrimer.size.length, request.sizeAndPrimer.size.height)

    const artPricePerSquareMeter = offer.pricing.artPerSquareMeter
    let totalPrice = artPricePerSquareMeter * squareMeters

    if (
      _.has(request, 'sizeAndPrimer.primerRequired') &&
      request.sizeAndPrimer.primerRequired === Primer.YES &&
      _.has(offer, 'pricing.primerPerSquareMeter') &&
      isFinite(offer.pricing.primerPerSquareMeter)
    ) {
      const primerPricePerSqureMeter = offer.pricing.primerPerSquareMeter
      totalPrice += primerPricePerSqureMeter * squareMeters
    }
    if (_.has(offer, 'pricing.additional.price') && isFinite(offer.pricing.additional.price)) {
      totalPrice += offer.pricing.additional.price
    }
    if (_.has(offer, 'pricing.discount') && isFinite(offer.pricing.discount)) {
      totalPrice -= offer.pricing.discount
    }
    if (offer.insurance?.accepted === true && !noInsurance) {
      totalPrice += getPercentageOfPrice(totalPrice, offer.insurance.pricePercentage)
    }

    return totalPrice
  }
}

export const getTotalPriceOfRequest = (artist: ArtistListItem, request: Request, noInsurance = false, noPrimer = false): number => {
  const isWorkshop = isFinite(request.attendee?.amount)
  if (isWorkshop) {
    return request.attendee.amount * artist.pricing.workshop.pricePerAttendee
  } else {
    const squareMeters = calculateSquareMeters(request.sizeAndPrimer.size.length, request.sizeAndPrimer.size.height)
    const projectSize = getProjectSizeEnum(squareMeters)
    const artPricePerSquareMeter = artist.pricing.art[projectSize]
    let totalPrice = artPricePerSquareMeter * squareMeters

    if (
      !noPrimer &&
      _.has(request, 'sizeAndPrimer.primerRequired') &&
      request.sizeAndPrimer.primerRequired === Primer.YES &&
      !artist.pricing.primer.disabled
    ) {
      const primerPricePerSqureMeter = artist.pricing.primer[projectSize]
      totalPrice += primerPricePerSqureMeter * squareMeters
    }
    if (!noInsurance && !!artist.insuranceDetail.enabled && (artist.insuranceAcceptedByBooker || !!request?.insurance?.accepted)) {
      totalPrice += getPercentageOfPrice(totalPrice, artist.insuranceDetail.pricePercentage)
    }

    return totalPrice
  }
}

export const getTotalPriceOfProject = (project: Project, noInsurance = false, noPrimer = false, noAdditionalPriceOrDiscount = false): number => {
  const isWorkshop = isFinite(project.attendee?.amount)
  if (isWorkshop) {
    let totalPrice = project.attendee.amount * project.pricing.pricePerAttendee
    if (!noAdditionalPriceOrDiscount) {
      if (_.has(project, 'pricing.additional.price') && isFinite(project.pricing.additional.price)) {
        totalPrice += project.pricing.additional.price
      }
      if (_.has(project, 'pricing.discount') && isFinite(project.pricing.discount)) {
        totalPrice -= project.pricing.discount
      }
    }
    return totalPrice
  } else {
    let totalPrice = project.pricing.artPerSquareMeter * project.sizeAndPrimer.sizeInSquareMeters

    if (!noPrimer && _.has(project, 'sizeAndPrimer.primerRequired') && project.sizeAndPrimer.primerRequired === Primer.YES) {
      const primerPricePerSqureMeter = project.pricing.primerPerSquareMeter
      totalPrice += primerPricePerSqureMeter * project.sizeAndPrimer.sizeInSquareMeters
    }
    if (!noAdditionalPriceOrDiscount) {
      if (_.has(project, 'pricing.additional.price') && isFinite(project.pricing.additional.price)) {
        totalPrice += project.pricing.additional.price
      }
      if (_.has(project, 'pricing.discount') && isFinite(project.pricing.discount)) {
        totalPrice -= project.pricing.discount
      }
    }
    if (!noInsurance && _.has(project, 'insurance.accepted') && !!project.insurance.accepted) {
      totalPrice += getPercentageOfPrice(totalPrice, project.insurance.pricePercentage)
    }

    return totalPrice
  }
}

export const singeConceptPriceOfAddOn = (project: Project, addOn: AddOn): number => {
  let totalPrice = 0
  if (!!addOn.conceptAdded) {
    totalPrice += project.pricing.singleConcept
  }
  return totalPrice
}

export const insurancePriceOfAddOn = (project: Project, addOn: AddOn): number => {
  let totalPrice = 0
  if (!!addOn.insuranceAdded && _.has(project, 'insurance.pricePercentage') && isFinite(project.insurance.pricePercentage)) {
    totalPrice += getPercentageOfPrice(getTotalPriceOfProject(project, true, true, true), project.insurance.pricePercentage)
  }
  return totalPrice
}

export const primerPriceOfAddOn = (project: Project, addOn: AddOn): number => {
  let totalPrice = 0
  if (!!addOn.primerAdded && _.has(project, 'pricing.primerPerSquareMeter')) {
    totalPrice += project.pricing.primerPerSquareMeter * project.sizeAndPrimer.sizeInSquareMeters
  }
  return totalPrice
}

export const additionalSquareMeterPriceOfAddOn = (project: Project, addOn: AddOn): number => {
  let totalPrice = 0
  if (!!addOn.additionalSquareMeter && isFinite(addOn.additionalSquareMeter)) {
    totalPrice += project.pricing.artPerSquareMeter * addOn.additionalSquareMeter
    // add primer if primer is required in the project
    if (project.sizeAndPrimer.primerRequired === Primer.YES) {
      totalPrice += project.pricing.primerPerSquareMeter * addOn.additionalSquareMeter
    }
  }
  return totalPrice
}

export const additionalOtherCostsPriceOfAddOn = (project: Project, addOn: AddOn): number => {
  let totalPrice = 0
  if (!!addOn.additionalOtherCosts && isFinite(addOn.additionalOtherCosts)) {
    totalPrice += addOn.additionalOtherCosts
  }
  return totalPrice
}

export const additionalAttenteesPriceOfAddOn = (project: Project, addOn: AddOn): number => {
  let totalPrice = 0
  if (!!addOn.additionalAttentees && isFinite(addOn.additionalAttentees)) {
    totalPrice += addOn.additionalAttentees * project.pricing.pricePerAttendee
  }
  return totalPrice
}

export const getTotalPriceOfProjectAddOn = (project: Project, addOn: AddOn): number => {
  let totalPrice = 0
  totalPrice += singeConceptPriceOfAddOn(project, addOn)
  totalPrice += insurancePriceOfAddOn(project, addOn)
  totalPrice += primerPriceOfAddOn(project, addOn)
  totalPrice += additionalSquareMeterPriceOfAddOn(project, addOn)
  totalPrice += additionalAttenteesPriceOfAddOn(project, addOn)
  totalPrice += additionalOtherCostsPriceOfAddOn(project, addOn)
  return totalPrice
}

export const maxAgeOfOffersInDays = 14

export const minArtistStoryLength = 150
export const maxArtistStoryLength = 450

export const maxPublicRatingTextLength = 150

export const isValidPhoneNumber = (phone: string | number) => validator.isMobilePhone(`${phone}`, 'de-DE', { strictMode: true })
export const isValidIBAN = (iban: string | number) => validator.isIBAN(`${iban}`, { whitelist: ['DE'] })

export const getCurrentMonthName = () =>
  new Date().toLocaleString('default', {
    month: 'long',
  })

export const calculateDistanceInKM = (location1: Location, location2: Location, radiusInKM?: number) => {
  let [lat1, lon1] = location1.coordinates
  let [lat2, lon2] = location2.coordinates

  const toRad = (value: number) => {
    return (value * Math.PI) / 180
  }

  var R = globeRadius // km
  var dLat = toRad(lat2 - lat1)
  var dLon = toRad(lon2 - lon1)
  lat1 = toRad(lat1)
  lat2 = toRad(lat2)

  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  var d = R * c

  if (isFinite(radiusInKM) && radiusInKM > 0) {
    return d <= radiusInKM
  }
  return d
}

export const isWithinArtistRadius = (artist: Artist, location: Location) => {
  if (!artist || !artist.locationDetail.location || !!artist.locationDetail.nationwide) return true
  return calculateDistanceInKM(location, artist.locationDetail.location, artist.locationDetail.radius)
}
