import { useState } from 'react'
import Form from '../Form/Form'
import NewInput from '../NewInput/NewInput'
import NewButton from '../NewButton/NewButton'
import NewModal from '../NewModal/NewModal'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import GetIcon from '../GetIcon/GetIcon'

type Props = {
  input?: string
  inputOnChange?: (input: string) => void
  children: React.ReactNode
  submitCallback: () => void
  small?: boolean
  className?: string
  toggleListView?: () => void
  isListView?: boolean
}

const SearchFilterMask: React.FC<Props> = ({
  input,
  inputOnChange,
  children,
  submitCallback,
  small = false,
  toggleListView,
  isListView,
  className = '',
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const t = useSelector((s) => s[props.TRANSLATION])

  const toggle = () => setOpen(!open)

  const apply = () => {
    setOpen(false)
    submitCallback()
  }

  return (
    <div className={`${className}`}>
      <div className="flex flex-row items-center w-full">
        <Form onSubmit={submitCallback} className="flex gap-2 rounded-full w-full items-center p-2 bg-gray-thirdary">
          <NewInput
            className="md:hidden border-none !h-7"
            contentClassName="!bg-gray-thirdary"
            value={input}
            onChange={inputOnChange}
            placeholder={t.UI.search.placeholderMobile}
          />
          <NewInput
            className="hidden md:block border-none !h-7"
            contentClassName="!bg-gray-thirdary"
            value={input}
            onChange={inputOnChange}
            placeholder={t.UI.search.placeholder}
          />
          <NewButton className="btn-secondary h-8" type="submit" text={t.UI.search.cta} />
          <NewButton className="btn-secondary-outline h-8 gap-2 group" onClick={toggle} text={t.UI.search.toggleFilter}>
            <GetIcon name={'filter'} className="h-4 fill-magenta-primary group-hover:fill-white" />
          </NewButton>
          {!!toggleListView && (
            <div className="h-8 hidden relative aspect-square border border-magenta-primary bg-white hover:bg-magenta-primary rounded-full 2xl:flex items-center justify-center group cursor-pointer transition-all">
              <GetIcon
                name={'filter-list'}
                className={isListView ? `w-[17px] fill-magenta-primary block group-hover:hidden` : `w-[17px] fill-white hidden group-hover:block`}
                onClick={toggleListView}
              />
              <GetIcon
                name={'filter-grid'}
                className={!isListView ? `w-[20px] fill-magenta-primary block group-hover:hidden` : `w-[20px] fill-white hidden group-hover:block`}
                onClick={toggleListView}
              />
            </div>
          )}
        </Form>
      </div>
      <NewModal show={open} onClose={() => setOpen(false)} noForm noClose glass large={!small}>
        {children}
        <NewButton className="btn-secondary mx-auto w-fit mt-10 mb-1" onClick={apply} text={t.UI.search.applyFilter} />
      </NewModal>
    </div>
  )
}

export default SearchFilterMask
