import { useCallback, useEffect, useState } from 'react'
import SearchFilterMask from '../../../UI/SearchFilterMask/SearchFilterMask'
import props from '../../../../redux/props'
import { useSelector } from 'react-redux'
import { Category, Tag } from '../../../../types/Tag'
import SelectableTagList from '../../../UI/SelectableTagList/SelectableTagList'
import { Artist } from '../../../../types/Artist'
import Routes from '../../../../redux/routes'
import { functional } from '@think-internet/zeus-frontend-package'
import Title from '../../../UI/Graybox/Title'
import OrderSelection from '../../../UI/SearchFilterMask/OrderSelection'
import _ from 'lodash'
import history from 'history/browser'
import SmallContainer from '../../../UI/Container/SmallContainer'
import ProjectSizeSelection, { SizeFilterSelection } from '../../../UI/SearchFilterMask/ProjectSizeSelection'

type Props = {
  artists: Artist[]
  setArtists: (artists: Artist[]) => void
  init: FilterProps
  toggleListView: () => void
  isListView: boolean
}

export type FilterProps = {
  input?: string
  skillTagEnumList?: string[]
  rating: {
    order?: 1 | -1
  }
  size: SizeFilterSelection
}

const Filter: React.FC<Props> = ({ artists, setArtists, init, toggleListView, isListView }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const allTags: Tag[] = useSelector((state) => state[props.TAG])
  const [filter, setFilter] = useState<FilterProps>(_.merge({}, { input: '', rating: {}, size: {} }, init || {}))
  const searchArtists = functional.use(Routes.SEARCH_ARTISTS)

  const search = useCallback(async () => {
    const artists = await searchArtists({ filter })
    if (Array.isArray(artists)) {
      setArtists(artists)
      history.replace('/artist?query=' + JSON.stringify(filter))
    }
  }, [setArtists, searchArtists, filter])

  useEffect(() => {
    const fetchArtists = async () => {
      search()
    }
    if (!artists) {
      fetchArtists()
    }
  })

  const set = (prop: keyof FilterProps) => (value: any) => setFilter({ ...filter, [prop]: value })
  const setOrder = (prop: keyof FilterProps) => (value: 1 | -1) => {
    const currentProp = filter[prop] as { order: 1 | -1 }
    if (value === currentProp.order) {
      setFilter({ ...filter, [prop]: { ...currentProp, order: undefined } })
    } else {
      setFilter({ ...filter, [prop]: { ...currentProp, order: value } })
    }
  }

  return (
    <SmallContainer innerClassName="!px-0 sm:!px-3">
      <SearchFilterMask
        input={filter.input}
        inputOnChange={set('input')}
        submitCallback={search}
        toggleListView={toggleListView}
        isListView={isListView}
        small
      >
        <div className="flex flex-col gap-10 md:gap-14 pt-2">
          <div className="w-full flex flex-col">
            <Title title={t.UI.search.filter.skillTags} className="text-white text-lg md:ml-3" />
            <SelectableTagList
              maxVisibleRows={4}
              className="mt-2"
              selectedTagENUMList={filter.skillTagEnumList}
              tags={allTags.filter((tag: Tag) => tag.category !== Category.EQUIPMENT && tag.category !== Category.SURFACE)}
              onChange={set('skillTagEnumList')}
            />
          </div>
          <ProjectSizeSelection useSmallName title={t.artist.search.filter.price.title} value={filter.size} onChange={set('size')} />
          <OrderSelection title={t.artist.search.filter.rating.title} value={filter.rating.order} onChange={setOrder('rating')} />
        </div>
      </SearchFilterMask>
    </SmallContainer>
  )
}

export default Filter
