import { useSelector } from 'react-redux'
import props from '../../../../../../redux/props'
import { Project } from '../../../../../../types/Project'
import Toggle from '../../../../../UI/NewToggle/NewToggle'
import useProjectVisibility from '../../../Project/View/ToggleVisibility/useProjectVisibility'

type Props = {
  project: Project
}

const Visibility: React.FC<Props> = ({ project }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [isVisible, setIsVisible] = useProjectVisibility(project)

  const toggle = () => {
    setIsVisible(!isVisible)
  }
  return (
    <div className="flex items-center gap-2">
      <Toggle checked={isVisible} onChange={toggle} />
      {isVisible && <div className="text-sm text-light-black">{t.artist.project.toggleVisibility.textIsActiveSmall}</div>}
      {!isVisible && <div className="text-sm text-light-black">{t.artist.project.toggleVisibility.textIsInactiveSmall}</div>}
    </div>
  )
}

export default Visibility
