type Props = {
  checked: boolean
  onChange?: (checked: boolean) => void
  disabled?: boolean
  className?: string
}

const Toggle: React.FC<Props> = ({ checked = false, onChange, disabled = false, className = '' }) => {
  // const change = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   onChange(e.target.checked)
  // }

  return (
    <label className={`inline-flex items-center ${!disabled && 'cursor-pointer'}`}>
      <input type="checkbox" checked={checked} disabled={disabled} onChange={(e) => onChange(e.target.checked)} className="sr-only peer" />
      <div
        className={`
        peer
        overflow-hidden
      shrink-0
      grow-0
      relative
      w-[43px]
      h-[22px]
      border
      border-white
      bg-white
      peer-checked:bg-toggle
      peer-checked:from-magenta-primary
      peer-checked:from-0%
      peer-checked:via-purple
      peer-checked:via-45%
      peer-checked:to-blue-primary
      peer-checked:to-100%
      shadow-toggle-inner
      peer-checked:shadow-none
      peer-focus:outline-none
      rounded-full
      dark:bg-gray-700
      peer-checked:after:border-white
      after:content-['']
      after:absolute 
      after:top-1/2 
      after:-translate-y-1/2 
      after:start-[1px] 
      peer-checked:after:start-[22px]
      after:bg-gray-secondary 
      after:shadow-toggle-circle 
      after:bg-gradient-to-tr 
    after:from-white
    after:to-gray-primary-second
    after:border-white
      after:border
      after:rounded-full
      after:h-[18px]
      after:w-[18px]
      after:grow-0
      after:shrink-0
      after:aspect-square
      after:transition-all
      peer-checked:after:transition-all
      dark:border-gray
      peer-checked:bg-blue`}
      ></div>
    </label>
  )

  // return (
  //   <input
  //     type="checkbox"
  //     disabled={disabled}
  //     className={`toggle checked:bg-blue-primary border-blue-primary toggle-sm ${className}`}
  //     onChange={change}
  //     checked={checked}
  //   />
  // )
}

export default Toggle
