import styled from 'styled-components'

export const StyledHeader = styled.div<{ open?: boolean }>`
  width: 100%;
  z-index: 11;
  top: 0;
  height: ${({ theme, open }) => (open ? '100%' : theme.headerHeight)};
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.white};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray.thirdary};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: ${({ theme, open }) => (open ? '100%' : theme.headerHeightMobile)};
  }
`
