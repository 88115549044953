import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { getDaysSince } from '../../../utility'

type Props = {
  date: Date
  className?: string
}

const DaysSince: React.FC<Props> = ({ date, className = '' }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const days = getDaysSince(date)

  const getText = () => {
    if (days === 0) return t.UI.daysSince.today
    if (days === 1) return t.UI.daysSince.yesterday
    return `${t.UI.daysSince.prefix}${days}${t.UI.daysSince.suffix}`
  }

  return <div className={`text-xs text-gray-primary ${className}`}>{getText()}</div>
}

export default DaysSince
