export enum Type {
  NEW_OFFER = 'NEW_OFFER',
  NEW_CHAT_MESSAGE = 'NEW_CHAT_MESSAGE',
  NEW_ARTIST_REQUEST = 'NEW_ARTIST_REQUEST',
  NEW_BOOKER_INVOICE = 'NEW_BOOKER_INVOICE',
  ARTIST_COMPLETE_PROFILE = 'ARTIST_COMPLETE_PROFILE',
  PROJECT_ADD_ON = 'PROJECT_ADD_ON',
  PROJECT_COMPLETION = 'PROJECT_COMPLETION',
  PROJECT_RATING = 'PROJECT_RATING',
}

export enum TopicArtist {
  DIRECT_REQUEST = 'DIRECT_REQUEST',
  CHAT_MESSAGE = 'CHAT_MESSAGE',
  PROJECT_CHANGE = 'PROJECT_CHANGE',
  REMINDER = 'REMINDER',
  NEWSLETTER = 'NEWSLETTER',
}

export enum TopicBooker {
  CHAT_MESSAGE = 'CHAT_MESSAGE',
  PROJECT_CHANGE = 'PROJECT_CHANGE',
  REMINDER = 'REMINDER',
  NEWSLETTER = 'NEWSLETTER',
}

export type Payload = {
  [key: string]: any
}

type Notification = {
  uuid: string
  receiverAccountLinkUUID: string
  type: Type
  payload: Payload
  read?: boolean
}

export type Setting = {
  accountLinkUUID: string
  topic: TopicArtist | TopicBooker
  enabled: boolean
}

export default Notification
