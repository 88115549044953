import { Line, Point } from './Steps.Styled'

type Props = {
  total: number
  current: number
}

const Steps: React.FC<Props> = ({ total, current }) => {
  if (total === 1) return null
  return (
    <div className="flex justify-center py-3">
      <div className="flex align-middle">
        {[...Array(total)].map((e, i) => (
          <div key={i} className="flex items-center">
            <Point active={i <= current} />
            {i + 1 < total && <Line active={i <= current} />}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Steps
