import { NavigationForm, PipelineState } from '../../types'
import UIForm from '../../../../UI/Form/Form'
import Controls from './Controls'
import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import useGoNext from '../useGoNext'

const Form = <D,>({
  pipelineStateProp,
  getData,
  finalStepSubmit,
  children,
  hideControls = false,
  componentMapping = null,
  prefilPipelineData = null,
}: NavigationForm<D>) => {
  const state: PipelineState<D> = useSelector((s) => s[props.PIPELINE])
  const goNext = useGoNext(pipelineStateProp, getData, finalStepSubmit, componentMapping, prefilPipelineData)

  return (
    <UIForm onSubmit={goNext}>
      {children}
      {!hideControls && <Controls<D> state={state} finalStepSubmit={finalStepSubmit} />}
    </UIForm>
  )
}

export default Form
