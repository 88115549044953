import { socket } from '@think-internet/zeus-frontend-package'
import sockets from '../../redux/sockets'
import Notification, { Payload, Type } from '../../types/Notification'
import { useSelector } from 'react-redux'
import props from '../../redux/props'

type Data = {
  uuid?: string
  type?: Type
  payload?: Payload
}

type Props = () => (data: Data) => void

const useReadNotification: Props = () => {
  const notifications = useSelector((s) => s[props.NOTIFICATION_LIST])
  const sendReadtoSocket = socket.use(sockets.NOTIFICATION)

  const read = (data: Data) => {
    const matchingNotifications = notifications.filter((n: Notification) => {
      if (!!data.uuid) {
        return n.uuid === data.uuid
      }
      if (!!data.type && !!data.payload) {
        const typeMatch = n.type === data.type
        const keys = Object.keys(data.payload)
        const payloadMatch = keys.every((key) => n.payload[key] === data.payload[key])
        return typeMatch && payloadMatch
      }
      return false
    })

    // emit read to socket if matching notification has been found
    for (const notification of matchingNotifications) {
      sendReadtoSocket({ uuid: notification.uuid })
    }
  }

  return read
}

export default useReadNotification
