import { useSelector } from 'react-redux'
import GrayBox from '../../../../UI/Graybox/Graybox'
import props from '../../../../../redux/props'
import useGetStatistics from '../../../../hooks/useGetStatistics'
import Loading from '../../../../UI/Loading/Loading'
import { Statistics as StatisticsType } from '../../../../../types/Statistics'
import { getCurrentMonthName } from '../../../../../utility'

const Statistics: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const statistics = useGetStatistics()

  const Item: React.FC<{ prop: keyof StatisticsType }> = ({ prop }) => {
    const getName = () => {
      if (prop === 'profileViewsOfMonth') {
        return t.artist.dashboard.statistics[prop](getCurrentMonthName())
      }
      return t.artist.dashboard.statistics[prop]
    }

    return (
      <div className="w-1/3 flex flex-col items-center gap-3">
        <div className="text-center whitespace-pre-wrap text-xs md:text-sm leading-3">{getName()}</div>
        <div className="text-white bg-gray-secondary rounded-full h-20 aspect-square flex items-center justify-center text-[40px]">
          {statistics[prop]}
        </div>
      </div>
    )
  }

  return (
    <GrayBox className="py-3 md:w-3/5" title={t.artist.dashboard.statistics.title}>
      <Loading loading={statistics} />
      {!!statistics && (
        <div className="flex justify-around mt-3 gap-5">
          <Item prop="favorites" />
          <Item prop="profileViewsOfMonth" />
          <Item prop="profileViewsTotal" />
        </div>
      )}
    </GrayBox>
  )
}

export default Statistics
