type Props = {
  title?: string
  className?: string
  noTitlePadding?: boolean
}

const Title: React.FC<Props> = ({ title, noTitlePadding, className = '' }) => {
  if (!title) return null
  return <div className={`font-bold ${!noTitlePadding && 'mb-5'} ${className}`}>{title}</div>
}

export default Title
