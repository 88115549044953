import { StyledLink } from './Link.Styled'
import { useNavigate } from 'react-router-dom'

type Props = {
  href?: string
  text: string
  size?: string
  color?: string
  margin?: string
  className?: string
  onClick?: (e: any) => void
  newTab?: boolean
}

const Link: React.FC<Props> = ({ href, text, size, color, margin, className, onClick, newTab = false }) => {
  const navigate = useNavigate()

  const trigger = (e) => {
    e.preventDefault()
    if (newTab) {
      window.open(href, '_blank').focus()
    } else {
      navigate(href)
    }
  }

  return (
    <StyledLink href={href} onClick={onClick || trigger} size={size} color={color} margin={margin} className={className}>
      {text}
    </StyledLink>
  )
}

export default Link
