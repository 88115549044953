import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import SmallContainer from '../../../UI/Container/SmallContainer'
import NewButton from '../../../UI/NewButton/NewButton'
import SectionHeader from '../../../UI/Pipeline/SectionHeader'
import { BookerBody } from '../../Booker/types'
import { ArtistBody } from '../../Artist/types'
import { PipelineState, TYPES } from '../../../UI/Pipeline/types'

const Success: React.FC<{ state: PipelineState<BookerBody | ArtistBody> }> = ({ state }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const isBooker = state.type === TYPES.REGISTER_BOOKER

  return (
    <SmallContainer>
      {isBooker ? (
        <SectionHeader
          headline={t.register.success.booker.headline}
          description={t.register.success.booker.description}
          className={'text-center'}
          classNameDescription={'text-center'}
        />
      ) : (
        <SectionHeader
          headline={t.register.success.artist.headline}
          description={t.register.success.artist.description}
          className={'text-center'}
          classNameDescription={'text-center'}
        />
      )}
      <NewButton text={t.register.success.loginCTA} href="/login" className="mt-4 mx-auto w-fit" />
    </SmallContainer>
  )
}

export default Success
