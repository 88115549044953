import styled from 'styled-components'

interface ICoreContent {
  hideHeader?: boolean
  hideFooter?: boolean
  isChat?: boolean
}

export const CoreContent = styled.div<ICoreContent>`
  position: relative;
  background-color: ${({ theme }) => theme.color.bg};
  min-height: calc(100vh - ${({ hideHeader, theme }) => (hideHeader ? '0px' : theme.headerHeight)});
  margin-top: ${({ hideHeader, theme }) => (hideHeader ? '0px' : theme.headerHeight)};
  ${({ hideFooter, isChat }) => (hideFooter ? (isChat ? '' : 'margin-bottom: 200px') : '')};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    margin-top: ${({ hideHeader, theme }) => (hideHeader ? '0px' : theme.headerHeightMobile)};
  }
`
