import { useSelector } from 'react-redux'
import icon from '../../../../../assets/image/chat/chat/icon.png'
import otherparty from '../../../../../assets/image/chat/chat/other-party.png'
import mymessage from '../../../../../assets/image/chat/chat/my-message.png'
import compose from '../../../../../assets/image/chat/chat/compose.png'

import props from '../../../../../redux/props'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import { PurposeFlag } from '../../../../../types/Auth'

const Placeholder = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const amount = [...new Array(4)]
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])

  const isArtist = accountLink.purposeFlag === PurposeFlag.ARTIST

  return (
    <div className="px-3">
      <div className="border-l border-r border-gray-primary px-3 relative">
        <div className="hidden md:flex flex-col gap-2">
          {amount.map((x, i) =>
            i % 2 === 0 ? (
              <div className="flex w-full" key={i}>
                <img src={otherparty} alt="" className="h-24" />
              </div>
            ) : (
              <div className="flex justify-end" key={i}>
                <img src={mymessage} alt="" className="h-24" />
              </div>
            ),
          )}
        </div>
        <div className="absolute top-0 left-0 px-8 w-full h-full flex items-center pt-20  flex-col">
          <img src={icon} alt="" className="h-10" />
          <div className="text-gray-primary font-bold text-lg mt-2 text-center">{t.chat.messages.placeholder}</div>
          {!!isArtist && <div className="text-gray-primary text-center text-sm">{t.chat.messages.artistPlaceholderHint}</div>}
          {!isArtist && <div className="text-gray-primary text-center text-sm">{t.chat.messages.bookerPlaceholderHint}</div>}
        </div>
      </div>
      <div className="mt-10 hidden md:flex">
        <img src={compose} alt="" />
      </div>
    </div>
  )
}

export default Placeholder
