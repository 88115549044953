import { useSelector } from 'react-redux'
import OfferButton from './OfferButton'
import AcceptButton from './AcceptButton'
import DeclineButton from './DeclineButton'
import { useState } from 'react'
import props from '../../../../../redux/props'
import { Request } from '../../../../../types/Request'
import ConfirmPublicRequestBudget from './ConfirmPublicRequestBudget'
import { Artist, VerificationState } from '../../../../../types/Artist'

type Props = {
  request: Request
  artist: Artist
}

const MakeAnOffer: React.FC<Props> = ({ request, artist }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [offerOpen, setOfferOpen] = useState<boolean>(false)

  const ValidUntil = (
    <div className="text-xs text-white bg-gray-secondary rounded-md px-3 py-1 text-center w-fit mx-auto">
      {!!request.artistList ? t.request.view.makeAnOffer.validUntil.prefixReverse : t.request.view.makeAnOffer.validUntil.prefix}
      {request.remainingDays}
      {t.request.view.makeAnOffer.validUntil.suffix}
    </div>
  )

  if (artist && artist.verificationState === VerificationState.UNVERIFIED) return <></>
  return (
    <div className="flex flex-col gap-3 mt-5">
      <div className="flex flex-col gap-3 w-60 mx-auto">
        <AcceptButton request={request} artist={artist} />
        <ConfirmPublicRequestBudget request={request} artist={artist} />
        <OfferButton request={request} artist={artist} open={offerOpen} setOpen={setOfferOpen} />
        <DeclineButton request={request} artist={artist} triggerReverseOpen={() => setOfferOpen(true)} />
      </div>
      {ValidUntil}
    </div>
  )
}

export default MakeAnOffer
