import { useSelector } from 'react-redux'
import DataSection from '../../../Components/DataSection/DataSection'
import { DataSectionComponent } from '../../types'
import props from '../../../../../redux/props'
import { useState } from 'react'
import toast, { TYPE } from '../../../../../toast'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../../redux/routes'
import { BankAccountStatus, InvoiceDetail } from '../../../../../types/Artist'
import NewInput from '../../../../UI/NewInput/NewInput'
import IbanInput from '../../../../UI/NewInput/IbanInput'
import { isValidIBAN } from '../../../../../utility'
import Property from '../../../../UI/PropertyList/Property'

type BankObject = {
  bankname: string
  iban: string
}

const BankAccount: DataSectionComponent = ({ artist, onChangeCallback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const addBankAccount = functional.use(Routes.ADD_BANK_ACCOUNT_TO_ARTIST)
  const [data, setData] = useState<BankObject>({
    bankname: '',
    iban: 'DE',
  })

  const Default = (
    <div>
      <div className="text-sm text-black-secondary my-2">{t.artist.settings.bankAccount.hint[artist.invoiceDetail.stripe.bankAccountStatus]}</div>
      {!!artist.invoiceDetail.bankname && <Property property={{ name: t.generic.bankname, value: artist.invoiceDetail.bankname }} />}
      {!!artist.invoiceDetail.ibanLast4Digits && (
        <Property property={{ name: t.generic.iban, value: t.generic.ibanLast4DigitsPrefix + artist.invoiceDetail.ibanLast4Digits }} />
      )}
    </div>
  )

  const save = async () => {
    if (!!data && !!data.bankname && !!data.iban) {
      if (isValidIBAN(data.iban)) {
        const invoiceDetail: InvoiceDetail = await addBankAccount(data)
        if (!invoiceDetail) {
          toast(TYPE.ERROR, t.artist.settings.bankAccount.edit.errorGeneric)
        } else {
          onChangeCallback(invoiceDetail)
          setData(null)
          return true
        }
      } else {
        toast(TYPE.ERROR, t.artist.settings.bankAccount.edit.errorIBAN)
      }
    } else {
      toast(TYPE.ERROR, t.artist.settings.bankAccount.edit.errorFill)
    }
    return false
  }

  const set = (prop: keyof BankObject) => (value: string) => setData({ ...data, [prop]: value })

  return (
    <DataSection
      className="md:w-1/2"
      title={t.artist.settings.bankAccount.title}
      defaultView={Default}
      enableCancel
      saveCallback={save}
      saveButtonText={t.generic.send}
      isHighlighted={artist.invoiceDetail.stripe.bankAccountStatus === BankAccountStatus.MISSING}
    >
      <div className="flex gap-3 flex-col">
        <NewInput required value={data?.bankname} onChange={set('bankname')} placeholder={t.generic.bankname} />
        <IbanInput required value={data?.iban} onChange={set('iban')} placeholder={t.generic.iban} />
      </div>
    </DataSection>
  )
}

export default BankAccount
