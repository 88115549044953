import { useSelector } from 'react-redux'
import { Project } from '../../../../../../types/Project'
import props from '../../../../../../redux/props'
import GrayBox from '../../../../../UI/Graybox/Graybox'
import Headline from '../../../../../UI/Headline/Headline'
import Title from '../../../../../UI/Graybox/Title'
import Toggle from '../../../../../UI/NewToggle/NewToggle'
import useProjectVisibility from './useProjectVisibility'

type Props = {
  project: Project
}

const ToggleVisibility: React.FC<Props> = ({ project }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [isVisible, setIsVisible] = useProjectVisibility(project)

  const toggle = () => {
    setIsVisible(!isVisible)
  }

  return (
    <div className="mt-5">
      <Headline className="mb-2 text-md">{t.artist.project.toggleVisibility.headline}</Headline>
      <GrayBox>
        <Title title={t.artist.project.toggleVisibility.title} />
        <div className="flex items-center gap-2 mt-2">
          <Toggle checked={isVisible} onChange={toggle} />
          {isVisible && <div className="text-sm">{t.artist.project.toggleVisibility.textIsActive}</div>}
          {!isVisible && <div className="text-sm">{t.artist.project.toggleVisibility.textIsInactive}</div>}
        </div>
      </GrayBox>
    </div>
  )
}

export default ToggleVisibility
