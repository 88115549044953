import { useParams } from 'react-router-dom'
import useGetArtist from '../../hooks/useGetArtist'
import useTrackArtistProfileView from '../../hooks/useTrackArtistProfileView'
import Loading from '../../UI/Loading/Loading'
import Container from '../../UI/Container/Container'
import Core from './Core'

const ViewByUUID: React.FC<{}> = () => {
  const { uuid } = useParams()
  const [artist] = useGetArtist(uuid)
  useTrackArtistProfileView(artist?.uuid)

  return (
    <Container className="py-3">
      <Loading loading={artist} />
      {artist && <Core artist={artist} />}
    </Container>
  )
}

export default ViewByUUID
