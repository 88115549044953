import { useSelector } from 'react-redux'
import { StyledTextarea } from './Textarea.Styled'
import props from '../../../redux/props'

type Props = Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'> & {
  onChange?: (value: string) => void
  value: string
  placeholder?: string
  margin?: string
  disabled?: boolean
  className?: string
  rows?: number
  required?: boolean
  id?: string
  minCharacters?: number
  maxCharacters?: number
  useRef?: any
}

const Textarea: React.FC<Props> = ({
  onChange,
  value,
  placeholder,
  margin,
  disabled,
  className,
  rows = 3,
  required = false,
  id,
  minCharacters,
  maxCharacters,
  useRef,
}) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const getRemainingCharacters = () => {
    if (!value) return minCharacters
    const remaining = minCharacters - value?.length
    return remaining > 0 ? remaining : 0
  }

  return (
    <div className="w-full">
      <StyledTextarea
        id={id}
        ref={useRef}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        placeholder={placeholder}
        margin={margin}
        required={required}
        className={`rounded-sm ${className}`}
        rows={rows}
        minLength={minCharacters}
        maxLength={maxCharacters}
      />
      {isFinite(minCharacters) && (
        <div className="flex flex-row gap-3">
          <div className="text-xs text-blue-primary">
            {t.UI.textarea.counter.prefix}
            {getRemainingCharacters()}
          </div>
          {isFinite(maxCharacters) && (
            <div className="text-xs text-blue-primary">
              {t.UI.textarea.counter.max}
              {maxCharacters}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Textarea
