import styled from 'styled-components'

export const StyledButton = styled.button<{
  width?: string
  margin?: string
  borderRadius?: string
  display?: string
  small?: boolean
  backgroundColor?: string
  sub?: boolean
  inverted?: boolean
}>`
  background-color: ${({ theme, backgroundColor, sub, disabled, inverted }) =>
    backgroundColor || sub ? theme.color.white : disabled ? theme.color.gray.primary : inverted ? theme.color.white : theme.color.magenta.primary};
  ${({ width }) => width && `max-width: ${width};`};
  padding: 5px 20px;
  border-radius: ${({ theme, borderRadius }) => borderRadius || '30px'};
  margin: ${({ margin }) => margin || '0px'};
  border: 1px solid
    ${({ theme, backgroundColor, sub, disabled }) =>
      backgroundColor || sub ? theme.color.magenta.primary : disabled ? theme.color.gray.primary : theme.color.magenta.primary};
  /* text-transform: uppercase; */

  &:hover {
    border: 1px solid ${({ theme, inverted }) => (inverted ? theme.color.magenta.primary : theme.color.font.secondary)};
  }

  color: ${({ theme, color, sub, inverted }) =>
    color || sub ? theme.color.magenta.primary : inverted ? theme.color.magenta.primary : theme.color.font.secondary};

  display: ${({ display }) => display || 'inline-block'};
  text-align: center;
  font-size: ${({ theme, small }) => (small ? theme.font.size.xs : theme.font.size.sm)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.xs};
  }
`

export const StyledButtonAnchor = styled.a<{
  width?: string
  margin?: string
  borderRadius?: string
  display?: string
  small?: boolean
  backgroundColor?: string
  sub?: boolean
  inverted?: boolean
}>`
  color: ${({ theme, color, inverted }) => (color || inverted ? theme.color.font.primary : theme.color.font.secondary)};

  background-color: ${({ theme, backgroundColor, sub }) => (backgroundColor || sub ? theme.color.white : theme.color.magenta.primary)};
  ${({ width }) => width && `max-width: ${width};`};
  padding: 5px 20px;
  border-radius: ${({ theme, borderRadius }) => borderRadius || '30px'};
  margin: ${({ margin }) => margin || '0px'};
  border: 1px solid ${({ theme, backgroundColor, sub }) => (backgroundColor || sub ? theme.color.magenta.primary : theme.color.magenta.primary)};

  &:hover {
    border: 1px solid ${({ theme }) => theme.color.font.secondary};
  }

  color: ${({ theme, color, sub }) => (color || sub ? theme.color.magenta.primary : theme.color.font.secondary)};

  display: ${({ display }) => display || 'inline-block'};
  text-align: center;
  font-size: ${({ theme, small }) => (small ? theme.font.size.xs : theme.font.size.sm)};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.xs};
  }
`
