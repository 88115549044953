import Layout from '../UI/Layout/Layout'
import Banner from './Banner/Banner'
import Features from './Features/Features'
import Functionality from './Functionality/Functionality'
import Mission from './Mission/Mission'
import Vision from './Vision/Vision'
import Ratings from './Ratings/Ratings'
import QrBanner from './QrBanner/QrBanner'
import SuggestedProjects from '../UI/SuggestedProjects/SuggestedProjects'
import Container from '../UI/Container/Container'
import Steps from './Steps/Steps'

const Index = () => {
  return (
    <Layout authLevelRequired={false} hideMenu>
      <Banner />
      <Container className="mt-10">
        <SuggestedProjects />
      </Container>
      <Functionality className="mt-16" />
      <Steps />
      <Ratings />
      <Features />
      <QrBanner />
      <Mission />
      <Vision />
    </Layout>
  )
}

export default Index
