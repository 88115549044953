import { useDispatch, useSelector } from 'react-redux'
import { ComponentMapping, FinalStepSubmit, GetData, PipelineState, PipelineStateProp, PrefilPipelineStateData } from '../types'
import props from '../../../../redux/props'
import { setLocal } from '../../../../redux/action/local'

const useGoNext = <D>(
  pipelineStateProp: PipelineStateProp,
  getData: GetData,
  finalStepSubmit?: FinalStepSubmit<D>,
  componentMapping?: ComponentMapping,
  prefilPipelineData?: PrefilPipelineStateData<D>,
) => {
  const state: PipelineState<D> = useSelector((s) => s[props.PIPELINE])
  const dispatch = useDispatch()

  const next = async () => {
    const data = await getData()
    if (data) {
      if (!!prefilPipelineData && !!componentMapping) {
        const newBody = { ...state, data: { ...state.data, ...prefilPipelineData, [pipelineStateProp]: data } }
        dispatch(setLocal(props.PIPELINE, { ...newBody, currentIndex: componentMapping.length - 1 }))
        document.body.scrollTop = 0
      } else {
        const newBody = { ...state, data: { ...state.data, [pipelineStateProp]: data } }
        if (finalStepSubmit) {
          finalStepSubmit(newBody)
          return
        }
        dispatch(setLocal(props.PIPELINE, { ...newBody, currentIndex: state.currentIndex + 1 }))
        document.body.scrollTop = 0
      }
    }
  }

  return next
}

export default useGoNext
