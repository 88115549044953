import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import props from '../../../../redux/props'
import { useEffect, useState } from 'react'
import { crud } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../../../toast'
import { ArtistListItem, Request } from '../../../../types/Request'
import MediumContainer from '../../../UI/Container/MediumContainer'
import DefaultProperties from '../../../Request/View/DefaultProperties/DefaultProperties'
import WorkshopProperties from '../../../Request/View/WorkshopProperties/WorkshopProperties'
import _ from 'lodash'
import styled from 'styled-components'
import PriceCalculator, { PriceCalculatorType } from '../../../UI/PriceCalculator/PriceCalculator'
import useListOffers from '../../../hooks/useListOffers'
import Headline from '../../../UI/Headline/Headline'
import Section from '../../../UI/NewAccordion/Section/Section'
import ReverseOffer from './ReverseOffer/ReverseOffer'
import RemoveDirectRequest from './RemoveDirectRequest/RemoveDirectRequest'
import { Offer } from '../../../../types/Offer'
import ArtistCard from './ReverseOffer/ArtistCard'
import SmallContainer from '../../../UI/Container/SmallContainer'
import { getUUID } from '../../../../utility'

const ItemWrapper = styled.div`
  width: calc(100% / 2 - 25px / 2);
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

const View: React.FC<{}> = () => {
  const { uuid } = useParams<{ uuid: string }>()
  const [request, setRequest] = useState<Request>(null)
  const t = useSelector((s) => s[props.TRANSLATION])
  const requestFeature = crud.use(props.REQUEST)
  const [offers] = useListOffers(uuid)

  useEffect(() => {
    const getRequest = async () => {
      const request = await requestFeature.get({ uuid })
      if (request) {
        setRequest(request)
      } else {
        toast(TYPE.ERROR, t.request.view.error)
      }
    }
    if (!request) {
      getRequest()
    }
  }, [request, uuid, t, requestFeature])

  const isWorkshop = () => {
    return request && _.has(request, 'attendee.amount') && isFinite(request.attendee.amount)
  }

  const isPublicRequest = () => {
    return !request.isDirectRequest
  }

  const filterArtist = (artist: ArtistListItem) => {
    if (Array.isArray(offers)) {
      const offer = offers.find((offer) => offer.artist.uuid === artist.uuid)
      if (offer) return false
    }
    return artist.remainingDays > 0
  }

  const filterOffer = (offer: Offer) => {
    return offer.remainingDays > 0
  }

  if (!request || !offers) return <></>
  return (
    <>
      <MediumContainer>
        <div className="flex flex-col gap-10 mb-10">
          {!isPublicRequest && Array.isArray(offers) && offers.length > 0 && (
            <div>
              <Headline>{t.booker.request.reverseOfferTitle}</Headline>
              <div className="flex flex-row justify-center flex-wrap gap-[25px] mt-3">
                {offers.filter(filterOffer).map((offer) => (
                  <ItemWrapper key={offer.artist.uuid}>
                    <ArtistCard artist={offer.artist} />
                    <Section
                      title={
                        <div className="text-sm text-center">
                          {`${t.request.view.offers.reverseOffer}`}
                          <span className="pl-3 text-sm font-light">{`(${t.request.view.offers.remainingDays}${offer.remainingDays})`}</span>
                        </div>
                      }
                      contentClassName="!px-[1px] !pb-[1px]"
                      className="bg-magenta-primary"
                      content={
                        <div className="bg-white p-3 rounded-sm rounded-t-none">
                          <ReverseOffer request={request} offer={offer} removeCallback={() => {}} />
                        </div>
                      }
                    />
                  </ItemWrapper>
                ))}
              </div>
            </div>
          )}
          {isPublicRequest && Array.isArray(offers) && offers.length > 0 && (
            <div>
              <Headline>{t.booker.request.offerTitle}</Headline>
              <div className="flex flex-row justify-center flex-wrap gap-[25px] mt-3">
                {offers.filter(filterOffer).map((offer) => (
                  <ItemWrapper key={offer.artist.uuid}>
                    <ArtistCard artist={offer.artist} />
                    <Section
                      title={
                        <div className="text-sm text-center">
                          {`${t.request.view.offers.offerTitle}`}
                          <span className="pl-3 text-sm font-light">{`(${t.request.view.offers.remainingDays}${offer.remainingDays})`}</span>
                        </div>
                      }
                      contentClassName="!px-[1px] !pb-[1px]"
                      className="bg-magenta-primary"
                      content={
                        <div className="bg-white p-3 rounded-sm rounded-t-none">
                          <ReverseOffer request={request} offer={offer} removeCallback={() => {}} />
                        </div>
                      }
                    />
                  </ItemWrapper>
                ))}
              </div>
            </div>
          )}
          {Array.isArray(request.artistList) && request.artistList.filter(filterArtist).length > 0 && (
            <div>
              <Headline>{t.booker.request.title}</Headline>
              <div className="flex flex-row justify-center flex-wrap gap-[25px] mt-3">
                {request.artistList.filter(filterArtist).map((artist) => (
                  <ItemWrapper key={artist.uuid}>
                    <ArtistCard artist={artist}>
                      <div className="flex flex-row justify-between">
                        <RemoveDirectRequest requestUUID={request.uuid} artistUUID={artist.uuid} />
                      </div>
                    </ArtistCard>
                    <Section
                      title={
                        <div className="text-sm text-center">
                          {`${t.request.view.artistPricing.title}`}
                          <span className="pl-3 text-sm font-light">{`(${t.request.view.offers.remainingDays}${artist.remainingDays})`}</span>
                        </div>
                      }
                      contentClassName="!px-[1px] !pb-[1px]"
                      className="bg-magenta-primary"
                      content={
                        <div className="bg-white p-6 rounded-sm rounded-t-none">
                          <PriceCalculator type={PriceCalculatorType.REQUEST} artist={artist} request={request} />
                        </div>
                      }
                    />
                  </ItemWrapper>
                ))}
              </div>
            </div>
          )}
        </div>
      </MediumContainer>
      <SmallContainer>
        {isWorkshop() && <WorkshopProperties request={request} />}
        {!isWorkshop() && <DefaultProperties request={request} />}

        {isWorkshop() ? (
          <div>
            {request.isDirectRequest && (
              <>
                <div className="bg-gray-thirdary py-3 px-5 mt-10">
                  <div className="text-sm">{t.request.create.summary.workshop.price.ofArtist.payment.title}</div>
                  {t.request.create.summary.workshop.price.ofArtist.payment.text.map((t: string) => (
                    <div className="text-xs mt-2 text-black-secondary" key={getUUID()}>
                      {t}
                    </div>
                  ))}
                </div>
                <div className="bg-blue-light py-3 px-5 mt-3">
                  <div className="text-sm">{t.request.create.summary.workshop.price.ofArtist.cancellation.title}</div>
                  <div className="text-xs mt-2 text-black-secondary">{t.request.create.summary.workshop.price.ofArtist.cancellation.text}</div>
                </div>
              </>
            )}
            {!request.isDirectRequest && (
              <>
                <div className="bg-gray-thirdary py-3 px-5 mt-10">
                  <div className="text-sm">{t.request.create.summary.workshop.price.payment.title}</div>
                  {t.request.create.summary.workshop.price.payment.text.map((t: string) => (
                    <div className="text-xs mt-2 text-black-secondary" key={getUUID()}>
                      {t}
                    </div>
                  ))}
                </div>
                <div className="bg-blue-light py-3 px-5 mt-3">
                  <div className="text-sm">{t.request.create.summary.workshop.price.cancellation.title}</div>
                  <div className="text-xs mt-2 text-black-secondary">{t.request.create.summary.workshop.price.cancellation.text}</div>
                </div>
              </>
            )}
          </div>
        ) : (
          <div>
            {request.isDirectRequest && (
              <>
                <div className="bg-gray-thirdary py-3 px-5 mt-10">
                  <div className="text-sm">{t.request.create.summary.default.price.ofArtist.payment.title}</div>
                  {t.request.create.summary.default.price.ofArtist.payment.text.map((t: string) => (
                    <div className="text-xs mt-2 text-black-secondary" key={getUUID()}>
                      {t}
                    </div>
                  ))}
                </div>
                <div className="bg-blue-light py-3 px-5 mt-3">
                  <div className="text-sm">{t.request.create.summary.default.price.ofArtist.cancellation.title}</div>
                  <div className="text-xs mt-2 text-black-secondary">{t.request.create.summary.default.price.ofArtist.cancellation.text}</div>
                </div>
              </>
            )}
            {!request.isDirectRequest && (
              <>
                <div className="bg-gray-thirdary py-3 px-5 mt-10">
                  <div className="text-sm">{t.request.create.summary.default.price.payment.title}</div>
                  {t.request.create.summary.default.price.payment.text.map((t: string) => (
                    <div className="text-xs mt-2 text-black-secondary" key={getUUID()}>
                      {t}
                    </div>
                  ))}
                </div>
                <div className="bg-blue-light py-3 px-5 mt-3">
                  <div className="text-sm">{t.request.create.summary.default.price.cancellation.title}</div>
                  <div className="text-xs mt-2 text-black-secondary">{t.request.create.summary.default.price.cancellation.text}</div>
                </div>
              </>
            )}
          </div>
        )}
      </SmallContainer>
    </>
  )
}

export default View
