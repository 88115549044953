import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { useEffect, useState } from 'react'

type Props = {
  open?: boolean
  title: React.ReactNode | string
  content: React.ReactNode | string | string[]
  clickedCallback?: () => void
  isLast?: boolean
  isFirst?: boolean
  className?: string
  contentClassName?: string
  fitTitle?: boolean
}

const Section: React.FC<Props> = ({ open, title, content, clickedCallback, className = '', fitTitle = false, contentClassName = '' }) => {
  const [internalOpen, setInternalOpen] = useState<boolean>(open || false)

  useEffect(() => {
    if (open !== undefined) {
      setInternalOpen(open)
    }
  }, [open])

  const click = () => {
    if (open !== undefined) {
      clickedCallback && clickedCallback()
    } else {
      setInternalOpen(!internalOpen)
    }
  }

  return (
    <div className={`bg-blue-primary rounded-sm overflow-hidden ${internalOpen ? 'h-fit' : 'h-7'} ${className}`}>
      <div className={`flex items-center title text-white py-1 px-2 ${fitTitle ? 'h-fit' : 'h-7'} cursor-pointer w-full`} onClick={click}>
        <div className="w-11/12 truncate text-sm ">{title}</div>
        <div className="w-1/12 flex justify-end">
          <ChevronDownIcon className={`w-4 transition-all ${internalOpen && 'rotate-180'}`} />
        </div>
      </div>
      <div className={`content transition-all px-2 ${internalOpen ? 'max-h-fit pb-1' : 'max-h-0'} ${contentClassName}`}>
        {typeof content === 'string' ? <div className="text-white text-xs">{content}</div> : content}
      </div>
    </div>
  )
}

export default Section
