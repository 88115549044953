import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { Skills as SkillsType } from '../types'
import { PipelineComponent } from '../../../UI/Pipeline/types'

import toast, { TYPE } from '../../../../toast'
import SelectableTagList from '../../../UI/SelectableTagList/SelectableTagList'
import SmallContainer from '../../../UI/Container/SmallContainer'
import SectionHeader from '../../../UI/Pipeline/SectionHeader'
import { useEffect, useState } from 'react'
import { Category, Tag } from '../../../../types/Tag'

const Skills: PipelineComponent<SkillsType> = ({ pipelineStateProp, data, setData, NavigationForm, finalStepSubmit }) => {
  const t = useSelector((state) => state[props.TRANSLATION])
  const allTags = useSelector<Tag[]>((state) => state[props.TAG])
  const [availableSkills, setAvailableSkills] = useState(null)

  useEffect(() => {
    if (!availableSkills && Array.isArray(allTags)) {
      setAvailableSkills(
        allTags.filter((tag: Tag) => {
          if (tag.category === Category.EQUIPMENT) return false
          if (tag.category === Category.SURFACE) return false
          return true
        }),
      )
    }
  }, [allTags, availableSkills])

  const set = (prop: keyof SkillsType) => (value: any) => setData({ ...data, [prop]: value })

  const getData = () => {
    if (data.tagENUMList?.length === 3) {
      return data
    } else {
      toast(TYPE.ERROR, t.register.artist.skills.error)
    }
    return false
  }

  return (
    <SmallContainer>
      <SectionHeader headline={t.register.artist.skills.headline} description={t.register.artist.skills.description} />
      <NavigationForm pipelineStateProp={pipelineStateProp} getData={getData} finalStepSubmit={finalStepSubmit}>
        {availableSkills && (
          <SelectableTagList
            maxVisibleRows={3}
            className="mt-7"
            selectedTagENUMList={data.tagENUMList}
            tags={availableSkills}
            maxSelectable={3}
            onChange={set('tagENUMList')}
          />
        )}
      </NavigationForm>
    </SmallContainer>
  )
}

export default Skills
