import { useSelector } from 'react-redux'
import GrayBox from '../../../../UI/Graybox/Graybox'
import NewButton from '../../../../UI/NewButton/NewButton'
import props from '../../../../../redux/props'
// import QR from 'qrcode.react'
import useGetArtist from '../../../../hooks/useGetArtist'
import { useEffect, useState } from 'react'
import Loading from '../../../../UI/Loading/Loading'
import { frontendDomain } from '../../../../../utility'
import { QRCode as QR } from 'react-qrcode-logo'

const QRCode: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [artist] = useGetArtist()
  const [url, setURL] = useState<string>()

  useEffect(() => {
    if (artist && !url) {
      setURL(`${frontendDomain}/artist/view/uuid/${artist.uuid}`)
    }
  }, [artist, url])

  const download = () => {
    const canvas = document.querySelector('#qrcode-canvas') as HTMLCanvasElement
    if (canvas) {
      const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
      let downloadLink = document.createElement('a')
      downloadLink.href = pngUrl
      downloadLink.download = 'qr-code.png'
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    }
  }

  return (
    <GrayBox className="py-3 md:w-2/5" title={t.artist.dashboard.qrCode.title}>
      <Loading loading={url} />
      {!!url && (
        <div className="flex items-end h-full gap-3">
          <QR id="qrcode-canvas" size={150} ecLevel={'L'} quietZone={0} value={url} qrStyle={'dots'} bgColor={'transparent'} fgColor={'#000000'} />
          <div className="flex flex-col gap-2">
            <div className="text-xs leading-4">{t.artist.dashboard.qrCode.hint}</div>
            <NewButton
              onClick={download}
              text={t.artist.dashboard.qrCode.cta}
              className="!bg-gray-thirdary !text-black !border-gray-primary rounded-sm !text-xs"
            />
          </div>
        </div>
      )}
    </GrayBox>
  )
}

export default QRCode
