import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { PipelineState } from '../../../../UI/Pipeline/types'
import Property from '../../../../UI/PropertyList/Property'
import { formatPrice } from '../../../../../utility'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import Headline from '../../../../UI/Headline/Headline'
import { ArtistListItem, Request } from '../../../../../types/Request'

type Props = {
  artist: ArtistListItem
  request: Request
}

type Data = {
  minPrice: number
  additionalAttendeePrice?: number
  totalPrice: number
}

const WorkshopCalculator: React.FC<Props> = ({ artist, request }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const state: PipelineState<Request> = useSelector((s) => s[props.PIPELINE])
  const [data, setData] = useState<Data>(null)

  useEffect(() => {
    if (_.has(request, 'attendee.amount')) {
      const data = {} as Data
      const singlePrice = artist.pricing.workshop.pricePerAttendee
      const totalPrice = request.attendee.amount * artist.pricing.workshop.pricePerAttendee
      data.minPrice = artist.pricing.workshop.minAttendees * singlePrice
      if (request.attendee.amount > artist.pricing.workshop.minAttendees) {
        data.additionalAttendeePrice = (request.attendee.amount - artist.pricing.workshop.minAttendees) * singlePrice
      }
      data.totalPrice = request.attendee.amount > artist.pricing.workshop.minAttendees ? totalPrice : data.minPrice

      setData(data)
    }
  }, [state, request, artist])

  if (!data || !isFinite(data.totalPrice)) return <></>
  return (
    <div className="absolute right-52 top-0 w-[300px] hidden xl:block">
      <Headline>{t.request.create.artistPriceCalculator.headline}</Headline>
      <div className="rounded-sm border border-gray-primary py-2 px-3">
        <Property valueRight property={{ name: t.request.create.artistPriceCalculator.workshop.minPrice, value: formatPrice(data.minPrice) }} />
        {isFinite(data.additionalAttendeePrice) && (
          <Property
            valueRight
            property={{ name: t.request.create.artistPriceCalculator.workshop.additionalAttendees, value: formatPrice(data.additionalAttendeePrice) }}
          />
        )}
        {isFinite(data.totalPrice) && (
          <Property
            valueRight
            className="border-t border-gray-primary pt-1 mt-1"
            property={{ name: t.request.create.artistPriceCalculator.workshop.total, value: formatPrice(data.totalPrice) }}
          />
        )}
      </div>
    </div>
  )
}

export default WorkshopCalculator
