import { useSelector } from 'react-redux'
import NewInput from '../../UI/NewInput/NewInput'
import NewButton from '../../UI/NewButton/NewButton'
import props from '../../../redux/props'
import { useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import Layout from '../../UI/Layout/Layout'
import MediumContainer from '../../UI/Container/MediumContainer'
import Form from '../../UI/Form/Form'
import { useParams } from 'react-router-dom'
import SectionHeader from '../../UI/Pipeline/SectionHeader'

const SetNewPassword: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [password, setPassword] = useState('')
  const [success, setSuccess] = useState(null)
  const { token } = useParams()
  const setNewPassword = functional.use(Routes.SET_NEW_PASSWORD)

  const submit = async () => {
    const status = await setNewPassword({ resettoken: token, password })
    setSuccess(!!status)
    setPassword('')
  }

  return (
    <Layout authLevelRequired={false}>
      <MediumContainer className="pt-5">
        <SectionHeader headline={t.lostPassword.set.title} description={t.lostPassword.set.description} />
        {success === null && (
          <Form onSubmit={submit} className="mt-3">
            <NewInput type="password" required placeholder={t.generic.password} onChange={setPassword} value={password} autoComplete="off" />
            <NewButton type="submit" text={t.generic.confirm} className="mt-3 ml-auto" />
          </Form>
        )}
        {success === true && (
          <>
            <div className="text-sm">{t.lostPassword.set.success}</div>
            <NewButton text={t.register.success.loginCTA} href="/login" className="mt-4 ml-auto w-fit" />
          </>
        )}
        {success === false && <div className="text-sm">{t.generic.error}</div>}
      </MediumContainer>
    </Layout>
  )
}

export default SetNewPassword
