import styled from 'styled-components'

type Prop = {
  type?: string
  unit?: string
  children?: React.ReactNode
  className?: string
}

const ChildrenWrapper = styled.div`
  .form-control,
  input {
    border: none !important;
  }
`

const TypeUnitValueMask: React.FC<Prop> = ({ type, unit, children, className = '' }) => {
  return (
    <div className={`flex flex-row gap-2 items-center ${className}`}>
      <div className={`shrink-0 flex h-7 rounded-full ${!unit ? '' : 'border border-blue-primary'} w-fit overflow-hidden bg-white min-w-[7rem]`}>
        {!!type && (
          <div
            className={`text-blue-primary bg-gray-secondary border border-blue-primary font-bold rounded-full ${
              !!unit ? 'aspect-square -ml-[1px]' : 'min-w-[5rem] w-full px-3'
            } h-7 flex self-center justify-center items-center`}
          >
            {type}
          </div>
        )}
        {!!unit && (
          <div className="shrink-1 text-blue-primary w-full flex items-center px-2">
            <div className="w-full text-sm whitespace-nowrap text-center">{unit}</div>
          </div>
        )}
      </div>
      {!!children && (
        <ChildrenWrapper className="rounded-full overflow-hidden border h-7 flex items-center border-blue-primary text-blue-primary bg-white">
          {children}
        </ChildrenWrapper>
      )}
    </div>
  )
}

export default TypeUnitValueMask
