import { Bar, Cell, ComposedChart, Line, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { Invoice } from '../../../../../types/Invoice'
import { useEffect, useState } from 'react'
import YearSelection from '../List/YearSelection'
import { formatPrice } from '../../../../../utility'

type Props = {
  invoices: Invoice[]
}

type DataItem = {
  projectUUID: string
  name: string
  v: number
}

type SummedUpItem = {
  [projectUUID: string]: {
    projectUUID: string
    name: string
    amountInCents: number
    createdOn: string
  }
}

const ProjectsPerYear: React.FC<Props> = ({ invoices }) => {
  const [data, setData] = useState<DataItem[]>([])
  const [year, setYear] = useState<number>(null)

  useEffect(() => {
    if (!!invoices) {
      const invoicesOfYear = invoices.filter((invoice) => new Date(invoice.createdOn).getFullYear() === year)

      const summedUp: SummedUpItem = invoicesOfYear.reduce((acc, cur) => {
        if (!acc[cur.projectUUID]) {
          acc[cur.projectUUID] = {
            projectUUID: cur.projectUUID,
            name: cur.project.nameAndLocation.name,
            amountInCents: 0,
            createdOn: cur.createdOn,
          }
        }
        acc[cur.projectUUID] = {
          ...acc[cur.projectUUID],
          amountInCents: acc[cur.projectUUID].amountInCents + cur.payment.netInCents,
          createdOn: cur.createdOn,
        }
        return acc
      }, {})

      const projects = Object.values(summedUp).sort((a, b) => new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime())

      const data: DataItem[] = []

      let charClipAt = 50
      if (projects.length >= 6) {
        charClipAt = 5
      } else if (projects.length >= 3) {
        charClipAt = 10
      }
      for (const project of projects) {
        data.push({
          projectUUID: project.projectUUID,
          name: project.name.length > charClipAt ? project.name.substring(0, charClipAt) + '...' : project.name,
          v: project.amountInCents / 100,
        })
      }

      setData(data)
    }
  }, [invoices, setData, year])

  const Label = (props: any) => {
    const { x, y, fill, value, width } = props

    if (value === 0) return null
    return (
      <text x={x + width / 2} y={y} dy={-4} width={100} fontSize="16" fill={fill} textAnchor="middle">
        {formatPrice(value, true)}
      </text>
    )
  }

  const getMaxValue = () => {
    let max = 0
    for (const d of data) {
      if (d.v > max) max = d.v
    }
    return max + max / 3
  }

  const selectColor = (index: number): string => {
    const colors = ['#00dece', '#63edac', '#b0f689', '#00cce4']
    return colors[index % 4]
  }

  return (
    <>
      <div className="lg:w-40 mb-3">
        <YearSelection invoices={invoices} value={year} onChange={setYear} />
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart data={data} margin={{ top: 20, bottom: 10 }}>
          <XAxis dataKey="name" tickLine={false} />
          <YAxis tickCount={6} domain={[0, getMaxValue()]} width={60} tickLine={false} tickFormatter={(value: number) => formatPrice(value, true)} />
          <Bar dataKey={'v'} label={<Label />}>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={selectColor(index)} />
            ))}
          </Bar>
          <Line
            type="linear"
            dataKey="v"
            stroke="rgb(14, 183, 233)"
            strokeDasharray={'0 8'}
            strokeWidth={2}
            strokeLinecap="round"
            dot={false}
            isAnimationActive={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </>
  )
}

export default ProjectsPerYear
