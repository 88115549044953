import Container from '../UI/Container/Container'
import Layout from '../UI/Layout/Layout'
import '../UI/LegalDocumentStyling/LegalDocument.scss'

const Imprint = () => {
  return (
    <Layout authLevelRequired={false}>
      <Container>
        <div className="legal-document">
          <h1>Impressum</h1> <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
          <p>
            Qsellart GmbH
            <br /> Goldstra&szlig;e 75a
            <br /> 48147 M&uuml;nster
          </p>
          <p>
            Handelsregister: HRB 20192
            <br /> Registergericht: M&uuml;nster
          </p>
          <p>
            <strong>Vertreten durch:</strong>
            <br /> Paul Ponomarev, Alexander Wei&szlig;en
          </p>
          <h2>Kontakt</h2>
          <p>
            Telefon: 017647367078
            <br /> E-Mail: info@qsellart.de
          </p>
          <h2>Umsatzsteuer-ID</h2>
          <p>
            Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:
            <br /> DE351350411
          </p>
          <h2>Redaktionell verantwortlich</h2> <p>Qsellart GmbH</p> <h2>EU-Streitschlichtung</h2>
          <p>
            Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <br />
            <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">
              https://ec.europa.eu/consumers/odr/
            </a>
            .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
          </p>
          <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
          <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
        </div>
      </Container>
    </Layout>
  )
}

export default Imprint
