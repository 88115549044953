import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { getUUID } from '../../../utility'
import Container from '../../UI/Container/Container'
import { StyledMission, Headline, Paragraph } from './Mission.Styled'

const Mission = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <StyledMission>
      <Container>
        <Headline>{translation.home.mission.headline}</Headline>
        {translation.home.mission.paragraphs.map((t, i) => (
          <Paragraph key={getUUID()}>{t}</Paragraph>
        ))}
      </Container>
    </StyledMission>
  )
}

export default Mission
