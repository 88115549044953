import { useSelector } from 'react-redux'
import { Pricing } from '../../../../../types/Artist'
import props from '../../../../../redux/props'
import { formatPrice } from '../../../../../utility'
import SizeAndPrice from '../../../../UI/Pricing/Editable/SizeAndPrice'

type Props = {
  data?: Pricing['concept']
}

const Concept: React.FC<Props> = ({ data }) => {
  const t = useSelector((state) => state[props.TRANSLATION])

  return (
    <div>
      <div className="font-bold mb-5">{t.register.artist.pricing.concept.headline}</div>
      <div className="flex flex-col items-center md:items-start">
        <SizeAndPrice required size={t.artist.search.view.pricing.conceptSizeText} price={formatPrice(data.price)} readOnly noUnit />
      </div>
    </div>
  )
}

export default Concept
