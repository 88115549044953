import { useSelector } from 'react-redux'
import { ArtistListItem, Primer, Request } from '../../../../types/Request'
import { Offer } from '../../../../types/Offer'
import { calculateSquareMeters, formatPrice, getPercentageOfPrice, getProjectSizeEnum } from '../../../../utility'
import props from '../../../../redux/props'
import Property from '../../PropertyList/Property'
import { useEffect } from 'react'
import AdditionalCosts from '../Components/AdditionalCosts'
import InsuranceToggle from '../Components/InsuranceToggle'
import { getTotalPriceOfOffer } from '../../../../utility'
import { Pricing, Insurance } from '../../../../types/Project'
import _ from 'lodash'
import AlternativeBudget from '../Components/AlternativeBudget'

type Props = {
  artist: ArtistListItem
  request: Request
  offer?: Offer
  setOffer?: (data: Offer) => void
  className?: string
}

const ForDefaultRequest: React.FC<Props> = ({ artist, request, offer, setOffer, className = '' }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const squareMeters = calculateSquareMeters(request.sizeAndPrimer.size.length, request.sizeAndPrimer.size.height)
  const projectSize = getProjectSizeEnum(squareMeters)

  const getTotalPrice = (noInsurance?: boolean) => getTotalPriceOfOffer(request, offer, noInsurance)

  const isReverseOffer = !!artist && Array.isArray(request.artistList) && request.artistList.some((a) => a.uuid === artist.uuid)

  const primerEnabled = request.sizeAndPrimer.primerRequired === Primer.YES && !artist.pricing.primer.disabled

  const insuranceIsPossible = !!artist.insuranceDetail.enabled

  const additionalPriceIsSet =
    _.has(offer, 'pricing.additional.price') && isFinite(offer.pricing.additional.price) && offer.pricing.additional.price > 0

  const discountPriceIsSet = _.has(offer, 'pricing.discount') && isFinite(offer.pricing.discount) && offer.pricing.discount > 0

  useEffect(() => {
    if (!offer && !!setOffer) {
      const offer: Offer = {
        requestUUID: request.uuid,
        pricing: {
          artPerSquareMeter: artist.pricing.art[projectSize],
          singleConcept: artist.pricing.concept.price,
          downPaymentPercentage: artist.pricing.downPaymentPercentage,
        },
        insurance: {
          accepted: false,
        },
      }
      if (!artist.pricing.primer.disabled) {
        offer.pricing.primerPerSquareMeter = artist.pricing.primer[projectSize]
      }

      if (!!artist.insuranceDetail.enabled) {
        offer.insurance.accepted = artist.insuranceAcceptedByBooker
        offer.insurance.radius = artist.insuranceDetail.radius
        offer.insurance.pricePercentage = artist.insuranceDetail.pricePercentage
        offer.insurance.timespanInMonths = artist.insuranceDetail.timespanInMonths
        offer.insurance.timeExpenseInHours = artist.insuranceDetail.timeExpenseInHours
      }
      setOffer(offer)
    }
  }, [offer, artist, projectSize, primerEnabled, setOffer, request])

  const set = (prop: keyof Pricing) => (value: any) => {
    const newOffer = { ...offer, pricing: { ...(offer.pricing || {}), [prop]: value } }
    setOffer(newOffer)
  }

  const setOfferProp = (prop: keyof Offer) => (value: any) => {
    const newOffer = { ...offer, [prop]: value }
    setOffer(newOffer)
  }

  const setInsurance = (prop: keyof Insurance) => (value: any) => {
    const newOffer = { ...offer, insurance: { ...(offer.insurance || { accepted: false }), [prop]: value } }
    setOffer(newOffer)
  }

  if (!offer) return null
  return (
    <div className={className}>
      {!!setOffer && (
        <div className="mb-3">
          {isReverseOffer && <AdditionalCosts value={offer.pricing.additional} onChange={set('additional')} isReverseOffer={isReverseOffer} />}
          {/* <DiscountAmount value={offer.pricing.discount} onChange={set('discount')} /> */}
          {isReverseOffer && insuranceIsPossible && <InsuranceToggle value={offer.insurance.accepted} onChange={setInsurance('accepted')} />}
        </div>
      )}
      <div className="rounded-sm">
        {/* in case of an initial offer show only the expected budget and the option to set an alternativ price */}
        {/* which automatically calculates the discount/additional costs */}
        {!isReverseOffer && !!setOffer && <AlternativeBudget request={request} offer={offer} set={setOfferProp} />}

        {/* in case of a reverse offer show all properties */}
        {(isReverseOffer || !setOffer) && (
          <>
            <Property
              property={{
                name: `${t.priceCalculator.art} ${formatPrice(offer.pricing.artPerSquareMeter)}${t.generic.multiply}${squareMeters}${
                  t.generic.squareMeter
                }`,
                value: formatPrice(offer.pricing.artPerSquareMeter * squareMeters),
              }}
            />
            {primerEnabled && (
              <Property
                property={{
                  name: `${t.priceCalculator.primer} ${formatPrice(offer.pricing.primerPerSquareMeter)}${t.generic.multiply}${squareMeters}${
                    t.generic.squareMeter
                  }`,
                  value: formatPrice(offer.pricing.primerPerSquareMeter * squareMeters),
                }}
              />
            )}
            {insuranceIsPossible && offer.insurance.accepted && (
              <Property
                property={{
                  name: t.priceCalculator.insurance.label,
                  value: formatPrice(getPercentageOfPrice(getTotalPrice(true), artist.insuranceDetail.pricePercentage)),
                }}
              />
            )}
            {additionalPriceIsSet ? (
              <>
                <Property
                  className="mt-5"
                  property={{
                    name: t.priceCalculator.additionalCosts.label,
                    value: formatPrice(offer.pricing.additional.price),
                  }}
                />
                {!!offer.pricing.additional.description && <div className="text-sm text-gray-primary">{offer.pricing.additional.description}</div>}
              </>
            ) : (
              <>{offer.alternativeBudgetDescription && <div className="text-sm text-gray-primary">{offer.alternativeBudgetDescription}</div>}</>
            )}
            {discountPriceIsSet && (
              <Property
                className="mt-5"
                property={{
                  name: t.priceCalculator.discountAmount.label,
                  value: formatPrice(offer.pricing.discount),
                }}
              />
            )}
            <Property
              className="mt-5"
              property={{
                name: t.priceCalculator.total,
                value: formatPrice(getTotalPrice()),
              }}
            />
            <Property
              className="mt-5"
              property={{
                name: `${t.priceCalculator.downPayment} ${offer.pricing.downPaymentPercentage}${t.generic.percent}`,
                value: formatPrice(getPercentageOfPrice(getTotalPrice(), offer.pricing.downPaymentPercentage)),
              }}
            />
            <Property
              property={{
                name: `${t.priceCalculator.finalPayment} ${100 - offer.pricing.downPaymentPercentage}${t.generic.percent}`,
                value: formatPrice(getPercentageOfPrice(getTotalPrice(), 100 - offer.pricing.downPaymentPercentage)),
              }}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default ForDefaultRequest
