import { AddOn } from './Project'

export enum MilestoneType {
  // base milestone types
  CONCEPT_AGREEMENT = 'CONCEPT_AGREEMENT',
  PROJECT_FEASIBILITY = 'PROJECT_FEASIBILITY',
  COMPLETE_FINAL_PAYMENT = 'COMPLETE_FINAL_PAYMENT',
  PROJECT_IN_PROGRESS = 'PROJECT_IN_PROGRESS',
  PROJECT_COMPLETED = 'PROJECT_COMPLETED',

  // additional milestone types
  OBJECT_INSPECTION = 'OBJECT_INSPECTION',
  DEADLINE_AGREEMENT = 'DEADLINE_AGREEMENT',
  OBJECT_PRIMER_ADDED = 'OBJECT_PRIMER_ADDED',
  PROJECT_ADD_ON = 'PROJECT_ADD_ON',
  MEETING_PREPARATION = 'MEETING_PREPARATION',
  CANVAS_COMPLETION = 'CANVAS_COMPLETION',
}

export const MilestonesWithBookerAcceptance: MilestoneType[] = [
  MilestoneType.OBJECT_INSPECTION,
  MilestoneType.PROJECT_COMPLETED,
  MilestoneType.PROJECT_ADD_ON,
  MilestoneType.COMPLETE_FINAL_PAYMENT,
]

export type Payload = {
  addOnUUID?: string
  addOn?: AddOn
}

export type Milestone = {
  uuid?: string
  projectUUID: string
  type?: MilestoneType
  index: number
  acceptedByArtist?: boolean
  acceptedByBooker?: boolean
  deadline?: Date
  completedOn?: Date
  payload?: Payload
  isDeletable?: boolean
  isCompleted?: boolean
}
