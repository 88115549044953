import { useSelector } from 'react-redux'
import Art from './Art'
import Primer from './Primer'
import Concept from './Concept'
import Workshop from './Workshop'
import { useState } from 'react'
import { DataSectionComponent } from '../../../../Home/Artist/types'
import props from '../../../../../redux/props'
import Title from '../../../../UI/Graybox/Title'
import arrowDown from '../../../../../assets/image/artist/public-profile/arrow-down.png'

const Pricing: DataSectionComponent = ({ artist }) => {
  const t = useSelector((state) => state[props.TRANSLATION])
  const [open, setOpen] = useState<boolean>(false)

  return (
    <div className="w-full mt-11 lg:ml-12 lg:mr-12">
      <div className="flex flex-col gap-0 md:flex-row md:gap-[100px] md:items-center">
        <Title className="text-lg !mb-0" title={t.artist.search.view.pricing.title} />
        <div className="flex flex-row gap-2 items-center cursor-pointer" onClick={() => setOpen(!open)}>
          <div className="text-magenta-primary">{t.artist.search.view.pricing.hint}</div>
          <div>
            <img src={arrowDown} alt="open" className="h-4" />
          </div>
        </div>
      </div>
      {!open && (
        <div className="flex flex-col md:flex-row gap-6 md:gap-3 items-center justify-between mt-10">
          <Art singleLine data={artist.pricing.art} noTitle />
          <Workshop data={artist.pricing.workshop} noTitle />
        </div>
      )}
      {open && (
        <div className="flex gap-3 flex-col md:flex-row mt-10">
          <div className="hidden md:flex flex-row gap-20 justify-center w-full">
            <Art data={artist.pricing.art} />
            <Primer data={artist.pricing.primer} />
            <div className="flex flex-col gap-3">
              <Concept data={artist.pricing.concept} />
              <Workshop data={artist.pricing.workshop} />
            </div>
          </div>
          <div className="flex md:hidden flex-col md:flex-row gap-5 justify-between">
            <Art data={artist.pricing.art} />
            <Primer data={artist.pricing.primer} />
          </div>
          <div className="flex md:hidden flex-col justify-between gap-5 mt-10 md:mt-0">
            <Concept data={artist.pricing.concept} />
            <Workshop data={artist.pricing.workshop} />
          </div>
        </div>
      )}
    </div>
  )
}

export default Pricing
