import FileSelect from '../FileSelect/FileSelect'
import upload from '../../../assets/image/generic/upload.png'
import { formatFileSize } from '../../../utility'

const acceptedMimeTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf']
const maxFileSize = 8000000

type Props = {
  title?: string
  files?: File[] | File
  onFiles: (files: File[] | File) => void
  multiple?: boolean
}

const DirectFileUpload: React.FC<Props> = ({ title, files, onFiles, multiple = false }) => {
  const File = ({ file }: { file: File }) => (
    <div key={file.name} className="flex flex-row justify-between">
      <div className="truncate w-9/12 text-sm">{file.name}</div>
      <div className="truncate w-3/12 text-sm text-right">({formatFileSize(file.size)})</div>
    </div>
  )

  const Box = (
    <div className="border w-full rounded-sm flex flex-col">
      <div className="h-24 flex items-center justify-center">
        <img src={upload} className="h-10 text-white hover:scale-105 transition-all" alt="" />
      </div>
      {!!files && (
        <div className="flex flex-col gap-0 mb-1 pt-1 px-1 border-t border-gray-secondary">
          {Array.isArray(files) && files.map((f) => <File key={f.name} file={f} />)}
          {!Array.isArray(files) && <File key={files.name} file={files} />}
        </div>
      )}
    </div>
  )

  return (
    <div>
      <div className="text-center font-bold text-sm">{title}</div>
      <FileSelect acceptedMimeTypes={acceptedMimeTypes} maxFileSize={maxFileSize} onFiles={onFiles} children={Box} multiple={multiple} />
    </div>
  )
}

export default DirectFileUpload
