import { useEffect, useState } from 'react'
import { crud } from '@think-internet/zeus-frontend-package'
import props from '../../redux/props'
import { Project } from '../../types/Project'

type Props = (uuid: string) => [Project, (project: keyof Project) => (data: any) => void]

const useGetProject: Props = (uuid) => {
  const [project, setProject] = useState<Project>()
  const projectFeature = crud.use(props.PROJECT)

  const set = (prop: keyof Project) => (value: any) => {
    setProject({ ...project, [prop]: value })
  }

  useEffect(() => {
    const get = async () => {
      if (!!uuid) {
        const project = await projectFeature.get({ uuid })
        setProject(project)
      }
    }
    if (!project) {
      get()
    }
  })

  return [project, set]
}

export default useGetProject
