import Title from '../Graybox/Title'
import { Sizes } from '../../../types/Size'
import SizeAndValue from '../SizeAndValue/SizeAndValue'
import OrderSelection from './OrderSelection'
import _ from 'lodash'

export type SizeFilterSelection = {
  order?: 1 | -1
  selectedSize?: Sizes
}

type Props = {
  title?: string
  value: SizeFilterSelection
  onChange: (value: SizeFilterSelection) => void
  className?: string
  useSmallName?: boolean
}

const ProjectSizeSelection: React.FC<Props> = ({ title, value, onChange, className = '', useSmallName = false }) => {
  const updateSelectedSize = (size: Sizes) => () => {
    if (_.has(value, 'selectedSize') && size === value.selectedSize) {
      onChange({
        ...value,
        selectedSize: undefined,
      })
    } else {
      onChange({
        ...value,
        selectedSize: size,
      })
    }
  }

  const updateOrder = (order: 1 | -1) => {
    onChange({
      ...value,
      order,
    })
  }

  return (
    <div className={className}>
      {!!title && <Title title={title} className="text-white text-lg md:ml-3 !mb-2" />}
      <OrderSelection value={value.order} onChange={updateOrder} />
      <div className="flex flex-col w-fit mx-auto md:mx-0 md:flex-row flex-wrap gap-3 mt-4">
        {Object.values(Sizes).map((size) => (
          <SizeAndValue key={size} size={size} invert={value.selectedSize !== size} onClick={updateSelectedSize(size)} useSmallName={useSmallName} />
        ))}
      </div>
    </div>
  )
}

export default ProjectSizeSelection
