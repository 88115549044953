import styled from 'styled-components'
import { Variant } from './Button'

export const StyledButton = styled.button<{ variant: Variant; isEnabled: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: ${({ variant }) => (variant === Variant.PREVIOUS ? 'row-reverse' : 'row')};
  cursor: ${({ isEnabled }) => (isEnabled ? 'pointer' : 'not-allowed')};
`

export const Text = styled.div`
  color: ${({ theme }) => theme.color.blue.primary};
  font-size: ${({ theme }) => theme.font.size.sm};
`
