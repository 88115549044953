interface IProgress {
  progress: number
  radial?: boolean
  className?: string
}

const Progress: React.FC<IProgress> = ({ progress, radial = false, className = '' }) => {
  if (!progress || progress === 0 || progress === 100) return null
  if (radial) {
    return (
      <div className="h-full w-full flex items-center justify-center">
        <div
          className="radial-progress text-blue-primary text-xs"
          style={{ '--value': progress, '--size': '2rem', '--thickness': '4px' } as React.CSSProperties}
          role="progressbar"
        >
          {progress} %
        </div>
      </div>
    )
  }
  return (
    <div className="h-full w-full flex items-center justify-center">
      <progress className={`progress progress-info !h-1 ${className}`} value={progress} max="100"></progress>
    </div>
  )
}

export default Progress
