import { useSelector } from 'react-redux'
import MediumContainer from '../../../UI/Container/MediumContainer'
import Headline from '../../../UI/Headline/Headline'
import Loading from '../../../UI/Loading/Loading'
import props from '../../../../redux/props'
import useGetArtist from '../../../hooks/useGetArtist'
import { Request, Request as RequestType } from '../../../../types/Request'
import { crud } from '@think-internet/zeus-frontend-package'
import { useEffect, useState } from 'react'
import toast, { TYPE } from '../../../../toast'
import RequestGridView from '../../../Request/RequestGridView/RequestGridView'
import placeholder from '../../../../assets/image/artist/placeholder.png'
import placeholderIcon from '../../../../assets/image/artist/request/placeholderIcon.png'
import { isWithinArtistRadius } from '../../../../utility'
import Notification from '../../../../types/Notification'
import Progress from '../Settings/Components/Progress'

const RequestList: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [artist] = useGetArtist()
  const requestFeature = crud.use(props.REQUEST)
  const [directRequests, setDirectRequests] = useState<RequestType[]>()
  const [publicRequests, setPublicRequests] = useState<RequestType[]>()
  const notifications: Notification[] = useSelector((s) => s[props.NOTIFICATION_LIST])

  const hasDirectRequests = () => !!directRequests && directRequests.length > 0
  const hasPublicRequests = () => !!publicRequests && publicRequests.length > 0
  const hasRequests = () => hasDirectRequests() || hasPublicRequests()

  const hasNotification = (uuid: string) => {
    if (!notifications) return false
    return !!notifications.find((notification: Notification) => {
      const payload = { requestUUID: uuid }
      const keys = Object.keys(payload)
      return keys.every((key) => notification.payload[key] === payload[key])
    })
  }

  useEffect(() => {
    const listRequests = async () => {
      const requests: Request[] = await requestFeature.list({ $or: [{ 'artistList.uuid': artist.uuid }, { artistList: [] }] })
      if (Array.isArray(requests)) {
        const directRequests = requests.filter((r) => r.artistList.some((a) => a.uuid === artist.uuid))
        const publicRequests = requests.filter((r) => r.artistList.length === 0 && isWithinArtistRadius(artist, r.nameAndLocation.location))
        setDirectRequests(directRequests)
        setPublicRequests(publicRequests)
      } else {
        toast(TYPE.ERROR, t.artist.request.error)
      }
    }
    if (!directRequests && !publicRequests && !!artist) {
      listRequests()
    }
  }, [artist, directRequests, publicRequests, t, requestFeature])

  return (
    <MediumContainer className="py-3" innerClassName="flex flex-col gap-20">
      <Loading loading={artist} />
      {!hasRequests() && (
        <div>
          <Headline className="mb-3">{t.artist.request.title}</Headline>
          <div className="mb-10">
            <Progress artist={artist} />
          </div>
          <div className="relative">
            <div className="lg:absolute w-full top-0 h-full left-0 flex items-center flex-col lg:pt-10">
              <img className="w-9" src={placeholderIcon} alt="" />
              <div className="font-bold text-gray-primary mt-2">{t.artist.request.list.placeholder.title}</div>
              <div className="lg:w-6/12 text-center text-gray-primary mt-2 text-sm">{t.artist.request.list.placeholder.text}</div>
            </div>
            <img className="w-full hidden lg:block" src={placeholder} alt="" />
          </div>
        </div>
      )}
      {hasDirectRequests() && (
        <div>
          <Headline className="mb-3">{t.artist.request.directTitle}</Headline>
          <div className="flex flex-col gap-10">
            {directRequests
              .filter((r) => hasNotification(r.uuid))
              .map((r) => (
                <RequestGridView key={r.uuid} request={r} linkToInternalView />
              ))}
            {directRequests
              .filter((r) => !hasNotification(r.uuid))
              .map((r) => (
                <RequestGridView key={r.uuid} request={r} linkToInternalView />
              ))}
          </div>
        </div>
      )}
      {hasPublicRequests() && (
        <div>
          <Headline className="mb-3">{t.artist.request.publicTitle}</Headline>
          <div className="mb-10">
            <Progress artist={artist} />
          </div>
          <div className="flex flex-col gap-10">
            {publicRequests
              .filter((r) => hasNotification(r.uuid))
              .map((r) => (
                <RequestGridView key={r.uuid} request={r} linkToInternalView />
              ))}
            {publicRequests
              .filter((r) => !hasNotification(r.uuid))
              .map((r) => (
                <RequestGridView key={r.uuid} request={r} linkToInternalView />
              ))}
          </div>
        </div>
      )}
    </MediumContainer>
  )
}

export default RequestList
