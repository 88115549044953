import { Route, Routes } from 'react-router-dom'
import Layout from '../UI/Layout/Layout'
import Selection from './Selection/Selection'
import Artist from './Artist/Artist'
import Booker from './Booker/Booker'
import Confirm from './Confirm/Confirm'

const Register = () => {
  return (
    <Layout authLevelRequired={false}>
      <Routes>
        <Route path={'artist'} element={<Artist />} />
        <Route path={'booker'} element={<Booker />} />
        <Route path={'confirm/:linkUUID'} element={<Confirm />} />
        <Route path={'*'} element={<Selection />} />
      </Routes>
    </Layout>
  )
}

export default Register
