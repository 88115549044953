import styled from 'styled-components'

export const StyledMission = styled.div`
  margin-top: 80px;
`

export const Headline = styled.div`
  color: ${({ theme }) => theme.color.blue.primary};
  font-size: ${({ theme }) => theme.font.size.xlg};
  margin-bottom: 60px;
  font-family: 'thin';
  background-color: ${({ theme }) => theme.color.blue.primary};
  color: ${({ theme }) => theme.color.font.secondary};
  width: fit-content;
  padding: 10px 20px 5px 20px;
`

export const Paragraph = styled.div`
  color: ${({ theme }) => theme.color.blue.primary};
  font-size: ${({ theme }) => theme.font.size.md};
  margin-bottom: 20px;
`
