import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import { Chat, Message as MessageType } from '../../../../../../types/Chat'
import Icon from '../../../../../UI/ProfileIcon/Icon'
import { useSelector } from 'react-redux'
import props from '../../../../../../redux/props'
import { getPrettyTime } from '../../../../../../utility'
import { PurposeFlag } from '../../../../../../types/Auth'
import Files from './Files'
import { Artist } from '../../../../../../types/Artist'
import { Booker } from '../../../../../../types/Booker'

type Props = {
  chat: Chat
  message: MessageType
  previewMode?: boolean
}

const Message: React.FC<Props> = ({ chat, message, previewMode = false }) => {
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])

  const isMyMessage = message?.senderAccountLinkUUID === accountLink.uuid

  const profileIcon = () => {
    if (isMyMessage) {
      return accountLink.purposeFlag === PurposeFlag.ARTIST ? chat.artist?.profileImages?.personImageURL : chat.booker?.profileImages?.personImageURL
    } else {
      return accountLink.purposeFlag === PurposeFlag.ARTIST ? chat.booker?.profileImages?.personImageURL : chat.artist?.profileImages?.personImageURL
    }
  }

  const getPreviewText = (text: string) => {
    if (text.length > 50) {
      return text.substring(0, 50) + '...'
    }
    return text
  }

  return (
    <div>
      <div className={`chat ${isMyMessage ? 'chat-end' : 'chat-start'}`}>
        <div className="chat-image avatar">
          {accountLink.purposeFlag === PurposeFlag.ARTIST ? (
            <Icon artist={chat.artist as Artist} className={previewMode ? '!h-16' : '!h-10'} image={profileIcon()} />
          ) : (
            <Icon booker={chat.booker as Booker} className={previewMode ? '!h-16' : '!h-10'} image={profileIcon()} />
          )}
          {/* {!isMyMessage && accountLink.purposeFlag === PurposeFlag.ARTIST ? (
            <Icon booker={chat.booker as Booker} className={previewMode ? '!h-16' : '!h-10'} image={profileIcon()} />
          ) : (
            <Icon artist={chat.artist as Artist} className={previewMode ? '!h-16' : '!h-10'} image={profileIcon()} />
          )} */}
        </div>
        {!!message.text && (
          <div
            className={`chat-bubble whitespace-pre-wrap break-words max-w-[80%] overflow-hidden  ${
              isMyMessage ? 'bg-lightGreen text-white' : 'bg-torquise text-white'
            } ${previewMode ? 'text-lg' : ''}`}
          >
            {previewMode ? getPreviewText(message.text) : message.text}
            <time className="text-[12px] opacity-50 absolute right-2 bottom-0 text-black">{getPrettyTime(new Date(message.createdOn))}</time>
          </div>
        )}
        <Files isMyMessage={isMyMessage} files={message.files} />
      </div>
    </div>
  )
}

export default Message
