import { useEffect, useState } from 'react'
import { Invoice } from '../../../../../types/Invoice'
import Dropdown from '../../../../UI/Dropdown/Dropdown'
import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'

type Props = {
  invoices: Invoice[]
  value: number
  onChange: (value: number) => void
  allowTotal?: boolean
}

const YearSelection: React.FC<Props> = ({ invoices, value, onChange, allowTotal = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [yearList, setYearList] = useState<number[]>(null)

  const update = (value: string | number) => {
    if (typeof value === 'number') {
      onChange(value)
    } else {
      onChange(parseInt(value))
    }
  }

  useEffect(() => {
    if (!!invoices) {
      const years = invoices.map((invoice) => new Date(invoice.createdOn).getFullYear())
      const uniqueYears = [...new Set(years)]
      setYearList(uniqueYears)
      onChange(allowTotal ? 0 : uniqueYears[0])
    }
  }, [invoices, onChange, allowTotal])

  const getItems = () => {
    if (!yearList) return []
    const items = []
    if (allowTotal) {
      items.push({ value: 0, label: t.UI.yearSelection.total })
    }
    items.push(...yearList.map((y) => ({ value: y, label: y.toString() })))

    return items
  }

  if (!yearList) return null
  return (
    <Dropdown
      onChange={update}
      value={value}
      items={getItems()}
      className="rounded-full !w-1/2 lg:!w-full text-white !bg-magenta-primary border-white text-center"
      showEmptySelection={false}
    />
  )
}

export default YearSelection
