import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import NewButton from '../../UI/NewButton/NewButton'
import SectionHeader from '../../UI/Pipeline/SectionHeader'
import { useParams } from 'react-router'
import SmallContainer from '../../UI/Container/SmallContainer'
import Headline from '../../UI/Headline/Headline'
import { useEffect, useState } from 'react'
import { Artist } from '../../../types/Artist'
import { crud } from '@think-internet/zeus-frontend-package'
import Default from './Default/Default'
import Workshop from './Workshop/Workshop'
import Loading from '../../UI/Loading/Loading'
import MediumContainer from '../../UI/Container/MediumContainer'
import artworkImage from '../../../assets/image/request/selection/artwork.jpg'
import workshopImage from '../../../assets/image/request/selection/workshop.jpg'

enum PipelineSelectionType {
  DEFAULT = 'DEFAULT',
  WORKSHOP = 'WORKSHOP',
}

const PipelineSelection = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const { username } = useParams<{ username?: string }>()
  const [selectedType, setSelectedType] = useState<PipelineSelectionType>()
  const [artist, setArtist] = useState<Artist>(null)
  const artistFeature = crud.use(props.ARTIST)

  useEffect(() => {
    const getArtist = async () => {
      const artist = await artistFeature.get({ personalDetail: { username } })
      if (artist) {
        setArtist(artist)
      }
    }
    if (!artist && !!username) {
      getArtist()
    }
  })

  return (
    <div className="relative">
      {username && <Loading loading={artist} />}
      {((!!username && artist) || !username) && (
        <>
          <SmallContainer>
            {!!username && artist && (
              <Headline>
                {t.request.create.pipelineSelection.forArtist.headlinePrefix}
                {artist.personalDetail.username}
              </Headline>
            )}
            {!username && <Headline>{t.request.create.pipelineSelection.global.headline}</Headline>}
          </SmallContainer>
          {!selectedType && (
            <MediumContainer>
              <SectionHeader
                description={
                  !!username ? t.request.create.pipelineSelection.forArtist.selection : t.request.create.pipelineSelection.global.selection
                }
              />
              <div className="mt-5 gap-5">
                <div className="flex flex-col md:flex-row gap-5 px-6 md:px-0 md:gap-3">
                  <div className="flex flex-col gap-3 group w-full">
                    <img
                      src={artworkImage}
                      alt="artwork"
                      className="rounded-md cursor-pointer w-full aspect-video object-cover"
                      onClick={() => setSelectedType(PipelineSelectionType.DEFAULT)}
                    />
                    <div>
                      <NewButton
                        className="hidden md:flex btn-secondary-outline w-10/12 mx-auto"
                        text={t.request.create.pipelineSelection.default.cta}
                        onClick={() => setSelectedType(PipelineSelectionType.DEFAULT)}
                      />
                      <NewButton
                        className="md:hidden btn-secondary w-10/12 mx-auto"
                        text={t.request.create.pipelineSelection.default.cta}
                        onClick={() => setSelectedType(PipelineSelectionType.DEFAULT)}
                      />
                    </div>
                    <div className="hidden md:block text-xs text-transparent group-hover:text-gray-primary transition-all">
                      {t.request.create.pipelineSelection.default.text}
                    </div>
                  </div>
                  <div className="flex flex-col gap-3 group w-full">
                    <img
                      src={workshopImage}
                      alt="workshop"
                      className="rounded-md cursor-pointer w-full aspect-video object-cover"
                      onClick={() => setSelectedType(PipelineSelectionType.WORKSHOP)}
                    />
                    <div>
                      <NewButton
                        className="hidden md:flex btn-secondary-outline w-10/12 mx-auto"
                        text={t.request.create.pipelineSelection.workshop.cta}
                        onClick={() => setSelectedType(PipelineSelectionType.WORKSHOP)}
                      />
                      <NewButton
                        className="md:hidden btn-secondary w-10/12 mx-auto"
                        text={t.request.create.pipelineSelection.workshop.cta}
                        onClick={() => setSelectedType(PipelineSelectionType.WORKSHOP)}
                      />
                    </div>
                    <div className="hidden md:block text-xs text-transparent group-hover:text-gray-primary transition-all">
                      {t.request.create.pipelineSelection.workshop.text}
                    </div>
                  </div>
                </div>
              </div>
            </MediumContainer>
          )}
          {selectedType && (
            <SmallContainer>
              {selectedType === PipelineSelectionType.DEFAULT && <Default artist={artist} />}
              {selectedType === PipelineSelectionType.WORKSHOP && <Workshop artist={artist} />}
            </SmallContainer>
          )}
        </>
      )}
    </div>
  )
}

export default PipelineSelection
