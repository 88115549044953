import { Property as PropertyType } from './types'

type Props = { property: PropertyType; valueRight?: boolean; className?: string; contrast?: boolean; small?: boolean; splitInHalf?: boolean }

const Property: React.FC<Props> = ({ property, valueRight = false, className = '', contrast = false, small = false, splitInHalf = false }) => {
  if (!property.name || !property.value) return null

  if (!!property.valuePrefix) {
    return (
      <div className={`flex flex-row flex-wrap justify-between items-center gap-0 md:gap-2 ${className}`}>
        <div className={`hidden md:block ${contrast ? 'text-light-black' : 'text-gray-primary'} ${small ? 'text-xs' : 'text-sm'} truncate`}>
          {`${property.name} ${property.valuePrefix}`}
        </div>
        <div className={`block md:hidden w-full ${contrast ? 'text-light-black' : 'text-gray-primary'} ${small ? 'text-xs' : 'text-sm'} truncate`}>
          {property.name}
        </div>
        <div className={`block md:hidden ${contrast ? 'text-light-black' : 'text-gray-primary'} ${small ? 'text-xs' : 'text-sm'} truncate`}>
          {property.valuePrefix}
        </div>
        <div
          className={`${contrast ? 'text-light-black' : 'text-gray-primary'} ${small ? 'text-xs' : 'text-sm'} font-light ${
            splitInHalf ? 'w-1/2' : 'w-1/3'
          } truncate ${valueRight ? 'text-right' : ''}`}
        >
          {property.value}
        </div>
      </div>
    )
  }
  return (
    <div className={`flex flex-row justify-between items-center gap-2 ${className}`}>
      <div
        className={`${contrast ? 'text-light-black' : 'text-gray-primary'} ${small ? 'text-xs' : 'text-sm'} ${
          splitInHalf ? 'w-1/2' : 'w-2/3'
        } truncate`}
      >
        {property.name}
      </div>
      <div
        className={`${contrast ? 'text-light-black' : 'text-gray-primary'} ${small ? 'text-xs' : 'text-sm'} font-light ${
          splitInHalf ? 'w-1/2' : 'w-1/3'
        } truncate ${valueRight ? 'text-right' : ''}`}
      >
        {property.value}
      </div>
    </div>
  )
}

export default Property
