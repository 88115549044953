import { useSelector } from 'react-redux'
import MediumContainer from '../../../../UI/Container/MediumContainer'
import Loading from '../../../../UI/Loading/Loading'
import props from '../../../../../redux/props'
import { useParams } from 'react-router-dom'
import useGetProject from '../../../../hooks/useGetProject'
import useGetRating from '../../../../hooks/useGetRating'
import { maxPublicRatingTextLength } from '../../../../../utility'
import { Internal, Rating as RatingType } from '../../../../../types/Rating'
import { useState } from 'react'
import Textarea from '../../../../UI/Textarea/Textarea'
import Form from '../../../../UI/Form/Form'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../../redux/routes'
import Question from './Question'
import Headline from '../../../../UI/Headline/Headline'
import NewButton from '../../../../UI/NewButton/NewButton'
import toast, { TYPE } from '../../../../../toast'
import useReadNotification from '../../../../hooks/useReadNotification'
import { Type } from '../../../../../types/Notification'

const Rating: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const { uuid } = useParams<{ uuid: string }>()
  const [project] = useGetProject(uuid)
  const receivedRating = useGetRating(uuid)
  const submitRating = functional.use(Routes.SUBMIT_PROJECT_RATING)
  const [rating, setRating] = useState<RatingType>({ internal: {} } as RatingType)
  const [success, setSuccess] = useState(false)
  const read = useReadNotification()

  const set = (prop: keyof RatingType) => (value: any) => setRating({ ...rating, [prop]: value })
  const setInternal = (prop: keyof Internal) => (value: any) => setRating({ ...rating, internal: { ...rating.internal, [prop]: value } })

  const submit = async () => {
    const requiredRatings = ['quality', 'reliability', 'communication', 'cooperation']
    const requiredRatingsAvailable = requiredRatings.every((prop) => !!rating[prop] && isFinite(rating[prop]) && rating[prop] > 0)
    if (requiredRatingsAvailable) {
      if (!!rating.text && rating.text.length <= maxPublicRatingTextLength) {
        const status = await submitRating({ uuid: project.uuid, rating })
        if (!!status) {
          setSuccess(true)
          read({
            type: Type.PROJECT_COMPLETION,
            payload: { projectUUID: project.uuid },
          })
          read({
            type: Type.PROJECT_RATING,
            payload: { projectUUID: project.uuid },
          })
          document.body.scrollTop = 0
        } else {
          toast(TYPE.ERROR, t.rating.error.submit)
        }
      } else {
        toast(TYPE.ERROR, t.rating.error.ratingText)
      }
    } else {
      toast(TYPE.ERROR, t.rating.error.ratingProp)
    }
  }

  return (
    <MediumContainer className="py-3">
      <Loading loading={project} />
      {!!project && (
        <Headline className="mb-3">
          {t.project.view.titlePrefix}
          {project.nameAndLocation.name}
        </Headline>
      )}
      {!!project && !receivedRating && !success && (
        <Form onSubmit={submit}>
          <div className="text-lg font-bold text-gray-primary">{t.rating.headline}</div>
          <div className="flex flex-col gap-2 mt-5">
            <Question value={rating.quality} onChange={set('quality')} label={t.rating.questions.quality} />
            <Question value={rating.reliability} onChange={set('reliability')} label={t.rating.questions.reliability} />
            <Question value={rating.communication} onChange={set('communication')} label={t.rating.questions.communication} />
            <Question value={rating.cooperation} onChange={set('cooperation')} label={t.rating.questions.cooperation} />
          </div>
          <div className="mt-5 w-11/12">
            <Textarea
              required
              placeholder={t.rating.textPlaceholder}
              value={rating.text}
              onChange={set('text')}
              maxCharacters={maxPublicRatingTextLength}
              rows={4}
            />
            <div className="text-xs text-blue-primary">{t.rating.textHint}</div>
          </div>
          <div className="mt-10 text-lg font-bold text-gray-primary">{t.rating.internal.headline}</div>
          <div className="text-gray-primary">{t.rating.internal.hint}</div>
          <div className="flex flex-col gap-2 mt-5">
            <Question value={rating.internal.quality} onChange={setInternal('quality')} label={t.rating.questions.quality} />
            <Question value={rating.internal.reliability} onChange={setInternal('reliability')} label={t.rating.questions.reliability} />
            <Question value={rating.internal.communication} onChange={setInternal('communication')} label={t.rating.questions.communication} />
            <Question value={rating.internal.cooperation} onChange={setInternal('cooperation')} label={t.rating.questions.cooperation} />
          </div>
          <div className="mt-5 w-11/12">
            <Textarea placeholder={t.rating.internal.textPlaceholder} value={rating.internal.text} onChange={setInternal('text')} rows={4} />
            <div className="text-xs text-blue-primary">{t.rating.internal.textHint}</div>
          </div>
          <div className="flex justify-end mt-5">
            <NewButton text={t.rating.cta} type="submit" className="btn-secondary" />
          </div>
        </Form>
      )}
      {!!success && <div className="text-center text-gray-primary text-lg font-bold mt-5">{t.rating.success.submit}</div>}
      {!!receivedRating && !success && <div className="text-center text-gray-primary text-lg font-bold mt-5">{t.rating.alreadyRated}</div>}
    </MediumContainer>
  )
}

export default Rating
