import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { Design as DesignType } from '../../../../../types/Project'
import { GetData, PipelineComponent, PipelineState } from '../../../../UI/Pipeline/types'
import toast, { TYPE } from '../../../../../toast'
import SectionHeader from '../../../../UI/Pipeline/SectionHeader'
import { UPLOAD_TYPES } from '../../../../UI/DirectFileUpload/DirectFileUpload'
import ArtistPriceCalculator from '../ArtistPriceCalculator/ArtistPriceCalculator'
import ProjectImageUploadMask from '../../../../UI/ProjectImageUploadMask/ProjectImageUploadMask'
import { ArtistListItem, Request } from '../../../../../types/Request'

const Design: PipelineComponent<DesignType> = ({ pipelineStateProp, data, setData, NavigationForm, finalStepSubmit }) => {
  const t = useSelector((state) => state[props.TRANSLATION])
  const state: PipelineState<Request, { artist: ArtistListItem }> = useSelector((state) => state[props.PIPELINE])

  const set = (prop: keyof DesignType) => (value: any) => setData({ ...data, [prop]: value })

  const isWorkshop = (): boolean => isFinite(state.data.attendee?.amount)

  const getData: GetData = () => {
    if (Array.isArray(data.keys) && data.keys.length > 0) {
      const clearedKeys = data.keys.filter((k) => !!k)
      if (clearedKeys.length > 0) {
        return { ...data, keys: clearedKeys }
      } else {
        toast(TYPE.ERROR, t.request.create.design.error.image)
      }
    } else {
      toast(TYPE.ERROR, t.request.create.design.error.image)
    }
    return false
  }

  return (
    <div>
      <ArtistPriceCalculator />
      <NavigationForm pipelineStateProp={pipelineStateProp} getData={getData} finalStepSubmit={finalStepSubmit}>
        {isWorkshop() ? (
          <>
            <SectionHeader headline={t.request.create.design.workshop.title} description={t.request.create.design.workshop.description} />
            <div className="mt-6">
              <ProjectImageUploadMask uploadType={UPLOAD_TYPES.REQUEST_IMAGE} imageKeys={data.keys} callback={set('keys')} />
            </div>
          </>
        ) : (
          <>
            <SectionHeader headline={t.request.create.design.title} description={t.request.create.design.description} />
            <div className="mt-6">
              <ProjectImageUploadMask uploadType={UPLOAD_TYPES.REQUEST_IMAGE} imageKeys={data.keys} callback={set('keys')} />
            </div>
          </>
        )}
      </NavigationForm>
    </div>
  )
}

export default Design
