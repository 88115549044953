import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import GlobalStyle from './Global.Styled'
import { ThemeProvider } from 'styled-components'
import theme from './theme'
import Index from './components/Index/Index'
import Login from './components/Login/Login'
import Register from './components/Register/Register'
import Imprint from './components/Imprint/Imprint'
import DataProtection from './components/DataProtection/DataProtection'
import Admin from './components/Admin/Admin'
import SetNewPassword from './components/Login/LostPassword/SetNewPassword'
import NewMail from './components/Login/NewMail/NewMail'
import Artist from './components/Artist/Artist'
import Request from './components/Request/Request'
import Home from './components/Home/Home'
import Project from './components/Project/Project'

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Router>
          <Routes>
            {/* refactored routes */}
            {/* auth */}
            <Route index element={<Index />} />
            <Route path={'register/*'} element={<Register />} />
            <Route path={'login'} element={<Login />} />
            <Route path={'lost-password/set/:token'} element={<SetNewPassword />} />
            <Route path={'new-mail/confirm/:uuid'} element={<NewMail />} />

            {/* public */}
            <Route path={'artist/*'} element={<Artist />} />
            <Route path={'request/*'} element={<Request />} />
            <Route path={'project/*'} element={<Project />} />

            {/* artist/booker */}
            <Route path={'home/*'} element={<Home />} />

            {/* admin */}
            <Route path={'admin/*'} element={<Admin />} />

            {/* legal */}
            <Route path={'imprint'} element={<Imprint />} />
            <Route path={'data-protection'} element={<DataProtection />} />

            {/* fallback routing */}
            <Route path={'*'} element={<Navigate replace to="/" />} />
          </Routes>
        </Router>
        <ToastContainer autoClose={10000} />
      </ThemeProvider>
    </div>
  )
}

export default App
