import styled from 'styled-components'

export const StyledBanner = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 70vh;
  position: relative;
`

export const Overlay = styled.div`
  background-color: rgba(51, 204, 255, 0.5);
  width: 100%;
  padding: 10px 50px;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
    padding: 5px 10px;
  }
`

export const Headline = styled.h1`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.font.size.xlg};
  margin-bottom: 20px;
  font-family: 'extrabold';
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    margin-bottom: 10px;
    font-size: ${({ theme }) => theme.font.size.lg};
    line-height: 1.2;
  }
`

export const SubHeadline = styled.h2`
  color: ${({ theme }) => theme.color.font.secondary};
`

export const Button = styled.a`
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.blue.primary};
  color: ${({ theme }) => theme.color.font.secondary};
  font-family: 'light';
  padding: 15px 25px;
  border: 2px solid ${({ theme }) => theme.color.white};
  border-radius: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    padding: 10px 10px;
    width: 100%;
    text-align: center;
    flex: 1 1 0;
    white-space: nowrap;
  }
  transition: box-shadow 0.3s;
  &:hover {
    box-shadow: 0px 0px 15px 0px #ffffff;
  }
`
