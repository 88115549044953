import styled from 'styled-components'
import Link from '../../../UI/Link/Link'
import Text from '../../../UI/Text/Text'

export const StyledFooter = styled.div`
  margin-top: 100px;
`

export const Content = styled.div`
  background-color: ${({ theme }) => theme.color.blue.primary};
  padding: 100px 0 50px 0;
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    padding: 30px 0 100px 0;
  }
`

export const Column = styled.div`
  width: 20%;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: calc(50% - 25px / 2);
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: 100%;
  }
`

export const CustomLink = styled(Link)`
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ size, theme }) => theme.font.size.md};
  font-family: 'thin';
  cursor: pointer;
`

export const CustomText = styled(Text)`
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.color.lightblack};
`

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    justify-content: center;
    flex-direction: column;
    gap: 10px;
  }
`

export const Logo = styled.img`
  width: 50px;
  height: 50px;
`

export const Name = styled.div`
  font-size: ${({ theme }) => theme.font.size.md};
  color: ${({ theme }) => theme.color.white};
  font-family: 'thin';
  text-transform: uppercase;
`

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    gap: 10px;
    flex-direction: column;
  }
`

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    margin-top: 20px;
    justify-content: center;
  }
`

export const Icon = styled.img`
  height: 30px;
`

export const Header = styled.div`
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.font.size.sm};
  font-family: 'bold';
  margin-bottom: 10px;
`

export const Item = styled.div`
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.font.size.sm};
  font-family: 'light';
`

export const Categories = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 25px;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-wrap: wrap;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    flex-direction: column;
  }
`

export const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  gap: 25px;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-wrap: wrap;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    flex-direction: column;
    align-items: flex-start;
  }
`
