import { Route, Routes } from 'react-router-dom'
import Layout from '../UI/Layout/Layout'
import { Level } from '../../types/Auth'
import Artist from './Artist/Artist'
import Booker from './Booker/Booker'

const Register = () => {
  return (
    <Layout authLevelRequired={[Level.DEFAULT]} hideFooter>
      <Routes>
        <Route path={'artist/*'} element={<Artist />} />
        <Route path={'booker/*'} element={<Booker />} />
      </Routes>
    </Layout>
  )
}

export default Register
