import { Price } from './Pricing'
import { PricingAdditional, Project, Pricing } from './Project'
import { Artist, Pricing as ArtistPricing } from './Artist'
import { Booker } from './Booker'

export enum Primer {
  YES = 'YES',
  NO = 'NO',
  UNSURE = 'UNSURE',
}

export type BudgetExpectation = {
  askingPrice?: Price
  noAskingPrice?: boolean
}

export type ArtistListItem = Pick<Artist, 'uuid' | 'personalDetail' | 'profileImages' | 'insuranceDetail'> & {
  insuranceAcceptedByBooker: boolean
  createdOn: Date
  remainingDays?: number
  additional?: PricingAdditional
  discount?: number
} & {
  pricing: Pick<Pricing, 'additional' | 'discount'> & ArtistPricing
}

export type Request = Pick<
  Project,
  'uuid' | 'bookerUUID' | 'nameAndLocation' | 'tags' | 'sizeAndPrimer' | 'deadline' | 'design' | 'attendee' | 'createdOn'
> & {
  artistList?: ArtistListItem[]
  isDirectRequest: boolean
  budgetExpectation: BudgetExpectation
  alternativeBudget: Price
  insurance: { accepted: boolean }
  remainingDays: number
  booker?: Booker
}
