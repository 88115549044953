import { prettyTagsRaw } from '../../../../utility'
import { BackgroundImage, PersonImage } from './HeaderMask.Styled'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import LocationLabel from '../../LocationLabel/LocationLabel'
import RatingStars from '../../RatingStars/RatingStars'
import DirectFileUpload, { OnCallbackFile, UPLOAD_TYPES } from '../../DirectFileUpload/DirectFileUpload'
import { ArrowUpTrayIcon } from '@heroicons/react/24/solid'
import NewButton from '../../NewButton/NewButton'
import { Artist } from '../../../../types/Artist'
import Favorite from '../../../Artist/Favorite/Favorite'
import Button from '../../NewButton/NewButton'
import Carousel from '../../Carousel/Carousel'
import useIsBreakpoint, { Breakpoint } from '../../../hooks/useIsBreakpoint'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import { PurposeFlag } from '../../../../types/Auth'

type Props = {
  artist: Artist
  personImageKeyCallback?: (key: string) => void
  backgroundImageKeyCallback?: (key: string) => void
  addPublicElements?: boolean
  addPrivateElements?: boolean
  className?: string
  isGridView?: boolean
  isRegistrationMode?: boolean
}

type Data = {
  personImageURL?: string
  backgroundImageURL?: string
}

const ArtistHeaderMask: React.FC<Props> = ({
  artist,
  personImageKeyCallback,
  backgroundImageKeyCallback,
  addPublicElements = false,
  addPrivateElements = false,
  className = '',
  isGridView = false,
  isRegistrationMode = false,
}) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const tags = useSelector((s) => s[props.TAG])
  const isMobileView = useIsBreakpoint(Breakpoint.SM)
  const [data, setData] = useState<Data>({
    personImageURL: artist.profileImages.personImageURL,
    backgroundImageURL: artist.profileImages.backgroundImageURL,
  })
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])

  const updateBackgroundImage = (file: OnCallbackFile) => {
    setData({ ...data, backgroundImageURL: file.url })
    backgroundImageKeyCallback(file.key)
  }

  const updatePersonImage = (file: OnCallbackFile) => {
    setData({ ...data, personImageURL: file.url })
    personImageKeyCallback(file.key)
  }

  const isMyProfile = () => {
    if (!accountLink) return false
    return artist.uuid === accountLink.assignedToUUID
  }

  const isArtist = !!accountLink && accountLink.purposeFlag === PurposeFlag.ARTIST

  return (
    <div className={`rounded-3xl overflow-hidden shadow-artist-card md:shadow-md md:border-[0.1px] md:border-gray-secondary w-full ${className}`}>
      <BackgroundImage src={data.backgroundImageURL}>
        {!!backgroundImageKeyCallback && (
          <div
            className={`flex h-full w-full transition-all items-center justify-center ${
              data.backgroundImageURL && 'opacity-0 hover:opacity-100 bg-black bg-opacity-10'
            }`}
          >
            <DirectFileUpload
              uploadType={UPLOAD_TYPES.PROFILE_BACKGROUND_IMAGE}
              acceptedMimeTypes={['image/*']}
              onFilesCallback={updateBackgroundImage}
            >
              <ArrowUpTrayIcon className="w-14 text-white hover:scale-105 transition-all" />
            </DirectFileUpload>
          </div>
        )}
      </BackgroundImage>
      <div className="flex flex-row gap-0 md:flex-row md:gap-5 bg-white">
        <div className={`block relative w-fit p-2 md:w-[200px] shrink-0`}>
          <PersonImage src={data.personImageURL}>
            {!!personImageKeyCallback && (
              <div
                className={`flex h-full w-full transition-all items-center justify-center rounded-full ${
                  data.personImageURL && 'opacity-0 hover:opacity-100 bg-black bg-opacity-10'
                }`}
              >
                <DirectFileUpload uploadType={UPLOAD_TYPES.PROFILE_PERSON_IMAGE} acceptedMimeTypes={['image/*']} onFilesCallback={updatePersonImage}>
                  <ArrowUpTrayIcon className="w-10 text-white hover:scale-105 transition-all" />
                </DirectFileUpload>
              </div>
            )}
            {addPublicElements && <Favorite artistUUID={artist.uuid} init={artist.isFavorite} className="bottom-0 right-0 md:bottom-6" />}
          </PersonImage>
        </div>
        <div className="px-3 pl-0 md:px-0 py-2 md:pb-3 md:pl-2 relative">
          {!isRegistrationMode && (
            <div className="hidden md:flex !absolute -top-10 -left-[6px] items-center w-full gap-3">
              {addPrivateElements && (
                <Button
                  text={t.artist.profile.externalViewCTA}
                  href={`/artist/view/${artist.personalDetail.username}`}
                  className="btn-secondary border-2 !border-white"
                />
              )}
              {addPublicElements && (
                <NewButton
                  href={`/artist/view/${artist.personalDetail.username}`}
                  className="btn-secondary border-2 !border-white w-fit"
                  text={t.artist.profile.viewCTA}
                />
              )}
              {!isMyProfile() && !isArtist && (
                <NewButton
                  href={`/request/create/${artist.personalDetail.username}`}
                  className="btn-secondary-outline w-fit"
                  text={t.artist.profile.createRequestCTA}
                />
              )}
            </div>
          )}
          {addPublicElements && <div className="text-lg">{artist.personalDetail.username}</div>}
          {!addPublicElements && <div className="hidden md:block text-lg">{artist.personalDetail.username}</div>}
          <div className="flex flex-col gap-1 mt-[6px]">
            <div className="text-xs flex flex-wrap gap-1">
              {prettyTagsRaw(artist.skills.tagENUMList, tags).map((tag) => (
                <div key={tag} className="bg-blue-primary text-white px-3 inline-block rounded-full text-xs">
                  {tag}
                </div>
              ))}
            </div>
            <LocationLabel location={artist.locationDetail.location} />
            <div className={`mt-1 ${isRegistrationMode ? 'hidden md:block' : ''}`}>
              <RatingStars projectAmount={artist.rating.projectAmount} rating={artist.rating.rating} ratingAmount={artist.rating.ratingAmount} />
            </div>
          </div>
        </div>
      </div>
      {((!!artist.portfolio && artist.portfolio.keys.length > 0) || (isGridView && !isMobileView)) && (
        <div className={`pb-3 md:px-10 ${isGridView ? 'h-14 md:h-fit 2xl:h-24' : ''}`}>
          {artist.portfolio.keys.length > 0 && (
            <Carousel images={artist.portfolio.urls} itemsToShow={isGridView ? 3 : 4} itemsToShowMobile={3} smallArrows />
          )}
        </div>
      )}
      {!isRegistrationMode && (
        <div className="flex md:hidden items-center justify-center w-full gap-3 mb-3">
          {addPrivateElements && (
            <Button
              text={t.artist.profile.externalViewCTA}
              href={`/artist/view/${artist.personalDetail.username}`}
              className="btn-secondary border-2 !border-white"
            />
          )}
          {addPublicElements && (
            <NewButton
              href={`/artist/view/${artist.personalDetail.username}`}
              className="btn-secondary border-2 !border-white w-fit"
              text={t.artist.profile.viewCTA}
            />
          )}
          <NewButton
            href={`/request/create/${artist.personalDetail.username}`}
            className="btn-secondary-outline w-fit"
            text={t.artist.profile.createRequestCTA}
          />
        </div>
      )}
    </div>
  )
}

export default ArtistHeaderMask
