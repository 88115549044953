import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import SmallContainer from '../../../../UI/Container/SmallContainer'
import NewButton from '../../../../UI/NewButton/NewButton'
import { PipelineState } from '../../../../UI/Pipeline/types'
import { Artist } from '../../../../../types/Artist'

const Success: React.FC<{}> = () => {
  const state: PipelineState<Artist> = useSelector((s) => s[props.PIPELINE])
  const t = useSelector((s) => s[props.TRANSLATION])
  const accountLink = useSelector((s) => s[props.ACCOUNT_LINK])

  const isPrivateRequest = !!state?.additionalData?.['artist']

  return (
    <SmallContainer>
      {accountLink ? (
        <>
          <div>
            <div className={`pt-12 leading-7 text-lg font-bold mb-4 text-gray-primary text-center`}>
              {isPrivateRequest ? t.request.create.success.privateRequest.headline : t.request.create.success.publicRequest.headline}
            </div>
            <div className={`text-gray-primary text-md leading-5 font-light mt-2 text-center`}>
              {isPrivateRequest ? t.request.create.success.privateRequest.description : t.request.create.success.publicRequest.description}
            </div>
          </div>
          <NewButton
            text={isPrivateRequest ? t.request.create.success.privateRequest.cta : t.request.create.success.publicRequest.cta}
            href="/home/booker/request"
            className="mt-4 mx-auto w-fit"
          />
        </>
      ) : (
        <>
          <div>
            <div className={`pt-12 leading-7 text-lg font-bold mb-4 text-gray-primary text-center`}>
              {t.request.create.success.anonymousRequest.headline}
            </div>
            <div className={`text-gray-primary text-md leading-5 font-light mt-2 text-center`}>
              {isPrivateRequest
                ? t.request.create.success.anonymousRequest.privateDescription
                : t.request.create.success.anonymousRequest.publicDescription}
            </div>
          </div>
          <NewButton text={t.request.create.success.anonymousRequest.cta} href="/login" className="mt-4 mx-auto w-fit" />
        </>
      )}
    </SmallContainer>
  )
}

export default Success
