import { StyledText } from './Text.Styled'

type Props = {
  text: string
  size?: string
  color?: string
  align?: string
  font?: string
  margin?: string
  className?: string
  width?: string
  bold?: boolean
  onClick?: () => void
}

const Text: React.FC<Props> = ({ text, size, color, align, font, margin, className, width, bold, onClick }) => {
  return (
    <StyledText
      onClick={onClick}
      fontSize={size}
      color={color}
      align={align}
      font={font}
      margin={margin}
      className={className}
      width={width}
      bold={bold}
    >
      {text}
    </StyledText>
  )
}

export default Text
