import { useSelector } from 'react-redux'
import NewButton from '../../UI/NewButton/NewButton'
import props from '../../../redux/props'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import { PurposeFlag } from '../../../types/Auth'
import { PlusIcon } from '@heroicons/react/24/outline'

const CreateRequestButton = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])

  if (!!accountLink && accountLink.purposeFlag === PurposeFlag.ARTIST) return <></>
  return (
    <>
      <NewButton
        className="hidden md:flex w-fit btn-secondary-outline !px-10 !h-8"
        text={t.request.search.createNewGlobalCTA}
        href="/request/create"
      />
      <a
        href="/request/create"
        className="h-8 bg-magenta-secondary aspect-square rounded-full border border-magenta-secondary flex md:hidden justify-center items-center"
      >
        <PlusIcon className="h-6 w-6 text-white stroke-1" />
      </a>
    </>
  )
}

export default CreateRequestButton
