import styled from 'styled-components'
import { ArtistListItem } from '../../../../../types/Request'
import { Artist } from '../../../../../types/Artist'
import Icon from '../../../../UI/ProfileIcon/Icon'

const BackgroundImage = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  height: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
`

export const Name = styled.a<{ largeName: boolean }>`
  color: ${({ theme }) => theme.color.font.secondary};
  font-family: 'bold';
  width: fit-content;
  max-width: 50%;
  border: 2px solid ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme, largeName }) => (largeName ? theme.font.size.xlg : theme.font.size.lg)};
  padding: 0.1rem 15px;
  text-shadow: 0 0 0px ${({ theme }) => theme.color.magenta.primary}, 0 0 2px ${({ theme }) => theme.color.magenta.primary};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.md};
  }
`

type Props = { artist: Artist | ArtistListItem; hideName?: boolean; children?: React.ReactNode; largeName?: boolean }

const ArtistCard: React.FC<Props> = ({ artist, children, hideName = false, largeName = false }) => {
  const link = `/artist/view/${artist.personalDetail.username}`
  return (
    <div>
      <div className="h-20 w-full relative">
        <div className="relative h-full">
          <BackgroundImage src={artist.profileImages.backgroundImageURL} className="border border-gray-secondary" />
          {!hideName && (
            <Name
              href={link}
              className={`w-fit truncate backdrop-blur-sm rounded-sm absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2`}
              largeName={largeName}
            >
              {artist.personalDetail.username}
            </Name>
          )}
        </div>
        {!!children && <div className="absolute top-1/2 -translate-y-1/2 right-5">{children}</div>}
        <div className="rounded-full h-full absolute left-0 top-0">
          <a href={link}>
            <Icon artist={artist as Artist} className="!h-full border border-white" image={artist?.profileImages?.personImageURL} />
          </a>
        </div>
      </div>
    </div>
  )
}

export default ArtistCard
