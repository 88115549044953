import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { calculateSquareMeters, formatPrice, getPrettyDate, prettyLocation, s3PublicStoragePrefix } from '../../../../utility'
import SectionHeader from '../../../UI/Pipeline/SectionHeader'
import { Request } from '../../../../types/Request'
import PropertyList from '../../../UI/PropertyList/PropertyList'
import Property from '../../../UI/PropertyList/Property'
import { Property as PropertyType } from '../../../UI/PropertyList/types'
import Headline from '../../../UI/Headline/Headline'
import { Tag, additionalTags, additionalTagsArray } from '../../../../types/Tag'
import Carousel from '../../../UI/Carousel/Carousel'
import GrayBox from '../../../UI/Graybox/Graybox'
import Button from '../../../UI/NewButton/NewButton'

type Props = {
  request: Request
  ProfilePreview?: React.ReactNode
}

const DefaultProperties: React.FC<Props> = ({ request, ProfilePreview }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const tags = useSelector((s) => s[props.TAG])
  const accountLink = useSelector((s) => s[props.ACCOUNT_LINK])

  const getTagName = (tagEnum: string) => {
    const tag = [...tags, ...additionalTagsArray].find((t: Tag) => t.enum === tagEnum)
    return tag ? tag.name : ''
  }

  const showEquipmentAvailable = () => {
    if (request.tags.equipment.includes(additionalTags.UNSURE.enum) && request.tags.equipment.length === 1) {
      return false
    }
    return !request.tags.equipment.includes(additionalTags.NO_EQUIPMENT_REQUIRED.enum)
  }

  const getEquipmentValue = () => {
    if (request.tags.equipment.includes(additionalTags.UNSURE.enum)) {
      if (request.tags.equipment.length === 1) {
        return t.request.create.summary.default.properties.equipmentUnsure
      }
      return (
        request.tags.equipment
          .filter((e) => e !== additionalTags.UNSURE.enum)
          .map((e) => getTagName(e))
          .join(', ') + ` (${t.request.create.summary.default.properties.equipmentUnsure})`
      )
    }
    return request.tags.equipment.map((e) => getTagName(e)).join(', ')
  }

  const getPropertyList = (): PropertyType[] => {
    const propertyList: PropertyType[] = [
      { name: t.request.search.properties.title, value: request.nameAndLocation.name },
      { name: t.request.search.properties.location, value: prettyLocation(request.nameAndLocation.location) },
      { name: t.request.search.properties.object, value: getTagName(request.tags.object) },
      { name: t.request.search.properties.surface, value: getTagName(request.tags.surface) },
      { name: t.request.search.properties.equipment, value: getEquipmentValue() },
    ]
    if (showEquipmentAvailable()) {
      propertyList.push({
        name: t.request.search.properties.equipmentAvailable.name,
        value: request.tags.equipmentAvailable
          ? t.request.search.properties.equipmentAvailable.YES
          : t.request.search.properties.equipmentAvailable.NO,
      })
    }
    // if (_.has(request, 'insurance.accepted')) {
    //   propertyList.push({
    //     name: t.request.search.properties.insurance.name,
    //     value: request.insurance.accepted ? t.request.search.properties.insurance.YES : t.request.search.properties.insurance.NO,
    //   })
    // }
    propertyList.push(
      {
        name: t.request.search.properties.size,
        value: `${calculateSquareMeters(request.sizeAndPrimer.size.length, request.sizeAndPrimer.size.height)}${t.generic.squareMeter}`,
      },
      {
        name: t.request.search.properties.primer,
        value: t.request.primerOptions[request.sizeAndPrimer.primerRequired],
      },
      {
        name: t.request.search.properties.deadlineDate,
        value: getPrettyDate(new Date(request.deadline.date)),
      },
    )
    return propertyList
  }

  return (
    <div className="flex flex-col gap-0">
      <Headline>{t.request.view.headline}</Headline>
      {!!ProfilePreview && ProfilePreview}
      <SectionHeader headline={t.request.view.propertySection} />
      <PropertyList className="mt-3" properties={getPropertyList()} />
      {!!request.budgetExpectation && (
        <>
          <SectionHeader headline={t.request.search.properties.budgetExpectation.title} />
          <Property
            className="mt-3"
            property={{
              name: t.request.search.properties.budgetExpectation.total,
              value:
                request.budgetExpectation.noAskingPrice === true
                  ? t.request.search.properties.budgetExpectation.noAskingPrice
                  : formatPrice(request.budgetExpectation.askingPrice),
            }}
          />
        </>
      )}
      <SectionHeader
        headline={t.request.search.properties.design.title}
        description={`${t.request.search.properties.imagePrefix} ${request.design.keys.length}`}
        italicSubline
      />
      <div className="text-gray-primary my-2 text-sm">{request.design.text}</div>
      <Carousel
        className="mt-6"
        itemsToShow={2}
        itemsToShowMobile={2}
        smallArrows={true}
        images={request.design.keys.map((key) => `${s3PublicStoragePrefix}${key}`)}
      />
      {!accountLink && (
        <GrayBox className="w-full mt-10 border-[1px] border-gray-secondary bg-white">
          <div className="pb-3 text-center text-blue-primary font-bold">{t.request.search.loginHint}</div>
          <div className="text-center flex items-center justify-center">
            <Button text={t.request.search.loginCTA} href={'/login'} className="btn-secondary w-fit !text-sm" />
          </div>
        </GrayBox>
      )}
    </div>
  )
}

export default DefaultProperties
