import { Sizes } from '../../../../types/Size'
import { Pricing } from '../types'

const isNumber = (value: any) => typeof value === 'number' && isFinite(value) && value > 0

const art = (data: Pricing['art']) => {
  if (!data) return false
  return Object.values(Sizes).reduce((acc, size) => {
    if (!acc) return false
    return isNumber(data[size])
  }, true)
}

const primer = (data: Pricing['primer']) => {
  if (!data) return false
  if (data.disabled === true) return true
  return Object.values(Sizes).reduce((acc, size) => {
    if (!acc) return false
    return isNumber(data[size])
  }, true)
}

const concept = (data: Pricing['concept']) => {
  if (!data) return false
  return isNumber(data.price)
}

const workshop = (data: Pricing['workshop']) => {
  if (!data) return false
  return isNumber(data.pricePerAttendee) && isNumber(data.minAttendees)
}

const validators = {
  art,
  primer,
  concept,
  workshop,
}

export default validators
