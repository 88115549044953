import { useSelector } from 'react-redux'
import Loading from '../../../../UI/Loading/Loading'
import useListChats from '../../../../hooks/useListChats'
import props from '../../../../../redux/props'
import Headline from '../../../../UI/Headline/Headline'
import { Chat } from '../../../../../types/Chat'
import { useEffect } from 'react'
import Placeholder from './Placeholder'
import Single from './Single'

type Props = {
  initProjectUUID: string
  chat: Chat | false
  setChat: (chat: Chat | false) => void
  openChatAutomatically?: boolean
  className?: string
}

const Contacts: React.FC<Props> = ({ initProjectUUID, chat, setChat, openChatAutomatically = true, className = '' }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const chats = useListChats()

  useEffect(() => {
    if (initProjectUUID && !chat && chats) {
      const chat = chats.find((chat) => chat.projectUUID === initProjectUUID)
      if (chat) setChat(chat)
    } else if (!initProjectUUID && !chat && chats && chats.length > 0 && !!openChatAutomatically) {
      setChat(chats[0])
    } else if (!!chats && chats.length === 0 && chat !== false) {
      setChat(false)
    }
  })

  const noChats = () => !chats || chats.length === 0

  return (
    <div className={`flex flex-col ${className}`}>
      <Headline className={`h-20 shrink-0 ${noChats() ? 'text-gray-primary' : ''}`}>{t.chat.contacts.headline}</Headline>
      <Loading loading={chats} />
      {!noChats() && (
        <div className="flex flex-col gap-1 px-3 md:px-0">
          {chats.map((c) => {
            const isActive = c && !!chat ? c.projectUUID === chat?.projectUUID : false
            return <Single key={c.projectUUID} chat={c} isActive={isActive} setChat={setChat} />
          })}
        </div>
      )}
      {noChats() && <Placeholder />}
    </div>
  )
}

export default Contacts
