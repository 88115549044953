import { Navigate, Route, Routes } from 'react-router-dom'
import Layout from '../UI/Layout/Layout'
import Tags from './Tags/Tags'
import { Level } from '../../types/Auth'

const Register = () => {
  return (
    <Layout authLevelRequired={[Level.ADMIN]}>
      <Routes>
        <Route path={'tags'} element={<Tags />} />
        <Route path="*" element={<Navigate replace to="tags" />} />
      </Routes>
    </Layout>
  )
}

export default Register
