import { useSelector } from 'react-redux'
import MediumContainer from '../../../UI/Container/MediumContainer'
import Headline from '../../../UI/Headline/Headline'
import Loading from '../../../UI/Loading/Loading'
import useGetBooker from '../../../hooks/useGetBooker'
import DataSectionWrapper from '../../Components/DataSection/DataSectionWrapper'
import Mail from './Components/Mail'
import Password from './Components/Password'
import ProfileImages from './Components/ProfileImages'
import Notification from './Components/Notification'
import props from '../../../../redux/props'
import Billing from './Components/Billing'

const Settings: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [booker, setBookerProperty] = useGetBooker()

  return (
    <MediumContainer className="py-3">
      <Headline className="mb-3">{t.booker.settings.title}</Headline>
      <Loading loading={booker} />
      {booker && (
        <div className="flex flex-col gap-3">
          <DataSectionWrapper>
            <ProfileImages booker={booker} onChangeCallback={setBookerProperty('profileImages')} />
            <Billing booker={booker} onChangeCallback={setBookerProperty('billingDetail')} />
          </DataSectionWrapper>
          <DataSectionWrapper>
            <Mail booker={booker} />
            <Password booker={booker} />
          </DataSectionWrapper>
          <DataSectionWrapper>
            <Notification booker={booker} onChangeCallback={setBookerProperty('profileImages')} />
          </DataSectionWrapper>
        </div>
      )}
    </MediumContainer>
  )
}

export default Settings
