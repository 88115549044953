import GridView from '../../UI/GridView/GridView'
import NewButton from '../../UI/NewButton/NewButton'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { prettyLocation } from '../../../utility'
import PropertyList from '../../UI/PropertyList/PropertyList'
import { Property } from '../../UI/PropertyList/types'
import { Project, VerifiedBy } from '../../../types/Project'
import { PurposeFlag } from '../../../types/Auth'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import internVerified from '../../../assets/image/project/verified-intern.png'
import externVerified from '../../../assets/image/project/verified-extern.png'
import Favorite from '../Favorite/Favorite'
import useHasNotification from '../../hooks/useHasNotification'
import { Type } from '../../../types/Notification'
import GetIcon from '../../UI/GetIcon/GetIcon'

type Props = {
  project: Project
  minifiedView?: boolean
  linkToInternalView?: boolean
  dynamicView?: boolean
  isGridView?: boolean
}

const ProjectGridView: React.FC<Props> = ({ project, minifiedView = false, linkToInternalView = false, dynamicView = false, isGridView = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])
  const hasAddOnNotification = useHasNotification(Type.PROJECT_ADD_ON, { projectUUID: project.uuid })
  const hasCompletionNotification = useHasNotification(Type.PROJECT_COMPLETION, { projectUUID: project.uuid })
  const hasRatingNotification = useHasNotification(Type.PROJECT_RATING, { projectUUID: project.uuid })

  const getPropertyList = (): Property[] => {
    const propertyList: Property[] = [{ name: t.project.search.properties.location, value: prettyLocation(project.nameAndLocation.location) }]
    if (!!project.sizeAndPrimer) {
      propertyList.push({
        name: t.project.search.properties.size,
        value: `${project.sizeAndPrimer.sizeInSquareMeters}${t.generic.squareMeter}`,
      })
    }
    if (!!project.attendee?.amount) {
      propertyList.push({
        name: t.project.search.properties.attendee.amount,
        value: `${project.attendee.amount}${t.project.search.properties.attendee.suffix}`,
      })
    }
    return propertyList
  }

  const isExternalUnverifiedProject = project.verification.verifiedBy === VerifiedBy.EXTERNAL && project.verification.isVerifiedByClient === false
  const isInternVerifiedProject = project.verification.verifiedBy === VerifiedBy.QSELLART
  const isExternVerifiedProject = project.verification.verifiedBy === VerifiedBy.EXTERNAL && project.verification.isVerifiedByClient === true

  const getCTALink = (): string => {
    if (linkToInternalView) {
      if (accountLink.purposeFlag === PurposeFlag.ARTIST) {
        return `/home/artist/project/view/${project.uuid}`
      } else {
        return `/home/booker/project/view/${project.uuid}`
      }
    }
    return `/project/view/${project.uuid}`
  }

  const isHighlighted = () => {
    return (hasAddOnNotification || hasCompletionNotification || hasRatingNotification) && linkToInternalView
  }

  const content = (
    <>
      <div className={`hidden md:block absolute bottom-2 h-fit ${minifiedView ? 'w-11/12' : 'w-full px-3'}`}>
        <div className="border border-white bg-white h-fit bg-opacity-60 rounded-md">
          <div className="flex flex-col gap-2 m-2">
            <div className={`flex flex-row gap-2 ${dynamicView ? 'hidden group-hover:flex' : ''}`}>
              <div className={`bg-white bg-opacity-70 rounded-md p-1 border border-gray-secondary w-4/6`}>
                <PropertyList className="box-border" properties={getPropertyList()} contrast small splitInHalf />
              </div>
              <div className="bg-white bg-opacity-70 rounded-md p-1 border border-gray-secondary w-2/6 flex flex-row gap-1 xlg:gap-3 items-center justify-center">
                {!!project.rating ? (
                  <>
                    <GetIcon name={'star-filled'} className={'h-6  fill-yellow-primary'} />
                    <div className={`font-bold ${isGridView ? 'text-lg' : 'text-xlg'} italic text-gray-primary`}>{project.rating.publicScore}</div>
                  </>
                ) : (
                  <div className="text-light-black text-sm">{t.project.search.ratingReplacementText}</div>
                )}
              </div>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <div className={`${isExternalUnverifiedProject ? 'w-full' : 'w-4/6'}`}>
                {isExternalUnverifiedProject && (
                  <div className="bg-white bg-opacity-70 rounded-md p-1 border border-gray-secondary w-full text-center text-xs text-black-secondary">{`${t.project.externalVerification.status.unverified.prefix}${project.verification.verifiedByDomain}${t.project.externalVerification.status.unverified.suffix}`}</div>
                )}
                {isExternVerifiedProject && (
                  <div className="bg-white bg-opacity-70 rounded-md p-1 border border-gray-primary w-full text-center text-xs text-black-secondary font-bold">{`${t.project.externalVerification.status.verified.prefix}${project.verification.verifiedByDomain}${t.project.externalVerification.status.verified.suffix}`}</div>
                )}
                {isInternVerifiedProject && (
                  <div className="text-white bg-blue-primary bg-opacity-70 border border-white rounded-md font-bold text-xs flex items-center justify-center text-center leading-5 h-8">
                    {t.project.internalVerification.statusText}
                  </div>
                )}
              </div>
              {!isExternalUnverifiedProject && (
                <div className="w-2/6">
                  <NewButton className="btn btn-secondary !outline mx-auto" text={t.project.search.goTo} href={getCTALink()} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={`md:hidden absolute bottom-2 h-fit ${minifiedView ? 'w-11/12' : 'w-full px-12'}`}>
        <div className="flex flex-col gap-2 m-2">
          <div className="flex flex-row gap-2 items-center">
            {!isExternalUnverifiedProject && (
              <NewButton className="btn btn-secondary !outline mx-auto w-fit" text={t.project.search.goTo} href={getCTALink()} />
            )}
          </div>
        </div>
      </div>
      {!minifiedView && (
        <>
          {isInternVerifiedProject && (
            <div className="absolute top-[11px] left-3 tooltip" data-tip={t.project.verified.intern}>
              <img src={internVerified} className="h-7 md:h-8" alt="" />
            </div>
          )}
          {isExternVerifiedProject && (
            <div className="absolute top-[11px] left-3 tooltip" data-tip={t.project.verified.extern}>
              <img src={externVerified} className="h-7 md:h-8" alt="" />
            </div>
          )}
        </>
      )}
      <Favorite initIsFavorite={project.isFavorite} initCount={project.favoriteCount} projectUUID={project.uuid} className="top-[11px] right-3" />
    </>
  )

  return (
    <>
      <GridView
        title={project.nameAndLocation.name}
        images={project.design.urls}
        enableFullHeightContent
        highlight={isHighlighted()}
        className="group md:hidden"
        hideSmallImages
      >
        {content}
      </GridView>
      <GridView
        title={project.nameAndLocation.name}
        images={project.design.urls}
        enableFullHeightContent
        highlight={isHighlighted()}
        className="group hidden md:flex"
      >
        {content}
      </GridView>
    </>
  )
}

export default ProjectGridView
