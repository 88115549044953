import { Project, Status } from '../../../../../../types/Project'
import GrayBox from '../../../../../UI/Graybox/Graybox'
import Headline from '../../../../../UI/Headline/Headline'
import { useSelector } from 'react-redux'
import props from '../../../../../../redux/props'
import Title from '../../../../../UI/Graybox/Title'
import NewButton from '../../../../../UI/NewButton/NewButton'
import useGetLastChatMessage, { Result } from '../../../../../hooks/useGetLastChatMessage'
import Message from '../../../../Components/Chat/Messages/Message/Message'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import { PurposeFlag } from '../../../../../../types/Auth'
import chatPlaceholder from '../../../../../../assets/image/project/chat-placeholder.png'

type Props = {
  project: Project
}

const ChatPreview: React.FC<Props> = ({ project }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const result: Result = useGetLastChatMessage(project.uuid)
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])

  const isArtist = accountLink?.purposeFlag === PurposeFlag.ARTIST

  const getBookerName = () => {
    return !!project.booker.billingDetail.companyName
      ? project.booker.billingDetail.companyName
      : `${project.booker.billingDetail.firstname || ''} ${project.booker.billingDetail.lastname || ''}`
  }

  return (
    <div className="mt-5">
      <Headline className="mb-2 text-md">
        {t.project.view.chatPreview.headlinePrefix}
        {isArtist ? getBookerName() : project.artist.personalDetail.username}
      </Headline>
      <GrayBox href={!!result ? `/home/${isArtist ? 'artist' : 'booker'}/chat/${project.uuid}` : null} className="pb-14 md:pb-7">
        <Title title={t.project.view.chatPreview.title} className="mb-0" />
        {project.status === Status.PENDING_DOWNPAYMENT ? (
          <div className="flex flex-col justify-center gap-2 pb-5">
            <img src={chatPlaceholder} alt="chat placeholder" className="h-12 mx-auto" />
            <p className="text-center text-gray-primary text-sm">{t.project.view.chatPreview.waitingForDownpayment}</p>
          </div>
        ) : (
          <>
            <>
              {!result?.message && (
                <div className="flex flex-col justify-center gap-2">
                  <img src={chatPlaceholder} alt="chat placeholder" className="h-12 mx-auto" />
                  <p className="text-center text-gray-primary text-sm">{t.project.view.chatPreview.noMessages}</p>
                </div>
              )}
              {!!result?.message && (
                <div className="flex justify-center">
                  <div className="w-full lg:w-[50%]">
                    <Message chat={result.chat} message={result.message} previewMode />
                  </div>
                </div>
              )}
            </>
            <NewButton
              className="hidden md:flex btn-secondary ml-auto w-fit mt-2"
              text={t.project.view.chatPreview.goTo}
              href={`/home/${isArtist ? 'artist' : 'booker'}/chat/${project.uuid}`}
            />
            <div className="md:hidden flex items-center justify-center  gap-2 absolute bottom-4 w-full left-0">
              <NewButton
                className="btn-secondary !text-2xs w-fit !h-6 md:!h-5 mx-auto"
                text={t.project.view.chatPreview.goTo}
                href={`/home/${isArtist ? 'artist' : 'booker'}/chat/${project.uuid}`}
              />
            </div>
          </>
        )}
      </GrayBox>
    </div>
  )
}

export default ChatPreview
