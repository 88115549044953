import { useSelector } from 'react-redux'
import { Pricing } from '../../../../../types/Artist'
import props from '../../../../../redux/props'
import SizeAndPrice from '../../../../UI/Pricing/Editable/SizeAndPrice'
import { formatWorkshopPricePerAttendee } from '../../../../../utility'

type Props = {
  data: Pricing['workshop']
  noTitle?: boolean
}

const Workshop: React.FC<Props> = ({ data, noTitle = false }) => {
  const t = useSelector((state) => state[props.TRANSLATION])

  return (
    <div>
      {!noTitle && <div className="font-bold mb-5">{t.register.artist.pricing.workshop.headline}</div>}
      <div className="flex flex-col items-center md:items-start">
        <SizeAndPrice
          required
          size={t.artist.search.view.pricing.workshopSizeText}
          price={formatWorkshopPricePerAttendee(data.pricePerAttendee)}
          readOnly
          noUnit
        />
      </div>
    </div>
  )
}

export default Workshop
