import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import Title from '../../../UI/Graybox/Title'

const Story = ({ artist }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div className="md:w-1/2 lg:ml-12">
      <Title title={t.artist.search.view.story.title} className="text-lg" />
      <div className="whitespace-pre-wrap break-words mt-3 leading-5 lg:text-justify">{artist.story.story}</div>
    </div>
  )
}

export default Story
