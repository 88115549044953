import { useEffect, useState } from 'react'
import { StyledControls } from './Controls.Styled'
import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { FinalStepSubmit, PipelineState } from '../../types'
import Button, { Variant } from './Button/Button'
import useGoPrevious from '../useGoPrevious'

type Props<D> = {
  state: PipelineState<D>
  finalStepSubmit?: FinalStepSubmit<D>
}

const Controls = <D,>({ finalStepSubmit }: Props<D>) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [scrollValue, setScrollValue] = useState(0)
  const goPrevious = useGoPrevious()

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight, clientWidth } = document.body
      const footerHeight = clientWidth > 992 ? 317 : 450
      if (scrollHeight - clientHeight - scrollTop < footerHeight) {
        const newScrollValue = (scrollHeight - clientHeight - scrollTop - footerHeight) * -1
        if (newScrollValue !== scrollValue) setScrollValue(newScrollValue)
      }
    }

    document.body.addEventListener('scroll', handleScroll)
    return () => {
      document.body.removeEventListener('scroll', handleScroll)
    }
  }, [scrollValue])

  return (
    <StyledControls scrollValue={scrollValue}>
      {goPrevious && <Button variant={Variant.PREVIOUS} text={t.pipeline.navigation.previous} onClick={goPrevious} />}
      {goPrevious && (
        <Button variant={Variant.NEXT} text={finalStepSubmit ? t.pipeline.navigation.finish : t.pipeline.navigation.next} type={'submit'} />
      )}
      {!goPrevious && (
        <Button
          className="ml-[54px]"
          variant={Variant.NEXT}
          text={finalStepSubmit ? t.pipeline.navigation.finish : t.pipeline.navigation.next}
          type={'submit'}
        />
      )}
    </StyledControls>
  )
}

export default Controls
