import { useSelector } from 'react-redux'
import MediumContainer from '../../../UI/Container/MediumContainer'
import Headline from '../../../UI/Headline/Headline'
import props from '../../../../redux/props'
import DataSectionWrapper from '../../Components/DataSection/DataSectionWrapper'
import Statistics from './Statistics/Statistics'
import QRCode from './QRCode/QRCode'
import Calendar from './Calendar/Calendar'
import Favorites from './Favorites/Favorites'
import Recommendation from './Recommendation/Recommendation'
import Progress from '../Settings/Components/Progress'
import useGetArtist from '../../../hooks/useGetArtist'

const Dashboard: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [artist] = useGetArtist()

  return (
    <MediumContainer className="py-3">
      <Headline className="mb-3">{t.artist.dashboard.title}</Headline>
      <Progress artist={artist} />
      <div className="flex flex-col gap-3">
        <DataSectionWrapper>
          <Statistics />
          <QRCode />
        </DataSectionWrapper>
        <DataSectionWrapper>
          <Calendar />
        </DataSectionWrapper>
        <DataSectionWrapper>
          <Favorites />
        </DataSectionWrapper>
        <DataSectionWrapper>
          <Recommendation />
        </DataSectionWrapper>
      </div>
    </MediumContainer>
  )
}

export default Dashboard
