import { useSelector } from 'react-redux'
import PropertyList from '../../../../../UI/PropertyList/PropertyList'
import { Property } from '../../../../../UI/PropertyList/types'
import { Project } from '../../../../../../types/Project'
import props from '../../../../../../redux/props'
import { getPrettyDate, prettyLocation } from '../../../../../../utility'

type Props = {
  project: Project
}

const DefaultProperties: React.FC<Props> = ({ project }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const getPropertyList = (): Property[] => {
    const propertyList: Property[] = [
      { name: t.project.search.properties.title, value: project.nameAndLocation.name },
      { name: t.project.search.properties.location, value: prettyLocation(project.nameAndLocation.location) },
      {
        name: t.project.search.properties.attendee.amount,
        value: `${project.attendee.amount}${t.project.search.properties.attendee.suffix}`,
      },
      {
        name: t.project.search.properties.workshopDate,
        value: getPrettyDate(new Date(project.deadline.date)),
      },
    ]
    return propertyList
  }

  return <PropertyList className="mt-3" properties={getPropertyList()} />
}

export default DefaultProperties
