type Props = {
  children: React.ReactNode
  className?: string
  innerClassName?: string
}

const SmallContainer: React.FC<Props> = ({ children, className = '', innerClassName = '' }) => {
  return (
    <div className={`w-full max-w-lg mx-auto ${className}`}>
      <div className={`px-3 sm:px-6 ${innerClassName}`}>{children}</div>
    </div>
  )
}

export default SmallContainer
