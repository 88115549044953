import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { useState } from 'react'
import GetIcon from '../GetIcon/GetIcon'

type Props = {
  projectAmount?: number
  rating: number
  ratingAmount?: number
  interactiveCallback?: (rating: number) => void
}

const RatingStars: React.FC<Props> = ({ projectAmount, rating, ratingAmount, interactiveCallback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [internalRating, setInternalRating] = useState<number>(rating)

  const starIsFilled = (index: number) => {
    if (index <= internalRating - 1) return true
    if ((internalRating - index) % 1 >= 0.5) return true
    return false
  }

  const hover = (index: number) => () => {
    if (!!interactiveCallback) {
      setInternalRating(index + 1)
    }
  }

  const click = (index: number) => () => {
    if (!!interactiveCallback) {
      interactiveCallback(index + 1)
    }
  }

  return (
    <div className="flex flex-row items-center gap-4">
      {isFinite(projectAmount) && (
        <div className="text-[12px] font-bold">
          {projectAmount}
          {t.artist.profile.rating.projectAmountSuffix}
        </div>
      )}
      <div className="flex flex-row items-center gap-2">
        <div className="flex flex-row gap-[2px]">
          {[...Array(5)].map((s, i) => (
            <div key={i}>
              <GetIcon
                name={starIsFilled(i) ? 'star-filled' : 'star-not-filled'}
                onMouseEnter={hover(i)}
                onMouseLeave={() => setInternalRating(rating)}
                onClick={click(i)}
                className={`${!!interactiveCallback ? 'h-8 cursor-pointer' : 'h-3'} fill-yellow-primary`}
              />
            </div>
          ))}
        </div>
        {isFinite(ratingAmount) && <div className="text-[12px]">{`(${ratingAmount}${t.artist.profile.rating.ratingAmountSuffix})`}</div>}
      </div>
    </div>
  )
}

export default RatingStars
