import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { getUUID } from '../../../utility'
import { Overlay, Headline, SubHeadline, Button, StyledBanner } from './Banner.Styled'

import { useEffect, useState } from 'react'
import randomBackgroundImage from './randomBackgroundImage'
import Container from '../../UI/Container/Container'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import { PurposeFlag } from '../../../types/Auth'

const Banner = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const [index, setIndex] = useState(null)
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])

  useEffect(() => {
    if (isNaN(parseInt(index))) setIndex(Math.floor(Math.random() * 4))
  }, [index])

  const getRandomText = translation.home.banner.texts[Math.floor(Math.random() * 4)]

  const createRequestLink = () => {
    const isBooker = !!accountLink && accountLink.purposeFlag === PurposeFlag.BOOKER
    return isBooker || !accountLink ? '/request/create' : '/request'
  }

  if (index === null) return <></>

  return (
    <StyledBanner src={randomBackgroundImage()}>
      <div className="absolute w-full bottom-8 justify-center">
        <Container>
          <div className="flex flex-col gap-7 w-full md:w-fit mx-auto 2xl:mx-0 items-center">
            <Overlay>
              <Headline>{getRandomText.headline}</Headline>
              {getRandomText.slogan.map((s) => (
                <SubHeadline key={getUUID()}>{s}</SubHeadline>
              ))}
            </Overlay>
            <div className="flex w-full flex-row gap-3 md:gap-7 justify-center 2xl:justify-start">
              <Button className="" href={createRequestLink()}>
                {translation.home.banner.buttonStart}
              </Button>
              <Button className="" href="/project">
                {translation.home.banner.buttonArtist}
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </StyledBanner>
  )
}

export default Banner
