import { Artist } from '../../../types/Artist'
import { ArtistListItem, Request } from '../../../types/Request'
import { Offer } from '../../../types/Offer'
import DefaultRequestWithArtist from './DefaultRequest/WithArtist'
import WorkshopRequestWithArtist from './WorkshopRequest/WithArtist'
import DefaultOfferWithArtist from './Offer/ForDefaultRequest'
import WorkshopOfferWithArtist from './Offer/ForWorkshopRequest'
import DefaultProject from './Project/Default'
import WorkshopProject from './Project/Workshop'
import { Project } from '../../../types/Project'
import _ from 'lodash'

export enum PriceCalculatorType {
  REQUEST = 'REQUEST',
  OFFER = 'OFFER',
  PROJECT = 'PROJECT',
}

type Props = {
  type: PriceCalculatorType
  artist?: Artist | ArtistListItem
  request?: Request
  project?: Project
  offer?: Offer
  setOffer?: (data: Offer) => void
}

const PriceCalculator: React.FC<Props> = ({ type, artist, request, project, offer, setOffer }) => {
  // calculators for request
  if (type === PriceCalculatorType.REQUEST) {
    const isWorkshop = _.has(request, 'attendee.amount') && isFinite(request.attendee.amount) && request.attendee.amount > 0
    if (isWorkshop) {
      return <WorkshopRequestWithArtist artist={artist as ArtistListItem} request={request} />
    }
    return <DefaultRequestWithArtist artist={artist as ArtistListItem} request={request} />

    // calculators for offer
  } else if (type === PriceCalculatorType.OFFER) {
    const isWorkshop = _.has(request, 'attendee.amount') && isFinite(request.attendee.amount) && request.attendee.amount > 0
    if (isWorkshop) {
      return <WorkshopOfferWithArtist artist={artist as ArtistListItem} request={request} offer={offer} setOffer={setOffer} />
    }
    return <DefaultOfferWithArtist artist={artist as ArtistListItem} request={request} offer={offer} setOffer={setOffer} />

    // calculators for project
  } else if (type === PriceCalculatorType.PROJECT) {
    const isWorkshop = _.has(project, 'attendee.amount') && isFinite(project.attendee.amount) && project.attendee.amount > 0
    if (isWorkshop) {
      return <WorkshopProject project={project} />
    }
    return <DefaultProject project={project} />
  }
  return <></>
}

export default PriceCalculator
