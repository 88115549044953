import { useSelector } from 'react-redux'
import NewButton from '../../UI/NewButton/NewButton'
import props from '../../../redux/props'
import { useEffect, useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import Layout from '../../UI/Layout/Layout'
import MediumContainer from '../../UI/Container/MediumContainer'
import { useParams } from 'react-router-dom'
import SectionHeader from '../../UI/Pipeline/SectionHeader'

const NewMail: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [success, setSuccess] = useState(null)
  const { uuid } = useParams()
  const confirm = functional.use(Routes.CONFIRM_NEW_MAIL)
  const accountLink = useSelector((s) => s[props.ACCOUNT_LINK])

  useEffect(() => {
    const confirmNewMail = async () => {
      const result = await confirm({ uuid })
      setSuccess(!!result)
    }
    if (!!uuid && success === null) {
      confirmNewMail()
    }
  }, [uuid, confirm, success])

  return (
    <Layout authLevelRequired={false}>
      <MediumContainer className="pt-5">
        <div className="flex flex-col justify-center items-center">
          <SectionHeader
            headline={t.newMailConfirm.title}
            description={t.newMailConfirm.description}
            className="text-center"
            classNameDescription="text-center"
          />
          {success === true && (
            <>
              <div className="text-sm">{t.newMailConfirm.success}</div>
              {!accountLink && <NewButton text={t.register.success.loginCTA} href="/login" className="mt-4 w-fit mx-auto" />}
            </>
          )}
          {success === false && <div className="text-sm">{t.newMailConfirm.error}</div>}
        </div>
      </MediumContainer>
    </Layout>
  )
}

export default NewMail
