import { MilestoneView } from '../types'
import Milestone from '../Milestone/Milestone'
import Upsert from '../Milestone/Upsert'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { getUUID } from '../../../../utility'
import { Status } from '../../../../types/Project'
import { MilestoneType } from '../../../../types/Milestone'

const Full: MilestoneView = ({ project, milestones, isArtist, updateListCallback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const hasOpenAddOn = milestones.some((m) => m.type === MilestoneType.PROJECT_ADD_ON && !m.isCompleted)

  return (
    <div className="mx-2 md:mx-0">
      {milestones.map((m) => (
        <Milestone
          key={m.uuid}
          project={project}
          milestone={m}
          isArtist={isArtist}
          updateListCallback={updateListCallback}
          hasOpenAddOn={hasOpenAddOn}
          milestones={milestones}
        />
      ))}
      {project.status === Status.PENDING_DOWNPAYMENT && (
        <>
          {!!isArtist && (
            <div className="rounded-md flex flex-col gap-2 bg-black-secondary p-5 mt-20">
              <div className="text-xs md:text-sm text-white" key={getUUID()}>
                {t.milestone.help.artist}
              </div>
            </div>
          )}
        </>
      )}
      {project.status === Status.IN_PROGRESS && (
        <>
          {!!isArtist ? (
            <div className="mt-20">
              <Upsert projectUUID={project.uuid} lastIndex={milestones.length} updateListCallback={updateListCallback} />
            </div>
          ) : (
            <div className="rounded-md flex flex-col gap-2 bg-black-secondary p-5 mt-20">
              {t.milestone.upsert.help.booker.map((t) => (
                <div className="text-xs md:text-sm text-white" key={getUUID()}>
                  {t}
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default Full
