// import { useSelector } from 'react-redux'
// import { StyledFooter, Content, Column, LogoWrapper, Logo, Name, CustomLink, LinkWrapper, IconsWrapper, Icon, Header, Item, Categories, BottomRow } from './Footer.Styled'
import { StyledFooter, Content, Column, LogoWrapper, Logo, Name, CustomLink, LinkWrapper, IconsWrapper, Icon, BottomRow } from './Footer.Styled'
import Container from '../../Container/Container'
import props from '../../../../redux/props'
import Wave from '../../Wave/Wave'
import { useSelector } from 'react-redux'
import logo from '../../../../assets/image/footer/logo-monochrom.png'
import tiktokIcon from '../../../../assets/image/footer/tiktok.png'
import instagramIcon from '../../../../assets/image/footer/instagram.png'
import facebookIcon from '../../../../assets/image/footer/facebook.png'
import { s3PublicStoragePrefix } from '../../../../utility'
import GetIcon from '../../GetIcon/GetIcon'

const Footer = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  const agb = () => {
    window.open(s3PublicStoragePrefix + 'agb/Q_AGB_120924.pdf', '_blank')
  }

  return (
    <StyledFooter>
      <Wave />
      <Content>
        <Container>
          {/* <Categories>
            <Column>
              <Header>{translation.footer.category}</Header>
              <Item>volescim</Item>
              <Item>sam qui</Item>
              <Item>quaeperum</Item>
              <Item>lacestrum la</Item>
              <Item>plique</Item>
              <Item>dici quo</Item>
              <Item>molore</Item>
              <Item>ipsapel min</Item>
              <Item>prate</Item>
              <Item>magnimus mos</Item>
              <Item>ea intiatqui i</Item>
              <Item>nonemque</Item>
            </Column>
            <Column>
              <Header>{translation.footer.about}</Header>
              <Item>volescim</Item>
              <Item>sam qui</Item>
              <Item>quaeperum</Item>
              <Item>lacestrum la</Item>
              <Item>plique</Item>
              <Item>dici quo</Item>
              <Item>molore</Item>
              <Item>ipsapel min</Item>
              <Item>prate</Item>
              <Item>magnimus mos</Item>
              <Item>ea intiatqui i</Item>
              <Item>nonemque</Item>
            </Column>
            <Column>
              <Header>{translation.footer.support}</Header>
              <Item>volescim</Item>
              <Item>sam qui</Item>
              <Item>quaeperum</Item>
              <Item>lacestrum la</Item>
              <Item>plique</Item>
              <Item>dici quo</Item>
              <Item>molore</Item>
              <Item>ipsapel min</Item>
              <Item>prate</Item>
              <Item>magnimus mos</Item>
              <Item>ea intiatqui i</Item>
              <Item>nonemque</Item>
            </Column>
            <Column>
              <Header>{translation.footer.community}</Header>
              <Item>volescim</Item>
              <Item>sam qui</Item>
              <Item>quaeperum</Item>
              <Item>lacestrum la</Item>
              <Item>plique</Item>
              <Item>dici quo</Item>
              <Item>molore</Item>
              <Item>ipsapel min</Item>
              <Item>prate</Item>
              <Item>magnimus mos</Item>
              <Item>ea intiatqui i</Item>
              <Item>nonemque</Item>
            </Column>
            <Column>
              <Header>{translation.footer.more}</Header>
              <Item>volescim</Item>
              <Item>sam qui</Item>
              <Item>quaeperum</Item>
              <Item>lacestrum la</Item>
              <Item>plique</Item>
              <Item>dici quo</Item>
              <Item>molore</Item>
              <Item>ipsapel min</Item>
              <Item>prate</Item>
              <Item>magnimus mos</Item>
              <Item>ea intiatqui i</Item>
              <Item>nonemque</Item>
            </Column>
          </Categories> */}
          <BottomRow>
            <Column>
              <LogoWrapper>
                <Logo src={logo} />
                <Name>{translation.footer.name}</Name>
              </LogoWrapper>
            </Column>

            <Column>
              <LinkWrapper>
                <CustomLink text={translation.footer.imprint} href="/imprint" />
                <CustomLink text={translation.footer.privacy} href="/data-protection" />
                <CustomLink text={translation.footer.agb} onClick={agb} />
              </LinkWrapper>
            </Column>
            <Column>
              <IconsWrapper>
                <a href="https://www.instagram.com/qsellart_official/" target="_blank" rel="noreferrer">
                  <Icon src={instagramIcon} />
                </a>
                <a href="https://x.com/qsellart" target="_blank" rel="noreferrer">
                  <GetIcon name={'x'} className="fill-white h-[30px] shrink-0" />
                </a>
                <a href="https://www.facebook.com/qsellart" target="_blank" rel="noreferrer">
                  <Icon src={facebookIcon} />
                </a>
                <a href="https://www.tiktok.com/@qsellart" target="_blank" rel="noreferrer">
                  <Icon src={tiktokIcon} />
                </a>
              </IconsWrapper>
            </Column>
          </BottomRow>
        </Container>
      </Content>
    </StyledFooter>
  )
}

export default Footer
