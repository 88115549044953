import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { Milestone } from '../../../../types/Milestone'
import NewModal from '../../../UI/NewModal/NewModal'
import { useState } from 'react'
import trash from '../../../../assets/image/milestone/trash.png'
import Routes from '../../../../redux/routes'
import { functional } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../../../toast'
import { UpdateListCallback } from '../types'

type Props = {
  milestone: Milestone
  updateListCallback: UpdateListCallback
}

const Remove: React.FC<Props> = ({ milestone, updateListCallback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState<boolean>(false)
  const removeMilestone = functional.use(Routes.REMOVE_MILESTONE_OF_PROJECT)

  const toggle = () => setOpen(!open)

  const trigger = async () => {
    const milestones: Milestone[] = await removeMilestone({ uuid: milestone.uuid })
    if (milestones) {
      updateListCallback(milestones)
      toast(TYPE.SUCCESS, t.milestone.remove.success)
    } else {
      toast(TYPE.SUCCESS, t.milestone.remove.error)
    }
  }

  if (!milestone.isDeletable) return <></>
  return (
    <div className={`w-6 justify-center items-center shrink-0 flex`}>
      <img onClick={toggle} src={trash} className="h-6 cursor-pointer" alt="" />
      <NewModal show={open} onClose={toggle} onConfirm={trigger} noForm cancelButtonText={t.generic.cancel}>
        <div className="font-bold">{t.milestone.remove.title}</div>
        <div className="mt-2">{t.milestone.remove.text}</div>
      </NewModal>
    </div>
  )
}

export default Remove
