import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../redux/routes'
import useGetFingerprint from './useGetFingerprint'
import { useSelector } from 'react-redux'
import props from '../../redux/props'
import { useEffect, useState } from 'react'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import { PurposeFlag } from '../../types/Auth'

enum Status {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
}

const useTransferDraftToRequest = () => {
  const transferDraftToRequest = functional.use(Routes.TRANSFER_DRAFT_TO_REQUEST)
  const fingerprintUUID = useGetFingerprint()
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])
  const [status, setStatus] = useState<Status>(Status.PENDING)

  useEffect(() => {
    const transfer = async () => {
      const response = await transferDraftToRequest({ fingerprintUUID })
      setStatus(response)
    }

    if (accountLink && accountLink.purposeFlag === PurposeFlag.BOOKER && fingerprintUUID && status === Status.PENDING) {
      setStatus(Status.IN_PROGRESS)
      transfer()
    }
  }, [accountLink, fingerprintUUID, status, transferDraftToRequest])

  return status
}

export default useTransferDraftToRequest
