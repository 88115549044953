import styled from 'styled-components'

export const Title = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  font-family: 'bold';
  width: fit-content;
  border: 2px solid ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.font.size.lg};
  padding: 0.1rem 0.5rem;
  text-shadow: 0 0 0px ${({ theme }) => theme.color.magenta.primary}, 0 0 2px ${({ theme }) => theme.color.magenta.primary};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.md};
  }
`
