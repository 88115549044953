import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { PipelineState } from '../../../../UI/Pipeline/types'
import Property from '../../../../UI/PropertyList/Property'
import { calculateSquareMeters, formatPrice, getPercentageOfPrice, getProjectSizeEnum, getTotalPriceOfRequest } from '../../../../../utility'
import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import { Request, Primer, ArtistListItem } from '../../../../../types/Request'
import Headline from '../../../../UI/Headline/Headline'

type Props = {
  artist: ArtistListItem
  request: Request
}

type Data = {
  artPrice: number
  primerPrice: number
  insurancePrice: number
  totalPrice: number
}

const DefaultCalculator: React.FC<Props> = ({ artist, request }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const state: PipelineState<Request> = useSelector((s) => s[props.PIPELINE])
  const [data, setData] = useState<Data>(null)

  const isPrimerEnabled = useCallback((): boolean => {
    return _.has(request, 'sizeAndPrimer.primerRequired') && request.sizeAndPrimer.primerRequired === Primer.YES && !artist.pricing.primer.disabled
  }, [request, artist])

  useEffect(() => {
    const data = {} as Data

    if (_.has(request, 'sizeAndPrimer.size.length') && _.has(request, 'sizeAndPrimer.size.height')) {
      const squareMeters = calculateSquareMeters(request.sizeAndPrimer.size.length, request.sizeAndPrimer.size.height)
      const projectSize = getProjectSizeEnum(squareMeters)
      const artPricePerSquareMeter = artist.pricing.art[projectSize]
      data.artPrice = artPricePerSquareMeter * squareMeters
      if (isPrimerEnabled()) {
        const primerPricePerSqureMeter = artist.pricing.primer[projectSize]
        data.primerPrice = primerPricePerSqureMeter * squareMeters
      }

      if (!!artist.insuranceDetail.enabled && !!request?.insurance?.accepted) {
        const totalPriceWithoutInsurance = getTotalPriceOfRequest(artist, request, true, true)
        data.insurancePrice = getPercentageOfPrice(totalPriceWithoutInsurance, artist.insuranceDetail.pricePercentage)
      }
      data.totalPrice = getTotalPriceOfRequest(artist, request)

      setData(data)
    }
  }, [state, isPrimerEnabled, request, artist])

  if (!data || !isFinite(data.totalPrice)) return <></>
  return (
    <div className="absolute right-20 2xl:right-52 top-0 w-[300px] hidden xl:block">
      <Headline>{t.request.create.artistPriceCalculator.headline}</Headline>
      <div className="rounded-sm border border-gray-primary py-2 px-3">
        {isFinite(data.artPrice) && (
          <Property valueRight property={{ name: t.request.create.artistPriceCalculator.default.art, value: formatPrice(data.artPrice) }} />
        )}
        {isFinite(data.primerPrice) && (
          <Property valueRight property={{ name: t.request.create.artistPriceCalculator.default.primer, value: formatPrice(data.primerPrice) }} />
        )}
        {isFinite(data.insurancePrice) && (
          <Property
            valueRight
            property={{ name: t.request.create.artistPriceCalculator.default.insurance, value: formatPrice(data.insurancePrice) }}
          />
        )}
        {isFinite(data.totalPrice) && (
          <Property
            valueRight
            className="border-t border-gray-primary pt-1 mt-1"
            property={{ name: t.request.create.artistPriceCalculator.default.total, value: formatPrice(data.totalPrice) }}
          />
        )}
      </div>
    </div>
  )
}

export default DefaultCalculator
