import styled from 'styled-components'
import { Booker } from '../../../../types/Booker'
import Icon from '../../ProfileIcon/Icon'

const BackgroundImage = styled.div`
  background-color: ${({ theme }) => theme.color.blue.superDark};
  height: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
`

export const Name = styled.div<{ largeName: boolean }>`
  color: ${({ theme }) => theme.color.font.secondary};
  font-family: 'bold';
  width: fit-content;
  max-width: 50%;
  font-size: ${({ theme, largeName }) => (largeName ? theme.font.size.xlg : theme.font.size.lg)};
  padding: 0.1rem 15px;
  /* text-shadow: 0 0 0px ${({ theme }) => theme.color.magenta.primary}, 0 0 2px ${({ theme }) => theme.color.magenta.primary}; */
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.font.size.md};
  }
`

type Props = { booker: Booker; hideName?: boolean; children?: React.ReactNode; largeName?: boolean }

const Card: React.FC<Props> = ({ booker, children, hideName = false, largeName = false }) => {
  return (
    <div>
      <div className="h-20 w-full relative">
        <div className="relative h-full">
          <BackgroundImage className="border border-gray-secondary" />
          {!hideName && (
            <Name
              className={`truncate backdrop-blur-sm rounded-sm absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2`}
              largeName={largeName}
            >
              {!!booker.billingDetail.companyName
                ? booker.billingDetail.companyName
                : `${booker.billingDetail.firstname || ''} ${booker.billingDetail.lastname ? `${booker.billingDetail.lastname[0]}.` : ''}`}
            </Name>
          )}
        </div>
        {!!children && <div className="absolute top-1/2 -translate-y-1/2 right-5">{children}</div>}
        <div className="rounded-full h-full absolute left-0 top-0">
          <Icon booker={booker as Booker} className="!h-full border border-white" image={booker?.profileImages?.personImageURL} />
        </div>
      </div>
    </div>
  )
}

export default Card
