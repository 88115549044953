import { Milestone, MilestonesWithBookerAcceptance } from '../../../../../types/Milestone'
import NewModal from '../../../../UI/NewModal/NewModal'
import { UpdateListCallback } from '../../types'
import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { useState } from 'react'
import Routes from '../../../../../redux/routes'
import { functional } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../../../../toast'
import accept from '../../../../../assets/image/milestone/unchecked.png'
import accepted from '../../../../../assets/image/milestone/checked.png'
import decline from '../../../../../assets/image/milestone/decline.png'
import { AddOnStatus } from '../../../../../types/Project'
import { Type } from '../../../../../types/Notification'
import useReadNotification from '../../../../hooks/useReadNotification'

type Props = {
  milestone: Milestone
  updateListCallback: UpdateListCallback
}

const Decision: React.FC<Props> = ({ milestone, updateListCallback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [acceptOpen, setAcceptOpen] = useState(false)
  const [declineOpen, setDeclineOpen] = useState(false)
  const submitDecisition = functional.use(Routes.PROJECT_ADD_ON_DECISION)
  const [loading, setLoading] = useState<boolean>(false)
  const read = useReadNotification()

  const submit = async (accepted: boolean) => {
    setLoading(true)
    const milestones = await submitDecisition({ addOnUUID: milestone.payload.addOnUUID, accepted })
    setLoading(false)
    return Array.isArray(milestones) ? milestones : false
  }

  const submitAccept = async () => {
    const milestones = await submit(true)
    if (milestones) {
      updateListCallback(milestones)
      setAcceptOpen(false)
      toast(TYPE.SUCCESS, t.milestone.addOnDecision.accept.success)
      read({
        type: Type.PROJECT_ADD_ON,
        payload: { addOnUUID: milestone.payload.addOnUUID },
      })
    } else {
      toast(TYPE.ERROR, t.milestone.addOnDecision.accept.error)
    }
  }

  const submitDecline = async () => {
    const milestones = await submit(false)
    if (milestones) {
      updateListCallback(milestones)
      setDeclineOpen(false)
      toast(TYPE.SUCCESS, t.milestone.addOnDecision.decline.success)
      read({
        type: Type.PROJECT_ADD_ON,
        payload: { addOnUUID: milestone.payload.addOnUUID },
      })
    } else {
      toast(TYPE.ERROR, t.milestone.addOnDecision.decline.error)
    }
  }

  const close = () => {
    setAcceptOpen(false)
    setDeclineOpen(false)
  }

  const isAccepted = milestone.payload.addOn.status === AddOnStatus.PENDING_PAYMENT
  const isCompleted = milestone.payload.addOn.status === AddOnStatus.COMPLETED

  const getTooltip = () => {
    if (isAccepted) return t.milestone.addOnDecision.accept.tooltipAlreadyAccepted
    return t.milestone.addOnDecision.accept.tooltip
  }

  if (!MilestonesWithBookerAcceptance.includes(milestone.type) || isCompleted) return <></>
  return (
    <>
      <div className="w-6 flex items-center justify-center shrink-0">
        <div className="tooltip" data-tip={getTooltip()}>
          {!isAccepted && (
            <div className={`absolute -right-[2px] -top-[2px] bg-magenta-primary rounded-full h-2 aspect-square grow-0 shrink-0`}></div>
          )}
          <img src={isAccepted ? accepted : accept} onClick={() => setAcceptOpen(true)} className="h-6 cursor-pointer" alt="" />
        </div>
      </div>
      {!isAccepted && (
        <div className="w-6 flex items-center justify-center shrink-0">
          <div className="tooltip" data-tip={t.milestone.addOnDecision.decline.title}>
            <img src={decline} onClick={() => setDeclineOpen(true)} className="h-6 cursor-pointer" alt="" />
          </div>
        </div>
      )}
      <NewModal show={acceptOpen} onClose={close} onConfirm={loading ? null : submitAccept} noForm cancelButtonText={t.generic.cancel}>
        <div className="font-bold">{t.milestone.addOnDecision.accept.title}</div>
        <div className="mt-2">{t.milestone.addOnDecision.accept.text}</div>
      </NewModal>
      <NewModal show={declineOpen} onClose={close} onConfirm={loading ? null : submitDecline} noForm cancelButtonText={t.generic.cancel}>
        <div className="font-bold">{t.milestone.addOnDecision.decline.title}</div>
        <div className="mt-2">{t.milestone.addOnDecision.decline.text}</div>
      </NewModal>
    </>
  )
}

export default Decision
