import { useState } from 'react'
import Image from './Image'
import { Title } from './Title'

type Props = {
  title?: string
  images: string[]
  children?: React.ReactNode
  className?: string
  enableFullHeightContent?: boolean
  highlight?: boolean
  hideSmallImages?: boolean
}

const GridView: React.FC<Props> = ({ title, images, children, className, enableFullHeightContent, highlight = false, hideSmallImages = false }) => {
  const [activeImageIndex, setActiveImageIndex] = useState<number>(null)

  const getImageOfIndex = (index: number) => {
    if (index === 0) {
      return images[activeImageIndex || 0]
    } else if (index > 0) {
      return index === activeImageIndex ? images[0] : images[index]
    } else {
      return images[index]
    }
  }

  const setImageIndex = (index: number) => () => {
    setActiveImageIndex(index === activeImageIndex ? null : index)
  }

  return (
    <div className={`flex flex-row gap-2 aspect-video ${className}`}>
      <div className={`relative h-full ${images.length === 1 || hideSmallImages ? 'w-full' : 'w-3/4'}`}>
        <Image image={getImageOfIndex(0)} highlight={highlight} />
        {!!title && (
          <div className="absolute top-2 w-full flex justify-center pointer-events-none">
            <Title className={`truncate max-w-[60%] 2xl:max-w-[60%] backdrop-blur-sm rounded-sm`}>{title}</Title>
          </div>
        )}
        {!!children && (
          <div className={`absolute w-full flex justify-center ${enableFullHeightContent ? 'h-full bottom-0' : 'bottom-2'}`}>{children}</div>
        )}
      </div>
      {images.length > 1 && !hideSmallImages && (
        <div className="flex flex-col gap-2 items-start w-1/4">
          <Image image={getImageOfIndex(1)} small onClick={setImageIndex(1)} />
          <Image image={getImageOfIndex(2)} small onClick={setImageIndex(2)} />
          <Image image={getImageOfIndex(3)} small onClick={setImageIndex(3)} />
        </div>
      )}
    </div>
  )
}

export default GridView
