import { CoreContent } from './Layout.Styled'
import Header from './Header/Header'
import Footer from './Footer/Footer'
import Secure from './Secure/Secure'
import ScrollToTop from './ScrollToTop/ScrollToTop'
import { AuthLevelRequired } from '../../../types/Auth'
// import CookieConsent from './CookieConsent/CookieConsent'
import Context from './Context/Context'
import BottomNavigation from './Header/Navigation/BottomNavigation'
import useGetFingerprint from '../../hooks/useGetFingerprint'
import useTransferDraftToRequest from '../../hooks/useTransferDraftToRequest'

type Props = {
  className?: string
  hideHeader?: boolean
  authLevelRequired?: AuthLevelRequired
  hideMenu?: boolean
  hideFooter?: boolean
  mobileFixHeight?: boolean
}

const Layout: React.FC<Props> = ({ children, className, hideHeader = false, authLevelRequired = [], hideMenu = false, hideFooter = false }) => {
  const isChatRoute = window.location.pathname.includes('/chat')
  useGetFingerprint()
  useTransferDraftToRequest()

  return (
    <div className={className}>
      <Secure authLevelRequired={authLevelRequired}>
        <ScrollToTop />
        {!hideHeader && <Header />}
        {/* <CookieConsent> */}
        <CoreContent hideHeader={hideHeader} hideFooter={hideFooter} isChat={isChatRoute}>
          <Context>{children}</Context>
        </CoreContent>
        {/* </CookieConsent> */}
        <BottomNavigation />
        {!hideFooter && <Footer />}
      </Secure>
    </div>
  )
}

export default Layout
