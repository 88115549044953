import { useSelector } from 'react-redux'
import { useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import { useNavigate } from 'react-router-dom'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import { Request } from '../../../../../types/Request'
import { Artist } from '../../../../../types/Artist'
import props from '../../../../../redux/props'
import Routes from '../../../../../redux/routes'
import { PurposeFlag } from '../../../../../types/Auth'
import toast, { TYPE } from '../../../../../toast'
import Loading from '../../../../UI/Loading/Loading'
import NewButton from '../../../../UI/NewButton/NewButton'
import NewModal from '../../../../UI/NewModal/NewModal'
import { Type } from '../../../../../types/Notification'
import useReadNotification from '../../../../hooks/useReadNotification'

type Props = {
  request: Request
  artist: Artist
  triggerReverseOpen: () => void
}

enum Processing {
  FALSE = 'FALSE',
  SUBMITTING = 'SUBMITTING',
  SUCCESS = 'SUCCESS',
}

const DeclineButton: React.FC<Props> = ({ request, artist, triggerReverseOpen }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [processingState, setProcessingState] = useState<Processing>(Processing.FALSE)
  const declineRequest = functional.use(Routes.DECLINE_REQUEST)
  const read = useReadNotification()

  const isArtist = accountLink && accountLink.purposeFlag === PurposeFlag.ARTIST

  const onClick = () => {
    if (isArtist) {
      setShowModal(true)
    } else {
      navigate('/login')
    }
  }

  const submit = async () => {
    setProcessingState(Processing.SUBMITTING)
    const status = await declineRequest({ uuid: request.uuid })
    if (!!status) {
      setProcessingState(Processing.SUCCESS)
      read({
        type: Type.NEW_ARTIST_REQUEST,
        payload: { requestUUID: request.uuid },
      })
    } else {
      setProcessingState(Processing.FALSE)
      toast(TYPE.ERROR, t.request.view.makeAnOffer.decline.processing.error)
    }
  }

  const triggerReverseOffer = () => {
    setShowModal(false)
    triggerReverseOpen()
  }

  const Cancel = <NewButton className="btn-secondary-outline w-fit mx-auto" onClick={() => setShowModal(false)} text={t.generic.cancel} />
  const Close = <NewButton className="btn-secondary-outline w-fit mx-auto" onClick={() => setShowModal(false)} text={t.generic.close} />
  const Confirm = (
    <NewButton
      className="btn-secondary-outline"
      onClick={processingState === Processing.FALSE ? submit : null}
      text={t.request.view.makeAnOffer.decline.modal.cta}
    />
  )
  const ReverseOffer = (
    <NewButton
      className="btn-secondary"
      onClick={processingState === Processing.FALSE ? triggerReverseOffer : null}
      text={t.request.view.makeAnOffer.decline.modal.ctaReverseOffer}
    />
  )

  if (!!request.artistList && !!artist && request.artistList.some((a) => a.uuid === artist.uuid)) {
    return (
      <>
        <NewButton className="btn-secondary" text={t.request.view.makeAnOffer.decline.cta} onClick={onClick} />
        <NewModal show={showModal} onClose={() => setShowModal(false)} noForm noClose>
          {processingState === Processing.FALSE && (
            <>
              <div className="font-bold text-gray-primary">{t.request.view.makeAnOffer.decline.modal.title} </div>
              <div className="text-gray-primary mt-3">{t.request.view.makeAnOffer.decline.modal.text} </div>
              <div className="flex flex-col gap-3 w-60 mx-auto mt-5">
                {ReverseOffer}
                {Confirm}
                {Cancel}
              </div>
            </>
          )}
          {processingState === Processing.SUBMITTING && (
            <>
              <div className="text-center text-sm mt-5">{t.request.view.makeAnOffer.decline.processing.submitting}</div>
              <Loading loading={false} />
            </>
          )}
          {processingState === Processing.SUCCESS && (
            <>
              <div className="text-center text-sm mb-3">{t.request.view.makeAnOffer.decline.processing.success}</div>
              {Close}
            </>
          )}
        </NewModal>
      </>
    )
  } else {
    return null
  }
}

export default DeclineButton
