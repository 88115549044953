import { useSelector } from 'react-redux'
import { Invoice as InvoiceType, InvoiceType as InvoiceTypeEnum } from '../../../../../types/Invoice'
import props from '../../../../../redux/props'
import { downloadFileFromURL } from '../../../../../utility'
import download from '../../../../../assets/image/invoices/download.png'
import NewButton from '../../../../UI/NewButton/NewButton'
import styled from 'styled-components'
import { PaymentTypeTranslation } from '../../../../../types/Payment'
import { CurrencyEuroIcon } from '@heroicons/react/24/outline'

type Props = {
  invoice: InvoiceType
  previewCallback?: (invoice: InvoiceType) => void
  isActive?: boolean
  isFirst?: boolean
}

const Image = styled.div<{ src: string }>`
  width: 100%;
  height: 100%;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
`

const Invoice: React.FC<Props> = ({ invoice, previewCallback, isActive = false, isFirst = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const getProjectImage = (): string => {
    const urls = invoice.project.design?.urls
    return Array.isArray(urls) && urls.length > 0 ? urls[0] : null
  }

  const triggerDownload = () => downloadFileFromURL(`${invoice.fullNumber}.pdf`, invoice.pdfURL)

  const isPaid = !!invoice.payment.completed || invoice.type === InvoiceTypeEnum.REVERSE_CHARGE_FOR_ARTIST

  const open = () => {
    if (previewCallback) {
      previewCallback(invoice)
    }
  }

  return (
    <div
      className={`flex flex-row gap-1 items-center px-3 py-2 ${isPaid ? 'bg-gray-secondary' : ''} ${
        isActive ? 'border border-magenta-primary bg-white' : 'hover:border-magenta-primary hover:border'
      }`}
    >
      <div className="w-1/6 h-8 cursor-pointer" onClick={open}>
        <Image src={getProjectImage()} />
      </div>
      <div className="w-[55%] md:w-3/6 cursor-pointer" onClick={open}>
        <div className="text-sm font-bold truncate">{invoice.project.nameAndLocation.name}</div>
        {!!invoice.payment.addOn && !invoice.payment.addOn.additionalOtherCosts ? (
          <div className="text-xs">
            {!!invoice.payment.addOn.additionalAttentees && t.priceCalculator.addOn.additionalAttentees}
            {!!invoice.payment.addOn.additionalOtherCosts && t.priceCalculator.addOn.additionalOtherCosts}
            {!!invoice.payment.addOn.additionalSquareMeter && t.priceCalculator.addOn.additionalSquareMeter}
            {!!invoice.payment.addOn.conceptAdded && t.priceCalculator.addOn.concept}
            {!!invoice.payment.addOn.insuranceAdded && t.priceCalculator.addOn.insurance}
            {!!invoice.payment.addOn.primerAdded && t.priceCalculator.addOn.primer}
          </div>
        ) : (
          <div className="text-xs">{`${PaymentTypeTranslation[invoice.payment.type]}${invoice.isStorno ? t.invoices.list.isStornoPrefix : ''}`}</div>
        )}
      </div>
      <div className="w-[28.34%] md:w-1/6 flex justify-end md:justify-center items-center shrink-0 gap-2 md:gap-1">
        {!isPaid && (
          <div className={`tooltip cursor-pointer ${isFirst && 'tooltip-bottom'}`} data-tip={t.invoices.list.notPaidYet}>
            <CurrencyEuroIcon className="h-8 md:h-7 text-black-primary stroke-1" />
          </div>
        )}
        {isPaid && (
          <div className={`tooltip ${isFirst && 'tooltip-bottom'}`} data-tip={t.invoices.list.fullyPaid}>
            <CurrencyEuroIcon className="h-8 md:h-7 text-black-primary stroke-1 opacity-30" />
          </div>
        )}
        <img src={download} alt="" className="h-7 md:h-6 cursor-pointer" onClick={triggerDownload} />
      </div>
      <div className="hidden md:block w-1/6 shrink-0">
        <NewButton
          text={t.invoices.list.download}
          onClick={open}
          className={`btn-secondary border-2 !border-white ${isPaid ? '!bg-gray-primary' : ''}`}
        />
      </div>
    </div>
  )
}

export default Invoice
