import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { ArtistListItem, Request } from '../../../../../types/Request'
import { getPrettyDate, prettyLocation, s3PublicStoragePrefix } from '../../../../../utility'
import SectionHeader from '../../../../UI/Pipeline/SectionHeader'
import PropertyList from '../../../../UI/PropertyList/PropertyList'
import { Property as PropertyType } from '../../../../UI/PropertyList/types'
import Pricing from './Pricing'
import { PipelineState } from '../../../../UI/Pipeline/types'
import Carousel from '../../../../UI/Carousel/Carousel'
import useIsBreakpoint, { Breakpoint } from '../../../../hooks/useIsBreakpoint'

type Props = {
  state: PipelineState<Request, { artist: ArtistListItem }>
}

const Properties: React.FC<Props> = ({ state }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const isMobile = useIsBreakpoint(Breakpoint.SM)

  const getPropertyList = (): PropertyType[] => {
    const propertyList: PropertyType[] = [
      { name: t.request.create.summary.workshop.properties.title, value: state.data.nameAndLocation.name },
      { name: t.request.create.summary.workshop.properties.location, value: prettyLocation(state.data.nameAndLocation.location) },
      {
        name: t.request.create.summary.workshop.properties.attendee.title,
        value: `${state.data.attendee.amount}${t.request.create.summary.workshop.properties.attendee.amountSuffix}`,
      },
      { name: t.request.create.summary.workshop.properties.deadlineDate, value: getPrettyDate(new Date(state.data.deadline.date)) },
    ]
    return propertyList
  }

  return (
    <div className="flex flex-col gap-0 mt-6">
      <PropertyList properties={getPropertyList()} />
      <SectionHeader headline={t.request.create.summary.workshop.properties.attendee.motto} />
      <div className="text-gray-primary my-2 text-sm break-words mt-6">{state.data.attendee.motto}</div>
      <SectionHeader
        headline={t.request.create.summary.workshop.design.title}
        description={`${t.request.create.summary.default.design.imagePrefix}${state.data.design.keys.length}`}
        italicSubline
      />
      <Carousel
        className="mt-6"
        itemsToShow={2}
        itemsToShowMobile={2}
        smallArrows={isMobile}
        images={state.data.design.keys.map((key) => `${s3PublicStoragePrefix}${key}`)}
      />
      <Pricing state={state} />
    </div>
  )
}

export default Properties
