import { useSelector } from 'react-redux'
import DataSection from '../../../Components/DataSection/DataSection'
import { DataSectionComponent } from '../../types'
import props from '../../../../../redux/props'
import { useState } from 'react'
import toast, { TYPE } from '../../../../../toast'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../../redux/routes'
import NewInput from '../../../../UI/NewInput/NewInput'

const Password: DataSectionComponent = ({ booker }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const updateCredentials = functional.use(Routes.UPDATE_ACCOUNT_CREDENTIALS)
  const [password, setPassword] = useState<string>('')
  const [passwordRepeat, setPasswordRepeat] = useState<string>('')

  const Default = (
    <div>
      <div className="text-sm text-black-secondary">{t.booker.settings.password.hint}</div>
    </div>
  )

  const save = async () => {
    if (password === passwordRepeat) {
      const status = await updateCredentials({ password })
      if (status) {
        toast(TYPE.SUCCESS, t.booker.settings.password.edit.success)
        setPassword(null)
        return true
      } else {
        toast(TYPE.ERROR, t.booker.settings.password.edit.error)
      }
    } else {
      toast(TYPE.ERROR, t.booker.settings.password.edit.passwordsNotEqual)
    }
    return false
  }

  return (
    <DataSection className="md:w-2/4" title={t.booker.settings.password.title} defaultView={Default} enableCancel saveCallback={save}>
      <div className="text-sm text-black-secondary">{t.booker.settings.password.edit.hint}</div>
      <NewInput className="mt-2" value={password} onChange={setPassword} placeholder={t.generic.password} type="password" required />
      <NewInput
        className="mt-2"
        value={passwordRepeat}
        onChange={setPasswordRepeat}
        placeholder={t.generic.repeatedPassword}
        type="password"
        required
      />
    </DataSection>
  )
}

export default Password
