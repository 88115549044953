import Container from '../UI/Container/Container'
import Layout from '../UI/Layout/Layout'
import '../UI/LegalDocumentStyling/LegalDocument.scss'

const DataProtection = () => {
  return (
    <Layout authLevelRequired={false}>
      <Container>
        <div className="legal-document">
          <h1>Datenschutz&shy;erkl&auml;rung</h1>
          <h2>1. Datenschutz auf einen Blick</h2>
          <h3>Allgemeine Hinweise</h3>{' '}
          <p>
            Die folgenden Hinweise geben einen einfachen &Uuml;berblick dar&uuml;ber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese
            Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen.
            Ausf&uuml;hrliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgef&uuml;hrten Datenschutzerkl&auml;rung.
          </p>
          <h3>Datenerfassung auf dieser Website</h3> <h4>Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser Website?</h4>{' '}
          <p>
            Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen Sie dem Abschnitt
            &bdquo;Hinweis zur Verantwortlichen Stelle&ldquo; in dieser Datenschutzerkl&auml;rung entnehmen.
          </p>{' '}
          <h4>Wie erfassen wir Ihre Daten?</h4>{' '}
          <p>
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.&nbsp;B. um Daten handeln, die Sie in
            ein Kontaktformular eingeben.
          </p>{' '}
          <p>
            Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem
            technische Daten (z.&nbsp;B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
            automatisch, sobald Sie diese Website betreten.
          </p>{' '}
          <h4>Wof&uuml;r nutzen wir Ihre Daten?</h4>{' '}
          <p>
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gew&auml;hrleisten. Andere Daten k&ouml;nnen zur
            Analyse Ihres Nutzerverhaltens verwendet werden.
          </p>{' '}
          <h4>Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?</h4>{' '}
          <p>
            Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber Herkunft, Empf&auml;nger und Zweck Ihrer gespeicherten personenbezogenen
            Daten zu erhalten. Sie haben au&szlig;erdem ein Recht, die Berichtigung oder L&ouml;schung dieser Daten zu verlangen. Wenn Sie eine
            Einwilligung zur Datenverarbeitung erteilt haben, k&ouml;nnen Sie diese Einwilligung jederzeit f&uuml;r die Zukunft widerrufen.
            Au&szlig;erdem haben Sie das Recht, unter bestimmten Umst&auml;nden die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten
            zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde zu.
          </p>{' '}
          <p>Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie sich jederzeit an uns wenden.</p>
          <h3>Analyse-Tools und Tools von Dritt&shy;anbietern</h3>{' '}
          <p>
            Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit sogenannten
            Analyseprogrammen.
          </p>{' '}
          <p>Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden Datenschutzerkl&auml;rung.</p>
          <h2>2. Hosting und Content Delivery Networks (CDN)</h2>
          <p>Wir hosten die Inhalte unserer Website bei folgenden Anbietern:</p>
          <h3>Amazon Web Services (AWS)</h3>{' '}
          <p>Anbieter ist die Amazon Web Services EMEA SARL, 38 Avenue John F. Kennedy, 1855 Luxemburg (nachfolgend AWS).</p>{' '}
          <p>
            Wenn Sie unsere Website besuchen, werden Ihre personenbezogenen Daten auf den Servern von AWS verarbeitet. Hierbei k&ouml;nnen auch
            personenbezogene Daten an das Mutterunternehmen von AWS in die USA &uuml;bermittelt werden. Die Daten&uuml;bertragung in die USA wird auf
            die EU-Standardvertragsklauseln gest&uuml;tzt. Details finden Sie hier:{' '}
            <a href="https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/" target="_blank" rel="noopener noreferrer">
              https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/
            </a>
            .
          </p>{' '}
          <p>
            Weitere Informationen entnehmen Sie der Datenschutzerkl&auml;rung von AWS:{' '}
            <a href="https://aws.amazon.com/de/privacy/?nc1=f_pr" target="_blank" rel="noopener noreferrer">
              https://aws.amazon.com/de/privacy/?nc1=f_pr
            </a>
            .
          </p>{' '}
          <p>
            Die Verwendung von AWS erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an einer m&ouml;glichst
            zuverl&auml;ssigen Darstellung unserer Website. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
            ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von
            Cookies oder den Zugriff auf Informationen im Endger&auml;t des Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die
            Einwilligung ist jederzeit widerrufbar.
          </p>
          <p>
            Das Unternehmen verf&uuml;gt &uuml;ber eine Zertifizierung nach dem &bdquo;EU-US Data Privacy Framework&ldquo; (DPF). Der DPF ist ein
            &Uuml;bereinkommen zwischen der Europ&auml;ischen Union und den USA, der die Einhaltung europ&auml;ischer Datenschutzstandards bei
            Datenverarbeitungen in den USA gew&auml;hrleisten soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese
            Datenschutzstandards einzuhalten. Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link:{' '}
            <a
              href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TOWQAA4&status=Active"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TOWQAA4&status=Active
            </a>
          </p>
          <h4>Auftragsverarbeitung</h4>{' '}
          <p>
            Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es sich
            um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gew&auml;hrleistet, dass dieser die personenbezogenen Daten unserer
            Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
          </p>
          <h3>Strato</h3>{' '}
          <p>
            Anbieter ist die Strato AG, Otto-Ostrowski-Stra&szlig;e 7, 10249 Berlin (nachfolgend &bdquo;Strato&ldquo;). Wenn Sie unsere Website
            besuchen, erfasst Strato verschiedene Logfiles inklusive Ihrer IP-Adressen.
          </p>{' '}
          <p>
            Weitere Informationen entnehmen Sie der Datenschutzerkl&auml;rung von Strato:{' '}
            <a href="https://www.strato.de/datenschutz/" target="_blank" rel="noopener noreferrer">
              https://www.strato.de/datenschutz/
            </a>
            .
          </p>{' '}
          <p>
            Die Verwendung von Strato erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an einer
            m&ouml;glichst zuverl&auml;ssigen Darstellung unserer Website. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die
            Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG, soweit die Einwilligung die
            Speicherung von Cookies oder den Zugriff auf Informationen im Endger&auml;t des Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des
            TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
          </p>
          <h4>Auftragsverarbeitung</h4>{' '}
          <p>
            Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es sich
            um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gew&auml;hrleistet, dass dieser die personenbezogenen Daten unserer
            Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
          </p>
          <h3>Amazon CloudFront CDN</h3>{' '}
          <p>
            Wir nutzen das Content Delivery Network Amazon CloudFront CDN. Anbieter ist die Amazon Web Services EMEA SARL, 38 avenue John F. Kennedy,
            L-1855, Luxemburg (nachfolgend &bdquo;Amazon&ldquo;).
          </p>{' '}
          <p>
            Bei Amazon CloudFront CDN handelt es sich um ein weltweit verteiltes Content Delivery Network. Dabei wird technisch der
            Informationstransfer zwischen Ihrem Browser und unserer Website &uuml;ber das Content Delivery Network geleitet. Hierdurch k&ouml;nnen wir
            die weltweite Erreichbarkeit und die Leistungsf&auml;higkeit unserer Website erh&ouml;hen.
          </p>{' '}
          <p>
            Der Einsatz von Amazon CloudFront CDN beruht auf unserem berechtigten Interesse an einer m&ouml;glichst fehlerfreien und sicheren
            Bereitstellung unseres Webangebotes (Art. 6 Abs. 1 lit. f DSGVO).
          </p>{' '}
          <p>
            Die Daten&uuml;bertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details finden Sie hier:{' '}
            <a href="https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/" target="_blank" rel="noopener noreferrer">
              https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/
            </a>
            .
          </p>{' '}
          <p>
            Weitere Informationen zu Amazon CloudFront CDN finden Sie hier:{' '}
            <a
              href="https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice__German_Translation.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice__German_Translation.pdf
            </a>
            .
          </p>
          <p>
            Das Unternehmen verf&uuml;gt &uuml;ber eine Zertifizierung nach dem &bdquo;EU-US Data Privacy Framework&ldquo; (DPF). Der DPF ist ein
            &Uuml;bereinkommen zwischen der Europ&auml;ischen Union und den USA, der die Einhaltung europ&auml;ischer Datenschutzstandards bei
            Datenverarbeitungen in den USA gew&auml;hrleisten soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese
            Datenschutzstandards einzuhalten. Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link:{' '}
            <a
              href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TOWQAA4&status=Active"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TOWQAA4&status=Active
            </a>
          </p>
          <h4>Auftragsverarbeitung</h4>{' '}
          <p>
            Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es sich
            um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gew&auml;hrleistet, dass dieser die personenbezogenen Daten unserer
            Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
          </p>
          <h2>3. Allgemeine Hinweise und Pflicht&shy;informationen</h2>
          <h3>Datenschutz</h3>{' '}
          <p>
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
            vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerkl&auml;rung.
          </p>{' '}
          <p>
            Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
            pers&ouml;nlich identifiziert werden k&ouml;nnen. Die vorliegende Datenschutzerkl&auml;rung erl&auml;utert, welche Daten wir erheben und
            wof&uuml;r wir sie nutzen. Sie erl&auml;utert auch, wie und zu welchem Zweck das geschieht.
          </p>{' '}
          <p>
            Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet (z.&nbsp;B. bei der Kommunikation per E-Mail) Sicherheitsl&uuml;cken
            aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht m&ouml;glich.
          </p>
          <h3>Hinweis zur verantwortlichen Stelle</h3> <p>Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf dieser Website ist:</p>{' '}
          <p>Qsellart GmbH, Goldstra&szlig;e 75a, 48147 M&uuml;nster</p>
          <p>
            Telefon: 017647367078
            <br />
            E-Mail: info@qsellart.de
          </p>
          <p>
            Verantwortliche Stelle ist die nat&uuml;rliche oder juristische Person, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und
            Mittel der Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen, E-Mail-Adressen o. &Auml;.) entscheidet.
          </p>
          <h3>Speicherdauer</h3>{' '}
          <p>
            Soweit innerhalb dieser Datenschutzerkl&auml;rung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten
            bei uns, bis der Zweck f&uuml;r die Datenverarbeitung entf&auml;llt. Wenn Sie ein berechtigtes L&ouml;schersuchen geltend machen oder eine
            Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gel&ouml;scht, sofern wir keine anderen rechtlich zul&auml;ssigen
            Gr&uuml;nde f&uuml;r die Speicherung Ihrer personenbezogenen Daten haben (z.&nbsp;B. steuer- oder handelsrechtliche Aufbewahrungsfristen);
            im letztgenannten Fall erfolgt die L&ouml;schung nach Fortfall dieser Gr&uuml;nde.
          </p>
          <h3>Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website</h3>{' '}
          <p>
            Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit.
            a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer
            ausdr&uuml;cklichen Einwilligung in die &Uuml;bertragung personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung
            au&szlig;erdem auf Grundlage von Art. 49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf
            Informationen in Ihr Endger&auml;t (z.&nbsp;B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung
            zus&auml;tzlich auf Grundlage von &sect; 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur
            Vertragserf&uuml;llung oder zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich, verarbeiten wir Ihre Daten auf Grundlage
            des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erf&uuml;llung einer rechtlichen Verpflichtung
            erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten
            Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen. &Uuml;ber die jeweils im Einzelfall einschl&auml;gigen Rechtsgrundlagen wird in den
            folgenden Abs&auml;tzen dieser Datenschutzerkl&auml;rung informiert.
          </p>
          <h3>
            Hinweis zur Datenweitergabe in datenschutzrechtlich nicht sichere Drittstaaten sowie die Weitergabe an US-Unternehmen, die nicht
            DPF-zertifiziert sind
          </h3>{' '}
          <p>
            Wir verwenden unter anderem Tools von Unternehmen mit Sitz in datenschutzrechtlich nicht sicheren Drittstaaten sowie US-Tools, deren
            Anbieter nicht nach dem EU-US-Data Privacy Framework (DPF) zertifiziert sind. Wenn diese Tools aktiv sind, k&ouml;nnen Ihre
            personenbezogene Daten in diese Staaten &uuml;bertragen und dort verarbeitet werden. Wir weisen darauf hin, dass in datenschutzrechtlich
            unsicheren Drittstaaten kein mit der EU vergleichbares Datenschutzniveau garantiert werden kann.
          </p>{' '}
          <p>
            Wir weisen darauf hin, dass die USA als sicherer Drittstaat grunds&auml;tzlich ein mit der EU vergleichbares Datenschutzniveau aufweisen.
            Eine Daten&uuml;bertragung in die USA ist danach zul&auml;ssig, wenn der Empf&auml;nger eine Zertifizierung unter dem &bdquo;EU-US Data
            Privacy Framework&ldquo; (DPF) besitzt oder &uuml;ber geeignete zus&auml;tzliche Garantien verf&uuml;gt. Informationen zu
            &Uuml;bermittlungen an Drittstaaten einschlie&szlig;lich der Datenempf&auml;nger finden Sie in dieser Datenschutzerkl&auml;rung.
          </p>
          <h3>Empfänger von personenbezogenen Daten</h3>{' '}
          <p>
            Im Rahmen unserer Gesch&auml;ftst&auml;tigkeit arbeiten wir mit verschiedenen externen Stellen zusammen. Dabei ist teilweise auch eine
            &Uuml;bermittlung von personenbezogenen Daten an diese externen Stellen erforderlich. Wir geben personenbezogene Daten nur dann an externe
            Stellen weiter, wenn dies im Rahmen einer Vertragserf&uuml;llung erforderlich ist, wenn wir gesetzlich hierzu verpflichtet sind
            (z.&nbsp;B. Weitergabe von Daten an Steuerbeh&ouml;rden), wenn wir ein berechtigtes Interesse nach Art. 6 Abs. 1 lit. f DSGVO an der
            Weitergabe haben oder wenn eine sonstige Rechtsgrundlage die Datenweitergabe erlaubt. Beim Einsatz von Auftragsverarbeitern geben wir
            personenbezogene Daten unserer Kunden nur auf Grundlage eines g&uuml;ltigen Vertrags &uuml;ber Auftragsverarbeitung weiter. Im Falle einer
            gemeinsamen Verarbeitung wird ein Vertrag &uuml;ber gemeinsame Verarbeitung geschlossen.
          </p>
          <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>{' '}
          <p>
            Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sie k&ouml;nnen eine bereits
            erteilte Einwilligung jederzeit widerrufen. Die Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom
            Widerruf unber&uuml;hrt.
          </p>
          <h3>Widerspruchsrecht gegen die Datenerhebung in besonderen F&auml;llen sowie gegen Direktwerbung (Art. 21 DSGVO)</h3>{' '}
          <p>
            WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GR&Uuml;NDEN,
            DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT
            AUCH F&Uuml;R EIN AUF DIESE BESTIMMUNGEN GEST&Uuml;TZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
            ENTNEHMEN SIE DIESER DATENSCHUTZERKL&Auml;RUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT
            MEHR VERARBEITEN, ES SEI DENN, WIR K&Ouml;NNEN ZWINGENDE SCHUTZW&Uuml;RDIGE GR&Uuml;NDE F&Uuml;R DIE VERARBEITUNG NACHWEISEN, DIE IHRE
            INTERESSEN, RECHTE UND FREIHEITEN &Uuml;BERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUS&Uuml;BUNG ODER VERTEIDIGUNG VON
            RECHTSANSPR&Uuml;CHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
          </p>{' '}
          <p>
            WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
            VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH F&Uuml;R DAS PROFILING,
            SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT
            MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
          </p>
          <h3>Beschwerde&shy;recht bei der zust&auml;ndigen Aufsichts&shy;beh&ouml;rde</h3>{' '}
          <p>
            Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbeh&ouml;rde, insbesondere
            in dem Mitgliedstaat ihres gew&ouml;hnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutma&szlig;lichen Versto&szlig;es zu.
            Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
          </p>
          <h3>Recht auf Daten&shy;&uuml;bertrag&shy;barkeit</h3>{' '}
          <p>
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erf&uuml;llung eines Vertrags automatisiert verarbeiten, an
            sich oder an einen Dritten in einem g&auml;ngigen, maschinenlesbaren Format aush&auml;ndigen zu lassen. Sofern Sie die direkte
            &Uuml;bertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
          </p>
          <h3>Auskunft, Berichtigung und L&ouml;schung</h3>{' '}
          <p>
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft &uuml;ber Ihre gespeicherten
            personenbezogenen Daten, deren Herkunft und Empf&auml;nger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder
            L&ouml;schung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten k&ouml;nnen Sie sich jederzeit an uns wenden.
          </p>
          <h3>Recht auf Einschr&auml;nkung der Verarbeitung</h3>{' '}
          <p>
            Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu k&ouml;nnen Sie sich
            jederzeit an uns wenden. Das Recht auf Einschr&auml;nkung der Verarbeitung besteht in folgenden F&auml;llen:
          </p>{' '}
          <ul>
            {' '}
            <li>
              Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, ben&ouml;tigen wir in der Regel Zeit, um dies
              zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer der Pr&uuml;fung haben Sie das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </li>{' '}
            <li>
              Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtm&auml;&szlig;ig geschah/geschieht, k&ouml;nnen Sie statt der L&ouml;schung
              die Einschr&auml;nkung der Datenverarbeitung verlangen.
            </li>{' '}
            <li>
              Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen, Sie sie jedoch zur Aus&uuml;bung, Verteidigung oder Geltendmachung von
              Rechtsanspr&uuml;chen ben&ouml;tigen, haben Sie das Recht, statt der L&ouml;schung die Einschr&auml;nkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </li>{' '}
            <li>
              Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abw&auml;gung zwischen Ihren und unseren Interessen
              vorgenommen werden. Solange noch nicht feststeht, wessen Interessen &uuml;berwiegen, haben Sie das Recht, die Einschr&auml;nkung der
              Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            </li>{' '}
          </ul>{' '}
          <p>
            Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschr&auml;nkt haben, d&uuml;rfen diese Daten &ndash; von ihrer Speicherung
            abgesehen &ndash; nur mit Ihrer Einwilligung oder zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen oder zum
            Schutz der Rechte einer anderen nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden eines wichtigen &ouml;ffentlichen
            Interesses der Europ&auml;ischen Union oder eines Mitgliedstaats verarbeitet werden.
          </p>
          <h3>SSL- bzw. TLS-Verschl&uuml;sselung</h3>{' '}
          <p>
            Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
            oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschl&uuml;sselung. Eine verschl&uuml;sselte Verbindung
            erkennen Sie daran, dass die Adresszeile des Browsers von &bdquo;http://&ldquo; auf &bdquo;https://&ldquo; wechselt und an dem
            Schloss-Symbol in Ihrer Browserzeile.
          </p>{' '}
          <p>
            Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist, k&ouml;nnen die Daten, die Sie an uns &uuml;bermitteln, nicht von Dritten
            mitgelesen werden.
          </p>
          <h3>Verschl&uuml;sselter Zahlungsverkehr auf dieser Website</h3>{' '}
          <p>
            Besteht nach dem Abschluss eines kostenpflichtigen Vertrags eine Verpflichtung, uns Ihre Zahlungsdaten (z.&nbsp;B. Kontonummer bei
            Einzugserm&auml;chtigung) zu &uuml;bermitteln, werden diese Daten zur Zahlungsabwicklung ben&ouml;tigt.
          </p>{' '}
          <p>
            Der Zahlungsverkehr &uuml;ber die g&auml;ngigen Zahlungsmittel (Visa/MasterCard, Lastschriftverfahren) erfolgt ausschlie&szlig;lich
            &uuml;ber eine verschl&uuml;sselte SSL- bzw. TLS-Verbindung. Eine verschl&uuml;sselte Verbindung erkennen Sie daran, dass die Adresszeile
            des Browsers von &bdquo;http://&ldquo; auf &bdquo;https://&ldquo; wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
          </p>{' '}
          <p>
            Bei verschl&uuml;sselter Kommunikation k&ouml;nnen Ihre Zahlungsdaten, die Sie an uns &uuml;bermitteln, nicht von Dritten mitgelesen
            werden.
          </p>
          <h3>Widerspruch gegen Werbe-E-Mails</h3>{' '}
          <p>
            Der Nutzung von im Rahmen der Impressumspflicht ver&ouml;ffentlichten Kontaktdaten zur &Uuml;bersendung von nicht ausdr&uuml;cklich
            angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdr&uuml;cklich
            rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
          </p>
          <h2>4. Datenerfassung auf dieser Website</h2>
          <h3>Cookies</h3>{' '}
          <p>
            Unsere Internetseiten verwenden so genannte &bdquo;Cookies&ldquo;. Cookies sind kleine Datenpakete und richten auf Ihrem Endger&auml;t
            keinen Schaden an. Sie werden entweder vor&uuml;bergehend f&uuml;r die Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente
            Cookies) auf Ihrem Endger&auml;t gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch gel&ouml;scht. Permanente Cookies
            bleiben auf Ihrem Endger&auml;t gespeichert, bis Sie diese selbst l&ouml;schen oder eine automatische L&ouml;schung durch Ihren Webbrowser
            erfolgt.
          </p>{' '}
          <p>
            Cookies k&ouml;nnen von uns (First-Party-Cookies) oder von Drittunternehmen stammen (sog. Third-Party-Cookies). Third-Party-Cookies
            erm&ouml;glichen die Einbindung bestimmter Dienstleistungen von Drittunternehmen innerhalb von Webseiten (z.&nbsp;B. Cookies zur
            Abwicklung von Zahlungsdienstleistungen).
          </p>{' '}
          <p>
            Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte Webseitenfunktionen ohne diese nicht
            funktionieren w&uuml;rden (z.&nbsp;B. die Warenkorbfunktion oder die Anzeige von Videos). Andere Cookies k&ouml;nnen zur Auswertung des
            Nutzerverhaltens oder zu Werbezwecken verwendet werden.
          </p>{' '}
          <p>
            Cookies, die zur Durchf&uuml;hrung des elektronischen Kommunikationsvorgangs, zur Bereitstellung bestimmter, von Ihnen erw&uuml;nschter
            Funktionen (z.&nbsp;B. f&uuml;r die Warenkorbfunktion) oder zur Optimierung der Website (z.&nbsp;B. Cookies zur Messung des Webpublikums)
            erforderlich sind (notwendige Cookies), werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere
            Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von notwendigen Cookies zur
            technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung von Cookies und
            vergleichbaren Wiedererkennungstechnologien abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage dieser
            Einwilligung (Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG); die Einwilligung ist jederzeit widerrufbar.
          </p>{' '}
          <p>
            Sie k&ouml;nnen Ihren Browser so einstellen, dass Sie &uuml;ber das Setzen von Cookies informiert werden und Cookies nur im Einzelfall
            erlauben, die Annahme von Cookies f&uuml;r bestimmte F&auml;lle oder generell ausschlie&szlig;en sowie das automatische L&ouml;schen der
            Cookies beim Schlie&szlig;en des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalit&auml;t dieser Website
            eingeschr&auml;nkt sein.
          </p>{' '}
          <p>Welche Cookies und Dienste auf dieser Website eingesetzt werden, k&ouml;nnen Sie dieser Datenschutzerkl&auml;rung entnehmen.</p>
          <h3>Server-Log-Dateien</h3>{' '}
          <p>
            Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch an
            uns &uuml;bermittelt. Dies sind:
          </p>{' '}
          <ul>
            {' '}
            <li>Browsertyp und Browserversion</li> <li>verwendetes Betriebssystem</li> <li>Referrer URL</li>{' '}
            <li>Hostname des zugreifenden Rechners</li> <li>Uhrzeit der Serveranfrage</li> <li>IP-Adresse</li>{' '}
          </ul>{' '}
          <p>Eine Zusammenf&uuml;hrung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.</p>{' '}
          <p>
            Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
            der technisch fehlerfreien Darstellung und der Optimierung seiner Website &ndash; hierzu m&uuml;ssen die Server-Log-Files erfasst werden.
          </p>
          <h3>Kontaktformular</h3>{' '}
          <p>
            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
            angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und f&uuml;r den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben
            wir nicht ohne Ihre Einwilligung weiter.
          </p>{' '}
          <p>
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung eines
            Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen &uuml;brigen
            F&auml;llen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art.
            6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist
            jederzeit widerrufbar.
          </p>{' '}
          <p>
            Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur L&ouml;schung auffordern, Ihre Einwilligung zur
            Speicherung widerrufen oder der Zweck f&uuml;r die Datenspeicherung entf&auml;llt (z.&nbsp;B. nach abgeschlossener Bearbeitung Ihrer
            Anfrage). Zwingende gesetzliche Bestimmungen &ndash; insbesondere Aufbewahrungsfristen &ndash; bleiben unber&uuml;hrt.
          </p>
          <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>{' '}
          <p>
            Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen
            Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne
            Ihre Einwilligung weiter.
          </p>{' '}
          <p>
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung eines
            Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen &uuml;brigen
            F&auml;llen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art.
            6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist
            jederzeit widerrufbar.
          </p>{' '}
          <p>
            Die von Ihnen an uns per Kontaktanfragen &uuml;bersandten Daten verbleiben bei uns, bis Sie uns zur L&ouml;schung auffordern, Ihre
            Einwilligung zur Speicherung widerrufen oder der Zweck f&uuml;r die Datenspeicherung entf&auml;llt (z.&nbsp;B. nach abgeschlossener
            Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen &ndash; insbesondere gesetzliche Aufbewahrungsfristen &ndash; bleiben
            unber&uuml;hrt.
          </p>
          <h3>Kommunikation via WhatsApp</h3>{' '}
          <p>
            F&uuml;r die Kommunikation mit unseren Kunden und sonstigen Dritten nutzen wir unter anderem den Instant-Messaging-Dienst WhatsApp.
            Anbieter ist die WhatsApp Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland.
          </p>{' '}
          <p>
            Die Kommunikation erfolgt &uuml;ber eine Ende-zu-Ende-Verschl&uuml;sselung (Peer-to-Peer), die verhindert, dass WhatsApp oder sonstige
            Dritte Zugriff auf die Kommunikationsinhalte erlangen k&ouml;nnen. WhatsApp erh&auml;lt jedoch Zugriff auf Metadaten, die im Zuge des
            Kommunikationsvorgangs entstehen (z.&nbsp;B. Absender, Empf&auml;nger und Zeitpunkt). Wir weisen ferner darauf hin, dass WhatsApp nach
            eigener Aussage, personenbezogene Daten seiner Nutzer mit seiner in den USA ans&auml;ssigen Konzernmutter Meta teilt. Weitere Details zur
            Datenverarbeitung finden Sie in der Datenschutzrichtlinie von WhatsApp unter:{' '}
            <a href="https://www.whatsapp.com/legal/#privacy-policy" target="_blank" rel="noopener noreferrer">
              https://www.whatsapp.com/legal/#privacy-policy
            </a>
            .
          </p>{' '}
          <p>
            Der Einsatz von WhatsApp erfolgt auf Grundlage unseres berechtigten Interesses an einer m&ouml;glichst schnellen und effektiven
            Kommunikation mit Kunden, Interessenten und sonstigen Gesch&auml;fts- und Vertragspartnern (Art. 6 Abs. 1 lit. f DSGVO). Sofern eine
            entsprechende Einwilligung abgefragt wurde, erfolgt die Datenverarbeitung ausschlie&szlig;lich auf Grundlage der Einwilligung; diese ist
            jederzeit mit Wirkung f&uuml;r die Zukunft widerrufbar.
          </p>{' '}
          <p>
            Die zwischen Ihnen und uns auf WhatsApp ausgetauschten Kommunikationsinhalte verbleiben bei uns, bis Sie uns zur L&ouml;schung auffordern,
            Ihre Einwilligung zur Speicherung widerrufen oder der Zweck f&uuml;r die Datenspeicherung entf&auml;llt (z.&nbsp;B. nach abgeschlossener
            Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen &ndash; insbesondere Aufbewahrungsfristen &ndash; bleiben unber&uuml;hrt.
          </p>
          <p>
            Das Unternehmen verf&uuml;gt &uuml;ber eine Zertifizierung nach dem &bdquo;EU-US Data Privacy Framework&ldquo; (DPF). Der DPF ist ein
            &Uuml;bereinkommen zwischen der Europ&auml;ischen Union und den USA, der die Einhaltung europ&auml;ischer Datenschutzstandards bei
            Datenverarbeitungen in den USA gew&auml;hrleisten soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese
            Datenschutzstandards einzuhalten. Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link:{' '}
            <a
              href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt00000011sfnAAA&status=Active "
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt00000011sfnAAA&status=Active{' '}
            </a>
          </p>
          <p>Wir nutzen WhatsApp in der Variante &bdquo;WhatsApp Business&ldquo;.</p>{' '}
          <p>
            Die Daten&uuml;bertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details finden Sie hier:{' '}
            <a href="https://www.whatsapp.com/legal/business-data-transfer-addendum" target="_blank" rel="noopener noreferrer">
              https://www.whatsapp.com/legal/business-data-transfer-addendum
            </a>
            .
          </p>
          <p>
            Wir haben unsere WhatsApp-Accounts so eingestellt, dass es keinen automatischen Datenabgleich mit dem Adressbuch auf den im Einsatz
            befindlichen Smartphones macht.
          </p>
          <p>Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) mit dem oben genannten Anbieter geschlossen.</p>
          <h3>Registrierung auf dieser Website</h3>{' '}
          <p>
            Sie k&ouml;nnen sich auf dieser Website registrieren, um zus&auml;tzliche Funktionen auf der Seite zu nutzen. Die dazu eingegebenen Daten
            verwenden wir nur zum Zwecke der Nutzung des jeweiligen Angebotes oder Dienstes, f&uuml;r den Sie sich registriert haben. Die bei der
            Registrierung abgefragten Pflichtangaben m&uuml;ssen vollst&auml;ndig angegeben werden. Anderenfalls werden wir die Registrierung
            ablehnen.
          </p>{' '}
          <p>
            F&uuml;r wichtige &Auml;nderungen etwa beim Angebotsumfang oder bei technisch notwendigen &Auml;nderungen nutzen wir die bei der
            Registrierung angegebene E-Mail-Adresse, um Sie auf diesem Wege zu informieren.
          </p>{' '}
          <p>
            Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt zum Zwecke der Durchf&uuml;hrung des durch die Registrierung
            begr&uuml;ndeten Nutzungsverh&auml;ltnisses und ggf. zur Anbahnung weiterer Vertr&auml;ge (Art. 6 Abs. 1 lit. b DSGVO).
          </p>{' '}
          <p>
            Die bei der Registrierung erfassten Daten werden von uns gespeichert, solange Sie auf dieser Website registriert sind und werden
            anschlie&szlig;end gel&ouml;scht. Gesetzliche Aufbewahrungsfristen bleiben unber&uuml;hrt.
          </p>
          <h2>5. Analyse-Tools und Werbung</h2>
          <h3>Google Tag Manager</h3>{' '}
          <p>Wir setzen den Google Tag Manager ein. Anbieter ist die Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland.</p>{' '}
          <p>
            Der Google Tag Manager ist ein Tool, mit dessen Hilfe wir Tracking- oder Statistik-Tools und andere Technologien auf unserer Website
            einbinden k&ouml;nnen. Der Google Tag Manager selbst erstellt keine Nutzerprofile, speichert keine Cookies und nimmt keine
            eigenst&auml;ndigen Analysen vor. Er dient lediglich der Verwaltung und Ausspielung der &uuml;ber ihn eingebundenen Tools. Der Google Tag
            Manager erfasst jedoch Ihre IP-Adresse, die auch an das Mutterunternehmen von Google in die Vereinigten Staaten &uuml;bertragen werden
            kann.
          </p>{' '}
          <p>
            Der Einsatz des Google Tag Managers erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
            Interesse an einer schnellen und unkomplizierten Einbindung und Verwaltung verschiedener Tools auf seiner Website. Sofern eine
            entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und
            &sect; 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endger&auml;t des
            Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
          </p>
          <p>
            Das Unternehmen verf&uuml;gt &uuml;ber eine Zertifizierung nach dem &bdquo;EU-US Data Privacy Framework&ldquo; (DPF). Der DPF ist ein
            &Uuml;bereinkommen zwischen der Europ&auml;ischen Union und den USA, der die Einhaltung europ&auml;ischer Datenschutzstandards bei
            Datenverarbeitungen in den USA gew&auml;hrleisten soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese
            Datenschutzstandards einzuhalten. Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link:{' '}
            <a
              href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active
            </a>
          </p>
          <h3>Google Analytics</h3>{' '}
          <p>
            Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Ireland Limited (&bdquo;Google&ldquo;),
            Gordon House, Barrow Street, Dublin 4, Irland.
          </p>{' '}
          <p>
            Google Analytics erm&ouml;glicht es dem Websitebetreiber, das Verhalten der Websitebesucher zu analysieren. Hierbei erh&auml;lt der
            Websitebetreiber verschiedene Nutzungsdaten, wie z.&nbsp;B. Seitenaufrufe, Verweildauer, verwendete Betriebssysteme und Herkunft des
            Nutzers. Diese Daten werden in einer User-ID zusammengefasst und dem jeweiligen Endger&auml;t des Websitebesuchers zugeordnet.
          </p>{' '}
          <p>
            Des Weiteren k&ouml;nnen wir mit Google Analytics u.&nbsp;a. Ihre Maus- und Scrollbewegungen und Klicks aufzeichnen. Ferner verwendet
            Google Analytics verschiedene Modellierungsans&auml;tze, um die erfassten Datens&auml;tze zu erg&auml;nzen und setzt
            Machine-Learning-Technologien bei der Datenanalyse ein.
          </p>
          <p>
            Google Analytics verwendet Technologien, die die Wiedererkennung des Nutzers zum Zwecke der Analyse des Nutzerverhaltens erm&ouml;glichen
            (z.&nbsp;B. Cookies oder Device-Fingerprinting). Die von Google erfassten Informationen &uuml;ber die Benutzung dieser Website werden in
            der Regel an einen Server von Google in den USA &uuml;bertragen und dort gespeichert.
          </p>{' '}
          <p>
            Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG. Die
            Einwilligung ist jederzeit widerrufbar.
          </p>{' '}
          <p>
            Die Daten&uuml;bertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details finden Sie hier:{' '}
            <a href="https://privacy.google.com/businesses/controllerterms/mccs/" target="_blank" rel="noopener noreferrer">
              https://privacy.google.com/businesses/controllerterms/mccs/
            </a>
            .
          </p>{' '}
          <p>
            Das Unternehmen verf&uuml;gt &uuml;ber eine Zertifizierung nach dem &bdquo;EU-US Data Privacy Framework&ldquo; (DPF). Der DPF ist ein
            &Uuml;bereinkommen zwischen der Europ&auml;ischen Union und den USA, der die Einhaltung europ&auml;ischer Datenschutzstandards bei
            Datenverarbeitungen in den USA gew&auml;hrleisten soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese
            Datenschutzstandards einzuhalten. Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link:{' '}
            <a
              href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active
            </a>
          </p>
          <h4>Browser Plugin</h4>{' '}
          <p>
            Sie k&ouml;nnen die Erfassung und Verarbeitung Ihrer Daten durch Google verhindern, indem Sie das unter dem folgenden Link verf&uuml;gbare
            Browser-Plugin herunterladen und installieren:{' '}
            <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noopener noreferrer">
              https://tools.google.com/dlpage/gaoptout?hl=de
            </a>
            .
          </p>{' '}
          <p>
            Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerkl&auml;rung von Google:{' '}
            <a href="https://support.google.com/analytics/answer/6004245?hl=de" target="_blank" rel="noopener noreferrer">
              https://support.google.com/analytics/answer/6004245?hl=de
            </a>
            .
          </p>
          <h4>Google-Signale</h4>{' '}
          <p>
            Wir nutzen Google-Signale. Wenn Sie unsere Website besuchen, erfasst Google Analytics u.&nbsp;a. Ihren Standort, Suchverlauf und
            YouTube-Verlauf sowie demografische Daten (Besucherdaten). Diese Daten k&ouml;nnen mit Hilfe von Google-Signal f&uuml;r personalisierte
            Werbung verwendet werden. Wenn Sie &uuml;ber ein Google-Konto verf&uuml;gen, werden die Besucherdaten von Google-Signal mit Ihrem
            Google-Konto verkn&uuml;pft und f&uuml;r personalisierte Werbebotschaften verwendet. Die Daten werden au&szlig;erdem f&uuml;r die
            Erstellung anonymisierter Statistiken zum Nutzerverhalten unserer User verwendet.
          </p>
          <h4>Auftragsverarbeitung</h4>{' '}
          <p>
            Wir haben mit Google einen Vertrag zur Auftragsverarbeitung abgeschlossen und setzen die strengen Vorgaben der deutschen
            Datenschutzbeh&ouml;rden bei der Nutzung von Google Analytics vollst&auml;ndig um.
          </p>
          <h3>Clarity</h3>{' '}
          <p>
            Diese Website nutzt Clarity. Anbieter ist die Microsoft Ireland Operations Limited, One Microsoft Place, South County Business Park,
            Leopardstown, Dublin 18, Irland,{' '}
            <a href="https://docs.microsoft.com/en-us/clarity/" target="_blank" rel="noopener noreferrer">
              https://docs.microsoft.com/en-us/clarity/
            </a>{' '}
            (im Folgenden &bdquo;Clarity&ldquo;).
          </p>{' '}
          <p>
            Clarity ist ein Werkzeug zur Analyse des Nutzerverhaltens auf dieser Website. Hierbei erfasst Clarity insbesondere Mausbewegungen und
            erstellt eine grafische Darstellung dar&uuml;ber, auf welchen Teil der Website Nutzer besonders h&auml;ufig scrollen (Heatmaps). Clarity
            kann ferner Sitzungen aufzeichnen, sodass wir die Seitennutzung in Form von Videos ansehen k&ouml;nnen. Ferner erhalten wir Angaben
            &uuml;ber das allgemeine Nutzerverhalten innerhalb unserer Website.
          </p>{' '}
          <p>
            Clarity verwendet Technologien, die die Wiedererkennung des Nutzers zum Zwecke der Analyse des Nutzerverhaltens erm&ouml;glichen
            (z.&nbsp;B. Cookies oder Einsatz von Device-Fingerprinting). Ihre personenbezogenen Daten werden auf den Servern von Microsoft (Microsoft
            Azure Cloud Service) in den USA gespeichert.
          </p>{' '}
          <p>
            Soweit eine Einwilligung (Consent) eingeholt wurde, erfolgt der Einsatz des o.&nbsp;g. Dienstes ausschlie&szlig;lich auf Grundlage von
            Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Soweit keine Einwilligung eingeholt wurde,
            erfolgt die Verwendung dieses Dienstes auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO; der Websitebetreiber hat ein berechtigtes Interesse
            an einer effektiven Nutzeranalyse.
          </p>{' '}
          <p>
            Weitere Details zum Datenschutz von Clarity finden Sie hier:{' '}
            <a href="https://docs.microsoft.com/en-us/clarity/faq" target="_blank" rel="noopener noreferrer">
              https://docs.microsoft.com/en-us/clarity/faq
            </a>
            .
          </p>
          <p>
            Das Unternehmen verf&uuml;gt &uuml;ber eine Zertifizierung nach dem &bdquo;EU-US Data Privacy Framework&ldquo; (DPF). Der DPF ist ein
            &Uuml;bereinkommen zwischen der Europ&auml;ischen Union und den USA, der die Einhaltung europ&auml;ischer Datenschutzstandards bei
            Datenverarbeitungen in den USA gew&auml;hrleisten soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese
            Datenschutzstandards einzuhalten. Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link:{' '}
            <a
              href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000KzNaAAK&status=Active"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000KzNaAAK&status=Active
            </a>
          </p>
          <h4>Auftragsverarbeitung</h4>{' '}
          <p>
            Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es sich
            um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gew&auml;hrleistet, dass dieser die personenbezogenen Daten unserer
            Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
          </p>
          <h3>Google Ads</h3>{' '}
          <p>
            Der Websitebetreiber verwendet Google Ads. Google Ads ist ein Online-Werbeprogramm der Google Ireland Limited (&bdquo;Google&ldquo;),
            Gordon House, Barrow Street, Dublin 4, Irland.
          </p>{' '}
          <p>
            Google Ads erm&ouml;glicht es uns Werbeanzeigen in der Google-Suchmaschine oder auf Drittwebseiten auszuspielen, wenn der Nutzer bestimmte
            Suchbegriffe bei Google eingibt (Keyword-Targeting). Ferner k&ouml;nnen zielgerichtete Werbeanzeigen anhand der bei Google vorhandenen
            Nutzerdaten (z.&nbsp;B. Standortdaten und Interessen) ausgespielt werden (Zielgruppen-Targeting). Wir als Websitebetreiber k&ouml;nnen
            diese Daten quantitativ auswerten, indem wir beispielsweise analysieren, welche Suchbegriffe zur Ausspielung unserer Werbeanzeigen
            gef&uuml;hrt haben und wie viele Anzeigen zu entsprechenden Klicks gef&uuml;hrt haben.
          </p>{' '}
          <p>
            Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG. Die
            Einwilligung ist jederzeit widerrufbar.
          </p>{' '}
          <p>
            Die Daten&uuml;bertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details finden Sie hier:{' '}
            <a href="https://policies.google.com/privacy/frameworks" target="_blank" rel="noopener noreferrer">
              https://policies.google.com/privacy/frameworks
            </a>{' '}
            und{' '}
            <a href="https://privacy.google.com/businesses/controllerterms/mccs/" target="_blank" rel="noopener noreferrer">
              https://privacy.google.com/businesses/controllerterms/mccs/
            </a>
            .
          </p>
          <p>
            Das Unternehmen verf&uuml;gt &uuml;ber eine Zertifizierung nach dem &bdquo;EU-US Data Privacy Framework&ldquo; (DPF). Der DPF ist ein
            &Uuml;bereinkommen zwischen der Europ&auml;ischen Union und den USA, der die Einhaltung europ&auml;ischer Datenschutzstandards bei
            Datenverarbeitungen in den USA gew&auml;hrleisten soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese
            Datenschutzstandards einzuhalten. Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link:{' '}
            <a
              href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active
            </a>
          </p>
          <h3>Google AdSense (nicht personalisiert)</h3>{' '}
          <p>
            Diese Website nutzt Google AdSense, einen Dienst zum Einbinden von Werbeanzeigen. Anbieter ist die Google Ireland Limited
            (&bdquo;Google&ldquo;), Gordon House, Barrow Street, Dublin 4, Irland.
          </p>{' '}
          <p>
            Wir nutzen Google AdSense im &bdquo;nicht-personalisierten&ldquo; Modus. Im Gegensatz zum personalisierten Modus beruhen die Werbeanzeigen
            daher nicht auf Ihrem fr&uuml;heren Nutzerverhalten und es wird kein Nutzerprofil von Ihnen erstellt. Stattdessen werden bei der Auswahl
            der Werbung sogenannte &bdquo;Kontextinformationen&ldquo; herangezogen. Die ausgew&auml;hlten Werbeanzeigen richten sich dann
            beispielsweise nach Ihrem Standort, dem Inhalt der Website, auf der Sie sich befinden oder nach Ihren aktuellen Suchbegriffen. Mehr zu den
            Unterschieden zwischen personalisiertem und nicht-personalisiertem Targeting mit Google AdSense finden Sie unter:{' '}
            <a href="https://support.google.com/adsense/answer/9007336" target="_blank" rel="noopener noreferrer">
              https://support.google.com/adsense/answer/9007336
            </a>
            .
          </p>{' '}
          <p>
            Bitte beachten Sie, dass auch beim Einsatz von Google Adsense im nicht-personalisierten Modus Cookies oder vergleichbare
            Wiedererkennungstechnologien (z.&nbsp;B. Device-Fingerprinting) verwendet werden k&ouml;nnen. Diese werden laut Google zur Bek&auml;mpfung
            von Betrug und Missbrauch eingesetzt.
          </p>{' '}
          <p>
            Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG. Die
            Einwilligung ist jederzeit widerrufbar.
          </p>{' '}
          <p>
            Die Daten&uuml;bertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details finden Sie hier:{' '}
            <a href="https://privacy.google.com/businesses/controllerterms/mccs/" target="_blank" rel="noopener noreferrer">
              https://privacy.google.com/businesses/controllerterms/mccs/
            </a>
            .
          </p>{' '}
          <p>
            Sie k&ouml;nnen Ihre Werbeeinstellungen selbstst&auml;ndig in Ihrem Nutzer-Account anpassen. Klicken Sie hierzu auf folgenden Link und
            loggen Sie sich ein:{' '}
            <a href="https://adssettings.google.com/authenticated" target="_blank" rel="noopener noreferrer">
              https://adssettings.google.com/authenticated
            </a>
            .
          </p>{' '}
          <p>
            Weitere Informationen zu den Werbetechnologien von Google finden Sie hier:{' '}
            <a href="https://policies.google.com/technologies/ads" target="_blank" rel="noopener noreferrer">
              https://policies.google.com/technologies/ads
            </a>{' '}
            und{' '}
            <a href="https://www.google.de/intl/de/policies/privacy/" target="_blank" rel="noopener noreferrer">
              https://www.google.de/intl/de/policies/privacy/
            </a>
            .
          </p>
          <p>
            Das Unternehmen verf&uuml;gt &uuml;ber eine Zertifizierung nach dem &bdquo;EU-US Data Privacy Framework&ldquo; (DPF). Der DPF ist ein
            &Uuml;bereinkommen zwischen der Europ&auml;ischen Union und den USA, der die Einhaltung europ&auml;ischer Datenschutzstandards bei
            Datenverarbeitungen in den USA gew&auml;hrleisten soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese
            Datenschutzstandards einzuhalten. Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link:{' '}
            <a
              href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active
            </a>
          </p>
          <h3>Google Ads Remarketing</h3>{' '}
          <p>
            Diese Website nutzt die Funktionen von Google Ads Remarketing. Anbieter ist die Google Ireland Limited (&bdquo;Google&ldquo;), Gordon
            House, Barrow Street, Dublin 4, Irland.
          </p>{' '}
          <p>
            Mit Google Ads Remarketing k&ouml;nnen wir Personen, die mit unserem Online-Angebot interagieren bestimmten Zielgruppen zuordnen, um ihnen
            anschlie&szlig;end interessenbezogene Werbung im Google-Werbenetzwerk anzeigen zu lassen (Remarketing bzw. Retargeting).
          </p>{' '}
          <p>
            Des Weiteren k&ouml;nnen die mit Google Ads Remarketing erstellten Werbe-Zielgruppen mit den ger&auml;te&uuml;bergreifenden Funktionen von
            Google verkn&uuml;pft werden. Auf diese Weise k&ouml;nnen interessenbezogene, personalisierte Werbebotschaften, die in Abh&auml;ngigkeit
            Ihres fr&uuml;heren Nutzungs- und Surfverhaltens auf einem Endger&auml;t (z.&nbsp;B. Handy) an Sie angepasst wurden auch auf einem anderen
            Ihrer Endger&auml;te (z.&nbsp;B. Tablet oder PC) angezeigt werden.
          </p>{' '}
          <p>
            Wenn Sie &uuml;ber einen Google-Account verf&uuml;gen, k&ouml;nnen Sie der personalisierten Werbung unter folgendem Link widersprechen:{' '}
            <a href="https://www.google.com/settings/ads/onweb/" target="_blank" rel="noopener noreferrer">
              https://www.google.com/settings/ads/onweb/
            </a>
            .
          </p>{' '}
          <p>
            Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG. Die
            Einwilligung ist jederzeit widerrufbar.
          </p>{' '}
          <p>
            Weitergehende Informationen und die Datenschutzbestimmungen finden Sie in der Datenschutzerkl&auml;rung von Google unter:{' '}
            <a href="https://policies.google.com/technologies/ads?hl=de" target="_blank" rel="noopener noreferrer">
              https://policies.google.com/technologies/ads?hl=de
            </a>
            .
          </p>
          <p>
            Das Unternehmen verf&uuml;gt &uuml;ber eine Zertifizierung nach dem &bdquo;EU-US Data Privacy Framework&ldquo; (DPF). Der DPF ist ein
            &Uuml;bereinkommen zwischen der Europ&auml;ischen Union und den USA, der die Einhaltung europ&auml;ischer Datenschutzstandards bei
            Datenverarbeitungen in den USA gew&auml;hrleisten soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese
            Datenschutzstandards einzuhalten. Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link:{' '}
            <a
              href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active
            </a>
          </p>
          <h4>Zielgruppenbildung mit Kundenabgleich</h4>{' '}
          <p>
            Zur Zielgruppenbildung verwenden wir unter anderem den Kundenabgleich von Google Ads Remarketing. Hierbei &uuml;bergeben wir bestimmte
            Kundendaten (z.&nbsp;B. E-Mail-Adressen) aus unseren Kundenlisten an Google. Sind die betreffenden Kunden Google-Nutzer und in ihrem
            Google-Konto eingeloggt, werden ihnen passende Werbebotschaften innerhalb des Google-Netzwerks (z.&nbsp;B. bei YouTube, Gmail oder in der
            Suchmaschine) angezeigt.
          </p>
          <h3>Google Conversion-Tracking</h3>{' '}
          <p>
            Diese Website nutzt Google Conversion Tracking. Anbieter ist die Google Ireland Limited (&bdquo;Google&ldquo;), Gordon House, Barrow
            Street, Dublin 4, Irland.
          </p>{' '}
          <p>
            Mit Hilfe von Google-Conversion-Tracking k&ouml;nnen Google und wir erkennen, ob der Nutzer bestimmte Aktionen durchgef&uuml;hrt hat. So
            k&ouml;nnen wir beispielsweise auswerten, welche Buttons auf unserer Website wie h&auml;ufig geklickt und welche Produkte besonders
            h&auml;ufig angesehen oder gekauft wurden. Diese Informationen dienen dazu, Conversion-Statistiken zu erstellen. Wir erfahren die
            Gesamtanzahl der Nutzer, die auf unsere Anzeigen geklickt haben und welche Aktionen sie durchgef&uuml;hrt haben. Wir erhalten keine
            Informationen, mit denen wir den Nutzer pers&ouml;nlich identifizieren k&ouml;nnen. Google selbst nutzt zur Identifikation Cookies oder
            vergleichbare Wiedererkennungstechnologien.
          </p>{' '}
          <p>
            Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG. Die
            Einwilligung ist jederzeit widerrufbar.
          </p>{' '}
          <p>
            Mehr Informationen zu Google Conversion-Tracking finden Sie in den Datenschutzbestimmungen von Google:{' '}
            <a href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noopener noreferrer">
              https://policies.google.com/privacy?hl=de
            </a>
            .
          </p>
          <p>
            Das Unternehmen verf&uuml;gt &uuml;ber eine Zertifizierung nach dem &bdquo;EU-US Data Privacy Framework&ldquo; (DPF). Der DPF ist ein
            &Uuml;bereinkommen zwischen der Europ&auml;ischen Union und den USA, der die Einhaltung europ&auml;ischer Datenschutzstandards bei
            Datenverarbeitungen in den USA gew&auml;hrleisten soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese
            Datenschutzstandards einzuhalten. Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link:{' '}
            <a
              href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active
            </a>
          </p>
          <h3>Meta-Pixel (ehemals Facebook Pixel)</h3>{' '}
          <p>
            Diese Website nutzt zur Konversionsmessung der Besucheraktions-Pixel von Facebook/Meta. Anbieter dieses Dienstes ist die Meta Platforms
            Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland. Die erfassten Daten werden nach Aussage von Facebook jedoch auch in die USA und
            in andere Drittl&auml;nder &uuml;bertragen.
          </p>{' '}
          <p>
            So kann das Verhalten der Seitenbesucher nachverfolgt werden, nachdem diese durch Klick auf eine Facebook-Werbeanzeige auf die Website des
            Anbieters weitergeleitet wurden. Dadurch k&ouml;nnen die Wirksamkeit der Facebook-Werbeanzeigen f&uuml;r statistische und
            Marktforschungszwecke ausgewertet werden und zuk&uuml;nftige Werbema&szlig;nahmen optimiert werden.
          </p>{' '}
          <p>
            Die erhobenen Daten sind f&uuml;r uns als Betreiber dieser Website anonym, wir k&ouml;nnen keine R&uuml;ckschl&uuml;sse auf die
            Identit&auml;t der Nutzer ziehen. Die Daten werden aber von Facebook gespeichert und verarbeitet, sodass eine Verbindung zum jeweiligen
            Nutzerprofil m&ouml;glich ist und Facebook die Daten f&uuml;r eigene Werbezwecke, entsprechend der Facebook-Datenverwendungsrichtlinie (
            <a href="https://de-de.facebook.com/about/privacy/" target="_blank" rel="noopener noreferrer">
              https://de-de.facebook.com/about/privacy/
            </a>
            ) verwenden kann. Dadurch kann Facebook das Schalten von Werbeanzeigen auf Seiten von Facebook sowie au&szlig;erhalb von Facebook
            erm&ouml;glichen. Diese Verwendung der Daten kann von uns als Seitenbetreiber nicht beeinflusst werden.
          </p>{' '}
          <p>
            Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG. Die
            Einwilligung ist jederzeit widerrufbar.
          </p>{' '}
          <p>Wir nutzen die Funktion des erweiterten Abgleichs innerhalb der Meta-Pixel.</p>{' '}
          <p>
            Der erweiterte Abgleich erm&ouml;glicht uns, verschiedene Arten von Daten (z.&nbsp;B. Wohnort, Bundesland, Postleitzahl, gehashte
            E-Mail-Adressen, Namen, Geschlecht, Geburtsdatum oder Telefonnummer) unserer Kunden und Interessenten, die wir &uuml;ber unsere Website
            sammeln an Meta (Facebook) zu &uuml;bermitteln. Durch diese Aktivierung k&ouml;nnen wir unsere Werbekampagnen auf Facebook noch
            pr&auml;ziser auf Personen zuschneiden, die sich f&uuml;r unsere Angebote interessieren. Au&szlig;erdem verbessert der erweiterte Abgleich
            Zuordnung von Webseiten-Conversions und erweitert Custom Audiences.
          </p>
          <p>
            Soweit mit Hilfe des hier beschriebenen Tools personenbezogene Daten auf unserer Website erfasst und an Facebook weitergeleitet werden,
            sind wir und die Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland gemeinsam f&uuml;r diese
            Datenverarbeitung verantwortlich (Art. 26 DSGVO). Die gemeinsame Verantwortlichkeit beschr&auml;nkt sich dabei ausschlie&szlig;lich auf
            die Erfassung der Daten und deren Weitergabe an Facebook. Die nach der Weiterleitung erfolgende Verarbeitung durch Facebook ist nicht Teil
            der gemeinsamen Verantwortung. Die uns gemeinsam obliegenden Verpflichtungen wurden in einer Vereinbarung &uuml;ber gemeinsame
            Verarbeitung festgehalten. Den Wortlaut der Vereinbarung finden Sie unter:{' '}
            <a href="https://www.facebook.com/legal/controller_addendum" target="_blank" rel="noopener noreferrer">
              https://www.facebook.com/legal/controller_addendum
            </a>
            . Laut dieser Vereinbarung sind wir f&uuml;r die Erteilung der Datenschutzinformationen beim Einsatz des Facebook-Tools und f&uuml;r die
            datenschutzrechtlich sichere Implementierung des Tools auf unserer Website verantwortlich. F&uuml;r die Datensicherheit der
            Facebook-Produkte ist Facebook verantwortlich. Betroffenenrechte (z.&nbsp;B. Auskunftsersuchen) hinsichtlich der bei Facebook
            verarbeiteten Daten k&ouml;nnen Sie direkt bei Facebook geltend machen. Wenn Sie die Betroffenenrechte bei uns geltend machen, sind wir
            verpflichtet, diese an Facebook weiterzuleiten.
          </p>{' '}
          <p>
            Die Daten&uuml;bertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details finden Sie hier:{' '}
            <a href="https://www.facebook.com/legal/EU_data_transfer_addendum" target="_blank" rel="noopener noreferrer">
              https://www.facebook.com/legal/EU_data_transfer_addendum
            </a>{' '}
            und{' '}
            <a href="https://de-de.facebook.com/help/566994660333381" target="_blank" rel="noopener noreferrer">
              https://de-de.facebook.com/help/566994660333381
            </a>
            .
          </p>{' '}
          <p>
            In den Datenschutzhinweisen von Facebook finden Sie weitere Hinweise zum Schutz Ihrer Privatsph&auml;re:{' '}
            <a href="https://de-de.facebook.com/about/privacy/" target="_blank" rel="noopener noreferrer">
              https://de-de.facebook.com/about/privacy/
            </a>
            .
          </p>{' '}
          <p>
            Sie k&ouml;nnen au&szlig;erdem die Remarketing-Funktion &bdquo;Custom Audiences&rdquo; im Bereich Einstellungen f&uuml;r Werbeanzeigen
            unter{' '}
            <a href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen" target="_blank" rel="noopener noreferrer">
              https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen
            </a>{' '}
            deaktivieren. Dazu m&uuml;ssen Sie bei Facebook angemeldet sein.
          </p>{' '}
          <p>
            Wenn Sie kein Facebook Konto besitzen, k&ouml;nnen Sie nutzungsbasierte Werbung von Facebook auf der Website der European Interactive
            Digital Advertising Alliance deaktivieren:{' '}
            <a href="http://www.youronlinechoices.com/de/praferenzmanagement/" target="_blank" rel="noopener noreferrer">
              http://www.youronlinechoices.com/de/praferenzmanagement/
            </a>
            .
          </p>
          <p>
            Das Unternehmen verf&uuml;gt &uuml;ber eine Zertifizierung nach dem &bdquo;EU-US Data Privacy Framework&ldquo; (DPF). Der DPF ist ein
            &Uuml;bereinkommen zwischen der Europ&auml;ischen Union und den USA, der die Einhaltung europ&auml;ischer Datenschutzstandards bei
            Datenverarbeitungen in den USA gew&auml;hrleisten soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese
            Datenschutzstandards einzuhalten. Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link:{' '}
            <a
              href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000GnywAAC&status=Active"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000GnywAAC&status=Active
            </a>
          </p>
          <h3>Facebook Conversion API</h3>{' '}
          <p>
            Wir haben Facebook Conversion API auf dieser Website eingebunden. Anbieter dieses Dienstes ist die Meta Platforms Ireland Limited, 4 Grand
            Canal Square, Dublin 2, Irland. Die erfassten Daten werden nach Aussage von Facebook jedoch auch in die USA und in andere Drittl&auml;nder
            &uuml;bertragen.
          </p>{' '}
          <p>
            Facebook Conversion API erm&ouml;glicht es uns, die Interaktionen des Websitebesuchers mit unserer Website zu erfassen und an Facebook
            weiterzugeben, um die Werbeperformance bei Facebook zu verbessern.
          </p>{' '}
          <p>
            Hierzu werden insbesondere Zeitpunkt des Aufrufes, die aufgerufene Webseite, Ihre IP-Adresse und Ihr User-Agent sowie ggf. weitere
            spezifische Daten (z.&nbsp;B. gekaufte Produkte, Wert des Warenkorbs und W&auml;hrung) erfasst. Eine vollst&auml;ndige &Uuml;bersicht
            &uuml;ber die erfassbaren Daten finden Sie hier:{' '}
            <a href="https://developers.facebook.com/docs/marketing-api/conversions-api/parameters" target="_blank" rel="noopener noreferrer">
              https://developers.facebook.com/docs/marketing-api/conversions-api/parameters
            </a>
            .
          </p>{' '}
          <p>
            Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG. Die
            Einwilligung ist jederzeit widerrufbar.
          </p>{' '}
          <p>
            Soweit mit Hilfe des hier beschriebenen Tools personenbezogene Daten auf unserer Website erfasst und an Facebook weitergeleitet werden,
            sind wir und die Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland gemeinsam f&uuml;r diese
            Datenverarbeitung verantwortlich (Art. 26 DSGVO). Die gemeinsame Verantwortlichkeit beschr&auml;nkt sich dabei ausschlie&szlig;lich auf
            die Erfassung der Daten und deren Weitergabe an Facebook. Die nach der Weiterleitung erfolgende Verarbeitung durch Facebook ist nicht Teil
            der gemeinsamen Verantwortung. Die uns gemeinsam obliegenden Verpflichtungen wurden in einer Vereinbarung &uuml;ber gemeinsame
            Verarbeitung festgehalten. Den Wortlaut der Vereinbarung finden Sie unter:{' '}
            <a href="https://www.facebook.com/legal/controller_addendum" target="_blank" rel="noopener noreferrer">
              https://www.facebook.com/legal/controller_addendum
            </a>
            . Laut dieser Vereinbarung sind wir f&uuml;r die Erteilung der Datenschutzinformationen beim Einsatz des Facebook-Tools und f&uuml;r die
            datenschutzrechtlich sichere Implementierung des Tools auf unserer Website verantwortlich. F&uuml;r die Datensicherheit der
            Facebook-Produkte ist Facebook verantwortlich. Betroffenenrechte (z.&nbsp;B. Auskunftsersuchen) hinsichtlich der bei Facebook
            verarbeiteten Daten k&ouml;nnen Sie direkt bei Facebook geltend machen. Wenn Sie die Betroffenenrechte bei uns geltend machen, sind wir
            verpflichtet, diese an Facebook weiterzuleiten.
          </p>{' '}
          <p>
            Die Daten&uuml;bertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details finden Sie hier:{' '}
            <a href="https://www.facebook.com/legal/EU_data_transfer_addendum" target="_blank" rel="noopener noreferrer">
              https://www.facebook.com/legal/EU_data_transfer_addendum
            </a>{' '}
            und{' '}
            <a href="https://de-de.facebook.com/help/566994660333381" target="_blank" rel="noopener noreferrer">
              https://de-de.facebook.com/help/566994660333381
            </a>
            .
          </p>{' '}
          <p>
            In den Datenschutzhinweisen von Facebook finden Sie weitere Hinweise zum Schutz Ihrer Privatsph&auml;re:{' '}
            <a href="https://de-de.facebook.com/about/privacy/" target="_blank" rel="noopener noreferrer">
              https://de-de.facebook.com/about/privacy/
            </a>
            .
          </p>
          <p>
            Das Unternehmen verf&uuml;gt &uuml;ber eine Zertifizierung nach dem &bdquo;EU-US Data Privacy Framework&ldquo; (DPF). Der DPF ist ein
            &Uuml;bereinkommen zwischen der Europ&auml;ischen Union und den USA, der die Einhaltung europ&auml;ischer Datenschutzstandards bei
            Datenverarbeitungen in den USA gew&auml;hrleisten soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese
            Datenschutzstandards einzuhalten. Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link:{' '}
            <a
              href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000GnywAAC&status=Active"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000GnywAAC&status=Active
            </a>
          </p>
          <h4>Auftragsverarbeitung</h4>{' '}
          <p>
            Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es sich
            um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gew&auml;hrleistet, dass dieser die personenbezogenen Daten unserer
            Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
          </p>
          <h3>Facebook Custom Audiences</h3>{' '}
          <p>
            Wir nutzen Facebook Custom Audiences. Anbieter dieses Dienstes ist die Meta Platforms Ireland Limited, 4 Grand Canal Square, Dublin 2,
            Irland.
          </p>{' '}
          <p>
            Wenn Sie unsere Webseiten und Apps besuchen bzw. nutzen, unsere kostenlosen oder kostenpflichtigen Angebote in Anspruch nehmen, Daten an
            uns &uuml;bermitteln oder mit den Facebook-Inhalten unseres Unternehmens interagieren, erfassen wir hierbei Ihre personenbezogenen Daten.
            Sofern Sie uns eine Einwilligung zur Nutzung von Facebook Custom Audiences erteilen, werden wir diese Daten an Facebook &uuml;bermitteln,
            mit denen Facebook Ihnen passende Werbung ausspielen kann. Des Weiteren k&ouml;nnen mit Ihren Daten Zielgruppen definiert werden
            (Lookalike Audiences).
          </p>{' '}
          <p>
            Facebook verarbeitet diese Daten als unser Auftragsverarbeiter. Details sind der Nutzungsvereinbarung von Facebook zu entnehmen:{' '}
            <a href="https://www.facebook.com/legal/terms/customaudience" target="_blank" rel="noopener noreferrer">
              https://www.facebook.com/legal/terms/customaudience
            </a>
            .
          </p>{' '}
          <p>
            Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG. Die
            Einwilligung ist jederzeit widerrufbar.
          </p>{' '}
          <p>
            Die Daten&uuml;bertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details finden Sie hier:{' '}
            <a href="https://www.facebook.com/legal/terms/customaudience" target="_blank" rel="noopener noreferrer">
              https://www.facebook.com/legal/terms/customaudience
            </a>{' '}
            und{' '}
            <a href="https://www.facebook.com/legal/terms/dataprocessing" target="_blank" rel="noopener noreferrer">
              https://www.facebook.com/legal/terms/dataprocessing
            </a>
            .
          </p>
          <p>
            Das Unternehmen verf&uuml;gt &uuml;ber eine Zertifizierung nach dem &bdquo;EU-US Data Privacy Framework&ldquo; (DPF). Der DPF ist ein
            &Uuml;bereinkommen zwischen der Europ&auml;ischen Union und den USA, der die Einhaltung europ&auml;ischer Datenschutzstandards bei
            Datenverarbeitungen in den USA gew&auml;hrleisten soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese
            Datenschutzstandards einzuhalten. Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link:{' '}
            <a
              href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000GnywAAC&status=Active"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000GnywAAC&status=Active
            </a>
          </p>
          <h3>TikTok Pixel</h3>{' '}
          <p>
            Wir haben das TikTok Pixel auf dieser Website eingebunden. Anbieter ist die TikTok Technology Limited, 10 Earlsfort Terrace, Dublin, D02
            T380, Irland (nachfolgend TikTok).
          </p>{' '}
          <p>
            Mit Hilfe von TikTok Pixel k&ouml;nnen wir Websitebesuchern, die sich unsere Angebote angesehen haben, interessengerechte Werbung bei
            TikTok anzeigen (TikTok Ads). Gleichzeitig k&ouml;nnen wir mit Hilfe des TikTok Pixels feststellen, wie effektiv unsere Werbung bei TikTok
            ist. Dadurch kann die Wirksamkeit der TikTok-Werbeanzeigen f&uuml;r statistische und Marktforschungszwecke ausgewertet und f&uuml;r
            zuk&uuml;nftige Werbema&szlig;nahmen optimiert werden. Hierbei werden verschiedene Nutzungsdaten verarbeitet, wie z.&nbsp;B. IP-Adresse,
            Seitenaufrufe, Verweildauer, verwendete Betriebssysteme und Herkunft des Nutzers, Informationen &uuml;ber die Anzeige, auf die eine Person
            auf TikTok geklickt hat oder ein Ereignis, das ausgel&ouml;st wurde (Zeitstempel). Diese Daten werden in einer User-ID zusammengefasst und
            dem jeweiligen Endger&auml;t des Websitebesuchers zugeordnet.
          </p>{' '}
          <p>
            Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG. Die
            Einwilligung ist jederzeit widerrufbar.
          </p>{' '}
          <p>
            Die Daten&uuml;bertragung in Drittstaaten wird auf die Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details finden Sie hier:{' '}
            <a href="https://www.tiktok.com/legal/page/eea/privacy-policy/de-DE" target="_blank" rel="noopener noreferrer">
              https://www.tiktok.com/legal/page/eea/privacy-policy/de-DE
            </a>{' '}
            und{' '}
            <a href="https://ads.tiktok.com/i18n/official/policy/controller-to-controller" target="_blank" rel="noopener noreferrer">
              https://ads.tiktok.com/i18n/official/policy/controller-to-controller
            </a>
            .
          </p>
          <h4>Auftragsverarbeitung</h4>{' '}
          <p>
            Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es sich
            um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gew&auml;hrleistet, dass dieser die personenbezogenen Daten unserer
            Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
          </p>
          <h2>6. Newsletter</h2>
          <h3>Newsletter&shy;daten</h3>{' '}
          <p>
            Wenn Sie den auf der Website angebotenen Newsletter beziehen m&ouml;chten, ben&ouml;tigen wir von Ihnen eine E-Mail-Adresse sowie
            Informationen, welche uns die &Uuml;berpr&uuml;fung gestatten, dass Sie der Inhaber der angegebenen E-Mail-Adresse sind und mit dem
            Empfang des Newsletters einverstanden sind. Weitere Daten werden nicht bzw. nur auf freiwilliger Basis erhoben. Diese Daten verwenden wir
            ausschlie&szlig;lich f&uuml;r den Versand der angeforderten Informationen und geben diese nicht an Dritte weiter.
          </p>{' '}
          <p>
            Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen Daten erfolgt ausschlie&szlig;lich auf Grundlage Ihrer Einwilligung
            (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des
            Newsletters k&ouml;nnen Sie jederzeit widerrufen, etwa &uuml;ber den &bdquo;Austragen&ldquo;-Link im Newsletter. Die
            Rechtm&auml;&szlig;igkeit der bereits erfolgten Datenverarbeitungsvorg&auml;nge bleibt vom Widerruf unber&uuml;hrt.
          </p>{' '}
          <p>
            Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter bei
            uns bzw. dem Newsletterdiensteanbieter gespeichert und nach der Abbestellung des Newsletters oder nach Zweckfortfall aus der
            Newsletterverteilerliste gel&ouml;scht. Wir behalten uns vor, E-Mail-Adressen aus unserem Newsletterverteiler nach eigenem Ermessen im
            Rahmen unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO zu l&ouml;schen oder zu sperren.
          </p>{' '}
          <p>Daten, die zu anderen Zwecken bei uns gespeichert wurden, bleiben hiervon unber&uuml;hrt.</p>{' '}
          <p>
            Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in einer
            Blacklist gespeichert, sofern dies zur Verhinderung k&uuml;nftiger Mailings erforderlich ist. Die Daten aus der Blacklist werden nur
            f&uuml;r diesen Zweck verwendet und nicht mit anderen Daten zusammengef&uuml;hrt. Dies dient sowohl Ihrem Interesse als auch unserem
            Interesse an der Einhaltung der gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit.
            f DSGVO). Die Speicherung in der Blacklist ist zeitlich nicht befristet.{' '}
            <strong>Sie k&ouml;nnen der Speicherung widersprechen, sofern Ihre Interessen unser berechtigtes Interesse &uuml;berwiegen.</strong>
          </p>
          <h2>7. Plugins und Tools</h2>
          <h3>Google reCAPTCHA</h3>{' '}
          <p>
            Wir nutzen &bdquo;Google reCAPTCHA&ldquo; (im Folgenden &bdquo;reCAPTCHA&ldquo;) auf dieser Website. Anbieter ist die Google Ireland
            Limited (&bdquo;Google&ldquo;), Gordon House, Barrow Street, Dublin 4, Irland.
          </p>{' '}
          <p>
            Mit reCAPTCHA soll &uuml;berpr&uuml;ft werden, ob die Dateneingabe auf dieser Website (z.&nbsp;B. in einem Kontaktformular) durch einen
            Menschen oder durch ein automatisiertes Programm erfolgt. Hierzu analysiert reCAPTCHA das Verhalten des Websitebesuchers anhand
            verschiedener Merkmale. Diese Analyse beginnt automatisch, sobald der Websitebesucher die Website betritt. Zur Analyse wertet reCAPTCHA
            verschiedene Informationen aus (z.&nbsp;B. IP-Adresse, Verweildauer des Websitebesuchers auf der Website oder vom Nutzer get&auml;tigte
            Mausbewegungen). Die bei der Analyse erfassten Daten werden an Google weitergeleitet.
          </p>{' '}
          <p>
            Die reCAPTCHA-Analysen laufen vollst&auml;ndig im Hintergrund. Websitebesucher werden nicht darauf hingewiesen, dass eine Analyse
            stattfindet.
          </p>{' '}
          <p>
            Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
            Interesse daran, seine Webangebote vor missbr&auml;uchlicher automatisierter Aussp&auml;hung und vor SPAM zu sch&uuml;tzen. Sofern eine
            entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und
            &sect; 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endger&auml;t des
            Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
          </p>{' '}
          <p>
            Weitere Informationen zu Google reCAPTCHA entnehmen Sie den Google-Datenschutzbestimmungen und den Google Nutzungsbedingungen unter
            folgenden Links:{' '}
            <a href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noopener noreferrer">
              https://policies.google.com/privacy?hl=de
            </a>{' '}
            und{' '}
            <a href="https://policies.google.com/terms?hl=de" target="_blank" rel="noopener noreferrer">
              https://policies.google.com/terms?hl=de
            </a>
            .
          </p>
          <p>
            Das Unternehmen verf&uuml;gt &uuml;ber eine Zertifizierung nach dem &bdquo;EU-US Data Privacy Framework&ldquo; (DPF). Der DPF ist ein
            &Uuml;bereinkommen zwischen der Europ&auml;ischen Union und den USA, der die Einhaltung europ&auml;ischer Datenschutzstandards bei
            Datenverarbeitungen in den USA gew&auml;hrleisten soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese
            Datenschutzstandards einzuhalten. Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link:{' '}
            <a
              href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active
            </a>
          </p>
          <h2>8. eCommerce und Zahlungs&shy;anbieter</h2>
          <h3>Verarbeiten von Kunden- und Vertragsdaten</h3>{' '}
          <p>
            Wir erheben, verarbeiten und nutzen personenbezogene Kunden- und Vertragsdaten zur Begr&uuml;ndung, inhaltlichen Ausgestaltung und
            &Auml;nderung unserer Vertragsbeziehungen. Personenbezogene Daten &uuml;ber die Inanspruchnahme dieser Website (Nutzungsdaten) erheben,
            verarbeiten und nutzen wir nur, soweit dies erforderlich ist, um dem Nutzer die Inanspruchnahme des Dienstes zu erm&ouml;glichen oder
            abzurechnen. Rechtsgrundlage hierf&uuml;r ist Art. 6 Abs. 1 lit. b DSGVO.
          </p>{' '}
          <p>
            Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder Beendigung der Gesch&auml;ftsbeziehung und Ablauf der ggf. bestehenden
            gesetzlichen Aufbewahrungsfristen gel&ouml;scht. Gesetzliche Aufbewahrungsfristen bleiben unber&uuml;hrt.
          </p>
          <h3>Daten&shy;&uuml;bermittlung bei Vertragsschluss f&uuml;r Online-Shops, H&auml;ndler und Warenversand</h3>{' '}
          <p>
            Wenn Sie Waren bei uns bestellen, geben wir Ihre personenbezogenen Daten an das zur Lieferung betraute Transportunternehmen sowie an den
            mit der Zahlungsabwicklung beauftragten Zahlungsdienstleister weiter. Es werden nur solche Daten herausgegeben, die der jeweilige
            Dienstleister zur Erf&uuml;llung seiner Aufgabe ben&ouml;tigt. Rechtsgrundlage hierf&uuml;r ist Art. 6 Abs. 1 lit. b DSGVO, der die
            Verarbeitung von Daten zur Erf&uuml;llung eines Vertrags oder vorvertraglicher Ma&szlig;nahmen gestattet. Sofern Sie eine entsprechende
            Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO erteilt haben, werden wir Ihre E-Mail-Adresse an das mit der Lieferung betraute
            Transportunternehmen &uuml;bergeben, damit dieses Sie per E-Mail &uuml;ber den Versandstatus Ihrer Bestellung informieren kann; Sie
            k&ouml;nnen die Einwilligung jederzeit widerrufen.
          </p>
          <h3>Daten&shy;&uuml;bermittlung bei Vertragsschluss f&uuml;r Dienstleistungen und digitale Inhalte</h3>{' '}
          <p>
            Wir &uuml;bermitteln personenbezogene Daten an Dritte nur dann, wenn dies im Rahmen der Vertragsabwicklung notwendig ist, etwa an das mit
            der Zahlungsabwicklung beauftragte Kreditinstitut.
          </p>{' '}
          <p>
            Eine weitergehende &Uuml;bermittlung der Daten erfolgt nicht bzw. nur dann, wenn Sie der &Uuml;bermittlung ausdr&uuml;cklich zugestimmt
            haben. Eine Weitergabe Ihrer Daten an Dritte ohne ausdr&uuml;ckliche Einwilligung, etwa zu Zwecken der Werbung, erfolgt nicht.
          </p>{' '}
          <p>
            Grundlage f&uuml;r die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erf&uuml;llung eines Vertrags
            oder vorvertraglicher Ma&szlig;nahmen gestattet.
          </p>
          <h3>Zahlungsdienste</h3>{' '}
          <p>
            Wir binden Zahlungsdienste von Drittunternehmen auf unserer Website ein. Wenn Sie einen Kauf bei uns t&auml;tigen, werden Ihre
            Zahlungsdaten (z.&nbsp;B. Name, Zahlungssumme, Kontoverbindung, Kreditkartennummer) vom Zahlungsdienstleister zum Zwecke der
            Zahlungsabwicklung verarbeitet. F&uuml;r diese Transaktionen gelten die jeweiligen Vertrags- und Datenschutzbestimmungen der jeweiligen
            Anbieter. Der Einsatz der Zahlungsdienstleister erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO (Vertragsabwicklung) sowie im
            Interesse eines m&ouml;glichst reibungslosen, komfortablen und sicheren Zahlungsvorgangs (Art. 6 Abs. 1 lit. f DSGVO). Soweit f&uuml;r
            bestimmte Handlungen Ihre Einwilligung abgefragt wird, ist Art. 6 Abs. 1 lit. a DSGVO Rechtsgrundlage der Datenverarbeitung;
            Einwilligungen sind jederzeit f&uuml;r die Zukunft widerrufbar.
          </p>
          <p>Folgende Zahlungsdienste / Zahlungsdienstleister setzen wir im Rahmen dieser Website ein:</p>
          <h4>Stripe</h4>{' '}
          <p>
            Anbieter f&uuml;r Kunden innerhalb der EU ist die Stripe Payments Europe, Ltd.,1 Grand Canal Street Lower, Grand Canal Dock, Dublin,
            Irland (im Folgenden &bdquo;Stripe&ldquo;).
          </p>{' '}
          <p>
            Die Daten&uuml;bertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details finden Sie hier:{' '}
            <a href="https://stripe.com/de/privacy" target="_blank" rel="noopener noreferrer">
              https://stripe.com/de/privacy
            </a>{' '}
            und{' '}
            <a href="https://stripe.com/de/guides/general-data-protection-regulation" target="_blank" rel="noopener noreferrer">
              https://stripe.com/de/guides/general-data-protection-regulation
            </a>
            .
          </p>{' '}
          <p>
            Details hierzu k&ouml;nnen Sie in der Datenschutzerkl&auml;rung von Stripe unter folgendem Link nachlesen:{' '}
            <a href="https://stripe.com/de/privacy" target="_blank" rel="noopener noreferrer">
              https://stripe.com/de/privacy
            </a>
            .
          </p>
          <h4>Klarna</h4>{' '}
          <p>
            Anbieter ist die Klarna AB, Sveav&auml;gen 46, 111 34 Stockholm, Schweden (im Folgenden &bdquo;Klarna&ldquo;). Klarna bietet verschiedene
            Zahlungsoptionen an (z.&nbsp;B. Ratenkauf). Wenn Sie sich f&uuml;r die Bezahlung mit Klarna entscheiden (Klarna-Checkout-L&ouml;sung),
            wird Klarna verschiedene personenbezogene Daten von Ihnen erheben. Klarna nutzt Cookies, um die Verwendung der Klarna-Checkout-L&ouml;sung
            zu optimieren. Details zum Einsatz von Klarna-Cookies entnehmen Sie folgendem Link:{' '}
            <a href="https://cdn.klarna.com/1.0/shared/content/policy/cookie/de_de/checkout.pdf" target="_blank" rel="noopener noreferrer">
              https://cdn.klarna.com/1.0/shared/content/policy/cookie/de_de/checkout.pdf
            </a>
            .
          </p>{' '}
          <p>
            Details hierzu k&ouml;nnen Sie in der Datenschutzerkl&auml;rung von Klarna unter folgendem Link nachlesen:{' '}
            <a href="https://www.klarna.com/de/datenschutz/" target="_blank" rel="noopener noreferrer">
              https://www.klarna.com/de/datenschutz/
            </a>
            .
          </p>
          <h4>Sofort&shy;&uuml;berweisung</h4>{' '}
          <p>
            Anbieter dieses Zahlungsdienstes ist die Sofort GmbH, Theresienh&ouml;he 12, 80339 M&uuml;nchen (im Folgenden &bdquo;Sofort GmbH&ldquo;).
            Mit Hilfe des Verfahrens &bdquo;Sofort&uuml;berweisung&ldquo; erhalten wir in Echtzeit eine Zahlungsbest&auml;tigung von der Sofort GmbH
            und k&ouml;nnen unverz&uuml;glich mit der Erf&uuml;llung unserer Verbindlichkeiten beginnen. Wenn Sie sich f&uuml;r die Zahlungsart
            &bdquo;Sofort&uuml;berweisung&ldquo; entschieden haben, &uuml;bermitteln Sie die PIN und eine g&uuml;ltige TAN an die Sofort GmbH, mit der
            diese sich in Ihr Online-Banking-Konto einloggen kann. Sofort GmbH &uuml;berpr&uuml;ft nach dem Einloggen automatisch Ihren Kontostand und
            f&uuml;hrt die &Uuml;berweisung an uns mit Hilfe der von Ihnen &uuml;bermittelten TAN durch. Anschlie&szlig;end &uuml;bermittelt sie uns
            unverz&uuml;glich eine Transaktionsbest&auml;tigung. Nach dem Einloggen werden au&szlig;erdem Ihre Ums&auml;tze, der Kreditrahmen des
            Dispokredits und das Vorhandensein anderer Konten sowie deren Best&auml;nde automatisiert gepr&uuml;ft. Neben der PIN und der TAN werden
            auch die von Ihnen eingegebenen Zahlungsdaten sowie Daten zu Ihrer Person an die Sofort GmbH &uuml;bermittelt. Bei den Daten zu Ihrer
            Person handelt es sich um Vor- und Nachnamen, Adresse, Telefonnummer(n), E-Mail-Adresse, IP-Adresse und ggf. weitere zur
            Zahlungsabwicklung erforderliche Daten. Die &Uuml;bermittlung dieser Daten ist notwendig, um Ihre Identit&auml;t zweifelsfrei zu
            festzustellen und Betrugsversuchen vorzubeugen. Details zur Zahlung mit Sofort&uuml;berweisung entnehmen Sie folgenden Links:{' '}
            <a href="https://www.sofort.de/datenschutz.html" target="_blank" rel="noopener noreferrer">
              https://www.sofort.de/datenschutz.html
            </a>{' '}
            und{' '}
            <a href="https://www.klarna.com/sofort/" target="_blank" rel="noopener noreferrer">
              https://www.klarna.com/sofort/
            </a>
            .
          </p>
          <h4>American Express</h4>{' '}
          <p>
            Anbieter dieses Zahlungsdienstes ist die American Express Europe S.A., Theodor-Heuss-Allee 112, 60486 Frankfurt am Main, Deutschland (im
            Folgenden &bdquo;American Express&ldquo;).
          </p>{' '}
          <p>
            American Express kann Daten an seine Muttergesellschaft in die USA &uuml;bermitteln. Die Daten&uuml;bertragung in die USA wird auf die
            Binding Corporate Rules gest&uuml;tzt. Details finden Sie hier:{' '}
            <a
              href="https://www.americanexpress.com/en-pl/company/legal/privacy-centre/european-implementing-principles/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.americanexpress.com/en-pl/company/legal/privacy-centre/european-implementing-principles/
            </a>
            .
          </p>{' '}
          <p>
            Weitere Informationen entnehmen Sie der Datenschutzerkl&auml;rung von American Express:{' '}
            <a href="https://www.americanexpress.com/de/legal/online-datenschutzerklarung.html" target="_blank" rel="noopener noreferrer">
              https://www.americanexpress.com/de/legal/online-datenschutzerklarung.html
            </a>
            .
          </p>
          <h4>Mastercard</h4>{' '}
          <p>
            Anbieter dieses Zahlungsdienstes ist die Mastercard Europe SA, Chauss&eacute;e de Tervuren 198A, B-1410 Waterloo, Belgien (im Folgenden
            &bdquo;Mastercard&ldquo;).
          </p>{' '}
          <p>
            Mastercard kann Daten an seine Muttergesellschaft in die USA &uuml;bermitteln. Die Daten&uuml;bertragung in die USA wird auf die Binding
            Corporate Rules von Mastercard gest&uuml;tzt. Details finden Sie hier:{' '}
            <a href="https://www.mastercard.de/de-de/datenschutz.html" target="_blank" rel="noopener noreferrer">
              https://www.mastercard.de/de-de/datenschutz.html
            </a>{' '}
            und{' '}
            <a href="https://www.mastercard.us/content/dam/mccom/global/documents/mastercard-bcrs.pdf" target="_blank" rel="noopener noreferrer">
              https://www.mastercard.us/content/dam/mccom/global/documents/mastercard-bcrs.pdf
            </a>
            .
          </p>
          <h4>VISA</h4>{' '}
          <p>
            Anbieter dieses Zahlungsdienstes ist die Visa Europe Services Inc., Zweigniederlassung London, 1 Sheldon Square, London W2 6TT,
            Gro&szlig;britannien (im Folgenden &bdquo;VISA&ldquo;).
          </p>{' '}
          <p>
            Gro&szlig;britannien gilt als datenschutzrechtlich sicherer Drittstaat. Das bedeutet, dass Gro&szlig;britannien ein Datenschutzniveau
            aufweist, das dem Datenschutzniveau in der Europ&auml;ischen Union entspricht.
          </p>{' '}
          <p>
            VISA kann Daten an seine Muttergesellschaft in die USA &uuml;bertragen. Die Daten&uuml;bertragung in die USA wird auf die
            Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details finden Sie hier:{' '}
            <a
              href="https://www.visa.de/nutzungsbedingungen/visa-globale-datenschutzmitteilung/mitteilung-zu-zustandigkeitsfragen-fur-den-ewr.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.visa.de/nutzungsbedingungen/visa-globale-datenschutzmitteilung/mitteilung-zu-zustandigkeitsfragen-fur-den-ewr.html
            </a>
            .
          </p>{' '}
          <p>
            Weitere Informationen entnehmen Sie der Datenschutzerkl&auml;rung von VISA:{' '}
            <a href="https://www.visa.de/nutzungsbedingungen/visa-privacy-center.html" target="_blank" rel="noopener noreferrer">
              https://www.visa.de/nutzungsbedingungen/visa-privacy-center.html
            </a>
            .
          </p>
          <h2>9. Eigene Dienste</h2>
          <h3>Umgang mit Bewerberdaten</h3>{' '}
          <p>
            Wir bieten Ihnen die M&ouml;glichkeit, sich bei uns zu bewerben (z.&nbsp;B. per E-Mail, postalisch oder via Online-Bewerberformular). Im
            Folgenden informieren wir Sie &uuml;ber Umfang, Zweck und Verwendung Ihrer im Rahmen des Bewerbungsprozesses erhobenen personenbezogenen
            Daten. Wir versichern, dass die Erhebung, Verarbeitung und Nutzung Ihrer Daten in &Uuml;bereinstimmung mit geltendem Datenschutzrecht und
            allen weiteren gesetzlichen Bestimmungen erfolgt und Ihre Daten streng vertraulich behandelt werden.
          </p>{' '}
          <h4>Umfang und Zweck der Datenerhebung </h4>{' '}
          <p>
            Wenn Sie uns eine Bewerbung zukommen lassen, verarbeiten wir Ihre damit verbundenen personenbezogenen Daten (z.&nbsp;B. Kontakt- und
            Kommunikationsdaten, Bewerbungsunterlagen, Notizen im Rahmen von Bewerbungsgespr&auml;chen etc.), soweit dies zur Entscheidung &uuml;ber
            die Begr&uuml;ndung eines Besch&auml;ftigungsverh&auml;ltnisses erforderlich ist. Rechtsgrundlage hierf&uuml;r ist &sect; 26 BDSG nach
            deutschem Recht (Anbahnung eines Besch&auml;ftigungsverh&auml;ltnisses), Art. 6 Abs. 1 lit. b DSGVO (allgemeine Vertragsanbahnung) und
            &ndash; sofern Sie eine Einwilligung erteilt haben &ndash; Art. 6 Abs. 1 lit. a DSGVO. Die Einwilligung ist jederzeit widerrufbar. Ihre
            personenbezogenen Daten werden innerhalb unseres Unternehmens ausschlie&szlig;lich an Personen weitergegeben, die an der Bearbeitung Ihrer
            Bewerbung beteiligt sind.
          </p>{' '}
          <p>
            Sofern die Bewerbung erfolgreich ist, werden die von Ihnen eingereichten Daten auf Grundlage von &sect; 26 BDSG und Art. 6 Abs. 1 lit. b
            DSGVO zum Zwecke der Durchf&uuml;hrung des Besch&auml;ftigungsverh&auml;ltnisses in unseren Datenverarbeitungssystemen gespeichert.
          </p>{' '}
          <h4>Aufbewahrungsdauer der Daten</h4>{' '}
          <p>
            Sofern wir Ihnen kein Stellenangebot machen k&ouml;nnen, Sie ein Stellenangebot ablehnen oder Ihre Bewerbung zur&uuml;ckziehen, behalten
            wir uns das Recht vor, die von Ihnen &uuml;bermittelten Daten auf Grundlage unserer berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO)
            bis zu 6 Monate ab der Beendigung des Bewerbungsverfahrens (Ablehnung oder Zur&uuml;ckziehung der Bewerbung) bei uns aufzubewahren.
            Anschlie&szlig;end werden die Daten gel&ouml;scht und die physischen Bewerbungsunterlagen vernichtet. Die Aufbewahrung dient insbesondere
            Nachweiszwecken im Falle eines Rechtsstreits. Sofern ersichtlich ist, dass die Daten nach Ablauf der 6-Monatsfrist erforderlich sein
            werden (z.&nbsp;B. aufgrund eines drohenden oder anh&auml;ngigen Rechtsstreits), findet eine L&ouml;schung erst statt, wenn der Zweck
            f&uuml;r die weitergehende Aufbewahrung entf&auml;llt.
          </p>{' '}
          <p>
            Eine l&auml;ngere Aufbewahrung kann au&szlig;erdem stattfinden, wenn Sie eine entsprechende Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
            erteilt haben oder wenn gesetzliche Aufbewahrungspflichten der L&ouml;schung entgegenstehen.
          </p>
          <h4>Aufnahme in den Bewerber-Pool</h4>{' '}
          <p>
            Sofern wir Ihnen kein Stellenangebot machen, besteht ggf. die M&ouml;glichkeit, Sie in unseren Bewerber-Pool aufzunehmen. Im Falle der
            Aufnahme werden alle Dokumente und Angaben aus der Bewerbung in den Bewerber-Pool &uuml;bernommen, um Sie im Falle von passenden Vakanzen
            zu kontaktieren.
          </p>{' '}
          <p>
            Die Aufnahme in den Bewerber-Pool geschieht ausschlie&szlig;lich auf Grundlage Ihrer ausdr&uuml;cklichen Einwilligung (Art. 6 Abs. 1 lit.
            a DSGVO). Die Abgabe der Einwilligung ist freiwillig und steht in keinem Bezug zum laufenden Bewerbungsverfahren.{' '}
            <strong>Der Betroffene kann seine Einwilligung jederzeit widerrufen.</strong> In diesem Falle werden die Daten aus dem Bewerber-Pool
            unwiderruflich gel&ouml;scht, sofern keine gesetzlichen Aufbewahrungsgr&uuml;nde vorliegen.
          </p>{' '}
          <p>Die Daten aus dem Bewerber-Pool werden sp&auml;testens zwei Jahre nach Erteilung der Einwilligung unwiderruflich gel&ouml;scht.</p>
        </div>
      </Container>
    </Layout>
  )
}

export default DataProtection
