import { MapPinIcon } from '@heroicons/react/24/outline'
import Location from '../../../types/Location'
import { prettyLocation } from '../../../utility'

type Props = {
  location: Location
  small?: boolean
  invert?: boolean
  className?: string
}

const LocationLabel: React.FC<Props> = ({ location, small, invert, className }) => {
  return (
    <div className={`flex flex-row items-center overflow-hidden ${className}`}>
      <MapPinIcon className={`h-4 shrink-0 ${small ? (invert ? 'text-white' : 'text-gray-primary') : ''}`} />
      <div className={`text-xs whitespace-nowrap truncate ${small ? (invert ? 'text-white' : 'text-gray-primary') : ''}`}>
        {prettyLocation(location)}
      </div>
    </div>
  )
}

export default LocationLabel
