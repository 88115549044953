import { socket } from '@think-internet/zeus-frontend-package'
import sockets from '../../../../../../redux/sockets'
import { useCallback, useEffect, useRef, useState } from 'react'
import Form from '../../../../../UI/Form/Form'
import NewButton from '../../../../../UI/NewButton/NewButton'
import Textarea from '../../../../../UI/Textarea/Textarea'
import NewInput from '../../../../../UI/NewInput/NewInput'
import { Message } from '../../../../../../types/Chat'
import { useSelector } from 'react-redux'
import props from '../../../../../../redux/props'
import DirectFileUpload, { OnCallbackFile, UPLOAD_TYPES } from '../../../../../UI/DirectFileUpload/DirectFileUpload'
import { TrashIcon } from '@heroicons/react/24/solid'
import GetIcon from '../../../../../UI/GetIcon/GetIcon'
import _ from 'lodash'

type Props = { projectUUID: string; addMessage: (message: Message) => void }

const Compose: React.FC<Props> = ({ projectUUID, addMessage }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [text, setText] = useState<string>('')
  const [showTextarea, setShowTextarea] = useState<boolean>(false)
  const [files, setFiles] = useState<OnCallbackFile[]>([])
  const textareaRef = useRef<HTMLTextAreaElement>()
  const inputRef = useRef<HTMLInputElement>()
  const send = socket.use(
    sockets.CHAT_MESSAGE,
    (data) => {
      if (data) {
        if (_.has(data, 'projectUUID') && data.projectUUID === projectUUID) {
          try {
            addMessage(data as Message)
          } catch (e) {
            console.log(e)
          }
        }
      }
    },
    { projectUUID },
  )

  const submit = useCallback(() => {
    if (text.trim().length > 0 || files.length > 0) {
      send({ text, files })
      setText('')
      setFiles([])
      setShowTextarea(false)
      setTimeout(() => {
        inputRef.current.focus()
      }, 0)
    }
  }, [text, send, files])

  useEffect(() => {
    const input = document.getElementById('default-input')
    const textarea = document.getElementById('extended-input')
    if (textarea) {
      // textarea keydown event manipulation
      const textareaKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          e.preventDefault()
          if (!e.shiftKey) {
            submit()
          } else {
            setText(text + '\n')
            setTimeout(() => {
              textareaRef.current.selectionStart = textareaRef.current.selectionEnd = textareaRef.current.value.length
            }, 0)
          }
        }
      }
      textarea.addEventListener('keydown', textareaKeyDown)
      return () => {
        textarea.removeEventListener('keydown', textareaKeyDown)
      }
    } else {
      // input keydown event manipulation
      const inputKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          if (!e.shiftKey) {
            submit()
          } else {
            setText(text + '\n')
            setShowTextarea(true)
            setTimeout(() => {
              textareaRef.current.focus()
              textareaRef.current.selectionStart = textareaRef.current.selectionEnd = textareaRef.current.value.length
            }, 0)
          }
        }
      }
      input.addEventListener('keydown', inputKeyDown)
      return () => {
        input.removeEventListener('keydown', inputKeyDown)
      }
    }
  }, [submit, setText, setShowTextarea, textareaRef, text])

  const updateText = (text: string) => {
    setText(text)
    if (text.trim().length === 0) {
      setShowTextarea(false)
      setTimeout(() => {
        inputRef.current.focus()
      }, 0)
    }
  }

  const onFilesCallback = (newFiles: OnCallbackFile[]) => {
    if (newFiles) {
      setFiles([...files, ...newFiles])
    }
  }

  const removeFile = (key: string) => () => {
    const newFiles = files.filter((file) => file.key !== key)
    setFiles(newFiles)
  }

  return (
    <Form onSubmit={submit} className="bg-gray-thirdary p-3 rounded-sm absolute left-0 w-full md:relative bottom-7 md:bottom-0">
      {!!files &&
        files.map((file) => {
          return (
            <div className="flex flex-row justify-between items-start mb-2" key={file.key}>
              <div className="w-8 shrink-0 pt-[1px] flex justify-center cursor-pointer" onClick={removeFile(file.key)}>
                <TrashIcon className="w-5 text-gray-primary hover:scale-105 transition-all" />
              </div>
              <div className="w-full">{file.name}</div>
            </div>
          )
        })}
      <div className="flex flex-row items-end gap-3">
        <div className="w-8">
          <DirectFileUpload
            uploadType={UPLOAD_TYPES.CHAT_FILE}
            acceptedMimeTypes={['image/*', 'application/pdf', 'application/msword', 'text/plain']}
            onFilesCallback={onFilesCallback}
            multiple
          >
            <div className="bg-magenta-primary hover:bg-white rounded-full w-8 h-8 flex items-center justify-center border border-magenta-primary group">
              <GetIcon name="upload" className="w-4 fill-white group-hover:fill-magenta-primary" />
            </div>
          </DirectFileUpload>
        </div>
        {!showTextarea && <NewInput id="default-input" useRef={inputRef} value={text} onChange={updateText} />}
        {!!showTextarea && <Textarea id="extended-input" useRef={textareaRef} value={text} onChange={updateText} />}
        <NewButton type="submit" className="btn-secondary" text={t.chat.submit} />
      </div>
    </Form>
  )
}

export default Compose
