import First from './First'
import Second from './Second'
import Third from './Third'

const Steps = () => {
  return (
    <div className="flex flex-col gap-10 mt-10">
      <First />
      <Second />
      <Third />
    </div>
  )
}

export default Steps
