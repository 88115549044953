import { useState } from 'react'
import { Milestone, MilestoneType } from '../../../../types/Milestone'
import Number from '../../NewInput/Number'
import NewInput from '../../NewInput/NewInput'
import NewButton from '../../NewButton/NewButton'
import { UpdateListCallback } from '../types'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import styled from 'styled-components'
import { getUUID } from '../../../../utility'
import toast, { TYPE } from '../../../../toast'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../redux/routes'

type Props = {
  projectUUID: string
  lastIndex?: number
  milestone?: Milestone
  updateListCallback?: UpdateListCallback
}

const TypeItem = styled.div`
  width: calc(100% / 3 - 20px * 2 / 3);
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: calc(100% / 2 - 15px / 2);
    padding: 10px;
  }
`

const Upsert: React.FC<Props> = ({ projectUUID, milestone, updateListCallback, lastIndex }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [data, setData] = useState<Milestone>(
    !!milestone ? milestone : { projectUUID, index: isFinite(lastIndex) && lastIndex > 0 ? lastIndex : null },
  )
  const [helpOpen, setHelpOpen] = useState<boolean>(false)
  const upsertMilestone = functional.use(Routes.UPSERT_MILESTONE_OF_PROJECT)

  const set = (key: keyof Milestone) => (value: any) => {
    if (key === 'index') {
      setData({ ...data, [key]: value - 1 })
    } else {
      setData({ ...data, [key]: value })
    }
  }

  const submit = async () => {
    if (!!data.type) {
      if (!data.deadline || new Date(data.deadline) > new Date()) {
        const milestones = await upsertMilestone(data)
        if (milestones) {
          toast(TYPE.SUCCESS, t.milestone.upsert.success)
          if (!!updateListCallback) {
            updateListCallback(milestones)
          }
        } else {
          toast(TYPE.ERROR, t.milestone.upsert.error.upsert)
        }
      } else {
        toast(TYPE.ERROR, t.milestone.upsert.error.date)
      }
    } else {
      toast(TYPE.ERROR, t.milestone.upsert.error.type)
    }
  }

  return (
    <div className="bg-gray-secondary py-5 px-7 rounded-md">
      <div className="relative rounded-md bg-white">
        <div className={`absolute -left-3 top-0 h-full flex items-center justify-center text-sm`}>
          <Number
            className="h-6 aspect-square bg-white flex items-center justify-center rounded-full border-magenta-primary"
            contentClassName="!px-0 !text-center text-magenta-primary"
            value={data.index + 1}
            onChange={set('index')}
            disabled={data.type === MilestoneType.PROJECT_COMPLETED || data.type === MilestoneType.PROJECT_IN_PROGRESS}
          />
        </div>
        <div className={`text-center text-magenta-primary font-thin italic text-lg py-3`}>
          {data.type ? t.milestone.type[data.type] : t.milestone.upsert.namePlaceholder}
        </div>
      </div>
      <NewInput
        type="date"
        className="max-w-xs w-40 mx-auto mt-2 border-none rounded-md"
        contentClassName="!text-center text-magenta-primary"
        value={data.deadline}
        onChange={set('deadline')}
        placeholder={t.request.create.deadline.title}
      />
      <div>
        <div
          className="ml-auto w-fit mx-auto md:mx-0 md:ml-auto rounded-full bg-black-secondary text-white py-1 px-5 mt-2 border border-magenta-primary cursor-pointer"
          onClick={() => setHelpOpen(!helpOpen)}
        >
          {t.milestone.upsert.help[helpOpen ? 'hide' : 'show']}
        </div>
        {helpOpen && (
          <div className="rounded-md flex flex-col gap-2 bg-black-secondary p-5 mt-2">
            {t.milestone.upsert.help.artist.map((t) => (
              <div className="text-xs md:text-sm text-white" key={getUUID()}>
                {t}
              </div>
            ))}
          </div>
        )}
      </div>
      {!milestone && (
        <div>
          <div className="text-white mt-4">{t.milestone.upsert.selection.title}</div>
          <div className="flex flex-wrap gap-[15px] md:gap-[20px] mt-2">
            {Object.keys(t.milestone.type)
              .filter(
                (k) =>
                  k !== MilestoneType.PROJECT_COMPLETED &&
                  k !== MilestoneType.PROJECT_FEASIBILITY &&
                  k !== MilestoneType.PROJECT_ADD_ON &&
                  k !== MilestoneType.COMPLETE_FINAL_PAYMENT &&
                  k !== MilestoneType.PROJECT_IN_PROGRESS,
              )
              .map((key) => (
                <TypeItem
                  key={key}
                  className={`border border-blue-primary rounded-sm py-2 w-1/3 flex justify-center items-center text-center cursor-pointer ${
                    data.type === key ? 'bg-blue-primary text-white' : 'text-blue-primary bg-white'
                  }`}
                  onClick={() => set('type')(key)}
                >
                  {t.milestone.type[key]}
                </TypeItem>
              ))}
          </div>
        </div>
      )}
      <NewButton
        text={!!milestone ? t.milestone.upsert.cta.edit : t.milestone.upsert.cta.create}
        className="btn-secondary mt-10 !text-lg !h-11 border !border-white rounded-none lg:w-2/3 mx-auto"
        onClick={submit}
      />
    </div>
  )
}

export default Upsert
