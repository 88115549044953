import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import props from '../../../../../redux/props'
import { setLocal } from '../../../../../redux/action/local'
import cookie from '../../../helper/cookie'
import ProfileIcon from '../../../ProfileIcon/ProfileIcon'
import { useState } from 'react'
import Dropdown from './Item/Dropdown'
import { NavigationItem } from '../types'
import NotificationBell from '../../../NotificationBell/NotificationBell'
import { getUUID } from '../../../../../utility'

type Props = {
  type: String
}

const Profile: React.FC<Props> = ({ type }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [open, setOpen] = useState<boolean>(false)
  const t = useSelector((s) => s[props.TRANSLATION])

  const logout = () => {
    cookie.remove('token')
    dispatch(setLocal(props.ACCOUNT_LINK, null))
    dispatch(setLocal(props.TOKEN, null))
    navigate('/')
  }

  const toggle = () => setOpen(!open)

  return (
    <div className="relative">
      <ProfileIcon onClick={toggle} />
      <NotificationBell
        className="flex xl:hidden"
        type={[...t.header.navigation[type].dropdown, ...t.header.navigation[type].sidemenu]
          .map((i: NavigationItem) => i.notificationType)
          .filter((i) => !!i)}
      />
      <NotificationBell
        className="hidden xl:flex"
        type={t.header.navigation[type].dropdown.map((i: NavigationItem) => i.notificationType).filter((i) => !!i)}
      />
      {open && (
        <div className="absolute flex flex-col gap-2 mt-1 top-full right-0 bg-gray-thirdary shadow-md p-3 rounded-md">
          {t.header.navigation[type].sidemenu.map((item: NavigationItem) => (
            <Dropdown key={getUUID()} data={item} className="flex 2xl:hidden" onClick={toggle} />
          ))}
          <div className="border-t-[0.1px] border-gray-primary-second 2xl:hidden"></div>
          {t.header.navigation[type].dropdown.map((item: NavigationItem) => (
            <Dropdown key={getUUID()} data={item} onClick={toggle} />
          ))}
          <Dropdown data={t.header.navigation.isLoggedIn} onClick={logout} />
        </div>
      )}
    </div>
  )
}

export default Profile
