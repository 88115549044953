import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { useState } from 'react'
import { Pricing } from '../types'
import { Price } from '../../../../types/Pricing'
import SizeAndPrice from '../../../UI/Pricing/Editable/SizeAndPrice'
import NewToggle from '../../../UI/NewToggle/NewToggle'
import SectionHeader from '../../../UI/Pipeline/SectionHeader'
import { Sizes } from '../../../../types/Size'

type Props = {
  initData?: Pricing['primer']
  update: (value: any) => void
}

const Primer: React.FC<Props> = ({ initData, update }) => {
  const t = useSelector((state) => state[props.TRANSLATION])
  const [data, setData] = useState<Pricing['primer']>(
    initData || {
      [Sizes.S]: null,
      [Sizes.M]: null,
      [Sizes.L]: null,
      disabled: false,
    },
  )

  const set = (prop: Sizes | 'disabled') => (value: Price | boolean) => {
    setData({ ...data, [prop]: value })
    update({ ...data, [prop]: value })
  }

  return (
    <div>
      <SectionHeader headline={t.register.artist.pricing.primer.headline} small />
      <div className="flex flex-col gap-3 mt-3">
        <SizeAndPrice disabled={data.disabled} size={Sizes.S} price={data[Sizes.S]} updatePrice={set(Sizes.S)} />
        <SizeAndPrice disabled={data.disabled} size={Sizes.M} price={data[Sizes.M]} updatePrice={set(Sizes.M)} />
        <SizeAndPrice disabled={data.disabled} size={Sizes.L} price={data[Sizes.L]} updatePrice={set(Sizes.L)} />
      </div>
      <div className="flex items-center gap-2 mt-5">
        <NewToggle checked={data.disabled} onChange={set('disabled')} />
        <div className="text-sm text-gray-primary">{t.register.artist.pricing.primer.disableToggleLabel}</div>
      </div>
    </div>
  )
}

export default Primer
