import { useEffect, useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../redux/routes'
import Favorite from '../../types/Favorite'

type Props = () => Favorite[]

const useListFavorites: Props = () => {
  const [favorites, setFavorites] = useState<Favorite[]>()
  const listFavorites = functional.use(Routes.LIST_FAVORITES)

  useEffect(() => {
    const list = async () => {
      const favorites = await listFavorites()
      setFavorites(favorites)
    }
    if (!favorites) {
      list()
    }
  })

  return favorites
}

export default useListFavorites
