import GridView from '../../UI/GridView/GridView'
import NewButton from '../../UI/NewButton/NewButton'
import { Request } from '../../../types/Request'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { calculateSquareMeters, formatPrice, getPrettyDate, prettyLocation } from '../../../utility'
import PropertyList from '../../UI/PropertyList/PropertyList'
import { Property } from '../../UI/PropertyList/types'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import useHasNotification from '../../hooks/useHasNotification'
import { Type } from '../../../types/Notification'
import { PurposeFlag } from '../../../types/Auth'

type Props = {
  request: Request
  linkToInternalView?: boolean
  isGridView?: boolean
}

const RequestGridView: React.FC<Props> = ({ request, linkToInternalView, isGridView = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])
  const hasOfferNotification = useHasNotification(Type.NEW_OFFER, { requestUUID: request.uuid })
  const hasArtistRequestNotification = useHasNotification(Type.NEW_ARTIST_REQUEST, { requestUUID: request.uuid })

  const isRequestToArtist = !!request.isDirectRequest

  const getPropertyList = (): Property[] => {
    const propertyList: Property[] = [
      { name: t.request.search.properties.location, value: prettyLocation(request.nameAndLocation.location) },
      { name: t.request.search.properties.deadlineDate, value: getPrettyDate(new Date(request.deadline.date)) },
    ]
    if (!!request.sizeAndPrimer) {
      propertyList.push({
        name: t.request.search.properties.size,
        value: `${calculateSquareMeters(request.sizeAndPrimer.size.length, request.sizeAndPrimer.size.height)}${t.generic.squareMeter}`,
      })
    }
    if (!!request.attendee?.amount) {
      propertyList.push({
        name: t.request.search.properties.attendee.amount,
        value: `${request.attendee.amount}${t.request.search.properties.attendee.suffix}`,
      })
    }
    propertyList.push({
      name: t.request.search.properties.budgetExpectation.title,
      value:
        !request.budgetExpectation || (!!request.budgetExpectation && request.budgetExpectation.noAskingPrice === true)
          ? t.request.search.properties.budgetExpectation.noAskingPrice
          : formatPrice(request.budgetExpectation.askingPrice),
    })
    return propertyList
  }

  const getCTALink = (): string => {
    if (linkToInternalView && accountLink) {
      if (accountLink.purposeFlag === PurposeFlag.ARTIST) {
        return `/home/artist/request/view/${request.uuid}`
      } else if (accountLink.purposeFlag === PurposeFlag.BOOKER && request.bookerUUID === accountLink.assignedToUUID) {
        return `/home/booker/request/view/${request.uuid}`
      }
    } else if (!isRequestToArtist && accountLink && accountLink.purposeFlag === PurposeFlag.ARTIST) {
      return `/home/artist/request/view/${request.uuid}`
    } else if (accountLink && accountLink.purposeFlag === PurposeFlag.BOOKER && request.bookerUUID === accountLink.assignedToUUID) {
      return `/home/booker/request/view/${request.uuid}`
    } else {
      return `/request/view/${request.uuid}`
    }
  }

  const isHighlighted = () => {
    return (hasOfferNotification || hasArtistRequestNotification) && linkToInternalView
  }

  const Content = (
    <div className={`absolute bottom-2 h-fit w-full ${isGridView ? 'md:w-11/12 px-3' : 'md:w-8/12 px-3'}`}>
      <div className="md:border border-white md:bg-white h-fit md:bg-opacity-60 rounded-md">
        <div className="flex flex-col gap-2 m-0 md:m-2">
          <div className="hidden md:block bg-white bg-opacity-70 rounded-md p-1 border border-gray-secondary">
            <PropertyList properties={getPropertyList()} contrast small splitInHalf />
          </div>
          <div className="flex flex-row gap-2 items-center">
            <div
              className={
                'w-4/6 text-light-black bg-white bg-opacity-70 border border-white rounded-md text-xs flex items-center justify-center text-center leading-5 h-8'
              }
            >
              {t.request.validUntil.prefix}
              {request.remainingDays}
              {t.request.validUntil.suffix}
            </div>
            <div className="md:w-2/6 flex justify-end">
              <NewButton
                className="btn-secondary w-fit"
                text={isRequestToArtist ? t.request.search.goToDirect : t.request.search.goTo}
                href={getCTALink()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <>
      <GridView title={request.nameAndLocation.name} images={request.design.urls} highlight={isHighlighted()} className="hidden md:flex">
        {Content}
      </GridView>
      <GridView title={request.nameAndLocation.name} images={request.design.urls} highlight={isHighlighted()} hideSmallImages className="md:hidden">
        {Content}
      </GridView>
    </>
  )
}

export default RequestGridView
