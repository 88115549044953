import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { Pricing as PricingType } from '../types'
import { PipelineComponent } from '../../../UI/Pipeline/types'

import toast, { TYPE } from '../../../../toast'
import Section from '../../../UI/NewAccordion/Section/Section'
import Art from './Art'
import Primer from './Primer'
import Concept from './Concept'
import Workshop from './Workshop'
import MediumContainer from '../../../UI/Container/MediumContainer'
import validator from './validator'
import { getUUID } from '../../../../utility'
import SectionHeader from '../../../UI/Pipeline/SectionHeader'

const Pricing: PipelineComponent<PricingType> = ({ pipelineStateProp, data, setData, NavigationForm, finalStepSubmit }) => {
  const t = useSelector((state) => state[props.TRANSLATION])

  const set = (prop: keyof PricingType) => (value: any) => setData({ ...data, [prop]: value })

  const getData = () => {
    if (validator.art(data['art'])) {
      if (validator.primer(data['primer'])) {
        if (validator.concept(data['concept'])) {
          if (validator.workshop(data['workshop'])) {
            return data
          } else {
            toast(TYPE.ERROR, t.register.artist.pricing.workshop.error)
          }
        } else {
          toast(TYPE.ERROR, t.register.artist.pricing.concept.error)
        }
      } else {
        toast(TYPE.ERROR, t.register.artist.pricing.primer.error)
      }
    } else {
      toast(TYPE.ERROR, t.register.artist.pricing.art.error)
    }
    return false
  }

  return (
    <MediumContainer>
      <SectionHeader headline={t.register.artist.pricing.headline} />
      <Section
        title={t.register.artist.pricing.hint}
        content={t.register.artist.pricing.hintText.map((t) => (
          <div className="text-white text-xs mb-2" key={getUUID()}>
            {t}
          </div>
        ))}
      />
      <NavigationForm pipelineStateProp={pipelineStateProp} getData={getData} finalStepSubmit={finalStepSubmit}>
        <div className="flex flex-col md:flex-row flex-1 gap-10">
          <Art initData={data.art} update={set('art')} />
          <Primer initData={data.primer} update={set('primer')} />
        </div>
        <Concept initData={data.concept} update={set('concept')} />
        <Workshop initData={data.workshop} update={set('workshop')} />
        <div className="text-gray-primary text-sm my-6">{t.register.artist.pricing.hiddenHint}</div>
        <div className="text-xs bg-gray-thirdary rounded-sm p-2">{t.register.artist.pricing.priceReductionHint}</div>
      </NavigationForm>
    </MediumContainer>
  )
}

export default Pricing
