import styled from 'styled-components'

export const StyledText = styled.div<{
  color?: string
  align?: string
  font?: string
  margin?: string
  fontSize?: string
  width?: string
  bold?: boolean
}>`
  color: ${({ color, theme }) => color || theme.color.gray.primary};
  text-align: ${({ align }) => align || 'left'};
  font-family: ${({ font }) => font || 'regular'};
  margin: ${({ margin }) => margin || '0px'};
  font-size: ${({ fontSize }) => fontSize || 'unset'};
  width: ${({ width }) => width || '100%'};
  font-family: ${({ bold }) => bold && 'bold'};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ fontSize }) => `calc(${fontSize} - 10)` || 'unset'};
  }
`
