import { useNavigate } from 'react-router-dom'
import Title from './Title'

type Props = {
  children: React.ReactNode
  title?: string
  noTitlePadding?: boolean
  className?: string
  href?: string | null
}

const GrayBox: React.FC<Props> = ({ children, title, noTitlePadding, className = '', href = null }) => {
  const navigate = useNavigate()

  const core = (
    <div className="flex flex-col h-full">
      <div>
        <Title noTitlePadding={noTitlePadding} title={title} />
      </div>
      <div className="h-full">{children}</div>
    </div>
  )

  const followLink = (e) => {
    e.preventDefault()
    navigate(href)
  }

  if (!!href) {
    return (
      <a href={href} onClick={followLink} className={`block bg-gray-thirdary shadow-md rounded-3xl py-7 px-7 relative ${className}`}>
        {core}
      </a>
    )
  }

  return <div className={`bg-gray-thirdary shadow-md rounded-3xl py-7 px-7 relative ${className}`}>{core}</div>
}

export default GrayBox
