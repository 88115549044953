// import { useNavigate } from 'react-router-dom'

const Link = ({ children, ...props }) => {
  // const navigate = useNavigate()
  // const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  //   e.preventDefault()
  //   navigate(props.href)
  // }

  return <a {...props}>{children}</a>
}

export default Link
