import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { Tags as TagsType } from '../../../../../types/Project'
import { GetData, PipelineComponent } from '../../../../UI/Pipeline/types'
import SectionHeader from '../../../../UI/Pipeline/SectionHeader'
import { Category, Tag, additionalTags, selectedObjectWhitelist } from '../../../../../types/Tag'
import SelectableTagList from '../../../../UI/SelectableTagList/SelectableTagList'
import SelectableTagListItem from '../../../../UI/SelectableTagList/Item/Item'
import toast, { TYPE } from '../../../../../toast'

const Tags: PipelineComponent<TagsType> = ({ pipelineStateProp, data, setData, NavigationForm, finalStepSubmit }) => {
  const t = useSelector((state) => state[props.TRANSLATION])
  const allTags = useSelector<Tag[]>((state) => state[props.TAG])

  const set = (prop: keyof TagsType) => (value: any) => setData({ ...data, [prop]: value })

  const getData: GetData = () => {
    const isSelected = (prop: any) => Array.isArray(prop) && prop.length > 0
    if (!!data.object) {
      if (!!data.surface) {
        if (isSelected(data.equipment)) {
          return data
        } else {
          toast(TYPE.ERROR, t.request.create.tags.equipment.error)
        }
      } else {
        toast(TYPE.ERROR, t.request.create.tags.surface.error)
      }
    } else {
      toast(TYPE.ERROR, t.request.create.tags.object.error)
    }
    return false
  }

  const setEquipment = (value: string[]) => {
    if (value[value.length - 1] === additionalTags.NO_EQUIPMENT_REQUIRED.enum) {
      setData({ ...data, equipment: [additionalTags.NO_EQUIPMENT_REQUIRED.enum], equipmentAvailable: null })
    } else {
      const equipmentAvailable = data.equipmentAvailable !== undefined && data.equipmentAvailable !== null ? data.equipmentAvailable : false
      setData({ ...data, equipment: value.filter((v) => v !== additionalTags.NO_EQUIPMENT_REQUIRED.enum), equipmentAvailable })
    }
  }

  const showAvailableEquipmentSection = () => {
    if (!Array.isArray(data.equipment)) return false
    if (data.equipment.includes(additionalTags.UNSURE.enum) && data.equipment.length === 1) {
      return false
    }
    return !data.equipment.includes(additionalTags.NO_EQUIPMENT_REQUIRED.enum) && data.equipment.length > 0
  }

  const getAvailableObjectTags = () => {
    if (!Array.isArray(allTags)) return []
    return selectedObjectWhitelist.map((t) => allTags.find((tag) => tag.enum === t)).filter((t) => !!t)
  }

  const getAvailableSurfaceTags = () => {
    if (!Array.isArray(allTags)) return []
    const stein = allTags.find((t: Tag) => t.category === Category.SURFACE && t.enum === 'STEIN')
    return [stein, ...allTags.filter((t: Tag) => t.category === Category.SURFACE && t.enum !== 'STEIN')]
  }

  return (
    <div>
      {Array.isArray(allTags) && (
        <NavigationForm pipelineStateProp={pipelineStateProp} getData={getData} finalStepSubmit={finalStepSubmit}>
          <SectionHeader headline={t.request.create.tags.object.title} />
          <SelectableTagList
            maxVisibleRows={3}
            className="mt-2"
            selectedTagENUMList={[data.object]}
            tags={getAvailableObjectTags()}
            onChange={set('object')}
            additionalTags={[additionalTags.OTHER]}
            singleSelect
          />
          <SectionHeader headline={t.request.create.tags.surface.title} />
          <SelectableTagList
            maxVisibleRows={3}
            className="mt-2"
            selectedTagENUMList={[data.surface]}
            tags={getAvailableSurfaceTags()}
            additionalTags={[additionalTags.UNSURE]}
            onChange={set('surface')}
            singleSelect
          />
          <SectionHeader headline={t.request.create.tags.equipment.title} />
          <SelectableTagList
            maxVisibleRows={3}
            className="mt-2"
            selectedTagENUMList={data.equipment}
            tags={allTags.filter((t: Tag) => t.category === Category.EQUIPMENT)}
            additionalTags={[additionalTags.UNSURE]}
            onChange={setEquipment}
          />
          {showAvailableEquipmentSection() && (
            <>
              <SectionHeader headline={t.request.create.tags.equipmentAvailable.title} />
              <div className="flex flex-col gap-2 mt-2">
                <SelectableTagListItem
                  name={t.request.create.tags.equipmentAvailable.yes}
                  isSelected={data.equipmentAvailable === true}
                  onClick={() => set('equipmentAvailable')(true)}
                />
                <SelectableTagListItem
                  name={t.request.create.tags.equipmentAvailable.no}
                  isSelected={data.equipmentAvailable === false}
                  onClick={() => set('equipmentAvailable')(false)}
                />
              </div>
            </>
          )}
        </NavigationForm>
      )}
    </div>
  )
}

export default Tags
