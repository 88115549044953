import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { NavigationItem } from '../types'
import GetIcon from '../../../GetIcon/GetIcon'
import NotificationBell from '../../../NotificationBell/NotificationBell'
import { getUUID } from '../../../../../utility'
import { useEffect, useState } from 'react'

type Props = {
  type: String
}

const Sidemenu: React.FC<Props> = ({ type }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [isMinified, setIsMinified] = useState(false)

  useEffect(() => {
    if (window.location.pathname === '/') {
      setIsMinified(true)
    } else {
      const evalMinimized = () => {
        setIsMinified(window.innerWidth < 1400)
      }
      window.addEventListener('resize', evalMinimized)

      return () => {
        window.removeEventListener('resize', evalMinimized)
      }
    }
  }, [])

  const isActive = (path: string) => {
    return window.location.pathname.includes(path)
  }

  return (
    <div className="absolute flex-col gap-3 mt-6 ml-6 top-full left-0 bg-white rounded-md p-3 hidden 2xl:flex group/menu transition-all">
      {t.header.navigation[type].sidemenu.map((data: NavigationItem) => (
        <a
          href={data.link || ''}
          key={getUUID()}
          className={`flex flex-row gap-3 items-center h-8 ${isMinified ? 'px-1 group-hover/menu:px-5' : 'px-5'} py-1 hover:bg-gray-primary ${
            isActive(data.link) ? 'bg-blue-primary' : ''
          } rounded-md group/item relative`}
        >
          {data.icon && (
            <GetIcon name={data.icon} className={`h-5 fill-blue-primary group-hover/item:fill-white ${isActive(data.link) ? 'fill-white' : ''}`} />
          )}
          <span
            className={`text-blue-primary transition-all font-thin ${isMinified ? 'hidden group-hover/menu:block' : ''} group-hover/item:text-white ${
              isActive(data.link) ? 'text-white' : ''
            }`}
          >
            {data.name}
          </span>
          <NotificationBell type={data.notificationType} />
        </a>
      ))}
    </div>
  )
}

export default Sidemenu
