import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Container from '../../UI/Container/Container'
import {
  StyledQrBanner,
  Flex,
  Column,
  Headline,
  SubHeadline,
  PictureQR,
  PictureWrapper,
  PictureRegister,
  Arrows,
  HeadlineWrapper,
  SubHeadlineWrapper,
  ArrowColumn,
} from './QrBanner.Styled'
import qrCode from '../../../assets/image/home/qr/qr.png'
import register from '../../../assets/image/home/qr/register.png'
import arrows from '../../../assets/image/home/qr/arrows.png'
import { getUUID } from '../../../utility'

const QrBanner = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <StyledQrBanner>
      <Container>
        <Flex>
          <Column>
            <HeadlineWrapper>
              {translation.home.qr.headlines.map((h) => (
                <Headline key={getUUID()}>{h}</Headline>
              ))}
            </HeadlineWrapper>
            <PictureWrapper>
              <PictureQR src={qrCode} />
              <PictureRegister src={register} />
            </PictureWrapper>
            <SubHeadlineWrapper>
              {translation.home.qr.subHeadlines.map((h) => (
                <SubHeadline key={getUUID()}>{h}</SubHeadline>
              ))}
            </SubHeadlineWrapper>
          </Column>
          <ArrowColumn>
            <Arrows src={arrows} />
          </ArrowColumn>
        </Flex>
      </Container>
    </StyledQrBanner>
  )
}

export default QrBanner
