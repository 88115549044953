import { useSelector } from 'react-redux'
import { useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import { useNavigate } from 'react-router-dom'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import { Request } from '../../../../../types/Request'
import { Artist } from '../../../../../types/Artist'
import props from '../../../../../redux/props'
import { Offer } from '../../../../../types/Offer'
import { PurposeFlag } from '../../../../../types/Auth'
import Routes from '../../../../../redux/routes'
import { getTotalPriceOfOffer, isWithinArtistRadius } from '../../../../../utility'
import toast, { TYPE } from '../../../../../toast'
import NewButton from '../../../../UI/NewButton/NewButton'
import Loading from '../../../../UI/Loading/Loading'
import PriceCalculator, { PriceCalculatorType } from '../../../../UI/PriceCalculator/PriceCalculator'
import NewModal from '../../../../UI/NewModal/NewModal'
import useReadNotification from '../../../../hooks/useReadNotification'
import { Type } from '../../../../../types/Notification'

type Props = {
  request: Request
  artist: Artist
  open: boolean
  setOpen: (open: boolean) => void
}

enum Processing {
  FALSE = 'FALSE',
  SUBMITTING = 'SUBMITTING',
  SUCCESS = 'SUCCESS',
}

const OfferButton: React.FC<Props> = ({ request, artist, open, setOpen }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])
  const navigate = useNavigate()
  const [processingState, setProcessingState] = useState<Processing>(Processing.FALSE)
  const submitOffer = functional.use(Routes.SUBMIT_OFFER)
  const [offer, setOffer] = useState<Offer>(null)
  const read = useReadNotification()

  const isPublicRequest = (!request.artistList || request.artistList.length === 0) && !request?.budgetExpectation?.noAskingPrice
  const isArtist = accountLink && accountLink.purposeFlag === PurposeFlag.ARTIST
  const isBooker = accountLink && accountLink.purposeFlag === PurposeFlag.BOOKER
  const isReverseOffer =
    (Array.isArray(request.artistList) && !!artist && !!request.artistList.some((a) => a.uuid === artist.uuid)) || isPublicRequest

  const onClick = () => {
    if (isArtist) {
      setOpen(true)
    } else {
      navigate('/login')
    }
  }

  const submit = async () => {
    const totalPrice = getTotalPriceOfOffer(request, offer)
    if (totalPrice > 0) {
      setProcessingState(Processing.SUBMITTING)
      const status = await submitOffer({ uuid: request.uuid, offer })
      if (!!status) {
        setProcessingState(Processing.SUCCESS)
        read({
          type: Type.NEW_ARTIST_REQUEST,
          payload: { requestUUID: request.uuid },
        })
      } else {
        setProcessingState(Processing.FALSE)
        toast(TYPE.ERROR, t.request.view.makeAnOffer.offer.processing.error)
      }
    } else {
      toast(TYPE.ERROR, t.request.view.makeAnOffer.offer.error.priceIsNegative)
    }
  }

  const Hint = <div className="text-xs mt-4 text-gray-primary rounded-sm p-1 mx-4 leading-4 text-center">{t.request.view.makeAnOffer.offer.hint}</div>
  const Cancel = <NewButton className="btn-secondary-outline w-fit mx-auto mt-4" onClick={() => setOpen(false)} text={t.generic.cancel} />
  const Close = <NewButton className="btn-secondary-outline w-fit mx-auto mt-4" onClick={() => window.location.reload()} text={t.generic.close} />
  const Confirm = (
    <NewButton
      className="btn-secondary w-fit mx-auto mt-5"
      onClick={processingState === Processing.FALSE ? submit : null}
      text={
        (!!request.artistList && request.artistList.length > 0) || isPublicRequest
          ? t.request.view.makeAnOffer.offer.modal.ctaReverseOffer
          : t.request.view.makeAnOffer.offer.modal.cta
      }
    />
  )
  const openCTAText = () =>
    isArtist
      ? isReverseOffer
        ? t.request.view.makeAnOffer.offer.ctaReverseOffer
        : t.request.view.makeAnOffer.offer.ctaLoggedIn
      : t.request.view.makeAnOffer.offer.ctaNotLoggedIn

  if (isBooker) return <></>
  return (
    <>
      <NewButton className="btn-secondary" text={openCTAText()} onClick={onClick} />
      <NewModal show={open} onClose={() => setOpen(false)} noForm noClose>
        {!isReverseOffer && <div className="font-bold text-gray-primary">{t.request.view.makeAnOffer.offer.modal.title} </div>}
        {!isWithinArtistRadius(artist, request.nameAndLocation.location) && (
          <div className="bg-gray-thirdary py-3 px-5 mt-3 rounded-sm">
            <div className="text-sm">{t.request.view.makeAnOffer.offer.modal.radiusHint.title}</div>
            <div className="text-xs mt-2 text-black-secondary">{t.request.view.makeAnOffer.offer.modal.radiusHint.text}</div>
          </div>
        )}
        {processingState === Processing.FALSE && (
          <>
            <Loading loading={artist} />
            {!!artist && (
              <div className="mt-2">
                <PriceCalculator type={PriceCalculatorType.OFFER} artist={artist} request={request} offer={offer} setOffer={setOffer} />
              </div>
            )}
            {Confirm}
            {Hint}
            {Cancel}
          </>
        )}
        {processingState === Processing.SUBMITTING && (
          <>
            <div className="text-center text-sm mt-5">{t.request.view.makeAnOffer.offer.processing.submitting}</div>
            <Loading loading={false} />
          </>
        )}
        {processingState === Processing.SUCCESS && (
          <>
            {!isReverseOffer && <div className="text-center text-sm mt-5">{t.request.view.makeAnOffer.offer.processing.success}</div>}
            {!!isReverseOffer && <div className="text-center text-sm mt-5">{t.request.view.makeAnOffer.offer.processing.successReverseOffer}</div>}
            <div className="text-xs mt-4 text-gray-primary rounded-sm p-1 mx-4 leading-4 text-center">
              {t.request.view.makeAnOffer.offer.successHint}
            </div>
            {Close}
          </>
        )}
      </NewModal>
    </>
  )
}

export default OfferButton
