import { useSelector } from 'react-redux'
import DataSection from '../../../Components/DataSection/DataSection'
import { DataSectionComponent } from '../../types'
import props from '../../../../../redux/props'
import { useState } from 'react'
import toast, { TYPE } from '../../../../../toast'
import { crud } from '@think-internet/zeus-frontend-package'
import NewInput from '../../../../UI/NewInput/NewInput'
import { BillingDetail } from '../../../../../types/Booker'
import Property from '../../../../UI/PropertyList/Property'

const Billing: DataSectionComponent = ({ booker, onChangeCallback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const bookerFeature = crud.use(props.BOOKER)
  const [data, setData] = useState<BillingDetail>(booker.billingDetail)

  const Default = (
    <div>
      <Property property={{ name: t.generic.firstname, value: data.firstname }} />
      <Property property={{ name: t.generic.lastname, value: data.lastname }} />
      <Property property={{ name: t.generic.companyName, value: data.companyName }} />
      <Property property={{ name: t.generic.street, value: data.street }} />
      <Property property={{ name: t.generic.postcode, value: data.postcode }} />
      <Property property={{ name: t.generic.city, value: data.city }} />
    </div>
  )

  const save = async () => {
    const status = await bookerFeature.update({ uuid: booker.uuid, billingDetail: { ...booker.billingDetail, ...data } })
    if (!status) {
      toast(TYPE.ERROR, t.booker.settings.billing.edit.error)
    } else {
      onChangeCallback(data)
    }
    return status
  }

  const set = (key: keyof BillingDetail) => (value: string) => setData({ ...data, [key]: value })

  return (
    <DataSection className="md:w-1/2" title={t.booker.settings.billing.title} defaultView={Default} enableCancel saveCallback={save}>
      <div className="text-sm text-black-secondary my-2">{t.booker.settings.billing.edit.hint}</div>
      <div className="flex gap-2 flex-col">
        <NewInput required value={data.firstname} onChange={set('firstname')} placeholder={t.generic.firstname} />
        <NewInput required value={data.lastname} onChange={set('lastname')} placeholder={t.generic.lastname} />
        <NewInput required value={data.street} onChange={set('street')} placeholder={t.generic.street} />
        <NewInput required value={data.postcode} onChange={set('postcode')} placeholder={t.generic.postcode} />
        <NewInput required value={data.city} onChange={set('city')} placeholder={t.generic.city} />
      </div>
    </DataSection>
  )
}

export default Billing
