import props from '../../../redux/props'
import DefaultProperties from './DefaultProperties/DefaultProperties'
import WorkshopProperties from './WorkshopProperties/WorkshopProperties'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { crud } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../../toast'
import { useSelector } from 'react-redux'
import MediumContainer from '../../UI/Container/MediumContainer'
import _ from 'lodash'
import { Project } from '../../../types/Project'
import Headline from '../../UI/Headline/Headline'
import { s3PublicStoragePrefix } from '../../../utility'
import GetIcon from '../../UI/GetIcon/GetIcon'
import GridViewWithCarousel from '../../UI/GridViewWithCarousel/GridViewWithCarousel'
import Favorite from '../Favorite/Favorite'
import Button from '../../UI/NewButton/NewButton'
import { PurposeFlag } from '../../../types/Auth'

const View: React.FC<{}> = () => {
  const { uuid } = useParams<{ uuid: string }>()
  const [project, setProject] = useState<Project>(null)
  const t = useSelector((s) => s[props.TRANSLATION])
  const projectFeature = crud.use(props.PROJECT)
  const accountLink = useSelector((s) => s[props.ACCOUNT_LINK])

  useEffect(() => {
    const getProject = async () => {
      const project = await projectFeature.get({ uuid })

      if (project) {
        setProject(project)
      } else {
        toast(TYPE.ERROR, t.project.view.error)
      }
    }
    if (!project) {
      getProject()
    }
  }, [project, uuid, t, projectFeature])

  const isWorkshop = () => {
    return project && _.has(project, 'attendee.amount') && isFinite(project.attendee.amount)
  }

  if (!project) return null
  return (
    <MediumContainer>
      <Headline>{project.nameAndLocation.name}</Headline>
      <div className="rounded-md bg-gray-thirdary p-2 flex flex-col md:flex-row gap-2 md:gap-10 justify-between">
        <div className="border border-gray-secondary bg-white rounded-md p-2 flex items-center w-full">
          {!isWorkshop() && <DefaultProperties project={project} />}
          {isWorkshop() && <WorkshopProperties project={project} />}
        </div>
        {project.rating && (
          <div className="border border-gray-secondary bg-white rounded-md py-2 px-3 flex flex-row items-center justify-around md:flex-col">
            <div className="text-sm text-light-black">{t.project.search.view.rating.title}</div>
            <div className="flex flex-row gap-2 items-center justify-end md:justify-center">
              <GetIcon name={'star-filled'} className={'h-6  fill-yellow-primary'} />
              <div className="font-bold text-lg text-gray-primary">{project.rating.publicScore}</div>
            </div>
          </div>
        )}
      </div>
      <GridViewWithCarousel enableFullHeightContent className="mt-2" images={project.design.keys.map((key) => `${s3PublicStoragePrefix}${key}`)}>
        <Favorite className="top-3 right-3" initIsFavorite={project.isFavorite} initCount={project.favoriteCount} projectUUID={project.uuid} />
      </GridViewWithCarousel>
      <div className="flex items-center justify-center gap-3 mt-5">
        <Button
          text={t.project.search.view.cta.artist}
          className="w-fit h-fit py-2 px-5 btn-secondary"
          href={`/artist/view/${project.artist.personalDetail.username}`}
        />
        {(!accountLink || accountLink.purposeFlag === PurposeFlag.BOOKER) && (
          <Button
            text={t.project.search.view.cta.artistRequest}
            className="w-fit h-fit py-2 px-5 btn-secondary"
            href={`/request/create/${project.artist.personalDetail.username}`}
          />
        )}
      </div>
    </MediumContainer>
  )
}

export default View
