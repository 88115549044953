import { useSelector } from 'react-redux'
import MediumContainer from '../../../../UI/Container/MediumContainer'
import Loading from '../../../../UI/Loading/Loading'
import props from '../../../../../redux/props'
import { useParams } from 'react-router-dom'
import useGetProject from '../../../../hooks/useGetProject'
import { Status, VerifiedBy } from '../../../../../types/Project'
import Milestones, { View } from '../../../../UI/Milestones/Milestones'
import GrayBox from '../../../../UI/Graybox/Graybox'
import NewButton from '../../../../UI/NewButton/NewButton'
import Properties from '../../../Components/Project/View/Properties/Properties'
import ChatPreview from '../../../Components/Project/View/ChatPreview/ChatPreview'
import Pricing from './Pricing/Pricing'
import Button from '../../../../UI/NewButton/NewButton'
import nextProject from '../../../../../assets/image/project/next-project.png'
import { useState } from 'react'
import GoToRating from './GoToRating/GoToRating'
import ArtistCard from '../../Request/ReverseOffer/ArtistCard'
import { Artist } from '../../../../../types/Artist'
import SmallContainer from '../../../../UI/Container/SmallContainer'

const Project: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const { uuid } = useParams<{ uuid: string }>()
  const [project] = useGetProject(uuid)
  const [isAddOnMilestone, setIsAddOnMilestone] = useState<boolean>(false)

  const isExternalProject = () => !!project && project.verification.verifiedBy === VerifiedBy.EXTERNAL

  const chatIsEnabled = () => {
    return !isExternalProject() && project.status !== Status.CANCELLED
  }

  return (
    <MediumContainer className="py-3">
      <Loading loading={project} />
      {project && (
        <>
          <div className="text-blue-primary text-center text-lg">
            {t.project.view.titlePrefix}
            {project.nameAndLocation.name}
          </div>
          {!!project.artist && (
            <SmallContainer className="mt-6 mb-10">
              <ArtistCard artist={project.artist as Artist}></ArtistCard>
            </SmallContainer>
          )}
          <GrayBox className="w-full mb-10 border-4 border-blue-primary bg-white">
            <div className="py-5 text-center text-blue-primary font-bold">{t.project.view.nextProject.text}</div>
            <div className="lg:absolute right-7 top-0 lg:h-full text-center flex items-center justify-center">
              <img src={nextProject} alt="" className="h-10 mr-5 hidden md:block" />
              <Button
                text={t.project.view.nextProject.cta}
                href={`/request/create/${project.artist.personalDetail.username}`}
                className="btn-secondary w-fit !text-sm"
              />
            </div>
          </GrayBox>
          {!isExternalProject() && project.status === Status.COMPLETED && <GoToRating project={project} />}
          {!isExternalProject() && (
            <GrayBox
              title={t.project.view.milestone.title}
              className={isAddOnMilestone ? 'border-4 border-magenta-primary pb-14 md:pb-7' : 'pb-14 md:pb-7'}
            >
              {project.status === Status.PENDING_DOWNPAYMENT ? (
                <div className="text-center text-gray-primary text-sm mb-5">{t.project.view.milestone.waitingForDownpayment}</div>
              ) : (
                <>
                  <Milestones view={View.SINGLE} project={project} isAddOnCallback={setIsAddOnMilestone} />
                  <NewButton
                    className="hidden md:flex btn-secondary ml-auto w-fit mt-2"
                    text={t.project.view.milestone.goTo}
                    href={`/home/booker/project/milestones/${project.uuid}`}
                  />
                  <div className="md:hidden flex items-center justify-center  gap-2 absolute bottom-4 w-full left-0">
                    <NewButton
                      className="btn-secondary !text-2xs w-fit !h-6 md:!h-5 mx-auto"
                      text={t.project.view.milestone.goTo}
                      href={`/home/booker/project/milestones/${project.uuid}`}
                    />
                  </div>
                </>
              )}
            </GrayBox>
          )}
          {chatIsEnabled() && <ChatPreview project={project} />}
          <Properties project={project} />
          {!isExternalProject() && <Pricing project={project} />}
        </>
      )}
    </MediumContainer>
  )
}

export default Project
