import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { useState } from 'react'
import { Pricing } from '../types'
import { Price } from '../../../../types/Pricing'
import TypeUnitValueMask from '../../../UI/Pricing/Editable/TypeUnitValueMask'
import Currency from '../../../UI/NewInput/Currency'
import Section from '../../../UI/NewAccordion/Section/Section'
import { getUUID } from '../../../../utility'
import SectionHeader from '../../../UI/Pipeline/SectionHeader'

type Props = {
  initData?: Pricing['concept']
  update: (value: any) => void
}

const Concept: React.FC<Props> = ({ initData, update }) => {
  const t = useSelector((state) => state[props.TRANSLATION])
  const [data, setData] = useState<Pricing['concept']>(
    initData || {
      price: null,
    },
  )

  const set = (prop: 'price') => (value: Price) => {
    setData({ ...data, [prop]: value })
    update({ ...data, [prop]: value })
  }

  return (
    <div>
      <SectionHeader headline={t.register.artist.pricing.concept.headline} small />
      <Section
        className="mt-2"
        title={t.register.artist.pricing.concept.hint}
        content={t.register.artist.pricing.concept.hintText.map((t) => (
          <div className="text-white text-xs mb-2" key={getUUID()}>
            {t}
          </div>
        ))}
      />
      <div className="w-2/3 md:w-1/2 mt-5">
        <TypeUnitValueMask type={t.register.artist.pricing.concept.amount} unit={t.register.artist.pricing.concept.unit}>
          <Currency value={data.price} onChange={set('price')} contentClassName="font-light" className="pr-1" />
        </TypeUnitValueMask>
      </div>
    </div>
  )
}

export default Concept
