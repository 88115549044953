import { useSelector } from 'react-redux'
import DataSection from '../../../Components/DataSection/DataSection'
import { DataSectionComponent } from '../../types'
import props from '../../../../../redux/props'
import { prettyLocation } from '../../../../../utility'
import Checkbox from '../../../../UI/Checkbox/Checkbox'
import { useState } from 'react'
import { LocationDetail } from '../../../../../types/Artist'
import LocationAutocomplete from '../../../../UI/LocationAutocomplete/LocationAutocomplete'
import Number from '../../../../UI/NewInput/Number'
import { crud } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../../../../toast'

const Location: DataSectionComponent = ({ artist, onChangeCallback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [locationDetail, setLocationDetail] = useState<LocationDetail>(artist.locationDetail)
  const artistFeature = crud.use(props.ARTIST)

  const Default = (
    <div className="text-sm">
      {locationDetail.nationwide && `${prettyLocation(locationDetail.location)} - ${t.generic.nationwide}`}
      {!locationDetail.nationwide && `${locationDetail.radius}${t.artist.profile.location.radius}${prettyLocation(locationDetail.location)}`}
    </div>
  )

  const set = (key: string) => (value: any) => {
    setLocationDetail({ ...locationDetail, [key]: value })
  }

  const save = async () => {
    const response = await artistFeature.update({ uuid: artist.uuid, locationDetail })
    if (!response) {
      toast(TYPE.ERROR, t.artist.profile.location.edit.error)
    } else {
      if (onChangeCallback) {
        onChangeCallback(locationDetail)
      }
    }
    return !!response
  }

  return (
    <DataSection className="md:w-1/3" title={t.artist.profile.location.title} defaultView={Default} saveCallback={save}>
      <div className="my-2 text-sm">{t.artist.profile.location.edit.addressTitle}</div>
      <LocationAutocomplete onChange={set('location')} label={t.generic.postcodeCity} initValue={locationDetail.location?.name} />
      <div className="mt-4 text-sm font-bold">{t.artist.profile.location.edit.radiusTitle}</div>
      <div className="my-2 text-sm">{t.artist.profile.location.edit.radiusHint}</div>
      <Number
        disabled={locationDetail.nationwide}
        onChange={set('radius')}
        label={t.artist.profile.location.edit.radius}
        value={locationDetail.radius}
        suffixText={t.generic.km}
      />
      <Checkbox checked={locationDetail.nationwide} onChange={set('nationwide')} label={t.generic.nationwide} className="mt-2" />
    </DataSection>
  )
}

export default Location
