import styled from 'styled-components'

export const StyledControls = styled.div<{ scrollValue: number }>`
  justify-content: center;
  margin-top: 50px;
  display: flex;
  gap: 20px;
  bottom: ${({ scrollValue }) => scrollValue || 0}px;
  left: 0;
  right: 0;
  margin-bottom: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    margin-bottom: 75px;
  }
`
