import { useSelector } from 'react-redux'
import { Artist, BankAccountStatus, BusinessType, IdentityCardStatus } from '../../../../../types/Artist'
import GrayBox from '../../../../UI/Graybox/Graybox'
import props from '../../../../../redux/props'
import NewButton from '../../../../UI/NewButton/NewButton'
import progressIconFilled from '../../../../../assets/image/progress/filled.png'

type Props = {
  artist: Artist
}

export const taxDetailIsFilled = (artist: Artist) => {
  const isCompany = artist.billingDetail?.businessType === BusinessType.COMPANY

  if (isCompany) {
    return (
      !!artist.invoiceDetail.taxNumber && !!artist.invoiceDetail.taxID && !!artist.invoiceDetail.registerNumber && !!artist.invoiceDetail.registerName
    )
  } else {
    return !!artist.invoiceDetail.taxNumber
  }
}

const Progress: React.FC<Props> = ({ artist }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const getProgress = () => {
    let progress = 25
    if (artist.invoiceDetail.stripe.bankAccountStatus === BankAccountStatus.VERIFIED) {
      progress += 25
    }
    if (artist.invoiceDetail.stripe.identityCardStatus === IdentityCardStatus.VERIFIED) {
      progress += 25
    }
    if (taxDetailIsFilled(artist)) {
      progress += 25
    }

    return progress
  }

  const getTailwindWidth = () => {
    switch (getProgress()) {
      case 25:
        return 'w-1/4'
      case 50:
        return 'w-1/2'
      case 75:
        return 'w-3/4'
      case 100:
        return 'w-full'
      default:
        return 'w-0'
    }
  }

  if (!artist || getProgress() === 100) return null
  return (
    <GrayBox className="mb-3 border-4 border-magenta-primary">
      <div className="flex flex-row gap-3 lg:w-[70%] mx-auto items-center">
        <div className="w-full !h-7 border-blue-primary border-2 bg-white relative rounded-full">
          <div
            className={`absolute h-full top-0 -left-[2px] z-1 ${getTailwindWidth()} text-center bg-blue-primary text-white text-xs font-bold flex items-center justify-center rounded-full transition-all`}
          >
            {getProgress()}
            {t.generic.percent}
            <div className="absolute right-0 -top-1">
              <img src={progressIconFilled} className="h-8 w-8" alt="" />
            </div>
          </div>
          <div className="absolute -right-1 -top-1 z-0 flex items-center justify-center h-8 w-8 bg-white rounded-full border-2 border-blue-primary">
            <div className="h-5 w-5 rounded-full border border-blue-primary"></div>
          </div>
        </div>
        <NewButton text={t.artist.settings.progress.cta} href="/home/artist/settings" className="btn-secondary-outline !text-xs px-5" />
      </div>
      <div className="text-sm text-center mt-3">{t.artist.settings.progress.hint}</div>
    </GrayBox>
  )
}

export default Progress
