import { useEffect, useState } from 'react'
import { Project } from '../../../../../../types/Project'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../../../redux/routes'
import _ from 'lodash'

type Props = (project: Project) => [boolean, (isVisible: boolean) => void] | null

const useProjectVisibility: Props = (project) => {
  const setVisiblityFeature = functional.use(Routes.SET_PROJECT_VISIBILITY)
  const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const [isVisible, setIsVisible] = useState<boolean>(false)

  useEffect(() => {
    if (!isInitialized && !!project) {
      setIsVisible(_.has(project, 'isVisible') ? project.isVisible : true)
      setIsInitialized(true)
    }
  }, [isInitialized, project])

  const internalSetVisible = async (isVisible: boolean) => {
    const newIsVisible = await setVisiblityFeature({ projectUUID: project.uuid, isVisible })
    setIsVisible(newIsVisible)
  }

  return isInitialized ? [isVisible, internalSetVisible] : [null, null]
}

export default useProjectVisibility
