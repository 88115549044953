import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { useState } from 'react'
import { Pricing } from '../types'
import SizeAndPrice from '../../../UI/Pricing/Editable/SizeAndPrice'
import SectionHeader from '../../../UI/Pipeline/SectionHeader'
import { Sizes } from '../../../../types/Size'

type Props = {
  initData?: Pricing['art']
  update: (value: any) => void
}

const Art: React.FC<Props> = ({ initData, update }) => {
  const t = useSelector((state) => state[props.TRANSLATION])
  const [data, setData] = useState<Pricing['art']>(
    initData || {
      [Sizes.S]: null,
      [Sizes.M]: null,
      [Sizes.L]: null,
    },
  )

  const set = (prop: Sizes) => (price: number) => {
    setData({ ...data, [prop]: price })
    update({ ...data, [prop]: price })
  }

  return (
    <div>
      <SectionHeader headline={t.register.artist.pricing.art.headline} small />
      <div className="flex flex-col gap-3 mt-3">
        <SizeAndPrice size={Sizes.S} price={data[Sizes.S]} updatePrice={set(Sizes.S)} />
        <SizeAndPrice size={Sizes.M} price={data[Sizes.M]} updatePrice={set(Sizes.M)} />
        <SizeAndPrice size={Sizes.L} price={data[Sizes.L]} updatePrice={set(Sizes.L)} />
      </div>
    </div>
  )
}

export default Art
