import { useSelector } from 'react-redux'
import DataSection from '../../../Components/DataSection/DataSection'
import { DataSectionComponent } from '../../types'
import props from '../../../../../redux/props'
import { useState } from 'react'
import toast, { TYPE } from '../../../../../toast'
import { crud } from '@think-internet/zeus-frontend-package'
import NewInput from '../../../../UI/NewInput/NewInput'
import { BillingDetail, BusinessType } from '../../../../../types/Artist'
import Property from '../../../../UI/PropertyList/Property'
import { getPrettyDate, isValidPhoneNumber } from '../../../../../utility'
import Phone from '../../../../UI/NewInput/Phone'

const Billing: DataSectionComponent = ({ artist, onChangeCallback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const artistFeature = crud.use(props.ARTIST)
  const [data, setData] = useState<BillingDetail>(artist.billingDetail)

  const Default = (
    <div>
      <Property property={{ name: t.generic.firstname, value: data.firstname }} />
      <Property property={{ name: t.generic.lastname, value: data.lastname }} />
      <Property property={{ name: t.generic.phone, value: data.phone }} />
      <Property property={{ name: t.generic.birthday, value: getPrettyDate(new Date(data.birthday)) }} />
      <Property property={{ name: t.generic.companyName, value: data.companyName }} />
      <Property property={{ name: t.generic.street, value: data.street }} />
      <Property property={{ name: t.generic.postcode, value: data.postcode }} />
      <Property property={{ name: t.generic.city, value: data.city }} />
    </div>
  )

  const save = async () => {
    if (isValidPhoneNumber(data.phone)) {
      const status = await artistFeature.update({ uuid: artist.uuid, billingDetail: { ...artist.billingDetail, ...data } })
      if (!status) {
        toast(TYPE.ERROR, t.artist.settings.billing.edit.error)
      } else {
        onChangeCallback(data)
      }
      return status
    } else {
      toast(TYPE.ERROR, t.generic.phoneError)
    }
  }

  const set = (key: keyof BillingDetail) => (value: string) => setData({ ...data, [key]: value })

  return (
    <DataSection className="md:w-1/2" title={t.artist.settings.billing.title} defaultView={Default} enableCancel saveCallback={save}>
      <div className="text-sm text-black-secondary my-2">{t.artist.settings.billing.edit.hint}</div>
      <div className="flex gap-2 flex-col">
        <NewInput required value={data.firstname} onChange={set('firstname')} placeholder={t.generic.firstname} />
        <NewInput required value={data.lastname} onChange={set('lastname')} placeholder={t.generic.lastname} />
        <NewInput type="date" required value={new Date(data.birthday)} onChange={set('birthday')} placeholder={t.generic.birthday} />
        <Phone required value={data.phone} onChange={set('phone')} placeholder={t.generic.phone} />
        <NewInput
          required={data.businessType === BusinessType.COMPANY}
          value={data.companyName}
          onChange={set('companyName')}
          placeholder={`${t.generic.companyName}${data.businessType === BusinessType.INDIVIDUAL ? t.generic.optionalSuffix : ''}`}
        />
        <NewInput required value={data.street} onChange={set('street')} placeholder={t.generic.street} />
        <NewInput required value={data.postcode} onChange={set('postcode')} placeholder={t.generic.postcode} />
        <NewInput required value={data.city} onChange={set('city')} placeholder={t.generic.city} />
      </div>
    </DataSection>
  )
}

export default Billing
