import { useSelector } from 'react-redux'
import DataSection from '../../../Components/DataSection/DataSection'
import { DataSectionComponent } from '../../types'
import props from '../../../../../redux/props'
import { useState } from 'react'
import toast, { TYPE } from '../../../../../toast'
import { functional } from '@think-internet/zeus-frontend-package'
import NewInput from '../../../../UI/NewInput/NewInput'
import { InvoiceDetail, BusinessType } from '../../../../../types/Artist'
import Property from '../../../../UI/PropertyList/Property'
import Section from '../../../../UI/NewAccordion/Section/Section'
import NewToggle from '../../../../UI/NewToggle/NewToggle'
import { getUUID } from '../../../../../utility'
import Routes from '../../../../../redux/routes'
import { taxDetailIsFilled } from './Progress'

const Tax: DataSectionComponent = ({ artist, onChangeCallback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const updateArtistInvoiceDetail = functional.use(Routes.UPDATE_ARTIST_INVOICE_DETAIL)
  const [data, setData] = useState<InvoiceDetail>(artist.invoiceDetail)

  const isCompany = artist.billingDetail?.businessType === BusinessType.COMPANY

  const Default = (
    <div>
      <div className="flex items-center gap-2">
        <NewToggle checked={data.smallBusinessRegulationActive} disabled />
        <div className="text-sm text-black-secondary">{t.register.artist.invoiceDetail.smallBusinessRegulation.toggle}</div>
      </div>
      <Property property={{ name: t.generic.invoice.taxNumber, value: data.taxNumber }} />
      <Property property={{ name: t.generic.invoice.taxID, value: data.taxID }} />
      <Property property={{ name: t.generic.invoice.registerNumber, value: data.registerNumber }} />
      <Property property={{ name: t.generic.invoice.registerName, value: data.registerName }} />
    </div>
  )

  const save = async () => {
    const status = await updateArtistInvoiceDetail({ invoiceDetail: { ...artist.invoiceDetail, ...data } })
    if (!status) {
      toast(TYPE.ERROR, t.artist.settings.tax.edit.error)
    } else {
      onChangeCallback(data)
    }
    return status
  }

  const set = (key: keyof InvoiceDetail) => (value: any) => setData({ ...data, [key]: value })

  return (
    <DataSection
      isHighlighted={!taxDetailIsFilled(artist)}
      className="md:w-1/2"
      title={t.artist.settings.tax.title}
      defaultView={Default}
      enableCancel
      saveCallback={save}
    >
      <div className="text-sm text-black-secondary my-2">{t.artist.settings.tax.edit.hint}</div>
      <div className="flex gap-2 flex-col">
        <Section
          title={t.generic.invoice.smallBusinessRegulation.hint}
          content={t.generic.invoice.smallBusinessRegulation.hintText.map((t: string) => (
            <div className="text-white text-xs mb-2" key={getUUID()}>
              {t}
            </div>
          ))}
        />
        <div className="flex items-center gap-2">
          <NewToggle checked={data.smallBusinessRegulationActive} onChange={set('smallBusinessRegulationActive')} />
          <div className="text-sm text-black-secondary">{t.register.artist.invoiceDetail.smallBusinessRegulation.toggle}</div>
        </div>

        <NewInput required value={data.taxNumber} onChange={set('taxNumber')} placeholder={t.generic.invoice.taxNumber} />
        <NewInput
          required={isCompany}
          value={data.taxID}
          onChange={set('taxID')}
          placeholder={`${t.generic.invoice.taxID}${artist.billingDetail.businessType === BusinessType.INDIVIDUAL ? t.generic.optionalSuffix : ''}`}
        />
        <NewInput
          required={isCompany}
          value={data.registerNumber}
          onChange={set('registerNumber')}
          placeholder={`${t.generic.invoice.registerNumber}${
            artist.billingDetail.businessType === BusinessType.INDIVIDUAL ? t.generic.optionalSuffix : ''
          }`}
        />
        <NewInput
          required={isCompany}
          value={data.registerName}
          onChange={set('registerName')}
          placeholder={`${t.generic.invoice.registerName}${
            artist.billingDetail.businessType === BusinessType.INDIVIDUAL ? t.generic.optionalSuffix : ''
          }`}
        />
      </div>
    </DataSection>
  )
}

export default Tax
