import { useSelector } from 'react-redux'
import DataSection from '../../../Components/DataSection/DataSection'
import { DataSectionComponent } from '../../types'
import props from '../../../../../redux/props'
import { useState } from 'react'
import toast, { TYPE } from '../../../../../toast'
import { crud } from '@think-internet/zeus-frontend-package'
import NewInput from '../../../../UI/NewInput/NewInput'
import { PersonalDetail } from '../../../../../types/Artist'

const Username: DataSectionComponent = ({ artist, onChangeCallback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const artistFeature = crud.use(props.ARTIST)
  const [data, setData] = useState<PersonalDetail>(artist.personalDetail)

  const Default = <div>{data.username}</div>

  const save = async () => {
    const status = await artistFeature.update({ uuid: artist.uuid, personalDetail: { ...artist.personalDetail, ...data } })
    if (!status) {
      toast(TYPE.ERROR, t.artist.settings.personalDetail.edit.error)
    } else {
      onChangeCallback(data)
    }
    return status
  }

  const set = (key: keyof PersonalDetail) => (value: string) => setData({ ...data, [key]: value })

  return (
    <DataSection className="md:w-1/2" title={t.artist.settings.personalDetail.title} defaultView={Default} enableCancel saveCallback={save}>
      <div className="text-sm text-black-secondary my-2">{t.artist.settings.personalDetail.edit.hint}</div>
      <div className="flex gap-2 flex-col">
        <NewInput required value={data.username} onChange={set('username')} />
      </div>
    </DataSection>
  )
}

export default Username
