type Props = {
  children: React.ReactNode
  className?: string
}

const DataSectionWrapper: React.FC<Props> = ({ children, className = '' }) => {
  return <div className={`flex flex-col md:flex-row gap-3 ${className}`}>{children}</div>
}

export default DataSectionWrapper
