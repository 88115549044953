import { Route, Routes } from 'react-router-dom'
import View from './View'
import List from './List'

const Request = () => {
  return (
    <Routes>
      <Route path={'view/:uuid'} element={<View />} />
      <Route path={'*'} element={<List />} />
    </Routes>
  )
}

export default Request
