import { useSelector } from 'react-redux'
import { Price } from '../../../../types/Pricing'
import props from '../../../../redux/props'
import Currency from '../../../UI/NewInput/Currency'
import TypeUnitValueMask from './TypeUnitValueMask'
import { Sizes, SizesFullName, sizeValues } from '../../../../types/Size'

type Props = {
  size: Sizes | SizesFullName | string
  price: Price | string
  updatePrice?: (price: Price) => void
  disabled?: boolean
  hiddenDisabled?: boolean
  required?: boolean
  readOnly?: boolean
  noUnit?: boolean
  sizeFullName?: boolean
}

const SizeAndPrice: React.FC<Props> = ({
  size,
  price,
  updatePrice,
  disabled,
  hiddenDisabled,
  required = false,
  readOnly = false,
  noUnit = false,
  sizeFullName = false,
}) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  if (readOnly) {
    return (
      <TypeUnitValueMask
        type={sizeFullName ? SizesFullName[size] : size}
        unit={
          noUnit
            ? undefined
            : !!sizeValues[size].max
            ? `${sizeValues[size].min} - ${sizeValues[size].max}${t.generic.squareMeter}`
            : `${t.generic.priceFromPrefix}${sizeValues[size].min}${t.generic.squareMeter}`
        }
      >
        <div className="font-light px-3 text-sm">{price}</div>
      </TypeUnitValueMask>
    )
  }

  return (
    <TypeUnitValueMask
      type={size}
      unit={
        noUnit
          ? undefined
          : !!sizeValues[size].max
          ? `${sizeValues[size].min} - ${sizeValues[size].max}${t.generic.squareMeter}`
          : `${t.generic.priceFromPrefix}${sizeValues[size].min}${t.generic.squareMeter}`
      }
    >
      <Currency
        disabled={disabled}
        hiddenDisabled={hiddenDisabled}
        value={price}
        onChange={updatePrice}
        suffixText={t.generic.currencySymbolToSqareMeter}
        contentClassName="font-light"
        required={required}
      />
    </TypeUnitValueMask>
  )
}

export default SizeAndPrice
