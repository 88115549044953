import { Route, Routes } from 'react-router-dom'
import PipelineSelection from './PipelineSelection'

const Create: React.FC = () => {
  return (
    <Routes>
      <Route path={'/:username'} element={<PipelineSelection />} />
      <Route path={'/'} element={<PipelineSelection />} />
    </Routes>
  )
}

export default Create
