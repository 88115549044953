import { useEffect, useState } from 'react'
import { StyledIcon } from './Icon.Styled'
import scrollToTopIcon from '../../../../../assets/image/scrollToTop.png'
import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'

const ScrollToTop = () => {
  const [show, setShow] = useState(false)
  const translation = useSelector((s) => s[props.TRANSLATION])

  useEffect(() => {
    const handleScroll = (e) => {
      if (e.target.scrollTop > 100) {
        setShow(true)
      } else {
        setShow(false)
      }
    }

    document.body.addEventListener('scroll', handleScroll)
    return () => document.body.removeEventListener('scroll', handleScroll)
  })

  const scrollTop = () => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  if (!show) return <></>

  return (
    <StyledIcon onClick={scrollTop} className="hidden md:block">
      <div className="bg-blue-primary rounded-full w-12 aspect-square flex items-center justify-center">
        <img className="h-6 cursor-pointer" src={scrollToTopIcon} alt={translation.projectName} />
      </div>
    </StyledIcon>
  )
}

export default ScrollToTop
