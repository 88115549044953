import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import props from '../../../redux/props'
import { useEffect, useState } from 'react'
import { crud } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../../toast'
import { Request } from '../../../types/Request'
import DefaultProperties from '../../Request/View/DefaultProperties/DefaultProperties'
import WorkshopProperties from '../../Request/View/WorkshopProperties/WorkshopProperties'
import _ from 'lodash'
import SmallContainer from '../../UI/Container/SmallContainer'

const View: React.FC<{}> = () => {
  const { uuid } = useParams<{ uuid: string }>()
  const [request, setRequest] = useState<Request>(null)
  const t = useSelector((s) => s[props.TRANSLATION])
  const requestFeature = crud.use(props.REQUEST)

  useEffect(() => {
    const getRequest = async () => {
      const request = await requestFeature.get({ uuid })
      if (request) {
        setRequest(request)
      } else {
        toast(TYPE.ERROR, t.request.view.error)
      }
    }
    if (!request) {
      getRequest()
    }
  }, [request, uuid, t, requestFeature])

  const isWorkshop = () => {
    return request && _.has(request, 'attendee.amount') && isFinite(request.attendee.amount)
  }

  if (!request) return <></>
  return (
    <>
      <SmallContainer>
        {isWorkshop() && <WorkshopProperties request={request} />}
        {!isWorkshop() && <DefaultProperties request={request} />}
      </SmallContainer>
    </>
  )
}

export default View
