import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { getPrettyDate, prettyLocation } from '../../../../utility'
import PropertyList from '../../../UI/PropertyList/PropertyList'
import { Property as PropertyType } from '../../../UI/PropertyList/types'
import { Tag, additionalTagsArray } from '../../../../types/Tag'
import { Project } from '../../../../types/Project'

type Props = {
  project: Project
}

const DefaultProperties: React.FC<Props> = ({ project }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const tags = useSelector((s) => s[props.TAG])

  const getTagName = (tagEnum: string) => {
    const tag = [...tags, ...additionalTagsArray].find((t: Tag) => t.enum === tagEnum)
    return tag ? tag.name : ''
  }

  const getPropertyList = (): PropertyType[] => {
    const propertyList: PropertyType[] = [
      { name: t.project.search.properties.location, value: prettyLocation(project.nameAndLocation.location) },
      { name: t.project.search.properties.object, value: getTagName(project.tags.object) },
      { name: t.project.search.properties.style, value: getTagName(project.tags.style.join('')) },
      {
        name: t.project.search.properties.size,
        value: `${project.sizeAndPrimer.sizeInSquareMeters}${t.generic.squareMeter}`,
      },
      {
        name: t.project.search.properties.deadlineDate,
        value: getPrettyDate(new Date(project.finalizedOn || project.deadline.date)),
      },
    ]
    return propertyList
  }

  return (
    <div className="w-full">
      <div className="hidden md:block">
        <PropertyList properties={getPropertyList()} contrast compressedView splitInHalf />
      </div>
      <div className="md:hidden">
        <PropertyList properties={getPropertyList()} contrast />
      </div>
    </div>
  )
}

export default DefaultProperties
