import { useState } from 'react'
import props from '../../../../redux/props'
import { useSelector } from 'react-redux'
import NewButton from '../../../UI/NewButton/NewButton'
import Loading from '../../../UI/Loading/Loading'
import Form from '../../../UI/Form/Form'
import GrayBox from '../../../UI/Graybox/Graybox'

type Props = {
  children?: React.ReactNode
  defaultView?: React.ReactNode
  saveCallback?: () => Promise<boolean>
  title?: string
  className?: string
  enableCancel?: boolean
  saveButtonText?: string
  isHighlighted?: boolean
}

const DataSection: React.FC<Props> = ({
  children,
  defaultView,
  saveCallback,
  title,
  className = '',
  enableCancel,
  saveButtonText,
  isHighlighted = false,
}) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [inEditView, setInEditView] = useState(false)
  const [loading, setLoading] = useState(false)

  const toggle = async () => {
    if (inEditView) {
      if (saveCallback) {
        setLoading(true)
        const result = await saveCallback()
        setLoading(false)
        if (result) {
          setInEditView(false)
        }
      } else {
        setInEditView(false)
      }
      return
    } else {
      setInEditView(true)
    }
  }

  const cancel = () => {
    setInEditView(false)
  }

  return (
    <GrayBox className={`${className} ${isHighlighted ? 'border-2 border-magenta-primary' : ''} ${!!children ? 'pb-14 md:pb-11' : ''}`}>
      <Form onSubmit={toggle}>
        {!!title && <div className="font-bold mb-4">{title}</div>}
        {!inEditView && <div>{defaultView}</div>}
        {inEditView && <div>{children}</div>}
        {!!children && (
          <div className="flex items-center justify-center md:justify-end gap-2 absolute bottom-4 w-full left-0 md:pr-4">
            {!loading && enableCancel && inEditView && (
              <NewButton className="btn btn-secondary-outline btn-sm !h-[22px] md:!h-[20px] !text-2xs" onClick={cancel} text={t.generic.cancel} />
            )}
            {!loading && (
              <NewButton
                type="submit"
                className="btn-secondary !text-2xs w-fit !h-6 md:!h-5"
                text={inEditView ? saveButtonText || t.dataSection.toggle.off : t.dataSection.toggle.on}
              />
            )}
            {loading && <Loading spinner className="!py-0 -mb-2 -mr-1 text-right" loading={false} />}
          </div>
        )}
      </Form>
    </GrayBox>
  )
}

export default DataSection
