import { Route, Routes } from 'react-router-dom'
import Search from './Search/Search'
import Create from './Create/Create'
import View from './View/View'
import Layout from '../UI/Layout/Layout'

const Request: React.FC = () => {
  return (
    <Layout authLevelRequired={false}>
      <Routes>
        <Route path={'/create/*'} element={<Create />} />
        <Route path={'/view/:uuid'} element={<View />} />
        <Route path={'*'} element={<Search />} />
      </Routes>
    </Layout>
  )
}

export default Request
