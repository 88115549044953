import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { NavigationItem } from '../types'
import Profile from './Profile'
import Default from './Item/Default'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import Sidemenu from './Sidemenu'
import { getUUID } from '../../../../../utility'

const Navigation = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])

  const isLoggedIn = () => !!accountLink

  return (
    <div className="flex justify-around gap-7 items-center">
      {t.header.navigation.default.map((item: NavigationItem) => (
        <Default className="hidden lg:flex" key={getUUID()} data={item} />
      ))}
      {isLoggedIn() ? (
        <>
          <Profile type={accountLink.purposeFlag} />
          <Sidemenu type={accountLink.purposeFlag} />
        </>
      ) : (
        <Default data={t.header.navigation.isNotLoggedIn} />
      )}
    </div>
  )
}

export default Navigation
