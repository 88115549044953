import { Type } from '../../../types/Notification'
import useGetNotification from '../../hooks/useGetNotifications'

type Props = {
  type: Type | Type[]
  className?: string
}

const NotificationBell: React.FC<Props> = ({ type, className }) => {
  const notifications = useGetNotification(type)

  if (notifications.length === 0) return null
  return (
    <div
      className={`absolute -right-1 -top-1 bg-magenta-primary text-white flex items-center justify-center text-sm rounded-full h-4 min-w-[1rem] px-1 ${className}`}
    >
      {notifications.length}
    </div>
  )
}

export default NotificationBell
