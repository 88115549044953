import { useSelector } from 'react-redux'
import icon from '../../../../../assets/image/chat/milestones/icon.png'
import start from '../../../../../assets/image/chat/milestones/start.png'
import end from '../../../../../assets/image/chat/milestones/end.png'

import props from '../../../../../redux/props'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import { PurposeFlag } from '../../../../../types/Auth'

const Placeholder = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const amount = [...new Array(3)]
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])

  const isArtist = accountLink.purposeFlag === PurposeFlag.ARTIST

  return (
    <div className="px-3">
      <div className=" px-3 relative">
        <div className="hidden md:flex flex-col">
          {amount.map((x, i) => (
            <img key={i} src={start} alt="" />
          ))}
          <img src={end} alt="" />
        </div>
        <div className="absolute top-0 left-0 px-8 w-full h-full flex items-center pt-20 flex-col">
          <img src={icon} alt="" className="h-10" />
          <div className="text-gray-primary font-bold text-lg mt-2 text-center">{t.chat.milestones.placeholder}</div>
          {!!isArtist && <div className="text-gray-primary text-center text-sm">{t.chat.milestones.artistPlaceholderHint}</div>}
          {!isArtist && <div className="text-gray-primary text-center text-sm">{t.chat.milestones.bookerPlaceholderHint}</div>}
        </div>
      </div>
    </div>
  )
}

export default Placeholder
