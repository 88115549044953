import { StyledImage } from './Image.Styled'

type Props = {
  src: string
  height?: string
  width?: string
  maxHeight?: string
  maxWidth?: string
  className?: string
  margin?: string
  onClick?: () => void
}

const Image: React.FC<Props> = ({ src, height, width, maxHeight, maxWidth, className, margin, onClick }) => {
  return (
    <StyledImage
      src={src}
      height={height}
      width={width}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      className={className}
      margin={margin}
      onClick={onClick}
    />
  )
}

export default Image
