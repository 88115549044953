import { useSelector } from 'react-redux'
import DataSection from '../../../Components/DataSection/DataSection'
import { DataSectionComponent } from '../../types'
import props from '../../../../../redux/props'
import { useState } from 'react'
import toast, { TYPE } from '../../../../../toast'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../../redux/routes'
import NewInput from '../../../../UI/NewInput/NewInput'

const Mail: DataSectionComponent = ({ booker }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const updateCredentials = functional.use(Routes.UPDATE_ACCOUNT_CREDENTIALS)

  const [mail, setMail] = useState<string>(booker.accountDetail.mail)

  const Default = (
    <div>
      <div className="text-sm text-black-secondary">{t.booker.settings.mail.hint}</div>
      <div className="mt-2 font-bold">{booker.accountDetail.mail}</div>
    </div>
  )

  const save = async () => {
    const status = await updateCredentials({ identifier: mail })
    if (status) {
      toast(TYPE.SUCCESS, t.booker.settings.mail.edit.success)
      setMail(booker.accountDetail.mail)
      return true
    } else {
      toast(TYPE.ERROR, t.booker.settings.mail.edit.error)
    }
    return false
  }

  return (
    <DataSection className="md:w-2/4" title={t.booker.settings.mail.title} defaultView={Default} enableCancel saveCallback={save}>
      <div className="text-sm text-black-secondary">{t.booker.settings.mail.edit.hint}</div>
      <NewInput className="mt-2" value={mail} onChange={setMail} placeholder={t.generic.mail} type="email" required />
    </DataSection>
  )
}

export default Mail
