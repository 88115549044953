import { useSelector } from 'react-redux'
import { Project, Tags as TagsType } from '../../../../../../types/Project'
import { Set } from './Create'
import props from '../../../../../../redux/props'
import NewModal from '../../../../../UI/NewModal/NewModal'
import { useState } from 'react'
import plus from '../../../../../../assets/image/artist/plus.png'
import SelectableTagList from '../../../../../UI/SelectableTagList/SelectableTagList'
import { Category, Tag } from '../../../../../../types/Tag'

type Props = {
  project: Project
  onChange: Set
}

const Tags: React.FC<Props> = ({ project, onChange }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const allTags: Tag[] = useSelector((state) => state[props.TAG])

  const [open, setOpen] = useState<boolean>(false)

  const set = (key: keyof TagsType) => (value: any) => onChange<TagsType>('tags', key)(value)

  return (
    <div>
      <div className="text-sm mt-5 bg-white p-2 text-light-black rounded-sm">{t.artist.profile.externalProjects.edit.tags.text}</div>
      <div
        className="bg-blue-primary h-10 md:h-7 items-center flex pl-2 mt-5 w-2/3 md:w-1/2 relative cursor-pointer rounded-r-full rounded-sm"
        onClick={() => setOpen(true)}
      >
        <span className="text-white text-lg md:text-md">{t.artist.profile.externalProjects.edit.tags.cta}</span>
        <div className="absolute right-0 top-[-10%] h-[120%]">
          <img src={plus} alt="" className="h-full" />
        </div>
      </div>
      <NewModal large show={open} onClose={() => setOpen(false)} onConfirm={() => setOpen(false)} noForm>
        <div className="font-bold">{t.artist.profile.externalProjects.edit.tags.modal.title}</div>
        <div className="flex flex-col md:flex-row gap-8 md:gap-10 mt-3">
          <div className="flex flex-col gap-3 flex-1">
            <div className="w-full text-left mt-3 pl-3 md:pl-0 mb-2 md:mb-0">{t.artist.profile.externalProjects.edit.tags.modal.object}</div>
            <SelectableTagList
              maxVisibleRows={3}
              maxVisibleItemsPerRow={3}
              selectedTagENUMList={[project.tags?.object]}
              tags={allTags.filter((t: Tag) => t.category === Category.OBJECT)}
              onChange={set('object')}
              singleSelect
            />
          </div>
          <div className="flex flex-col gap-3 mt-3 flex-1">
            <div className="w-full text-left pl-3 md:pl-0 mb-2 md:mb-0">{t.artist.profile.externalProjects.edit.tags.modal.style}</div>
            <SelectableTagList
              maxVisibleRows={3}
              maxVisibleItemsPerRow={3}
              selectedTagENUMList={project.tags?.style}
              tags={allTags.filter((t: Tag) => t.category === Category.STYLE)}
              onChange={set('style')}
            />
          </div>
        </div>
      </NewModal>
    </div>
  )
}

export default Tags
