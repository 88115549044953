import { useSelector } from 'react-redux'
import DataSection from '../../../Components/DataSection/DataSection'
import { DataSectionComponent } from '../../types'
import props from '../../../../../redux/props'
import { useState } from 'react'
import { Pricing } from '../../../../../types/Artist'
import { crud } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../../../../toast'
import Number from '../../../../UI/NewInput/Number'

const DownPayment: DataSectionComponent = ({ artist, onChangeCallback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [pricing, setPricing] = useState<Pricing>(artist.pricing)
  const artistFeature = crud.use(props.ARTIST)

  const Default = (
    <div>
      <div className="text-sm">{t.artist.profile.downPayment.text(pricing.downPaymentPercentage)}</div>
    </div>
  )

  const set = (value: any) => setPricing({ ...pricing, downPaymentPercentage: value })

  const save = async () => {
    if (isFinite(pricing.downPaymentPercentage) && pricing.downPaymentPercentage >= 10 && pricing.downPaymentPercentage <= 70) {
      const response = await artistFeature.update({ uuid: artist.uuid, pricing })
      if (!response) {
        toast(TYPE.ERROR, t.artist.profile.downPayment.edit.errorGeneric)
      } else {
        if (onChangeCallback) {
          onChangeCallback(pricing)
        }
      }
      return !!response
    } else {
      toast(TYPE.ERROR, t.artist.profile.downPayment.edit.error)
    }
    return false
  }

  return (
    <DataSection className="md:w-1/3" title={t.artist.profile.downPayment.title} defaultView={Default} saveCallback={save}>
      <div className="text-sm">{t.artist.profile.downPayment.hint}</div>
      <Number required value={pricing.downPaymentPercentage} onChange={set} suffixText={t.generic.percent} className="mt-2" />
    </DataSection>
  )
}

export default DownPayment
