import { ArrowLeftOnRectangleIcon, ArrowRightOnRectangleIcon, Cog6ToothIcon, TagIcon } from '@heroicons/react/24/outline'
import { ReactComponent as Request } from '../../../assets/image/menu/Request.svg'
import { ReactComponent as PublicRequest } from '../../../assets/image/menu/PublicRequest.svg'
import { ReactComponent as Chat } from '../../../assets/image/menu/Chat.svg'
import { ReactComponent as Finance } from '../../../assets/image/menu/Finance.svg'
import { ReactComponent as Dashboard } from '../../../assets/image/menu/Dashboard.svg'
import { ReactComponent as Project } from '../../../assets/image/menu/Project.svg'
import { ReactComponent as Profil } from '../../../assets/image/menu/Profil.svg'
import { ReactComponent as FavoriteInactive } from '../../../assets/image/favorite/heartInactive.svg'
import { ReactComponent as FavoriteActive } from '../../../assets/image/favorite/heartActive.svg'
import { ReactComponent as StarNotFilled } from '../../../assets/image/rating/star-not-filled.svg'
import { ReactComponent as StarFilled } from '../../../assets/image/rating/star-filled.svg'
import { ReactComponent as Filter } from '../../../assets/image/searchFilterMask/filter.svg'
import { ReactComponent as X } from '../../../assets/image/footer/x.svg'
import { ReactComponent as FilterGrid } from '../../../assets/image/filter/grid-view.svg'
import { ReactComponent as FilterList } from '../../../assets/image/filter/list-view.svg'
import { ReactComponent as Upload } from '../../../assets/image/chat/chat/upload.svg'

type Props = {
  name: string
  className?: string
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  onClick?: () => void
}

const GetIcon: React.FC<Props> = ({ name, className = '', onMouseEnter, onMouseLeave, onClick }) => {
  className = `aspect-square ${className}`

  const defaultProps = {
    onMouseEnter,
    onMouseLeave,
    onClick,
  }

  const transformToText = () => {
    return className.replaceAll('fill-', 'text-')
  }

  switch (name) {
    case 'project':
      return <Project className={className} {...defaultProps} />
    case 'artist':
      return <Profil className={className} {...defaultProps} />
    case 'upload':
      return <Upload className={className} {...defaultProps} />
    case 'request':
      return <Request className={className} {...defaultProps} />
    case 'publicrequest':
      return <PublicRequest className={className} {...defaultProps} />
    case 'home':
      return <Profil className={className} {...defaultProps} />
    case 'settings':
      return <Cog6ToothIcon className={transformToText()} {...defaultProps} />
    case 'dashboard':
      return <Dashboard className={className} {...defaultProps} />
    case 'finance':
      return <Finance className={className} {...defaultProps} />
    case 'chat':
      return <Chat className={className} {...defaultProps} />
    case 'tag':
      return <TagIcon className={transformToText()} {...defaultProps} />
    case 'login':
      return <ArrowRightOnRectangleIcon className={transformToText()} {...defaultProps} />
    case 'logout':
      return <ArrowLeftOnRectangleIcon className={transformToText()} {...defaultProps} />
    case 'favorite-inactive':
      return <FavoriteInactive className={className} {...defaultProps} />
    case 'favorite-active':
      return <FavoriteActive className={className} {...defaultProps} />
    case 'star-not-filled':
      return <StarNotFilled className={className} {...defaultProps} />
    case 'star-filled':
      return <StarFilled className={className} {...defaultProps} />
    case 'filter':
      return <Filter className={className} {...defaultProps} />
    case 'x':
      return <X className={className} {...defaultProps} />
    case 'filter-grid':
      return <FilterGrid className={className} {...defaultProps} />
    case 'filter-list':
      return <FilterList className={className} {...defaultProps} />
    default:
      return null
  }
}

export default GetIcon
