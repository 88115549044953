import { StyledHeader } from './Header.Styled'
import { useSelector } from 'react-redux'
import logo from '../../../../assets/image/logo-icon.png'
import { useNavigate } from 'react-router-dom'
import props from '../../../../redux/props'
import Search from './Search/Search'
import Navigation from './Navigation/Navigation'
import Container from '../../Container/Container'

const Header = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const navigate = useNavigate()

  const goHome = () => {
    let path = '/'
    navigate(path)
  }

  return (
    <StyledHeader>
      <Container>
        <div className="flex flex-row justify-between items-center gap-5 md:gap-10">
          <div onClick={goHome} className="flex flex-row gap-3 items-center cursor-pointer">
            <img className="w-12" src={logo} alt="" />
            <div className="hidden sm:block text-blue-primary font-thin text-xlg">{translation.generic.name}</div>
          </div>
          <Search />
          <Navigation />
        </div>
      </Container>
    </StyledHeader>
  )
}

export default Header
