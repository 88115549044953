import GrayBox from '../../../../UI/Graybox/Graybox'
import props from '../../../../../redux/props'
import { useSelector } from 'react-redux'
import Button from '../../../../UI/NewButton/NewButton'

const StartProject: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <GrayBox className="w-full !p-3">
      <div className="py-3 lg:py-5 text-center font-bold">{t.booker.dashboard.startProject.title}</div>
      <div className="relative lg:absolute lg:right-7 top-0 lg:h-full flex items-center justify-center">
        <Button text={t.booker.dashboard.startProject.cta} href={'/request/create'} className="btn-secondary w-fit" />
      </div>
    </GrayBox>
  )
}

export default StartProject
