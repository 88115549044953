import { useEffect, useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../redux/routes'
import Recommendation, { Type } from '../../types/Recommendation'

type Props = (types: Type[]) => Recommendation[]

const useListRecommendations: Props = (types) => {
  const [recommendations, setRecommendations] = useState<Recommendation[]>()
  const listRecommendations = functional.use(Routes.LIST_RECOMMENDATIONS)

  useEffect(() => {
    const list = async () => {
      const recommendations = await listRecommendations({ types })
      setRecommendations(recommendations)
    }
    if (!recommendations) {
      list()
    }
  })

  return recommendations
}

export default useListRecommendations
