import { Project } from '../../../../../../types/Project'
import _ from 'lodash'
import Default from './Default'
import Workshop from './Workshop'
import Headline from '../../../../../UI/Headline/Headline'
import GrayBox from '../../../../../UI/Graybox/Graybox'
import { useSelector } from 'react-redux'
import props from '../../../../../../redux/props'

type Props = {
  project: Project
}

const Properties: React.FC<Props> = ({ project }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const isWorkshop = _.has(project, 'attendee.amount') && isFinite(project.attendee.amount)

  return (
    <div className="mt-5">
      <Headline className="mb-2 text-md">{t.project.view.properties.headline}</Headline>
      <GrayBox title={t.project.view.properties.title}>
        <div className="lg:pl-10">
          {!!isWorkshop && <Workshop project={project} />}
          {!isWorkshop && <Default project={project} />}
        </div>
      </GrayBox>
    </div>
  )
}

export default Properties
