import { StyledFeatures, Item, Content, Image, Headline, Text } from './Features.Styled'
import Container from '../../UI/Container/Container'
import props from '../../../redux/props'
import { useSelector } from 'react-redux'
import secure from '../../../assets/image/home/features/secure.png'
import chat from '../../../assets/image/home/features/chat.png'
import invoice from '../../../assets/image/home/features/invoice.png'
import project from '../../../assets/image/home/features/project.png'
import milestones from '../../../assets/image/home/features/milestones.png'
import search from '../../../assets/image/home/features/search.png'

const Features = () => {
  const imageMapping = [secure, search, milestones, chat, project, invoice]
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <StyledFeatures>
      <Container>
        <Content>
          {translation.home.icons.map((t, i) => (
            <Item odd={i % 2 === 1} key={i}>
              <div className="flex justify-center">
                <Image src={imageMapping[i]} />
              </div>
              <Headline>{t.headline}</Headline>
              <Text>{t.text}</Text>
            </Item>
          ))}
        </Content>
      </Container>
    </StyledFeatures>
  )
}

export default Features
