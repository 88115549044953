import { Artist } from '../../types/Artist'
import { useEffect, useState } from 'react'
import { crud } from '@think-internet/zeus-frontend-package'
import props from '../../redux/props'
import { useSelector } from 'react-redux'
import { PurposeFlag } from '../../types/Auth'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'

type Props = (uuid?: string, username?: string) => [Artist, (artist: keyof Artist) => (data: any) => void]

const useGetArtist: Props = (uuid, username) => {
  const [artist, setArtist] = useState<Artist>()
  const artistFeature = crud.use(props.ARTIST)
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])

  const set = (prop: keyof Artist) => (value: any) => {
    setArtist({ ...artist, [prop]: value })
  }

  useEffect(() => {
    const get = async () => {
      if (!!uuid) {
        const artist = await artistFeature.get({ uuid })
        setArtist(artist)
      } else if (!!username) {
        const artist = await artistFeature.get({ 'personalDetail.username': username })
        setArtist(artist)
      } else if (accountLink && accountLink.purposeFlag === PurposeFlag.ARTIST) {
        const artist = await artistFeature.get({ uuid: accountLink.assignedToUUID })
        setArtist(artist)
      }
    }
    if (!artist) {
      get()
    }
  })

  return [artist, set]
}

export default useGetArtist
