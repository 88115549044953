import { useSelector } from 'react-redux'
import { Artist } from '../../../../../types/Artist'
import { Booker } from '../../../../../types/Booker'
import { Chat } from '../../../../../types/Chat'
import LocationLabel from '../../../../UI/LocationLabel/LocationLabel'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import props from '../../../../../redux/props'
import { PurposeFlag } from '../../../../../types/Auth'
import Icon from '../../../../UI/ProfileIcon/Icon'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'

type Props = {
  chat: Chat
  setChat: (chat: Chat) => void
  isActive?: boolean
  minifiedView?: boolean
  removeChatSelection?: () => void
}

const Single: React.FC<Props> = ({ chat, setChat, isActive = false, minifiedView = false, removeChatSelection }) => {
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])
  const isArtist = accountLink?.purposeFlag === PurposeFlag.ARTIST

  return (
    <div
      key={chat.projectUUID}
      className={`flex flex-row items-center items cursor-pointer w-full ${
        isActive ? 'md:rounded-sm' : 'border-t first:border-t-0 border-gray-secondary'
      } py-1 px-2 gap-2 ${isActive ? 'bg-blue-primary' : ''} ${minifiedView && 'border-b border-gray-thirdary'}`}
      onClick={() => setChat(chat)}
    >
      {!!removeChatSelection && (
        <div className="md:hidden h-14 flex items-center" onClick={removeChatSelection}>
          <ChevronLeftIcon className="h-5 text-white" />
        </div>
      )}
      {isArtist ? (
        <Icon booker={chat.booker as Booker} className="!h-14" image={chat.booker?.profileImages?.personImageURL} />
      ) : (
        <Icon artist={chat.artist as Artist} className="!h-14" image={chat.artist?.profileImages?.personImageURL} />
      )}
      <div className="overflow-hidden">
        <div className={`text-xs truncate font-bold ${isActive ? 'text-white' : 'text-gray-primary'}`}>{chat.project.nameAndLocation.name}</div>
        <div className={`text-xs truncate ${isActive ? 'text-white' : 'text-gray-primary'}`}>
          {isArtist ? `${chat.booker.billingDetail.firstname} ${chat.booker.billingDetail.lastname}` : chat.artist.personalDetail.username}
        </div>
        {!minifiedView && <LocationLabel small invert={isActive} location={chat.project.nameAndLocation.location} />}
      </div>
    </div>
  )
}

export default Single
