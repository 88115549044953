import { useSelector } from 'react-redux'
import { functional } from '@think-internet/zeus-frontend-package'
import { useState } from 'react'
import { Request } from '../../../../../types/Request'
import { Offer } from '../../../../../types/Offer'
import props from '../../../../../redux/props'
import { Project } from '../../../../../types/Project'
import Routes from '../../../../../redux/routes'
import toast, { TYPE } from '../../../../../toast'
import PriceCalculator, { PriceCalculatorType } from '../../../../UI/PriceCalculator/PriceCalculator'
import NewButton from '../../../../UI/NewButton/NewButton'
import NewModal from '../../../../UI/NewModal/NewModal'
import Loading from '../../../../UI/Loading/Loading'
import useReadNotification from '../../../../hooks/useReadNotification'
import { Type } from '../../../../../types/Notification'

type Props = {
  request: Request
  offer: Offer
  removeCallback: () => void
}

enum State {
  DEFAULT = 'DEFAULT',
  ACCEPTING = 'ACCEPTING',
  DECLINING = 'DECLINING',
}

enum Processing {
  FALSE = 'FALSE',
  SUBMITTING = 'SUBMITTING',
}

const OfferBody: React.FC<Props> = ({ request, offer, removeCallback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const acceptOffer = functional.use(Routes.ACCEPT_OFFER)
  const declineOffer = functional.use(Routes.DECLINE_OFFER)
  const [state, setState] = useState<State>(State.DEFAULT)
  const [project, setProject] = useState<Project>()
  const [processingState, setProcessingState] = useState<Processing>(Processing.FALSE)
  const read = useReadNotification()

  const decline = async () => {
    setProcessingState(Processing.SUBMITTING)
    const status = await declineOffer({ uuid: offer.uuid })
    read({
      type: Type.NEW_OFFER,
      payload: { offerUUID: offer.uuid },
    })
    setProcessingState(Processing.FALSE)
    if (!!status) {
      window.location.reload()
    } else {
      toast(TYPE.ERROR, t.request.view.offers.decline.error)
    }
  }

  const accept = async () => {
    setProcessingState(Processing.SUBMITTING)
    const project = await acceptOffer({ uuid: offer.uuid })
    read({
      type: Type.NEW_OFFER,
      payload: { offerUUID: offer.uuid, requestUUID: request.uuid },
    })
    setProcessingState(Processing.FALSE)
    if (!!project) {
      setProject(project)
    } else {
      toast(TYPE.ERROR, t.request.view.offers.accept.error)
    }
  }

  const openAccept = () => setState(State.ACCEPTING)
  const openDecline = () => setState(State.DECLINING)

  return (
    <div className="bg-white p-3">
      <PriceCalculator type={PriceCalculatorType.OFFER} artist={offer.artist} offer={offer} request={request} />
      <div className="flex justify-center gap-3 mt-7">
        <NewButton text={t.request.view.offers.accept.cta} className="btn-secondary" onClick={openAccept} />
        <NewButton text={t.request.view.offers.decline.cta} className="btn-secondary-outline" onClick={openDecline} />
      </div>
      <NewModal
        title={!!project ? t.request.view.offers.modal.title.accepted : t.request.view.offers.modal.title[state.toString().split('_')[0]]}
        show={state !== State.DEFAULT}
        noClose
        onClose={() => setState(State.DEFAULT)}
        noForm
      >
        {!project && processingState === Processing.FALSE && t.request.view.offers.modal.text[state]}
        {processingState === Processing.SUBMITTING && (
          <>
            <div className="text-center text-sm mt-5">{t.request.view.offers.modal.text.in_progress}</div>
            <Loading loading={false} />
          </>
        )}
        <div className={`flex ${!!project ? 'flex-col items-center' : 'flex-row justify-center'} gap-3 mt-10`}>
          {!!project ? (
            <div className="flex items-center flex-col">
              <div className="flex-1">{t.request.view.offers.modal.text.accepting_success}</div>
              <div className="flex flex-row justify-center gap-3 mt-10">
                <NewButton text={t.request.view.offers.modal.goToFinance} className="btn-secondary w-fit" href={`/home/booker/finance`} />
                <NewButton
                  text={t.request.view.offers.modal.goToProject}
                  className="btn-secondary-outline w-fit"
                  href={`/home/booker/project/view/${project.uuid}`}
                />
              </div>
            </div>
          ) : (
            <>
              <NewButton
                text={t.request.view.offers.modal.cta[state]}
                className="btn-secondary w-fit"
                onClick={!project && processingState === Processing.FALSE ? (state === State.ACCEPTING ? accept : decline) : null}
              />
              <NewButton text={t.generic.close} className="w-fit btn-secondary-outline" onClick={() => setState(State.DEFAULT)} />
            </>
          )}
        </div>
      </NewModal>
    </div>
  )
}

export default OfferBody
