import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import NewInput from '../../../UI/NewInput/NewInput'
import { BillingDetail as BillingDetailType } from '../types'
import { PipelineComponent } from '../../../UI/Pipeline/types'
import SmallContainer from '../../../UI/Container/SmallContainer'
import SectionHeader from '../../../UI/Pipeline/SectionHeader'
import Dropdown from '../../../UI/Dropdown/Dropdown'
import { BusinessType } from '../../../../types/Artist'
import toast, { TYPE } from '../../../../toast'

const BillingDetailOne: PipelineComponent<BillingDetailType> = ({ pipelineStateProp, data, setData, NavigationForm, finalStepSubmit }) => {
  const t = useSelector((state) => state[props.TRANSLATION])

  const set = (prop: keyof BillingDetailType) => (value: any) => setData({ ...data, [prop]: value })

  const getData = () => {
    if (!!data.businessType) {
      return data
    } else {
      toast(TYPE.ERROR, t.register.artist.billingDetail.error)
    }
    return false
  }

  return (
    <SmallContainer>
      <SectionHeader headline={t.register.artist.billingDetail.one.headline} description={t.register.artist.billingDetail.one.description} />
      <NavigationForm pipelineStateProp={pipelineStateProp} getData={getData} finalStepSubmit={finalStepSubmit}>
        <div className="mt-7 flex gap-3 flex-col lg:w-7/12 mx-auto">
          <Dropdown
            emptySelection={t.generic.businessType.label}
            onChange={set('businessType')}
            value={data.businessType}
            items={Object.values(BusinessType).map((type) => ({ value: type, label: t.generic.businessType[type] }))}
          />
          {!!data.businessType && (
            <>
              <NewInput
                required={data.businessType === BusinessType.COMPANY}
                value={data.companyName}
                onChange={set('companyName')}
                placeholder={`${t.generic.companyName}${data.businessType === BusinessType.INDIVIDUAL ? t.generic.optionalSuffix : ''}`}
              />
              <NewInput required value={data.postcode} onChange={set('postcode')} placeholder={t.generic.postcode} />
              <NewInput required value={data.city} onChange={set('city')} placeholder={t.generic.city} />
              <NewInput required value={data.street} onChange={set('street')} placeholder={t.generic.street} />
            </>
          )}
        </div>
      </NavigationForm>
    </SmallContainer>
  )
}

export default BillingDetailOne
