import styled from 'styled-components'

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`
export const Wrapper = styled.div<{ width?: string; margin?: string }>`
  width: ${({ width }) => width || '100%'};
  margin: ${({ margin }) => margin || '0px'};
`

export const Icon = styled.svg`
  fill: none;
  stroke: ${({ theme, color }) => color || theme.color.font.primary};
  stroke-width: 3px;
  color: ${({ theme }) => theme.color.blue.primary};
`

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  /* clippath: inset(50%); */
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const StyledSpan = styled.span`
  margin-left: 8px;
  color: ${({ theme, color }) => color || theme.color.gray.primary};
  cursor: pointer;
  margin-top: -2px;
  * {
    font-size: 14px;
  }
  line-height: 18px;
`

export const StyledCheckbox = styled.div<{ checked?: boolean; color?: string; round?: boolean }>`
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 1px solid ${({ theme, color }) => color || theme.color.blue.primary};
  transition: all 150ms;
  justify-content: center;
  justify-items: center;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
  ${({ round }) =>
    round &&
    `
    border-radius: 20px;
  `}

  ${Icon} {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }
`
