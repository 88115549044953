import { useSelector } from 'react-redux'
import DataSection from '../../../Components/DataSection/DataSection'
import { DataSectionComponent } from '../../types'
import props from '../../../../../redux/props'
import { useState } from 'react'
import toast, { TYPE } from '../../../../../toast'
import { functional } from '@think-internet/zeus-frontend-package'
import DocumentSelector from '../../../../UI/DocumentSelector/DocumentSelector'
import Routes from '../../../../../redux/routes'
import { Type } from '../../../../../types/Document'
import { IdentityCardStatus, InvoiceDetail } from '../../../../../types/Artist'

type DocumentObject = {
  identityCardFront: File
  identityCardBack: File
}

const Documents: DataSectionComponent = ({ artist, onChangeCallback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const addDocuments = functional.use(Routes.ADD_DOCUMENTS_TO_ARTIST)
  const [data, setData] = useState<DocumentObject>()

  const Default = (
    <div>
      <div className="text-sm text-black-secondary my-2">
        {t.artist.settings.documents.identityCard.hint[artist.invoiceDetail.stripe.identityCardStatus]}
      </div>
    </div>
  )

  const save = async () => {
    if (!!data && !!data.identityCardFront && !!data.identityCardBack) {
      const invoiceDetail: InvoiceDetail = await addDocuments({ type: Type.IDENTITY_CARD }, null, null, [
        data.identityCardFront,
        data.identityCardBack,
      ])
      if (!invoiceDetail) {
        toast(TYPE.ERROR, t.artist.settings.documents.edit.individual.errorGeneric)
      } else {
        onChangeCallback(invoiceDetail)
        setData(null)
        return true
      }
    } else {
      toast(TYPE.ERROR, t.artist.settings.documents.edit.individual.error)
    }
    return false
  }

  const onFile = (prop: keyof DocumentObject) => (files: File[]) => setData({ ...data, [prop]: files[0] })

  return (
    <DataSection
      className="md:w-1/2"
      title={t.artist.settings.documents.title}
      defaultView={Default}
      enableCancel
      saveCallback={save}
      saveButtonText={t.generic.send}
      isHighlighted={
        artist.invoiceDetail.stripe.identityCardStatus === IdentityCardStatus.MISSING ||
        artist.invoiceDetail.stripe.identityCardStatus === IdentityCardStatus.DECLINED
      }
    >
      {(artist.invoiceDetail.stripe.identityCardStatus === IdentityCardStatus.MISSING ||
        artist.invoiceDetail.stripe.identityCardStatus === IdentityCardStatus.DECLINED) && (
        <div className="flex gap-3 flex-col">
          <DocumentSelector
            title={t.artist.settings.documents.edit.individual.front}
            files={data?.identityCardFront}
            onFiles={onFile('identityCardFront')}
          />
          <DocumentSelector
            title={t.artist.settings.documents.edit.individual.back}
            files={data?.identityCardBack}
            onFiles={onFile('identityCardBack')}
          />
        </div>
      )}
    </DataSection>
  )
}

export default Documents
