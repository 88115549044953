import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import NewInput from '../../../../UI/NewInput/NewInput'
import { NameAndLocation as NameAndLocationType } from '../../../../../types/Project'
import { GetData, PipelineComponent, PipelineState, TYPES } from '../../../../UI/Pipeline/types'
import SectionHeader from '../../../../UI/Pipeline/SectionHeader'
import LocationAutocomplete from '../../../../UI/LocationAutocomplete/LocationAutocomplete'
import toast, { TYPE } from '../../../../../toast'
import { useEffect, useState } from 'react'
import Loading from '../../../../UI/Loading/Loading'
import { crud } from '@think-internet/zeus-frontend-package'
import Dropdown from '../../../../UI/Dropdown/Dropdown'
import { Artist } from '../../../../../types/Artist'
import { Primer, Request } from '../../../../../types/Request'
import { PurposeFlag } from '../../../../../types/Auth'

const NameAndLocation: PipelineComponent<NameAndLocationType> = ({
  pipelineStateProp,
  data,
  setData,
  NavigationForm,
  finalStepSubmit,
  componentMapping,
}) => {
  const t = useSelector((state) => state[props.TRANSLATION])
  const requestFeature = crud.use(props.REQUEST)
  const state: PipelineState<Request, { artist?: Artist }> = useSelector((state) => state[props.PIPELINE])
  const [exisitingRequests, setExistingRequests] = useState<Request[]>(null)
  const [selectedExistingRequest, setSelectedExistingRequest] = useState<Request>(null)
  const accountLink = useSelector((state) => state[props.ACCOUNT_LINK])

  useEffect(() => {
    const fetchRequests = async () => {
      // only artist request pipelines have existing requests
      if (state.type === TYPES.ARTIST_DEFAULT_REQUEST || state.type === TYPES.ARTIST_WORKSHOP_REQUEST) {
        // check for attenddees if it is a workshop request
        const payload = { attendee: { $exists: state.type === TYPES.ARTIST_WORKSHOP_REQUEST } }
        const requests: Request[] = await requestFeature.list({ bookerUUID: accountLink.assignedToUUID, ...payload, artistList: { $exists: true } })
        if (requests) {
          setExistingRequests(
            requests.filter((r) => {
              if (Array.isArray(r.artistList) && r.artistList.length > 0) {
                return !r.artistList.some((a) => a.uuid === state.additionalData.artist.uuid)
              }
              return false
            }),
          )
        } else {
          toast(TYPE.ERROR, t.request.create.nameAndLocation.existingProject.error)
        }
      } else {
        setExistingRequests([])
      }
    }
    if (!exisitingRequests) {
      if (!!accountLink && accountLink.purposeFlag === PurposeFlag.BOOKER) {
        fetchRequests()
      } else {
        setExistingRequests([])
      }
    } else if (!!exisitingRequests && data.existingRequestUUID) {
      const request = exisitingRequests.find((r) => r.uuid === data.existingRequestUUID)
      setSelectedExistingRequest(request)
    }
  }, [exisitingRequests, t, requestFeature, accountLink, setSelectedExistingRequest, data, state])

  const set = (prop: keyof NameAndLocationType) => (value: any) => setData({ ...data, [prop]: value })

  const setExistingRequest = (uuid: string) => {
    if (!!uuid) {
      const request = exisitingRequests.find((r) => r.uuid === uuid)
      if (!request.attendee) {
        if (state.additionalData.artist.pricing.primer.disabled) {
          request.sizeAndPrimer.primerRequired = Primer.NO
        }
      }
      setSelectedExistingRequest(request)
      setData({ ...data, existingRequestUUID: uuid })
    } else {
      setSelectedExistingRequest(null)
      setData({ ...data, existingRequestUUID: '' })
    }
  }

  const getData: GetData = () => {
    if ((!!data.name && !!data.location) || !!data.existingRequestUUID) {
      // in case of existing project, we need to set name and location from the selected project
      if (!!data.existingRequestUUID) {
        return {
          ...data,
          name: selectedExistingRequest.nameAndLocation.name,
          location: selectedExistingRequest.nameAndLocation.location,
        }
      }
      // otherwise just return the data
      return data
    } else {
      toast(TYPE.ERROR, t.request.create.nameAndLocation.location.error)
    }
  }

  return (
    <div>
      <Loading loading={exisitingRequests} />
      {exisitingRequests && (
        <NavigationForm
          pipelineStateProp={pipelineStateProp}
          getData={getData}
          finalStepSubmit={finalStepSubmit}
          componentMapping={componentMapping}
          prefilPipelineData={selectedExistingRequest}
        >
          {exisitingRequests.length > 0 && !!state.additionalData.artist && (
            <div className="mt-5">
              <SectionHeader
                headline={t.request.create.nameAndLocation.exisitingProject.title}
                description={t.request.create.nameAndLocation.exisitingProject.description}
              />
              <Dropdown
                className="mt-4"
                emptySelection={t.request.create.nameAndLocation.exisitingProject.emptySelection}
                onChange={setExistingRequest}
                value={data.existingRequestUUID}
                items={exisitingRequests.map((p) => ({ value: p.uuid, label: p.nameAndLocation.name }))}
              />
            </div>
          )}
          {!data.existingRequestUUID && (
            <>
              <SectionHeader headline={t.request.create.nameAndLocation.name.title} description={t.request.create.nameAndLocation.name.description} />
              <div className="mt-4 mb-10">
                <NewInput required value={data.name} onChange={set('name')} placeholder={t.request.create.nameAndLocation.name.placeholder} />
              </div>
              <SectionHeader small description={t.request.create.nameAndLocation.location.title} />
              <div className="mt-4">
                <LocationAutocomplete onChange={set('location')} label={t.generic.postcodeCity} initValue={data.location?.name} />
              </div>
            </>
          )}
        </NavigationForm>
      )}
    </div>
  )
}

export default NameAndLocation
