import { useSelector } from 'react-redux'
import { Pricing } from '../../../../../../types/Artist'
import { useState } from 'react'
import props from '../../../../../../redux/props'
import { Sizes } from '../../../../../../types/Size'
import SizeAndPrice from '../../../../../UI/Pricing/Editable/SizeAndPrice'
import { Price } from '../../../../../../types/Pricing'
import NewToggle from '../../../../../UI/NewToggle/NewToggle'

type Props = {
  initData?: Pricing['primer']
  update?: (value: any) => void
}

const Primer: React.FC<Props> = ({ initData, update }) => {
  const t = useSelector((state) => state[props.TRANSLATION])
  const [data, setData] = useState<Pricing['primer']>(
    initData || {
      [Sizes.S]: null,
      [Sizes.M]: null,
      [Sizes.L]: null,
      disabled: false,
    },
  )

  const set = (prop: Sizes | 'disabled') => (value: Price | boolean) => {
    setData({ ...data, [prop]: value })
    update({ ...data, [prop]: value })
  }

  return (
    <div>
      <div className="font-bold mb-5">{t.register.artist.pricing.primer.headline}</div>
      <div className="flex flex-col gap-4 mt-2">
        <SizeAndPrice
          disabled={data.disabled}
          size={Sizes.S}
          price={data[Sizes.S]}
          updatePrice={set(Sizes.S)}
          hiddenDisabled={!update && !data.disabled}
        />
        <SizeAndPrice
          disabled={data.disabled}
          size={Sizes.M}
          price={data[Sizes.M]}
          updatePrice={set(Sizes.M)}
          hiddenDisabled={!update && !data.disabled}
        />
        <SizeAndPrice
          disabled={data.disabled}
          size={Sizes.L}
          price={data[Sizes.L]}
          updatePrice={set(Sizes.L)}
          hiddenDisabled={!update && !data.disabled}
        />
      </div>
      {!!update && (
        <div className="flex justify-center md:justify-start items-center gap-2 mt-4">
          <NewToggle checked={data.disabled} onChange={set('disabled')} />
          <div className="text-sm text-gray-primary">{t.register.artist.pricing.primer.disableToggleLabel}</div>
        </div>
      )}
    </div>
  )
}

export default Primer
