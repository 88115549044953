import { ComponentMapping as ComponentMappingType } from '../../UI/Pipeline/types'
import AccountDetail from './AccountDetail/AccountDetail'
import BillingDetail from './BillingDetail/BillingDetail'

const ComponentMapping: ComponentMappingType = [
  {
    component: AccountDetail,
    pipelineStateProp: 'accountDetail',
  },
  {
    component: BillingDetail,
    pipelineStateProp: 'billingDetail',
  },
]

export default ComponentMapping
