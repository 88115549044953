import { useSelector } from 'react-redux'
import Currency from '../../NewInput/Currency'
import props from '../../../../redux/props'
import { Request } from '../../../../types/Request'
import { formatPrice } from '../../../../utility'
import _ from 'lodash'
import { Offer } from '../../../../types/Offer'
import Textarea from '../../Textarea/Textarea'

type Props = {
  request: Request
  offer: Offer
  set: (key: keyof Offer) => (value: any) => void
}

const AlternativeBudget: React.FC<Props> = ({ request, offer, set }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const hasAskingPrice =
    _.has(request, 'budgetExpectation') && !request.budgetExpectation.noAskingPrice && isFinite(request.budgetExpectation.askingPrice)

  if (!offer) return <></>
  return (
    <div>
      <div className="flex flex-col gap-2">
        {hasAskingPrice && (
          <div>
            {t.priceCalculator.alternativeBudget.currentBudget}
            <span className="font-bold">{formatPrice(request.budgetExpectation.askingPrice)}</span>
          </div>
        )}
        {!hasAskingPrice && <div>{t.priceCalculator.alternativeBudget.noBudget}</div>}
        <div className="flex flex-row gap-5">
          <div className="bg-blue-primary text-white h-8 flex justify-center items-center px-3">{t.priceCalculator.alternativeBudget.title}</div>
          <Currency value={offer.alternativeBudget} onChange={set('alternativeBudget')} className="!w-24" />
        </div>
        <Textarea
          value={offer.alternativeBudgetDescription}
          onChange={set('alternativeBudgetDescription')}
          placeholder={t.priceCalculator.alternativeBudget.textPlaceholder}
        />
      </div>
    </div>
  )
}

export default AlternativeBudget
