import { getDateInputFormat } from '../../../utility'

export interface BaseInput extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  id?: string
  type?: string
  onChange: (value: any) => void
  label?: string
  placeholder?: string
  className?: string
  contentClassName?: string
  required?: boolean
  Icon?: any
  value: any
  autoComplete?: string
  prefixText?: string
  suffixText?: string
  returnRawChangeEvent?: boolean
  disabled?: boolean
  hiddenDisabled?: boolean
  textAlign?: string
  useRef?: any
  maxLength?: number
}

const Input: React.FC<BaseInput> = ({
  id,
  type = 'text',
  value,
  label,
  placeholder,
  onChange,
  required = false,
  className = '',
  contentClassName = '',
  Icon,
  autoComplete = 'on',
  prefixText,
  suffixText,
  returnRawChangeEvent = false,
  disabled = false,
  hiddenDisabled = false,
  textAlign = null,
  useRef,
  maxLength,
}) => {
  const prepValue = () => {
    if (!value) return ''
    if (type === 'date') {
      if (typeof value === 'object') {
        return getDateInputFormat(value)
      } else if (value.includes('T')) {
        return getDateInputFormat(new Date(value))
      }
      return value
    } else {
      return value
    }
  }

  return (
    <div
      className={`form-control border overflow-hidden w-full bg-gray-100 flex-row items-center align-middle border-blue-primary bg-white rounded-sm ${className}`}
    >
      {Icon && (
        <div className="w-8 flex justify-end">
          <Icon className="h-5 text-black" />
        </div>
      )}
      {prefixText && <div className={`w-fit whitespace-nowrap px-1 text-blue-primary ${contentClassName}`}>{prefixText}</div>}
      <input
        id={id}
        ref={useRef}
        disabled={disabled || hiddenDisabled}
        required={required}
        type={type}
        value={prepValue()}
        onChange={(e) => onChange(returnRawChangeEvent ? e : e.target.value)}
        placeholder={placeholder || label}
        className={`input w-full h-7 rounded-none bg-white px-2 active:outline-none focus:outline-none text-md text-blue-primary ${contentClassName} ${
          !!textAlign ? ` text-${textAlign}` : ''
        } ${hiddenDisabled ? 'disabled:bg-white disabled:border-none disabled:text-opacity-100' : ''}`}
        autoComplete={autoComplete}
        maxLength={maxLength}
      />
      {suffixText && <div className={`w-fit whitespace-nowrap px-1 text-blue-primary ${contentClassName}`}>{suffixText}</div>}
    </div>
  )
}

export default Input
