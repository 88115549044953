import { Route, Routes } from 'react-router-dom'
import Layout from '../UI/Layout/Layout'
import Search from './Search/Search'
import ViewByUsername from './View/ViewByUsername'
import ViewByUUID from './View/ViewByUUID'

const Artist = () => {
  return (
    <Layout authLevelRequired={false}>
      <Routes>
        <Route path={'/view/:username'} element={<ViewByUsername />} />
        <Route path={'/view/uuid/:uuid'} element={<ViewByUUID />} />
        <Route path={'/:query'} element={<Search />} />
        <Route path={'*'} element={<Search />} />
      </Routes>
    </Layout>
  )
}

export default Artist
