import useTags from './useTags'
import useNotifications from './useNotifications'

type Props = {
  children: React.ReactNode
}

const Context: React.FC<Props> = ({ children }) => {
  const tags = useTags()
  useNotifications()

  if (!tags) return <></>
  return <>{children}</>
}

export default Context
