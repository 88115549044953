import styled from 'styled-components'

export const StyledWave = styled.div<{ reverse?: boolean }>`
  width: 100%;
  overflow: hidden;
  line-height: 0;
  ${({ reverse }) => reverse && `transform: rotate(180deg);`}
  transform: rotate(180deg);
  svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 67px;
    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      height: 25px;
    }
  }
  .shape-fill {
    fill: ${({ theme }) => theme.color.blue.primary};
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    svg {
      width: 200%;
    }
  }
`
