import { useEffect, useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import { Chat, Message } from '../../types/Chat'
import Routes from '../../redux/routes'

export type Result = { chat: Chat; message: Message }

type Props = (projectUUID: string) => Result

const useGetLastChatMessage: Props = (projectUUID) => {
  const [result, setResult] = useState<Result>()
  const getLastChatMessage = functional.use(Routes.GET_LAST_CHAT_MESSAGE)

  useEffect(() => {
    const get = async () => {
      const result: Result = await getLastChatMessage({ projectUUID })
      setResult(result)
    }
    if (!result) {
      get()
    }
  })

  return result
}

export default useGetLastChatMessage
