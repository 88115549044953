import GrayBox from '../../../../UI/Graybox/Graybox'
import { Project, VerifiedBy } from '../../../../../types/Project'
import { useEffect, useState } from 'react'
import { crud } from '@think-internet/zeus-frontend-package'
import props from '../../../../../redux/props'
import toast, { TYPE } from '../../../../../toast'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CalendarBig from '../../../../UI/Calendar/Big/Big'
import Icon from '../../../../UI/ProfileIcon/Icon'
import LocationLabel from '../../../../UI/LocationLabel/LocationLabel'
import useGetBooker from '../../../../hooks/useGetBooker'

const Calendar: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [booker] = useGetBooker()
  const [projects, setProjects] = useState<Project[]>()
  const projectFeature = crud.use(props.PROJECT)
  const navigate = useNavigate()

  useEffect(() => {
    const list = async () => {
      const projects = await projectFeature.list({ bookerUUID: booker.uuid, 'verification.verifiedBy': VerifiedBy.QSELLART })
      if (Array.isArray(projects)) {
        setProjects(projects)
      } else {
        toast(TYPE.ERROR, t.booker.dashboard.calendar.error.loadingProjects)
      }
    }
    if (booker && !projects) {
      list()
    }
  }, [booker, projects, t, projectFeature])

  const getEvents = () => {
    const colorPairs = [
      { backgroundColor: '#00dece', textColor: 'white' },
      { backgroundColor: '#63edac', textColor: 'white' },
      { backgroundColor: '#b0f689', textColor: 'white' },
      { backgroundColor: '#00cce4', textColor: 'white' },
    ]

    if (projects) {
      return projects.map((p, i) => {
        const randomColorPair = colorPairs[i % 4]
        return {
          id: p.uuid,
          start: p.createdOn,
          end: p.deadline.date,
          allDay: true,
          backgroundColor: randomColorPair.backgroundColor,
          textColor: randomColorPair.textColor,
        }
      })
    }
  }

  const viewProject = (info: any) => navigate(`/home/booker/project/view/${info.event.id}`)

  const renderEvent = (eventInfo: any) => {
    const project = projects.find((p) => p.uuid === eventInfo.event.id)

    return (
      <div className={'flex flex-row items-center items cursor-pointer w-full basis-full py-0 px-0 gap-2 shadow-calendar-entry'}>
        {Array.isArray(project.design.urls) && project.design.urls.length > 0 && (
          <Icon className="!h-5 md:!h-5 border-transparent" image={project.design.urls[0]} />
        )}
        <div className="overflow-hidden flex flex-row gap-2">
          {/* <div className={'text-xs truncate font-bol text-white'}>{project.nameAndLocation.name}</div> */}
          <div className={'text-xs truncate'}>{`${project.booker.billingDetail.firstname} ${project.booker.billingDetail.lastname}`}</div>
          <LocationLabel className="hidden md:flex" invert location={project.nameAndLocation.location} />
        </div>
      </div>
    )
  }

  return (
    <GrayBox className="w-full" title={t.booker.dashboard.calendar.title}>
      <div className="bg-white p-1">
        <CalendarBig events={getEvents()} eventRenderer={renderEvent} onEventClick={viewProject} />
      </div>
    </GrayBox>
  )
}

export default Calendar
