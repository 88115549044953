import NameAndLocation from '../StepComponents/NameAndLocation/NameAndLocation'
import Tags from '../StepComponents/Tags/Tags'
import SizeAndPrimer from '../StepComponents/SizeAndPrimer/SizeAndPrimer'
import Insurance from '../StepComponents/Insurance/Insurance'
import Deadline from '../StepComponents/Deadline/Deadline'
import DesignText from '../StepComponents/DesignText/DesignText'
import Design from '../StepComponents/Design/Design'
import { ComponentMapping as ComponentMappingType } from '../../../UI/Pipeline/types'
import Summary from './Summary/Summary'
import { Artist } from '../../../../types/Artist'

const ComponentMapping = (artist: Artist): ComponentMappingType => {
  const componentMapping: ComponentMappingType = [
    {
      component: NameAndLocation,
      pipelineStateProp: 'nameAndLocation',
    },
    {
      component: Tags,
      pipelineStateProp: 'tags',
    },
    {
      component: SizeAndPrimer,
      pipelineStateProp: 'sizeAndPrimer',
    },
    {
      component: Deadline,
      pipelineStateProp: 'deadline',
    },
    {
      component: DesignText,
      pipelineStateProp: 'design',
    },
    {
      component: Design,
      pipelineStateProp: 'design',
    },
    {
      component: Summary,
      pipelineStateProp: 'summary',
    },
  ]
  if (artist.insuranceDetail.enabled) {
    componentMapping.splice(3, 0, {
      component: Insurance,
      pipelineStateProp: 'insurance',
    })
  }
  return componentMapping
}

export default ComponentMapping
