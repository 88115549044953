import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { PipelineState, PipelineStateProp } from '../../../../UI/Pipeline/types'
import DefaultCalculator from './DefaultCalculator'
import WorkshopCalculator from './WorkshopCalculator'
import { ArtistListItem, Request } from '../../../../../types/Request'

type Props = {
  pipelineStateProp?: PipelineStateProp
  currentState?: {
    [key: string]: any
  }
}

const ArtistPriceCalculator: React.FC<Props> = ({ pipelineStateProp, currentState }) => {
  const state: PipelineState<Request, { artist: ArtistListItem }> = useSelector((s) => s[props.PIPELINE])

  const getRequest = (): Request => {
    if (pipelineStateProp && currentState) {
      return { ...state.data, [pipelineStateProp]: currentState }
    }
    return { ...state.data }
  }

  const isWorkshop = (): boolean => {
    const workingState = getRequest()
    return isFinite(workingState.attendee?.amount)
  }

  if (!state.additionalData.artist) return <></>
  if (isWorkshop()) return <WorkshopCalculator artist={state.additionalData.artist} request={getRequest()} />
  return <DefaultCalculator artist={state.additionalData.artist} request={getRequest()} />
}

export default ArtistPriceCalculator
