import { useSelector } from 'react-redux'
import DataSection from '../../../Components/DataSection/DataSection'
import { DataSectionComponent } from '../../types'
import props from '../../../../../redux/props'
import { useEffect, useState } from 'react'
import { Skills as SkillsType } from '../../../../../types/Artist'
import { crud } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../../../../toast'
import SelectableTagList from '../../../../UI/SelectableTagList/SelectableTagList'
import { Category, Tag } from '../../../../../types/Tag'
import NewButton from '../../../../UI/NewButton/NewButton'
import { getTagByEnum } from '../../../../../utility'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: fit-content;
`

const Skills: DataSectionComponent = ({ artist, onChangeCallback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [skills, setSkills] = useState<SkillsType>(artist.skills)
  const artistFeature = crud.use(props.ARTIST)
  const allTags = useSelector((s) => s[props.TAG])
  const [availableSkills, setAvailableSkills] = useState(null)

  useEffect(() => {
    if (!availableSkills && Array.isArray(allTags)) {
      setAvailableSkills(allTags.filter((tag: Tag) => tag.category !== Category.EQUIPMENT && tag.category !== Category.SURFACE))
    }
  }, [allTags, availableSkills])

  const Default = (
    <div>
      <div className="text-sm">{t.artist.profile.skills.hint}</div>
      <div className="flex flex-row gap-x-[16px] mt-3">
        {skills.tagENUMList.map((tagEnum: string) => (
          <Wrapper key={tagEnum}>
            <NewButton className={`!h-6 btn-primary w-fit`} text={getTagByEnum(tagEnum, allTags)?.name} />
          </Wrapper>
        ))}
      </div>
    </div>
  )

  const set = (value: any) => setSkills({ ...skills, tagENUMList: value })

  const save = async () => {
    if (skills.tagENUMList.length === 3) {
      const response = await artistFeature.update({ uuid: artist.uuid, skills })
      if (!response) {
        toast(TYPE.ERROR, t.artist.profile.skills.edit.error)
      } else {
        if (onChangeCallback) {
          onChangeCallback(skills)
        }
      }
      return !!response
    } else {
      toast(TYPE.ERROR, t.artist.profile.skills.edit.errorAmount)
    }
    return false
  }

  return (
    <DataSection className="md:w-2/3" title={t.artist.profile.skills.title} defaultView={Default} saveCallback={save}>
      <SelectableTagList maxVisibleRows={3} selectedTagENUMList={skills.tagENUMList} tags={availableSkills} maxSelectable={3} onChange={set} />
    </DataSection>
  )
}

export default Skills
