import { useEffect, useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../redux/routes'
import { Rating } from '../../types/Rating'

type Props = (uuid: string) => Rating[] | undefined

const useListArtistRatings: Props = (uuid) => {
  const [ratings, setRatings] = useState<Rating[]>()
  const listRating = functional.use(Routes.LIST_ARTIST_RATING)

  useEffect(() => {
    const list = async () => {
      if (!!uuid) {
        const ratings = await listRating({ artistUUID: uuid })
        setRatings(ratings)
      }
    }
    if (!ratings) {
      list()
    }
  })

  return ratings
}

export default useListArtistRatings
