import { useSelector } from 'react-redux'
import DataSection from '../../../Components/DataSection/DataSection'
import { DataSectionComponent } from '../../types'
import props from '../../../../../redux/props'
import { useState } from 'react'
import { crud } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../../../../toast'
import { ProfileImages as ProfileImagesType } from '../../../../../types/Booker'
import ProfileIcon from '../../../../UI/ProfileIcon/ProfileIcon'
import DirectFileUpload, { OnCallbackFile, UPLOAD_TYPES } from '../../../../UI/DirectFileUpload/DirectFileUpload'
import { ArrowUpTrayIcon } from '@heroicons/react/24/solid'

const ProfileImages: DataSectionComponent = ({ booker, onChangeCallback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [profileImages, setProfileImages] = useState<ProfileImagesType>(booker.profileImages)
  const bookerFeature = crud.use(props.BOOKER)

  const Default = (
    <div>
      <div className="text-black-secondary text-sm">{t.booker.settings.profileImages.hint}</div>
      <div className="flex justify-center mt-4">
        <ProfileIcon className="!h-24" />
      </div>
    </div>
  )

  const set = (prop: keyof ProfileImagesType) => (value: OnCallbackFile) => {
    if (prop === 'personImageKey') {
      setProfileImages({ ...profileImages, [prop]: value.key, personImageURL: value.url })
    }
  }

  const save = async () => {
    const response = await bookerFeature.update({ uuid: booker.uuid, profileImages })
    if (!response) {
      toast(TYPE.ERROR, t.booker.settings.profileImages.edit.error)
    } else {
      if (onChangeCallback) {
        onChangeCallback(profileImages)
      }
    }
    return !!response
  }

  return (
    <DataSection className="md:w-2/4" title={t.booker.settings.profileImages.title} defaultView={Default} saveCallback={save}>
      <div className="flex justify-center">
        <div className="h-24 w-24 rounded-full overflow-hidden relative">
          {profileImages?.personImageURL && (
            <div className={`h-full w-full aspect-square rounded-full overflow-hidden`}>
              <img src={profileImages.personImageURL} alt="" className="h-full w-full object-cover" />
            </div>
          )}
          <div className="absolute top-0 left-0 w-full h-full transition-all items-center justify-center bg-black-secondary bg-opacity-25">
            <DirectFileUpload uploadType={UPLOAD_TYPES.PROFILE_PERSON_IMAGE} acceptedMimeTypes={['image/*']} onFilesCallback={set('personImageKey')}>
              <ArrowUpTrayIcon className="w-10 text-white hover:scale-105 transition-all" />
            </DirectFileUpload>
          </div>
        </div>
      </div>
    </DataSection>
  )
}

export default ProfileImages
