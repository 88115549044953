import { useNavigate, useParams } from 'react-router-dom'
import toast, { TYPE } from '../../../toast'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Routes from '../../../redux/routes'
import { functional } from '@think-internet/zeus-frontend-package'
import SmallContainer from '../../UI/Container/SmallContainer'
import Countdown from '../../UI/Countdown/Countdown'
import Loading from '../../UI/Loading/Loading'

const Confirm = () => {
  const { linkUUID } = useParams()
  const navigate = useNavigate()
  const t = useSelector((s) => s[props.TRANSLATION])
  const [loading, setLoading] = useState(true)
  const [confirmed, setConfirmed] = useState(false)
  const confirm = functional.use(Routes.CONFIRM_REGISTRATION)

  useEffect(() => {
    const submit = async () => {
      const status = await confirm({ linkUUID })
      if (status) {
        setConfirmed(true)
        setLoading(false)
      } else {
        toast(TYPE.ERROR, t.register.confirm.error)
        setLoading(false)
      }
    }

    submit()
  }, [confirm, linkUUID, navigate, t])

  return (
    <SmallContainer>
      <div className="pt-5 text-center">
        <h2 className="text-xl text-gray-primary font-bold">{confirmed ? t.register.confirm.confirmed : t.register.confirm.headline}</h2>
      </div>
      <Loading loading={!loading} />
      {!loading && (
        <>
          {!confirmed && <p className="mt-5 text-center text-sm">{t.register.confirm.error}</p>}
          {confirmed && (
            <p className="mt-5 text-center">
              {t.register.confirm.redirectCountdownPrefix}
              <Countdown start={5} doneCallback={() => navigate('/login')} />
              {t.register.confirm.redirectCountdownSuffix}
            </p>
          )}
        </>
      )}
    </SmallContainer>
  )
}

export default Confirm
