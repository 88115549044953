import { useDispatch, useSelector } from 'react-redux'
import { PipelineState } from '../types'
import props from '../../../../redux/props'
import { setLocal } from '../../../../redux/action/local'

const useGoPrevious = <D>() => {
  const state: PipelineState<D> = useSelector((s) => s[props.PIPELINE])
  const dispatch = useDispatch()

  const isPreviousEnabled = () => {
    return state.currentIndex > 0
  }

  const previous = () => {
    dispatch(setLocal(props.PIPELINE, { ...state, currentIndex: state.currentIndex - 1 }))
    document.body.scrollTop = 0
  }

  return isPreviousEnabled() ? previous : null
}

export default useGoPrevious
