import { useSelector } from 'react-redux'
import DataSection from '../../../Components/DataSection/DataSection'
import { DataSectionComponent } from '../../types'
import props from '../../../../../redux/props'
import { useState } from 'react'
import { Portfolio as PortfolioType } from '../../../../../types/Artist'
import { crud } from '@think-internet/zeus-frontend-package'
import toast, { TYPE } from '../../../../../toast'
import DirectFileUpload, { OnCallbackFile, UPLOAD_TYPES } from '../../../../UI/DirectFileUpload/DirectFileUpload'
import upload from '../../../../../assets/image/generic/upload.png'
import Carousel from '../../../../UI/Carousel/Carousel'
import { TrashIcon } from '@heroicons/react/24/solid'

const Portfolio: DataSectionComponent = ({ artist, onChangeCallback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [portfolio, setPortfolio] = useState<PortfolioType>(artist.portfolio)
  const artistFeature = crud.use(props.ARTIST)

  const onImage = (files: OnCallbackFile[]) => {
    const newKeys = Array.isArray(portfolio.keys) ? [...files.map((f) => f.key), ...portfolio.keys] : files.map((f) => f.key)
    const newURLs = Array.isArray(portfolio.urls) ? [...files.map((f) => f.url), ...portfolio.urls] : files.map((f) => f.url)
    setPortfolio({ ...portfolio, keys: newKeys, urls: newURLs })
  }

  console.log(portfolio)

  const hasImages = Array.isArray(portfolio?.urls) && portfolio.urls.length > 0

  const Default = (
    <div>
      {!hasImages && t.artist.profile.porfolio.hintEmpty.map((t) => <div className="text-sm">{t}</div>)}
      {hasImages && <div className="text-sm">{t.artist.profile.porfolio.hint}</div>}
      {Array.isArray(portfolio?.urls) && portfolio.urls.length > 0 && (
        <Carousel smallArrows itemsToShow={4} className="mt-5" images={portfolio.urls} />
      )}
    </div>
  )

  const save = async () => {
    const response = await artistFeature.update({ uuid: artist.uuid, portfolio })
    if (!response) {
      toast(TYPE.ERROR, t.artist.profile.story.edit.errorGeneric)
    } else {
      if (onChangeCallback) {
        onChangeCallback({ ...portfolio, urls: undefined })
      }
    }
    return !!response
  }

  const remove = (index: number) => {
    const newKeys = portfolio.keys.filter((key, i) => i !== index)
    const newURLs = portfolio.urls.filter((url, i) => i !== index)
    setPortfolio({ ...portfolio, keys: newKeys, urls: newURLs })
  }

  return (
    <DataSection className="w-full" title={t.artist.profile.porfolio.title} defaultView={Default} saveCallback={save}>
      {!hasImages && t.artist.profile.porfolio.hintEmpty.map((t) => <div className="text-sm">{t}</div>)}
      {hasImages && <div className="text-sm">{t.artist.profile.porfolio.hint}</div>}
      <div className="border-blue-primary border bg-white h-28 w-full flex items-center justify-center rounded-sm my-2">
        <DirectFileUpload
          uploadType={UPLOAD_TYPES.PROFILE_PORTFOLIO_IMAGE}
          acceptedMimeTypes={['image/*']}
          onFilesCallback={onImage}
          multiple
          radialProgressBar
        >
          <img src={upload} className="h-7 hover:scale-105 transition-all" alt={t.generic.upload} />
        </DirectFileUpload>
      </div>
      {hasImages && (
        <Carousel
          smallArrows
          itemsToShow={4}
          className="mt-5"
          items={portfolio.urls.map((url: string, i: number) => (
            <div
              key={i}
              className="relative aspect-video w-full items-center justify-center bg-center bg-cover bg-no-repeat rounded-md cursor-pointer border border-gray-secondary"
              style={{ backgroundImage: `url(${url})` }}
            >
              <div
                className="backdrop-blur-sm rounded-sm border py-1 px-2 border-white cursor-pointer group w-fit absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                onClick={() => remove(i)}
              >
                <TrashIcon className="h-5 fill-white group-hover:fill-magenta-primary" />
              </div>
              {/* <div className="hidden md:block absolute opacity-0 hover:bg-white hover:bg-opacity-25 hover:opacity-100 hover:flex h-full w-full cursor-pointer items-center justify-center">
                <TrashIcon className="w-8 text-white hover:scale-105 transition-all" onClick={() => remove(i)} />
              </div> */}
            </div>
          ))}
        />
      )}
    </DataSection>
  )
}

export default Portfolio
