import { useParams } from 'react-router-dom'
import { useState } from 'react'
import Contacts from './Contacts/Contacts'
import Messages from './Messages/Messages'
import Milestones from './Milestones/Milestones'
import { Chat as ChatType } from '../../../../types/Chat'
import Container from '../../../UI/Container/Container'
import styled from 'styled-components'
import useIsBreakpoint, { Breakpoint } from '../../../hooks/useIsBreakpoint'

export const FullHeightBody = styled.div<{ reduceHeightBy?: number }>`
  height: calc(100vh - ${({ theme }) => theme.headerHeight} - ${({ reduceHeightBy }) => (reduceHeightBy ? `${reduceHeightBy}px` : '0px')});
  overflow-y: auto;
  overflow-x: hidden;
`

const Chat: React.FC<{}> = () => {
  const { uuid } = useParams<{ uuid: string }>()
  const [chat, setChat] = useState<ChatType | false>()
  const isBreakpoint = useIsBreakpoint(Breakpoint.SM)

  const removeChatSelection = () => {
    setChat(false)
  }

  return (
    <Container innerClassName="!px-1 sm:px-6">
      <div className="flex flex-row">
        {!isBreakpoint && (
          <div className="w-3/12 hidden md:block">
            <Contacts initProjectUUID={uuid} chat={chat} setChat={setChat} />
          </div>
        )}
        {!chat && isBreakpoint && (
          <div className="w-full md:hidden">
            <Contacts className="md:hidden" initProjectUUID={uuid} chat={chat} setChat={setChat} openChatAutomatically={false} />
          </div>
        )}
        <div className="w-full md:w-6/12">
          <Messages chat={chat} removeChatSelection={removeChatSelection} />
        </div>
        <div className="w-3/12 hidden md:block">
          <Milestones chat={chat} />
        </div>
      </div>
    </Container>
  )
}

export default Chat
