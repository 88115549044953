import { useSelector } from 'react-redux'
import Art from './Art'
import Primer from './Primer'
import Concept from './Concept'
import Workshop from './Workshop'
import props from '../../../../../../redux/props'
import { Pricing as PricingType } from '../../../../../../types/Artist'
import { DataSectionComponent } from '../../../types'
import toast, { TYPE } from '../../../../../../toast'
import { useState } from 'react'
import DataSection from '../../../../Components/DataSection/DataSection'
import { crud } from '@think-internet/zeus-frontend-package'
// import Section from '../../../../../UI/NewAccordion/Section/Section'
import validators from '../../../../../Register/Artist/Pricing/validator'
import Toggle from '../../../../../UI/NewToggle/NewToggle'

const Pricing: DataSectionComponent = ({ artist, onChangeCallback }) => {
  const t = useSelector((state) => state[props.TRANSLATION])
  const [pricing, setPricing] = useState<PricingType>(artist.pricing)
  const artistFeature = crud.use(props.ARTIST)

  const Default = (
    <div className="flex flex-col md:flex-row flex-1 gap-10">
      <div className="flex flex-col gap-7 md:w-1/2">
        <Art initData={pricing.art} />
        <Concept initData={pricing.concept} />
      </div>
      <div className="md:w-1/2">
        <div className="flex flex-col gap-7">
          <Primer initData={pricing.primer} />
          <Workshop initData={pricing.workshop} />
        </div>
      </div>
    </div>
  )

  const set = (prop: keyof PricingType) => (value: any) => setPricing({ ...pricing, [prop]: value })

  const save = async () => {
    if (validators.art(pricing['art'])) {
      if (validators.primer(pricing['primer'])) {
        if (validators.concept(pricing['concept'])) {
          if (validators.workshop(pricing['workshop'])) {
            const response = await artistFeature.update({ uuid: artist.uuid, pricing })
            if (!response) {
              toast(TYPE.ERROR, t.artist.profile.downPayment.edit.errorGeneric)
            } else {
              if (onChangeCallback) {
                onChangeCallback(pricing)
              }
            }
            return !!response
          } else {
            toast(TYPE.ERROR, t.register.artist.pricing.workshop.error)
          }
        } else {
          toast(TYPE.ERROR, t.register.artist.pricing.concept.error)
        }
      } else {
        toast(TYPE.ERROR, t.register.artist.pricing.primer.error)
      }
    } else {
      toast(TYPE.ERROR, t.register.artist.pricing.art.error)
    }
    return false
  }

  return (
    <DataSection className="w-full" defaultView={Default} saveCallback={save}>
      <div className="flex flex-col md:flex-row flex-1 gap-10">
        <div className="flex flex-col gap-7 md:w-1/2">
          <Art initData={pricing.art} update={set('art')} />
          <div className="flex flex-col gap-4">
            <Concept initData={pricing.concept} update={set('concept')} />
            <div className="flex justify-center md:justify-start items-center gap-2 mb:mt-2">
              <Toggle checked={pricing.hidden} onChange={set('hidden')} />
              <div className="text-sm text-gray-primary">{t.artist.profile.pricing.hideToggle}</div>
            </div>
          </div>
        </div>
        <div className="md:w-1/2">
          <div className="flex flex-col gap-7">
            <Primer initData={pricing.primer} update={set('primer')} />
            <Workshop initData={pricing.workshop} update={set('workshop')} />
          </div>
        </div>
      </div>
      {/* <div className="text-xs bg-gray-thirdary rounded-sm p-1">{t.register.artist.pricing.priceReductionHint}</div> */}
    </DataSection>
  )
}

export default Pricing
