import { useSelector } from 'react-redux'
import Currency from '../../NewInput/Currency'
import props from '../../../../redux/props'
import { useEffect } from 'react'
import Textarea from '../../Textarea/Textarea'
import { PricingAdditional } from '../../../../types/Project'

type Props = {
  value: PricingAdditional
  onChange: (data: PricingAdditional) => void
  isReverseOffer: boolean
}

const AdditionalCosts: React.FC<Props> = ({ value, onChange, isReverseOffer }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  useEffect(() => {
    if (!value) {
      onChange({
        price: 0,
        description: '',
      })
    }
  }, [value, onChange])

  const set = (prop: string) => (updatedValue: number | string) => onChange({ ...value, [prop]: updatedValue })

  if (!value) return <></>
  return (
    <div>
      <div className="flex items-center gap-2">
        {isReverseOffer && <div className="text-sm text-gray-primary">{t.priceCalculator.additionalCosts.titleReverseOffer}</div>}
        {!isReverseOffer && <div className="text-sm text-gray-primary">{t.priceCalculator.additionalCosts.title}</div>}
      </div>
      <div className="flex flex-col gap-2 mt-5">
        <Textarea value={value.description} onChange={set('description')} placeholder={t.priceCalculator.additionalCosts.descriptionPlaceholder} />
        <div className="font-bold text-gray-primary mt-5">{t.priceCalculator.additionalCosts.priceLabel}</div>
        <div className="flex flex-row gap-5">
          <div className="bg-blue-primary text-white h-8 flex justify-center items-center px-3">{t.priceCalculator.additionalCosts.label}</div>
          <Currency value={value.price || 0} onChange={set('price')} className="!w-24" />
        </div>
      </div>
    </div>
  )
}

export default AdditionalCosts
