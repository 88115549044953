import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { ArtistListItem, Request } from '../../../../../types/Request'
import { formatPrice, getUUID } from '../../../../../utility'
import SectionHeader from '../../../../UI/Pipeline/SectionHeader'
import Property from '../../../../UI/PropertyList/Property'
import PriceCalculator, { PriceCalculatorType } from '../../../../UI/PriceCalculator/PriceCalculator'
import { PipelineState } from '../../../../UI/Pipeline/types'

type Props = {
  state: PipelineState<Request, { artist: ArtistListItem }>
}

const Pricing: React.FC<Props> = ({ state }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const isArtistRequest = !!state.additionalData.artist

  if (isArtistRequest) {
    return (
      <>
        <SectionHeader headline={t.request.create.summary.default.price.ofArtist.concept.title} />
        <div className="mt-6 text-gray-primary">{t.request.create.summary.default.price.ofArtist.concept.description}</div>
        <div className="my-3 rounded-sm border border-gray-primary py-3 px-5">
          <div className="text-gray-primary">{`${t.request.create.summary.default.price.ofArtist.concept.additionalConcept.prefix}${state.additionalData.artist.personalDetail.username}${t.request.create.summary.default.price.ofArtist.concept.additionalConcept.suffix}`}</div>
          <div className="text-gray-primary">{formatPrice(state.additionalData.artist.pricing.concept.price)}</div>
        </div>
        <div className="bg-gray-thirdary py-3 px-5 mt-3">
          <div className="text-sm">{t.request.create.summary.default.price.ofArtist.concept.estimation.title}</div>
          <div className="text-xs mt-2 text-black-secondary">{t.request.create.summary.default.price.ofArtist.concept.estimation.description}</div>
        </div>
        <SectionHeader headline={t.request.create.summary.default.price.ofArtist.overview.title} />
        <div className="rounded-sm border border-gray-primary py-3 px-5 mt-6">
          <PriceCalculator type={PriceCalculatorType.REQUEST} artist={state.additionalData.artist} request={state.data} />
        </div>
        <div className="bg-gray-thirdary py-3 px-5 mt-3">
          <div className="text-sm">{t.request.create.summary.default.price.ofArtist.payment.title}</div>
          {t.request.create.summary.default.price.ofArtist.payment.text.map((t: string) => (
            <div className="text-xs mt-2 text-black-secondary" key={getUUID()}>
              {t}
            </div>
          ))}
        </div>
        <div className="bg-blue-light py-3 px-5 mt-3">
          <div className="text-sm">{t.request.create.summary.default.price.ofArtist.cancellation.title}</div>
          <div className="text-xs mt-2 text-black-secondary">{t.request.create.summary.default.price.ofArtist.cancellation.directText}</div>
        </div>
        <div className="p-3 mt-3">
          <div className="text-sm">{t.request.create.summary.widerrufsrecht.title}</div>
          {t.request.create.summary.widerrufsrecht.text.map((t: string) => (
            <div className="text-xs mt-2 text-black-secondary" key={getUUID()}>
              {t}
            </div>
          ))}
        </div>
      </>
    )
  } else {
    return (
      <>
        <SectionHeader headline={t.request.create.summary.default.price.ofArtist.concept.title} />
        <div className="mt-6 text-gray-primary">{t.request.create.summary.default.price.ofArtist.concept.description}</div>
        <div className="bg-gray-thirdary py-3 px-5 mt-3">
          <div className="text-sm">{t.request.create.summary.default.price.ofArtist.concept.estimation.title}</div>
          <div className="text-xs mt-2 text-black-secondary">{t.request.create.summary.default.price.ofArtist.concept.estimation.description}</div>
        </div>
        <SectionHeader headline={t.request.create.summary.default.price.expectation.title} />
        <Property
          property={{
            name: t.request.create.summary.default.price.expectation.total,
            value:
              state.data.budgetExpectation.noAskingPrice === true
                ? t.request.create.summary.default.price.expectation.noAskingPrice
                : formatPrice(state.data.budgetExpectation.askingPrice),
          }}
        />
        <div className="bg-gray-thirdary py-3 px-5 mt-10">
          <div className="text-sm">{t.request.create.summary.default.price.payment.title}</div>
          {t.request.create.summary.default.price.payment.text.map((t: string) => (
            <div className="text-xs mt-2 text-black-secondary" key={getUUID()}>
              {t}
            </div>
          ))}
        </div>
        <div className="bg-blue-light py-3 px-5 mt-3">
          <div className="text-sm">{t.request.create.summary.default.price.cancellation.title}</div>
          <div className="text-xs mt-2 text-black-secondary">{t.request.create.summary.default.price.cancellation.text}</div>
        </div>
        <div className="p-3 mt-3">
          <div className="text-sm">{t.request.create.summary.widerrufsrecht.title}</div>
          {t.request.create.summary.widerrufsrecht.text.map((t: string) => (
            <div className="text-xs mt-2 text-black-secondary" key={getUUID()}>
              {t}
            </div>
          ))}
        </div>
      </>
    )
  }
}

export default Pricing
