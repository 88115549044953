import threeImage from '../../../assets/image/home/enumeration/three-sample.png'
import three from '../../../assets/image/home/enumeration/three.png'
import secureIcon from '../../../assets/image/home/functionality/secure.png'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Container from '../../UI/Container/Container'
import Button from '../../UI/NewButton/NewButton'
import { PurposeFlag } from '../../../types/Auth'

const First = () => {
  const t = useSelector((state) => state[props.TRANSLATION])
  const accountLink = useSelector((state) => state[props.ACCOUNT_LINK])

  const createRequestLink = () => {
    const isBooker = !!accountLink && accountLink.purposeFlag === PurposeFlag.BOOKER
    return isBooker || !accountLink ? '/request/create' : '/request'
  }

  return (
    <div className="bg-blue-primary relative">
      <Container className="relative">
        <div className="flex flex-col md:flex-row py-10 gap-10 md:gap-5 items-center">
          <div className="shrink-0">
            <img src={three} alt="three" className="h-28 md:h-56" />
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex flex-col md:flex-row-reverse gap-5 md:gap-10 items-center md:items-start">
              <div className="flex items-center justify-center flex-col gap-3 shrink-0">
                <img className="h-16 md:h-28" src={secureIcon} alt="" />
                <Button className="btn-secondary !outline w-fit" href={createRequestLink()}>
                  {t.home.functionality.step3.cta}
                </Button>
              </div>
              <div className="flex flex-col gap-3">
                <div className="text-center text-white md:text-right font-bold text-lg mt-3">{t.home.functionality.step3.headline}</div>
                <div className="text-center text-white md:text-right">{t.home.functionality.step3.text}</div>
              </div>
            </div>
          </div>
          <div className="hidden md:flex shrink-0 ml-10 w-64 items-center justify-center">
            <img src={threeImage} alt="three" className="h-[110%] -top-[5%] aspect-auto absolute right-0" />
          </div>
          <div className="bg-white absolute h-full w-full left-full top-0 z-0"></div>
        </div>
      </Container>
    </div>
  )
}

export default First
