import styled from 'styled-components'

const Wrapper = styled.div<{ maxVisibleItemsPerRow?: number; disableFixedWidth?: boolean }>`
  ${({ disableFixedWidth, maxVisibleItemsPerRow, theme }) => {
    return (
      !disableFixedWidth &&
      `
  width: calc(100% / ${maxVisibleItemsPerRow || 4} - (${maxVisibleItemsPerRow ? maxVisibleItemsPerRow - 1 : 3} * 16px / ${
        maxVisibleItemsPerRow || 4
      }));
  @media (max-width: ${theme.breakpoint.md}) {
    width: calc(100% / 2 - (1 * 16px / 2));
  }
    `
    )
  }}
`

export default Wrapper
