import { useEffect, useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../redux/routes'

type Props = (uuid: string) => boolean

const useGetRating: Props = (uuid) => {
  const [rating, setRating] = useState<boolean>()
  const getRating = functional.use(Routes.GET_PROJECT_RATING)

  useEffect(() => {
    const get = async () => {
      if (!!uuid) {
        const rating = await getRating({ uuid })
        setRating(!!rating)
      }
    }
    if (typeof rating === 'undefined') {
      get()
    }
  })

  return rating
}

export default useGetRating
