import { Route, Routes } from 'react-router-dom'
import Layout from '../UI/Layout/Layout'
import ExternalVerification from './ExternalVerification/ExternalVerification'
import View from './View/View'
import Search from './Search/Search'

const Project = () => {
  return (
    <Layout authLevelRequired={false}>
      <Routes>
        <Route path={'/view/:uuid'} element={<View />} />
        <Route path={'/external-verification/:projectUUID'} element={<ExternalVerification />} />
        <Route path={'*'} element={<Search />} />
      </Routes>
    </Layout>
  )
}

export default Project
