import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Carousel from '../../UI/Carousel/Carousel'
import Container from '../../UI/Container/Container'
import { Rating } from '../../../types/Rating'
import { useEffect, useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import styled from 'styled-components'
import Icon from '../../UI/ProfileIcon/Icon'
import RatingStars from '../../UI/RatingStars/RatingStars'
import DaysSince from '../../UI/DaysSince/DaysSince'

export const Headline = styled.div`
  width: fit-content;
  padding: 15px 40px;
  color: ${({ theme }) => theme.color.blue.primary};
  font-size: ${({ theme }) => theme.font.size.xlg};
  /* color: ${({ theme }) => theme.color.font.secondary}; */
  font-family: 'thin';
  margin-bottom: 34px;
`

const Reviews = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const [ratings, setRatings] = useState<Rating[]>()
  const getSuggestedRatings = functional.use(Routes.GET_SUGGESTED_RATINGS)

  const getBookerName = (rating: Rating) => {
    const company = rating?.booker?.billingDetail?.companyName
    if (!!company) return company
    return `${rating?.booker?.billingDetail?.firstname || ''} ${rating?.booker?.billingDetail?.lastname[0 || '']}.`
  }

  useEffect(() => {
    const getRatings = async () => {
      const ratings = await getSuggestedRatings()
      if (Array.isArray(ratings)) {
        setRatings(ratings)
      }
    }
    if (!ratings) {
      getRatings()
    }
  }, [ratings, getSuggestedRatings])

  const getImage = (rating: Rating) => {
    if (rating?.project?.design?.urls?.length > 0) {
      return rating.project.design.urls[0]
    }
  }

  if (!ratings || ratings.length === 0) return <></>
  return (
    <div className="mt-20">
      <Container>
        <Headline>{translation.home.reviews.headline}</Headline>
        <div>
          <div className="border-t-[0.1px] border-gray-secondary text-center text-gray-primary mx-10" />
          <Carousel
            className="py-10"
            items={ratings.map((rating) => (
              <div key={rating.uuid} className="px-4">
                <div className="flex flex-row gap-2 items-center">
                  <div>
                    <Icon booker={rating.booker} image={rating.booker?.profileImages?.personImageURL} className="!h-12" />
                  </div>
                  <div className="flex flex-col gap-0">
                    <div className="text-sm">{getBookerName(rating)}</div>
                    <div className="flex flex-row gap-1 items-center">
                      <RatingStars rating={rating.publicScore} />
                      <div className="text-xs text-yellow-primary">{rating.publicScore}</div>
                      <DaysSince date={new Date(rating.createdOn)} className="ml-3" />
                    </div>
                  </div>
                </div>
                <div className="leading-4 text-sm text-gray-primary mx-auto w-10/12 h-20 overflow-hidden mt-3">{rating.text}</div>
                {!!getImage(rating) && (
                  <div className="mx-auto w-10/12 mt-3">
                    <img src={getImage(rating)} alt="" className="max-h-20 max-w-full" />
                  </div>
                )}
              </div>
            ))}
          />
          <div className="border-t-[0.1px] border-gray-secondary text-center text-gray-primary mx-10" />
        </div>
      </Container>
    </div>
  )
}

export default Reviews
