import ComponentMapping from './ComponentMapping'
import Routes from '../../../redux/routes'
import Success from '../SharedComponents/Success/Success'
import Pipeline from '../../UI/Pipeline/Pipeline'
import Headline from '../../UI/Headline/Headline'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { TYPES } from '../../UI/Pipeline/types'
import { ArtistBody } from './types'

const Artist: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  return (
    <>
      <Headline>{t.register.artist.headline}</Headline>
      <Pipeline<ArtistBody>
        type={TYPES.REGISTER_ARTIST}
        componentMapping={ComponentMapping}
        finalSubmitFunctionalRoute={Routes.REGISTER_ARTIST}
        SuccessComponent={Success}
      />
    </>
  )
}

export default Artist
