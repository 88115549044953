import banner1 from '../../../assets/image/home/banner/bg4.webp'
import banner2 from '../../../assets/image/home/banner/bg1.webp'
import banner3 from '../../../assets/image/home/banner/bg2.webp'
import banner4 from '../../../assets/image/home/banner/bg3.webp'

const randomBackgroundImage = (): string => {
  const images = [banner1, banner2, banner3, banner4]

  const index = Math.floor(Math.random() * 4)

  return images[index]
}

export default randomBackgroundImage
