import axios from 'axios'
import { File } from '../../../../../../types/Chat'
import { triggerClientSideDownload } from '../../../../../../utility'
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline'
import styled from 'styled-components'

type Props = {
  files: File[]
  isMyMessage: boolean
}

const Image = styled.img<{ itemsPerRow: number }>`
  max-width: ${({ itemsPerRow }) => `calc(100% / ${itemsPerRow} - (10px * ${itemsPerRow - 1} / ${itemsPerRow}));`};
`

const Files: React.FC<Props> = ({ files, isMyMessage }) => {
  const downloadFile = (file: File) => async () => {
    if (file.mimeType.startsWith('image/')) {
      window.open(file.url, '_blank')
    } else {
      const result = await axios.get(file.url, { responseType: 'blob' })
      if (result?.data) triggerClientSideDownload(result.data, file.name)
    }
  }

  if (!Array.isArray(files) || files.length === 0) return null

  const images = files.filter((file) => file.mimeType.startsWith('image/'))
  const documents = files.filter((file) => !file.mimeType.startsWith('image/'))

  return (
    <div
      className={`${
        isMyMessage ? 'bg-lightGreen text-white' : 'bg-torquise text-white'
      } rounded-md p-2 lg:max-w-[80%] overflow-hidden flex flex-wrap gap-[10px] mt-1 relative`}
    >
      {images.map((file) => {
        let imagesPerRow = images.length <= 3 ? images.length : 3
        return (
          <Image
            loading="lazy"
            key={file.key}
            className="rounded-sm cursor-pointer hover:scale-105 transition-all"
            src={file.url}
            alt={file.name}
            onClick={downloadFile(file)}
            itemsPerRow={imagesPerRow}
          />
        )
      })}
      {documents.map((file) => {
        return (
          <div
            key={file.key}
            className="flex flex-row justify-between items-center w-full overflow-hidden cursor-pointer"
            onClick={downloadFile(file)}
          >
            <div className="w-1/12 flex justify-center">
              <DocumentArrowDownIcon className={`w-4 stroke-white hover:scale-105 transition-all`} />
            </div>
            <div className={`w-11/12 truncate text-white text-sm`}>{file.name}</div>
          </div>
        )
      })}
    </div>
  )
}

export default Files
