import { getUUID } from '../../../utility'

type Props = {
  headline?: string
  description?: string
  small?: boolean
  className?: string
  italicSubline?: boolean
  classNameDescription?: string
}

const SectionHeader: React.FC<Props> = ({
  headline,
  description,
  small = false,
  italicSubline = false,
  className = '',
  classNameDescription = '',
}) => {
  return (
    <div>
      {!!headline && (
        <div
          className={`pt-12 ${small ? 'text-md' : 'leading-7 text-lg font-bold'} ${italicSubline ? 'mb-1' : 'mb-4'} text-gray-primary ${className}`}
        >
          {headline}
        </div>
      )}
      {!!description && (
        <>
          {Array.isArray(description) ? (
            description.map((d) => (
              <div key={getUUID()} className={`text-gray-primary leading-5 text-md font-light mt-2 ${classNameDescription}`}>
                {d}
              </div>
            ))
          ) : (
            <div className={`text-gray-primary text-md leading-5 font-light ${italicSubline ? 'italic mt-0' : 'mt-2'} ${classNameDescription}`}>
              {description}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default SectionHeader
