import { useSelector } from 'react-redux'
import { useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import { useNavigate } from 'react-router-dom'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import { Request } from '../../../../../types/Request'
import { Artist } from '../../../../../types/Artist'
import props from '../../../../../redux/props'
import Routes from '../../../../../redux/routes'
import { Project } from '../../../../../types/Project'
import { PurposeFlag } from '../../../../../types/Auth'
import toast, { TYPE } from '../../../../../toast'
import Loading from '../../../../UI/Loading/Loading'
import NewButton from '../../../../UI/NewButton/NewButton'
import NewModal from '../../../../UI/NewModal/NewModal'
import useReadNotification from '../../../../hooks/useReadNotification'
import { Type } from '../../../../../types/Notification'
import { isWithinArtistRadius } from '../../../../../utility'

type Props = {
  request: Request
  artist: Artist
}

enum Processing {
  FALSE = 'FALSE',
  SUBMITTING = 'SUBMITTING',
  SUCCESS = 'SUCCESS',
}

const AcceptButton: React.FC<Props> = ({ request, artist }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [processingState, setProcessingState] = useState<Processing>(Processing.FALSE)
  const acceptRequest = functional.use(Routes.ACCEPT_REQUEST)
  const [createdProject, setCreatedProject] = useState<Project>(null)
  const read = useReadNotification()

  const isArtist = accountLink && accountLink.purposeFlag === PurposeFlag.ARTIST

  const onClick = () => {
    if (isArtist) {
      setShowModal(true)
    } else {
      navigate('/login')
    }
  }

  const submit = async () => {
    setProcessingState(Processing.SUBMITTING)
    const project = await acceptRequest({ uuid: request.uuid })
    if (!!project) {
      read({
        type: Type.NEW_ARTIST_REQUEST,
        payload: { requestUUID: request.uuid },
      })
      setCreatedProject(project)
      setProcessingState(Processing.SUCCESS)
    } else {
      setProcessingState(Processing.FALSE)
      toast(TYPE.ERROR, t.request.view.makeAnOffer.accept.processing.error)
    }
  }

  const isDirectRequest = request.artistList && request.artistList.length > 0 && !!artist && !!request.artistList.some((a) => a.uuid === artist.uuid)

  const Cancel = <NewButton className="btn-secondary-outline w-fit mx-auto" onClick={() => setShowModal(false)} text={t.generic.cancel} />
  const Close = <NewButton className="btn-secondary-outline w-fit mx-auto" onClick={() => setShowModal(false)} text={t.generic.close} />
  const Confirm = (
    <NewButton
      className="btn-secondary"
      onClick={processingState === Processing.FALSE ? submit : null}
      text={t.request.view.makeAnOffer.accept.modal.cta}
    />
  )
  const GoToProject = () => (
    <NewButton
      className="btn-secondary"
      onClick={processingState === Processing.FALSE ? submit : null}
      href={`/home/artist/project/view/${createdProject.uuid}`}
      text={t.request.view.makeAnOffer.accept.modal.goToOffer}
    />
  )

  if (!!artist && isArtist && isDirectRequest) {
    return (
      <>
        <NewButton className="btn-secondary" text={t.request.view.makeAnOffer.accept.cta} onClick={onClick} />
        <NewModal show={showModal} onClose={() => setShowModal(false)} noForm noClose>
          {processingState === Processing.FALSE && (
            <>
              <div className="font-bold text-gray-primary">{t.request.view.makeAnOffer.accept.modal.title}</div>
              {!isWithinArtistRadius(artist, request.nameAndLocation.location) && (
                <div className="bg-gray-thirdary py-3 px-5 mt-3 rounded-sm">
                  <div className="text-sm">{t.request.view.makeAnOffer.accept.modal.radiusHint.title}</div>
                  <div className="text-xs mt-2 text-black-secondary">{t.request.view.makeAnOffer.accept.modal.radiusHint.text}</div>
                </div>
              )}
              <div className="text-gray-primary mt-3 text-sm">{t.request.view.makeAnOffer.accept.modal.text}</div>
              <div className="flex flex-col gap-3 w-60 mx-auto mt-5">
                {Confirm}
                {Cancel}
              </div>
            </>
          )}
          {processingState === Processing.SUBMITTING && (
            <>
              <div className="text-center text-sm mt-5">{t.request.view.makeAnOffer.accept.processing.submitting}</div>
              <Loading loading={false} />
            </>
          )}
          {processingState === Processing.SUCCESS && (
            <>
              <div className="text-center text-sm">{t.request.view.makeAnOffer.accept.processing.success}</div>
              <div className="flex flex-col gap-3 w-60 mx-auto mt-5">
                <GoToProject />
              </div>
              <div className="flex flex-col gap-3 w-60 mx-auto mt-5">{Close}</div>
            </>
          )}
        </NewModal>
      </>
    )
  } else {
    return null
  }
}

export default AcceptButton
