import InputMask from 'react-input-mask'
import { BaseInput } from './NewInput'

type CustomProps = {
  mask: string
}

const MaskInput: React.FC<BaseInput & CustomProps> = ({ value, onChange, mask }) => {
  const change = (e) => onChange(e.target.value.replaceAll('_', '').replaceAll(' ', ''))

  return (
    <div className={`form-control border overflow-hidden w-full bg-gray-100 flex-row items-center align-middle border-blue-primary bg-white`}>
      <InputMask
        mask={mask}
        alwaysShowMask={true}
        value={value}
        onChange={change}
        className="input w-full h-7 rounded-none bg-white px-2 active:outline-none focus:outline-none text-md text-blue-primary"
      />
    </div>
  )
}

export default MaskInput
