import Link from './Link/Link'

interface IButton {
  text?: string
  className?: string
  type?: string
  onClick?: () => void
  href?: string
  children?: React.ReactNode
  positionChildrenAtEnd?: boolean
  target?: string
}

enum Type {
  DEFAULT = 'default',
  SUBMIT = 'submit',
}

const baseClasses =
  'btn btn-primary font-medium min-h-0 px-3 h-8 rounded-full flex flex-row flex-nowrap items-center justify-center gap-1 cursor-pointer relative normal-case truncate text-ellipsis shrink-0'

const Button: React.FC<IButton> = ({ text, className, onClick, type = Type.DEFAULT, children, href, positionChildrenAtEnd, target }) => {
  if (type === Type.SUBMIT)
    return (
      <button className={baseClasses + ' ' + (className || '')} type="submit">
        {!!children && !positionChildrenAtEnd ? children : null}
        {text}
        {!!children && positionChildrenAtEnd ? children : null}
      </button>
    )
  if (!!href) {
    return (
      <Link href={href} className={baseClasses + ' ' + (className || '')} target={target}>
        {!!children && !positionChildrenAtEnd ? children : null}
        {text}
        {!!children && positionChildrenAtEnd ? children : null}
      </Link>
    )
  }
  return (
    <div className={baseClasses + ' ' + (className || '')} onClick={onClick}>
      {!!children && !positionChildrenAtEnd ? children : null}
      {text}
      {!!children && positionChildrenAtEnd ? children : null}
    </div>
  )
}

export default Button
