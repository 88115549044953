import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import NewToggle from '../../NewToggle/NewToggle'

const InsuranceToggle = ({ value, onChange }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div className="rounded-md mt-3">
      <div className="flex items-center gap-2">
        <NewToggle checked={value} onChange={onChange} />
        <div className="text-xs text-black-secondary">{t.priceCalculator.insurance.toggle}</div>
      </div>
    </div>
  )
}

export default InsuranceToggle
