import { useCallback, useEffect, useState } from 'react'
import SearchFilterMask from '../../../UI/SearchFilterMask/SearchFilterMask'
import props from '../../../../redux/props'
import { useSelector } from 'react-redux'
import { Category, Tag } from '../../../../types/Tag'
import SelectableTagList from '../../../UI/SelectableTagList/SelectableTagList'
import Routes from '../../../../redux/routes'
import { functional } from '@think-internet/zeus-frontend-package'
import Title from '../../../UI/Graybox/Title'
import OrderSelection from '../../../UI/SearchFilterMask/OrderSelection'
import { Project } from '../../../../types/Project'
import ProjectSizeSelection, { SizeFilterSelection } from '../../../UI/SearchFilterMask/ProjectSizeSelection'
import _ from 'lodash'
import history from 'history/browser'
import SmallContainer from '../../../UI/Container/SmallContainer'

type Props = {
  projects: Project[]
  setProjects: (project: Project[]) => void
  init: FilterProps
  toggleListView: () => void
  isListView: boolean
}

export type FilterProps = {
  input?: string
  objectTagEnumList?: string[]
  styleTagEnumList?: string[]
  price: {
    order?: 1 | -1
  }
  date: {
    order?: 1 | -1
  }
  size: SizeFilterSelection
}

const Filter: React.FC<Props> = ({ projects, setProjects, init, toggleListView, isListView }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const allTags: Tag[] = useSelector((state) => state[props.TAG])
  const [filter, setFilter] = useState<FilterProps>(
    _.merge(
      {},
      {
        input: '',
        price: {
          order: undefined,
        },
        date: {},
        size: {},
      },
      init || {},
    ),
  )
  const searchProjects = functional.use(Routes.SEARCH_PROJECTS)

  const search = useCallback(async () => {
    const projects = await searchProjects({ filter })
    if (Array.isArray(projects)) {
      setProjects(projects)
      history.replace('/project?query=' + JSON.stringify(filter))
    }
  }, [setProjects, searchProjects, filter])

  useEffect(() => {
    const fetchProjects = async () => {
      search()
    }
    if (!projects) {
      fetchProjects()
    }
  })

  const set = (prop: keyof FilterProps) => (value: any) => setFilter({ ...filter, [prop]: value })

  const setOrder = (prop: keyof FilterProps) => (value: 1 | -1) => {
    const currentProp = filter[prop] as { order: 1 | -1 }
    if (value === currentProp.order) {
      setFilter({ ...filter, [prop]: { ...currentProp, order: undefined } })
    } else {
      setFilter({ ...filter, [prop]: { ...currentProp, order: value } })
    }
  }

  return (
    <SmallContainer>
      <SearchFilterMask
        input={filter.input}
        inputOnChange={set('input')}
        submitCallback={search}
        toggleListView={toggleListView}
        isListView={isListView}
      >
        <div className="flex flex-col lg:flex-row gap-10 md:gap-14 pt-2">
          <div className="w-full flex flex-col gap-10">
            <div>
              <Title title={t.tag.category[Category.OBJECT]} className="text-white text-lg md:ml-3" />
              <SelectableTagList
                maxVisibleRows={3}
                selectedTagENUMList={filter.objectTagEnumList}
                tags={allTags.filter((tag: Tag) => tag.category === Category.OBJECT)}
                onChange={set('objectTagEnumList')}
              />
            </div>
            <div>
              <Title title={t.tag.category[Category.STYLE]} className="text-white text-lg md:ml-3" />
              <SelectableTagList
                maxVisibleRows={3}
                selectedTagENUMList={filter.styleTagEnumList}
                tags={allTags.filter((tag: Tag) => tag.category === Category.STYLE)}
                onChange={set('styleTagEnumList')}
              />
            </div>
          </div>
          <div className="w-full flex flex-col gap-10 ">
            <OrderSelection title={t.project.search.filter.price.title} value={filter.price.order} onChange={setOrder('price')} />
            <OrderSelection title={t.project.search.filter.date.title} value={filter.date.order} onChange={setOrder('date')} />
            <ProjectSizeSelection useSmallName title={t.project.search.filter.size.title} value={filter.size} onChange={set('size')} />
          </div>
        </div>
      </SearchFilterMask>
    </SmallContainer>
  )
}

export default Filter
