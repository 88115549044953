import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { Insurance as InsuranceType } from '../../../../../types/Project'
import { GetData, PipelineComponent, PipelineState } from '../../../../UI/Pipeline/types'
import SectionHeader from '../../../../UI/Pipeline/SectionHeader'
import toast, { TYPE } from '../../../../../toast'
import ArtistPriceCalculator from '../ArtistPriceCalculator/ArtistPriceCalculator'
import { formatPrice, getPercentageOfPrice, getTotalPriceOfRequest } from '../../../../../utility'
import { ArtistListItem, Request } from '../../../../../types/Request'
import SelectableTagListItem from '../../../../UI/SelectableTagList/Item/Item'
import useIsBreakpoint, { Breakpoint } from '../../../../hooks/useIsBreakpoint'

const Insurance: PipelineComponent<InsuranceType> = ({ pipelineStateProp, data, setData, NavigationForm, finalStepSubmit }) => {
  const t = useSelector((state) => state[props.TRANSLATION])
  const state: PipelineState<Request, { artist: ArtistListItem }> = useSelector((state) => state[props.PIPELINE])
  const isMobile = useIsBreakpoint(Breakpoint.SM)

  const set = (value: boolean) => () => {
    setData({
      ...data,
      accepted: value,
    })
  }

  const getData: GetData = () => {
    if (data.accepted === undefined) {
      toast(TYPE.ERROR, t.request.create.insurance.error)
      return false
    }
    return data
  }

  const Prop: React.FC<{ name: String }> = ({ name }) => (
    <div className="w-1/2 bg-blue-primary text-white border border-blue-primary  h-8 flex items-center justify-center rounded-sm">{name}</div>
  )
  const Value: React.FC<{ value: any }> = ({ value }) => (
    <div className="w-1/2 border border-blue-primary text-blue-primary h-8 flex items-center justify-center rounded-sm">{value}</div>
  )

  if (!data) return null
  return (
    <div>
      <ArtistPriceCalculator pipelineStateProp={pipelineStateProp} currentState={data} />
      <NavigationForm pipelineStateProp={pipelineStateProp} getData={getData} finalStepSubmit={finalStepSubmit}>
        <SectionHeader headline={t.request.create.insurance.title} description={t.request.create.insurance.description} />
        <div className="flex flex-col md:flex-row items-center gap-5 mt-5">
          <div className="w-full md:w-1/2 flex flex-col gap-3">
            <div className="flex flex-row gap-3">
              <Prop name={t.request.create.insurance.price} />
              <Value
                value={formatPrice(
                  getPercentageOfPrice(
                    getTotalPriceOfRequest(state.additionalData.artist, state.data, true),
                    state.additionalData.artist.insuranceDetail.pricePercentage,
                  ),
                )}
              />
            </div>
            <div className="flex flex-row gap-3">
              <Prop name={t.request.create.insurance.timespanInMonths} />
              <Value value={`${state.additionalData.artist.insuranceDetail.timespanInMonths}${t.generic.months}`} />
            </div>
            <div className="flex flex-row gap-3">
              <Prop name={t.request.create.insurance.timeExpenseInHours} />
              <Value value={`${state.additionalData.artist.insuranceDetail.timeExpenseInHours}${t.generic.hoursShort}`} />
            </div>
            <div className="block md:hidden mt-3">
              <div className="p-5 bg-gray-thirdary rounded-3xl shadow-md">
                <div className="font-bold text-sm">{t.request.create.insurance.info.title}</div>
                <div className="text-xs">{t.request.create.insurance.info.text}</div>
              </div>
            </div>
            <div className="flex flex-row gap-5 mt-4 justify-center">
              <SelectableTagListItem
                className="md:w-fit md:mx-auto"
                maxVisibleItemsPerRow={2}
                disableFixedWidth={isMobile}
                name={t.request.create.insurance.cta.accept}
                isSelected={data.accepted === true}
                onClick={set(true)}
              />
              <SelectableTagListItem
                className="md:w-fit md:mx-auto"
                maxVisibleItemsPerRow={2}
                disableFixedWidth={isMobile}
                name={t.request.create.insurance.cta.decline}
                isSelected={data.accepted === false}
                onClick={set(false)}
              />
            </div>
          </div>
          <div className="hidden md:block mt-0 w-1/2">
            <div className="p-5 bg-gray-thirdary rounded-3xl shadow-md">
              <div className="font-bold text-sm">{t.request.create.insurance.info.title}</div>
              <div className="text-xs">{t.request.create.insurance.info.text}</div>
            </div>
          </div>
        </div>
      </NavigationForm>
    </div>
  )
}

export default Insurance
