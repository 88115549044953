export type AuthLevelRequired = Array<Level> | false

export enum Level {
  ADMIN = 'ADMIN',
  DEFAULT = 'DEFAULT',
}

export enum PurposeFlag {
  ARTIST = 'ARTIST',
  BOOKER = 'BOOKER',
}

export enum AccountType {
  ARTIST = 'ARTIST',
  BOOKER = 'BOOKER',
  UNKNOWEN_SESSION = 'UNKNOWEN_SESSION',
}
