import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { Attendee as AttendeeType } from '../../../../../types/Project'
import { GetData, PipelineComponent, PipelineState } from '../../../../UI/Pipeline/types'
import toast, { TYPE } from '../../../../../toast'
import SectionHeader from '../../../../UI/Pipeline/SectionHeader'
import Number from '../../../../UI/NewInput/Number'
import NewInput from '../../../../UI/NewInput/NewInput'
import ArtistPriceCalculator from '../ArtistPriceCalculator/ArtistPriceCalculator'
import { ArtistListItem, Request } from '../../../../../types/Request'

const Attendee: PipelineComponent<AttendeeType> = ({ pipelineStateProp, data, setData, NavigationForm, finalStepSubmit }) => {
  const t = useSelector((state) => state[props.TRANSLATION])
  const state: PipelineState<Request, { artist: ArtistListItem }> = useSelector((state) => state[props.PIPELINE])

  const set = (prop: keyof AttendeeType) => (value: any) => setData({ ...data, [prop]: value })

  const getData: GetData = () => {
    const minAttendees = state.additionalData.artist ? state.additionalData.artist.pricing.workshop.minAttendees : 1
    if (!!data.amount && minAttendees <= data.amount) {
      if (!!data.motto) {
        return data
      } else {
        toast(TYPE.ERROR, t.request.create.attendee.motto.error)
        return false
      }
    } else {
      toast(TYPE.ERROR, t.request.create.attendee.error(minAttendees))
      return false
    }
  }

  return (
    <div>
      <ArtistPriceCalculator pipelineStateProp={pipelineStateProp} currentState={data} />
      <NavigationForm pipelineStateProp={pipelineStateProp} getData={getData} finalStepSubmit={finalStepSubmit}>
        <SectionHeader headline={t.request.create.attendee.amount.title} description={t.request.create.attendee.amount.description} />
        {!!state.additionalData.artist && (
          <div className="text-magenta-primary text-xs my-2">
            {t.request.create.attendee.hint(
              state.additionalData.artist.pricing.workshop.minAttendees,
              state.additionalData.artist.pricing.workshop.pricePerAttendee,
            )}
          </div>
        )}
        <div className={`flex flex-row gap-2 items-center mt-5`}>
          <div className={`shrink-0 flex h-[30px] rounded-sm ${'border border-blue-primary'} w-fit overflow-hidden bg-white`}>
            <div className="shrink-1 bg-blue-primary text-white w-full flex items-center px-2">
              <div className="w-full text-sm whitespace-nowrap text-center">{t.request.create.attendee.amount.unit}</div>
            </div>
          </div>
          <Number required className="!w-10 h-[30px]" textAlign="center" value={data.amount} onChange={set('amount')} />
        </div>
        <SectionHeader headline={t.request.create.attendee.motto.title} description={t.request.create.attendee.motto.description} />
        <NewInput required value={data.motto} onChange={set('motto')} placeholder={t.request.create.attendee.motto.placeholder} className="mt-5" />
      </NavigationForm>
    </div>
  )
}

export default Attendee
