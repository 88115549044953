import { useState } from 'react'
import { Artist } from '../../../types/Artist'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Filter, { FilterProps } from './Filter/Filter'
import ArtistHeaderMask from '../../UI/Artist/HeaderMask/HeaderMask'
import MediumContainer from '../../UI/Container/MediumContainer'
import Headline from '../../UI/Headline/Headline'
import { useSearchParams } from 'react-router-dom'
import Container from '../../UI/Container/Container'
import styled from 'styled-components'

const Wrapper = styled.div<{ itemsPerRow: number }>`
  width: calc(100% / ${(props) => props.itemsPerRow} - (20px));
  @media (min-width: 1024px) and (max-width: 1536px) {
    width: 100%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

const Search = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [artists, setArtists] = useState<Artist[]>(null)
  const [searchParams] = useSearchParams()
  const [isListView, setIsListView] = useState<boolean>(false)

  const getInitQuery = (): FilterProps => {
    const query = searchParams.get('query')
    try {
      return !!query ? JSON.parse(query) : null
    } catch (e) {
      return null
    }
  }

  const toggleListView = () => setIsListView(!isListView)

  const ArtistList = artists?.length > 0 && (
    <div className="px-3 sm:px-6 2xl:px-0">
      {artists?.length > 0 && (
        <div className="flex flex-row flex-wrap gap-6 md:gap-[40px] lg:gap-[20px] 2xl:gap-[40px]  mt-5">
          {artists.map((artist) => (
            <Wrapper key={artist.uuid} itemsPerRow={isListView ? 1 : 2}>
              <ArtistHeaderMask key={artist.personalDetail.username} isGridView={!isListView} artist={artist} addPublicElements />
            </Wrapper>
          ))}
        </div>
      )}
    </div>
  )

  return (
    <>
      <MediumContainer>
        <Headline>{t.artist.search.headline}</Headline>
        <div className="mt-5">
          <Filter artists={artists} setArtists={setArtists} init={getInitQuery()} toggleListView={toggleListView} isListView={isListView} />
        </div>
      </MediumContainer>

      {isListView ? (
        <MediumContainer innerClassName="!px-0 sm:!px-3">{ArtistList}</MediumContainer>
      ) : (
        <Container innerClassName="!px-0 sm:!px-3">{ArtistList}</Container>
      )}
    </>
  )
}

export default Search
