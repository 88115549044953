import { useSelector } from 'react-redux'
import { Pricing } from '../../../../../types/Artist'
import props from '../../../../../redux/props'
import { Sizes } from '../../../../../types/Size'
import SizeAndPrice from '../../../../UI/Pricing/Editable/SizeAndPrice'
import { formatPricePerSquareMeter } from '../../../../../utility'

type Props = {
  data: Pricing['primer']
}

const Primer: React.FC<Props> = ({ data }) => {
  const t = useSelector((state) => state[props.TRANSLATION])

  return (
    <div>
      <div className="font-bold mb-5">{t.register.artist.pricing.primer.headline}</div>
      <div className="flex flex-col gap-4 items-center">
        <SizeAndPrice disabled={data.disabled} size={Sizes.S} price={formatPricePerSquareMeter(data[Sizes.S])} readOnly />
        <SizeAndPrice disabled={data.disabled} size={Sizes.M} price={formatPricePerSquareMeter(data[Sizes.M])} readOnly />
        <SizeAndPrice disabled={data.disabled} size={Sizes.L} price={formatPricePerSquareMeter(data[Sizes.L])} readOnly />
      </div>
    </div>
  )
}

export default Primer
