import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import GrayBox from '../../../../UI/Graybox/Graybox'
import useListFavorites from '../../../../hooks/useListFavorites'
import Loading from '../../../../UI/Loading/Loading'
import Carousel from '../../../../UI/Carousel/Carousel'
import Project from '../../../../UI/Project/SmallCard/SmallCard'
import Artist from '../../../../UI/Artist/SmallCard/SmallCard'

const Favorites: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const favorites = useListFavorites()

  const getItems = () =>
    favorites?.map((favorite) => (
      <div key={favorite.uuid} className="w-full pt-5">
        {favorite.artist && <Artist initFavorite={true} artist={favorite.artist} />}
        {favorite.project && <Project initFavorite={true} project={favorite.project} />}
      </div>
    ))

  return (
    <GrayBox className="w-full" title={t.booker.dashboard.favorites.title} noTitlePadding>
      <Loading loading={favorites} />
      {!!favorites && favorites.length === 0 && <div className="text-center text-sm text-gray-primary">{t.booker.dashboard.favorites.empty}</div>}
      {!!favorites && favorites.length > 0 && <Carousel items={getItems()} smallArrows />}
    </GrayBox>
  )
}

export default Favorites
