import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { Design as DesignType } from '../../../../../types/Project'
import { GetData, PipelineComponent } from '../../../../UI/Pipeline/types'
import toast, { TYPE } from '../../../../../toast'
import SectionHeader from '../../../../UI/Pipeline/SectionHeader'
import ArtistPriceCalculator from '../ArtistPriceCalculator/ArtistPriceCalculator'
import Textarea from '../../../../UI/Textarea/Textarea'

const DesignText: PipelineComponent<DesignType> = ({ pipelineStateProp, data, setData, NavigationForm, finalStepSubmit }) => {
  const t = useSelector((state) => state[props.TRANSLATION])

  const set = (prop: keyof DesignType) => (value: any) => setData({ ...data, [prop]: value })

  const getData: GetData = () => {
    if (!!data.text && data.text.length > 0) {
      return data
    } else {
      toast(TYPE.ERROR, t.request.create.design.error.text)
    }
    return false
  }

  return (
    <div>
      <ArtistPriceCalculator />
      <NavigationForm pipelineStateProp={pipelineStateProp} getData={getData} finalStepSubmit={finalStepSubmit}>
        <SectionHeader headline={t.request.create.design.text.title} description={t.request.create.design.text.description} />
        <Textarea className="mt-6" value={data.text} onChange={set('text')} placeholder={t.request.create.design.text.placeholder} />
      </NavigationForm>
    </div>
  )
}

export default DesignText
