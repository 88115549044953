import styled from 'styled-components'

export const Headline = styled.div`
  color: ${({ theme }) => theme.color.blue.primary};
  font-size: ${({ theme }) => theme.font.size.xlg};
  margin-bottom: 52px;
  font-family: 'thin';
  margin-left: 30px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    margin-left: 0px;
    margin-bottom: 30px;

    color: ${({ theme }) => theme.color.blue.primary};
    font-size: ${({ theme }) => theme.font.size.lg};
    margin-bottom: 60px;
    font-family: 'thin';
    background-color: ${({ theme }) => theme.color.blue.primary};
    color: ${({ theme }) => theme.color.font.secondary};
    width: fit-content;
    padding: 10px 20px 5px 20px;
  }
`

export const Card = styled.div<{ image: string; image2?: string }>`
  height: 297px;
  width: 250px;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  border: 0.1px solid ${({ theme }) => theme.color.gray.secondary};
  &:hover {
    ${({ image2 }) =>
      image2 &&
      `
    background-image: url(${image2});
  `}
  }
`

export const Header = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  margin-top: 30px;
  padding: 5px 20px;
`

export const Type = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: 16px;
  text-shadow: 0px 0px 2px #000000;
  font-style: italic;
`

export const Name = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.font.size.xlg};
  text-shadow: 0px 0px 2px #000000;
  font-style: italic;
`

export const CTAWrapper = styled.div`
  margin-bottom: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CTA = styled.a`
  color: ${({ theme }) => theme.color.font.secondary};
  border: 2px solid ${({ theme }) => theme.color.white};
  border-radius: 50px;
  padding: 7px 20px;
  background-color: ${({ theme }) => theme.color.pink};
  font-size: ${({ theme }) => theme.font.size.sm};
  cursor: pointer;
  transition: all 0.2s ease-out;
  &:hover {
    box-shadow: 0px 0px 15px 0px #ffffff;
  }
`

export const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const PictureCard = styled.div<{ image: string; alternativeImage: string }>`
  height: 297px;
  width: 228px;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  transition: all 0.2s ease-out;
  &:hover {
    transition: all 0.2s ease-in;
    background-image: url(${({ alternativeImage }) => alternativeImage});
  }
`
