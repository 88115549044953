import { StyledButton, StyledButtonAnchor } from './Button.Styled'

type Props = {
  text: string
  onClick?: () => void
  borderRadius?: string
  href?: string
  className?: string
  margin?: string
  width?: string
  color?: string
  display?: string
  sub?: boolean
  backgroundColor?: string
  type?: 'button' | 'submit' | 'reset'
  small?: boolean
  disabled?: boolean
  inverted?: boolean
}

const Button: React.FC<Props> = ({
  text,
  onClick,
  borderRadius,
  href,
  className,
  margin,
  width,
  color,
  display,
  sub,
  backgroundColor,
  type = 'button',
  small = false,
  disabled = false,
  inverted = false,
}) => {
  if (href) {
    return (
      <StyledButtonAnchor
        href={href}
        className={className}
        margin={margin}
        width={width}
        color={color}
        display={display}
        backgroundColor={backgroundColor}
        inverted={inverted}
      >
        {text}
      </StyledButtonAnchor>
    )
  }
  return (
    <StyledButton
      disabled={disabled}
      small={small}
      sub={sub}
      borderRadius={borderRadius}
      onClick={onClick}
      className={className}
      margin={margin}
      type={type}
      width={width}
      color={color}
      display={display}
      backgroundColor={backgroundColor}
      inverted={inverted}
    >
      {text}
    </StyledButton>
  )
}

export default Button
