import { socket } from '@think-internet/zeus-frontend-package'
import sockets from '../../redux/sockets'
import { Message } from '../../types/Chat'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'

const useReadChatMessages = (projectUUID: string) => {
  const sendReadToSocket = socket.use(sockets.CHAT_MESSAGE_READ, null, { projectUUID })

  const read = (messages: Message[], accountLink: AccountLink) => {
    if (!!accountLink?.uuid) {
      const unreadMessages = messages.filter((m) => m.readByOpponent === false && m.senderAccountLinkUUID !== accountLink.uuid)
      unreadMessages.forEach((m) => sendReadToSocket({ messageUUID: m.uuid }))
    }
  }

  return read
}

export default useReadChatMessages
