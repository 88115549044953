import { useSelector } from 'react-redux'
import Title from '../../../../UI/Graybox/Title'
import props from '../../../../../redux/props'
import useListArtistRatings from '../../../../hooks/useListArtistRatings'
import Loading from '../../../../UI/Loading/Loading'
import Carousel from '../../../../UI/Carousel/Carousel'
import prev from '../../../../../assets/image/artist/public-profile/reviews/prev.png'
import next from '../../../../../assets/image/artist/public-profile/reviews/next.png'
import { Artist } from '../../../../../types/Artist'
import { Rating } from '../../../../../types/Rating'
import Icon from '../../../../UI/ProfileIcon/Icon'
import RatingStars from '../../../../UI/RatingStars/RatingStars'
import DaysSince from '../../../../UI/DaysSince/DaysSince'

type RatingItemProps = {
  rating: Rating
}
const RatingItem: React.FC<RatingItemProps> = ({ rating }) => {
  const getBookerName = () => {
    const company = rating.booker.billingDetail.companyName
    if (!!company) return company
    return `${rating.booker.billingDetail.firstname} ${rating.booker.billingDetail.lastname[0]}.`
  }

  return (
    <div>
      <div className="flex flex-row gap-2 items-center">
        <div className="">
          <Icon booker={rating.booker} image={rating.booker.profileImages?.personImageURL} className="!h-12" />
        </div>
        <div className="flex flex-col gap-0">
          <div className="text-sm">{getBookerName()}</div>
          <div className="flex flex-row gap-1 items-center">
            <RatingStars rating={rating.publicScore} />
            <div className="text-xs text-yellow-primary">{rating.publicScore}</div>
            <DaysSince date={new Date(rating.createdOn)} className="ml-3" />
          </div>
        </div>
      </div>
      <div className="leading-4 text-sm text-gray-primary mx-auto w-10/12 mt-3">{rating.text}</div>
    </div>
  )
}

type Props = {
  artist: Artist
}
const Reviews: React.FC<Props> = ({ artist }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const reviews = useListArtistRatings(artist.uuid)

  return (
    <div className="md:w-1/2">
      <Title title={t.artist.search.view.reviews.title} className="text-lg" />
      <Loading loading={reviews} />
      {reviews && reviews.length === 0 && <div className="text-gray-primary text-sm text-center mt-3">{t.artist.search.view.reviews.empty}</div>}
      {reviews && reviews.length > 0 && (
        <Carousel
          className="border border-gray-secondary rounded-md p-3 mt-3 !w-10/12 mx-auto"
          customPrevArrow={<img src={prev} alt="prev" className="h-5" />}
          customNextArrow={<img src={next} alt="next" className="h-5" />}
          items={reviews.map((r) => (
            <RatingItem key={r.uuid} rating={r} />
          ))}
          itemsToShow={1}
        />
      )}
    </div>
  )
}

export default Reviews
