import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { BudgetExpectation as BudgetExpectationType } from '../../../../../types/Request'
import { GetData, PipelineComponent } from '../../../../UI/Pipeline/types'
import toast, { TYPE } from '../../../../../toast'
import SectionHeader from '../../../../UI/Pipeline/SectionHeader'
import Currency from '../../../../UI/NewInput/Currency'
import { useEffect } from 'react'
import Checkbox from '../../../../UI/Checkbox/Checkbox'
import Slider from '../../../../UI/Slider/Slider'

const BudgetExpectation: PipelineComponent<BudgetExpectationType> = ({ pipelineStateProp, data, setData, NavigationForm, finalStepSubmit }) => {
  const t = useSelector((state) => state[props.TRANSLATION])

  const set = (prop: keyof BudgetExpectationType) => (value: any) => setData({ ...data, [prop]: value })

  const getData: GetData = () => {
    if (data.askingPrice > 0 || data.noAskingPrice === true) {
      return data
    } else {
      toast(TYPE.ERROR, t.request.create.budgetExpectation.error)
      return false
    }
  }

  useEffect(() => {
    if (typeof data.askingPrice === 'undefined') {
      setData({ ...data, askingPrice: 150 })
    }
  }, [setData, data])

  return (
    <div>
      <NavigationForm pipelineStateProp={pipelineStateProp} getData={getData} finalStepSubmit={finalStepSubmit}>
        <SectionHeader headline={t.request.create.budgetExpectation.title} />
        <div className="mt-7 flex flex-col gap-10">
          {!data.noAskingPrice && (
            <>
              <div className="w-36">
                <Currency value={data.askingPrice} onChange={set('askingPrice')} />
              </div>
              <Slider max={20000} value={data.askingPrice} onChange={set('askingPrice')} />
            </>
          )}
          <div className="flex items-center gap-2 mt-2">
            <Checkbox checked={data.noAskingPrice} onChange={set('noAskingPrice')} label={t.request.create.budgetExpectation.noAskingPrice} />
          </div>
        </div>
      </NavigationForm>
    </div>
  )
}

export default BudgetExpectation
