import { Artist } from '../../../types/Artist'
import { Booker } from '../../../types/Booker'

type Props = {
  artist?: Artist
  booker?: Booker
  image?: string
  className?: string
}

const Icon: React.FC<Props> = ({ artist, booker, image, className = '' }) => {
  return (
    <div className={`h-9 md:h-12 2xl:h-9 aspect-square rounded-full overflow-hidden shrink-0 border border-white ${className}`}>
      {!image && (
        <div className="h-full w-full border border-white rounded-full flex items-center justify-center bg-blue-super-dark text-lg text-white uppercase">
          {!!artist && !!artist?.personalDetail?.username && artist.personalDetail.username[0]}
          {!!booker && !!booker?.billingDetail?.companyName ? booker.billingDetail.companyName[0] : booker?.billingDetail?.lastname[0] || ''}
        </div>
      )}
      {image && <img src={image} alt="" className="h-full w-full object-cover" />}
    </div>
  )
}

export default Icon
